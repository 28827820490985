import FormInput from "@/components/input/FormInput";
import { Button } from "@/components/ui/button";
import { DialogClose } from "@/components/ui/dialog";
import { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";

const RescheduleConfirmationCard = ({ handleReschedule, isLoading }) => {
	const [errorMessage, setErrorMessage] = useState("");
	const [formData, setFormData] = useState<{ message: string }>({
		message:
			"The space you have reserved has already been reserved at the same time. Please reschedule your time or pick a different space. Thanks! ",
	});

	const submit = () => {
		if (formData.message.length < 10) {
			return setErrorMessage("Please enter a valid message");
		}
		setErrorMessage("");
		handleReschedule(formData.message);
	};
	return (
		<div className="flex w-full  flex-col !rounded-lg">
			<h1 className="pb-4 text-2xl font-semibold text-basecolor">
				Request to Reschedule
			</h1>
			<FormInput
				textarea={true}
				rows={6}
				label="Default Message"
				value={formData.message}
				onChange={(e) => setFormData({ message: e.target.value })}
			/>
			{errorMessage && (
				<p className="text-sm text-red-400">{errorMessage}</p>
			)}
			<div className="flex w-full justify-end gap-4 pt-4">
				<DialogClose className="w-fit rounded-md bg-transparent px-3  py-2 text-primary">
					Cancel
				</DialogClose>
				<Button onClick={submit} disabled={isLoading}>
					{isLoading ? (
						<LoadingOutlined style={{ fontSize: 24 }} spin />
					) : (
						"Send Message"
					)}
				</Button>
			</div>
		</div>
	);
};

export default RescheduleConfirmationCard;
