import { TeamMemberRolesType } from "@/types/team-member";

const MemberInfo: React.FC<{
	memberType: TeamMemberRolesType;
}> = ({ memberType }) => {
	return (
		<div className="w-full rounded-[28px] bg-white">
			<div className="border-b border-b-[#BFBFBF] px-6 py-4">
				<h1 className="font-bold text-basecolor">
					{MemberType[memberType].title}
				</h1>
				<p className="pt-2 text-xs font-normal text-basecolor">
					{MemberType[memberType].desc}
				</p>
			</div>
			<div className="flex flex-col gap-y-6 px-6 py-8">
				{/* <div className="flex flex-col gap-2">
					<h4 className="text-xs font-semibold">Assigned to </h4>
					<div className="flex flex-wrap gap-2">
						{MemberType[memberType].assigned.map((item, index) => (
							<div
								key={index}
								className="rounded-full bg-[#F9F9F9] px-2.5 py-2 "
							>
								<p className="text-xs text-[#6B7280]">{item}</p>
							</div>
						))}
					</div>
				</div> */}
				<div className="flex flex-col gap-y-2">
					<h4 className="text-xs font-semibold">Access to </h4>
					<div className="flex flex-wrap gap-2">
						{MemberType[memberType].access.map((item, index) => (
							<div
								key={index}
								className="rounded-full bg-[#F9F9F9] px-2.5 py-2 "
							>
								<p className="text-xs text-[#6B7280]">{item}</p>
							</div>
						))}
					</div>
				</div>
				<div className="flex flex-col gap-y-2">
					<h4 className="text-xs font-semibold">Actions </h4>
					<div className="flex flex-wrap gap-2">
						{MemberType[memberType].actions.map((item, index) => (
							<div
								key={index}
								className="rounded-full bg-[#F9F9F9] px-2.5 py-2"
							>
								<p className="text-xs text-[#6B7280]">{item}</p>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default MemberInfo;

export const MemberType = {
	SUPER_ADMIN: {
		title: "Super Admin",
		desc: "Ultimate access for all system aspects, user management, system settings, data oversight, and full platform operation across all locations.",
		assigned: ["Full Name 1", "Full Name 2", "Full Name 3"],
		access: ["All Locations", "All Rooms", "All Partners"],
		actions: [
			"Add, Edit, and Remove Locations",
			"Add, Edit, and Remove Reservations",
			"Add, Edit, and Remove Team Members",
			"Add, Edit, and Remove Partners",
			"Add, Edit, and Remove Rooms",
			"Reset Account Details of Admins & Partners",
			"Add, Edit, and Remove Amenities",
		],
	},
	LOCATION_MANAGER: {
		title: "Location Manager",
		desc: "Manages appointment schedules, patient flow, and resource allocation for a specific clinic or hospital location.",
		assigned: ["Full Name 1", "Full Name 2", "Full Name 3"],
		access: ["Location Name 1", "Location Name 2", "All Rooms in Location"],
		actions: [
			"Add, Edit, and Remove Locations",
			"Add, Edit, and Remove Reservations",
			"Add, Edit, and Remove Partners",
			"Add, Edit, and Remove Rooms",
			"Add, Edit, and Remove Amenities",
		],
	},
	ROOM_MANAGER: {
		title: "Space Manager",
		desc: "Oversees reservations, updates space schedules, and coordinates healthcare provider-patient interactions for assigned rooms.",
		assigned: ["Full Name 1", "Full Name 2", "Full Name 3"],
		access: ["Space Name 1", "Space Name 2", "Space Name 3"],
		actions: [
			"Add, Edit, and Remove Spaces",
			"Add, Edit, and Remove Reservations",
			"Add, Edit, and Remove Amenities",
		],
	},
	PARTNER_MANAGER: {
		title: "Partner Manager",
		desc: "Creates and oversees bookings, coordinates interactions for assigned partner(s).",
		assigned: ["Full Name 1", "Full Name 2", "Full Name 3"],
		access: ["Partner Name 1", "Partner Name 2"],
		actions: [
			"Add, Edit, and Remove Team Members",
			"Add, Edit, and Remove Bookings",
			"Add, Edit, and Remove Partners",
			"Reset Account Details of Admins & Partners",
		],
	},
	TEAM_MEMBER: {
		title: "Team Member",
		desc: "Creates and oversees bookings, coordinates interactions for assigned partner(s).",
		assigned: [""],
		access: ["Partner Name 1", "Partner Name 2"],
		actions: ["View All Bookings"],
	},
};
