import FormInput from "@/components/input/FormInput";
import Modal from "@/components/modals/Modal";
import DeleteMember from "@/components/modals/partner/DeleteMember";
import MakeAdmin from "@/components/modals/partner/MakeAdmin";
import RemoveAdmin from "@/components/modals/partner/RemoveAdmin";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { useUpdateMemberMutation } from "@/redux/features/api/partnerEndpoints";
import { formatDateString } from "@/services/utilities";
import { PartnerCompanyMember } from "@/types/partners/member";
import { message } from "antd";
import { useState } from "react";
import { IoClose } from "react-icons/io5";
import EditMember from "../../modals/EditMember";
import { useAppSelector } from "@/hooks/hooks";
import { TeamMemberRoles } from "@/types/team-member";

interface MemberCardProps {
	type: "guest" | "admin";
	onSelect: () => void;
	checked: boolean;
	item: PartnerCompanyMember;
}

const MemberCard = ({ type, checked, item, onSelect }: MemberCardProps) => {
	const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");
	const [editDetails, setEditDetails] = useState(false);
	const [messageApi, contextHolder] = message.useMessage();
	const [showDeleteMember, setShowDeleteMember] = useState(false);
	const [showMemberDetails, setShowMemberDetails] = useState(false);
	const [showMakeAdmin, setShowMakeAdmin] = useState(false);
	const [showRemoveAdmin, setShowRemoveAdmin] = useState(false);
	const [updateMember] = useUpdateMemberMutation();

	const initialState = {
		name: item.name,
		email: item.email,
		phone: item.phone_number,
		dateJoined: item.created_at,
	};
	const [formData, setFormData] = useState(initialState);

	const handleClose = () => {
		setEditDetails(false);
		setFormData(initialState);
	};

	const handleEditToggle = () => {
		if (editDetails) {
			handleUpdateMember();
		} else if (!editDetails) {
			setEditDetails(!editDetails);
		}
	};
	const handleUpdateMember = async () => {
		messageApi.open({
			key: "updateMemberMessage",
			type: "loading",
			content: "Updating member...",
		});
		try {
			const result: any = await updateMember({
				member_id: item.id,
				data: {
					name: formData.name ?? " ",
					email: formData.email ?? " ",
					phone_number: formData.phone ?? " ",
					role: type,
				},
			});
			if (result?.data) {
				messageApi.open({
					key: "updateMemberMessage",
					type: "success",
					content: "Member Updated successfully!",
					duration: 2,
				});
			}
			messageApi.open({
				key: "updateMemberMessage",
				type: "error",
				content: "Error updating member",
				duration: 2,
			});
		} catch (error) {
			messageApi.open({
				key: "updateMemberMessage",
				type: "error",
				content: "Error updating member",
				duration: 2,
			});
		}
	};

	return (
		<TooltipProvider>
			{contextHolder}
			<div className="my-2 flex items-center justify-between rounded-[12px] bg-white px-4 py-1.5 shadow-lg sm:my-4">
				<div className="flex gap-4 lg:flex-[0.2]">
					<input
						type="checkbox"
						checked={checked}
						onChange={onSelect}
					/>
					<div className="flex items-center gap-2">
						<span
							className={cn(
								"relative flex h-[16px] w-[16px] items-center justify-center rounded-full border border-[#B7B7B7] bg-[#F8F8F8] p-2.5 text-[8px] text-[#525252] md:text-[12px]"
							)}
						>
							{formData?.name?.charAt(0) ?? ""}
						</span>
						<FormInput
							inputType="text"
							value={formData?.name ?? ""}
							className={cn(" h-7 w-fit pl-8 text-[14px]", {
								"border-none focus:border-none": !editDetails,
							})}
							onChange={(e) =>
								setFormData({
									...formData,
									name: e.target.value,
								})
							}
							disabled={!editDetails}
						/>
					</div>
				</div>

				<div className="hidden items-center justify-end gap-x-4 lg:flex lg:flex-[0.6]">
					<div className="flex items-center space-x-2">
						{/* <MdOutlineMail className="absolute left-2" /> */}
						<i className="mgc_mail_line before:!text-[#858C95] xs:text-[18px]" />
						<FormInput
							inputType="text"
							value={formData?.email ?? ""}
							className={cn(" h-7 pl-0 text-[14px]", {
								"border-none focus:border-none": !editDetails,
							})}
							onChange={(e) =>
								setFormData({
									...formData,
									email: e.target.value,
								})
							}
							disabled={!editDetails}
						/>
					</div>
					<div className="flex items-center space-x-2">
						<i className="mgc_phone_line before:!text-[#858C95] xs:text-[18px]" />
						<FormInput
							inputType="text"
							value={formData?.phone ?? ""}
							className={cn("h-7 pl-0 text-[14px]", {
								"border-none focus:border-none": !editDetails,
							})}
							onChange={(e) =>
								setFormData({
									...formData,
									phone: e.target.value,
								})
							}
							disabled={!editDetails}
						/>
					</div>
					<div className="flex items-center gap-1">
						<i className="mgc_calendar_line text-[18px] before:!text-[#858C95]" />
						<FormInput
							inputType="text"
							value={
								formData?.dateJoined
									? formatDateString(formData.dateJoined)
									: ""
							}
							className={cn(
								"h-7 border-none pl-0 text-[14px] focus:border-none"
							)}
							disabled={true}
						/>
					</div>
				</div>
				{userRole !== TeamMemberRoles.PartnerAdmin && (
					<div className="hidden justify-end gap-2 lg:flex lg:flex-[0.2]">
						{/* Cancel  */}
						{editDetails ? (
							<Tooltip delayDuration={0}>
								<TooltipTrigger asChild>
									<Button
										className="h-7 bg-[#E0E0E0] px-1.5 py-1.5"
										onClick={handleClose}
									>
										<IoClose />
									</Button>
								</TooltipTrigger>
								<TooltipContent side="top" sideOffset={10}>
									Cancel
								</TooltipContent>
							</Tooltip>
						) : null}

						<Tooltip delayDuration={0}>
							<TooltipTrigger asChild>
								<Button
									variant={
										editDetails
											? "default"
											: "outlinecustom"
									}
									className={cn(
										"h-7 px-1.5 py-1.5 duration-200 ease-in-out hover:bg-[#F5F5F5]",
										{
											"bg-primary hover:bg-primary/85":
												editDetails,
										}
									)}
									onClick={handleEditToggle}
								>
									{editDetails ? (
										<i className="mgc_save_2_line before:!text-white" />
									) : (
										<i className="mgc_edit_2_line before:!text-primary" />
									)}
								</Button>
							</TooltipTrigger>
							<TooltipContent side="top" sideOffset={10}>
								{editDetails ? "Save" : "Edit"}
							</TooltipContent>
						</Tooltip>
						{type === "guest" && (
							<Tooltip delayDuration={0}>
								<TooltipTrigger asChild>
									<Button
										variant="outlinecustom"
										className="h-7 px-1.5 py-1.5 duration-200 ease-in-out hover:bg-[#F5F5F5]"
										onClick={() => setShowMakeAdmin(true)}
									>
										<i className="mgc_user_star_fill before:!text-primary" />
									</Button>
								</TooltipTrigger>
								<TooltipContent side="top" sideOffset={10}>
									Make Admin
								</TooltipContent>
							</Tooltip>
						)}
						{type === "admin" && (
							<Tooltip delayDuration={0}>
								<TooltipTrigger asChild>
									<Button
										variant="outlinecustom"
										className="h-7 px-1.5 py-1.5 duration-200 ease-in-out hover:bg-[#F5F5F5]"
										onClick={() => setShowRemoveAdmin(true)}
									>
										<i className="mgc_user_star_fill before:!text-primary" />
									</Button>
								</TooltipTrigger>
								<TooltipContent side="top" sideOffset={10}>
									Remove Admin
								</TooltipContent>
							</Tooltip>
						)}

						{/* Delete function  */}
						<Tooltip delayDuration={0}>
							<TooltipTrigger asChild>
								<Button
									variant="outlinecustom"
									className="h-7 px-1.5 py-1.5 duration-200 ease-in-out hover:bg-[#F5F5F5]"
									onClick={() => setShowDeleteMember(true)}
								>
									<i className="mgc_delete_2_line before:!text-primary" />
								</Button>
							</TooltipTrigger>
							<TooltipContent side="top" sideOffset={10}>
								Delete
							</TooltipContent>
						</Tooltip>
					</div>
				)}

				{/* hidden  */}
				{userRole !== TeamMemberRoles.PartnerAdmin && (
					<>
						<div className="lg:hidden">
							<EditMember
								item={item}
								showMemberDetails={showMemberDetails}
								setShowMemberDetails={setShowMemberDetails}
							/>
						</div>

						{/* Delete Admin Dialog  */}
						<Dialog
							modal
							open={showDeleteMember}
							onOpenChange={setShowDeleteMember}
						>
							<DialogContent className="flex w-fit max-w-[360px] rounded-lg border border-[#E5E5E7] bg-white p-4 shadow-md ">
								<DeleteMember
									handleClose={() =>
										setShowDeleteMember(false)
									}
									name={formData?.name ?? formData.email}
									id={item.id}
								/>
							</DialogContent>
						</Dialog>

						<Modal
							open={showMakeAdmin}
							setOpen={setShowMakeAdmin}
							dialogClassName="!rounded-lg max-w-[360px]"
						>
							<MakeAdmin
								handleClose={() => setShowMakeAdmin(false)}
								name={formData?.name ?? formData?.email}
								id={item.id}
							/>
						</Modal>
						<Modal
							open={showRemoveAdmin}
							setOpen={setShowRemoveAdmin}
							dialogClassName="!rounded-lg max-w-[360px]"
						>
							<RemoveAdmin
								handleClose={() => setShowRemoveAdmin(false)}
								name={formData?.name ?? formData?.email}
								id={item.id}
							/>
						</Modal>
					</>
				)}
			</div>
		</TooltipProvider>
	);
};

export default MemberCard;
