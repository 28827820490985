import BookingOverviewContent from "@/components/admin/BookingOverviewContent";
import { Booking } from "@/types/admin/booking";
import { useNavigate } from "react-router";
import { Button } from "../../ui/button";

const BookingOverview: React.FC<{ data: Booking }> = ({ data }) => {
	const navigate = useNavigate();

	const handleShowMeeting = () => {
		if(data?.bookings.length > 1){
			const id = data?.bookings[0].id
			navigate(`/partner/meeting/${id}`)
		}else{
			navigate(`/partner/meeting/${data?.id}`)
		}
	}

	return (
		<section className="space-y-6">
			<BookingOverviewContent data={data} />
			<div className="flex w-full gap-2 pb-4">
				<Button
					className="w-full bg-transparent"
					variant="outlinecustom"
					onClick={handleShowMeeting}
				>
					Edit
				</Button>
				<Button
					className="w-full"
					variant="default"
					onClick={handleShowMeeting}
				>
					View Reservation
				</Button>
			</div>
		</section>
	);
};

export default BookingOverview;
