import { useAppSelector } from "@/hooks/hooks";
import { useGetLocationAnalyticsQuery } from "@/redux/features/api/locationEndpoints";
import React from "react";
import { ScrollArea } from "../../../../components/ui/scroll-area";
import MostbookedRoomsBarLine from "./MostbookedRoomsBarLine";

const MostBookedRooms: React.FC = () => {
	const businessId =
		useAppSelector((state) => state.user.user?.business_id) ?? 0;
	const queryData = useAppSelector((state) => state.analytics.query_data);

	const getAdminLocationsQuery = useGetLocationAnalyticsQuery({
		user_id: businessId,
		query_data: queryData,
	});

	const createBarData = (name: string, value: number) => ({
		name,
		value,
	});

	const locations = getAdminLocationsQuery?.data
		? getAdminLocationsQuery?.data?.data.statics.booked_by_rooms
				.map((item) => {
					const keyName = Object.keys(item)[0];
					const value = parseInt(item[keyName] ?? "0");

					return createBarData(keyName, value);
				})
				.sort((a, b) => b.value - a.value)
		: [];

	const total = locations.reduce((acc, item) => acc + item.value, 0);

	const maxTotalCount = Math.max(...locations.map((item) => item.value));

	return (
		<section className="flex flex-[0.3fr] flex-col rounded-lg bg-white p-8 pb-[41px]">
			<div className="mb-6 flex flex-col space-y-2">
				<h3 className="font-manrope text-lg font-bold leading-[16px] tracking-[0.5px] text-[#454D56]">
					Reservations By Space
				</h3>
				<p className="text-xs leading-[120%] tracking-[0.41px] text-[#596574]">
					Total Reservations :{" "}
					<span className="text-base font-semibold">{total}</span>
				</p>
			</div>
			<ScrollArea className="max-h-[275px] flex-1">
				<ul className="flex flex-col space-y-2 ">
					{locations.map((item, index) => {
						return (
							<MostbookedRoomsBarLine
								key={index}
								title={item.name}
								completeTotal={maxTotalCount}
								rowTotal={item.value}
								halfTotal={0}
								backgroundColor="#005893"
							/>
						);
					})}
				</ul>
			</ScrollArea>
		</section>
	);
};

export default MostBookedRooms;
