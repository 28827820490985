import { createSlice } from "@reduxjs/toolkit";

interface EventsState {
	searchQuery: string;
	selectedLocations: string;
	selectedPartners: string;
	selectedRooms: { label: string; value: string }[];
	selectedStatus: string;
}

const initialState: EventsState = {
	searchQuery: "",
	selectedLocations: "all",
	selectedPartners: "all",
	selectedStatus: "all",
	selectedRooms: [],
};

const eventsSlice = createSlice({
	name: "events",
	initialState,
	reducers: {
		setSearchQuery: (state, action) => {
			state.searchQuery = action.payload;
		},
		setSelectedLocations: (state, action) => {
			state.selectedLocations = action.payload;
		},
		setSelectedPartners: (state, action) => {
			state.selectedPartners = action.payload;
		},
		setSelectedRooms: (state, action) => {
			state.selectedRooms = action.payload;
		},
		setSelectedStatus: (state, action) => {
			state.selectedStatus = action.payload;
		},
		resetBookingFilters: () => initialState,
	},
});

export const {
	setSearchQuery,
	setSelectedLocations,
	setSelectedPartners,
	setSelectedRooms,
	setSelectedStatus,
	resetBookingFilters,
} = eventsSlice.actions;
export default eventsSlice.reducer;
