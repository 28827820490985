import AmenityHolder from "@/components/common/AmenityHolder";
import CapacityHolder from "@/components/common/CapacityHolder";
import ImageCarousel from "@/components/common/ImageCarousel";
import { Button } from "@/components/ui/button";
import { DialogClose } from "@/components/ui/dialog";
import { useState } from "react";
import EditRoom from "./EditRoom";
import { useAppSelector } from "@/hooks/hooks";
import { TeamMemberRoles } from "@/types/team-member";
import { Room } from "@/types/admin/booking";

const RoomSummary: React.FC<{
	room: Room;
	handleClose: () => void;
}> = ({ room, handleClose }) => {
	const [editRoom, setEditRoom] = useState(false);
	const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");

	return (
		<div className="flex h-full w-full flex-col gap-y-3 rounded-[30px] bg-white">
			<div className="flex w-full cursor-pointer justify-center border-b border-b-primary pb-2 pt-4 text-center text-[15px] font-semibold text-primary ">
				{editRoom ? <h1>Edit Details</h1> : <h1>Space Details</h1>}
			</div>
			{editRoom ? (
				<div className="max-w-full">
					<EditRoom roomData={room} handleClose={handleClose} />{" "}
				</div>
			) : (
				<div className="flex flex-col gap-y-4 rounded-bl-[30px] rounded-br-[30px] p-4">
					<div>
						<ImageCarousel images={room?.photos} />
					</div>
					<div>
						<div className="flex items-center justify-between">
							<div className="">
								<span className="flex items-center gap-2 text-[10px]">
									<i className="mgc_information_line before:!text-[#323539]" />
									<span className="text-[14px] font-semibold">
										{room?.name}
									</span>
								</span>
								<span className="flex items-center gap-2 text-[10px]">
									<i className="mgc_location_3_line text-[12px] before:!text-[#323539]" />
									<span>{room?.address}</span>
								</span>
							</div>
							<CapacityHolder capacity={room?.capacity} />
						</div>
						<p className="pt-2 text-[14px] font-light">
							{room?.description ?? "No description available"}
						</p>
					</div>
					{/* Amenities  */}
					<div className="mb-4 space-y-2">
						<h1 className="text-[14px] font-medium">Amenities</h1>
						<div className="flex items-center gap-2">
							{room?.amenities.length ? (
								room?.amenities?.map(
									(amenity: any, index: number) => (
										<AmenityHolder
											key={index}
											amenity={amenity.name}
											variant="list"
										/>
									)
								)
							) : (
								<p className=" text-[14px] font-light">
									No amenities available
								</p>
							)}
						</div>
					</div>

					<div className="flex w-full items-center gap-x-1.5 pt-2">
						<DialogClose className="w-full rounded-md border border-primary bg-transparent px-4 py-2 text-primary">
							Cancel
						</DialogClose>
						{userRole !== TeamMemberRoles.TeamMember &&
							userRole !== TeamMemberRoles.PartnerManager && (
								<Button
									className=" w-full rounded-md px-4 py-2 text-white "
									onClick={() => setEditRoom(true)}
								>
									Edit
								</Button>
							)}
					</div>
				</div>
			)}
		</div>
	);
};

export default RoomSummary;
