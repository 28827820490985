import CustomSelect from "@/components/common/CustomSelect";
import Search from "@/components/input/Search";
import AddUser from "@/components/modals/admin/partners/AddUser";
import Modal from "@/components/modals/Modal";
import { Button } from "@/components/ui/button";
import { DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useAppSelector } from "@/hooks/hooks";
import { useAddNewMemberMutation } from "@/redux/features/api/partnerEndpoints";
import { TeamMemberRoles } from "@/types/team-member";
import { IUser, IUserPartner } from "@/types/user";
import { useState } from "react";
import UserListCard from "./UserListCard";
import Wrapper from "@/container/Wrapper";

interface UserListProps {
	users?: IUser[];
	isUserDataFetching: boolean;
	isUserFetchSuccess: boolean;
	isUserFetchError: boolean;
	companyId: string | undefined;
}

const UserList = ({
	users,
	isUserDataFetching,
	isUserFetchSuccess,
	isUserFetchError,
}: UserListProps) => {
	const user = useAppSelector((state) => state.user.user) as IUserPartner;
	const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");
	const isPartnerOfCompany =
		users &&
		user.team_member_info?.partner_companies?.some((userPartnerCompany) =>
			users.some(
				(partnerCompany) =>
					userPartnerCompany.id === partnerCompany.company_id
			)
		);

	const [showAddUser, setShowAddUser] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [sortBy, setSortBy] = useState<{
		value: string;
		label: string;
	} | null>({ value: "", label: "Sort By" });
	const addNewMemberMutation = useAddNewMemberMutation();

	// const initialItems: any[] = users;
	// const [items] = useState<Item[]>(initialItems);
	const [selectedItems, setSelectedItems] = useState<any[]>([]);
	const [, setSelectAll] = useState<boolean>(false);

	// const filteredUsers = users;
	const filteredUsers = users
		?.filter((item) =>
			item?.name?.toLowerCase().includes(searchQuery.toLowerCase())
		)
		.sort((a, b) => {
			if (sortBy?.value === "name") {
				return a.name.localeCompare(b.name);
			} else if (sortBy?.value === "email") {
				return a.email.localeCompare(b.email);
			} else if (sortBy?.value === "role") {
				return a.role.localeCompare(b.role);
			}
			return 0;
		});
	// const toggleSelectAll = () => {
	// 	if (selectAll) {
	// 		setSelectedItems([]);
	// 	} else {
	// 		setSelectedItems(items);
	// 	}
	// 	setSelectAll(!selectAll);
	// };

	const toggleSelectItem = (item: IUser) => {
		if (selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
			setSelectedItems(
				selectedItems.filter(
					(selectedItem) => selectedItem.id !== item.id
				)
			);
		} else {
			setSelectedItems([...selectedItems, item]);
		}
		setSelectAll(false);
	};

	return (
		<Wrapper className="" fetching={isUserDataFetching}>
			{isUserFetchSuccess && user && (
				<>
					<div className="mb-4  flex h-9 w-full gap-2">
						<div className="flex w-full flex-[0.6]">
							<Search
								value={searchQuery}
								onChange={(e) => setSearchQuery(e.target.value)}
								placeholder="Search"
								className="h-9 w-full !bg-white"
							/>
						</div>
						<div className="flex w-full flex-[0.4] gap-2">
							<CustomSelect
								placeholder="Sort by"
								className="w-full min-w-[157px]"
								options={[
									{ value: "", label: "Sort By" },
									{ value: "name", label: "Name" },
									{ value: "email", label: "Email" },
									{ value: "role", label: "Role" }, // Added Role sorting
								]}
								value={sortBy}
								onChange={(selectedOption: any) =>
									setSortBy(selectedOption)
								}
							/>
							{((userRole !== TeamMemberRoles.TeamMember &&
								userRole !== TeamMemberRoles.PartnerManager) ||
								isPartnerOfCompany) && (
								<Button
									variant="default"
									className=" h-full px-8"
									onClick={() => setShowAddUser(true)}
									// disabled={true}
								>
									Add
								</Button>
							)}
						</div>
					</div>
					<ScrollArea className="h-[70vh] w-full">
						<div className="flex flex-col gap-y-4">
							{/* <div className="flex justify-between px-4">
						<div className="flex items-center gap-2 text-[14px]">
							<input
								id="select-all"
								type="checkbox"
								onClick={toggleSelectAll}
								disabled={true}
							/>
							<label
								className={cn("", {
									"text-[#7D7E80]": !selectAll,
								})}
							>
								Select All
							</label>
						</div>
						<TooltipProvider>
							<div className="flex items-center gap-1">
								<Tooltip delayDuration={0}>
									<TooltipTrigger asChild>
										<Button
											variant="outlinecustom"
											// disabled={!selectAll}
											disabled={true}
											className="h-7 px-1.5 py-1.5"
										>
											<TbTrash />
										</Button>
									</TooltipTrigger>
									<TooltipContent side="top" sideOffset={10}>
										Delete
									</TooltipContent>
								</Tooltip>
							</div>
						</TooltipProvider>
					</div> */}

							<div className="flex flex-col gap-2 p-1">
								{filteredUsers?.map((item, index) => (
									<UserListCard
										type={"super_admin"}
										userDetails={item}
										key={index}
										onSelect={() => toggleSelectItem(item)}
										checked={selectedItems.some(
											(selectedItem) =>
												selectedItem.id === item.id
										)}
									/>
								))}
							</div>
						</div>
					</ScrollArea>

					{((userRole !== TeamMemberRoles.TeamMember &&
						userRole !== TeamMemberRoles.PartnerManager) ||
						isPartnerOfCompany) && (
						<Modal
							dialogClassName="!rounded-[30px] bg-[#F5F5F5] !min-w-[600px] pt-12"
							open={showAddUser}
							setOpen={(value) => {
								if (!addNewMemberMutation[1].isLoading)
									return setShowAddUser(value);
							}}
						>
							<>
								<DialogHeader className="absolute left-0 top-0 mx-auto flex w-full rounded-tl-[30px] rounded-tr-[30px] border-b border-b-primary bg-white py-4 text-center">
									<DialogTitle className="text-center text-primary ">
										Add User
									</DialogTitle>
								</DialogHeader>
								<AddUser
									addNewMember={addNewMemberMutation}
									handleClose={() => setShowAddUser(false)}
								/>
							</>
						</Modal>
					)}
				</>
			)}

			{isUserFetchError && <p>Error Fetching partner user data</p>}
		</Wrapper>
	);
};

export default UserList;
