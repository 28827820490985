import { Button } from "@/components/ui/button";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { TeamMember, TeamMemberRoles } from "@/types/team-member";
import { useState } from "react";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router";
import DeleteTeamMember from "./DeleteTeamMember";
import { useAppSelector } from "@/hooks/hooks";
import DeleteGuest from "./DeleteGuest";

interface MemberListCardProps {
	onSelect: () => void;
	checked: boolean;
	item: TeamMember;
}
const MemberListCard = ({ onSelect, item, checked }: MemberListCardProps) => {
	const navigate = useNavigate();
	const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");
	const [editDetails, setEditDetails] = useState(false);
	const business_id = useAppSelector(
		(state) => state?.user?.user?.business_id
	);

	return (
		<TooltipProvider>
			<div className="flex items-center justify-between rounded-[12px] bg-white px-4  py-3 shadow-[0px_0px_5px_0px_rgba(0,0,0,0.25)]">
				{/* <div className="flex gap-4 lg:flex-[0.2]">

					<Avatar
						name={item.name}
						variant="guest"
						className="!text-[16px]"
					/>
				</div> */}
				<div className="flex flex-[0.25] items-center space-x-5">
					<input
						type="checkbox"
						checked={checked}
						onChange={onSelect}
						className=""
					/>
					<p className="text-[18px] leading-[20px] tracking-[-0.1px] text-[#323539]">
						{item.name}
					</p>
				</div>

				<div className="hidden flex-[0.55] items-center justify-between gap-x-4 lg:flex">
					<div className="flex flex-1 items-center space-x-2.5">
						<i className="mgc_mail_line before-text-gray-5 text-[18px]" />
						<small className="text-[12px] leading-[20px] tracking-[-0.1px] text-[#323539]">
							{item.email}
						</small>
					</div>
					{item.phone_number && (
						<div className="flex flex-1 items-center space-x-2.5">
							<i className="mgc_phone_line before-text-gray-5 text-[18px]" />
							<small className="text-[12px] leading-[20px] tracking-[-0.1px] text-[#323539]">
								({item.phone_number.slice(0, -10)}){" "}
								{item.phone_number.slice(-10)}
							</small>
						</div>
					)}

					<div className="flex flex-1 items-center space-x-2.5">
						<i className="mgc_user_security_line before-text-gray-5 text-[18px]" />
						<small className="text-[12px] capitalize leading-[20px] tracking-[-0.1px] text-[#323539]">
							{item.role.toLowerCase().replace("_", " ")}
						</small>
					</div>
				</div>
				<div className="hidden justify-end gap-2 lg:flex">
					{/* Cancel  */}
					{editDetails ? (
						<Tooltip delayDuration={0}>
							<TooltipTrigger asChild>
								<Button
									className="h-7 bg-[#E0E0E0] px-1.5 py-1.5"
									onClick={() => setEditDetails(false)}
								>
									<IoClose />
								</Button>
							</TooltipTrigger>
							<TooltipContent side="top" sideOffset={10}>
								Cancel
							</TooltipContent>
						</Tooltip>
					) : null}
					{userRole !== TeamMemberRoles.TeamMember &&
						userRole !== TeamMemberRoles.PartnerManager && (
							<>
								<Tooltip delayDuration={0}>
									<TooltipTrigger asChild>
										<Button
											variant="outlinecustom"
											className="h-7 px-1.5 py-1.5"
											onClick={() =>
												navigate(
													item.role === "guest"
														? "/admin/team-members/edit-guest/" +
																business_id +
																"/" +
																item.id
														: "edit-team-member/" +
																item.id,
													{
														state: {
															team_member: item,
														},
													}
												)
											}
										>
											<i className="mgc_edit_2_line text-[12px] before:!text-primary" />
										</Button>
									</TooltipTrigger>
									<TooltipContent side="top" sideOffset={10}>
										{editDetails ? "Save" : "Edit"}
									</TooltipContent>
								</Tooltip>
								{item.role === "guest" && (
									<DeleteGuest teamMember={item} />
								)}
								{item.role !== "guest" && (
									<DeleteTeamMember teamMember={item} />
								)}
							</>
						)}
				</div>

				{/* hidden  */}

				{/* <Dialog modal >
					<DialogTrigger asChild>
						<Button variant="ghost" className="lg:hidden">
							<MdMoreVert className="w-5 text-[#D9D9D9] h-5" />
						</Button>
					</DialogTrigger>
					<DialogContent className="flex w-[80%] p-4 shadow-md border border-[#E5E5E7] bg-white rounded-lg max-w-[690px]">
						<EditMember/>
					</DialogContent>
				</Dialog> */}

				{/* Delete Admin Dialog  */}
				{/* <Dialog modal  open={showDeleteAdmin} onOpenChange={setShowDeleteAdmin}>
					<DialogContent className="flex w-fit max-w-[360px] p-4 shadow-md border border-[#E5E5E7] bg-white rounded-lg ">
						<DeleteAdmin name={item.name}/>
					</DialogContent>
				</Dialog> */}

				{/* {hidden} */}
			</div>
		</TooltipProvider>
	);
};

export default MemberListCard;
