import LocationSummary from "@/components/modals/admin/LocationSummary";
import Modal from "@/components/modals/Modal";
import { Button } from "@/components/ui/button";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { useAppSelector } from "@/hooks/hooks";
import { BookingLocation } from "@/types/location";
import { TeamMemberRoles } from "@/types/team-member";
import { ChevronRight } from "lucide-react";
import { MouseEvent, MouseEventHandler, useState } from "react";
import { CiLocationOn } from "react-icons/ci";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { TbTrash } from "react-icons/tb";
import { useNavigate } from "react-router";

interface LocationListCardProps {
	onSelect: () => void;
	checked: boolean;
	location: BookingLocation;
}

const LocationListCard = ({
	onSelect,
	location,
	checked,
}: LocationListCardProps) => {
	const navigate = useNavigate();
	const [showSummary, setShowSummary] = useState(false);
	const user = useAppSelector((state) => state.user.user);
	const handleOpenLocation: MouseEventHandler<HTMLDivElement> = () => {
		navigate(`${location?.id}`);
	};

	const handleOpenSummary = (e: MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		setShowSummary(true);
	};

	return (
		<>
			<TooltipProvider>
				<div
					className="flex cursor-pointer items-center justify-between rounded-[0.5rem] bg-white px-6 py-2 drop-shadow-[0px_1px_5px_rgba(0,0,0,0.1)]"
					onClick={handleOpenLocation}
				>
					{/* Checkbox and partner logo  */}
					<div className="flex flex-1 gap-5">
						<input
							type="checkbox"
							className="accent-primary"
							checked={checked}
							onChange={onSelect}
						/>
						<div className=" flex items-center gap-3">
							<div className="flex h-[65px] w-[65px] items-center justify-center rounded-md border border-[#E8E8E8]">
								<div className="flex size-9 items-center justify-center rounded-full bg-[#C7C7C7]/60">
									<p className="select-none text-lg font-semibold text-white">
										{location?.name
											.split(" ")[0][0]
											.toUpperCase()}
									</p>
								</div>
							</div>
							<div>
								<h1 className="truncate font-bold text-basecolor">
									{location?.name}
								</h1>
								<p className="flex items-center gap-2 truncate text-[10px] font-light text-basecolor">
									<CiLocationOn />
									{location?.address ?? "N/A"}
								</p>
							</div>
						</div>
					</div>

					{/* More Details  */}
					{/* <div className="flex flex-[0.4] justify-between ">
						<div className="flex items-center gap-4 pr-8">
							<span className="flex items-center gap-1.5 rounded-full bg-primary/5 px-3 py-2 text-[10px]">
								<i className="mgc_box_line before-text-primary text-sm" />

								<span className="text-[#506F8B]">Rooms</span>
								<p className="text-light font-semibold text-basecolor">
									{location?.total_rooms ?? "N/A"}
								</p>
							</span>
						</div>
						<div className="flex w-full items-center gap-2 border-l pl-2">
							<span className="flex items-center gap-2 text-sm font-light text-primary">
								<FaUserShield />
								<p className="text-[10px] text-[#6B7280]">
									Location Manager
								</p>
							</span>
							<p className="text-sm font-light text-primary">
								{location?.manager ?? "N/A"}
							</p>
							<Tooltip delayDuration={0}>
								<TooltipTrigger asChild>
									<span className="flex w-fit items-center justify-center rounded-full bg-[#F9F9F9] p-1 text-xs text-primary">
										<MdMailOutline className="text-[10px]" />
									</span>
								</TooltipTrigger>
								<TooltipContent side="top" sideOffset={10}>
									{location?.email ?? "N/A"}
								</TooltipContent>
							</Tooltip>

							<Tooltip delayDuration={0}>
								<TooltipTrigger asChild>
									<span className="flex w-fit items-center justify-center rounded-full bg-[#F9F9F9] p-1 text-xs text-primary">
										<FaPhone className="text-[10px]" />
									</span>
								</TooltipTrigger>
								<TooltipContent side="top" sideOffset={10}>
									{location?.phone_number ?? "N/A"}
								</TooltipContent>
							</Tooltip>
						</div>
					</div> */}

					{/* Action buttons  */}
					<div className="">
						<div
							className="hidden justify-end gap-1 space-x-1 border-l lg:flex "
							onClick={(e) => e.stopPropagation()}
						>
							<Tooltip delayDuration={0}>
								<TooltipTrigger asChild>
									<Button
										variant="outlinecustom"
										className="h-7 px-1.5 py-1.5"
										onClick={(
											e: MouseEvent<HTMLButtonElement>
										) => handleOpenSummary(e)}
									>
										<IoIosInformationCircleOutline />
									</Button>
								</TooltipTrigger>
								<TooltipContent side="top" sideOffset={10}>
									Information
								</TooltipContent>
							</Tooltip>
							{user &&
								user.role !== TeamMemberRoles.TeamMember &&
								user.role !==
									TeamMemberRoles.PartnerManager && (
									<>
										<Tooltip delayDuration={0}>
											<TooltipTrigger asChild>
												<Button
													variant="outlinecustom"
													className="h-7 px-1.5 py-1.5"
													onClick={() =>
														navigate(
															"edit-location/" +
																location.id
														)
													}
												>
													<MdEdit />
												</Button>
											</TooltipTrigger>
											<TooltipContent
												side="top"
												sideOffset={10}
											>
												Edit
											</TooltipContent>
										</Tooltip>

										<Tooltip delayDuration={0}>
											<TooltipTrigger asChild>
												<Button
													variant="outlinecustom"
													className="h-7 px-1.5 py-1.5"
													onClick={() =>
														setShowSummary(true)
													}
												>
													<TbTrash />
												</Button>
											</TooltipTrigger>
											<TooltipContent
												side="top"
												sideOffset={10}
											>
												Delete
											</TooltipContent>
										</Tooltip>
									</>
								)}
							<Tooltip delayDuration={0}>
								<TooltipTrigger asChild>
									<Button
										variant="ghost"
										className="h-7 px-1.5 py-1.5 text-[#858C95]"
										onClick={() =>
											navigate(`${location.id}`)
										}
									>
										<ChevronRight />
									</Button>
								</TooltipTrigger>
								<TooltipContent side="top" sideOffset={10}>
									More
								</TooltipContent>
							</Tooltip>
						</div>
					</div>
				</div>
			</TooltipProvider>
			<Modal
				dialogClassName="!rounded-[12px] max-w-2xl p-0 bg-white w-full"
				open={showSummary}
				setOpen={setShowSummary}
			>
				<LocationSummary data={location} setOpen={setShowSummary} />
			</Modal>
		</>
	);
};

export default LocationListCard;
