import NameLetter from "@/components/NameLetter";
import LoadingSpinner from "@/components/loaders/Spinner";
import Modal from "@/components/modals/Modal";
import LocationSummary from "@/components/modals/admin/LocationSummary";
import { Button } from "@/components/ui/button";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { useAppSelector } from "@/hooks/hooks";
import { useGetLocationQuery } from "@/redux/features/api/locationEndpoints";
import { BookingLocation } from "@/types/location";
import { TeamMemberRoles } from "@/types/team-member";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";

const LocationInfoOverview: React.FC = () => {
	const { user } = useAppSelector((state) => state.user);
	const { location_id } = useParams();
	const navigate = useNavigate();
	const { data, isSuccess, isLoading } = useGetLocationQuery({
		user_id: user?.business_id ?? 0,
		location_id: parseInt(location_id ?? ""),
	});
	const [showSummary, setShowSummary] = useState<boolean>(false);
	const Location = data?.data as BookingLocation;
	if (isLoading) return <LoadingSpinner />;

	return (
		<>
			<div className="flex w-full items-center justify-between rounded-md bg-white p-4 drop-shadow-[0px_1px_5px_rgba(16,24,40,0.15)]">
				<div className="flex justify-center">
					{isLoading && <LoadingSpinner />}
				</div>
				{!isLoading && isSuccess && (
					<TooltipProvider>
						<div className="flex flex-[0.3] items-center gap-2">
							<NameLetter
								name={Location?.name
									.split(" ")[0][0]
									.toUpperCase()}
							/>
							<div>
								<h1>{Location?.name ?? ""}</h1>
								<p className="flex items-center gap-2 text-[10px] font-light">
									<i className="mgc_location_line before:!text-primary" />{" "}
									{Location?.address ?? "0"}
								</p>
							</div>
						</div>
						<div className="flex flex-[0.7] items-center justify-between">
							<div className="flex items-center gap-4">
								<span className="flex items-center gap-1 rounded-full bg-primary/10 bg-opacity-5 px-3 py-2 text-sm">
									<i className="mgc_user_security_line text-[18px] before:!text-[#858C95]" />
									<span className="text-xs text-[#506F8B]">
										Admins
									</span>
									<p className="text-light text-[14px] font-semibold text-basecolor">
										{Location?.total_admins ?? "0"}
									</p>
								</span>
								<span className="flex items-center gap-1 rounded-full bg-primary/10 bg-opacity-5 px-3 py-2 text-xs">
									<i className="mgc_box_line text-[18px] before:!text-[#858C95]" />
									<span className="text-xs text-[#506F8B]">
										Spaces
									</span>
									<p className="text-light text-sm font-semibold text-basecolor">
										{Location?.total_rooms ?? "0"}
									</p>
								</span>
							</div>

							<div className="flex items-center gap-2 border-l border-r px-4">
								<span className="flex gap-2 text-sm font-light text-primary">
									<i className="mgc_user_security_line mt-1 text-[12px] before:!text-primary" />
									<div>
										<p className="text-[10px] text-[#6B7280]">
											Location Manager
										</p>
										<p className="text-sm font-light text-primary">
											{Location?.admin.name ?? ""}
										</p>
									</div>
								</span>

								<Tooltip delayDuration={0}>
									<TooltipTrigger asChild>
										<span className="flex items-center justify-center rounded-full bg-[#F9F9F9] p-2 text-sm">
											<i className="mgc_mail_line text-[12px] before:!text-primary xs:text-[13px]" />
										</span>
									</TooltipTrigger>
									<TooltipContent side="top" sideOffset={4}>
										{Location.admin?.email}
									</TooltipContent>
								</Tooltip>
								<Tooltip delayDuration={0}>
									<TooltipTrigger asChild>
										<span className="flex items-center justify-center rounded-full bg-[#F9F9F9] p-2 text-sm text-primary">
											<i className="mgc_phone_line text-[12px] before:!text-primary xs:text-[13px]" />
										</span>
									</TooltipTrigger>
									{Location.admin.phone_number && (
										<TooltipContent
											side="top"
											sideOffset={4}
										>
											{Location.admin.phone_number}
										</TooltipContent>
									)}
								</Tooltip>
							</div>

							<div className="hidden justify-end gap-2 lg:flex lg:flex-[0.2] ">
								<Tooltip delayDuration={0}>
									<TooltipTrigger asChild>
										<Button
											variant="outlinecustom"
											className="h-7 px-1.5 py-1.5"
											onClick={() => setShowSummary(true)}
										>
											<i className="mgc_information_line before:!text-primary" />
										</Button>
									</TooltipTrigger>
									<TooltipContent side="top" sideOffset={10}>
										Information
									</TooltipContent>
								</Tooltip>
								{user &&
									user.role !== TeamMemberRoles.TeamMember &&
									user.role !==
										TeamMemberRoles.PartnerManager && (
										<>
											<Tooltip delayDuration={0}>
												<TooltipTrigger asChild>
													<Button
														variant="outlinecustom"
														className="h-7 px-1.5 py-1.5"
														onClick={() =>
															navigate(
																`/admin/location-management/edit-location/${Location.id}`
															)
														}
													>
														<i className="mgc_settings_3_line before:!text-primary" />
													</Button>
												</TooltipTrigger>
												<TooltipContent
													side="top"
													sideOffset={10}
												>
													Settings
												</TooltipContent>
											</Tooltip>
											<Tooltip delayDuration={0}>
												<TooltipTrigger asChild>
													<Button
														variant="outlinecustom"
														className="h-7 px-1.5 py-1.5"
													>
														<i className="mgc_delete_2_line before:!text-primary" />
													</Button>
												</TooltipTrigger>
												<TooltipContent
													side="top"
													sideOffset={10}
												>
													Delete
												</TooltipContent>
											</Tooltip>
										</>
									)}
							</div>
						</div>
					</TooltipProvider>
				)}
			</div>
			<Modal
				dialogClassName="!rounded-[12px] max-w-2xl p-0 bg-white w-full"
				open={showSummary}
				setOpen={setShowSummary}
			>
				<LocationSummary data={Location} setOpen={setShowSummary} />
			</Modal>
		</>
	);
};

export default LocationInfoOverview;
