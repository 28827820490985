import { TeamMemberRoles } from "@/types/team-member";
import { IUser, IUserPartner } from "@/types/user";
import {
	Navigate,
	Outlet,
	useLocation,
	useSearchParams,
} from "react-router-dom";

const LoggedIn: React.FC<{ user: IUser | IUserPartner | null }> = ({
	user,
}) => {
	const location = useLocation();
	const [searchParams] = useSearchParams();

	if (
		user &&
		(user?.role === TeamMemberRoles.PartnerSuperAdmin ||
			user?.role === TeamMemberRoles.PartnerAdmin)
	) {
		//rework on this later to redirect to the correct page
		const to =
			searchParams.get("redirect") &&
			searchParams.get("redirect")?.startsWith("/partner")
				? searchParams.get("redirect") ?? ""
				: "/partner/rooms";
		return <Navigate to={to} state={{ from: location }} replace />;
	} else if (
		user &&
		(user.role === TeamMemberRoles.SuperAdmin ||
			user.role === TeamMemberRoles.BusinessAdmin ||
			user.role === TeamMemberRoles.TeamMember ||
			user.role === TeamMemberRoles.PartnerManager)
	) {
		const to =
			searchParams.get("redirect") &&
			searchParams.get("redirect")?.startsWith("/admin")
				? searchParams.get("redirect") ?? ""
				: "/admin/bookings/management";
		return <Navigate to={to} state={{ from: location }} replace />;
	}

	return <Outlet />;
};

export default LoggedIn;
