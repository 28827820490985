import { useAppSelector } from "@/hooks/hooks";
import { useGetLocationAnalyticsQuery } from "@/redux/features/api/locationEndpoints";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

const Summary: React.FC = () => {
	const businessId =
		useAppSelector((state) => state.user.user?.business_id) ?? 0;
	const queryData = useAppSelector((state) => state.analytics.query_data);

	const getAdminLocationsQuery = useGetLocationAnalyticsQuery({
		user_id: businessId,
		query_data: queryData,
	});

	const createChartData = (
		name: string,
		value: number | undefined,
		fillColor: string
	) => ({ name, value, fillColor });

	const utilizationRate = [
		createChartData(
			"Available",
			getAdminLocationsQuery?.data?.data.summary.utilization_rate
				.total_available,
			"#C5D0CE"
		),
		createChartData(
			"Occupied",
			getAdminLocationsQuery?.data?.data.summary.utilization_rate
				.total_occupied,
			"#1E8A7C"
		),
	];

	const completionRate = [
		createChartData(
			"Cancelled",
			getAdminLocationsQuery?.data?.data.summary.completion
				.total_cancelled_bookings,
			"#D4DDE3"
		),
		createChartData(
			"Completed",
			getAdminLocationsQuery?.data?.data.summary.completion
				.total_completed_bookings,
			"#005893"
		),
	];

	const cancellationRate = [
		createChartData(
			"By Admin",
			getAdminLocationsQuery?.data?.data.summary.cancelled_summary
				.cancelled_by_admin,
			"#F9E1E2"
		),
		createChartData(
			"By Partner",
			getAdminLocationsQuery?.data?.data.summary.cancelled_summary
				.cancelled_by_partner,
			"#D8434B"
		),
	];

	const calculateTotal = (part: number, whole: number) =>
		(part / (whole + part)) * 100;

	const dateRange =
		dayjs(queryData.start_date).format("D MMMM, YYYY") +
		" - " +
		dayjs(queryData.end_date).format("D MMMM, YYYY");

	return (
		<div className="flex w-full flex-col space-y-4 rounded-[20px] bg-[#F8F9FB] p-3">
			<div>
				<h3 className="text-lg font-semibold text-[#111827]">
					Summary
				</h3>
				<p className="text-sm text-[#374151]">{dateRange}</p>
			</div>
			<div className="flex items-center justify-between space-x-4">
				<SummaryCard
					title="Total Reservations"
					data={
						getAdminLocationsQuery?.data?.data.total_bookings.toLocaleString() ??
						""
					}
					desc="Reservations"
				/>
				<SummaryCard
					title="Avg. Time Per Reservation"
					data={
						getAdminLocationsQuery?.data?.data.summary
							.average_time_per_meeting_in_minutes
							? getAdminLocationsQuery?.data?.data.summary.average_time_per_meeting_in_minutes.toFixed(
									0
								)
							: "0"
					}
					desc="Minutes"
				/>
				<DonutChart
					title="Utilisation"
					data={utilizationRate}
					total={calculateTotal(
						getAdminLocationsQuery?.data?.data.summary
							.utilization_rate.total_occupied ?? 0,
						getAdminLocationsQuery?.data?.data.summary
							.utilization_rate.total_available ?? 0
					)}
					totalType="percent"
				/>
				<DonutChart
					title="Completion"
					data={completionRate}
					total={calculateTotal(
						getAdminLocationsQuery?.data?.data.summary.completion
							.total_completed_bookings ?? 0,
						getAdminLocationsQuery?.data?.data.summary.completion
							.total_cancelled_bookings ?? 0
					)}
					totalType="percent"
				/>
				<DonutChart
					title="Cancellation"
					data={cancellationRate}
					total={calculateTotal(
						getAdminLocationsQuery?.data?.data.summary
							.cancelled_summary.cancelled_by_partner ?? 0,
						getAdminLocationsQuery?.data?.data.summary
							.cancelled_summary.cancelled_by_admin ?? 0
					)}
					totalType="percent"
				/>
			</div>
		</div>
	);
};

export default Summary;

const DonutChart: React.FC<{
	title: string;
	data: {
		name: string;
		value: number | undefined;
		fillColor: string;
	}[];
	total: number;
	totalType: "visitors" | "minutes" | "percent";
}> = ({ title, data, total, totalType }) => {
	return (
		<div className="flex h-[254px] flex-1 flex-col justify-between space-y-4 self-stretch rounded-lg bg-white px-2.5 pb-4 pt-5">
			<p className="text-center font-manrope text-sm font-extrabold tracking-[0.5px] text-[#525D6A]">
				{title}
			</p>

			<div className="flex flex-col space-y-4">
				<div className="relative">
					<ResponsiveContainer width="100%" height={148}>
						<PieChart>
							<Pie
								data={data}
								cx="50%"
								cy="50%"
								innerRadius={50}
								outerRadius={68}
								height={120}
								startAngle={-270}
								paddingAngle={0}
								dataKey="value"
							>
								{data.map((entry, index) => (
									<Cell
										key={`cell-${index}`}
										fill={entry.fillColor}
									/>
								))}
							</Pie>

							<Tooltip content={<CustomTooltip />} />
						</PieChart>
					</ResponsiveContainer>
					<div className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center space-y-[-4px] leading-[120%] tracking-[0.41px] text-[#323539]">
						<h3 className="font-semibold">
							{isNaN(total) ? 0 : total.toFixed(1)}
							{totalType === "percent" && "%"}
						</h3>
						<span className="text-xs">
							{totalType === "minutes"
								? "minutes"
								: totalType === "visitors" && "visitors"}
						</span>
					</div>
				</div>
				<RenderLegend payload={data} />
			</div>
		</div>
	);
};

const CustomTooltip: React.FC<any> = ({ active, payload }) => {
	const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

	useEffect(() => {
		const handleMouseMove = (event: MouseEvent) => {
			setTimeout(() => {
				setMousePosition({
					x: event.offsetX,
					y: event.offsetY,
				});
			}, 50);
		};

		window.addEventListener("mousemove", handleMouseMove);

		return () => {
			window.removeEventListener("mousemove", handleMouseMove);
		};
	}, []);

	if (active && payload && payload.length) {
		return (
			<>
				<div
					style={{
						position: "absolute",
						top: mousePosition.y,
						left: mousePosition.x,
						transform: "translate(-50%, -100%)",
						pointerEvents: "none",
						zIndex: 1000,
					}}
				>
					<div className="relative mb-3 flex w-[103px] space-x-1 rounded-lg bg-white px-[7px] py-[11px] text-black shadow-lg drop-shadow-md">
						<i
							className="block size-2 rounded-[2px]"
							style={{
								backgroundColor: payload[0].payload.fillColor,
							}}
						/>

						<div className="flex flex-col space-y-1">
							<p className="text-xs font-medium leading-[8px]">
								{payload[0].name}
							</p>
							<p className="text-sm font-bold">
								{payload[0].value}
							</p>
						</div>
						<svg
							className="absolute left-[46.5%] top-full -translate-x-[50%] translate-y-0"
							width="7"
							height="7"
							viewBox="0 0 7 7"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M0,0 L7,0 L3.5,7 Z"
								fill="white"
								stroke="white"
							/>
						</svg>
					</div>
				</div>
			</>
		);
	}

	return null;
};

const RenderLegend: React.FC<{
	payload: { name: string; fillColor: string }[];
}> = ({ payload }) => {
	return (
		<>
			<ul className="mt-4 flex items-center justify-center space-x-4">
				{payload.map((entry, index) => (
					<li key={index} className="flex items-center space-x-1">
						<i
							className="block size-2 rounded-[2px]"
							style={{
								backgroundColor: entry.fillColor,
							}}
						/>
						<span className="text-xs text-[#323539]">
							{entry.name}
						</span>
					</li>
				))}
			</ul>
		</>
	);
};

const SummaryCard: React.FC<{
	title: string;
	desc: string;
	data: string;
}> = ({ title, desc, data }) => {
	return (
		<div className="flex h-[254px] flex-1 flex-col justify-between space-y-4 self-stretch rounded-lg bg-white px-2.5 pb-4 pt-5">
			<p className="text-center font-manrope text-sm font-extrabold tracking-[0.5px] text-[#525D6A]">
				{title}
			</p>
			<div className="flex flex-1 flex-col items-center justify-center space-y-5">
				<h2 className="text-[42px] font-semibold leading-[50.4px] tracking-[0.41px] text-[#323539]">
					{data}
				</h2>
				<h3 className="text-[22px] font-semibold leading-[20px] tracking-[-0.1px] text-[#6B7280]  md:text-center">
					{desc}
				</h3>
			</div>
		</div>
	);
};
