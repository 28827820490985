import CustomSelect from "@/components/common/CustomShadSelect";
import Search from "@/components/input/Search";
import Loader from "@/components/Loader/Loader";
import { Button } from "@/components/ui/button";
import { useAppSelector } from "@/hooks/hooks";
import { useGetAllGuestsQuery } from "@/redux/features/api/teamMemberEndpoints";
import { TeamMember, TeamMemberRoles } from "@/types/team-member";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import MemberListCard from "./MemberListCard";

const GuestList: React.FC = () => {
	const userId = useAppSelector((state) => state!.user!.user!.business_id);
	const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");
	const getAllTeamMembersQuery = useGetAllGuestsQuery(userId);

	const navigate = useNavigate();

	const [items, setItems] = useState<TeamMember[]>(
		getAllTeamMembersQuery?.data?.data ?? []
	);
	const [selectedItems, setSelectedItems] = useState<TeamMember[]>([]);
	const [, setSelectAll] = useState<boolean>(false);
	const [selectedSortBy, setSelectedSortBy] = useState("asc");
	const [searchQuery, setSearchQuery] = useState("");

	// const toggleSelectAll = () => {
	// 	if (selectAll) {
	// 		setSelectedItems([]);
	// 	} else {
	// 		setSelectedItems(items);
	// 	}
	// 	setSelectAll(!selectAll);
	// };

	const handleSortChange = (sortOrder: "asc" | "dsc") => {
		setSelectedSortBy(sortOrder);
		const sortedData = sortGuestMembersByName([...items], sortOrder);
		setItems(sortedData);
	};

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const query = e.target.value;
		setSearchQuery(query);
		setItems((prevData) =>
			e.target.value.length === 0
				? getAllTeamMembersQuery?.data?.data ?? []
				: prevData.filter(
						(item) =>
							(item.name &&
								item.name
									.toLocaleLowerCase()
									.includes(e.target.value.toLowerCase())) ||
							(item.email &&
								item.email
									.toLocaleLowerCase()
									.includes(e.target.value.toLowerCase()))
					)
		);
	};

	const toggleSelectItem = (item: TeamMember) => {
		if (selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
			setSelectedItems(
				selectedItems.filter(
					(selectedItem) => selectedItem.id !== item.id
				)
			);
		} else {
			setSelectedItems([...selectedItems, item]);
		}
		setSelectAll(false);
	};

	useEffect(() => {
		if (getAllTeamMembersQuery.data)
			setItems(getAllTeamMembersQuery?.data?.data);
	}, [getAllTeamMembersQuery.isSuccess, getAllTeamMembersQuery.data]);

	return (
		<>
			<div className="relative flex h-full flex-1 flex-col gap-y-3">
				<div className="flex w-full gap-2">
					<Search
						value={searchQuery}
						onChange={handleSearch}
						placeholder="Search"
						className="h-9 flex-1 !bg-white"
						inputClassName="h-9"
					/>

					<CustomSelect
						placeholder="Sort by"
						className="h-9 w-fit flex-1"
						value={selectedSortBy}
						options={[
							{ value: "asc", label: "Sort (A-Z)" },
							{ value: "dsc", label: "Sort (Z-A)" },
						]}
						onChange={(value) => {
							handleSortChange(value as "asc" | "dsc");
						}}
					/>
					{userRole !== TeamMemberRoles.TeamMember &&
						userRole !== TeamMemberRoles.PartnerManager && (
							<Button
								variant="default"
								className=" h-full px-8"
								onClick={() => navigate("add-guest")}
							>
								Add
							</Button>
						)}
				</div>
				<div className="flex h-full flex-1 flex-col gap-y-4">
					{/* <div className="ml-9 flex justify-between">
						<div className="flex items-center gap-2 text-[14px]">
							<input
								id="select-all"
								type="checkbox"
								onClick={toggleSelectAll}
							/>

							<label
								className={cn("", {
									"text-[#7D7E80]": !selectAll,
								})}
							>
								Select All
							</label>
						</div>
						<TooltipProvider>
							<div className="flex items-center gap-1">
								<Tooltip delayDuration={0}>
									<TooltipTrigger asChild>
										<Button
											variant="outlinecustom"
											disabled={!selectAll}
											className="h-7 px-1.5 py-1.5"
										>
											<TbTrash />
										</Button>
									</TooltipTrigger>
									<TooltipContent side="top" sideOffset={10}>
										Delete All
									</TooltipContent>
								</Tooltip>
							</div>
						</TooltipProvider>
					</div> */}

					<div className="flex flex-col space-y-2 px-1">
						{getAllTeamMembersQuery.data &&
							items &&
							items.map((item, index) => (
								<MemberListCard
									key={index}
									item={item}
									onSelect={() => toggleSelectItem(item)}
									checked={selectedItems.some(
										(selectedItem) =>
											selectedItem.id === item.id
									)}
								/>
							))}

						{getAllTeamMembersQuery.data &&
							items &&
							items.length === 0 && (
								<p className="text-center text-[18px] leading-[20px] py-10 tracking-[-0.1px] text-[#323539]">
									No Guests Found 🤕
								</p>
							)}

						{getAllTeamMembersQuery.isLoading && (
							<Loader size={28} />
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default GuestList;

const sortGuestMembersByName = (
	teamMembers: TeamMember[],
	sortOrder: "asc" | "dsc" = "asc"
): TeamMember[] => {
	return teamMembers.sort((a, b) => {
		if (sortOrder === "asc") {
			return a.name.localeCompare(b.name);
		} else {
			return b.name.localeCompare(a.name);
		}
	});
};
