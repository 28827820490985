import { HiOutlineTrash } from "react-icons/hi2";
import { DialogClose } from "../../ui/dialog";
import { Button } from "../../ui/button";
import { LoadingOutlined } from "@ant-design/icons";

interface DeletePartnerProps {
	name: string;
	handleDelete: () => void;
	isLoading: boolean;
}
const DeletePartner: React.FC<DeletePartnerProps> = ({
	name,
	handleDelete,
	isLoading,
}) => {
	return (
		<div className="flex items-start gap-2 pr-4">
			<div className="flex flex-[0.1] pt-1">
				<HiOutlineTrash className="text-lg text-[#C8322B]" />
			</div>
			<div className="flex flex-grow flex-col gap-y-2">
				<h1 className="text-[22px] font-semibold text-[#323539]">
					Delete Partner
				</h1>
				<p>
					Are you sure you want to remove{" "}
					<span className="font-bold text-[#6D748D]">
						{name || "anna"}{" "}
					</span>
					as a partner?
				</p>
				<p>
					They will no longer be able to reserve spaces and manage
					meetings.
				</p>
				<div className="flex w-full items-center gap-x-1.5 pt-2">
					<DialogClose className="w-full rounded-md border border-primary bg-transparent px-4 py-2 text-primary">
						Cancel
					</DialogClose>
					<Button
						className=" w-full rounded-md px-4 py-2 text-white"
						variant="destructive"
						onClick={handleDelete}
					>
						{isLoading ? (
							<LoadingOutlined style={{ fontSize: 24 }} spin />
						) : (
							"Delete"
						)}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default DeletePartner;
