import useCustomToast from "@/components/CustomToast";
import NameLetter from "@/components/NameLetter";
import { useAppSelector } from "@/hooks/hooks";
import { cn } from "@/lib/utils";
import { useDeleteLocationMutation } from "@/redux/features/api/locationEndpoints";
import { BookingLocation } from "@/types/location";
import { Dispatch, SetStateAction, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "../../ui/button";
import { DialogClose } from "../../ui/dialog";
import DeleteModal from "../DeleteModal";
import { TeamMemberRoles } from "@/types/team-member";

const LocationSummary: React.FC<{
	data: BookingLocation;
	setOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ data, setOpen }) => {
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const user = useAppSelector((state) => state.user.user);
	const business_id =
		useAppSelector((state) => state.user.user?.business_id) ?? 0;
	const customToast = useCustomToast();

	const [deleteLocation, deleteLocationMutation] =
		useDeleteLocationMutation();

	const handleDeleteLocation = async () => {
		if (
			user &&
			(user.role === TeamMemberRoles.TeamMember ||
				user.role === TeamMemberRoles.PartnerManager)
		)
			return customToast("You are not allowed to perform this action", {
				id: "delete-location",
				type: "error",
			});
		try {
			await deleteLocation({ business_id, location_id: data.id });
			customToast("Location deleted successfully", {
				id: "delete-location",
				type: "success",
			});
			setOpen(false);
		} catch (error) {
			customToast("Could not delete location", {
				id: "delete-location",
				type: "error",
			});
		}
	};

	return (
		<>
			<div className="flex w-full flex-col items-start gap-2">
				<div className="flex  w-full items-center justify-between rounded-tl-[30px] rounded-tr-[30px] bg-white px-7 py-4">
					<div className="flex items-center gap-2">
						<NameLetter
							name={data?.name.split(" ")[0][0].toUpperCase()}
						/>

						<div>
							<h1 className="font-bold text-basecolor">
								{data?.name ?? "N/A"}
							</h1>
							<p className="flex items-center gap-2 text-[10px] font-light text-basecolor">
								<i className="mgc_location_line before:!text-basecolor" />
								{data?.address ?? "N/A"}
							</p>
						</div>
					</div>
					<div className="flex flex-col gap-2 pr-4">
						<div className="flex items-center gap-1 text-xs">
							<i className="mgc_phone_line text-[12px] before:!text-[#323539] xs:text-[13px]" />
							<p className="text-[#6B7280]">
								Phone:{" "}
								<span className="text-[#323539]">
									{data?.phone_number ?? "N/A"}
								</span>
							</p>
						</div>
						<div className="flex items-center gap-1 text-xs">
							<i className="mgc_user_security_line text-[12px] before:!text-[#323539] xs:text-[13px]" />
							<div className="flex items-center gap-1 text-xs">
								<p className="text-[#6B7280]">
									Admin:{" "}
									<span className="text-[#323539]">
										{data?.admin.name ?? "N/A"}
									</span>{" "}
								</p>
								<span className="flex w-fit items-center justify-center rounded-full bg-[#F9F9F9] p-1 text-xs text-primary">
									<a
										href={`${data?.admin?.email ? "mailto:info@migranium.com" : null}`}
									>
										<i className="mgc_mail_line before:!text-primary" />
									</a>
								</span>
								<a
									href={"tel:" + data?.phone_number}
									className="flex size-[24px] items-center justify-center rounded-full bg-[#F9F9F9]"
								>
									<i className="mgc_phone_line before:!text-primary" />
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className="flex w-full flex-col gap-y-2 rounded-bl-[30px] rounded-br-[30px] bg-[#F5F5F5] px-7 pb-6 pt-4">
					<div className="flex flex-col gap-y-4 py-3">
						<div className="flex flex-col gap-y-3">
							<h1 className="text-xs font-semibold">General</h1>
							<div className="flex gap-3 ">
								{/* <SummaryPlaceholder
									title="Admin"
									icon={<FaUserShield />}
									value={
										data?.total_admins
											? data?.total_admins.toString()
											: "N/A"
									}
								/> */}
								<SummaryPlaceholder
									title="Space"
									value={
										data?.total_rooms
											? data?.total_rooms.toString()
											: "N/A"
									}
									icon="mgc_group_2_line"
								/>
								<SummaryPlaceholder
									title="Amenities"
									value={
										data?.amenities &&
										data?.amenities.length
											? data?.amenities.length.toString()
											: "N/A"
									}
									icon="mgc_group_2_line"
								/>
							</div>
						</div>
						<div className="flex flex-col gap-y-3">
							<h1 className="text-xs font-semibold">
								Total Reservations
							</h1>
							<div className="flex gap-3 ">
								<SummaryPlaceholder
									title="Booked"
									value={
										data?.total_bookings
											? data?.total_bookings.toString()
											: "0"
									}
								/>
								<SummaryPlaceholder
									title="Cancelled"
									value={
										data?.total_cancelled_bookings
											? data?.total_cancelled_bookings.toString()
											: "0"
									}
								/>
								{/* <SummaryPlaceholder
									title="Room Used"
									value={
										data?.total_rooms_used
											? data?.total_rooms_used.toString()
											: "N/A"
									}
								/>
								<SummaryPlaceholder
									title="Rescheduled"
									value={
										data?.total_rescheduled_bookings
											? data?.total_rescheduled_bookings.toString()
											: "N/A"
									}
								/> */}
							</div>
						</div>
					</div>
					<div className="flex w-full items-center justify-between gap-x-1.5 pt-2">
						{user &&
							user.role !== TeamMemberRoles.TeamMember &&
							user.role !== TeamMemberRoles.PartnerManager && (
								<Button
									className="h-9 w-fit rounded-md bg-transparent px-6"
									variant="outlinecustom"
									onClick={(e) => {
										e.preventDefault();
										setShowDeleteModal(true);
									}}
								>
									<div className="flex items-center space-x-2">
										<i className="mgc_delete_2_line text-[14px] before:!text-primary" />
										<p>Delete Location</p>
									</div>
								</Button>
							)}

						<div className="flex gap-2">
							<DialogClose
								className="h-9 w-fit rounded-md border border-primary bg-transparent px-4 text-primary"
								disabled={deleteLocationMutation.isLoading}
							>
								Close
							</DialogClose>
							{user &&
								user.role !== TeamMemberRoles.TeamMember &&
								user.role !==
									TeamMemberRoles.PartnerManager && (
									<Link
										to={
											"/admin/location-management/edit-location/" +
											data.id
										}
									>
										<Button
											className="h-9 w-fit rounded-md px-6"
											disabled={
												deleteLocationMutation.isLoading
											}
										>
											Edit
										</Button>
									</Link>
								)}
						</div>
					</div>
				</div>
			</div>
			<DeleteModal
				showDeleteModal={showDeleteModal}
				setShowDeleteModal={setShowDeleteModal}
				title={"Delete Location"}
				content={
					<div className="mb-4 mt-1 flex flex-col space-y-3 text-[#6D748D]">
						<p>
							Are you sure you want to delete{" "}
							<span className="font-semibold capitalize">
								{data.name} ?
							</span>
						</p>
						<p>
							This location and it's associated information will
							be deleted.
						</p>
					</div>
				}
				isLoading={deleteLocationMutation.isLoading}
				isDisabled={deleteLocationMutation.isLoading}
				handleDelete={handleDeleteLocation}
			/>
		</>
	);
};

export default LocationSummary;

export const SummaryPlaceholder: React.FC<{
	icon?: string;
	title: string;
	value: string;
}> = ({ icon, title, value }) => {
	return (
		<div
			className={cn(
				"flex items-center gap-1 rounded-full bg-white px-2.5 py-2 text-xs",
				{
					"px-3": icon,
				}
			)}
		>
			{icon && (
				<i className={"text-[12px] before:!text-[#323539] " + icon} />
			)}

			<p className="font-light text-[#506F8B]">
				{title} {icon ? "" : ": "}
			</p>
			<p className="font-semibold">{value}</p>
		</div>
	);
};
