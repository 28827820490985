import CapacityHolder from "@/components/common/CapacityHolder";
import RequestIsLoading from "@/components/Loader/RequestIsLoading";
import GuestMeetingDetailsCard from "@/components/partner/GuestMeetingDetailsCard";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion";
import { Badge } from "@/components/ui/badge";
import { useGetGuestDetailQuery } from "@/redux/features/api/adminEndpoints";
import { MeetingStatus } from "@/services/mockData";
import { useQueryParams } from "@/services/utilities";
import { PiBuildings } from "react-icons/pi";
import { useParams } from "react-router";

const MeetingInvitation = () => {
	const query = useQueryParams();
	const { meeting_id } = useParams();
	const status = (query.get("status") as MeetingStatus) || "invited";
	const getGuestDetailQuery = useGetGuestDetailQuery({
		meeting_id: meeting_id ?? "",
	});

	// Dont forget to write logic for the status when it does not exist in query
	//  and the status is meant to come from the request on pageload

	return (
		<>
			{!getGuestDetailQuery.isLoading && getGuestDetailQuery.data ? (
				<div className="flex flex-col space-y-8 ">
					<div className="bg-primary py-4"></div>
					<div className="px-4 lg:px-6 ">
						<h1 className="text-[22px] font-semibold text-[#323539]">
							Invitation from:{" "}
							{
								getGuestDetailQuery.data?.data.room_booking.host
									.name
							}
						</h1>
					</div>

					<div className="flex flex-col gap-x-20 px-4 pb-4 md:flex-row lg:px-12 ">
						<div className="w-full ">
							{(status === "accepted" ||
								status === "invited" ||
								status === "declined") && (
								<section>
									<div className="mb-4 border-b-[0.5px] border-b-[#858C95] pb-3">
										<h1 className="text-xl font-semibold text-[#323539]">
											{
												getGuestDetailQuery.data?.data
													.room_booking.room.location
													.name
											}
										</h1>
										<p className="flex items-center gap-1 text-[14px] text-[#6D748D]">
											<PiBuildings />{" "}
											{
												getGuestDetailQuery.data?.data
													.room_booking.room.location
													.address
											}
										</p>
									</div>
								</section>
							)}
							<section className="flex items-center justify-between">
								<div>
									<h1 className="text-lg font-bold text-[#323539]">
										{
											getGuestDetailQuery.data?.data
												.room_booking.room.name
										}
									</h1>
									<p className="text-[10px]">
										{/* Building <b>A</b> | */}{" "}
										{
											getGuestDetailQuery.data?.data
												.room_booking.room.address
										}
									</p>
								</div>
								<div>
									<CapacityHolder
										capacity={
											getGuestDetailQuery.data?.data
												.room_booking.room.capacity
										}
									/>
								</div>
							</section>
							<section className="py-4">
								<p className="text-[12px]">
									{getGuestDetailQuery.data?.data.room_booking
										.description ?? "No description"}
								</p>
							</section>
							<section>
								{getGuestDetailQuery.data?.data.room_booking
									?.room.photos.length ? (
									<img
										src={
											getGuestDetailQuery.data?.data
												.room_booking?.room.photos[0]
												.image_url ?? ""
										}
										alt="Room image"
										className="h-[120px] w-full rounded-md  md:h-[192px] md:max-w-[616px]"
									/>
								) : (
									<div className="flex h-[120px] w-full flex-col justify-center rounded-md bg-[#DCDCDC] md:h-[192px] md:max-w-[616px]">
										<div className="mx-4 flex h-[50px] w-[50px] items-center justify-center rounded-full bg-[#C7C7C7]">
											<p className="select-none text-lg text-white font-semibold">
												{getGuestDetailQuery.data?.data.room_booking?.room.name
													.split(" ")[0][0]
													.toUpperCase()}
											</p>
										</div>
									</div>
								)}
							</section>
							<div className="flex flex-col border-b-[0.5px] py-4 md:gap-y-2 mmd:hidden">
								<div className="flex items-center justify-between">
									<h1 className=" font-medium">Amenities</h1>
								</div>
								{getGuestDetailQuery.data?.data.room_booking
									.room.amenities.length ? (
									<div className="flex flex-wrap items-center gap-[5px]">
										{getGuestDetailQuery.data?.data.room_booking.room.amenities.map(
											(item) => (
												<Badge
													variant="white"
													className="rounded-full border-transparent px-2.5 py-2 shadow-[0px_0px_4px_0px_rgba(0,0,0,0.15)]"
												>
													{item.name}
												</Badge>
											)
										)}
									</div>
								) : (
									<p className="text-[12px] font-light">
										No amenities available
									</p>
								)}
							</div>
							<Accordion
								type="single"
								collapsible
								className="w-full md:hidden"
							>
								<AccordionItem
									value="item-1"
									className="border-none"
								>
									<AccordionTrigger className="py-2 hover:no-underline">
										Description
									</AccordionTrigger>
									<AccordionContent>
										{getGuestDetailQuery.data?.data
											.room_booking.description ??
											"No description"}
									</AccordionContent>
								</AccordionItem>
								<AccordionItem
									value="item-2"
									className="border-none"
								>
									<AccordionTrigger className="py-2 hover:no-underline">
										Amenities
									</AccordionTrigger>
									<AccordionContent>
										<div className="p-1">
											{getGuestDetailQuery.data?.data
												.room_booking.room.amenities
												.length ? (
												<div className="flex flex-wrap items-center gap-[5px]">
													{getGuestDetailQuery.data?.data.room_booking.room.amenities.map(
														(item) => (
															<Badge
																variant="white"
																className="rounded-full border-transparent px-2.5 py-2 shadow-[0px_0px_4px_0px_rgba(0,0,0,0.15)]"
															>
																{item.name}
															</Badge>
														)
													)}
												</div>
											) : (
												<p className="text-[12px] font-light">
													No amenities available
												</p>
											)}
										</div>
									</AccordionContent>
								</AccordionItem>
							</Accordion>
						</div>
						<div className="w-full">
							<GuestMeetingDetailsCard />
						</div>
					</div>
				</div>
			) : (
				<RequestIsLoading
					isLoading={getGuestDetailQuery.isLoading}
					isFullpage
					size={24}
				/>
			)}
		</>
	);
};

export default MeetingInvitation;
