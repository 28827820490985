import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MeetingFilterState {
	location: string;
	room: string;
	status: string;
	bookedBy: string;
	searchQuery: string;
}

const initialState: MeetingFilterState = {
	location: "all",
	room: "all",
	status: "all",
	bookedBy: "all",
	searchQuery: "",
};

const MeetingFilterSlice = createSlice({
	name: "filters",
	initialState,
	reducers: {
		setLocation: (state, action: PayloadAction<string>) => {
			state.location = action.payload;
		},
		setRoom: (state, action: PayloadAction<string>) => {
			state.room = action.payload;
		},
		setStatus: (state, action: PayloadAction<string>) => {
			state.status = action.payload;
		},
		setBookedBy: (state, action: PayloadAction<string>) => {
			state.bookedBy = action.payload;
		},
		setSearchQuery: (state, action: PayloadAction<string>) => {
			state.searchQuery = action.payload;
		},
		clearFilters: () => initialState,
	},
});

export const {
	setLocation,
	setRoom,
	setStatus,
	setBookedBy,
	setSearchQuery,
	clearFilters,
} = MeetingFilterSlice.actions;
export default MeetingFilterSlice.reducer;
