import { FaLocationDot } from "react-icons/fa6";
import { IoMdTime } from "react-icons/io";
import { IoPeopleOutline } from "react-icons/io5";
import { LuCalendar, LuContact } from "react-icons/lu";
// import { MEETING_DATA } from "@/services/mockData";
// import { ChevronRight } from "lucide-react";
// import { useNavigate } from "react-router";
import useDimension from "@/hooks/useScreenDimension";
import { cn } from "@/lib/utils";
// import { Button } from "@/components/ui/button";
// import { BsBox2 } from "react-icons/bs";
import Modal from "@/components/modals/Modal";
import BookingOverview from "@/components/modals/admin/bookings/BookingOverview";
import { formatDateString } from "@/services/utilities";
import React, { useState } from "react";
import { Link } from "react-router-dom";

interface BookingHistoryCardProps {
	// meetingdata: MEETING_DATA;
	meetingdata: any;
}

const BookingHistoryCard: React.FC<BookingHistoryCardProps> = ({
	meetingdata,
}) => {
	const { width } = useDimension();
	const [showBookingInfo, setShowBookingInfo] = useState(false);

	// console.log(meetingdata);
	const tab = meetingdata.status == "approved" ? "room" : "booking";

	return (
		<>
			{/* //View above 768px  */}
			{width ? (
				<>
					<Link
						className="flex max-h-[131px] cursor-pointer flex-col items-center justify-between rounded-[12px] bg-white p-2 drop-shadow-[0_1px_5px_rgba(16,24,40,0.15)] md:flex-row"
						// onClick={() => {
						// 	navigate(
						// 		`/admin/partner-management/${meetingdata.company_id}/booking/${meetingdata.id}`,
						// 		{ state: { history: meetingdata } }
						// 	);
						// }}
						to={`/admin/partner-management/${meetingdata.company_id}/booking/${meetingdata.id}`}
					>
						<div className="flex h-full flex-grow gap-3.5 rounded-xl xl:flex-[0.5]">
							{!meetingdata.room?.photos[0]?.image_url ? (
								<div className="flex h-[72px] w-[150px] flex-col justify-center rounded-[12px] bg-[#DCDCDC] px-4 md:h-[123px] md:w-[179px]">
									<div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-[#C7C7C7] ">
										<p className="select-none text-lg font-semibold text-white">
											{meetingdata.room?.name
												.split(" ")[0][0]
												.toUpperCase()}
										</p>
									</div>
								</div>
							) : (
								<img
									src={
										meetingdata?.room?.photos[0]?.image_url
									}
									alt={
										meetingdata?.room?.photos[0]?.file_name
									}
									className="h-[72px] w-[150px] rounded-[12px] md:h-[123px] md:w-[179px]"
								/>
							)}

							<div className="flex flex-col justify-between gap-y-2">
								<div className="space-y-1">
									<h2 className="font-bold text-basecolor">
										{meetingdata?.room?.name ?? "N/A"}
									</h2>
									<div className="flex items-center gap-1 text-[10px]">
										<FaLocationDot className="text-[#323539]" />
										{meetingdata?.room?.address ?? "N/A"}
									</div>
								</div>

								<div className="hidden w-fit items-center gap-2 rounded-full  bg-[#E3F2FF] px-2 py-1 md:flex">
									<div className="flex items-center gap-1 ">
										<IoPeopleOutline className="text-[9px] text-[#022A4F]" />
										<p className="text-[8px] font-light">
											Capacity
										</p>
									</div>
									<p className="text-[10px] font-bold">
										{meetingdata?.room?.capacity ?? "N/A"}
									</p>
								</div>
							</div>
						</div>
						<div className="flex h-full flex-grow flex-col gap-x-1.5 gap-y-4 border-l-2 py-1 pl-2 xl:flex-[0.2] ">
							<span className="flex items-center gap-2 ">
								<LuCalendar className="text-[#BFBFBF]" />
								<p className="text-[10px]">
									{meetingdata?.start_at
										? formatDateString(
												meetingdata?.start_at.split(
													" "
												)[0]
											)
										: "N/A"}
								</p>
							</span>
							<span className="flex items-center gap-2 ">
								<IoMdTime className="text-[#BFBFBF]" />
								<p className="text-[10px]">
									{meetingdata?.start_time?.slice(0, -3) ??
										"N/A"}{" "}
									-{" "}
									{meetingdata?.end_time?.slice(0, -3) ??
										"N/A"}
								</p>
							</span>
							<span className="flex items-center gap-2 ">
								<LuContact className="text-[#BFBFBF]" />
								<p className="text-[10px]">
									{meetingdata?.host?.name ?? "N/A"}
								</p>
							</span>
						</div>

						<div className="flex h-max flex-grow flex-col  gap-y-8 border-l-2 pl-2 xl:flex-[0.2] ">
							<p className=" font-semibold text-[#323539]">
								{meetingdata?.title}
							</p>
							<div className="flex w-[80%] justify-between gap-x-2.5">
								<span
									className={cn(
										"flex w-[111px] items-center justify-center rounded-lg bg-[#F6E2E1] px-4 py-0.5 text-sm  font-medium capitalize",
										{
											"bg-[#F6E2E1] text-[#B7241D]":
												meetingdata?.status ===
												"declined",
											"bg-[#F6F6F6] text-[#636363]":
												meetingdata?.status === "done",
											"bg-[#E7F3F1] text-[#138576]":
												meetingdata?.status ===
												"approved",
											"bg-[#F5F1F6] text-[#69216F]":
												meetingdata?.status ===
												"onprogress",
										}
									)}
								>
									{meetingdata?.status === "onprogress"
										? "In Progress"
										: meetingdata?.status}
								</span>
							</div>
						</div>

						<div
							className={cn(
								"ml-auto mt-10 flex h-full flex-[0.1] flex-col items-end gap-1.5 py-1 pr-4",
								{
									hidden: meetingdata?.status === "completed",
								}
							)}
						>
							<div className="flex flex-col justify-end gap-y-2">
								<span className="flex items-center gap-2 text-[10px]">
									<span className="flex h-[13px] w-[13px] items-center justify-center rounded-full border border-[#BFBFBF] bg-[#fff] p-1 text-[6px] text-[#BFBFBF]">
										{meetingdata?.host?.name?.split(
											""
										)[0] ?? "N/A"}
									</span>
									<span className="text-[10px] font-light">
										{meetingdata?.host?.name ?? "N/A"}
									</span>
								</span>

								<div className="flex items-center gap-1 ">
									<IoPeopleOutline className="text-[9px] text-[#022A4F]" />
									<p className="text-[10px] font-light">
										Attendees
									</p>
									<p className="text-xs font-light">
										{meetingdata?.room?.guest_count ??
											"N/A"}
									</p>
								</div>
							</div>
						</div>
					</Link>
					<Modal
						dialogClassName="max-w-2xl p-0 pt-2"
						open={showBookingInfo}
						setOpen={setShowBookingInfo}
					>
						<BookingOverview
							handleClose={() => setShowBookingInfo(false)}
							activetab={tab}
							status={meetingdata?.status}
							id={meetingdata?.id}
						/>
					</Modal>
				</>
			) : (
				<p></p>
				// Mobile View less than 768px
				// <MobileBookingHistoryCard meetingdata={meetingdata} />
			)}
		</>
	);
};

export default BookingHistoryCard;

// interface MobileBookingHistoryCardProps {
// 	meetingdata: MEETING_DATA;
// }
// const MobileBookingHistoryCard = ({
// 	meetingdata,
// }: MobileBookingHistoryCardProps) => {
// 	const navigate = useNavigate();
// 	return (
// 		<div className="flex flex-col items-center justify-between rounded-[12px] bg-white p-2 drop-shadow-[0_1px_5px_rgba(16,24,40,0.15)] md:flex-row ">
// 			<div className="flex  w-full gap-3.5 rounded-xl">
// 				<img
// 					src={meetingdata?.image}
// 					alt=""
// 					className=" h-[72px] w-[150px] rounded-[12px] md:h-[123px] md:w-[179px]"
// 				/>
// 				<div className="flex flex-col justify-between gap-y-2">
// 					<div className="space-y-1">
// 						<h2 className="text-[14px]  font-semibold">
// 							{meetingdata.title}
// 						</h2>
// 					</div>
// 				</div>
// 				<div
// 					className={cn(
// 						"ml-auto flex flex-[0.1] flex-col items-end justify-center gap-1.5 py-1",
// 						{
// 							hidden: meetingdata.status === "completed",
// 						}
// 					)}
// 				>
// 					<button
// 						className="text-[#858C95]"
// 						onClick={() =>
// 							navigate("/partner/meeting/7", {
// 								state: { meeting: meetingdata.status },
// 							})
// 						}
// 					>
// 						<ChevronRight />
// 					</button>
// 				</div>
// 			</div>
// 			<div className="grid w-full grid-cols-3 grid-rows-2 gap-1.5 py-1 pt-3.5">
// 				<span className="flex items-center gap-2 ">
// 					<BsBox2 className="w-[11px] font-bold text-[#BFBFBF]" />
// 					<p className="text-[10px]">{meetingdata.room_name}</p>
// 				</span>
// 				<span className="flex items-center gap-2 ">
// 					<LuCalendar className="w-[11px] text-[#BFBFBF]" />
// 					<p className="text-[10px]">
// 						{meetingdata.appointment?.date}
// 					</p>
// 				</span>
// 				<span className="flex items-center gap-2 ">
// 					<LuContact className="w-[11px] text-[#BFBFBF]" />
// 					<p className="text-[10px]">
// 						{meetingdata.appointment?.partner}
// 					</p>
// 				</span>
// 				<span className="flex items-center gap-2 ">
// 					<IoLocation className="w-[11px] text-[#BFBFBF]" />
// 					<p className="text-[10px]">{meetingdata.location}</p>
// 				</span>

// 				<span className="flex items-center gap-2 ">
// 					<IoMdTime className="w-[14px] text-[#BFBFBF] " />
// 					<p className="text-[10px]">
// 						{meetingdata.appointment?.time}
// 					</p>
// 				</span>
// 				<span className="flex items-center gap-2 text-[10px]">
// 					<span className="flex h-[10px] w-[10px] items-center justify-center rounded-full border border-[#BFBFBF] bg-[#fff] p-1 text-[6px] text-[#BFBFBF]">
// 						A
// 					</span>
// 					{meetingdata.appointment.name}
// 				</span>
// 			</div>

// 			<div className="flex h-max w-full flex-col justify-between gap-y-7 border-t pt-2">
// 				<div className="flex w-full justify-between gap-x-2.5">
// 					{meetingdata.status === "booked" && (
// 						<Button variant="outline" className="h-[36px] w-full">
// 							Cancel
// 						</Button>
// 					)}
// 					{meetingdata.status === "booked" && (
// 						<Button className="h-[36px] w-full">Reschedule</Button>
// 					)}
// 					{meetingdata.status === "invited" && (
// 						<Button className="h-[36px] w-full" variant="outline">
// 							Decline
// 						</Button>
// 					)}
// 					{meetingdata.status === "invited" && (
// 						<Button className="h-[36px] w-full">Accept</Button>
// 					)}

// 					{(meetingdata.status === "accepted" ||
// 						meetingdata.status === "declined" ||
// 						meetingdata.status === "completed") && (
// 						<span
// 							className={cn(
// 								"ml-auto flex w-fit items-center rounded-lg bg-[#F6E2E1] px-5 py-1 text-[14px]  font-medium capitalize",
// 								{
// 									"bg-[#F6E2E1] text-[#B7241D]":
// 										meetingdata.status === "declined",
// 									"bg-[#F6F6F6] text-[#636363]":
// 										meetingdata?.status === "completed",
// 									"bg-[#E7F3F1] text-[#138576]":
// 										meetingdata?.status === "accepted",
// 								}
// 							)}
// 						>
// 							{meetingdata.status}
// 						</span>
// 					)}
// 				</div>
// 			</div>
// 		</div>
// 	);
// };
