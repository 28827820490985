import { cn } from "@/lib/utils";
import React, { Dispatch, SetStateAction, useState } from "react";
import { FaChevronLeft } from "react-icons/fa6";
import { Navigate, useLocation, useNavigate, useParams } from "react-router";
import EditLocationInfo from "./fragments/EditLocationInfo";
import { useAppSelector } from "@/hooks/hooks";
import { useGetLocationQuery } from "@/redux/features/api/locationEndpoints";
import EditLocationOperatingHours from "./fragments/EditLocationOperatingHours";
import RequestIsLoading from "@/components/Loader/RequestIsLoading";

const EditLocation: React.FC = () => {
	const { location_id } = useParams();
	const location = useLocation();

	if (location_id && isNaN(parseInt(location_id))) {
		return (
			<Navigate
				to="/admin/location-management"
				state={{ from: location }}
				replace
			/>
		);
	}

	const navigate = useNavigate();
	// const location = useLocation();
	const [step, setStep] = useState<"location-info" | "operating-hours">(
		"location-info"
	);

	const user = useAppSelector((state) => state.user);

	const { isLoading } = useGetLocationQuery({
		user_id: user?.user?.business_id ?? 0,
		location_id: location_id ? +location_id : 0,
	});

	// console.log(location_id ,location_id && typeof parseInt(location_id) !== "number" )

	return (
		<>
			<div className="relative">
				<div className="flex gap-4">
					<button className="" onClick={() => navigate(-1)}>
						<FaChevronLeft className="text-xl font-semibold text-[#858C95]" />
					</button>
					<div>
						<div className="flex ">
							<p className="pr-1 text-[#6D748D]">Location</p>
							<p className="text-primary"> &gt; Edit Location</p>
						</div>
						<h1 className="text-[20px] font-semibold text-basecolor">
							Edit Location
						</h1>
					</div>
				</div>
				<div className="flex justify-center">
					<div className="flex w-full max-w-[804px] flex-col items-center">
						<StepsComponent
							steps={steps}
							step={step}
							setStep={setStep}
						/>
						<div className="mt-4 w-full">
							{step === "location-info" && <EditLocationInfo />}
							{step === "operating-hours" && (
								<EditLocationOperatingHours />
							)}
						</div>
					</div>
				</div>
				<RequestIsLoading isLoading={isLoading} size={20} />
			</div>
		</>
	);
};

export default EditLocation;

interface Step {
	label: string;
	currentStep: "location-info" | "operating-hours";
}

interface StepsComponentProps {
	steps: Step[];
	step: "location-info" | "operating-hours";
	setStep: Dispatch<SetStateAction<"location-info" | "operating-hours">>;
}

const StepsComponent: React.FC<StepsComponentProps> = ({
	steps,
	step,
	setStep,
}) => {
	return (
		<div className="my-4 flex w-full items-center justify-evenly border-b border-b-[#E5E5E7]">
			{steps.map((item, index) => (
				<React.Fragment key={index}>
					<div
						id={`step-${index}`}
						className={cn(
							`step my-[-1px] flex cursor-pointer items-center gap-2 border-b-2 border-b-transparent py-3 font-semibold text-gray-500 transition-all duration-300`,
							{
								"border-primary text-primary":
									item.currentStep === step,
							}
						)}
						onClick={() => setStep(item.currentStep)}
					>
						{item.label}
					</div>
				</React.Fragment>
			))}
		</div>
	);
};

const steps: Step[] = [
	{
		label: "Location Info",
		currentStep: "location-info",
	},
	{
		label: "Operating Hours",
		currentStep: "operating-hours",
	},
];
