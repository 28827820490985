import { decodeValue, encodeValue } from "./utilities";

export default class LocalStorage {
	setItem<T>(key: string, value: T): void {
		this.setLocalStorage(key, value);
	}

	getItem<T>(key: string): T | null {
		return this.getLocalStorage(key);
	}

	removeItem(key: string): void {
		this.removeLocalStorage(key);
	}

	// ------------------------------------ // Local storage // ------------------------------------
	private setLocalStorage<T>(key: string, value: T): void {
		if (typeof localStorage === "undefined") {
			return;
		}

		try {
			const encodedValue = encodeValue<T>(value);
			localStorage.setItem(key, encodedValue);
		} catch (e) {
			// Handle the error if needed
		}
	}

	private getLocalStorage<T>(key: string): T | null {
		if (typeof localStorage === "undefined") {
			return null;
		}

		const value = localStorage.getItem(key);
		return value !== null ? decodeValue(value) : null;
	}

	private removeLocalStorage(key: string): void {
		if (typeof localStorage === "undefined") {
			return;
		}

		localStorage.removeItem(key);
	}
}
