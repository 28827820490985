import useCustomToast from "@/components/CustomToast";
import { LoaderButton } from "@/components/Loader/LoaderButton";
import CustomShadSelect from "@/components/common/CustomShadSelect";
import FormInput from "@/components/input/FormInput";
import { Button } from "@/components/ui/button";
import { useAppSelector } from "@/hooks/hooks";
import useSetErrors, { ApiError } from "@/hooks/useSetErrors";
import { useAddGuestMutation } from "@/redux/features/api/teamMemberEndpoints";

import { countryCodes } from "@/services/constants";
import { GuestParams, GuestSchema } from "@/types/team-member";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const AddGuest: React.FC = () => {
	const [countryCode, setCountryCode] = useState("+1");
	const [firstName, setFirstName] = useState("");

	const {
		register,
		handleSubmit,
		setValue,
		setError,
		formState: { errors },
		watch,
	} = useForm<GuestParams>({
		resolver: zodResolver(GuestSchema),
	});

	const navigate = useNavigate();
	const user = useAppSelector((state) => state.user);

	const customToast = useCustomToast();

	const [
		adminAddGuest,
		{ data: responseData, isSuccess, isLoading, isError, error },
	] = useAddGuestMutation();

	const handleAddGuest: SubmitHandler<GuestParams> = async (data) => {
		await adminAddGuest({
			business_id: user.user?.business_id ?? 0,
			data: {
				...data,
				name:
					data.name && data.name.length
						? data.name + " " + firstName
						: undefined,
				phone_number: data.phone_number
					? countryCode + data.phone_number
					: undefined,
			},
		}).unwrap();
	};

	useEffect(() => {
		if (isSuccess && responseData) {
			customToast("Guest added successfully 🎉", {
				id: "add-team-member",
				type: "success",
				duration: 3000,
			});
			setTimeout(() => {
				navigate("/admin/team-members/guest");
			}, 1500);
		}
	}, [isSuccess]);

	useSetErrors({
		error,
		isError,
		setError,
		keys: ["email"],
	});

	useEffect(() => {
		const errorObject = error as ApiError;
		if (errorObject && errorObject.status !== 401) {
			customToast("Could not add guest 🤕. Please try again later", {
				id: "add-guest",
				type: "error",
			});
		}
	}, [isError]);

	return (
		<form
			className="relative flex h-full w-[60vw] flex-col space-y-4"
			onSubmit={handleSubmit(handleAddGuest)}
		>
			{/* The header  */}
			<div className="flex gap-4">
				<Link to="/admin/team-members/">
					<FaChevronLeft className="text-xl font-semibold text-[#858C95]" />
				</Link>
				<div>
					<p className="text-xs font-normal">{user!.user!.name}</p>
					<h1 className="text-[20px] font-semibold text-basecolor">
						Add Guest
					</h1>
				</div>
			</div>
			{/* body content  */}
			<div className="flex flex-1 flex-col space-y-6 px-8">
				<div className="flex flex-col gap-y-4">
					<div className="flex flex-col gap-y-4">
						<p className="font-manrope font-semibold text-basecolor">
							Guest Information
						</p>
						<div className="grid grid-cols-2 gap-2">
							<FormInput
								inputType="w-full"
								placeholder="Last Name"
								className="h-10"
								error={errors?.name}
								register={{
									...register("name"),
								}}
							/>
							<FormInput
								inputType="w-full"
								placeholder="First Name"
								className="h-10"
								onChange={(e) =>
									setFirstName(e.target.value.trim())
								}
							/>
							<FormInput
								inputType="w-full"
								placeholder="Email"
								className="h-10"
								register={{
									...register("email"),
								}}
								error={errors.email}
							/>
							<div>
								<div className="flex items-center">
									<CustomShadSelect
										className="h-10 w-fit rounded-r-none border-r-transparent"
										placeholder="+1"
										label="Country Codes"
										options={countryCodes}
										onChange={(value) => {
											setCountryCode(value);
										}}
										labelClassName="px-2"
										itemClassName="px-8"
									/>
									<FormInput
										inputType="text"
										className="h-10 rounded-l-none py-0"
										placeholder="Phone Number"
										maxLength={10}
										value={watch("phone_number")}
										error={
											errors?.phone_number?.message
												? ""
												: undefined
										}
										onChange={(e) => {
											setValue(
												"phone_number",
												e.target.value
											);
										}}
									/>
								</div>
								{errors.phone_number && (
									<small className="mt-1.5 text-xs text-red-500">
										{errors.phone_number.message}
									</small>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="mt-6 flex justify-end gap-2 px-8">
				<Button
					className="max-w-[190px] flex-1"
					variant="outlinecustom"
					onClick={() => {
						navigate("/admin/location-management");
					}}
					disabled={isLoading}
				>
					Cancel
				</Button>
				<LoaderButton
					className="max-w-[190px] flex-1"
					variant="default"
					disabled={isLoading}
					loading={isLoading}
					loaderSize={20}
				>
					Add Guest
				</LoaderButton>
			</div>
		</form>
	);
};

export default AddGuest;
