import LoginSvg from "@/components/LoginSvg";
import React from "react";

const BuildingsWelcomeRegister: React.FC = () => {
	return (
		<div className="mx-auto flex w-full flex-col items-center gap-y-12">
			<div className="space-y-2 text-center">
				<h1 className="text-xl font-semibold text-primary lg:text-3xl">
					Effortless Space Reservations
				</h1>
				<p className="flex items-center gap-2 text-sm font-light leading-[42px] text-primary lg:text-xl">
					Experience Seamless and Hassle-Free Reservations
				</p>
			</div>
			{/* <div className="flex flex-col items-center"> */}
			<LoginSvg />
			{/* <div className="flex items-center space-x-4">
					<span className="text-primary ">
						<PiBuildings size={24} />
					</span>
					<p className="text-[20px] font-light leading-[28px] tracking-[-1%] text-primary">
						<span className="font-semibold">
							North West Toronto{" "}
						</span>{" "}
						| Ontario Health Team
					</p>
				</div> */}
			{/* </div> */}
		</div>
	);
};

export default BuildingsWelcomeRegister;
