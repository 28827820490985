import { AuthData } from "@/provider/AuthProvider";
import { IUser, IUserPartner } from "@/types/user";
import { createContext, useContext } from "react";

interface AuthContextType {
	user: IUser | IUserPartner | null;
	setAuthUser: (data: AuthData) => void;
	logout: () => void;
}
export const AuthContext = createContext<AuthContextType>(
	{} as AuthContextType
);

export function useAuth() {
	return useContext(AuthContext);
}
