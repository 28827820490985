import Avatar from "@/components/common/Avatar";
import CapacityHolder from "@/components/common/CapacityHolder";
import GuestHolder from "@/components/common/GuestHolder";
import RescheduleConfirmationCard from "@/components/modals/admin/partners/RescheduleCard";
import Modal from "@/components/modals/Modal";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import Wrapper from "@/container/Wrapper";
import { useAppSelector } from "@/hooks/hooks";
import { cn } from "@/lib/utils";
import { useGetAdminBookingDetailsQuery } from "@/redux/features/api/adminEndpoints";
import { useUpdateAdminBookingStatusMutation } from "@/redux/features/apiSlice";
import { formatDateString } from "@/services/utilities";
import { TeamMemberRoles } from "@/types/team-member";
import { message } from "antd";
import { ChevronLeft } from "lucide-react";
import { useEffect, useState } from "react";
import { LuCalendarCheck } from "react-icons/lu";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

const BookingListCardDetails: React.FC = () => {
	const status = "upcoming";
	const location = useLocation();
	const booking_id = location.pathname.split("/").pop();
	const [showAllGuests, setShowAllGuests] = useState(false);
	const [showReschedule, setShowReschedule] = useState(false);
	const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");

	const [
		updateStatus,
		{
			isSuccess: isActionSuccess,
			isError: isActionError,
			isLoading: isActionLoading,
			error,
		},
	] = useUpdateAdminBookingStatusMutation();

	const [messageApi, contextHolder] = message.useMessage();
	const key = "updateBookingStatus";
	const handleCancelBooking = () => {
		if (
			userRole === TeamMemberRoles.TeamMember ||
			userRole === TeamMemberRoles.PartnerManager
		) {
			return messageApi.open({
				key,
				type: "error",
				content: "You are not allowed to perform this action",
				duration: 2,
			});
		}
		messageApi.open({
			key,
			type: "loading",
			content: "Cancelling reservation...",
			duration: 0, // Keep the message open until the request is done
		});
		updateStatus({
			booking_id: booking_id!,
			data: { status: "cancelled" },
		});
	};

	const handleRescheduleBooking = () => {
		if (
			userRole === TeamMemberRoles.TeamMember ||
			userRole === TeamMemberRoles.PartnerManager
		) {
			return messageApi.open({
				key,
				type: "error",
				content: "You are not allowed to perform this action",
				duration: 2,
			});
		}

		messageApi.open({
			key,
			type: "loading",
			content: "Requesting reschedule...",
			duration: 0, // Keep the message open until the request is done
		});
		updateStatus({
			booking_id: booking_id!,
			data: { status: "provider_requested_reschedule" }, // Update status to 'reschedule_requested'
		});
	};

	useEffect(() => {
		if (isActionSuccess && data) {
			messageApi.open({
				key,
				type: "success",
				content: "Action completed successfully!",
				duration: 2,
			});
			// Additional actions after successful update (e.g., navigate back)
		} else if (isActionError && error) {
			messageApi.open({
				key,
				type: "error",
				content: "Error performing action!",
				duration: 2,
			});
		}
	}, [isActionSuccess, isActionError]);

	// const handleCancelBooking = () => {
	// 	updateStatus({
	// 		booking_id: booking_id!,
	// 		data: { status: "cancelled" },
	// 	});
	// };

	// const handleRescheduleBooking = () => {
	// 	updateStatus({
	// 		booking_id: booking_id! ,
	// 		data: { status: "approved" },
	// 	});
	// }
	const { data, isLoading, isSuccess, isError } =
		useGetAdminBookingDetailsQuery({
			booking_id: parseInt(booking_id!),
		});
	const bookingdetails = data?.data;

	return (
		<>
			<Wrapper className="" fetching={isLoading}>
				{contextHolder}
				{isSuccess && data && (
					<div className="flex flex-col gap-6  px-8">
						<div className="border-b border-b-primary pb-3">
							<Link
								className="flex gap-2 font-semibold text-primary"
								to={`/admin/partner-management/${location.pathname.split("/")[3]}`}
							>
								<ChevronLeft />
								Reservations
							</Link>
						</div>
						<div className="flex gap-4">
							{/* image div  */}
							{bookingdetails?.room?.photos[0]?.image_url ? (
								<div className="h-[285px] w-[179px] rounded-md border bg-cover">
									<img
										src={
											bookingdetails?.room?.photos[0]
												?.image_url ?? ".."
										}
										alt={
											bookingdetails?.room?.photos[0]
												?.file_name
										}
										className=" h-full rounded-md bg-white object-cover md:w-full"
									/>
								</div>
							) : (
								<div className="h-[285px] w-[179px] rounded-md bg-[#DCDCDC]">
									<div className="m-4 flex h-[50px] w-[50px] items-center justify-center rounded-full bg-[#C7C7C7]">
										<p className="select-none text-lg font-semibold text-white">
											{bookingdetails?.title
												.split(" ")[0][0]
												.toUpperCase()}
										</p>
									</div>
								</div>
							)}

							{/* Meeting Details  */}
							<div className="flex w-full gap-24">
								<div className="flex w-full flex-col">
									<p className="text-xs tracking-[2px]  text-[#323539]">
										MEETING TITLE
									</p>
									<h1 className="text-[20px] font-bold text-basecolor">
										{bookingdetails?.title}
									</h1>
									{bookingdetails?.description ? (
										<>
											<p className="text-sm text-basecolor">
												Description
											</p>
											<p>{bookingdetails.description}</p>
										</>
									) : null}

									<div className="mt-8 hidden flex-col space-y-1 md:flex">
										<div className="flex justify-between space-x-2">
											<div className="flex items-center gap-2 font-bold">
												<i className="mgc_box_line text-[14px] before:!text-[#858C95]" />
												<p>
													{bookingdetails?.room?.name}
												</p>
											</div>
											<CapacityHolder
												capacity={
													bookingdetails?.room
														?.capacity
												}
											/>
										</div>

										<p className="flex items-center gap-2 border-t-[0.5px] border-t-primary pt-3">
											<i className="mgc_location_line text-[14px] before:!text-[#858C95]" />
											{bookingdetails?.room?.address}
										</p>
										<p className="flex items-center gap-2 border-t-[0.5px]  border-t-primary pt-3 ">
											<LuCalendarCheck
												size={14}
												color="#858C95"
											/>

											{bookingdetails?.start_at
												? formatDateString(
														bookingdetails?.start_at.split(
															" "
														)[0]
													)
												: "N/A"}
										</p>
										<div className="flex items-center gap-2 border-y-[0.5px]  border-y-primary  pb-1 pt-3">
											<i className="mgc_time_line text-[14px] before:!text-[#858C95]" />
											{bookingdetails?.start_at
												? bookingdetails.start_time.slice(
														0,
														-3
													)
												: "N/A"}{" "}
											-{" "}
											{bookingdetails?.end_time
												? bookingdetails.end_time.slice(
														0,
														-3
													)
												: "N/A"}
										</div>
									</div>
								</div>

								<div className="flex w-full flex-col pr-4 ">
									<div className="ml-auto flex items-end gap-x-2.5">
										<span
											className={cn(
												"flex w-fit items-center rounded-lg bg-[#F7EDD4] px-5 py-1 text-[14px] font-medium  capitalize text-[#896200]",
												{
													"bg-[#F6E2E1] text-[#B7241D]":
														bookingdetails.status ===
														"declined",
													"bg-[#F6F6F6] text-[#636363]":
														bookingdetails?.status ===
														"done",
													"bg-[#E7F3F1] text-[#138576]":
														bookingdetails?.status ===
														"approved",
													"bg-[#F5F1F6] text-[#69216F]":
														bookingdetails?.status ===
														"onprogress",
												}
											)}
										>
											{bookingdetails?.status
												? bookingdetails?.status ===
													"onprogress"
													? "In Progress"
													: bookingdetails?.status
												: "N/A"}
										</span>
									</div>
									<section className="flex justify-between pt-16">
										<div className="hidden w-full space-y-2 md:block">
											<h1>Organizer</h1>
											<Avatar
												variant="host"
												name={
													bookingdetails?.host?.name
												}
											/>
										</div>
										<div className="w-[200px] space-y-2 lg:min-w-[200px]">
											<h1 className="text-[12px] font-semibold text-[#323539] md:text-base">
												Guests
											</h1>
											{!showAllGuests && (
												<>
													{bookingdetails?.meeting_members
														.slice(0, 3)
														?.map(
															(
																guest: any,
																index: number
															) => (
																<div
																	className=""
																	key={index}
																>
																	<GuestHolder
																		data={
																			guest
																		}
																		status={
																			status
																		}
																		name={
																			guest
																				?.member_profile
																				?.name ??
																			guest
																				?.member_profile
																				?.email
																		}
																		invitationStatus={
																			guest?.status
																		}
																	/>
																</div>
															)
														)}

													{bookingdetails
														?.meeting_members
														?.length > 3 && (
														<button
															onClick={() =>
																setShowAllGuests(
																	true
																)
															}
															className="text-[12px] font-medium text-primary"
														>
															{" "}
															+ 2 other guests
														</button>
													)}
												</>
											)}
											{showAllGuests && (
												<ScrollArea className="h-[120px]">
													{bookingdetails?.meeting_members?.map(
														(
															guest: any,
															index: number
														) => (
															<div
																className="py-2"
																key={index}
															>
																<GuestHolder
																	data={guest}
																	status={
																		status
																	}
																	name={
																		guest
																			?.member_profile
																			?.name ??
																		guest
																			?.member_profile
																			?.email
																	}
																	invitationStatus={
																		guest?.status
																	}
																/>
															</div>
														)
													)}
												</ScrollArea>
											)}
										</div>
									</section>
								</div>
							</div>

							{/* buttons  */}
						</div>
						{userRole !== TeamMemberRoles.TeamMember &&
							userRole !== TeamMemberRoles.PartnerManager && (
								<>
									{bookingdetails?.status == "pending" ||
									bookingdetails?.status == "approved" ||
									bookingdetails?.status == "upcoming" ? (
										<div className="ml-auto flex gap-2 pt-4 ">
											<Button
												variant="outlinecustom"
												className="w-full"
												onClick={() =>
													setShowReschedule(true)
												}
												disabled={isActionLoading}
											>
												Request to Reschedule
											</Button>
											<Button
												onClick={handleCancelBooking}
												className="w-full text-white"
												disabled={isActionLoading}
											>
												Cancel Event
											</Button>
										</div>
									) : null}
								</>
							)}
					</div>
				)}
				{isError && data && <p>Error Fetching data for reservation</p>}
			</Wrapper>

			{userRole !== TeamMemberRoles.TeamMember &&
				userRole !== TeamMemberRoles.PartnerManager && (
					<Modal
						open={showReschedule}
						setOpen={setShowReschedule}
						dialogClassName="max-w-[400px] p-6 !rounded-lg bg-white"
					>
						<RescheduleConfirmationCard
							handleReschedule={handleRescheduleBooking}
							isLoading={isActionLoading}
						/>
					</Modal>
				)}
		</>
	);
};

export default BookingListCardDetails;
