import { useAppDispatch, useAppSelector } from "@/hooks/hooks";
import { useGetLocationAnalyticsQuery } from "@/redux/features/api/locationEndpoints";
import StatisticsLineChart from "../statistics/StatisticsLineChart";
import { useState } from "react";
import CustomShadSelect from "@/components/common/CustomShadSelect";
import { setQueryData } from "@/redux/slices/analyticsSlice";
import { AnalyticsQueryParams } from "@/types/location";

const MeetingsBookedByHourOfDay: React.FC = () => {
	const dispatch = useAppDispatch();
	const businessId =
		useAppSelector((state) => state.user.user?.business_id) ?? 0;
	const queryData = useAppSelector((state) => state.analytics.query_data);

	const [selectedDay, setSelectedDay] = useState("all");

	const getAdminLocationsQuery = useGetLocationAnalyticsQuery({
		user_id: businessId,
		query_data: queryData,
	});

	const createLineData = (title: string, value: number) => ({
		title,
		value,
	});
	const bookedByDayOfWeekData = getAdminLocationsQuery.data
		? Object.keys(
				getAdminLocationsQuery.data.data.trends.by_times_of_day
			).map((keyName) => {
				const value = parseInt(
					getAdminLocationsQuery?.data?.data.trends.by_times_of_day[
						keyName
					] ?? "0"
				);
				// const newKeyName =
				// 	keyName.split(" ")[0] + keyName.split(" ")[1].toLowerCase();

				const newKeyName =
					Number(keyName.split(":")[0]).toString() +
					(keyName.split(":")[1].split(" ")[1] === "AM"
						? " am"
						: " pm");

				return createLineData(newKeyName, value);
			})
		: [];

	const total = bookedByDayOfWeekData.reduce(
		(acc, item) => acc + item.value,
		0
	);

	const dayList = [
		{ label: "All", value: "all" },
		...(getAdminLocationsQuery.data
			? Object.keys(
					getAdminLocationsQuery.data.data.trends.by_days_of_week
				).map((item) => ({
					label: item,
					value: item.toLowerCase(),
				}))
			: []),
	];

	return (
		<section className="flex h-[320px] flex-1 flex-col rounded-lg bg-white p-8 pb-[41px]">
			<div className="flex justify-between">
				<div className="mb-6 flex flex-col space-y-2">
					<h3 className="font-manrope text-lg font-bold leading-[16px] tracking-[0.5px] text-[#454D56]">
						Reservations by Time of Day
					</h3>
					<p className="text-xs leading-[120%] tracking-[0.41px] text-[#596574]">
						Total Reservations:{" "}
						<span className="text-base font-semibold">{total}</span>
					</p>
				</div>
				<CustomShadSelect
					className="h-9 max-w-[145px]"
					placeholder="Filter by Day"
					options={dayList}
					value={selectedDay}
					onChange={(value) => {
						setSelectedDay(value);
						dispatch(
							setQueryData({
								day_filter_for_trending_by_times:
									value === "all" ? undefined : value,
							} as AnalyticsQueryParams)
						);
					}}
					labelClassName="px-2"
					itemClassName="px-8"
				/>
			</div>
			<StatisticsLineChart formattedData={bookedByDayOfWeekData} />
		</section>
	);
};

export default MeetingsBookedByHourOfDay;
