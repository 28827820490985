import useCustomToast from "@/components/CustomToast";
import FormInput from "@/components/input/FormInput";
import LoginSvg from "@/components/LoginSvg";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/hooks/auth";
import { useLoginMutation } from "@/redux/features/apiSlice";
import { changeTheme } from "@/services/utilities";
import Spin from "antd/es/spin";
import { useEffect, useState } from "react";

const Login = () => {
	const [email, setEmail] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const toast = useCustomToast();
	const { setAuthUser } = useAuth();

	const handleLogin = async () => {
		const formData = {
			email: email,
			password: password,
		};
		login(formData);
	};

	const [login, { data, error, isError, isSuccess, isLoading }] =
		useLoginMutation();

	useEffect(() => {
		if (isSuccess && data) {
			const message = "Welcome to Spaces 🎉";

			toast(message, {
				id: "login_message",
				type: "success",
			});
			setTimeout(() => {
				setAuthUser(data);
				changeTheme(data.user.business.theme);
			}, 2500);
		}
		if (isError && error) {
			if (error) {
				const errorMessage =
					(
						error as {
							data?: { message?: string };
						}
					)?.data?.message || "An error occurred";
				return toast(errorMessage, {
					id: "login,error",
					type: "error",
				});
			}
		}
	}, [isSuccess, data, isError]);

	useEffect(() => {
		localStorage.clear();
	}, []);

	return (
		<div className="mx-auto mt-auto flex h-full w-full flex-col items-center justify-center gap-x-8 px-8 lg:flex-row xl:px-24">
			<div className="mx-auto flex w-full flex-col items-center gap-y-12">
				<div className="space-y-2 text-center">
					<h1 className="text-xl font-semibold text-primary lg:text-3xl">
						Welcome to Migranium Spaces
					</h1>
					<p className="flex items-center gap-2 text-sm font-light text-primary lg:text-xl">
						Unified Access for Effortless Reservations & Management
					</p>
				</div>
				<LoginSvg />
			</div>
			{/* Form  */}
			<div className="flex h-fit w-full max-w-[488px] flex-col overflow-hidden rounded-[10px] border border-[#E5E5E7] shadow-[0_0_25px_5px_rgba(16,24,40,0.1)]">
				<div className="flex flex-col gap-y-8 px-8 py-6 text-left">
					<div>
						<h1 className="text-[22px] font-semibold text-basecolor">
							Login to your portal
						</h1>
						<a
							href={"https://admin.migranium.com/sign-up"}
							className="font-normal tracking-[-1%] text-[#858C95]"
						>
							Don't have an account?{" "}
							<span className="text-[#195388]">Sign up</span>
						</a>
					</div>
					<div className="flex flex-col gap-y-4 ">
						<FormInput
							label="Email Address"
							inputType="text"
							onChange={(e) => setEmail(e.target.value)}
						/>
						<div>
							<FormInput
								label="Password"
								inputType="password"
								onChange={(e) => setPassword(e.target.value)}
							/>
							{/* <span className="text-[14px] text-[#858C95]">
								Password must be a minimum 8 characters
							</span> */}
						</div>
					</div>
					{/* <div className="flex items-center gap-2 text-[14px] text-[#858C95]">
						<input type="checkbox" />
						Remember me
					</div> */}
					{/* //forgot password page routing */}
					<a
						href="https://admin.migranium.com/forgot-password"
						target="_blank"
						className="text-basecolor underline"
					>
						Forgot Password?
					</a>
				</div>

				<div className="flex  flex-col items-center justify-between gap-y-4 bg-[#FAFBFC] px-8 py-4 lg:flex-row">
					<Button
						className="w-full px-4 lg:w-[110px]"
						disabled={isLoading || (!email && !password)}
						onClick={handleLogin}
					>
						{isLoading ? (
							<Spin style={{ color: "#fff" }} />
						) : (
							"Log in"
						)}
					</Button>
					{/* <div className="flex w-full flex-col items-center gap-2 lg:w-fit lg:flex-row">
						<p className="whitespace-nowrap text-sm font-light text-[#858C95]">
							Or <span className="text-">,</span> Sign in with
						</p>
						<Button className="flex w-full gap-2 border-[#E5E5E7] bg-white text-basecolor shadow-sm ">
							<img src={GoogleLogo} />
							Google
						</Button>
					</div> */}
				</div>
			</div>
		</div>
	);
};

export default Login;
