import React from "react";

const CustomCheckbox: React.FC<{
	checked: boolean;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	id?: string;
	htmlFor?: string;
	type?: "radio" | "checkbox";
}> = ({ id, htmlFor, checked, onChange, type = "checkbox" }) => {
	return (
		<label
			className="relative flex cursor-pointer items-center rounded-full"
			htmlFor={htmlFor}
		>
			{/* When you have time kindnly find a way to get the correct border color cause we need to get the darker variant of brand colour */}
			<input
				type={type}
				// name={htmlFor}
				className="peer relative h-[17px] w-[17px] cursor-pointer appearance-none rounded-md border border-[#E5E5E7] transition-all checked:border-primary checked:bg-primary hover:before:opacity-10"
				checked={checked}
				id={id}
				{...(onChange && { onChange })}
				readOnly
			/>
			<span className="pointer-events-none absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="h-[11px] w-[11px]"
					viewBox="0 0 20 20"
					fill="currentColor"
					stroke="currentColor"
					strokeWidth="1"
				>
					<path
						fillRule="evenodd"
						d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
						clipRule="evenodd"
					/>
				</svg>
			</span>
		</label>
	);
};

export default CustomCheckbox;
