import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { useState } from "react";
import { MdMoreVert } from "react-icons/md";
import EventActionPane from "../EventActionPane";
import { Booking } from "@/types/admin/booking";

const EventBlock = ({
	booking,
	viewType,
}: {
	viewType: "Day" | "Week" | "Month";
	booking: Booking;
}) => {
	const { id, status, room, host, company } = booking || {};
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const is_internal = company.is_internal === 1 ? true : false;

	const handlePopOverVisibility = (e) => {
		e.stopPropagation();
		setIsPopoverOpen(true);
	};
	const handleClose = () => {
		setIsPopoverOpen(false);
	};

	const renderPopover = () => (
		<Popover
			onOpenChange={() => setIsPopoverOpen(!isPopoverOpen)}
			open={isPopoverOpen}
		>
			<PopoverTrigger asChild>
				<button>
					<MdMoreVert className="text-sm" />
				</button>
			</PopoverTrigger>
			<PopoverContent className="w-fit bg-[#F8F9FB] px-4 py-3 pr-8">
				<EventActionPane
					internal={is_internal}
					id={id}
					eventStatus={status as any}
					onClose={handleClose}
				/>
			</PopoverContent>
		</Popover>
	);
	return (
		<div
			className={cn(
				"relative  flex h-full items-start rounded-[8px] border border-[#9C9C9C80]/50 bg-[#E9E9E9]  font-inter text-black",
				{
					"border-stone-600/50 bg-[#FFF5F5]":
						status.toLowerCase() == "pending",
					"border-stone-600/50 bg-[#D2E8E5]":
						status.toLowerCase() == "approved",
				}
			)}
		>
			{viewType === "Day" && (
				<div
					className="flex h-full w-full items-start justify-between py-2 px-2 text-[#323539]"
					onClick={handlePopOverVisibility}
				>
					<div className="flex flex-col gap-y-1">
						<div className="flex  items-center gap-2">
							<div className="flex flex-wrap items-center gap-x-3">
								<p className="truncate text-xs  font-medium">
									{host?.name}
								</p>
								<p className="truncate text-xs font-medium">
									{company?.name}
								</p>
							</div>
						</div>
						<p className="truncate text-[10px] font-light">
							{room?.name ?? ""}
						</p>
					</div>
					{renderPopover()}
				</div>
			)}
			{viewType === "Week" && (
				<div
					className="my-1 flex w-full flex-row items-center justify-between pl-2"
					onClick={handlePopOverVisibility}
				>
					<div className="flex h-full w-full flex-col text-basecolor ">
						<p className="line-clamp-1 text-[8px]  font-normal ">
							{company?.name}
						</p>
						<p className="line-clamp-1 text-[8px] font-bold">
							{host?.name}
						</p>
					</div>
					{renderPopover()}
				</div>
			)}
			{viewType === "Month" && (
				<div
					className="flex  w-full flex-row items-center justify-between pl-1 pr-4"
					onClick={handlePopOverVisibility}
				>
					<div className="flex h-full w-full flex-col gap-1 text-basecolor ">
						<p className="truncate text-[8px]  font-normal ">
							{company?.name}
						</p>
						<p className="line-clamp-1 truncate text-[8px] font-bold">
							{host?.name}
						</p>
					</div>
					{renderPopover()}
				</div>
			)}
		</div>
	);
};

export default EventBlock;
