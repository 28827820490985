import React from "react";
import {
	ResponsiveContainer,
	BarChart,
	XAxis,
	YAxis,
	Bar,
	LabelList,
} from "recharts";

const BookedByPartnersBarChart: React.FC<{
	data: { partner_name: string; booked: number; cancelled: number }[];
}> = ({ data }) => {
	return (
		<>
			<ResponsiveContainer width="100%" height="99%">
				<BarChart
					data={data}
					barGap={8}
					barCategoryGap={20}
					barSize={12}
					margin={{ top: 16 }}
				>
					<XAxis
						dataKey="partner_name"
						tickLine={false}
						axisLine={{ stroke: "#E5E5E7", strokeWidth: 1 }}
						className="fill-[#858C95] text-xs leading-[20] tracking-[0%]"
					/>
					<YAxis axisLine={false} hide={true} />
					<Bar dataKey="booked" fill="#1E8A7C" radius={2}>
						<LabelList
							dataKey="booked"
							position="top"
							style={{ fill: "#858C95", fontSize: "12px" }}
						/>
					</Bar>
					<Bar dataKey="cancelled" fill="#C5D0CE" radius={2}>
						<LabelList
							dataKey="cancelled"
							position="top"
							style={{ fill: "#858C95", fontSize: "12px" }}
						/>
					</Bar>
				</BarChart>
			</ResponsiveContainer>
			<ul className="flex items-center justify-center space-x-4 text-xs tracking-[0%] text-[#323539]">
				<li className="flex items-center space-x-2">
					<i className="block size-2 rounded-[2px] bg-[#1E8A7C]" />
					<p>Reserved</p>
				</li>
				<li className="flex items-center space-x-2">
					<i className="block size-2 rounded-[2px] bg-[#C5D0CE]" />
					<p>Cancelled</p>
				</li>
			</ul>
		</>
	);
};

export default BookedByPartnersBarChart;
