import { useCallback, useLayoutEffect, useState } from "react";

export default function useScreenDimension() {
	const [dimension, setDimension] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const updateDimension = useCallback(() => {
		setDimension({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	}, []);

	useLayoutEffect(() => {
		updateDimension();
		window.addEventListener("resize", updateDimension);

		return () => window.removeEventListener("resize", updateDimension);
	}, [updateDimension]);

	return dimension;
}
