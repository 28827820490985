import { Option } from "@/components/common/CustomShadSelect";
import RoundTick from "@/components/input/RoundTick";
import MemberInfo from "@/components/modals/admin/members/overview/MemberInfo";
import Modal from "@/components/modals/Modal";
import MultiSelect from "@/components/ui/multi-select";
import { useAppSelector } from "@/hooks/hooks";
import { cn } from "@/lib/utils";
import { useGetAllAdminPartnersQuery } from "@/redux/features/api/adminEndpoints";
import { TeamMemberRolesType } from "@/types/team-member";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

const MembersRoleSelector: React.FC<{
	label: string;
	value: TeamMemberRolesType;
	isActive: boolean;
	description: string;
	currentSelectedRole: TeamMemberRolesType;
	selectedPartners: Option[];
	setSelectedPartners: Dispatch<SetStateAction<Option[]>>;
	onClick: () => void;
}> = ({
	label,
	currentSelectedRole,
	isActive,
	value,
	description,
	selectedPartners,
	setSelectedPartners,
	onClick,
}) => {
	const user = useAppSelector((state) => state.user);
	const [showInfo, setShowInfo] = useState(false);

	const getAllAdminPartnersQuery = useGetAllAdminPartnersQuery({
		businessId: user.user?.business_id ?? 0,
	});

	const [adminPartners, setAdminPartners] = useState([
		{
			value: "none",
			label: "None",
			disabled: true,
		},
	]);

	// const [selected, setSelected] = useState<Option[]>([]);

	useEffect(() => {
		if (
			getAllAdminPartnersQuery.data &&
			getAllAdminPartnersQuery.data.data
		) {
			const filteredPartners = selectedPartners.length
				? getAllAdminPartnersQuery.data.data.filter(
						(item) =>
							!selectedPartners.some(
								(partner) =>
									partner.value === item.id.toString()
							)
					)
				: getAllAdminPartnersQuery.data.data;

			setAdminPartners([
				...filteredPartners.map((item) => ({
					value: item.id.toString(),
					label: item.name,
					disabled: false,
				})),
				{
					value: "none",
					label: "None",
					disabled: true,
				},
			]);
		}
	}, [
		getAllAdminPartnersQuery.isSuccess,
		getAllAdminPartnersQuery.data,
		selectedPartners,
	]);

	return (
		<>
			<div
				className={`flex items-center space-x-2.5 rounded-xl bg-white px-5 py-3.5 shadow-[0px_0px_5px_0px_rgba(0,0,0,0.25)]`}
			>
				<div
					className={cn("self-center", {
						"self-start py-2.5":
							currentSelectedRole === "PARTNER_MANAGER" &&
							value === "PARTNER_MANAGER",
					})}
					onClick={onClick}
				>
					<RoundTick isActive={isActive} />
				</div>
				<div className="flex flex-col space-y-[5px]">
					<div className="flex flex-col space-y-[5px]">
						<div
							className="flex w-fit cursor-pointer items-center space-x-2"
							onClick={() => setShowInfo(true)}
						>
							<p className="text-medium text-[14px] leading-[20px] tracking-[-0.1px]">
								{label}
							</p>
							<i className="mgc_information_line schedule-time-block-icon before-text-dark text-[14px]" />
						</div>
						<p className="text-[12px] leading-[18px] text-[#323539]">
							{description}
						</p>
					</div>
					{currentSelectedRole === "PARTNER_MANAGER" &&
						value === "PARTNER_MANAGER" && (
							<div className="flex flex-col space-y-1.5">
								<label className="mb-1.5 block text-sm leading-[20px] tracking-[-0.1px] text-[#323539]">
									Assigned Partners
								</label>
								<MultiSelect
									options={adminPartners}
									placeholder={"Location"}
									selected={selectedPartners}
									setSelected={setSelectedPartners}
								/>
							</div>
						)}
				</div>
			</div>
			<Modal
				dialogClassName="max-w-2xl p-0"
				open={showInfo}
				setOpen={setShowInfo}
			>
				<MemberInfo memberType={value} />
			</Modal>
		</>
	);
};

export default MembersRoleSelector;
