import { HiOutlineTrash } from "react-icons/hi2";
import { DialogClose } from "../../ui/dialog";
import { Button } from "../../ui/button";
import { useState } from "react";
import FormInput from "../../input/FormInput";
import { LoadingOutlined } from "@ant-design/icons";
import { notifyWithIcon } from "@/services/utilities";

const DeletePartners = ({ onDeleteSuccess, isLoading }) => {
	const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
	const [confirmationText, setConfirmationText] = useState("");
	const confirmationPhrase = "DELETE ALL PARTNERS";

	const handleDelete = () => {
		if (confirmationText.toUpperCase() === confirmationPhrase) {
			onDeleteSuccess();
		} else {
			notifyWithIcon("error", "Incorrect confirmation text");
			// console.error("Incorrect confirmation text");
		}
	};

	return (
		<div className="flex w-full items-start gap-2 pr-6">
			<div className="flex flex-[0.1] pt-1">
				<HiOutlineTrash className="text-lg text-red-400" />
			</div>
			<div className="flex flex-grow flex-col gap-y-2">
				<h1 className="text-[22px] font-semibold text-[#323539]">
					Delete Selected Partners
				</h1>

				{confirmDelete ? (
					<>
						<p className="text-[#6D748D]">
							Please type{" "}
							<span className="font-bold">
								{confirmationPhrase}
							</span>{" "}
							to confirm:
						</p>
						<FormInput
							value={confirmationText}
							inputType="text"
							onChange={(e) =>
								setConfirmationText(e.target.value)
							}
						/>
					</>
				) : (
					<div className="flex flex-col gap-y-2 text-sm text-[#6D748D]">
						<p className="">
							Are you sure you want to remove <b>ALL PARTNERS?</b>
						</p>
						<p>
							This action <b>cannot be undone</b> and they will no
							longer be able to reserve spaces or manage meetings.
						</p>
					</div>
				)}

				<div className="flex w-full items-center gap-x-1.5 pt-2">
					<DialogClose className="w-full rounded-md border border-[#6B7280] bg-transparent px-4 py-2 text-primary">
						Cancel
					</DialogClose>
					{/* <DialogClose className="w-full">
						<Button
							className="bg-transparent rounded-md py-2 px-4 w-full"
							variant="outline"
						>
							Cancel
						</Button>
					</DialogClose> */}
					{confirmDelete ? (
						<Button
							className="w-full rounded-md px-4 py-2 text-white"
							variant="destructive"
							disabled={
								confirmationText.toUpperCase() !==
								confirmationPhrase
							}
							onClick={handleDelete}
						>
							{isLoading ? (
								<LoadingOutlined
									style={{ fontSize: 24 }}
									spin
								/>
							) : (
								"Delete"
							)}
						</Button>
					) : (
						<Button
							className="w-full rounded-md bg-[#C8322B] px-4 py-2 text-white"
							onClick={() => setConfirmDelete(true)}
							variant="destructive"
						>
							Yes
						</Button>
					)}
				</div>
			</div>
		</div>
	);
};

export default DeletePartners;
