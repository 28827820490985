import CustomSelect from "@/components/common/CustomSelect";
import FormInput from "@/components/input/FormInput";
import { Button } from "@/components/ui/button";
import { DatePicker } from "@/components/ui/datepicker";
import { useAppDispatch, useAppSelector } from "@/hooks/hooks";
import { cn } from "@/lib/utils";
import { updateBooking } from "@/redux/slices/bookingSlice";
import {
	daysOfWeek,
	defaultDay,
	defaultMonth,
	defaultWeekDay,
	defaultWeekOfMonth,
} from "@/services/constants";
import { months } from "@/services/constants";
import { getSelectedDays } from "@/services/utilities";
import { DateTime } from "luxon";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import toast from "react-hot-toast";
import RecurrenceSummary from "./RecurrenceSummary";

type DayDateFormat = "by_date" | "by_day";

export type IRecurringData = {
	is_recurring: number | null;
	frequency: string | null;
	end_date: string | null;
	day_date_format?: DayDateFormat | null;
	days?: string[] | null;
	dates?: number[] | null;
	week_occurrences?: number[] | null;
	yearly_month?: string | null;
};

interface RecurrenceFormProps {
	defaultType: {
		label: string;
		value: string;
	};
	updateRepeatSelection: Dispatch<any>;
	previousData?: any;
	setPreviousData: Dispatch<SetStateAction<any>>;
	handleClose: () => void;
	handleSaveRecurrenceData: (recurrence_data: any) => void;
	previousRecurringData: IRecurringData | any;
}

const RecurrenceEditForm = ({
	handleClose,
	handleSaveRecurrenceData,
	previousRecurringData,
	previousData,
	setPreviousData,
	defaultType,
	updateRepeatSelection,
}: RecurrenceFormProps) => {
	const { date } = useAppSelector((state) => state.bookings.booking);
	const [startDate, setStartDate] = useState<any>(
		 date ?? previousRecurringData?.start_at?.split(" ")[0] 
	);
	const dispatch = useAppDispatch();
	const [endDate, setEndDate] = useState<any>(
		previousRecurringData?.end_at?.split(" ")[0] ??
			previousRecurringData?.end_date
	);
	const [repeatEvery, setRepeatEvery] = useState<{
		label: string;
		value: string;
	}>(() => {
		switch (defaultType.value) {
			case "daily":
				return { label: "Day", value: "daily" };
			case "weekly":
				return { label: "Week", value: "weekly" };
			case "monthly":
				return { label: "Month", value: "monthly" };
			case "yearly":
				return { label: "Year", value: "yearly" };
			default:
				return { label: "Day", value: "daily" };
		}
	});
	const [optionSelected, setOptionSelected] =
		useState<string>("yearlyOption1");
	const [optionMonthlySelected, setOptionMonthlySelected] =
		useState<string>("monthlyOption1");
	// const [occurrence, setOccurrence] = useState("");
	const [day, setDay] = useState(defaultDay);
	const [selectedDays, setSelectedDays] = useState<string[]>(
		getSelectedDays(previousRecurringData, defaultWeekDay)
	);
	// console.log(previousRecurringData);
	// console.log(selectedDays.map((option) => option));

	const [weekMonth, setWeekMonth] = useState<{
		label: string;
		value: string;
	}>({
		label: defaultWeekOfMonth,
		value: defaultWeekOfMonth,
	});
	const [weekDay, setWeekDay] = useState<{ label: string; value: string }>({
		label: defaultWeekDay.label,
		value: defaultWeekDay.value,
	});
	const [month, setMonth] = useState<{ label: string; value: string }>({
		label: defaultMonth.label,
		value: defaultMonth.value,
	});

	const validateForm = () => {
		if (!startDate || !repeatEvery || !endDate) {
			return false;
		} else if (DateTime.fromISO(endDate) <= DateTime.fromISO(startDate)) {
			return false;
		}
		return true;
	};

	const toggleDaySelection = (day) => {
		if (selectedDays.includes(day)) {
			if (selectedDays.length > 1) {
				setSelectedDays(selectedDays.filter((d) => d !== day));
			}
		} else {
			setSelectedDays([...selectedDays, day]);
		}
	};

	// useEffect(() => {
	// 	if (repeatEvery.value === "yearly") {
	// 		setOccurrence("1");
	// 	}
	// }, [repeatEvery]);

	// useEffect(() => {
	// 	let text = "";
	// 	if (repeatEvery.label === "Day") {
	// 		text = `Occurs every ${parseInt(occurrence) > 1 ? occurrence : ""} ${parseInt(occurrence) > 1 ? "days" : "day"} starting ${startDate} until ${endDate}`;
	// 	} else if (repeatEvery.label === "Week") {
	// 		text = `Occurs every ${parseInt(occurrence) > 1 ? occurrence : ""} ${parseInt(occurrence) > 1 ? "weeks" : "week"} on ${selectedDays.join(", ")} starting ${startDate} until ${endDate}`;
	// 	}
	// 	setOccurenceText(text);
	// }, [
	// 	repeatEvery,
	// 	occurrence,
	// 	startDate,
	// 	endDate,
	// 	selectedDays,
	// 	defaultType,
	// ]);

	useEffect(() => {
		// Set selected day based on the start date
		const startDay = DateTime.fromISO(startDate).weekdayLong;
		if (startDay && !selectedDays.length) {
			setSelectedDays([startDay]);
		}
	}, [startDate]);

	const handleSubmit = (e) => {
		const recurringData: IRecurringData = {
			is_recurring: 1,
			frequency: repeatEvery.value,
			day_date_format: "by_day",
			...(endDate && { end_date: endDate }),
		};
		// Determine the day_date_format
		if (repeatEvery.value === "monthly") {
			recurringData.day_date_format =
				optionMonthlySelected === "monthlyOption1"
					? "by_date"
					: "by_day";
		} else if (repeatEvery.value === "yearly") {
			recurringData.day_date_format =
				optionSelected === "yearlyOption1" ? "by_date" : "by_day";
		}
		if (repeatEvery.value === "weekly") {
			recurringData.days = selectedDays.map((day) => day.toLowerCase());
		}
		if (
			repeatEvery.value === "monthly" &&
			optionMonthlySelected === "monthlyOption1"
		) {
			recurringData.dates = [parseInt(day)];
		} else if (
			repeatEvery.value === "yearly" &&
			optionSelected === "yearlyOption1"
		) {
			recurringData.dates = [parseInt(month.value), parseInt(day)];
		}
		// Determine the week_occurrences
		if (
			repeatEvery.value === "monthly" &&
			optionMonthlySelected === "monthlyOption2"
		) {
			recurringData.week_occurrences = [
				parseInt(weekMonth.value),
				parseInt(weekDay.value),
			];
		} else if (
			repeatEvery.value === "yearly" &&
			optionSelected === "yearlyOption2"
		) {
			recurringData.week_occurrences = [parseInt(weekMonth.value)];
		}
		// Add the yearly_month if frequency is yearly
		if (repeatEvery.value === "yearly" && month.label) {
			recurringData.yearly_month = month.label;
		}
		e.preventDefault();
		if (validateForm()) {
			handleSaveRecurrenceData(recurringData);
		} else {
			toast.error("Please fill in all required fields.");
		}
	};

	const renderWeeklyOptions = () => {
		if (repeatEvery.value !== "weekly") {
			return null;
		}
		return (
			<div className="flex items-center gap-4">
				<p>On Day</p>
				<div className="flex items-center gap-1">
					{daysOfWeek.map((day) => (
						<button
							key={day.value}
							type="button"
							onClick={() => toggleDaySelection(day.label)}
							className={cn(
								"h-[36px] w-[36px] rounded-md",
								selectedDays.includes(day.label)
									? "bg-primary text-white"
									: "bg-white"
							)}
						>
							{day.label.charAt(0)}
						</button>
					))}
				</div>
			</div>
		);
	};

	const renderYearlyOptions = () => {
		if (repeatEvery.value !== "yearly") {
			return null;
		}
		return (
			<div className="flex flex-col gap-y-2 pl-8">
				<div className={cn("flex items-center gap-2")}>
					<input
						type="radio"
						name="yearlyOption"
						className="accent-primary"
						checked={optionSelected === "yearlyOption1"}
						onChange={() => setOptionSelected("yearlyOption1")}
					/>
					<p className="text-xs">On </p>
					<CustomSelect
						className={cn("w-[125px]", {
							"pointer-events-none opacity-50":
								optionSelected === "yearlyOption2",
						})}
						value={month}
						onChange={(selectedOption: any) =>
							setMonth(selectedOption)
						}
						options={months}
					/>
					<FormInput
						inputType="text"
						className={cn("flex w-[50px] items-center", {
							"pointer-events-none opacity-50":
								optionSelected === "yearlyOption2",
						})}
						value={day}
						onChange={(e) => setDay(e.target.value)}
					/>
				</div>
				<div className={cn("flex items-center gap-2")}>
					<input
						type="radio"
						className="accent-primary"
						name="yearlyOption"
						checked={optionSelected === "yearlyOption2"}
						onChange={() => setOptionSelected("yearlyOption2")}
					/>
					<p className="text-xs">On the</p>
					<CustomSelect
						className={cn("w-[125px]", {
							"pointer-events-none opacity-50":
								optionSelected === "yearlyOption1",
						})}
						value={weekMonth}
						onChange={(selectedOption: any) =>
							setWeekMonth(selectedOption)
						}
						options={[
							{ label: "First", value: "1" },
							{ label: "Second", value: "2" },
							{ label: "Third", value: "3" },
							{ label: "Fourth", value: "4" },
						]}
					/>
					<CustomSelect
						className={cn("w-[125px]", {
							"pointer-events-none opacity-50":
								optionSelected === "yearlyOption1",
						})}
						value={weekDay}
						onChange={(selectedOption: any) =>
							setWeekDay(selectedOption)
						}
						options={daysOfWeek}
					/>
					<p>Of</p>
					<CustomSelect
						className={cn("w-[125px]", {
							"pointer-events-none opacity-50":
								optionSelected === "yearlyOption1",
						})}
						value={month}
						onChange={(selectedOption: any) =>
							setMonth(selectedOption)
						}
						options={months}
					/>
				</div>
			</div>
		);
	};

	const renderMonthlyOptions = () => {
		if (repeatEvery.value !== "monthly") {
			return null;
		}
		return (
			<div className="flex flex-col gap-y-2 pl-4">
				<div className="flex items-center gap-3">
					<input
						type="radio"
						className="accent-primary"
						name="monthlyOption"
						checked={optionMonthlySelected === "monthlyOption1"}
						onChange={() =>
							setOptionMonthlySelected("monthlyOption1")
						}
					/>
					<p>On day</p>
					<FormInput
						inputType="text"
						className={cn("w-[71px]", {
							"pointer-events-none opacity-50":
								optionMonthlySelected === "monthlyOption2",
						})}
						value={day}
						onChange={(e) => {
							const value = e.target.value;
							if (
								/^\d*$/.test(value) &&
								(value === "" || parseInt(value) <= 31)
							) {
								setDay(value);
							}
						}}
					/>
				</div>
				<div className="flex items-center gap-2">
					<input
						type="radio"
						className="accent-primary"
						name="monthlyOption"
						checked={optionMonthlySelected === "monthlyOption2"}
						onChange={() =>
							setOptionMonthlySelected("monthlyOption2")
						}
					/>
					<p>On the</p>
					<CustomSelect
						className={cn("w-[172px]", {
							"pointer-events-none opacity-50":
								optionMonthlySelected === "monthlyOption1",
						})}
						value={weekMonth}
						onChange={(selectedOption: any) =>
							setWeekMonth(selectedOption)
						}
						options={[
							{ label: "First", value: "1" },
							{ label: "Second", value: "2" },
							{ label: "Third", value: "3" },
							{ label: "Fourth", value: "4" },
						]}
					/>
					<CustomSelect
						className={cn("w-[172px]", {
							"pointer-events-none opacity-50":
								optionMonthlySelected === "monthlyOption1",
						})}
						value={weekDay}
						onChange={(selectedOption: any) =>
							setWeekDay(selectedOption)
						}
						options={daysOfWeek}
					/>
				</div>
			</div>
		);
	};

	return (
		<form onSubmit={handleSubmit} className="w-full rounded-[30px] ">
			<div className="flex w-full justify-center rounded-tl-[30px] rounded-tr-[30px] bg-white py-4">
				<h1 className="font-semibold text-primary">Set Recurrence</h1>
			</div>
			<div className="flex flex-col gap-y-5 px-6 py-4 ">
				<div className="flex items-center gap-2">
					<p>Start</p>
					<DatePicker
						title="Start Date"
						className="w-[275px]"
						date={
							startDate
								? DateTime.fromISO(startDate).toJSDate()
								: DateTime.fromISO(startDate).toJSDate()
						}
						setDate={(date) => {
							if (date) {
								const luxonDate = DateTime.fromJSDate(date);
								setStartDate(luxonDate.toISODate() ?? "");
								setPreviousData({
									...previousData,
									date: luxonDate.toISODate(),
								});
								dispatch(
									updateBooking({
										date: luxonDate.toISODate() ?? "",
									})
								);
							}
						}}
					/>
				</div>
				{/* Repeat Every */}
				<div className="flex items-center gap-2">
					<p>Repeat Every</p>
					<div className="flex gap-2">
						{/* <FormInput
							className={cn(
								"h-[39px] w-[50px] disabled:bg-gray-300"
							)}
							inputType="text"
							disabled={repeatEvery.value === "yearly"}
							value={occurrence}
							onChange={(e) => {
								const value = e.target.value;
								if (
									/^\d*$/.test(value) &&
									(value === "" || parseInt(value) <= 100)
								) {
									setOccurrence(e.target.value);
								}
							}}
						/> */}
						<CustomSelect
							value={repeatEvery}
							onChange={(selectedOption: any) => {
								setRepeatEvery(selectedOption);
								updateRepeatSelection(selectedOption);
							}}
							className="!h-[36px] w-[172px]"
							options={[
								{ label: "Day", value: "daily" },
								{ label: "Week", value: "weekly" },
								{ label: "Month", value: "monthly" },
								{ label: "Year", value: "yearly" },
							]}
						/>
					</div>
				</div>
				{/* Yearly */}
				{repeatEvery.value === "yearly" && renderYearlyOptions()}
				{/* Monthly */}
				{repeatEvery.value === "monthly" && renderMonthlyOptions()}
				{/* Weekly */}
				{repeatEvery.value === "weekly" && renderWeeklyOptions()}
				{/* End Date */}
				<div className="flex items-center gap-2">
					<p>End</p>
					<DatePicker
						title="Select Date"
						className="w-[275px]"
						disabledDate={
							startDate
								? DateTime.fromISO(startDate).toJSDate()
								: undefined
						}
						disablePastDates
						date={
							endDate
								? DateTime.fromISO(endDate).toJSDate()
								: undefined
						}
						setDate={(date) => {
							if (date) {
								const luxonDate = DateTime.fromJSDate(date);
								setEndDate(luxonDate.toISODate() ?? "");
							}
						}}
					/>
				</div>

				<RecurrenceSummary />
				{/* <p>

					{repeatEvery.value.toLowerCase() === "daily" && (
						<span>
							Occurs every day starting {startDate} until{" "}
							{endDate}
						</span>
					)}

					{repeatEvery.value.toLowerCase() == "weekly" && (
						<span>
							Occurs every week{" "}
							{selectedDays.map((item, idx) => (
								<span key={idx}>{item}, </span>
							))}{" "}
							starting {startDate} until {endDate}
						</span>
					)}
					{/* {repeatEvery.label === "Year" && <span>Occurs every {parseInt(occurrence) > 1 ? occurrence : null}  {parseInt(occurrence) > 1 ? "weeks" : "week"} starting{" "} {startDate}</span>} 
				</p> 
				*/}
				<div className="flex w-full gap-2">
					<Button
						className="w-full"
						type="button"
						variant="outlinecustom"
						onClick={handleClose}
					>
						Cancel
					</Button>
					<Button className="w-full" onClick={handleSubmit}>
						Save
					</Button>
				</div>
			</div>
		</form>
	);
};

export default RecurrenceEditForm;
