import CustomSelect from "@/components/common/CustomSelect";
import CustomShadSelect from "@/components/common/CustomShadSelect";
import FormInput from "@/components/input/FormInput";
import { Button } from "@/components/ui/button";
import { useAppSelector } from "@/hooks/hooks";
import { useAddNewPartnerMutation } from "@/redux/features/api/adminEndpoints";
import { countryCodes } from "@/services/constants";
import { notifyWithIcon } from "@/services/utilities";
import {
	AdminAddPartnerParams,
	AdminAddPartnerSchema,
	TeamMemberRoles,
} from "@/types/team-member";
import { LoadingOutlined } from "@ant-design/icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

const AddPartner = ({ handleClose }) => {
	const [countryCode, setCountryCode] = useState("+1");
	const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");
	const {
		register,
		handleSubmit,
		formState: { errors },
		watch,
		setValue,
	} = useForm<AdminAddPartnerParams>({
		resolver: zodResolver(AdminAddPartnerSchema),
	});
	const onSubmit: SubmitHandler<AdminAddPartnerParams> = async (data) => {
		if (
			userRole === TeamMemberRoles.TeamMember ||
			userRole === TeamMemberRoles.PartnerManager
		)
			return notifyWithIcon("error", "You are not allowed to perform this action");
		const dataFormat = {
			company_name: data.company_name,
			superadmin_name: data.superadmin_name,
			superadmin_email: data.superadmin_email,
			superadmin_phone_number: data.superadmin_phone_number
				? countryCode + data.superadmin_phone_number.slice(-10)
				: undefined,
		};
		addPartner({ data: dataFormat });
	};

	const [addPartner, { isLoading, isSuccess, isError, error }] =
		useAddNewPartnerMutation();

	useEffect(() => {
		if (isSuccess) {
			notifyWithIcon("", "Partner added successfully");
			handleClose();
		}
		if (isError && error) {
			const errorMessage =
				(
					error as {
						data?: { message?: string };
					}
				)?.data?.message || "An error occurred";
			return notifyWithIcon("", errorMessage);
		}
	}, [isSuccess, isError]);

	return (
		<div className="flex w-full flex-col gap-y-3 rounded-[30px] bg-[#F5F5F5] ">
			<div className="flex w-full cursor-pointer justify-center rounded-tl-[30px] rounded-tr-[30px]  border-b border-b-primary bg-white pb-2 pt-4 text-center text-[15px] font-semibold text-primary ">
				<h1 className="">Add Partner</h1>
			</div>
			<div className="px-4 ">
				<div>
					<p className="border-b border-b-primary py-2 text-sm text-basecolor ">
						Invite a partner's account to register a partner
					</p>
				</div>

				<form
					onSubmit={handleSubmit(onSubmit)}
					className="flex flex-col gap-y-3 pt-2"
					id="add-partner-form"
				>
					<FormInput
						inputType="text"
						placeholder="Partner Business Name"
						{...register("company_name", {
							required: "Partner Business Name is required",
						})}
						error={errors.company_name}
					/>
					<div className="flex gap-4">
						<div className="w-full">
							<FormInput
								inputType="text"
								placeholder="Admin Name"
								{...register("superadmin_name", {
									required: "Admin Name is required",
								})}
								error={errors.superadmin_name}
							/>
						</div>
						<div className="w-full">
							<CustomSelect
								placeholder="Super Admin"
								className=""
								isDisabled={true}
							/>
						</div>
					</div>
					<div className="flex gap-4">
						<FormInput
							inputType="email"
							placeholder="info@businessemail.com"
							{...register("superadmin_email", {
								required: "Email is required",
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
									message: "Invalid email address",
								},
							})}
							error={errors.superadmin_email}
						/>
						{/* <FormInput
							inputType="tel"
							placeholder="+1 (000)000 - 000"
							{...register("phone", {
								pattern: {
									value: /^\+?\d{10,14}$/,
									message: "Invalid phone number",
								},
							})}
							error={errors.phone}
						/> */}
						<div className="flex-1">
							<div className="flex items-center">
								<CustomShadSelect
									className="h-[42px] w-fit rounded-r-none border-r-transparent"
									placeholder="+1"
									label="Country Codes"
									value={countryCode}
									options={countryCodes}
									onChange={(value) => {
										setCountryCode(value);
									}}
									labelClassName="px-2"
									itemClassName="px-8"
								/>
								<FormInput
									inputType="text"
									className="h-[42px] min-w-[160px] rounded-l-none py-0"
									placeholder="Phone number"
									maxLength={10}
									value={watch("superadmin_phone_number")}
									error={
										errors?.superadmin_phone_number?.message
											? ""
											: undefined
									}
									onChange={(e) => {
										setValue(
											"superadmin_phone_number",
											e.target.value
										);
									}}
								/>
							</div>
							{errors.superadmin_phone_number && (
								<small className="mt-1 text-xs text-red-500">
									{errors.superadmin_phone_number.message}
								</small>
							)}
						</div>
					</div>
				</form>
			</div>

			<div className="mt-12 flex w-full gap-2 px-4 py-6">
				<Button
					className="w-full"
					variant="outlinecustom"
					onClick={handleClose}
				>
					Cancel
				</Button>
				<Button
					className="w-full"
					variant="default"
					type="submit"
					form="add-partner-form"
				>
					{isLoading ? (
						<LoadingOutlined style={{ fontSize: 24 }} spin />
					) : (
						"Send Invite"
					)}
				</Button>
			</div>
		</div>
	);
};

export default AddPartner;
