import CalendarImage from "@/assets/icons/full_calendar.svg";
import useCustomToast from "@/components/CustomToast";
import { LoaderButton } from "@/components/Loader/LoaderButton";
import { useUpdateAdminBookingStatusMutation } from "@/redux/features/apiSlice";
import { formatDateString, formatTime } from "@/services/utilities";
import { useState } from "react";
import { FaLocationDot, FaRegClock } from "react-icons/fa6";
import { LuCalendarCheck, LuContact, LuUsers } from "react-icons/lu";
import { PiBuildings } from "react-icons/pi";

const PendingBooking = ({ data }) => {
	const [updateStatus, updateAdminBookingStatusMutation] =
		useUpdateAdminBookingStatusMutation();
	const [updateState, setUpdateState] = useState<"decline" | "approve">(
		"decline"
	);
	const customToast = useCustomToast();

	const handleDeclineBooking = async () => {
		try {
			customToast("Declining reservation", {
				id: "decline-booking",
				type: "loading",
			});
			await updateStatus({
				booking_id: data.id,
				data: { status: "declined" },
			});
			customToast("Reservation Declined", {
				id: "decline-booking",
				type: "success",
			});
		} catch (error) {
			customToast("Reservation could not be declined", {
				id: "decline-booking",
				type: "error",
			});
		}
	};

	const handleApproveBooking = async () => {
		try {
			customToast("Approving reservation", {
				id: "approve-booking",
				type: "loading",
			});
			await updateStatus({
				booking_id: data.id,
				data: { status: "approved" },
			});
			customToast("Reservation Approved", {
				id: "approve-booking",
				type: "success",
			});
		} catch (error) {
			customToast("Reservation could not be approved", {
				id: "approve-booking",
				type: "error",
			});
		}
	};

	return (
		<div className="flex flex-col gap-y-6">
			<div className="flex justify-between border-b-2 pb-2">
				<div>
					<h1 className=" pb-2 text-3xl font-semibold text-basecolor">
						Reservation Details
					</h1>
					<p className="flex items-center gap-2 font-medium text-basecolor">
						<span className="text-[#BFBFBF]">
							<PiBuildings />
						</span>
						{data?.room?.name ?? "N/A"}
					</p>
					<p className="flex items-center gap-2 pt-2 font-medium text-basecolor">
						<span className="text-[#BFBFBF]">
							<FaLocationDot />
						</span>
						{data?.room?.address ?? "N/A"}
					</p>
				</div>
				<div className="flex flex-col gap-2">
					<img
						src={CalendarImage}
						className="ml-auto h-[57px] w-[55px]"
					/>
					{/* <Button
						variant="outlinecustom"
						className="border-basecolor bg-transparent text-basecolor"
					>
						Change Room
					</Button> */}
				</div>
			</div>

			{/* Info  */}

			<div className="flex gap-x-6">
				<div className="w-full">
					<h1 className="max-w-full divide-y-0 border-b-2 border-b-[#BFBFBF] pb-2 font-semibold  text-[#323539]">
						{data?.title ?? "N/A"}
					</h1>
					<section className="hidden flex-col gap-y-2 pt-2 md:flex">
						<p className="flex items-center gap-2">
							<span className="text-[#BFBFBF]">
								<LuCalendarCheck />
							</span>
							{data?.start_at
								? formatDateString(data?.start_at.split(" ")[0])
								: "N/A"}
						</p>
						<p className="flex items-center gap-2 ">
							<span className="text-[#BFBFBF]">
								<FaRegClock />
							</span>
							{data?.start_at
								? formatTime(data.start_time)
								: "N/A"}{" "}
							-{" "}
							{data?.end_time ? formatTime(data.end_time) : "N/A"}
						</p>

						<p className="flex items-center gap-2 ">
							<LuContact className="text-xl text-[#BFBFBF]" />
							<span className=" ">
								{data?.company?.name ?? "N/A"}
							</span>
						</p>
						<p className="flex items-center gap-2 ">
							<span className="flex h-[13px] w-[13px] items-center justify-center rounded-full border border-[#BFBFBF] bg-[#fff] p-2 text-xs text-[#BFBFBF]">
								{data?.host?.name.split("")[0][0]}
							</span>
							{data?.host?.name ?? "N/A"}
						</p>
						<p className="flex items-center gap-2">
							<LuUsers className="text-[#BFBFBF]" />
							<span className=" text-[#6B7280]">
								{data?.meeting_members.length} Attendees
							</span>
						</p>
					</section>
				</div>
				<div className="w-full">
					{data.room.photos.length ? (
						<img
							src={data.room.photos[0]}
							className="h-[171px] w-[352px] rounded-md"
						/>
					) : (
						<div className="flex h-[171px] w-[352px] flex-col justify-center rounded-md bg-[#DCDCDC] px-4">
							<div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-[#C7C7C7] ">
								<p className="select-none text-lg font-semibold text-white">
									{data.room?.name
										.split(" ")[0][0]
										.toUpperCase()}
								</p>
							</div>
						</div>
					)}
				</div>
			</div>

			<div className="flex w-full items-center gap-x-1.5 pb-2 pt-4">
				<LoaderButton
					className="w-full rounded-md border border-primary px-4 py-2 text-primary"
					variant={"outlinecustom"}
					loading={
						updateState === "decline" &&
						updateAdminBookingStatusMutation.isLoading
					}
					disabled={updateAdminBookingStatusMutation.isLoading}
					loaderSize={20}
					onClick={() => {
						setUpdateState("decline");
						handleDeclineBooking();
					}}
				>
					Decline
				</LoaderButton>
				<LoaderButton
					className=" w-full rounded-md bg-[#138576] px-4 py-2 text-white"
					loading={
						updateState === "approve" &&
						updateAdminBookingStatusMutation.isLoading
					}
					disabled={updateAdminBookingStatusMutation.isLoading}
					loaderSize={20}
					onClick={() => {
						setUpdateState("approve");
						handleApproveBooking();
					}}
				>
					Approve
				</LoaderButton>
			</div>
		</div>
	);
};

export default PendingBooking;
