import AmenitiesList from "@/components/admin/location/AmenitiesList";
import LocationInfoOverview from "@/components/admin/location/LocationInfoOverview";
import RoomList from "@/components/admin/location/rooms/RoomList";
import Wrapper from "@/container/Wrapper";
import { useAppSelector } from "@/hooks/hooks";
import { cn } from "@/lib/utils";
import { useGetLocationQuery } from "@/redux/features/api/locationEndpoints";
import {
	useGetAllAdminLocationAmenitiesQuery,
	useGetAllAdminLocationRoomsQuery,
} from "@/redux/features/apiSlice";
import { CalendarRange, ChevronLeft } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

const Location = () => {
	const { user } = useAppSelector((state) => state.user);
	const { location_id } = useParams();
	const { data: locationData } = useGetLocationQuery({
		user_id: user?.business_id ?? 0,
		location_id: parseInt(location_id ?? ""),
	});
	const [rooms, setRooms] = useState<any>([]);
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState<"rooms" | "amenities">("rooms");
	const { data, isSuccess, isFetching } = useGetAllAdminLocationRoomsQuery({
		id: parseInt(location_id!),
	});
	const {
		isSuccess: isAmenitiesSuccess,
		isError: isAmenitiesError,
		isFetching: isAmenitiesFetching,
		data: amenitiesList,
	} = useGetAllAdminLocationAmenitiesQuery({
		id: parseInt(location_id!),
	});
	useEffect(() => {
		if (isSuccess) {
			setRooms(data.data);
		}
	}, [isSuccess, isFetching]);

	return (
		<div className="flex h-full flex-col gap-y-6 ">
			{/* Location Header  */}
			<div className="flex items-start justify-between pr-8">
				<div className="flex items-center gap-4">
					<button onClick={() => navigate(-1)}>
						<ChevronLeft />
					</button>
					<div>
						<p className="text-xs font-normal">
							{user?.business?.name ?? ""}
						</p>
						<h1 className="text-[20px] font-semibold text-basecolor">
							Locations
						</h1>
					</div>
				</div>
				<div className="flex items-center gap-4">
					<div className="flex w-[144px]  gap-1 rounded-[99px] p-1 shadow-[0px_0px_5px_rgba(0,0,0,0.15)]">
						<div className="flex h-[48px] w-[48px] items-center justify-center rounded-[99px] bg-primary/20 p-2">
							<CalendarRange
								width={20}
								className=" text-primary"
							/>
						</div>
						<div className="">
							<p className="pb-0 text-lg font-semibold text-primary">
								{locationData?.data.total_bookings ?? 0}
							</p>
							<p className="pt-0 text-[13px] font-[400] text-primary/60">
								Reservations
							</p>
						</div>
					</div>
					<div className="flex w-[124px] gap-1 rounded-[99px] p-1 shadow-[0px_0px_5px_rgba(0,0,0,0.15)]">
						<div className="flex h-[48px] w-[48px] items-center justify-center rounded-[99px] bg-primary/20 p-2">
							{/* <img src={Office} alt="Office Icon" /> */}
							<i className="mgc_box_line text-xl before:!text-primary" />
						</div>
						{/* //This should change based on the view  */}
						<div className=" ">
							<p className="pb-0 text-lg font-semibold text-primary">
								{rooms?.length ?? 0}
							</p>
							<p className="pt-0 text-[13px] font-[400] text-primary">
								Spaces
							</p>
						</div>
					</div>
				</div>
			</div>
			{/* //Location Profile  */}
			<div className="px-8">
				<LocationInfoOverview />
			</div>

			<div className="flex flex-col gap-y-6 px-4">
				{/* tab list  */}
				<div className="flex h-full w-full flex-col px-6  pb-3">
					{/* Tab  */}
					<div className="mb-4 flex w-full cursor-pointer border-b border-b-[#9CA3AF] text-[15px] font-semibold text-[#858C95] ">
						<button
							onClick={() => setActiveTab("rooms")}
							className={cn("flex w-full justify-center py-1", {
								"border-b-2 border-b-primary text-primary":
									activeTab === "rooms",
							})}
						>
							<div
								className={cn("flex items-center gap-2 ", {
									"text-primary": activeTab === "rooms",
								})}
							>
								<p>Spaces</p>
								<span className=" flex h-[25px]  w-[25px] items-center justify-center rounded-full bg-primary/20  text-center text-xs text-primary">
									{rooms?.length}
								</span>
							</div>
							{/*  */}
						</button>
						<div
							onClick={() => setActiveTab("amenities")}
							className={cn("flex w-full justify-center py-1", {
								"border-b-2 border-b-primary text-primary":
									activeTab === "amenities",
							})}
						>
							<div
								className={cn("flex items-center gap-2 ", {
									"text-primary": activeTab === "amenities",
								})}
							>
								<p>Amenities</p>
								<span className=" flex h-[25px]  w-[25px] items-center justify-center rounded-full bg-primary/20  text-center text-xs text-primary">
									{amenitiesList?.data?.length ?? 0}
								</span>
							</div>
						</div>
					</div>
					{/* Tab list  */}
					<div className="">
						{activeTab === "rooms" && (
							<div>
								<Wrapper fetching={isFetching} className="">
									<RoomList rooms={rooms} />
								</Wrapper>
							</div>
						)}
						{activeTab === "amenities" && (
							<div className="w-full">
								<Wrapper
									fetching={isAmenitiesFetching}
									className=""
								>
									{isAmenitiesSuccess &&
										amenitiesList?.data && (
											<AmenitiesList
												amenities={amenitiesList}
											/>
										)}

									{isAmenitiesError && (
										<p>Error Fetching Details</p>
									)}
								</Wrapper>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Location;
