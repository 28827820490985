import CustomShadSelect from "@/components/common/CustomShadSelect";
import { useAppDispatch, useAppSelector } from "@/hooks/hooks";
import { useGetLocationAnalyticsQuery } from "@/redux/features/api/locationEndpoints";
import { setQueryData } from "@/redux/slices/analyticsSlice";
import { AnalyticsQueryParams } from "@/types/location";
import { useState } from "react";
import StatisticsLineChart from "../statistics/StatisticsLineChart";

const MeetingsBookedByDayOfWeek: React.FC = () => {
	const dispatch = useAppDispatch();
	const [selectedTime, setSelectedTime] = useState("all");

	const businessId =
		useAppSelector((state) => state.user.user?.business_id) ?? 0;
	const queryData = useAppSelector((state) => state.analytics.query_data);

	const getAdminLocationsQuery = useGetLocationAnalyticsQuery({
		user_id: businessId,
		query_data: queryData,
	});

	const createLineData = (title: string, value: number) => ({
		title,
		value,
	});

	const bookedByDayOfWeekData = getAdminLocationsQuery.data
		? Object.keys(
				getAdminLocationsQuery.data.data.trends.by_days_of_week
			).map((keyName) => {
				const value = parseInt(
					getAdminLocationsQuery?.data?.data.trends.by_days_of_week[
						keyName
					] ?? "0"
				);
				return createLineData(keyName, value);
			})
		: [];

	const total = bookedByDayOfWeekData.reduce(
		(acc, item) => acc + item.value,
		0
	);

	const timesList = [
		{ label: "All", value: "all" },
		...(getAdminLocationsQuery.data
			? Array.from({ length: 24 }, (_, i) => ({
					label: i.toString().padStart(2, "0") + ":00",
					value: i.toString().padStart(2, "0") + ":00",
				}))
			: []),
	];

	return (
		<section className="flex h-[320px] flex-1 flex-col rounded-lg bg-white p-8 pb-[41px]">
			<div className="flex justify-between">
				<div className="mb-6 flex flex-col space-y-2">
					<h3 className="font-manrope text-lg font-bold leading-[16px] tracking-[0.5px] text-[#454D56]">
						Reservations by Week Day
					</h3>
					<p className="text-xs leading-[120%] tracking-[0.41px] text-[#596574]">
						Total Reservations:{" "}
						<span className="text-base font-bold">{total}</span>
					</p>
				</div>
				<CustomShadSelect
					className="h-9 max-w-[145px]"
					placeholder="Time"
					options={timesList}
					value={selectedTime}
					onChange={(value) => {
						setSelectedTime(value);
						dispatch(
							setQueryData({
								time_filter_for_trending_by_days:
									value === "all" ? undefined : value,
							} as AnalyticsQueryParams)
						);
					}}
					labelClassName="px-2"
					itemClassName="px-8"
				/>
			</div>
			<StatisticsLineChart
				formattedData={bookedByDayOfWeekData}
				dayFormat
			/>
		</section>
	);
};

export default MeetingsBookedByDayOfWeek;
