import { Route, Routes } from "react-router";
import AddLocationLayout from "./AddLocationLayout";
import EditLocation from "./EditLocation";
import LocationInfo from "./fragments/AddLocationInfo";
import OperationalHours from "./fragments/AddLocationOperationalHour";
import Locations from "./List";
import Location from "./Profile";
import { TeamMemberRoles } from "@/types/team-member";
import UserProtectedRoute from "@/provider/UserProtectedRoute";

const Home: React.FC = () => {
	return (
		<Routes>
			<Route index element={<Locations />} />
			<Route path=":location_id/*" element={<Location />} />

			<Route
				element={
					<UserProtectedRoute
						redirect="/admin/location-management"
						allowedRoles={[
							TeamMemberRoles.SuperAdmin,
							TeamMemberRoles.RoomManager,
							TeamMemberRoles.BusinessAdmin,
						]}
					/>
				}
			>
				<Route
					path="/edit-location/:location_id"
					element={<EditLocation />}
				/>
				<Route element={<AddLocationLayout />}>
					<Route path="/add-location" element={<LocationInfo />} />
					<Route
						path="/add-location/:location_id/operating-hours"
						element={<OperationalHours />}
					/>
				</Route>
			</Route>

			{/* <Route path=":id/booking/*" element={<BookingDetails />} /> */}
		</Routes>
	);
};

export default Home;
