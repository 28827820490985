import CustomSelect from "@/components/common/CustomSelect";
import Search from "@/components/input/Search";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useState } from "react";
import BookingListCard from "./BookingListCard";
// import { BsDownload } from "react-icons/bs";

const BookingList = ({ bookings }) => {
	const [searchQuery, setSearchQuery] = useState("");
	const [sortBy, setSortBy] = useState<{
		value: string;
		label: string;
	} | null>({ value: "", label: "Sort By" });

	const filteredBookings = bookings
		.filter((booking) => {
			return booking.title
				.toLowerCase()
				.includes(searchQuery.toLowerCase());
		})
		.sort((a, b) => {
			if (sortBy?.value === "title") {
				return a.title.localeCompare(b.title);
			} else if (sortBy?.value === "date") {
				return (
					new Date(a.start_at).getTime() -
					new Date(b.start_at).getTime()
				);
			} else if (sortBy?.value === "host") {
				return a.host?.name.localeCompare(b.host?.name);
			}
			return 0; // No sorting by default
		});
	// const initialItems: any[] = bookings;
	// const [items] = useState<any>(initialItems);
	const [selectedItems, setSelectedItems] = useState<any>([]);
	const [, setSelectAll] = useState<boolean>(false);

	// const toggleSelectAll = () => {
	// 	if (selectAll) {
	// 		setSelectedItems([]);
	// 	} else {
	// 		setSelectedItems(items);
	// 	}
	// 	setSelectAll(!selectAll);
	// };

	const toggleSelectItem = (item: any) => {
		if (selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
			setSelectedItems(
				selectedItems.filter(
					(selectedItem) => selectedItem.id !== item.id
				)
			);
		} else {
			setSelectedItems([...selectedItems, item]);
		}
		setSelectAll(false); // Unselect select all if any individual item is selected
	};

	return (
		<>
			<div className="mb-4  flex w-full flex-col gap-2">
				<div className="mb-4  flex h-9 w-full gap-2">
					<div className="flex w-full flex-[0.6]">
						<Search
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
							placeholder="Search by title"
							className="h-9 w-full !bg-white"
						/>
					</div>
					<div className="flex w-full flex-[0.4] gap-2">
						<CustomSelect
							placeholder="Sort by"
							className="w-full min-w-[157px]"
							options={[
								{ value: "", label: "Sort By" },
								{ value: "title", label: "Title" },
								{ value: "date", label: "Date" },
								{ value: "host", label: "Host" },
							]}
							value={sortBy}
							onChange={(selectedOption: any) =>
								setSortBy(selectedOption)
							}
						/>
					</div>
				</div>
				{/* <div className="flex justify-between px-4">
					<div className="flex items-center gap-2 text-[14px]">
						<input
							id="select-all"
							type="checkbox"
							onClick={toggleSelectAll}
							disabled={true}
						/>
						<label
							className={cn("", {
								"text-[#7D7E80]": !selectAll,
							})}
						>
							Select All
						</label>
					</div>
					<TooltipProvider>
						<div className="flex items-center gap-1">
							<Tooltip delayDuration={0}>
								<TooltipTrigger asChild>
									<Button
										variant="outlinecustom"
										disabled={!selectAll}
										className="h-7 px-1.5 py-1.5"
									>
										<TbTrash />
									</Button>
								</TooltipTrigger>
								<TooltipContent side="top" sideOffset={10}>
									Delete
								</TooltipContent>
							</Tooltip>
						</div>
					</TooltipProvider>
				</div> */}
			</div>
			<ScrollArea className="h-[70vh] w-full">
				<div className="flex flex-col gap-y-4">
					<div className="flex flex-col gap-2 p-1">
						{filteredBookings.map((item, index) => (
							<BookingListCard
								bookingdata={item}
								key={index}
								onSelect={() => toggleSelectItem(item)}
								checked={selectedItems.some(
									(selectedItem) =>
										selectedItem.id === item.id
								)}
							/>
						))}
					</div>
				</div>
			</ScrollArea>
		</>
	);
};

export default BookingList;
