import RescheduleIcon from "@/assets/icons/reschedule.svg";
import CapacityHolder from "@/components/common/CapacityHolder";
import Modal from "@/components/modals/Modal";
import BookingOverview from "@/components/modals/admin/bookings/BookingOverview";
import DeleteBooking from "@/components/modals/admin/bookings/DeleteBooking";
import RescheduleConfirmationCard from "@/components/modals/admin/partners/RescheduleCard";
import { useAppDispatch } from "@/hooks/hooks";
import { useDeleteRecurringReservationMutation } from "@/redux/features/api/adminBookingEndpoints";
import { useUpdateAdminBookingStatusMutation } from "@/redux/features/apiSlice";
import { setDate } from "@/redux/slices/eventSlice";
import { formatDateString, formatTime } from "@/services/utilities";
import { Booking } from "@/types/admin/booking";
import { message } from "antd";
import { useEffect, useState } from "react";
import { BsCheckCircle, BsInfoCircle } from "react-icons/bs";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import { IoMdTime } from "react-icons/io";
import { LuBellRing, LuCalendar, LuContact, LuUsers } from "react-icons/lu";
import { MdOutlineCancel } from "react-icons/md";
import { TbTrash } from "react-icons/tb";

interface EventCardProps {
	status: "approved" | "pending" | "onprogress";
	data: Booking;
}

const EventCard: React.FC<EventCardProps> = ({ status, data }) => {
	const dispatch = useAppDispatch();
	const [showRescheduleBooking, setShowResheduleBooking] = useState(false);
	const [submittedStatus, setSubmittedStatus] = useState<string | null>(null);
	const [showDeleteBookingModal, setShowDeleteBookingModal] = useState(false);
	const [showBookingInfo, setShowBookingInfo] = useState(false);
	const tab = status == "approved" ? "room" : "booking";
	const [messageApi, contextHolder] = message.useMessage();
	const key = "updateBookingStatus";
	const [updateStatus, { isSuccess, isError, isLoading }] =
		useUpdateAdminBookingStatusMutation();
	const [
		deleteRecurring,
		{
			isSuccess: isRecurringDeletionSuccess,
			isError: isRecurringDeletionError,
			isLoading: isRecurringDeletionLoading,
		},
	] = useDeleteRecurringReservationMutation();

	const handleRescheduleBooking = (message) => {
		messageApi.open({
			key,
			type: "loading",
			content: "Requesting reschedule...",
			duration: 0,
		});
		setSubmittedStatus("reschedule");
		updateStatus({
			booking_id: data?.id.toString(),
			data: {
				status: "provider_requested_reschedule",
				message_to_host: message,
			},
		});
	};
	const handleNotifyHost = () => {
		messageApi.open({
			key,
			type: "loading",
			content: "Notifying Host...",
		});
		setSubmittedStatus("notified");
		updateStatus({
			booking_id: data?.id.toString(),
			data: { status: "notify" },
		});
	};
	const handleApproveBooking = () => {
		messageApi.open({
			key,
			type: "loading",
			content: "Approving reservation...",
		});
		setSubmittedStatus("approved");

		updateStatus({
			booking_id: data?.id.toString(),
			data: { status: "approved" },
		});
	};
	const handleDeclineBooking = () => {
		messageApi.open({
			key,
			type: "loading",
			content: "Declining reservation...",
		});
		setSubmittedStatus("declined");

		updateStatus({
			booking_id: data?.id.toString(),
			data: { status: "declined" },
		});
	};

	const handleDeleteBooking = () => {
		setSubmittedStatus("delete");
		updateStatus({
			booking_id: data?.id.toString(),
			data: { status: "delete" },
		});
	};

	const handleDeleteRecurring = () => {
		setSubmittedStatus("delete");
		deleteRecurring({
			company_id: data.company_id,
			recurring_id: data.recurring_id,
		});
	};
	useEffect(() => {
		if (isSuccess || isRecurringDeletionSuccess) {
			messageApi.open({
				key,
				type: "success",
				content:
					submittedStatus === "approved"
						? "Reservation status updated successfully!"
						: submittedStatus === "delete"
							? "Reservation deleted successfully!"
							: submittedStatus === "reschedule"
								? "Reservation rescheduled successfully!"
								: submittedStatus === "notified"
									? "Host was notified"
									: "Reservation declined.",

				duration: 2,
			});
			setTimeout(() => {
				setShowResheduleBooking(false);
				setShowDeleteBookingModal(false);
			}, 1500);
		}

		if (isError || isRecurringDeletionError) {
			messageApi.open({
				key,
				type: "error",
				content:
					submittedStatus === "approved"
						? "Error approving reservation."
						: submittedStatus === "delete"
							? "Error deleting reservation."
							: submittedStatus === "reschedule"
								? "Error in rescheduling"
								: submittedStatus === "notified"
									? "Error in notifying host"
									: "Error declining reservation.",
				duration: 2,
			});
		}
	}, [
		isSuccess,
		isError,
		status,
		messageApi,
		isRecurringDeletionSuccess,
		isRecurringDeletionError,
	]);
	return (
		<div
			className="flex w-full max-w-[350px] cursor-pointer flex-col rounded-[12px] bg-white p-2 shadow-md  hover:border hover:border-primary"
			onClick={() =>
				dispatch(setDate(new Date(data.start_at).toISOString()))
			}
			onDoubleClick={() => setShowBookingInfo(true)}
		>
			{contextHolder}
			<div className="flex gap-2">
				<div className="flex h-[70px] w-[90px] items-center justify-center rounded-[8px] bg-[#f3f3f3]">
					<div className="flex size-9 items-center justify-center rounded-full bg-[#C7C7C7]/60">
						<p className="select-none text-lg font-semibold text-white">
							{data?.room?.name.split(" ")[0][0].toUpperCase()}
						</p>
					</div>
				</div>
				<div className="flex flex-col justify-between space-y-0.5 self-stretch">
					<div>
						<h1 className="pb-1 text-xs font-semibold text-[#323539]">
							{data?.room?.name ?? "[Space Name]"}
						</h1>
						{data?.room?.address && (
							<span className="flex items-center gap-2 text-[10px] text-[#6B7280]">
								<i className="mgc_location_3_line text-[10px] before:!text-[#BFBFBF]" />
								<span>{data?.room?.address ?? "N/A"}</span>
							</span>
						)}
					</div>
					<CapacityHolder capacity={data?.room?.capacity ?? 0} />
				</div>
				<button
					className="ml-auto h-fit w-fit rounded-sm bg-[#F5F5F5] p-1"
					onClick={() => setShowBookingInfo(true)}
				>
					<BsInfoCircle className="text-[10px]" />
				</button>
			</div>
			<h1 className="pt-2 text-xs font-normal">
				{data?.title ?? "[Event Title]"}
			</h1>
			<div className="grid grid-cols-2 gap-1 pt-2">
				<span className="flex items-center gap-2 ">
					<LuCalendar className="text-[#BFBFBF]" />
					<p className="text-[10px] font-semibold text-basecolor">
						{data?.start_at
							? formatDateString(data?.start_at.split(" ")[0])
							: "N/A"}
					</p>
				</span>
				<span className="flex items-center gap-2 text-[10px] text-[#6B7280]">
					<span className="flex h-[13px] w-[13px] items-center justify-center rounded-full border border-[#BFBFBF] bg-[#fff] p-1 text-[8px] text-[#BFBFBF]">
						{data?.host?.name.split("")[0][0]}
					</span>
					{data?.host?.name ?? "N/A"}
				</span>
				<span className="flex items-center gap-2 ">
					<IoMdTime className="text-[#BFBFBF]" />
					<p className="text-[10px] font-semibold text-basecolor">
						{data?.start_at ? formatTime(data.start_time) : "N/A"} -{" "}
						{data?.end_time ? formatTime(data.end_time) : "N/A"}
					</p>
				</span>

				<span className="flex items-center gap-2 ">
					<LuUsers className="text-[#BFBFBF]" />
					<p className="text-[10px]  text-[#6B7280]">
						{/* //This is missing, needed from mikiyas */}
						{/* Attendes: 8 */}
						N/A
					</p>
				</span>
			</div>
			<span className="flex items-center gap-2 pt-1">
				<LuContact className="text-[#BFBFBF]" />
				<p className="text-[10px] font-semibold text-basecolor">
					{data?.company?.name ?? "N/A"}
				</p>
			</span>

			<div className="flex  items-center justify-between pt-2">
				{status == "approved" && (
					<>
						<div className="flex gap-2">
							<button
								className="rounded-md bg-[#F5F5F5] p-1.5 text-sm text-basecolor"
								onClick={() => setShowResheduleBooking(true)}
							>
								<img
									src={RescheduleIcon}
									alt="reschedule icon"
								/>
							</button>
							<button
								className="rounded-md bg-[#F5F5F5] p-1.5 text-sm text-basecolor"
								onClick={() => setShowBookingInfo(true)}
							>
								<FaArrowRightArrowLeft />
							</button>
							<button
								className="rounded-md bg-[#F5F5F5] p-1.5 text-sm text-basecolor"
								onClick={() => setShowDeleteBookingModal(true)}
							>
								<TbTrash />
							</button>
						</div>
						<div>
							<button
								className="rounded-lg border border-[#3F9590] bg-[#F5F5F5] p-2 text-[#3F9590]"
								onClick={handleNotifyHost}
							>
								<LuBellRing className="text-sm" />
							</button>
						</div>
					</>
				)}

				{status == "pending" && (
					<>
						<div className="flex gap-2">
							<button
								className="rounded-md bg-[#F5F5F5] p-1.5 text-sm text-basecolor"
								onClick={() => setShowResheduleBooking(true)}
							>
								<img
									src={RescheduleIcon}
									alt="reschedule icon"
								/>
							</button>
							<button
								className="rounded-md bg-[#F5F5F5] p-1.5 text-sm text-basecolor"
								onClick={() => setShowDeleteBookingModal(true)}
							>
								<TbTrash />
							</button>
						</div>
						<div className="flex gap-2">
							<button
								disabled={isLoading}
								className="flex items-center gap-1 rounded-md border border-[#BC2E48] bg-[#F5F5F5] px-2 py-1 text-xs font-semibold text-[#BC2E48]"
								onClick={handleDeclineBooking}
							>
								<MdOutlineCancel />
								Decline
							</button>
							<button
								disabled={isLoading}
								className="flex items-center gap-1 rounded-md border border-[#3F9590] bg-[#F5F5F5] px-2 py-1 text-xs font-semibold text-[#3F9590]"
								onClick={handleApproveBooking}
							>
								<BsCheckCircle />
								Accept
							</button>
						</div>
					</>
				)}
			</div>

			<Modal
				dialogClassName="max-w-2xl p-0 pt-2"
				open={showBookingInfo}
				setOpen={setShowBookingInfo}
				dialogTitle="Reservation Informartion"
			>
				<BookingOverview
					handleClose={() => setShowBookingInfo(false)}
					activetab={tab}
					status={status}
					id={data?.id}
				/>
			</Modal>
			<Modal
				open={showRescheduleBooking}
				setOpen={setShowResheduleBooking}
				dialogClassName="max-w-[400px] p-6 !rounded-lg bg-white"
				dialogTitle="Reschedule Reservation"
			>
				<RescheduleConfirmationCard
					handleReschedule={handleRescheduleBooking}
					isLoading={false}
				/>
			</Modal>
			<Modal
				open={showDeleteBookingModal}
				setOpen={setShowDeleteBookingModal}
				dialogClassName="max-w-[400px] py-6 px-4 !rounded-lg bg-white"
				dialogTitle="Delete Reservation"
			>
				<DeleteBooking
					data={data}
					name={data?.company?.name ?? data?.title}
					isLoading={isLoading}
					isRecurringLoading={isRecurringDeletionLoading}
					handleDelete={handleDeleteBooking}
					handleDeleteRecurring={handleDeleteRecurring}
				/>
			</Modal>
		</div>
	);
};

export default EventCard;
