import { useState } from "react";
import { BsPlus } from "react-icons/bs";
import { HiOutlineTrash } from "react-icons/hi";
import { cn } from "@/lib/utils";
import { ScrollArea } from "@/components/ui/scroll-area";
import FormInput from "@/components/input/FormInput";
import Avatar from "@/components/common/Avatar";
import { Spin } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { notifyWithIcon } from "@/services/utilities";

interface AttendeeSelectorProps {
	user: any;
	selectedAttendees: any;
	setSelectedAttendees: any;
	allAttendees?: any;
	isLoading: boolean;
	isError: boolean;
	showHost?: boolean;
}

const AttendeeSelector = ({
	user,
	selectedAttendees,
	setSelectedAttendees,
	allAttendees,
	isLoading,
	isError,
	showHost = true,
}: AttendeeSelectorProps) => {
	const [searchQuery, setSearchQuery] = useState("");
	const [searchResults, setSearchResults] = useState([]);

	const isValidEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const handleSearch = (e) => {
		const query = e.target.value;
		setSearchQuery(query);
		const results = filterAttendees(query, allAttendees, selectedAttendees);
		if (query && isValidEmail(query)) {
			const emailExists = allAttendees.some(
				(email) => email.toLowerCase() === query.toLowerCase()
			);
			if (!emailExists) {
				results.unshift(query);
			}
		}
		setSearchResults(results);
	};
	const handleAddAttendee = (attendee) => {
		if (selectedAttendees.includes(attendee)) {
			notifyWithIcon("error", "Email already exists");
			return;
		}
		setSelectedAttendees([...selectedAttendees, attendee]);
		setSearchQuery("");
		setSearchResults([]);
	};

	const handleRemoveAttendee = (attendee) => {
		setSelectedAttendees(selectedAttendees.filter((a) => a !== attendee));
		setSearchResults([]);
	};

	const filterAttendees = (query, allAttendees, selectedAttendees) => {
		if (!query) {
			return [];
		}

		const lowercaseQuery = query.toLowerCase();

		const filteredAttendees = allAttendees.filter((email) => {
			const lowercaseEmail = email.toLowerCase();
			const isAttendeeSelected = selectedAttendees.includes(email);

			return (
				!isAttendeeSelected && lowercaseEmail.includes(lowercaseQuery)
			);
		});

		return filteredAttendees;
	};

	if (isLoading) {
		return (
			<div className="mt-4">
				<Spin
					spinning={true}
					indicator={<SyncOutlined style={{ fontSize: 24 }} spin />}
				>
					<div className="fetching" />
				</Spin>
			</div>
		);
	}

	if (isError) {
		return <div>Error fetching attendees</div>;
	}

	return (
		<div className="">
			{showHost && (
				<>
					<h1>Host</h1>
					<div className="py-2">
						<Avatar
							spanClassName="p-0 size-[22px]"
							variant="host"
							name={user?.user?.name ?? "N/A"}
						/>
					</div>
				</>
			)}

			<div className="border-t py-2">
				<div className="flex items-center justify-between gap-8 text-[14px]">
					<p className="text-[#323539]">Add Attendees</p>
				</div>
				<div className="relative mt-2 flex w-full flex-col">
					<FormInput
						value={searchQuery}
						className="text-[14px]"
						inputType="text"
						onChange={handleSearch}
						placeholder="Search for attendees"
					/>
					{searchResults.length > 0 && (
						<div className="fixed z-10 mt-10 w-[210px]">
							<ScrollArea className="mt-2 h-[10vh] rounded-lg bg-white px-4 pt-2">
								{searchResults.map((result: any, index) => (
									<div
										key={`${result}-${index}`}
										className="flex items-center justify-between"
									>
										<div>
											<p className="text-xs">{result}</p>
										</div>
										<button
											type="button"
											title="Add attendee"
											onClick={() =>
												handleAddAttendee(result)
											}
										>
											<BsPlus className="text-2xl text-[#858C95]" />
										</button>
									</div>
								))}
							</ScrollArea>
						</div>
					)}
				</div>
				{selectedAttendees.length > 0 && (
					<ScrollArea className="h-[10vh] pt-2">
						{selectedAttendees.map((attendee: any, index) => (
							<div
								key={`${attendee}-${index}`}
								className={cn(
									"group/item flex cursor-pointer items-center justify-between rounded-xl pr-2 pt-2 hover:bg-[#EFEFEF]"
								)}
							>
								<div className="flex items-center gap-2">
									<Avatar name={attendee} variant="guest" />
								</div>
								<button
									title="Remove attendee"
									onClick={() =>
										handleRemoveAttendee(attendee)
									}
								>
									<HiOutlineTrash
										className={cn(
											"invisible text-sm text-[#8C8C8C] hover:text-black group-hover/item:visible"
										)}
									/>
								</button>
							</div>
						))}
					</ScrollArea>
				)}
			</div>
		</div>
	);
};

export default AttendeeSelector;
