import { Toaster } from "react-hot-toast";
import "./index.css";
import AuthProvider from "./provider/AuthProvider";
import AppRoutes from "./Routes";

function App() {
	return (
		<AuthProvider>
			<AppRoutes />
			<ToastContainer />
		</AuthProvider>
	);
}

export default App;

const ToastContainer: React.FC = () => {
	return (
		<Toaster
			position="bottom-center"
			toastOptions={{
				style: {
					maxWidth: "1000px",
				},
			}}
		/>
	);
};
