import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
export interface BookingStateType {
	id: string;
	roomId: number;
	title: string;
	description: string;
	phoneNumber: string;
	members: {
		email: string;
		id: number;
	}[];
	date: string;
	startTime: string;
	endTime: string;
	isAllDay: boolean;
	duration: number;
	capacity: number;
}

export interface BookingsState {
	booking: BookingStateType;
	loading: boolean;
	error: string | null;
}

const firstState: BookingStateType = {
	id: "0",
	roomId: 2,
	title: "",
	description: "",
	phoneNumber: "",
	members: [
		{
			email: "",
			id: 0,
		},
	],
	date: DateTime.now().toISODate(),
	startTime: "",
	capacity: 10,
	endTime: "",
	isAllDay: false,
	duration: 30,
};

const initialState: BookingsState = {
	booking: firstState,
	loading: false,
	error: null,
};

const bookingsSlice = createSlice({
	name: "bookings",
	initialState,
	reducers: {
		startBooking(state) {
			state.loading = true;
			state.error = null;
		},
		bookingSuccess(state, action: PayloadAction<BookingStateType>) {
			state.booking = action.payload;
			state.loading = false;
			state.error = null;
		},
		bookingFailure(state, action: PayloadAction<string>) {
			state.loading = false;
			state.error = action.payload;
		},

		updateBooking(state, action: PayloadAction<Partial<BookingStateType>>) {
			state.booking = { ...state.booking, ...action.payload };
		},
		updateDuration(state, action: PayloadAction<number>) {
			state.booking.duration = action.payload;
		},
		updateCapacity(state, action: PayloadAction<number>) {
			state.booking.capacity = action.payload;
		},
		updateIsAllDay(state, action: PayloadAction<boolean>) {
			state.booking.isAllDay = action.payload;
			if (action.payload == true) {
				state.booking.duration = 0;
			} else if (action.payload == false) {
				state.booking.duration = 60;
			}
		},
		clearBooking(state) {
			state.booking = firstState;
			state.loading = false;
			state.error = null;
		},
	},
});

export const {
	startBooking,
	bookingSuccess,
	bookingFailure,
	updateIsAllDay,
	updateDuration,
	updateBooking,
	updateCapacity,
	clearBooking,
} = bookingsSlice.actions;

export default bookingsSlice.reducer;
