import Search from "@/components/input/Search";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { PartnerCompanyMember } from "@/types/partners/member";
import { useState } from "react";
import Select, { SingleValue } from "react-select";
import MemberCard from "./MemberCard";
import { useAppSelector } from "@/hooks/hooks";
import { TeamMemberRoles } from "@/types/team-member";

interface MemberListProps {
	memberType: "guest" | "admin";
	setShowAddMember: () => void;
	list: PartnerCompanyMember[];
}

const MemberList = ({
	// memberType,
	setShowAddMember,
	list,
}: MemberListProps) => {
	// const initialItems: PartnerCompanyMember[] = list;
	const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");
	const [selectedItems, setSelectedItems] = useState<PartnerCompanyMember[]>(
		[]
	);
	const [, setSelectAll] = useState<boolean>(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [sortOption, setSortOption] = useState("");

	const handleSortChange = (
		selectedOption: SingleValue<{ value: string; label: string }>
	) => {
		setSortOption(selectedOption ? selectedOption.value : "");
	};

	const sortOptions = [
		{ value: "", label: "Sort by" },
		{ value: "nameAsc", label: "Name (A-Z)" },
		{ value: "nameDesc", label: "Name (Z-A)" },
	];

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(event.target.value);
	};

	const toggleSelectItem = (item: PartnerCompanyMember) => {
		if (selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
			setSelectedItems(
				selectedItems?.filter(
					(selectedItem) => selectedItem.id !== item.id
				)
			);
		} else {
			setSelectedItems([...selectedItems, item]);
		}
		setSelectAll(false);
	};

	const filteredMembers = list?.filter((member) => {
		const fullName = member.name ? member.name.toLowerCase() : "";
		return fullName.includes(searchQuery.toLowerCase());
	});

	const sortedMembers = filteredMembers?.sort((a, b) => {
		if (sortOption === "nameAsc") {
			return a.name && b.name ? a.name.localeCompare(b.name) : 0;
		} else if (sortOption === "nameDesc") {
			return a.name && b.name ? b.name.localeCompare(a.name) : 0;
		}
		return 0;
	});

	return (
		<div className="flex h-full flex-1 flex-col gap-y-4 overflow-hidden">
			<div className="flex w-full gap-2 xs:gap-4">
				<div className="w-full">
					<Search
						value={searchQuery}
						onChange={handleSearchChange}
						placeholder="Search"
						className="w-full border-[#E5E5E7]"
					/>
				</div>
				<Select
					placeholder="Sort by"
					className="hidden w-[30%] whitespace-nowrap lg:block"
					options={sortOptions}
					onChange={handleSortChange}
					value={sortOptions.find(
						(option) => option.value === sortOption
					)}
				/>
				{userRole !== TeamMemberRoles.PartnerAdmin && (
					<Button onClick={setShowAddMember}>Add Member</Button>
				)}
				{/* <button className="block rounded-md border border-[#E5E5E7] bg-white p-2 shadow-sm md:hidden">
					<RiSortDesc color="#6B7280" size="20px" />
				</button> */}
			</div>
			<ScrollArea className="flex-1">
				{/* <div className="flex flex-col  flex-1 overflow-scroll"> */}
				{/* <div className="flex justify-between px-6">
						<div className="flex items-center gap-2 text-[14px]">
							<input
								id="select-all"
								type="checkbox"
								onClick={() =>
									setSelectAll(
										(prevSelectAll) => !prevSelectAll
									)
								}
								checked={selectAll}
								onChange={() => {}}
							/>
							<label
								className={cn("", {
									"text-[#7D7E80]": !selectAll,
								})}
							>
								Select All
							</label>
						</div>
						<TooltipProvider>
							<div className="flex items-center gap-1">
								{memberType === "guest" && (
									<Tooltip delayDuration={0}>
										<TooltipTrigger asChild>
											<Button
												variant="outlinecustom"
												disabled={!selectAll}
												className="h-7 px-1.5 py-1.5"
											>
												<FaUserShield />
											</Button>
										</TooltipTrigger>
										<TooltipContent
											side="top"
											sideOffset={10}
										>
											Make Admin
										</TooltipContent>
									</Tooltip>
								)}
								{memberType === "admin" && (
									<Tooltip delayDuration={0}>
										<TooltipTrigger asChild>
											<Button
												variant="outlinecustom"
												disabled={!selectAll}
												className="h-7 px-1.5 py-1.5"
											>
												<FaUserTimes />
											</Button>
										</TooltipTrigger>
										<TooltipContent
											side="top"
											sideOffset={10}
										>
											Remove Admin
										</TooltipContent>
									</Tooltip>
								)}

								<Tooltip delayDuration={0}>
									<TooltipTrigger asChild>
										<Button
											variant="outlinecustom"
											disabled={!selectAll}
											className="h-7 px-1.5 py-1.5"
										>
											<TbTrash />
										</Button>
									</TooltipTrigger>
									<TooltipContent side="top" sideOffset={10}>
										Delete
									</TooltipContent>
								</Tooltip>
							</div>
						</TooltipProvider>
					</div> */}
				{sortedMembers?.map((item) => (
					<MemberCard
						key={item.id}
						item={item}
						onSelect={() => toggleSelectItem(item)}
						checked={selectedItems.some(
							(selectedItem) => selectedItem.id === item.id
						)}
						type={item.role}
					/>
				))}

				{/* </div> */}
			</ScrollArea>
		</div>
	);
};

export default MemberList;
