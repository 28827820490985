import Logo from "../assets/footerlogo.svg";

const Footer = () => {
	return (
		<div className="flex justify-center">
			<div className="flex items-center gap-x-2">
				<span className=" text-[12px] font-normal text-[#6D748D]">
					Powered by{" "}
				</span>
				<img
					src={Logo}
					alt="logo"
					className=" text-[12px] font-normal"
				/>
			</div>
		</div>
	);
};

export default Footer;
