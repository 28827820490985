import { ROOM_BOOKING_URI } from "@/services/constants";
import LocalStorage from "@/services/storage";
import { apiSlice } from "../apiSlice";

const storage = new LocalStorage();

export const generalEndpoints = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getPartnerUsers: builder.query<any, { companyId: string }>({
			query: ({ companyId }) => {
				return {
					url: `/${ROOM_BOOKING_URI}/partner-users/${companyId}/list`,
					method: "get",
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			providesTags: ["AdminPartnerUsers"],
		}),
		deletePartnerMember: builder.mutation<
			any,
			{
				member_id;
				company_id;
			}
		>({
			query: ({ company_id, member_id }) => {
				return {
					url: `/${ROOM_BOOKING_URI}/partner-users/${company_id}/delete-member/${member_id}`,
					method: "delete",
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			invalidatesTags: ["AdminPartnerUsers"],
		}),
		deletePartnerUser: builder.mutation<
			any,
			{
				user_id;
				company_id;
			}
		>({
			query: ({ company_id, user_id }) => {
				return {
					url: `/${ROOM_BOOKING_URI}/partner-users/${company_id}/delete-user/${user_id}`,
					method: "delete",
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			invalidatesTags: ["AdminPartnerUsers"],
		}),
		updatePartnerUser: builder.mutation<
			any,
			{
				data;
				company_id;
				user_id;
			}
		>({
			query: ({ data, company_id, user_id }) => {
				return {
					url: `/${ROOM_BOOKING_URI}/partner-users/${company_id}/update-user/${user_id}`,
					method: "post",
					data,
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			invalidatesTags: ["AdminPartnerUsers"],
		}),
		updatePartnerMember: builder.mutation<
			any,
			{
				data;
				company_id;
				member_id;
			}
		>({
			query: ({ data, company_id, member_id }) => {
				return {
					url: `/${ROOM_BOOKING_URI}/partner-users/${company_id}/update-member/${member_id}`,
					method: "post",
					data,
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			invalidatesTags: ["AdminPartnerUsers"],
		}),
	}),
});

export const {
	useGetPartnerUsersQuery,
	useDeletePartnerMemberMutation,
	useDeletePartnerUserMutation,
	useUpdatePartnerMemberMutation,
	useUpdatePartnerUserMutation,
} = generalEndpoints;
