import { useLocation } from "react-router";

const useAdminBookingReview = () => {
	const location = useLocation();
	const regex = /\/admin\/partner-management\/\d+\/booking\/\d+/;

	return regex.test(location.pathname);
};

export default useAdminBookingReview;
