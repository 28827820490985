import { HiOutlineTrash } from "react-icons/hi2";
import { DialogClose } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useDeleteMemberMutation } from "@/redux/features/api/partnerEndpoints";
import { LoadingOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { notifyWithIcon } from "@/services/utilities";

interface DeleteAdminProps {
	name: string;
	id: number;
	handleClose: () => void;
}
const DeleteMember = ({ name, id, handleClose }: DeleteAdminProps) => {
	const [deleteMember, { isSuccess, isError, error, isLoading }] =
		useDeleteMemberMutation();

	const handleDelete = async () => {
		if (id) {
			return deleteMember({ member_id: id });
		} else {
			notifyWithIcon("", "Member id does not exist");
		}
	};
	useEffect(() => {
		if (isSuccess) {
			notifyWithIcon("", "Member successfully deleted");
			handleClose();
		}
		if (isError && error) {
			notifyWithIcon("", "Error occured during deletion");
			handleClose();
		}
	}, [isSuccess, isError, error]);
	return (
		<div className="flex items-start gap-2">
			<div className="flex flex-[0.1] pt-1">
				<HiOutlineTrash className="text-lg text-red-400" />
			</div>
			<div className="flex flex-grow flex-col gap-y-2">
				<h1 className="text-2xl font-semibold text-[#323539]">
					Remove as Admin
				</h1>
				<p>
					Are you sure you want to delete{" "}
					<span className="font-bold text-[#6D748D]">
						{name || "member"}{" "}
					</span>
					from your member list
				</p>
				<div className="flex w-full items-center gap-x-1.5 pt-2">
					<DialogClose className="w-full rounded-md border border-primary bg-transparent bg-white px-4 py-2 text-primary">
						Cancel
					</DialogClose>
					<Button
						className=" w-full rounded-md px-4 py-2 text-white"
						variant="destructive"
						onClick={handleDelete}
					>
						{isLoading ? (
							<LoadingOutlined style={{ fontSize: 24 }} spin />
						) : (
							"Delete"
						)}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default DeleteMember;
