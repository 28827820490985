import { useState } from "react";
import SubscriptionCard from "@/components/admin/settings/SubscriptionCard";
import CustomSelect from "@/components/common/CustomSelect";
import FormInput from "@/components/input/FormInput";
import { Button } from "@/components/ui/button";

const Subscription = () => {
	const [selectedPlan, setSelectedPlan] = useState<
		"custom" | "free" | "basic"
	>("free");
	const PlanOptions = [
		{ label: "Free", value: "free" },
		{ label: "Custom", value: "custom" },
		{ label: "Basic", value: "basic" },
	];
	return (
		<div>
			<h1 className="text-2xl font-semibold text-basecolor">
				Subscription
			</h1>
			<div className="flex justify-between gap-x-12 pt-4">
				<div className="flex w-full flex-[0.6] flex-col gap-y-8 pt-2">
					<div className="flex w-full items-center gap-3">
						<div className="w-full">
							<p className="pb-1 text-sm font-medium text-basecolor">
								Current Plan
							</p>
							<CustomSelect
								placeholder="Custom Plan"
								options={PlanOptions}
								onChange={(selectedValue: any) =>
									setSelectedPlan(selectedValue.value)
								}
							/>
						</div>
						<div className="w-full">
							<FormInput
								className="h-full w-full"
								inputType="text"
								label="Plan Expires"
								placeholder="Plan expiry date"
							/>
						</div>
					</div>
					<div className="flex w-full gap-3">
						<div className="w-full">
							<p className="pb-1 text-sm font-medium text-basecolor">
								Billing Cycle
							</p>
							<CustomSelect placeholder="Monthly" />
						</div>
						<div className="w-full">
							<FormInput
								className="h-full w-full"
								inputType="text"
								label="Next Billing Date"
								placeholder="Next Billing Date"
							/>
						</div>
					</div>
					<Button
						variant="default"
						className="ml-auto w-fit"
						disabled={true}
					>
						Save
					</Button>
				</div>
				{/* card  */}
				<div className="w-full flex-[0.4]">
					<SubscriptionCard subscriptionType={selectedPlan} />
				</div>
			</div>
		</div>
	);
};

export default Subscription;
