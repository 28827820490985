import DisplayCard from "@/components/admin/display/DisplayCard";
import DisplayFullScreenView from "@/components/admin/display/DisplayFullScreenView";
import DisplayHeader from "@/components/admin/display/DisplayHeader";
import DefaultTopbar from "@/components/topbar/DefaultTopbar";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
	useGetDisplayDataQuery
} from "@/redux/features/api/adminBookingEndpoints";
// import {
// 	useGetAllAdminBookingsQuery,
// 	useGetAllAdminAvailableRoomsQuery,
// } from "@/redux/features/api/adminEndpoints";
import { useState } from "react";
// import { useState } from 'react';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const Display = () => {
	const handle = useFullScreenHandle();
	const [isFullScreen, setIsFullScreen] = useState(false);
	const { data } = useGetDisplayDataQuery({});
	// const ewd = useGetAllAdminAvailableRoomsQuery(undefined);

	return (
		<div className="flex h-full flex-1 flex-col overflow-hidden">
			<DefaultTopbar title="DEW" />
			<div className="flex flex-1 flex-col overflow-hidden px-4">
				<div className="pb-8">
					<DisplayHeader onClick={handle.enter} />
				</div>

				<ScrollArea className="flex flex-1 flex-col gap-y-2 rounded-[20px] border border-primary  bg-[#F0F0F0] px-4 py-2">
					{data &&
						data.data.map((room, index) => (
							<div className="my-2" key={index}>
								<DisplayCard roomdata={room} key={index} />
							</div>
						))}
				</ScrollArea>

				<FullScreen
					handle={handle}
					onChange={(state) => setIsFullScreen(state)}
				>
					{isFullScreen ? <DisplayFullScreenView /> : null}
				</FullScreen>
			</div>
		</div>
	);
};

export default Display;
