import RoomCardContent from "@/components/admin/RoomCardContent";
import { RoomCardProps } from "@/components/admin/booking/book/room/RoomCard";
import Modal from "@/components/modals/Modal";
import { Drawer, DrawerContent } from "@/components/ui/drawer";
import { TooltipProvider } from "@/components/ui/tooltip";
import useScreenDimension from "@/hooks/useScreenDimension";
import { useState } from "react";
import RoomOverview from "../../modals/RoomOverview";

const RoomCard: React.FC<RoomCardProps> = ({ room, isLoading }) => {
	const { width } = useScreenDimension();
	const isMobile = width < 800;
	const [openBooking, setOpenBooking] = useState(false);

	return (
		<TooltipProvider>
			<div className="flex flex-col gap-y-2 rounded-xl bg-[#FBFBFB] p-1.5 drop-shadow-[0_0_6px_rgba(0,0,0,0.15)]">
				<RoomCardContent
					room={room}
					isLoading={isLoading}
					openBooking={openBooking}
					setOpenBooking={setOpenBooking}
				/>
				<Modal
					dialogClassName=" p-0 max-w-2xl "
					onInteractOutside={(e) => {
						e.preventDefault();
					}}
					open={!isMobile && openBooking}
					setOpen={setOpenBooking}
				>
					<div className="w-full pt-4">
						<RoomOverview room={room} />
					</div>
				</Modal>
				<Drawer
					open={isMobile && openBooking}
					onOpenChange={setOpenBooking}
				>
					<DrawerContent className="mb-4 h-[90svh] bg-[#F9F9F9]">
						<RoomOverview room={room} />
					</DrawerContent>
				</Drawer>
			</div>
		</TooltipProvider>
	);
};

export default RoomCard;
