import CustomSelect from "@/components/common/CustomSelect";
import Search from "@/components/input/Search";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useEffect, useState } from "react";
// import { BsFiletypeCsv } from "react-icons/bs";
import AddAmenities from "@/components/modals/admin/locations/AddAmenities";
import Modal from "@/components/modals/Modal";
import { useEditAmenityMutation } from "@/redux/features/apiSlice";
import { notifyWithIcon } from "@/services/utilities";
import { message } from "antd";
import AmenitiesListCard, { Amenity } from "./AmenitiesListCard";
import { useAppSelector } from "@/hooks/hooks";
import { TeamMemberRoles } from "@/types/team-member";

const AmenitiesList = ({ amenities }: { amenities: any }) => {
	const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");
	const id = location.pathname.split("/").pop();
	const [editAmenity, { isLoading, isSuccess, isError }] =
		useEditAmenityMutation();
	const [showAddAmenities, setShowAddAmenities] = useState(false);
	const [items, setItems] = useState<Amenity[]>(amenities.data);
	const [searchQuery, setSearchQuery] = useState("");
	const [sortBy, setSortBy] = useState("");
	const [selectedItems, setSelectedItems] = useState<Amenity[]>([]);
	const [, setSelectAll] = useState<boolean>(false);
	const filteredItems = amenities.data
		.filter((item: Amenity) =>
			item.name.toLowerCase().includes(searchQuery.toLowerCase())
		)
		.sort((a: Amenity, b: Amenity) => {
			if (sortBy === "name") {
				return a.name.localeCompare(b.name);
			}
			return 0; // No sorting by default
		});

	const removeAmenity = (item: any) => {
		setItems(items.filter((selectedItem) => selectedItem.id !== item.id));
	};

	// const toggleSelectAll = () => {
	// 	if (selectAll) {
	// 		setSelectedItems([]);
	// 	} else {
	// 		setSelectedItems(filteredItems);
	// 	}
	// 	setSelectAll(!selectAll);
	// };

	const toggleSelectItem = (item: Amenity) => {
		if (selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
			setSelectedItems(
				selectedItems.filter(
					(selectedItem) => selectedItem.id !== item.id
				)
			);
		} else {
			setSelectedItems([...selectedItems, item]);
		}
		setSelectAll(false);
	};

	const handleEditAmenity = (amenity_id, data) => {
		if (data && amenity_id) {
			return editAmenity({
				id: id!,
				amenity_id: amenity_id,
				data: data,
			}).unwrap();
		}
		message.error("Error updating amenity");
	};

	useEffect(() => {
		if (isSuccess) {
			notifyWithIcon("success", "Amenity updated successfully!");
		} else if (isError) {
			notifyWithIcon("error", "Error updating amenity!");
		}
	}, [isSuccess, isError]);

	return (
		<div className="flex w-full scroll-m-0 flex-col overflow-y-auto">
			<div className="flex h-9 w-full gap-2">
				<Search
					placeholder="Search"
					className="h-8.5 flex-[0.6]"
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
				/>
				<CustomSelect
					placeholder="Sort by"
					className="w-full flex-[0.4]"
					value={sortBy}
					options={[
						{ value: "", label: "None" },
						{ value: "name", label: "Name" },
					]}
					onChange={(selectedOption: any) =>
						setSortBy(selectedOption.value as string)
					}
				/>

				{userRole !== TeamMemberRoles.TeamMember &&
					userRole !== TeamMemberRoles.PartnerManager && (
						<Button
							variant="default"
							className=" h-full px-10"
							onClick={() => setShowAddAmenities(true)}
						>
							Add
						</Button>
					)}
			</div>

			<div className="flex flex-col gap-y-4 py-2">
				{/* <div className="flex justify-between px-6 pb-1 pt-2">
					<div className="flex items-center gap-2 text-[14px]">
						<input
							id="select-all"
							type="checkbox"
							className="accent-primary"
							onClick={toggleSelectAll}
							disabled={amenities?.data.length == 0}
						/>
						<label
							className={cn("", {
								"text-[#7D7E80]": !selectAll,
							})}
						>
							Select All
						</label>
					</div>
					<Button
						variant="outlinecustom"
						disabled={!selectAll}
						className="h-7 px-1.5 py-1.5"
					>
						<TbTrash />
					</Button>
				</div> */}
				{amenities?.data.length > 0 && (
					<ScrollArea className="h-[50vh]">
						<div className="flex flex-col gap-2 px-2 py-2">
							{filteredItems.map((item, index) => (
								<AmenitiesListCard
									removeAmenity={removeAmenity}
									amenity={item}
									key={index}
									onSelect={() => toggleSelectItem(item)}
									checked={selectedItems.some(
										(selectedItem) =>
											selectedItem.id === item.id
									)}
									handleEditSave={handleEditAmenity}
									isLoading={isLoading}
								/>
							))}
						</div>
					</ScrollArea>
				)}
				{amenities?.data.length == 0 && (
					<p className="w-full text-sm py-24 text-center">
						No Amenities Available
					</p>
				)}
			</div>

			<Modal
				dialogClassName="!rounded-md max-w-sm p-4 bg-white w-full"
				open={showAddAmenities}
				setOpen={setShowAddAmenities}
			>
				<AddAmenities setShow={setShowAddAmenities} />
			</Modal>
		</div>
	);
};

export default AmenitiesList;
