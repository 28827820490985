import useCustomToast from "@/components/CustomToast";
import { LoaderButton } from "@/components/Loader/LoaderButton";
import RequestIsLoading from "@/components/Loader/RequestIsLoading";
import CustomShadSelect, { Option } from "@/components/common/CustomShadSelect";
import FormInput from "@/components/input/FormInput";
import { Button } from "@/components/ui/button";
import MultiSelect from "@/components/ui/multi-select";
import { useAppSelector } from "@/hooks/hooks";
import useSetErrors, { ApiError } from "@/hooks/useSetErrors";
import { useGetAllAdminPartnersQuery } from "@/redux/features/api/adminEndpoints";
import { useAdminAddTeamMemberMutation } from "@/redux/features/api/teamMemberEndpoints";
import { useGetAllAdminLocationsQuery } from "@/redux/features/apiSlice";
import { countryCodes, roleOptions } from "@/services/constants";
import { TeamMemberParams, TeamMemberSchema } from "@/types/team-member";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import MembersRoleSelector from "../LocationManagement/fragments/MembersRoleSelector";

const AddTeamMember = () => {
	const [selectedLocations, setSelectedLocations] = useState<Option[]>([]);
	const [selectedPartners, setSelectedPartners] = useState<Option[]>([]);

	const {
		register,
		handleSubmit,
		setValue,
		setError,
		formState: { errors },
		watch,
	} = useForm<TeamMemberParams>({
		resolver: zodResolver(TeamMemberSchema),
		defaultValues: {
			role: "TEAM_MEMBER",
			location_ids: selectedLocations.map((item) => Number(item.value)),
		},
	});

	const navigate = useNavigate();
	const user = useAppSelector((state) => state.user);

	const getAllAdminLocationsQuery = useGetAllAdminLocationsQuery();

	const getAllAdminPartnersQuery = useGetAllAdminPartnersQuery({
		businessId: user.user?.business_id ?? 0,
	});

	const customToast = useCustomToast();

	const [
		adminAddTeamMember,
		{ data: responseData, isSuccess, isLoading, isError, error },
	] = useAdminAddTeamMemberMutation();

	const [countryCode, setCountryCode] = useState("+1");
	const [firstName, setFirstName] = useState("");

	const [locations, setLocations] = useState([
		{
			value: "none",
			label: "None",
			disabled: true,
		},
	]);

	const handleAddTeamMember: SubmitHandler<TeamMemberParams> = async (
		data
	) => {
		await adminAddTeamMember({
			id: user.user?.business_id ?? 0,
			data: {
				...data,
				phone_number: countryCode + data.phone_number,
				name: data.name + " " + firstName,
				role:
					data.role === "SUPER_ADMIN" ? "BUSINESS_ADMIN" : data.role,
			},
		}).unwrap();
	};

	useEffect(() => {
		if (
			getAllAdminLocationsQuery.data &&
			getAllAdminLocationsQuery.data.data
		)
			setLocations([
				...getAllAdminLocationsQuery.data.data.map((item) => ({
					value: item.id.toString(),
					label: item.name,
					disabled: false, // Ensure disabled property is included
				})),
				{
					value: "none",
					label: "None",
					disabled: true,
				},
			]);
	}, [getAllAdminLocationsQuery.isSuccess, getAllAdminLocationsQuery.data]);

	useEffect(() => {
		setValue(
			"location_ids",
			selectedLocations.map((item) => Number(item.value))
		);
	}, [selectedLocations, setValue]);

	useEffect(() => {
		setValue(
			"partner_company_ids",
			selectedPartners.map((item) => Number(item.value))
		);
	}, [selectedPartners, setValue]);

	useEffect(() => {
		if (isSuccess && responseData) {
			customToast("Team member added successfully 🎉", {
				id: "add-team-member",
				type: "success",
				duration: 3000,
			});
			setTimeout(() => {
				navigate("/admin/team-members");
			}, 1500);
		}
	}, [isSuccess]);

	useSetErrors({
		error,
		isError,
		setError,
		keys: ["email"],
	});

	useEffect(() => {
		const errorObject = error as ApiError;
		if (errorObject && errorObject.status !== 401) {
			customToast(
				"Could not add team member 🤕. Please try again later",
				{
					id: "add-team-member",
					type: "error",
				}
			);
		}
	}, [isError]);

	return (
		<form
			className="relative flex w-[60vw] flex-col space-y-4"
			onSubmit={handleSubmit(handleAddTeamMember)}
		>
			{/* The header  */}
			<div className="flex gap-4">
				<Link to="/admin/team-members/">
					<FaChevronLeft className="text-xl font-semibold text-[#858C95]" />
				</Link>
				<div>
					<p className="text-xs font-normal">{user!.user!.name}</p>
					<h1 className="text-[20px] font-semibold text-basecolor">
						Add Team Member
					</h1>
				</div>
			</div>
			{/* body content  */}
			<div className="flex flex-col space-y-6 px-8">
				<div className="flex flex-col gap-y-4">
					<div className="flex flex-col gap-y-4">
						<p className="font-manrope font-semibold text-basecolor">
							Team Member Information
						</p>
						<div className="grid grid-cols-2 gap-2">
							<FormInput
								inputType="w-full"
								placeholder="Last Name"
								className="h-10"
								error={errors?.name}
								register={{
									...register("name"),
								}}
							/>

							<FormInput
								inputType="w-full"
								placeholder="First Name"
								className="h-10"
								onChange={(e) =>
									setFirstName(e.target.value.trim())
								}
							/>

							<FormInput
								inputType="w-full"
								placeholder="Email"
								className="h-10"
								register={{
									...register("email"),
								}}
								error={errors.email}
							/>
							<div>
								<div className="flex items-center">
									<CustomShadSelect
										className="h-10 w-fit rounded-r-none border-r-transparent"
										placeholder="+1"
										label="Country Codes"
										options={countryCodes}
										onChange={(value) => {
											setCountryCode(value);
										}}
										labelClassName="px-2"
										itemClassName="px-8"
									/>
									<FormInput
										inputType="text"
										className="h-10 rounded-l-none py-0"
										placeholder="Phone Number"
										maxLength={10}
										value={watch("phone_number")}
										error={
											errors?.phone_number?.message
												? ""
												: undefined
										}
										onChange={(e) => {
											setValue(
												"phone_number",
												e.target.value
											);
										}}
									/>
								</div>
								{errors.phone_number && (
									<small className="mt-1.5 text-xs text-red-500">
										{errors.phone_number.message}
									</small>
								)}
							</div>
						</div>
					</div>
					<div className="flex flex-col gap-y-4">
						<p className="font-manrope font-semibold text-basecolor">
							Select Role
						</p>
						<div className="flex flex-col space-y-2.5">
							{roleOptions.map((item, index) => {
								if (index === 1 || index === 2) return null;
								return (
									<MembersRoleSelector
										key={index}
										selectedPartners={selectedPartners}
										setSelectedPartners={
											setSelectedPartners
										}
										{...item}
										onClick={() =>
											setValue("role", item.value as any)
										}
										currentSelectedRole={watch("role")}
										isActive={watch("role") === item.value}
									/>
								);
							})}
						</div>
					</div>
					{watch("role") === "LOCATION_MANAGER" && (
						<div className="flex flex-col space-y-1.5">
							<label className="mb-1.5 block text-sm leading-[20px] tracking-[-0.1px] text-[#323539]">
								Assigned Locations
							</label>
							<MultiSelect
								options={locations}
								placeholder={"Locations"}
								selected={selectedLocations}
								setSelected={setSelectedLocations}
							/>
						</div>
					)}
				</div>
			</div>

			<div className="mt-6 flex justify-end gap-2 px-8">
				<Button
					className="max-w-[190px] flex-1"
					variant="outlinecustom"
					onClick={() => {
						navigate("/admin/location-management");
					}}
					disabled={isLoading}
				>
					Cancel
				</Button>
				<LoaderButton
					className="max-w-[190px] flex-1"
					variant="default"
					disabled={
						getAllAdminPartnersQuery.isLoading ||
						getAllAdminLocationsQuery.isLoading ||
						isLoading
					}
					loading={isLoading}
					loaderSize={20}
				>
					Add Member
				</LoaderButton>
			</div>

			<RequestIsLoading
				isLoading={
					getAllAdminPartnersQuery.isLoading ||
					getAllAdminLocationsQuery.isLoading
				}
				size={20}
				isWhite
			/>
		</form>
	);
};

export default AddTeamMember;
