import CustomMultiSelect from "@/components/common/CustomMultiSelect";
import CustomShadSelect from "@/components/common/CustomShadSelect";
import { useAppDispatch, useAppSelector } from "@/hooks/hooks";
import { useGetDisplayDataQuery } from "@/redux/features/api/adminBookingEndpoints";
import { useGetAllAdminBookingsQuery } from "@/redux/features/api/adminEndpoints";
import {
	setSelectedLocations,
	setSelectedPartners,
	setSelectedRooms,
	setSelectedStatus,
} from "@/redux/slices/displayFilterSlice";
import { extractUniqueObjectValues } from "@/services/utilities";
import { GoScreenFull } from "react-icons/go";
import { Button } from "../../ui/button";

const DisplayHeader = ({ onClick }: { onClick: () => void }) => {
	const dispatch = useAppDispatch();
	const { data } = useGetAllAdminBookingsQuery({});
	const displayDataQuery = useGetDisplayDataQuery({});
	const {
		// searchQuery,
		selectedLocations,
		selectedPartners,
		selectedRooms,
		selectedStatus,
	} = useAppSelector((state) => state.displayFilter);

	// const locations = extractUniqueObjectValues(data?.data ?? [], {
	// 	all: "All Locations",
	// 	keyName: "location",
	// 	valueKeyName: "id",
	// 	labelKeyName: "name",
	// });

	const locations = extractUniqueObjectValues(
		data?.data ?? [],
		{
			all: "All Locations",
			keyName: "location",
			valueKeyName: "id",
			labelKeyName: "name",
		},
		false
	);

	const rooms = extractUniqueObjectValues(
		data?.data ?? [],
		{
			all: "All Rooms",
			keyName: "room",
			valueKeyName: "id",
			labelKeyName: "name",
		},
		false
	);

	const partners = extractUniqueObjectValues(
		data?.data ?? [],
		{
			all: "All Partners",
			keyName: "company",
			valueKeyName: "id",
			labelKeyName: "name",
		},
		false
	);

	// const handleSearchQueryChange = (searchQuery) => {
	// 	dispatch(setSearchQuery(searchQuery.value));
	// };

	const handleLocationsChange = (selectedLocation: string) => {
		dispatch(setSelectedLocations(selectedLocation));
	};

	const handlePartnerChange = (selectedPartner: string) => {
		dispatch(setSelectedPartners(selectedPartner));
	};

	const handleStatusChange = (selectedStatus: string) => {
		dispatch(setSelectedStatus(selectedStatus));
	};

	const handleRoomsChange = (selectedRooms: unknown) => {
		dispatch(setSelectedRooms(selectedRooms));
	};

	const isOptionDisabled = (option) => {
		return selectedRooms.length >= 4 && !selectedRooms.includes(option);
	};

	const isLocationOptionDisabled = (option) => {
		return selectedRooms.length >= 4 && !selectedRooms.includes(option);
	};
	const isPartnerOptionDisabled = (option) => {
		return selectedRooms.length >= 4 && !selectedRooms.includes(option);
	};

	return (
		<div className="flex w-full items-center justify-between">
			<div className="flex w-[180px] gap-1 rounded-[99px] bg-[#F5F5F5] p-1">
				<div className="flex h-[48px] w-[48px] items-center justify-center rounded-[99px] bg-primary/60 p-2">
					<figure>
						<i className="mgc_house_line text-[32px] before:!text-primary" />
					</figure>
				</div>
				<div className=" ">
					<p className="pb-0 text-lg font-semibold text-primary/80">
						{(displayDataQuery.data &&
							displayDataQuery.data.data.length) ??
							0}{" "}
						Available
					</p>
					<p className="pt-0 text-[13px] font-[400] text-primary/80">
						Meeting Rooms
					</p>
				</div>
			</div>
			<div className="flex items-center gap-2.5">
				{/* <CustomShadSelect
					placeholder="Location"
					className="xl:!w-[186px]"
					value={selectedLocations}
					options={locations}
					onChange={handleLocationChange}
				/> */}
				<CustomMultiSelect
					isSearchable
					isMulti
					closeMenuOnSelect={false}
					value={selectedLocations}
					placeholder="Locations"
					className="xl:w-[186px]"
					options={locations}
					isOptionDisabled={isOptionDisabled}
					onChange={handleLocationsChange}
				/>
				<CustomMultiSelect
					isSearchable
					isMulti
					closeMenuOnSelect={false}
					value={selectedRooms}
					placeholder="Rooms"
					className="xl:w-[186px]"
					options={rooms}
					isOptionDisabled={isLocationOptionDisabled}
					onChange={handleRoomsChange}
				/>
				<CustomMultiSelect
					isSearchable
					isMulti
					closeMenuOnSelect={false}
					value={selectedPartners}
					placeholder="Partner"
					className="xl:w-[186px]"
					options={partners}
					isOptionDisabled={isPartnerOptionDisabled}
					onChange={handlePartnerChange}
				/>
				{/* <Select placeholder="Status" className="w-[186px]" /> */}
				<CustomShadSelect
					placeholder="Status"
					className="xl:!w-[186px]"
					value={selectedStatus}
					options={partners}
					onChange={handleStatusChange}
				/>
				<Button
					className="flex gap-2 px-2 text-white"
					onClick={onClick}
				>
					<GoScreenFull />
					Open Screen View
				</Button>
			</div>
		</div>
	);
};

export default DisplayHeader;
