import { Button } from "@/components/ui/button";
import { FaCircleCheck } from "react-icons/fa6";

interface SubscriptionCardProps {
	subscriptionType: "custom" | "basic" | "free";
	activePlan?: boolean;
}
const SubscriptionCard = ({ subscriptionType }: SubscriptionCardProps) => {
	return (
		<div className="flex flex-col rounded-[20px] border bg-white px-8 pb-6 pt-9 shadow-lg">
			<div>
				<h1 className="text-[28px] font-bold">
					{SubscriptionType[subscriptionType].title}
				</h1>
				<p className="font-normal text-[#6D748D]">
					{SubscriptionType[subscriptionType].desc}
				</p>
				<ul className="flex flex-col gap-y-2 pt-8">
					{SubscriptionType[subscriptionType].perks.map(
						(perk, index) => (
							<li
								key={index}
								className="flex items-center gap-2 font-normal "
							>
								<FaCircleCheck className="text-sm text-green-600" />
								{perk}
							</li>
						)
					)}
				</ul>
				<div className="mx-auto mt-4 w-[70%] text-center">
					<p className="py-2 text-lg text-[#6D748D]">
						Want to update your plan?
					</p>
					<Button variant="default" className="w-full">
						Contact Us
					</Button>
				</div>
			</div>
		</div>
	);
};

export default SubscriptionCard;

const SubscriptionType = {
	free: {
		title: "Free Plan",
		desc: "With 1 location and 1 space allocated to your business, start with Migranium today!",
		perks: [
			"1 Location",
			"1 Space",
			"Virtual Space Management",
			"Basic Analytics for insights",
			"Limited Monthly Reservations",
			"Real Time SMS/ Email Notifications",
		],
	},
	basic: {
		title: "Basic Plan",
		desc: "With 1 location and 1 space allocated to your business, start with Migranium today!",
		perks: [
			"1 Location(Add more for extra)",
			"Unlimited Spaces",
			"Dynamic Space Management",
			"Robust Analytics for insights",
			"150 Monthly Reservations",
			"Real Time SMS/ Email Notifications",
			"24/7 Support",
			"and more...",
		],
	},
	custom: {
		title: "Custom Plan",
		desc: "Customized Plans for our partners",
		perks: [
			"Unlimited Locations",
			"Unlimited Spaces",
			"Dynamic Space Management",
			"Customized Location Management",
			"Robust Analytics for Insights",
			"Unlimited Monthly Reservations",
			"24/7 Support",
			"and more...",
		],
	},
};
