import { cn } from "@/lib/utils";
import AcceptedIcon from "@/assets/icons/accepted.svg";
import DeclinedIcon from "@/assets/icons/declined.svg";
import { getInitials } from "@/services/utilities";

interface AvatarProps {
	name: string;
	variant: "guest" | "host";
	status?: "accepted" | "waiting" | "declined" | "pending";
	className?: string;
	spanClassName?: string;
}

const Avatar = ({
	name,
	variant,
	status,
	className,
	spanClassName,
}: AvatarProps) => {
	return (
		<span
			className={cn(
				"flex select-none items-center gap-2 text-[14px] md:text-[12px]",
				{},
				className
			)}
		>
			<span
				className={cn(
					"relative flex h-[16px] w-[16px] items-center justify-center rounded-full p-2.5 text-[8px] text-white md:text-[12px]",
					{
						"bg-primary text-white": variant === "host",
						"border border-[#B7B7B7] bg-[#F8F8F8] text-[#525252]":
							variant === "guest",
					},
					spanClassName
				)}
			>
				{getInitials(name)}
				<span className="absolute -bottom-1 -right-1">
					{status == "accepted" && <img src={AcceptedIcon} />}
					{status == "declined" && <img src={DeclinedIcon} />}
				</span>
			</span>
			{name}
		</span>
	);
};

export default Avatar;
