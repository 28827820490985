// import useScreenDimension from "@/hooks/useScreenDimension";
import { cn } from "@/lib/utils";
import { formatTime } from "@/services/utilities";
import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from "../ui/carousel";

interface TimeSlotPickerProps {
	timeslots: string[];
	isLoading: boolean;
	slotAmount?: number; // representing the amount of timeslots shown per card
	clickableSlot?: boolean;
	selectedTimeSlot?: string | null;
	selectTimeSlot?: (timeSlot: string) => void;
}
const TimeSlotPicker = ({
	selectTimeSlot,
	selectedTimeSlot,
	timeslots,
	slotAmount,
	isLoading,
}: TimeSlotPickerProps) => {
	return (
		<div className="pl-4 pr-6">
			{isLoading && <p>Loading...</p>}
			{timeslots.length && !isLoading ? (
				<Carousel>
					<CarouselContent className="cursor-pointer ml-0">
						{timeslots.map((timeslot, index) => (
							<CarouselItem
								key={index}
								className={cn(
									"basis-1/3 rounded-md !pl-2 text-[12px] font-medium",
									{
										"basis-1/2": slotAmount === 2,
										"basis-1/3": slotAmount === 3,
										"basis-1/4": slotAmount === 4,
										"basis-1/6": slotAmount === 6,
									}
								)}
							>
								<div
									className={cn(
										"flex w-full justify-center whitespace-nowrap rounded-md border  border-[#F2F2F2] bg-white py-1.5 shadow-inner duration-300 ease-in-out",
										{
											"bg-primary text-white":
												selectedTimeSlot === timeslot,
										}
									)}
									onClick={() =>
										selectTimeSlot &&
										selectTimeSlot(timeslot)
									}
								>
									{formatTime(timeslot)}
								</div>
							</CarouselItem>
						))}
					</CarouselContent>
					<CarouselPrevious
						type="button"
						className="-left-6 h-4  w-4 border-none text-primary  bg-transparent hover:bg-transparent"
					/>
					<CarouselNext
						type="button"
						className="-right-6 h-4 w-4 border-none text-primary  bg-transparent hover:bg-transparent"
					/>
				</Carousel>
			) : (
				!timeslots.length &&
				!isLoading && <p>No Time Slots Available For This Reservation</p>
			)}
		</div>
	);
};

export default TimeSlotPicker;
