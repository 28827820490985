import { useAppDispatch, useAppSelector } from "@/hooks/hooks";
import {
	setBookedBy,
	setLocation,
	setRoom,
	setSearchQuery,
	setStatus,
} from "@/redux/slices/meetingFilterSlice";
import { toTitleCase } from "@/services/utilities";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import CustomShadSelect from "../common/CustomShadSelect";
import Search from "../input/Search";
import { Booking } from "@/types/admin/booking";

const MeetingHeader: React.FC<{
	initialData: Booking[];
	filteredMeetings: Booking[];
	setData: Dispatch<SetStateAction<Booking[]>>;
}> = ({ initialData, setData, filteredMeetings }) => {
	const [locationOptions, setLocationsOptions] = useState<any>([]);
	const [roomOptions, setRoomOptions] = useState<any>([]);
	const [statusOptions, setStatusOptions] = useState<any>([]);
	const [bookedByOptions, setBookedByOptions] = useState<any>([]);

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const query = e.target.value;
		dispatch(setSearchQuery(query));
		setData((prevData) =>
			query.length === 0
				? filteredMeetings ?? []
				: prevData.filter(
						(item) =>
							item.title
								.toLocaleLowerCase()
								.includes(query.toLowerCase()) ||
							item.room.name
								.toLocaleLowerCase()
								.includes(query.toLowerCase())
					)
		);
	};

	useEffect(() => {
		const uniqueLocations = new Set();
		const uniqueRooms = new Set();
		const uniqueStatuses = new Set();
		const uniqueUsers = new Set();

		uniqueLocations.add({ value: "all", label: "All Locations" });
		uniqueRooms.add({ value: "all", label: "All Rooms" });
		uniqueStatuses.add({ value: "all", label: "All Statuses" });
		uniqueUsers.add({ value: "all", label: "All Users" });

		if (initialData && initialData.length) {
			initialData.forEach((meeting) => {
				if (meeting.room.location) {
					const locKey = `${meeting.room.location.id} - ${meeting.room.location.name}`;
					if (!uniqueLocations.has(locKey)) {
						uniqueLocations.add(locKey);
						uniqueLocations.add({
							value: meeting.room.location.name,
							label: meeting.room.location.name,
						});
					}
				}

				if (meeting.room) {
					const roomKey = `${meeting.room.id} - ${meeting.room.name}`;
					if (!uniqueRooms.has(roomKey)) {
						uniqueRooms.add(roomKey);
						uniqueRooms.add({
							value: meeting.room.name,
							label: meeting.room.name,
						});
					}
				}

				if (meeting.status) {
					const statusKey = `${meeting.status}`;
					if (!uniqueStatuses.has(statusKey)) {
						uniqueStatuses.add(statusKey);
						uniqueStatuses.add({
							value: meeting.status,
							label: toTitleCase(
								meeting.status.split("_").join(" ")
							),
						});
					}
				}

				if (meeting.host) {
					const userKey = `${meeting.host.id}-${meeting.host.name}`;
					if (!uniqueUsers.has(userKey)) {
						uniqueUsers.add(userKey);
						uniqueUsers.add({
							value: meeting.host.name,
							label: meeting.host.name,
						});
					}
				}
			});
		}

		setLocationsOptions(
			Array.from(uniqueLocations).filter(
				(location) => typeof location === "object"
			)
		);
		setRoomOptions(
			Array.from(uniqueRooms).filter((room) => typeof room === "object")
		);
		setStatusOptions(
			Array.from(uniqueStatuses).filter(
				(status) => typeof status === "object"
			)
		);
		setBookedByOptions(
			Array.from(uniqueUsers).filter((user) => typeof user === "object")
		);
	}, [initialData]);

	const dispatch = useAppDispatch();
	const { location, room, status, bookedBy, searchQuery } = useAppSelector(
		(state) => state.meetingFilter
	);

	const handleLocationChange = (selectedOption) => {
		dispatch(setLocation(selectedOption));
	};

	const handleRoomChange = (selectedOption) => {
		dispatch(setRoom(selectedOption));
	};

	const handleStatusChange = (selectedOption) => {
		dispatch(setStatus(selectedOption));
	};

	const handleBookedByChange = (selectedOption) => {
		dispatch(setBookedBy(selectedOption));
	};

	return (
		<div className="flex w-full items-center gap-2.5 gap-x-2 md:justify-between">
			<div className="flex flex-1 flex-row flex-wrap items-center gap-2.5">
				<Search
					value={searchQuery}
					onChange={handleSearch}
					placeholder="Search"
					className="w-fit flex-1 border-[#E5E5E7]"
				/>
				<CustomShadSelect
					value={location}
					options={locationOptions}
					onChange={handleLocationChange}
					placeholder="Location"
					className="min-w-fit flex-1 flex-wrap !border-[#E5E5E7]"
				/>
				<CustomShadSelect
					value={room}
					options={roomOptions}
					onChange={handleRoomChange}
					placeholder="Spaces"
					className="min-w-fit flex-1 flex-wrap !border-[#E5E5E7]"
				/>
				<CustomShadSelect
					value={status}
					options={statusOptions}
					onChange={handleStatusChange}
					placeholder="Status"
					className="min-w-fit flex-1 flex-wrap !border-[#E5E5E7]"
				/>
				<CustomShadSelect
					value={bookedBy}
					options={bookedByOptions}
					onChange={handleBookedByChange}
					placeholder="Reserved by"
					className="min-w-fit flex-1 flex-wrap !border-[#E5E5E7]"
				/>
			</div>

			{/* <DatePicker
				title="Select Dates"
				className="w-fit font-medium !text-[#858C95] md:w-[183px]"
			/> */}
			{/* <div className="block rounded-md border border-[#E5E5E7] bg-white p-2 shadow-sm md:hidden">
				<FilterIcon color="#6B7280" size="20px" />
			</div> */}
		</div>
	);
};

export default MeetingHeader;
