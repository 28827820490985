import { useAppDispatch, useAppSelector } from "@/hooks/hooks";
import Amenity from "./Amenity";
import { setAmenitiesFilter } from "@/redux/slices/filterSlice";

interface AmenityFilterProps {
	amenities: any[];
}
const AmenityFilter = ({ amenities }: AmenityFilterProps) => {
	const dispatch = useAppDispatch();
	const selectedAmenities =
		useAppSelector((state) => state.filters.amenities) || [];

	const handleAddSelectedAmenity = (amenity: string) => {
		const updatedAmenities = [...selectedAmenities, amenity];
		dispatch(setAmenitiesFilter(updatedAmenities));
	};

	const handleRemoveSelectedAmenity = (amenity: string) => {
		const updatedAmenities = selectedAmenities.filter(
			(item) => item !== amenity
		);
		dispatch(setAmenitiesFilter(updatedAmenities));
	};
	return (
		<div className="flex flex-wrap gap-2">
			{amenities?.map((amenity, index) => (
				<Amenity
					selected={selectedAmenities.some(
						(item) => item == amenity.name
					)}
					key={index}
					amenity={amenity.name}
					setSelected={handleAddSelectedAmenity}
					removeSelected={handleRemoveSelectedAmenity}
				/>
			))}
		</div>
	);
};

export default AmenityFilter;
