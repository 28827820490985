import { Outlet } from "react-router";
import Footer from "@/components/Footer";
import { changeTheme } from "@/services/utilities";

const GuestLayout = () => {
	changeTheme("#005893");

	return (
		<main className="relative mx-auto flex h-full max-w-[1280px] flex-col text-center">
			<div className="flex h-full  min-h-[100svh] w-full  flex-col pt-4 ">
				{/* //Add topbar here too  */}
				<Outlet />
				<div className="bottom-0 left-0 mt-auto w-full bg-transparent py-4 text-center ">
					<Footer />
				</div>
			</div>
			{/* the migranium footer can be added here  */}
		</main>
	);
};

export default GuestLayout;
