import { CustomToastProps } from "@/components/CustomToast";
import { Option } from "@/components/common/CustomShadSelect";
import { OperatingHour } from "@/redux/slices/locationSlice";
import { memberType } from "@/types/admin/members";
import { ScheduleData, Workable } from "@/types/location";
import { GetProp, UploadProps } from "antd";
import notification from "antd/es/notification";
import { DateTime } from "luxon";
import { useMemo } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router";
import { statesOptions } from "./constants";

//change location later
export type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

export const RoutePath = {
	HOME: "/",
	SIGN_IN: "/sign-in",
	SIGN_UP: "/sign-up",
	ADMIN_DISPLAY: "/admin/display", //to change
	ADMIN_ANALYTICS: "/admin/analytics",
	ADMIN_LOCATIONS: "/admin/location-management",
	ADMIN_BOOKINGS: "/admin/bookings",
	ADMIN_BOOKINGS_BOOK: "/admin/bookings/book",
	ADMIN_BOOKINGS_MANAGEMENT: "/admin/bookings/management",
	ADMIN_BOOKINGS_HISTORY: "/admin/bookings/history",
	ADMIN_TEAM_MEMBERS: "/admin/team-members",
	ADMIN_PARTNERS: "/admin/partner-management",
	ADMIN_SETTINGS: "/admin/settings",
	PARTNERS_ANALYTICS: "/partner/rooms",
	PARTNERS_MEETINGS: "/partner/meetings",
	PARTNERS_ROOMS: "/partner/rooms",
	PARTNERS_SETTINGS: "/partner/account",
	PARTNER_TEAM_MEMBERS: "/partner/my-business",
};

// export const CopyUrlLink = (link: string) => {
// 	navigator.clipboard
// 		.writeText(link)
// 		.then(() => {
// 			alert("Link copied to clipboard!");
// 		})
// 		.catch((error) => {
// 			console.error("Failed to copy link: ", error);
// 		});
// };

export const useQueryParams = () => {
	const { search } = useLocation();
	return useMemo(() => new URLSearchParams(search), [search]);
};

export const getInitials = (name: string) => {
	const nameParts = name.trim().split(" ");

	// Extract the first letter of each part and join them
	const initials = nameParts
		.map((part) => part.charAt(0).toUpperCase())
		// .slice(0, 2)
		.slice(0, 1)
		.join("");

	return initials;
};

export function encodeValue<U>(val: U): string {
	if (typeof val === "string") {
		return val;
	}
	return JSON.stringify(val);
}

export function decodeValue<U, T extends U>(val: string | null): T | null {
	if (val === null) {
		return null;
	}
	if (typeof val === "string") {
		try {
			return JSON.parse(val) as T;
		} catch (_) {
			//
		}
	}
	return val as T;
}

export const notifyWithIcon = (
	message = "",
	description: any,
	duration = 4
) => {
	notification.open({ message, description, duration });
};

export const formatDateTime = (date: string) => {
	if (date) {
		let dateParts = date.split("T");
		return dateParts[0];
	}
};

export const MemberType: {
	title: string;
	type: memberType;
	desc: string;
}[] = [
	{
		title: "Super Admin",
		type: "super_admin",
		desc: "Ultimate access for all system aspects, user management, system settings, data oversight, and full platform operation across all locations.",
	},
	{
		title: "Location Manager",
		type: "location_manager",
		desc: "Manages appointment schedules, patient flow, and resource allocation for a specific clinic or hospital location.",
	},
	{
		title: "Space Manager",
		type: "room_manager",
		desc: "Oversees reservations, updates space schedules, and coordinates healthcare provider-patient interactions for assigned rooms.",
	},
	{
		title: "Partner Manager",
		type: "partner_manager",
		desc: "Creates and oversees reservations, coordinates interactions for assigned partner(s).",
	},
	{
		title: "Team Member",
		type: "team_member",
		desc: "Can view all reservations and partner activity at selected location(s).",
	},
];

export const createFormData = (
	formData: FormData,
	key: string,
	data: any
): void => {
	if (typeof data === "object" && !Array.isArray(data)) {
		for (const prop in data) {
			if (data.hasOwnProperty(prop)) {
				createFormData(formData, `${key}[${prop}]`, data[prop]);
			}
		}
	} else if (Array.isArray(data)) {
		data.forEach((item, index) => {
			createFormData(formData, `${key}[${index}]`, item);
		});
	} else {
		formData.append(key, data);
	}
};

export const getBase64 = (file: FileType): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = (error) => reject(error);
	});

export function hexToHSL(hex) {
	hex = hex.replace("#", "");

	const r = parseInt(hex.substring(0, 2), 16) / 255;
	const g = parseInt(hex.substring(2, 4), 16) / 255;
	const b = parseInt(hex.substring(4, 6), 16) / 255;

	const max = Math.max(r, g, b);
	const min = Math.min(r, g, b);

	// Calculate lightness
	let h,
		s,
		l = (max + min) / 2;

	if (max === min) {
		h = s = 0;
	} else {
		const d = max - min;
		s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

		switch (max) {
			case r:
				h = (g - b) / d + (g < b ? 6 : 0);
				break;
			case g:
				h = (b - r) / d + 2;
				break;
			case b:
				h = (r - g) / d + 4;
				break;
		}
		h /= 6;
	}

	h = Math.round(h * 360);
	s = Math.round(s * 100);
	l = Math.round(l * 100);
	return `${h} ${s}% ${l}%;`;
}

export function hexToRGB(hex) {
	hex = hex.replace(/^#/, "");
	let r, g, b;
	if (hex.length === 3) {
		r = parseInt(hex[0] + hex[0], 16);
		g = parseInt(hex[1] + hex[1], 16);
		b = parseInt(hex[2] + hex[2], 16);
	} else if (hex.length === 6) {
		r = parseInt(hex.slice(0, 2), 16);
		g = parseInt(hex.slice(2, 4), 16);
		b = parseInt(hex.slice(4, 6), 16);
	} else {
		throw new Error("Invalid HEX color.");
	}

	return { r, g, b };
}

// export function generateTimeOptions(startTime = "00:00") {
// 	const [startHour, startMinute] = startTime.split(":").map(Number);

// 	const timeOptions: { label: string; value: string }[] = [];
// 	for (let hour = startHour; hour < 24; hour++) {
// 		// Change condition to hour < 24
// 		const minuteStart = hour === startHour ? startMinute : 0;
// 		for (let minute = minuteStart; minute < 60; minute += 15) {
// 			const formattedHour = hour.toString().padStart(2, "0");
// 			const formattedMinute = minute.toString().padStart(2, "0");
// 			timeOptions.push({
// 				label: `${formattedHour}:${formattedMinute}`,
// 				value: `${formattedHour}:${formattedMinute}`,
// 			});
// 		}
// 	}
// 	return timeOptions;
// }

export function generateTimeOptions(startTime = "00:00") {
	const [startHour, startMinute] = startTime.split(":").map(Number);

	const timeOptions: { label: string; value: string }[] = [];
	for (let hour = startHour; hour < 24; hour++) {
		const minuteStart = hour === startHour ? startMinute : 0;
		for (let minute = minuteStart; minute < 60; minute += 15) {
			const formattedHour = hour.toString().padStart(2, "0");
			const formattedMinute = minute.toString().padStart(2, "0");
			const time = `${formattedHour}:${formattedMinute}`;
			timeOptions.push({
				label: formatTime(time),
				value: time,
			});
		}
	}
	return timeOptions;
}

export const formatDateString = (datestring: string) => {
	const date = DateTime.fromISO(datestring);
	return date.toFormat(" MMMM  d'' yyyy");
};

export function formatTime(time: string): string {
	const [hours, minutes] = time.split(":");
	const meridiem = parseInt(hours) >= 12 ? "PM" : "AM";
	let formattedHours: number | string = parseInt(hours) % 12 || 12;
	formattedHours =
		formattedHours < 10 ? `0${formattedHours}` : formattedHours;
	const formattedTime = `${formattedHours}:${minutes} ${meridiem}`;
	return formattedTime;
}

// export function formatTime(timeStr) {
// 	const time = DateTime.fromFormat(timeStr, "HH:mm:ss", { zone: "utc" });
// 	const formattedTime = time.toFormat("h:mm a");
// 	return formattedTime;
// }
export const convertSchedule = (input: OperatingHour[]): ScheduleData => {
	const daysMapping: { [key: string]: string } = {
		Monday: "monday",
		Tuesday: "tuesday",
		Wednesday: "wednesday",
		Thursday: "thursday",
		Friday: "friday",
		Saturday: "saturday",
		Sunday: "sunday",
	};

	const output: ScheduleData = {};

	input.forEach((daySchedule) => {
		const dayKey = daysMapping[daySchedule.day];
		if (dayKey) {
			output[dayKey] = {
				is_active: Boolean(daySchedule.is_active),
				time_slots: daySchedule.time_slots.map((slot) => ({
					start_time: slot.start_time.slice(0, 5),
					end_time: slot.end_time.slice(0, 5),
					...(slot.is_active !== undefined && {
						is_active: slot.is_active,
					}),
				})),
			};
		}
	});

	return output;
};

const dayValueMap: { [key: string]: number } = {
	monday: 1,
	tuesday: 2,
	wednesday: 3,
	thursday: 4,
	friday: 5,
	saturday: 6,
	sunday: 7,
};

export const convertToOperatingHours = (data: Workable[]): OperatingHour[] => {
	return data.map((item) => ({
		day: item.day_of_week,
		day_value: dayValueMap[item.day_of_week],
		id: item.id,
		is_active: item.is_active,
		location_id: item.workable_id,
		time_slots: item.time_slots.map((slot) => ({
			start_time: slot.start_time,
			end_time: slot.end_time,
			is_active: slot.is_active,
		})),
	}));
};

export const convertColumnTimeToMinutesOrSeconds = (
	time: string,
	convert: "minutes" | "seconds"
): number => {
	const [hours, minutes, seconds] = time.split(":").map(Number);
	if (convert === "minutes") return hours * 60 + minutes + seconds / 60;
	else if (convert === "seconds")
		return hours * 3600 + minutes * 60 + seconds;
	return 0;
};

/** 
Function to check if the time slots are overlapping
 * @param {OperatingHour[]} time_slots - Array of time slots
 * @param slots.start - Start time of the time slot
 * @param slots.end - End time of the time slot
 * @returns {boolean} - Returns true if the time slots are overlapping
 */

export const isTimeOverlapping = (time_slots: OperatingHour[]): boolean => {
	for (let h = 0; h < time_slots.length; h++) {
		const slots = time_slots[h].time_slots.map((slot) => ({
			start: convertColumnTimeToMinutesOrSeconds(
				slot.start_time,
				"minutes"
			),
			end: convertColumnTimeToMinutesOrSeconds(slot.end_time, "minutes"),
			day_value: time_slots[h].day_value,
		}));
		for (let i = 0; i < slots.length; i++) {
			for (let j = i + 1; j < slots.length; j++) {
				if (
					(slots[i].start < slots[j].end &&
						slots[i].end > slots[j].start) ||
					(slots[j].start < slots[i].end &&
						slots[j].end > slots[i].start)
				) {
					toast.error(
						`${time_slots[h].day}'s times are overlapping`,
						{
							id: "add-location-form",
						}
					);
					return true;
				}
			}
		}
	}

	return false;
};

export const toTitleCase = (string?: string): string => {
	if (!string || typeof string !== "string") {
		return "";
	}

	return string
		.toLocaleLowerCase()
		.split(" ")
		.map((item) => item[0].toUpperCase() + item.slice(1, item.length))
		.join(" ");
};

export const generateColorWithOpacity = (color, opacity) => {
	const [r, g, b] = color
		.replace(/[^0-9,]/g, "")
		.split(",")
		.map(Number);

	const rgbaColor = `rgba(${r}, ${g}, ${b}, ${opacity})`;
	return rgbaColor;
};

export const serializeQueryArrayParamsToObject = (params: any) =>
	Object.keys(params).reduce((acc, keyName) => {
		if (Array.isArray(params[keyName])) {
			const arr = params[keyName].map((value) => value).toString();
			acc[keyName] = "[" + arr + "]";
		} else {
			acc[keyName] = params[keyName];
		}
		return acc;
	}, {});

/**
 * Converts an object to FormData
 * @param {Record<string, any>} obj - The object to convert to FormData
 * @returns {FormData} The resulting FormData object
 */
export const objectToFormData = (obj: Record<string, any>): FormData => {
	const formData = new FormData();

	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			formData.append(key, obj[key]);
		}
	}

	return formData;
};

export const handleFileChange = (
	event: React.ChangeEvent<HTMLInputElement>,
	allowedFormats: string[],
	customToast: (
		message: string,
		{ id, type, duration, undoText, undoHandler }: CustomToastProps
	) => void
): FileList | void => {
	if (event && event.target) {
		const files: FileList | null = event.target.files;
		if (files && files.length > 0) {
			for (let i = 0; i < files.length; i++) {
				if (files[i].size >= 2 * 1024 * 1024) {
					// Check if the file size is 2MB or larger
					customToast(
						"File size is too large. Please select a file smaller than 2MB.",
						{
							id: "file-size-error",
							type: "error",
						}
					);
					return;
				}

				if (!allowedFormats.includes(files[i].type)) {
					// Check if the file format is allowed
					customToast(
						"Invalid file format. Please select a JPG, JPEG, PNG, GIF, or SVG file.",
						{
							id: "file-format-error",
							type: "error",
						}
					);
					return;
				}
			}

			return files;
		}
	}
};

export const changeTheme = (theme: string) => {
	if (theme == "default") {
		theme = "#005893";
	}
	const { r, g, b } = hexToRGB(theme);
	document.documentElement.style.setProperty("--primary", `${r} ${g} ${b}`);
	return theme;
};

/**
 * Extracts unique values from an array of objects based on specified keys.
 *
 * @param {Array} data - The array of objects to process.
 * @param {Object} objectType - An object defining key names used for extraction.
 * @param {string} objectType.all - The label for the "all" option.
 * @param {string} objectType.keyName - The key name to access the nested object.
 * @param {string} objectType.valueKeyName - The key name to access the value within the nested object.
 * @param {string} objectType.labelKeyName - The key name to access the label within the nested object.
 * @param {boolean} [addAllRooms=true] - Whether to include the "all" option in the result.
 * @returns {Array} An array of unique values, each with a `value` and `label`.
 *
 * @example
 * const data = [
 *   { room: { id: '1', name: 'Room A' } },
 *   { room: { id: '2', name: 'Room B' } },
 *   { room: { id: '1', name: 'Room A' } }
 * ];
 *
 * const objectType = {
 *   all: 'All Rooms',
 *   keyName: 'room',
 *   valueKeyName: 'id',
 *   labelKeyName: 'name'
 * };
 *
 * const result = extractUniqueObjectValues(data, objectType);
 * console.log(result);
 * // Output:
 * // [
 * //   { value: 'all', label: 'All Rooms' },
 * //   { value: '1', label: 'Room A' },
 * //   { value: '2', label: 'Room B' }
 * // ]
 */
export const extractUniqueObjectValues: (
	data: any[],
	objectType: {
		all: string;
		keyName: string;
		valueKeyName: string;
		labelKeyName: string;
	},
	addAllRooms?: boolean
) => any = (data, objectType, addAllRooms = true) => {
	const uniqueValues: any = [];

	// Add 'all' option first
	if (addAllRooms) uniqueValues.push({ value: "all", label: objectType.all });

	if (data) {
		const uniqueKeys = new Set(); // Use a Set to track unique keys

		data.forEach((item) => {
			const key = item[objectType.keyName]?.[objectType.valueKeyName];
			if (key && !uniqueKeys.has(key)) {
				uniqueValues.push({
					value: key,
					label: item[objectType.keyName][objectType.labelKeyName],
				});
				uniqueKeys.add(key); // Add key to Set to ensure uniqueness
			}
		});
	}

	return uniqueValues;
};

export const sortByName = (
	data: any[],
	sortOrder: "asc" | "dsc" = "asc"
): any[] => {
	return data.sort((a, b) => {
		if (sortOrder === "asc") {
			return a.name.localeCompare(b.name);
		} else {
			return b.name.localeCompare(a.name);
		}
	});
};

export const insertAndSortTimes = (
	times: string[],
	newTime: string,
	isDateSame: boolean
): string[] => {
	if (isDateSame) {
		times = times.filter((item) => item !== newTime);
		times.push(newTime);
	}

	times.sort((a, b) => {
		// Convert time strings to total seconds for comparison
		const timeToSeconds = (time: string) => {
			const [hours, minutes, seconds] = time.split(":").map(Number);
			return hours * 3600 + minutes * 60 + seconds;
		};

		return timeToSeconds(a) - timeToSeconds(b);
	});

	return times;
};

export const changeCountry = (country: string) => {
	if (country) {
		return statesOptions(country);
	} else {
		return [];
	}
};

export const updateCountryAndState = (
	setValue: any,
	setProvinceOptions: any,
	isFromAddress: boolean,
	stateValue?: string,
	countryValue?: string
) => {
	if (isFromAddress) {
		setValue("country", countryValue);
		setProvinceOptions(changeCountry(countryValue ?? ""));
		// console.log(12, stateValue);
		setValue("state", stateValue);
	} else {
		if (countryValue) {
			// console.log(11);
			setValue("country", countryValue);
			setProvinceOptions(changeCountry(countryValue));
		}
		if (stateValue) {
			setValue("state", stateValue);
			// console.log(22, stateValue);
		}
	}
};

/**
 * Generates an array of time objects with labels and values in 30-minute intervals from 00:00 to 23:30.
 * @returns {Option[]} Array of time objects with label and value.
 */
export const generateTimes = (): Option[] => {
	const times: Option[] = [];
	for (let hour = 0; hour < 24; hour++) {
		for (let minutes = 0; minutes < 60; minutes += 15) {
			const value = `${String(hour).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
			const period = hour < 12 ? "AM" : "PM";
			const displayHour = hour % 12 === 0 ? 12 : hour % 12;
			const label = `${String(displayHour).padStart(2, "0")}:${String(minutes).padStart(2, "0")} ${period}`;
			times.push({ label, value });
		}
	}
	return times;
};

/**
 * Generates an array of end time options based on the provided timeslots and the selected start time.
 *
 * @param {string[]} timeslots - An array of time slot strings in the format "HH:MM".
 * @param {string} startTime - The selected start time, which is a string in the format "HH:MM".
 * @returns {Object[]} An array of objects with `label` and `value` properties, representing the end time options.
 *
 */
export const generateBookingEndTimeOptions = (
	timeslots: string[],
	startTime: string
): { label: string; value: string }[] => {
	const startIndex = timeslots.indexOf(startTime);
	const endTimeOptions: { label: string; value: string }[] = [];

	for (let i = startIndex + 1; i < timeslots.length; i++) {
		endTimeOptions.push({
			label: formatTime(timeslots[i]),
			value: timeslots[i],
		});
	}

	return endTimeOptions;
};


export const newGenerateBookingEndTimeOptions = (
  timeslots: string[],
  startTime: string
): { label: string; value: string }[] => {
  const startIndex = timeslots.indexOf(startTime);
  const endTimeOptions: { label: string; value: string }[] = [];

  if (startIndex === -1 || startIndex === timeslots.length - 1) {
    return endTimeOptions;
  }

  const startDateTime = DateTime.fromFormat(startTime, "HH:mm:ss");
  let previousDateTime = startDateTime;

  for (let i = startIndex + 1; i < timeslots.length; i++) {
    const currentDateTime = DateTime.fromFormat(timeslots[i], "HH:mm:ss");
    const diffFromPrevious = currentDateTime.diff(previousDateTime, "minutes").minutes;

    // Only include slots that are within 60 minutes of the previous slot
    if (diffFromPrevious <= 60) {
      endTimeOptions.push({
        label: formatTime(timeslots[i]),
        value: timeslots[i],
      });
      previousDateTime = currentDateTime;
    } else {
      break;
    }
  }

  return endTimeOptions;
};

// function formatTime(time: string): string {
//   return DateTime.fromFormat(time, "HH:mm:ss").toFormat("h:mm a");
// }



/**
 * Generates a duration string in hours and minutes from a given number of minutes.
 *
 * @param {number} minutes - The total number of minutes.
 * @returns {string} A formatted string representing the duration in hours and minutes.
 *
 */
export function generateDurationString(minutes: number): string {
	const hours = Math.floor(minutes / 60);
	const remainingMinutes = minutes % 60;
	let durationString = "";
	if (hours > 0) {
		durationString += `${hours} hr${hours !== 1 ? "s" : ""}`;
	}
	if (remainingMinutes > 0) {
		if (durationString) {
			durationString += " ";
		}
		durationString += `${remainingMinutes} min${remainingMinutes !== 1 ? "s" : ""}`;
	}

	if (durationString === "") {
		durationString = "0 minutes";
	}
	return durationString;
}

/**
 * Generates the duration in minutes between the selected start time and end time.
 * If the duration is less than 30 minutes, it returns 30 minutes.
 *
 * @param {string} startTime - The selected start time, which is a string in the format "HH:MM".
 * @param {string} endTime - The selected end time, which is a string in the format "HH:MM".
 * @returns {number} The duration in minutes.
 *
 * */

export function generateBookingDurationTime(
	startTime: string,
	endTime: string
): number {
	const [startHour, startMinute] = startTime.split(":").map(Number);
	const [endHour, endMinute] = endTime.split(":").map(Number);
	const durationInMinutes =
		endHour * 60 + endMinute - (startHour * 60 + startMinute);
	return durationInMinutes < 30 ? 30 : durationInMinutes;
}

export const selectRepeatOption = (option: string) => {
	const options = [
		{ label: "Daily", value: "daily" },
		{ label: "Weekly", value: "weekly" },
		{ label: "Monthly", value: "monthly" },
		{ label: "Yearly", value: "yearly" },
		{ label: "Does not repeat", value: "does not repeat" },
	];

	if (!option) {
		return options.find((opt) => opt.value === "does not repeat");
	}

	const selectedOption = options.find(
		(opt) => opt.value.toLowerCase() === option.toLowerCase()
	);
	return (
		selectedOption || options.find((opt) => opt.value === "does not repeat")
	);
};

export const findInSelectOption = (
	optionArray: { label: string; value: string }[],
	value: string
) => {
	console.log(optionArray?.find((option) => option.value == value));
	return optionArray?.find((option) => option.value == value) || null;
};

export const getSelectedDays = (
	previousRecurringData: { days?: string | string[] },
	defaultWeekDay: { label: string }
): string[] => {
	let days: string[] = [];

	if (typeof previousRecurringData?.days === "string") {
		try {
			days = JSON.parse(previousRecurringData.days);
		} catch (error) {
			console.error("Error parsing days:", error);
			days = [];
		}
	} else if (Array.isArray(previousRecurringData?.days)) {
		days = previousRecurringData.days;
	}

	return days.map((day) => {
		if (day) {
			return day.charAt(0).toUpperCase() + day.slice(1);
		} else {
			return defaultWeekDay.label;
		}
	});
};
