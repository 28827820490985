import BookingOverviewContent from "@/components/admin/BookingOverviewContent";
import { Button } from "@/components/ui/button";
import { Booking } from "@/types/admin/booking";
import { useNavigate } from "react-router";

const BookingOverview: React.FC<{ data: Booking }> = ({ data }) => {
	const navigate = useNavigate();

	return (
		<section className="space-y-6">
			<BookingOverviewContent data={data} />
			<div className="flex w-full gap-2 pb-4">
				<Button
					className="w-full bg-transparent"
					variant="outlinecustom"
					onClick={() => navigate("/admin/bookings/management")}
				>
					Edit
				</Button>
				<Button
					className="w-full"
					variant="default"
					onClick={() => navigate("/admin/bookings/management")}
				>
					View Reservation
				</Button>
			</div>
		</section>
	);
};

export default BookingOverview;
