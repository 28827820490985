import { useAppSelector } from "@/hooks/hooks";
import { cn } from "@/lib/utils";
import { useGetAdminRoomTimeslotsQuery } from "@/redux/features/api/adminBookingEndpoints";
import { Room } from "@/types/admin/booking";
import { useEffect, useState } from "react";
import BookingDetails from "../admin/booking/book/room/BookingDetails";
import BookingOverview from "../admin/booking/book/room/BookingOverview";
import RoomDetails from "../admin/RoomDetails";
import useCustomToast from "../CustomToast";

const AdminRoomOverview: React.FC<{ room: Room }> = ({ room }) => {
	const [overviewData, setOverviewData] = useState<any>();
	const [timeslots, setTimeSlots] = useState<string[]>(room.time_slots);
	const toast = useCustomToast();
	const [activeTab, setActiveTab] = useState<
		"reservation" | "room" | "overview"
	>("room");
	const handleTab = (tab) => {
		setActiveTab(tab);
	};

	const { isAllDay } = useAppSelector((state) => state.bookings.booking);
	const { date: filterDate } = useAppSelector((state) => state.filters);

	const [defaultDate, setDefaultDate] = useState(filterDate);

	const {
		isSuccess,
		data,
		isLoading: getAdminRoomTimeSlotsIsLoading,
	} = useGetAdminRoomTimeslotsQuery({
		room_id: room.id,
		date: defaultDate,
		duration: 30,
		is_allday: isAllDay ? 1 : 0,
	});
	useEffect(() => {
		if (isSuccess && data.data) {
			setTimeSlots(data.data);
		}
		if (isSuccess && data?.data?.length == 0) {
			toast("No  time slots  available for this date", {
				duration: 2000,
				type: "error",
				id: "timeslots",
			});
		}
	}, [isSuccess, data]);

	return (
		<div className="flex h-full w-full max-w-2xl flex-col pb-4">
			<div className="mb-4 flex w-full cursor-pointer border-b border-b-[#9CA3AF] text-[15px] font-semibold text-[#858C95] ">
				<button
					onClick={() => setActiveTab("room")}
					disabled={activeTab == "overview"}
					className={cn(
						"flex w-full justify-center py-2 disabled:opacity-60",
						{
							"border-b-2 border-b-primary text-primary":
								activeTab === "room",
						}
					)}
				>
					Space
				</button>
				<button
					onClick={() => setActiveTab("reservation")}
					disabled={activeTab == "overview"}
					className={cn(
						"flex w-full justify-center py-2 disabled:opacity-60",
						{
							"border-b-2 border-b-primary text-primary":
								activeTab === "reservation",
						}
					)}
				>
					Reservation
				</button>
			</div>
			<div className="px-4">
				{activeTab === "room" && (
					<div className="w-full">
						<RoomDetails
							timeslots={
								timeslots.map((time) => time.slice(0, -3)) ?? []
							}
							room={room}
							handleActiveTab={handleTab}
						/>
					</div>
				)}
				{activeTab === "reservation" && (
					<div className="w-full">
						<BookingDetails
							getAdminRoomTimeSlotsIsLoading={
								getAdminRoomTimeSlotsIsLoading
							}
							timeslots={
								timeslots.map((time) => time.slice(0, -3)) ?? []
							}
							room={room}
							defaultDate={defaultDate}
							setDefaultDate={setDefaultDate}
							// getTimeSlotsRefetch={getTimeSlotsRefetch}
							setOverviewData={setOverviewData}
							setActiveTab={setActiveTab}
							isLoading={false}
							bookingData={undefined}
						/>
					</div>
				)}
				{activeTab === "overview" && (
					<BookingOverview data={overviewData?.data} />
				)}
			</div>
		</div>
	);
};

export default AdminRoomOverview;
