import { useAppSelector } from "@/hooks/hooks";

interface DefaultTopbarProps {
	title: string;
}

const DefaultTopbar: React.FC<DefaultTopbarProps> = ({ title }) => {
	const user = useAppSelector((s) => s.user);

	return (
		<div className="mb-4 text-left ">
			<p className="leadning-[14.4px] font-manrope text-[12px] text-[#323539]">
				{user?.user?.business.name}
			</p>
			<p className="text-[22px] font-semibold text-[#323539]">
				{title || "Meetings"}
			</p>
		</div>
	);
};

export default DefaultTopbar;
