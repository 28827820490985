import { HiOutlineTrash } from "react-icons/hi2";
import { Button } from "../ui/button";
import { DialogClose } from "../ui/dialog";
// import { IoClose } from "react-icons/io5";

interface DeleteGuestProps {
	name: string;
	id?: string;
}
const DeleteGuest = ({ name }: DeleteGuestProps) => {
	return (
		<div className="flex items-start gap-2">
			<div className="flex flex-[0.1] pt-1">
				<HiOutlineTrash className="text-lg text-red-400" />
			</div>
			<div className="flex flex-grow flex-col gap-y-2">
				<h1 className="font-semibold text-[#323539]">Delete Guest</h1>
				<p>
					Are you sure you want to remove{" "}
					<span className="font-bold text-[#6D748D]">
						{name || "anna"}{" "}
					</span>
					from the guest list?
				</p>
				<div className="flex w-full items-center gap-x-1.5">
					<DialogClose className="w-full">
						<Button
							className="w-full rounded-md bg-white px-4 py-2 text-primary"
							variant="outline"
						>
							Cancel
						</Button>
					</DialogClose>
					<Button
						className=" w-full rounded-md px-4 py-2 text-white"
						variant="destructive"
					>
						Delete
					</Button>
				</div>
			</div>
		</div>
	);
};

export default DeleteGuest;
