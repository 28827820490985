import LocalStorage from "@/services/storage";
import { apiSlice } from "../apiSlice";
import { ADMIN_URI, BOOKING_URI } from "@/services/constants";
import { Booking, Location } from "@/types/admin/booking";

const storage = new LocalStorage();

// const partner_company_id = storage.getItem("MG:PARTNERID");
// const partner_company_id = localStorage.getItem("MG:USERID")
// console.log(partner_company_id)
// const partner_user_id = storage.getItem("MG:USERID");

export const partnerEndpoints = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		verifyPartnerRegistrationRequest: builder.query<any, { token: string }>(
			{
				query: ({ token }) => ({
					url: `/${BOOKING_URI}/request/${token}`,
					method: "get",
				}),
			}
		),
		registerPartner: builder.mutation<any, { data: any; token: string }>({
			query: ({ data, token }) => ({
				url: `/${BOOKING_URI}/request/${token}/accept`,
				method: "post",
				data,
			}),
		}),
		registerPartnerTeamMember: builder.mutation<
			any,
			{ data: any; token: string }
		>({
			query: ({ data, token }) => ({
				url: `/${BOOKING_URI}/accept-admin-invitation/${token}`,
				method: "post",
				data,
			}),
		}),

		// Above are unprotected routes
		// bookRoom: builder.mutation<any, any>({
		// 	query: (data) => {
		// 		const partner_company_id =
		// 			localStorage.getItem("MG:COMPANYID") || null;
		// 		return {
		// 			url: `/${BOOKING_URI}/${partner_company_id}/booking`,
		// 			method: "post",
		// 			data,
		// 			headers: {
		// 				Authorization:
		// 					"Bearer " + storage.getItem("TOKEN_COOKIE"),
		// 			},
		// 		};
		// 	},
		// 	invalidatesTags: ["PartnerMeetingRooms"],
		// }),
		updateBooking: builder.mutation<any, any>({
			query: ({ data, booking_id, companyId }) => {
				const partner_company_id =
					localStorage.getItem("MG:COMPANYID") || null;
				return {
					url: `/${BOOKING_URI}/${companyId ?? partner_company_id}/booking/${booking_id}`,
					method: "post",
					data,
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			invalidatesTags: [
				"AdminPartnerBookingDetail",
				"PartnerMeetingRooms",
				"RoomTimeslots",
			],
		}),
		updateBookingStatus: builder.mutation<any, any>({
			query: ({ data, booking_id }) => {
				const partner_company_id =
					localStorage.getItem("MG:COMPANYID") || null;
				return {
					url: `/${BOOKING_URI}/${partner_company_id}/booking/${booking_id}`,
					method: "post",
					data,
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			invalidatesTags: ["PartnerMeetingRooms"],
		}),
		getRoomTimeslots: builder.query<
			any,
			{
				room_id: string;
				date: any;
				duration: any;
				is_allday: any;
				company_id?: number;
			}
		>({
			query: ({ room_id, date, duration, is_allday, company_id }) => {
				const queryParams = new URLSearchParams();
				queryParams.append("date", date);
				if (duration !== 0) {
					queryParams.append("duration", duration.toString());
				}
				queryParams.append("is_allday", is_allday.toString());
				const partner_company_id =
					company_id ?? localStorage.getItem("MG:COMPANYID");
				// queryParams.append('is_allday', is_allday.toString());
				// const queryParams = new URLSearchParams({
				// 	date,
				// 	...(duration !== 0 && { duration: duration.toString() }),
				// 	is_allday: is_allday.toString(),
				//   });

				return {
					url: `/${BOOKING_URI}/${partner_company_id}/booking/get-room-time-slots/${room_id}?${queryParams}`,
					method: "get",
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			providesTags: ["RoomTimeslots"],
		}),

		getAllPartnerTeamMembers: builder.query<any, void>({
			query: () => {
				const partner_company_id =
					localStorage.getItem("MG:COMPANYID") || null;
				return {
					url: `/${BOOKING_URI}/${partner_company_id}/members`,
					method: "get",
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			providesTags: ["PartnerUsers"],
		}),
		getAllPartnerTeamMemberEmails: builder.query<
			any,
			{ company_id?: string }
		>({
			query: ({ company_id }) => {
				const partner_company_id =
					localStorage.getItem("MG:COMPANYID") || null;
				return {
					url: `/${BOOKING_URI}/${company_id ?? partner_company_id}/members/emails`,
					method: "get",
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
		}),
		updateBusiness: builder.mutation<any, { data: any }>({
			query: ({ data }) => {
				const partner_company_id =
					localStorage.getItem("MG:COMPANYID") || null;
				return {
					url: `/${BOOKING_URI}/${partner_company_id}/update-company-profile`,
					method: "post",
					data,
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			invalidatesTags: ["PartnerDetails"],
		}),
		addNewMember: builder.mutation<
			any,
			{ partner_company_id: number; data: any }
		>({
			query: ({ partner_company_id, data }) => {
				// const partner_company_id =
				// 	localStorage.getItem("MG:COMPANYID") || null;
				return {
					url: `/${BOOKING_URI}/${partner_company_id}/members`,
					method: "post",
					data,
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			invalidatesTags: ["PartnerUsers"],
		}),
		updateMember: builder.mutation<
			any,
			{
				member_id: number;
				partner_company_id?: number;
				data: {
					name?: string;
					email?: string;
					phone_number?: string;
					role?: "admin" | "guest";
				};
			}
		>({
			query: ({ data, partner_company_id, member_id }) => {
				return {
					url: `/${BOOKING_URI}/${partner_company_id}/members/${member_id}`,
					method: "post",
					data,
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			invalidatesTags: ["PartnerUsers"],
		}),
		deleteMember: builder.mutation<any, { member_id: number }>({
			query: ({ member_id }) => {
				const partner_company_id =
					localStorage.getItem("MG:COMPANYID") || null;
				return {
					url: `/${BOOKING_URI}/${partner_company_id}/members/${member_id}`,
					method: "delete",
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			invalidatesTags: ["PartnerUsers"],
		}),
		getFilterData: builder.query<
			{ data: { locations: Location[] } },
			{ isAdmin: boolean; business_or_company_id: number | undefined }
		>({
			query: ({ isAdmin, business_or_company_id }) => {
				// const partner_company_id =
				// 	localStorage.getItem("MG:COMPANYID") || null;
				// console.log(isAdmin)
				return {
					url: isAdmin
						? `/${ADMIN_URI}/${business_or_company_id}/admin-booking/filter-data`
						: `/${BOOKING_URI}/${business_or_company_id}/booking/filter-data`,
					method: "get",
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
		}),

		getPartnerAccountDetails: builder.query<any, { id: number }>({
			query: () => {
				const partner_company_id =
					localStorage.getItem("MG:COMPANYID") || null;
				return {
					url: `/${BOOKING_URI}/${partner_company_id}/detail/`,
					method: "get",
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			providesTags: ["PartnerDetails"],
		}),
		getPartnerMeetingDetails: builder.query<any, { id: number }>({
			query: ({ id }) => {
				const partner_company_id =
					localStorage.getItem("MG:COMPANYID") || null;
				return {
					url: `/room_booking/booking/${partner_company_id}/${id}`,
					method: "get",
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			providesTags: ["PartnerMeetingRooms"],
		}),
		getAllBookings: builder.query<{ data: Booking[] }, void>({
			query: () => {
				const partner_company_id =
					localStorage.getItem("MG:COMPANYID") || null;
				return {
					url: `/${BOOKING_URI}/${partner_company_id}/booking`,
					method: "get",
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			providesTags: ["PartnerMeetingRooms"],
		}),
	}),
});

export const {
	useVerifyPartnerRegistrationRequestQuery,
	useRegisterPartnerMutation,
	useRegisterPartnerTeamMemberMutation,
	useGetAllBookingsQuery,
	useUpdateBookingMutation,
	useUpdateBookingStatusMutation,
	useUpdateBusinessMutation,
	useGetRoomTimeslotsQuery,
	useAddNewMemberMutation,
	useUpdateMemberMutation,
	useDeleteMemberMutation,
	useGetFilterDataQuery,
	useGetAllPartnerTeamMembersQuery,
	useGetAllPartnerTeamMemberEmailsQuery,
	useGetPartnerMeetingDetailsQuery,
	// useGetAllPartnerMeetingQuery,
	useGetPartnerAccountDetailsQuery,
} = partnerEndpoints;
