import FormInput from "@/components/input/FormInput";
import { Button } from "@/components/ui/button";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { TbTrash } from "react-icons/tb";
import { IoClose } from "react-icons/io5";
import { MdOutlineModeEdit } from "react-icons/md";
import { useEffect, useState } from "react";
import { IoIosSave } from "react-icons/io";
import { cn } from "@/lib/utils";
import CustomMultiSelect from "@/components/common/CustomMultiSelect";
import { useGetAllAdminLocationRoomsQuery } from "@/redux/features/apiSlice";
import { FaBox } from "react-icons/fa6";
import { useAppSelector } from "@/hooks/hooks";
import { TeamMemberRoles } from "@/types/team-member";

export type Amenity = {
	created_at: any;
	description: string;
	icon: string;
	id: number;
	location_id: number;
	name: string;
	rooms: { id: string; name: string }[];
};

interface AmenitiesListCardProps {
	onSelect: () => void;
	checked: boolean;
	amenity: Amenity;
	removeAmenity: (item: Amenity) => void;
	handleEditSave: (id: number, item: any) => void;
	isLoading?: boolean;
}
const AmenitiesListCard: React.FC<AmenitiesListCardProps> = ({
	amenity,
	onSelect,
	checked,
	removeAmenity,
	handleEditSave,
}) => {
	const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");
	const id = location.pathname.split("/").pop();
	const [editDetails, setEditDetails] = useState(false);
	const { data, isSuccess } = useGetAllAdminLocationRoomsQuery({
		id: parseInt(id!),
	});
	const [allRooms, setAllRooms] = useState([]);
	const [selectedRooms, setSelectedRooms] = useState<
		{ label: string; value: string }[]
	>(
		amenity?.rooms?.length
			? amenity.rooms.map((room) => ({
					label: room.name,
					value: room.id,
				}))
			: []
	);

	useEffect(() => {
		if (isSuccess) {
			setAllRooms(
				data.data.map((room) => ({ name: room.name, id: room.id }))
			);
		}
	}, [isSuccess]);

	const initialState = {
		amenity: amenity?.name,
		rooms: amenity?.rooms,
	};
	const [formData, setFormData] = useState(initialState);

	const handleClose = () => {
		setEditDetails(false);
		setFormData(initialState);
		setSelectedRooms(
			amenity.rooms
				? amenity.rooms.map((room) => ({
						label: room.name,
						value: room.id,
					}))
				: []
		);
	};

	const handleEditToggle = () => {
		if (editDetails) {
			const formToSubmit = {
				name: formData.amenity,
				rooms: selectedRooms.map((room) => room.value),
			};
			handleEditSave(amenity.id, formToSubmit);
			//you are saving now
			// do the saving operation

			// onEditSave({ ...amenity, ...formData });
		} else if (!editDetails) {
			setEditDetails(!editDetails);
		}
	};

	return (
		<TooltipProvider>
			<div className="flex items-center justify-between rounded-[12px] bg-white px-4  py-3 shadow-[0px_0px_5px_rgba(0,0,0,0.25)]">
				<div className="flex gap-4 lg:flex-[0.2]">
					<input
						type="checkbox"
						checked={checked}
						onChange={onSelect}
						className="accent-primary"
					/>
					<div className="flex items-center gap-2">
						<FormInput
							inputType="text"
							value={formData.amenity}
							className={cn(" h-7 w-fit text-sm", {
								"border-none pl-0 text-sm  focus:border-none":
									!editDetails,
							})}
							disabled={!editDetails}
							onChange={(e) =>
								setFormData({
									...formData,
									amenity: e.target.value,
								})
							}
						/>
					</div>
				</div>

				<div className="hidden items-center justify-end gap-x-4 pl-4 lg:flex lg:flex-[0.6]">
					<div className="relative flex items-center gap-1">
						<CustomMultiSelect
							isDisabled={!editDetails}
							placeholder={
								<div className="flex items-center gap-2 capitalize">
									<FaBox /> Space Tagged
								</div>
							}
							isSearchable
							isMulti
							closeMenuOnSelect={false}
							value={selectedRooms}
							onChange={(selectedOption) => {
								setSelectedRooms(selectedOption);
								setFormData({
									...formData,
									rooms: selectedOption,
								});
							}}
							options={
								allRooms.length
									? allRooms.map((room: any) => ({
											label: room.name,
											value: room.id,
										}))
									: []
							}
						/>
					</div>
				</div>
				{userRole !== TeamMemberRoles.TeamMember &&
					userRole !== TeamMemberRoles.PartnerManager && (
					<div className="hidden justify-end gap-2 lg:flex lg:flex-[0.2]">
						{/* Cancel  */}
						{editDetails ? (
							<Tooltip delayDuration={0}>
								<TooltipTrigger asChild>
									<Button
										className="h-7 bg-[#E0E0E0] px-1.5 py-1.5"
										onClick={handleClose}
									>
										<IoClose />
									</Button>
								</TooltipTrigger>
								<TooltipContent side="top" sideOffset={10}>
									Cancel
								</TooltipContent>
							</Tooltip>
						) : null}

						<Tooltip delayDuration={0}>
							<TooltipTrigger asChild>
								<Button
									variant="outlinecustom"
									className="h-7 px-1.5 py-1.5"
									onClick={handleEditToggle}
								>
									{editDetails ? (
										<IoIosSave />
									) : (
										<MdOutlineModeEdit />
									)}
								</Button>
							</TooltipTrigger>
							<TooltipContent side="top" sideOffset={10}>
								{editDetails ? "Save" : "Edit"}
							</TooltipContent>
						</Tooltip>

						<Tooltip delayDuration={0}>
							<TooltipTrigger asChild>
								<Button
									variant="outlinecustom"
									className="h-7 px-1.5 py-1.5"
									onClick={() => removeAmenity(amenity)}
								>
									<TbTrash />
								</Button>
							</TooltipTrigger>
							<TooltipContent side="top" sideOffset={10}>
								Delete
							</TooltipContent>
						</Tooltip>
					</div>
				)}
			</div>
		</TooltipProvider>
	);
};

export default AmenitiesListCard;
