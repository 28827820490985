import CustomSelect from "@/components/common/CustomSelect";
import FormInput from "@/components/input/FormInput";
import { Button } from "@/components/ui/button";
import { DatePicker } from "@/components/ui/datepicker";
import useRecurrence from "@/hooks/admin/booking/useRecurrence";
import { useAppDispatch, useAppSelector } from "@/hooks/hooks";
import { cn } from "@/lib/utils";
import { updateBooking } from "@/redux/slices/bookingSlice";
import { daysOfWeek, weekInMonthOptions } from "@/services/constants";
import { months } from "@/services/constants";
import { DateTime } from "luxon";
import toast from "react-hot-toast";

type DayDateFormat = "by_date" | "by_day";

interface IRecurringData {
	is_recurring: number;
	frequency: string;
	end_date: string;
	day_date_format?: DayDateFormat;
	days?: string[];
	dates?: number[];
	week_occurrences?: number[];
	yearly_month?: string;
}

interface RecurrenceFormProps {
	defaultType: {
		label: string;
		value: string;
	};
	handleClose: () => void;
	handleSaveRecurrenceData: (recurrence_data: any) => void;
	// setOccurenceText?: (text: string) => void;
}

const RecurrenceForm = ({
	handleClose,
	handleSaveRecurrenceData,
	// setOccurenceText,
	defaultType,
}: RecurrenceFormProps) => {
	const { date } = useAppSelector((state) => state.bookings.booking);
	const dispatch = useAppDispatch();
	const {
		startDate,
		setStartDate,
		endDate,
		setEndDate,
		repeatEvery,
		setRepeatEvery,
		optionSelected,
		setOptionSelected,
		optionMonthlySelected,
		setOptionMonthlySelected,
		day,
		setDay,
		selectedDays,
		toggleDaySelection,
		weekMonth,
		setWeekMonth,
		weekDay,
		setWeekDay,
		month,
		setMonth,
		saveRecurrenceData,
		validateForm,
	} = useRecurrence(defaultType);
	const handleSubmit = (e) => {
		const recurringData: IRecurringData = {
			is_recurring: 1,
			frequency: repeatEvery.value,
			day_date_format: "by_day",
			...(endDate && { end_date: endDate }),
		};
		// Determine the day_date_format
		if (repeatEvery.value === "monthly") {
			recurringData.day_date_format =
				optionMonthlySelected === "monthlyOption1"
					? "by_date"
					: "by_day";
		} else if (repeatEvery.value === "yearly") {
			recurringData.day_date_format =
				optionSelected === "yearlyOption1" ? "by_date" : "by_day";
		}
		if (repeatEvery.value === "weekly") {
			recurringData.days = selectedDays.map((day) => day.toLowerCase());
		}
		if (
			repeatEvery.value === "monthly" &&
			optionMonthlySelected === "monthlyOption1"
		) {
			recurringData.dates = [parseInt(day)];
		} else if (
			repeatEvery.value === "yearly" &&
			optionSelected === "yearlyOption1"
		) {
			recurringData.dates = [parseInt(month.value), parseInt(day)];
		}
		// Determine the week_occurrences
		if (
			repeatEvery.value === "monthly" &&
			optionMonthlySelected === "monthlyOption2"
		) {
			recurringData.week_occurrences = [
				parseInt(weekMonth.value),
				// parseInt(weekDay.value),
			];
			recurringData.days = selectedDays.map((day) => day.toLowerCase());
		} else if (
			repeatEvery.value === "yearly" &&
			optionSelected === "yearlyOption2"
		) {
			recurringData.week_occurrences = [parseInt(weekMonth.value)];
			recurringData.days = selectedDays.map((day) => day.toLowerCase());
		}
		// Add the yearly_month if frequency is yearly
		if (repeatEvery.value === "yearly" && month.label) {
			recurringData.yearly_month = month.label;
		}
		e.preventDefault();
		if (validateForm()) {
			saveRecurrenceData(recurringData);
			handleSaveRecurrenceData(recurringData);
		} else {
			toast.error("Please fill in all required fields.");
		}
	};

	const renderWeeklyOptions = () => {
		if (repeatEvery.value !== "weekly") {
			return null;
		}
		return (
			<div className="flex items-center gap-4">
				<p>On Day</p>
				<div className="flex items-center gap-1">
					{daysOfWeek.map((day) => (
						<button
							key={day.value}
							type="button"
							onClick={() => toggleDaySelection(day.label)}
							className={cn(
								"h-[36px] w-[36px] rounded-md",
								selectedDays.includes(day.label)
									? "bg-primary text-white"
									: "bg-white"
							)}
						>
							{day.label.charAt(0)}
						</button>
					))}
				</div>
			</div>
		);
	};

	const renderYearlyOptions = () => {
		if (repeatEvery.value !== "yearly") {
			return null;
		}
		return (
			<div className="flex flex-col gap-y-2 pl-8">
				<div className={cn("flex items-center gap-2")}>
					<input
						type="radio"
						name="yearlyOption"
						className="accent-primary"
						checked={optionSelected === "yearlyOption1"}
						onChange={() => setOptionSelected("yearlyOption1")}
					/>
					<p className="text-xs">On </p>
					<CustomSelect
						className={cn("w-[125px]", {
							"pointer-events-none opacity-50":
								optionSelected === "yearlyOption2",
						})}
						value={month}
						onChange={(selectedOption: any) =>
							setMonth(selectedOption)
						}
						options={months}
					/>
					<FormInput
						inputType="text"
						className={cn("flex w-[50px] items-center", {
							"pointer-events-none opacity-50":
								optionSelected === "yearlyOption2",
						})}
						value={day}
						onChange={(e) => setDay(e.target.value)}
					/>
				</div>
				<div className={cn("flex items-center gap-2")}>
					<input
						type="radio"
						className="accent-primary"
						name="yearlyOption"
						checked={optionSelected === "yearlyOption2"}
						onChange={() => setOptionSelected("yearlyOption2")}
					/>
					<p className="text-xs">On the</p>
					<CustomSelect
						className={cn("w-[125px]", {
							"pointer-events-none opacity-50":
								optionSelected === "yearlyOption1",
						})}
						value={weekMonth}
						onChange={(selectedOption: any) =>
							setWeekMonth(selectedOption)
						}
						options={[
							{ label: "First", value: "1" },
							{ label: "Second", value: "2" },
							{ label: "Third", value: "3" },
							{ label: "Fourth", value: "4" },
						]}
					/>
					<CustomSelect
						className={cn("w-[125px]", {
							"pointer-events-none opacity-50":
								optionSelected === "yearlyOption1",
						})}
						value={weekDay}
						onChange={(selectedOption: any) =>
							setWeekDay(selectedOption)
						}
						options={daysOfWeek}
					/>
					<p>Of</p>
					<CustomSelect
						className={cn("w-[125px]", {
							"pointer-events-none opacity-50":
								optionSelected === "yearlyOption1",
						})}
						value={month}
						onChange={(selectedOption: any) =>
							setMonth(selectedOption)
						}
						options={months}
					/>
				</div>
			</div>
		);
	};

	const renderMonthlyOptions = () => {
		if (repeatEvery.value !== "monthly") {
			return null;
		}
		return (
			<div className="flex flex-col gap-y-2 pl-4">
				<div className="flex items-center gap-3">
					<input
						type="radio"
						className="accent-primary"
						name="monthlyOption"
						checked={optionMonthlySelected === "monthlyOption1"}
						onChange={() =>
							setOptionMonthlySelected("monthlyOption1")
						}
					/>
					<p>On day</p>
					<FormInput
						inputType="text"
						className={cn("w-[71px]", {
							"pointer-events-none opacity-50":
								optionMonthlySelected === "monthlyOption2",
						})}
						value={day}
						onChange={(e) => {
							const value = e.target.value;
							if (
								/^\d*$/.test(value) &&
								(value === "" || parseInt(value) <= 31)
							) {
								setDay(value);
							}
						}}
					/>
				</div>
				<div className="flex items-center gap-2">
					<input
						type="radio"
						className="accent-primary"
						name="monthlyOption"
						checked={optionMonthlySelected === "monthlyOption2"}
						onChange={() =>
							setOptionMonthlySelected("monthlyOption2")
						}
					/>
					<p>On the</p>
					<CustomSelect
						className={cn("w-[172px]", {
							"pointer-events-none opacity-50":
								optionMonthlySelected === "monthlyOption1",
						})}
						value={weekMonth}
						onChange={(selectedOption: any) =>
							setWeekMonth(selectedOption)
						}
						options={weekInMonthOptions}
					/>
					<CustomSelect
						className={cn("w-[172px]", {
							"pointer-events-none opacity-50":
								optionMonthlySelected === "monthlyOption1",
						})}
						value={weekDay}
						onChange={(selectedOption: any) => {
							toggleDaySelection(selectedOption.label);
							setWeekDay(selectedOption);
						}}
						options={daysOfWeek}
					/>
				</div>
			</div>
		);
	};

	return (
		<form onSubmit={handleSubmit} className="w-full rounded-[30px] ">
			<div className="flex w-full justify-center rounded-tl-[30px] rounded-tr-[30px] bg-white py-4">
				<h1 className="font-semibold text-primary">Set Recurrence</h1>
			</div>
			<div className="flex flex-col gap-y-5 px-6 py-4 ">
				<div className="flex items-center gap-2">
					<p>Start</p>
					<DatePicker
						title="Start Date"
						className="w-[275px]"
						date={
							date
								? DateTime.fromISO(date).toJSDate()
								: DateTime.fromISO(startDate).toJSDate()
						}
						setDate={(date) => {
							if (date) {
								const luxonDate = DateTime.fromJSDate(date);
								setStartDate(luxonDate.toISODate() ?? "");
								dispatch(
									updateBooking({
										date: luxonDate.toISODate() ?? "",
									})
								);
							}
						}}
					/>
				</div>
				{/* Repeat Every */}
				<div className="flex items-center gap-2">
					<p>Repeat Every</p>
					<div className="flex gap-2">
						<CustomSelect
							value={repeatEvery}
							onChange={(selectedOption: any) =>
								setRepeatEvery(selectedOption)
							}
							className="!h-[36px] w-[172px]"
							options={[
								{ label: "Day", value: "daily" },
								{ label: "Week", value: "weekly" },
								{ label: "Month", value: "monthly" },
								{ label: "Year", value: "yearly" },
							]}
						/>
					</div>
				</div>
				{/* Yearly */}
				{repeatEvery.value === "yearly" && renderYearlyOptions()}
				{/* Monthly */}
				{repeatEvery.value === "monthly" && renderMonthlyOptions()}
				{/* Weekly */}
				{repeatEvery.value === "weekly" && renderWeeklyOptions()}
				{/* End Date */}
				<div className="flex items-center gap-2">
					<p>End</p>
					<DatePicker
						title="Select Date"
						className="w-[275px]"
						disabledDate={
							startDate
								? DateTime.fromISO(startDate).toJSDate()
								: undefined
						}
						date={
							endDate
								? DateTime.fromISO(endDate).toJSDate()
								: undefined
						}
						setDate={(date) => {
							if (date) {
								const luxonDate = DateTime.fromJSDate(date);
								setEndDate(luxonDate.toISODate() ?? "");
							}
						}}
					/>
				</div>

				<p>
					{repeatEvery.value.toLowerCase() === "daily" && (
						<span>
							Occurs every day starting {startDate} until{" "}
							{endDate}
						</span>
					)}

					{repeatEvery.value.toLowerCase() == "weekly" && (
						<span>
							Occurs every week{" "}
							{selectedDays.map((item, idx) => (
								<span key={idx}>{item}, </span>
							))}{" "}
							starting {startDate} until {endDate}
						</span>
					)}
					{/* {repeatEvery.label === "Year" && <span>Occurs every {parseInt(occurrence) > 1 ? occurrence : null}  {parseInt(occurrence) > 1 ? "weeks" : "week"} starting{" "} {startDate}</span>} */}
				</p>
				<div className="flex w-full gap-2">
					<Button
						className="w-full"
						type="button"
						variant="outlinecustom"
						onClick={handleClose}
					>
						Cancel
					</Button>
					<Button className="w-full" onClick={handleSubmit}>
						Save
					</Button>
				</div>
			</div>
		</form>
	);
};

export default RecurrenceForm;
