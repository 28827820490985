import { ADMIN_URI, BOOKING_MANAGEMENT_URI, ROOM_BOOKING_URI } from "@/services/constants";
import LocalStorage from "@/services/storage";
import { Room } from "@/types/admin/booking";
import { apiSlice } from "../apiSlice";

const storage = new LocalStorage();

export const adminBookingEndpoints = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAllAdminAvailableRooms: builder.query<{ data: Room[] }, any>({
			query: (filters) => {
				const queryParams = new URLSearchParams(filters);
				const business_id = localStorage.getItem("ID") || null;
				return {
					url: `/${ADMIN_URI}/${business_id}/admin-booking/rooms?${queryParams}`,
					method: "get",
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			providesTags: ["AdminMeetingRooms"],
		}),
		registerAdminTeamMember: builder.mutation<any, any>({
			query: (data) => {
				// const business_id = localStorage.getItem("ID") || null;
				return {
					url: `/${ROOM_BOOKING_URI}/accept-team-member-invitation`,
					method: "post",
					data,
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			// invalidatesTags: [""],
		}),

		getAllAdminBookingFilters: builder.query<any, any>({
			query: (status) => {
				const queryParams = new URLSearchParams(status);
				const business_id = localStorage.getItem("ID") || null;
				return {
					url: `/${ADMIN_URI}/${business_id}/bookings?${queryParams}`,
					method: "get",
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			providesTags: ["Adminbooking"],
		}),
		getAdminRoomTimeslots: builder.query<any, any>({
			query: ({ room_id, date, duration, is_allday }) => {
				const queryParams = new URLSearchParams({
					date,
					duration: duration.toString(),
					is_allday: is_allday.toString(),
				});
				const business_id = localStorage.getItem("ID") || null;

				return {
					url: `/${ADMIN_URI}/${business_id}/admin-booking/get-room-time-slots/${room_id}?${queryParams}`,
					method: "get",
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			// providesTags: ["AdminMeetingRooms"],
		}),
		getAdminBookingFilterData: builder.query<any, void>({
			query: () => {
				const business_id = localStorage.getItem("ID") || null;
				return {
					url: `/${ADMIN_URI}/${business_id}/admin-booking/filter-data`,
					method: "get",
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
		}),
		getAdminBookingPartnerList: builder.query<any, void>({
			query: () => {
				const business_id = localStorage.getItem("ID") || null;
				return {
					url: `/${ADMIN_URI}/${business_id}/admin-booking/partner-list`,
					method: "get",
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
		}),
		getAdminPartnerRoomsAvailableForChange: builder.query<
			any,
			{ booking_id: number }
		>({
			query: ({ booking_id }) => {
				const business_id = localStorage.getItem("ID") || null;
				return {
					url: `/${ADMIN_URI}/${business_id}/bookings/${booking_id}/get-rooms-for-change`,
					method: "get",
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
		}),
		getDisplayData: builder.query({
			query: () => {
				const business_id = localStorage.getItem("ID") || null;
				return {
					url: `/${ADMIN_URI}/${business_id}/display-summary`,
					method: "get",
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
		}),
		//Delete recurring booking
		deleteRecurringReservation: builder.mutation<
			any,
			{
				recurring_id,
				company_id
			}
		>({
			query: (data) => {
				return {
					url: `/${BOOKING_MANAGEMENT_URI}/${data.company_id}/recurring/${data.recurring_id}`,
					method: "delete",
					// data,
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			invalidatesTags: ["Adminbooking", "AdminPartnerBookingDetail"],
		}),
		getRecurringReservationDetail: builder.query<
		any,
		{ booking_id: number , companyId: number}
	>({
			query: ({ booking_id, companyId }) => {
				const company_id =
					localStorage.getItem("MG:COMPANYID") || null;
				return {
					url: `/${BOOKING_MANAGEMENT_URI}/${companyId ?? company_id}/recurring/${booking_id}`,
					method: "get",
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
		}),
		updateRecurringBooking: builder.mutation<any, any>({
			query: ({ data, booking_id, companyId }) => {
				const partner_company_id =
					localStorage.getItem("MG:COMPANYID") || null;
				return {
					url: `/${BOOKING_MANAGEMENT_URI}/${companyId ?? partner_company_id}/recurring/${booking_id}`,
					method: "post",
					data,
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			invalidatesTags: [
				"PartnerMeetingRooms",
				"RoomTimeslots",
				"AdminPartnerBookingDetail",
			],
		}),
	}),
});

export const {
	useGetAllAdminAvailableRoomsQuery,
	useRegisterAdminTeamMemberMutation,
	useUpdateRecurringBookingMutation,
	useGetRecurringReservationDetailQuery,
	// useGetAllAdminBookingsQuery,
	useGetAllAdminLocationRoomsQuery,
	useGetAllAdminLocationAmenitiesQuery,
	useGetAllAdminLocationsQuery,
	useGetAdminRoomTimeslotsQuery,
	useGetAdminBookingFilterDataQuery,
	useGetAdminBookingPartnerListQuery,
	useGetAdminPartnerRoomsAvailableForChangeQuery,
	useGetDisplayDataQuery,
	useDeleteRecurringReservationMutation,
	// useGetAdminBookingPartnerList,
	// useGetAllAdminPartnersQuery,
	// useGetAllAdminPartnerDetailsQuery,
	// useGetAdminBookingDetailsQuery,
	// useDeleteAdminPartnerMutation,
	// useAddNewPartnerMutation,
} = adminBookingEndpoints;
