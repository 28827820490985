import { useAuth } from "@/hooks/auth";
import { useAppSelector } from "@/hooks/hooks";
import { cn } from "@/lib/utils";
import React, { useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { getInitials, RoutePath } from "../../services/utilities";
import { Button } from "../ui/button";
import { ScrollArea } from "../ui/scroll-area";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "../ui/tooltip";
// import { MdOutlineDashboardCustomize } from "react-icons/md";

type NavItem = {
	icon: React.ReactNode;
	label: string;
	path: string;
	subMenu?: {
		icon?: React.ReactNode;
		label: string;
		path: string;
	}[];
};

const navItems: NavItem[] = [
	// {
	// 	icon: "mgc_airplay_line",
	// 	label: "Display",
	// 	path: RoutePath.ADMIN_DISPLAY,
	// },
	{
		icon: "mgc_book_6_line",
		label: "Reservations",
		path: RoutePath.ADMIN_BOOKINGS,
		subMenu: [
			{
				label: "Reserve",
				path: RoutePath.ADMIN_BOOKINGS_BOOK,
			},
			{
				label: "Manage",
				path: RoutePath.ADMIN_BOOKINGS_MANAGEMENT,
			},
			{
				label: "View All",
				path: RoutePath.ADMIN_BOOKINGS_HISTORY,
			},
		],
	},
	{
		icon: "mgc_chart_bar_line",
		label: "Analytics",
		path: RoutePath.ADMIN_ANALYTICS,
	},
	{
		icon: "mgc_location_2_line",
		label: "Location",
		path: RoutePath.ADMIN_LOCATIONS,
	},
	{
		icon: "mgc_group_line",
		label: "Partners",
		path: RoutePath.ADMIN_PARTNERS,
	},
	{
		icon: "mgc_classify_add_line",
		label: "Members",
		path: RoutePath.ADMIN_TEAM_MEMBERS,
	},
];

// type Props = {
// 	variant?: "icon" | "default";
// };

const AdminSidebar = () => {
	const location = useLocation();
	const { user } = useAppSelector((state) => state.user);
	const [isIconView, setIsIconView] = useState(true);
	const { logout } = useAuth();
	const [variant, setVariant] = useState<"icon" | "default">("icon");
	const handleMouseEnter = () => {
		setVariant("default");
		setIsIconView(false);
	};
	const businessName = user?.name ?? "";
	const handleMouseLeave = () => {
		setVariant("icon");
		setIsIconView(true);
	};

	return (
		<TooltipProvider>
			<aside
				className={cn(
					"absolute left-0 top-0 z-20 hidden h-screen bg-primary opacity-100  transition-all md:block  ",
					{
						"w-[160px] min-w-[160px]": variant === "default",
						" w-[60px] min-w-[60px]": variant === "icon",
					}
				)}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				<div className="relative flex h-full w-full flex-col py-2 ">
					<NavLink
						to={RoutePath.ADMIN_SETTINGS}
						className={cn(
							"my-3 flex items-center justify-center gap-2 px-4 py-1 text-[14px]",
							{
								"bg-white": location.pathname.includes(
									RoutePath.ADMIN_SETTINGS
								),
								"duration-200 ease-in-out hover:bg-white/10":
									!location.pathname.includes(
										RoutePath.ADMIN_SETTINGS
									),
							}
						)}
					>
						<p
							className={cn(
								"flex items-center justify-center rounded-full p-2.5 font-semibold text-white",
								{
									"bg-primary/30 text-primary":
										location.pathname.includes(
											RoutePath.ADMIN_SETTINGS
										),
								}
							)}
						>
							{getInitials(businessName)}
						</p>

						{isIconView ? null : (
							<p
								className={cn("whitespace-nowrap text-white ", {
									"text-primary": location.pathname.includes(
										RoutePath.ADMIN_SETTINGS
									),
								})}
							>
								My Account
							</p>
						)}
					</NavLink>
					<ScrollArea className="max-h-[100svh]">
						{navItems.map((item) => {
							const isActive = location.pathname.includes(
								item.path
							);
							return isIconView ? (
								<Tooltip key={item.label} delayDuration={0}>
									<TooltipTrigger asChild>
										<NavLink
											to={item.path}
											className={cn(
												"mb-2 flex items-center justify-center gap-2  py-2.5 text-sm text-white transition-all",
												{
													"before-text-primary bg-white text-primary":
														isActive,
													"duration-200 ease-in-out hover:bg-white/10":
														!isActive,
												}
											)}
										>
											<i
												className={cn(
													`${item.icon} text-[20px] before:!text-white`,
													{
														"before:!text-primary":
															isActive,
													}
												)}
											/>
										</NavLink>
									</TooltipTrigger>
									<TooltipContent
										side="right"
										sideOffset={10}
									>
										{item.label}
									</TooltipContent>
								</Tooltip>
							) : (
								<React.Fragment key={item.label}>
									<NavLink
										className={cn("flex ", {
											"bg-white": isActive,
											"duration-200 ease-in-out hover:bg-white/10":
												!isActive,
										})}
										to={item.path}
									>
										<div
											className={cn(
												"mb-2 flex items-center gap-2 px-6 py-2.5 text-base font-medium text-white transition-all ",
												{
													"text-primary": isActive,
												}
											)}
										>
											<i
												className={cn(
													`${item.icon} text-[20px] before:!text-white`,
													{
														"before:!text-primary":
															isActive,
													}
												)}
											/>

											<div>{item.label}</div>
										</div>
									</NavLink>
									{item.subMenu &&
										item.subMenu.map((subItem) => {
											const isSubItemActive =
												location.pathname.includes(
													subItem.path
												);
											return (
												<NavLink
													className={cn("flex pl-6", {
														"bg-white":
															isSubItemActive,
														"duration-200 ease-in-out hover:bg-white/10":
															!isSubItemActive,
													})}
													key={subItem.label}
													to={subItem.path}
												>
													<div
														className={cn(
															"mb-2 flex items-center gap-2 px-6 py-2.5 text-base font-medium text-white transition-all ",
															{
																"text-primary":
																	isSubItemActive,
															}
														)}
													>
														<div className="text-sm">
															{subItem.icon}
														</div>
														<div>
															{subItem.label}
														</div>
													</div>
												</NavLink>
											);
										})}
								</React.Fragment>
							);
						})}
					</ScrollArea>
					<div className=" mt-auto pb-4 text-sm">
						{isIconView ? (
							<>
								<Tooltip delayDuration={0}>
									<TooltipTrigger asChild>
										<Button
											className="w-full text-white hover:bg-primary hover:text-white"
											variant="ghost"
											size="icon"
										>
											<i className="mgc_entrance_line text-[18px] before:!text-white" />
										</Button>
									</TooltipTrigger>
									<TooltipContent
										side="right"
										sideOffset={10}
									>
										Sign out
									</TooltipContent>
								</Tooltip>
							</>
						) : (
							<>
								<Button
									className="w-full rounded-none text-base text-white hover:bg-primary hover:text-white"
									variant="ghost"
									onClick={logout}
								>
									<i className="mgc_entrance_line text-[20px] before:!text-white hover:before:!text-primary" />
									<span className="ml-2">Sign out</span>
								</Button>
							</>
						)}
					</div>
				</div>
			</aside>
		</TooltipProvider>
	);
};

export default AdminSidebar;
