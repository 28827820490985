import CustomMultiSelect from "@/components/common/CustomMultiSelect";
import ImageUploader from "@/components/common/ImageUploader";
import useCustomToast from "@/components/CustomToast";
import FormInput from "@/components/input/FormInput";
import { LoaderButton } from "@/components/Loader/LoaderButton";
import { DialogClose } from "@/components/ui/dialog";
import { useAppSelector } from "@/hooks/hooks";
import { cn } from "@/lib/utils";
import {
	useAddRoomMutation,
	useGetAllAdminLocationAmenitiesQuery,
} from "@/redux/features/apiSlice";
import { allowedUploadFormats } from "@/services/constants";
import {
	createFormData,
	handleFileChange
} from "@/services/utilities";
import { TeamMemberRoles } from "@/types/team-member";
import { useEffect, useState } from "react";
import { BsCloudArrowDown } from "react-icons/bs";
import { IoClose } from "react-icons/io5";

// type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

// Turn to a component later
export const uploadButton = (
	<button
		style={{
			border: 0,
			background: "none",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		}}
		type="button"
	>
		<BsCloudArrowDown className="text-xl" />
		<div style={{ marginTop: 8 }}>Upload</div>
	</button>
);

const AddRoom = ({ handleClose }: { handleClose: () => void }) => {
	const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");
	// const user = useAppSelector((state) => state?.user?.user);
	const id = location.pathname.split("/").pop();
	const { data: amenitiesList } = useGetAllAdminLocationAmenitiesQuery({
		id: parseInt(id!),
	});
	const [addNewRoom, { isLoading }] = useAddRoomMutation();
	const customToast = useCustomToast();
	const formDataToSend = new FormData();
	// const roomImagesRef: any | null = useRef(null);
	// const [previewOpen, setPreviewOpen] = useState(false);
	// const [previewImage, setPreviewImage] = useState("");
	const [roomPhotos, setRoomPhotos] = useState<FileList>();
	const [selectedAmenities, setSelectedAmenities] = useState<
		{ label: string; value: string }[]
	>([]);
	const handleRemoveSelectedAmenity = (amenity: string) => {
		const updatedAmenities = selectedAmenities.filter(
			(item) => item.label !== amenity
		);
		setSelectedAmenities(updatedAmenities);
		setFormData({
			...formData,
			amenities: updatedAmenities,
		});
	};
	// const handlePreview = async (file: UploadFile) => {
	// 	if (!file.url && !file.preview) {
	// 		file.preview = await getBase64(file.originFileObj as FileType);
	// 	}

	// 	setPreviewImage(file.url || (file.preview as string));
	// 	setPreviewOpen(true);
	// };

	// const handleChange: UploadProps["onChange"] = ({
	// 	fileList: newFileList,
	// }) => {
	// 	setFileList(newFileList);
	// 	const files = newFileList.map((file) => file.originFileObj as File);
	// 	// setFormData((prevFormData) => ({
	// 	// 	...prevFormData,
	// 	// 	roomPhotos: files,
	// 	// }));
	// };

	// const beforeUpload = (file: RcFile) => {
	// 	const isJpgOrPng =
	// 		file.type === "image/jpeg" || file.type === "image/png";
	// 	if (!isJpgOrPng) {
	// 		message.error("You can only upload JPG/PNG file!");
	// 	}
	// 	const isLt2M = file.size / 1024 / 1024 < 2;
	// 	if (!isLt2M) {
	// 		message.error("Image must be smaller than 2MB!");
	// 	}
	// 	return isJpgOrPng && isLt2M;
	// };

	const [formData, setFormData] = useState<{
		name: string;
		address: string;
		capacity: string;
		description: "";
		amenities: any[];
		roomPhotos?: FileList;
	}>({
		name: "",
		address: "",
		capacity: "",
		description: "",
		amenities: [],
		// roomPhotos: [],
	});

	const handleSubmit = async () => {
		if (
			userRole === TeamMemberRoles.TeamMember ||
			userRole === TeamMemberRoles.PartnerManager
		)
			return customToast("You are not allowed to perform this action", {
				id: "add-room",
				type: "error",
			});

		if (formData.name === "")
			return customToast("Kindly add room Name", {
				id: "add-room",
				type: "error",
			});

		if (formData.capacity === "")
			return customToast("Kindly add capacity", {
				id: "add-room",
				type: "error",
			});

		let amenitiesID = [];
		if (formData.amenities) {
			const amenities = amenitiesList.data.filter((amenity) =>
				formData.amenities.some((item) => item.label == amenity.name)
			);
			amenitiesID = amenities.map((amenity) => amenity.id);
		}
		formDataToSend.append("name", formData.name);
		formDataToSend.append("capacity", formData.capacity);
		if (formData.address.length)
			formDataToSend.append("address", formData.address);
		if (formData.description.length)
			formDataToSend.append("description", formData.description);
		if (formData?.roomPhotos)
			Array.from(formData?.roomPhotos).forEach((file, index) => {
				formDataToSend.append(`room_photos[${index}]`, file);
			});
		// files.forEach((file:any, index) => {
		// 	formDataToSend.append(`room_photos[${index}]`, file);
		// });

		// selectedImages.forEach((file, index) => {
		// 	formDataToSend.append(`room_photos[${index}]`, file);
		// });
		createFormData(formDataToSend, "amenities", amenitiesID);

		try {
			const submissionData = {
				id: id!,
				data: formDataToSend,
			};
			const response = await addNewRoom(submissionData).unwrap();
			customToast(response.message, {
				id: "add-room",
				type: "error",
			});
			handleClose();
		} catch (error) {
			customToast("An error occured", {
				id: "add-room",
				type: "error",
			});
			// Handle error
			// console.error(error);
		}
	};

	useEffect(() => {
		setFormData((prevFormData) => ({ ...prevFormData, roomPhotos }));
	}, [roomPhotos]);

	return (
		<div className="flex max-h-[70svh] w-full flex-col gap-y-3 rounded-[30px]  bg-[#F5F5F5]">
			<div className="flex w-full cursor-pointer justify-center rounded-tl-[30px] rounded-tr-[30px]  border-b border-b-primary bg-white pb-2 pt-4 text-center text-[15px] font-semibold text-primary ">
				<h1 className="font-semibold text-primary">Add Spaces</h1>
			</div>
			<div className="overflow-y-auto px-8 py-2">
				{/* <div className="">
					<Upload
						multiple={true}
						listType="picture-card"
						accept="image/*"
						fileList={fileList}
						beforeUpload={beforeUpload}
						onPreview={handlePreview}
						onChange={handleChange}
					>
						{fileList.length >= 8 ? null : uploadButton}
					</Upload>
					{previewImage && (
						<Image
							wrapperStyle={{ display: "none" }}
							preview={{
								visible: previewOpen,
								onVisibleChange: (visible) =>
									setPreviewOpen(visible),
								afterOpenChange: (visible) =>
									!visible && setPreviewImage(""),
							}}
							src={previewImage}
						/>
					)}
				</div> */}
				<div className="add-room-grid m-auto max-w-full">
					<ImageUploader
						photos={formData.roomPhotos}
						setPhotos={setRoomPhotos}
						allowedUploadFormats={allowedUploadFormats}
						handleFileChange={handleFileChange}
					/>
				</div>

				{/* Description  */}
				<div className="flex flex-col gap-y-4 pt-4">
					<FormInput
						name="name"
						value={formData.name}
						label="Space Name"
						inputType="text"
						placeholder="Add Name"
						onChange={(e) =>
							setFormData({
								...formData,
								name: e.target.value,
							})
						}
					/>
					<div className="flex gap-4">
						<FormInput
							name="address"
							value={formData.address}
							label="Space Location"
							inputType="text"
							placeholder="Add Address"
							onChange={(e) =>
								setFormData({
									...formData,
									address: e.target.value,
								})
							}
						/>
						<FormInput
							name="capacity"
							value={formData.capacity}
							min={0}
							label="Capacity"
							inputType="number"
							placeholder="Add Capacity"
							max={5000}
							onChange={(e) => {
								if (e.target.value > 5000 || e.target.value < 0)
									return;
								setFormData({
									...formData,
									capacity: e.target.value,
								});
							}}
						/>
					</div>
					<FormInput
						label="Description"
						value={formData.description}
						textarea={true}
						className="text-[15px]"
						rows={2}
						placeholder="Add Description"
						onChange={(e) =>
							setFormData({
								...formData,
								description: e.target.value,
							})
						}
					/>

					<div className="flex flex-col gap-y-1">
						<h1 className="text-sm font-medium text-basecolor">
							Amenities
						</h1>
						<CustomMultiSelect
							placeholder="Add Amenity"
							isSearchable
							isMulti
							closeMenuOnSelect={false}
							value={selectedAmenities}
							onChange={(selectedOption) => {
								setSelectedAmenities(selectedOption);
								setFormData({
									...formData,
									amenities: selectedOption,
								});
							}}
							options={
								amenitiesList.data.length
									? amenitiesList?.data?.map((amenity) => ({
											label: amenity.name,
											value: amenity.name,
										}))
									: []
							}
						/>
					</div>

					{/* Amenities  */}
					<div className="mb-4 space-y-2">
						<div className="flex items-center gap-2">
							{selectedAmenities.map((item, id) => (
								<AmenityHolder
									removeSelected={handleRemoveSelectedAmenity}
									key={id}
									amenity={item.label}
									variant="overview"
								/>
							))}
						</div>
					</div>
				</div>
			</div>
			<div className="flex w-full items-center gap-x-1.5 rounded-bl-[30px] rounded-br-[30px] px-4 py-4">
				<DialogClose className="w-full rounded-md border border-primary bg-transparent px-4 py-2 text-primary">
					Cancel
				</DialogClose>
				<LoaderButton
					className=" w-full rounded-md px-4 py-2 text-white "
					onClick={handleSubmit}
					loading={isLoading}
					disabled={isLoading}
					loaderSize={20}
				>
					Add Space
				</LoaderButton>
			</div>
		</div>
	);
};

export default AddRoom;

interface AmenityHolderProps {
	amenity: string;
	variant?: "overview" | "list";
	removeSelected: (amenity: string) => void;
}
const AmenityHolder = ({
	amenity,
	variant,
	removeSelected,
}: AmenityHolderProps) => {
	return (
		<div>
			<span
				className={cn("flex items-center gap-1 text-[#959595] ", {
					"rounded-full bg-primary/5 px-2 py-1":
						variant === "overview",
				})}
			>
				<p
					className={cn("text-[14px] font-normal text-[#6B7280]", {
						"text-[10px]": variant === "list",
					})}
				>
					{amenity}
				</p>
				<button onClick={() => removeSelected(amenity)}>
					<IoClose />
				</button>
			</span>
		</div>
	);
};
