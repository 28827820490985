import useCustomToast from "@/components/CustomToast";
import NameLetter from "@/components/NameLetter";
import Modal from "@/components/modals/Modal";
import DeletePartner from "@/components/modals/admin/DeletePartner";
import PartnerSummary from "@/components/modals/admin/partners/PartnerSummary";
import { Button } from "@/components/ui/button";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { useAppSelector } from "@/hooks/hooks";
import { useDeleteAdminPartnerMutation } from "@/redux/features/api/adminEndpoints";
import { PartnerCompany } from "@/types/partners/member";
import { TeamMemberRoles } from "@/types/team-member";
import { IUserPartner } from "@/types/user";
import { ChevronRight } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

interface PartnerListCardProps {
	onSelect: () => void;
	checked: boolean;
	partner: PartnerCompany;
}
const PartnerListCard: React.FC<PartnerListCardProps> = ({
	onSelect,
	checked,
	partner,
}) => {
	const user = useAppSelector((state) => state.user.user) as IUserPartner;
	const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");
	const isPartnerOfCompany =
		user.team_member_info &&
		!!user.team_member_info.partner_companies.find(
			(item) => item.id === partner.id
		);
	const navigate = useNavigate();
	const [showDelete, setShowDelete] = useState(false);
	const toast = useCustomToast();
	const [showSummary, setShowSummary] = useState(false);

	const [deleteAdminPartner, { isSuccess, isLoading, isError }] =
		useDeleteAdminPartnerMutation();

	const handleDelete = () => {
		if (
			(userRole === TeamMemberRoles.TeamMember ||
				userRole === TeamMemberRoles.PartnerManager) &&
			isPartnerOfCompany
		)
			return toast("You are not allowed to perform this action", {
				id: "permission-error",
				type: "error",
			});

		const data = {
			company_ids: [parseInt(partner.id.toString())],
		};
		if (partner.id == undefined) {
			toast("Partner ID is undefined", {
				id: "partner-id-error",
				type: "error",
			});
		} else {
			deleteAdminPartner(data);
		}
	};

	useEffect(() => {
		if (isSuccess) {
			toast("Partner deleted successfully", {
				id: "deletion-success",
				type: "success",
			});
			setShowDelete(false);
		}
		if (isError) {
			toast("Error deleting partner", {
				id: "deletion-error",
				type: "error",
			});
			setShowDelete(false);
		}
	}, [isSuccess, isError]);

	return (
		<>
			<TooltipProvider>
				<div
					className="flex cursor-pointer items-center rounded-lg bg-white px-6 py-2 drop-shadow-[0px_1px_5px_rgba(0,0,0,0.1)]"
					onClick={() =>
						navigate(`${partner?.id}`, {
							state: { id: partner?.id },
						})
					}
				>
					{/* Checkbox and partner logo  */}
					<div className="flex flex-[0.35] gap-2 ">
						<input
							type="checkbox"
							checked={checked}
							onChange={onSelect}
						/>
						<div className=" flex items-center gap-3">
							<NameLetter name={partner?.name.split(" ")[0][0]} />
							<div>
								<h1 className="font-bold text-basecolor">
									{partner?.name}
								</h1>
								{partner?.address && (
									<p className="flex items-center gap-2 text-[10px] font-light text-basecolor">
										<i className="mgc_location_line before:!text-basecolor" />{" "}
										{partner?.address}
									</p>
								)}
							</div>
						</div>
					</div>

					{/* More Details  */}
					<div className="flex flex-[0.45] justify-between">
						<div className="flex flex-[0.3] items-center justify-center gap-4 pr-8">
							{partner?.status == "pending" ? (
								<span className="rounded-full bg-[#F6E2E1]  px-7 py-0.5 text-[10px] font-medium text-[#B7241D] ">
									Pending
								</span>
							) : (
								<span className="flex items-center gap-1.5 rounded-full bg-[#F3F3F3] px-2 py-1 text-[10px]">
									<i className="mgc_calendar_line text-xs before:!text-[#858C95]" />
									<span className="text-[10px] font-light text-[#506F8B] ">
										Reservations
									</span>
									<p className="text-light text-[10px] font-semibold text-basecolor">
										{partner?.statics.total_bookings}
									</p>
								</span>
							)}
						</div>

						{/* Add admin as an offset  */}
						<div className="flex flex-[0.7] flex-col border-l pl-2">
							<p className="text-[10px] text-[#6B7280]">Admin</p>
							<div className="flex items-center gap-2">
								<span className="flex items-center gap-2 text-sm font-light text-primary">
									<i className="mgc_user_security_line text-[14px] before:!text-primary" />
									<div className="flex flex-col">
										<p className="text-sm font-light text-primary">
											{partner?.super_admin?.name ??
												"N/A"}
										</p>
									</div>
								</span>

								<Tooltip delayDuration={0}>
									<TooltipTrigger asChild>
										<span className="flex items-center justify-center rounded-full bg-[#F9F9F9] p-1">
											<i className="mgc_mail_line text-[12px] before:!text-primary xs:text-[13px]" />
										</span>
									</TooltipTrigger>
									<TooltipContent side="top" sideOffset={10}>
										{partner?.email ?? "N/A"}
									</TooltipContent>
								</Tooltip>

								<Tooltip delayDuration={0}>
									<TooltipTrigger asChild>
										<span className="flex items-center justify-center rounded-full bg-[#F9F9F9] p-1">
											<i className="mgc_phone_line text-[12px] before:!text-primary xs:text-[13px]" />
										</span>
									</TooltipTrigger>
									<TooltipContent side="top" sideOffset={10}>
										{partner?.phone_number ?? "N/A"}
									</TooltipContent>
								</Tooltip>
							</div>
						</div>
					</div>

					{/* Action buttons  */}
					<div className="flex-[0.2]">
						<div className="hidden justify-end gap-1 border-l pl-16 lg:flex ">
							<Tooltip delayDuration={0}>
								<TooltipTrigger asChild>
									<Button
										variant="outlinecustom"
										className="h-7 px-1.5 py-1.5"
										onClick={(e) => {
											e.stopPropagation();
											setShowSummary(true);
										}}
									>
										<i className="mgc_information_line before:!text-primary" />
									</Button>
								</TooltipTrigger>
								<TooltipContent side="top" sideOffset={10}>
									Information
								</TooltipContent>
							</Tooltip>
							{((userRole !== TeamMemberRoles.TeamMember &&
								userRole !== TeamMemberRoles.PartnerManager) ||
								isPartnerOfCompany) && (
								<>
									<Tooltip delayDuration={0}>
										<TooltipTrigger asChild>
											<Button
												variant="outlinecustom"
												className="h-7 px-1.5 py-1.5"
											>
												<i className="mgc_edit_2_line before:!text-primary" />
											</Button>
										</TooltipTrigger>
										<TooltipContent
											side="top"
											sideOffset={10}
										>
											Edit
										</TooltipContent>
									</Tooltip>

									<Tooltip delayDuration={0}>
										<TooltipTrigger asChild>
											<Button
												variant="outlinecustom"
												className="h-7 px-1.5 py-1.5"
												onClick={(e) => {
													e.stopPropagation();
													setShowDelete(true);
												}}
											>
												<i className="mgc_delete_2_line before:!text-primary" />
											</Button>
										</TooltipTrigger>
										<TooltipContent
											side="top"
											sideOffset={10}
										>
											Delete
										</TooltipContent>
									</Tooltip>
								</>
							)}

							<Tooltip delayDuration={0}>
								<TooltipTrigger asChild>
									<Button
										variant="ghost"
										className="h-7 px-1.5 py-1.5 text-[#858C95]"
									>
										<ChevronRight />
									</Button>
								</TooltipTrigger>
								<TooltipContent side="top" sideOffset={10}>
									More
								</TooltipContent>
							</Tooltip>
						</div>
					</div>
				</div>
			</TooltipProvider>
			{((userRole !== TeamMemberRoles.TeamMember &&
				userRole !== TeamMemberRoles.PartnerManager) ||
				isPartnerOfCompany) && (
				<Modal
					dialogClassName="!rounded-[12px] max-w-[360px] bg-white w-full"
					open={showDelete}
					setOpen={setShowDelete}
				>
					<DeletePartner
						isLoading={isLoading}
						handleDelete={handleDelete}
						name={partner?.name ?? "N/A"}
					/>
				</Modal>
			)}
			<Modal
				dialogClassName="!rounded-[12px] max-w-2xl p-0 bg-white w-full"
				open={showSummary}
				setOpen={setShowSummary}
			>
				<PartnerSummary data={partner} />
			</Modal>
		</>
	);
};

export default PartnerListCard;
