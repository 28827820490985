// import React from 'react'

import { cn } from "@/lib/utils";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogTitle,
} from "@/components/ui/dialog";
import { Dispatch, ReactNode, SetStateAction } from "react";

interface ModalProps {
	dialogClassName?: string;
	dialogTitle?: string;
	dialogDescription?: string;
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	onInteractOutside?: (e) => void;
	showDeleteIcon?: boolean;
	children: ReactNode;
}
const Modal = ({
	dialogClassName,
	dialogTitle,
	dialogDescription,
	open,
	setOpen,
	onInteractOutside,
	showDeleteIcon,
	children,
}: ModalProps) => {
	// Takes in both dialog and drawer for mobile

	return (
		<Dialog open={open} onOpenChange={setOpen} modal>
			<DialogContent
				hasDeleteIcon={showDeleteIcon}
				onInteractOutside={onInteractOutside}
				className={cn(
					"flex w-full !rounded-[30px] border border-[#E5E5E7] bg-[#F5F5F5] p-4  shadow-md",
					dialogClassName
				)}
			>
				<DialogDescription className="sr-only">{dialogDescription}</DialogDescription>
				<DialogTitle className="sr-only">{dialogTitle}</DialogTitle>
				{children}
			</DialogContent>
		</Dialog>
	);
};

export default Modal;
