import { ScrollArea } from "@/components/ui/scroll-area";
import { useState } from "react";
import BillingHistoryCard from "./BillingHistoryCard";

export type Item = {
	id: number;
	date: string;
	plan: string;
	price: string;
};
const BillingHistory = () => {
	const initialItems: Item[] = [
		{ id: 1, date: "10 April 2024", plan: "custom plan", price: "400.00" },
		{ id: 2, date: "10 mar 2024", plan: "custom plan", price: "400.00" },
		{ id: 3, date: "10 feb 2023", plan: "custom plan", price: "400.00" },
	];
	const [items] = useState<Item[]>(initialItems);
	const [selectedItems, setSelectedItems] = useState<Item[]>([]);
	const [, setSelectAll] = useState<boolean>(false);

	// const toggleSelectAll = () => {
	// 	if (selectAll) {
	// 		setSelectedItems([]);
	// 	} else {
	// 		setSelectedItems(items);
	// 	}
	// 	setSelectAll(!selectAll);
	// };

	const toggleSelectItem = (item: Item) => {
		if (selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
			setSelectedItems(
				selectedItems.filter(
					(selectedItem) => selectedItem.id !== item.id
				)
			);
		} else {
			setSelectedItems([...selectedItems, item]);
		}
		setSelectAll(false);
	};
	return (
		<ScrollArea className="h-[30vh] w-full">
			<div className="flex flex-col gap-y-4">
				{/* <div className="flex justify-between px-4">
					<div className="flex items-center gap-2 text-[14px]">
						<input
							id="select-all"
							type="checkbox"
							onClick={toggleSelectAll}
						/>
						<label
							className={cn("", { "text-[#7D7E80]": !selectAll })}
						>
							Select All
						</label>
					</div>
					<TooltipProvider>
						<div className="flex items-center gap-1">
							<Tooltip delayDuration={0}>
								<TooltipTrigger asChild>
									<Button
										variant="outlinecustom"
										disabled={!selectAll}
										className="h-7 px-1.5 py-1.5"
									>
										<TbTrash />
									</Button>
								</TooltipTrigger>
								<TooltipContent side="top" sideOffset={10}>
									Delete
								</TooltipContent>
							</Tooltip>
						</div>
					</TooltipProvider>
				</div> */}

				<div className="flex flex-col gap-2 px-1">
					{items.map((item, index) => (
						<BillingHistoryCard
							item={item}
							key={index}
							onSelect={() => toggleSelectItem(item)}
							checked={selectedItems.some(
								(selectedItem) => selectedItem.id === item.id
							)}
						/>
					))}
				</div>
			</div>
		</ScrollArea>
	);
};

export default BillingHistory;
