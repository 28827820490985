import { AnalyticsQueryParams } from "@/types/location";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the types

interface AnalyticsState {
	data: any | null;
	loading: boolean;
	error: string | null;
	success: boolean;
	query_data: AnalyticsQueryParams;
}

const initialState: AnalyticsState = {
	data: null,
	loading: false,
	error: null,
	success: false,
	query_data: {
		"location_ids[]": "all",
	} as AnalyticsQueryParams,
};

const analyticsSlice = createSlice({
	name: "analytics",
	initialState,
	reducers: {
		fetchAnalyticsLoading(state) {
			state.loading = true;
			state.error = null;
			state.success = false;
		},
		resetQueryData(state) {
			state.query_data = {} as AnalyticsQueryParams;
		},
		setQueryData(state, action: PayloadAction<AnalyticsQueryParams>) {
			state.query_data = { ...state.query_data, ...action.payload };
			// console.log(state.query_data);
		},
		fetchAnalyticsSuccess(state, action: any) {
			state.data = action.payload;
			state.loading = false;
			state.success = true;
		},
		fetchAnalyticsFailure(state, action: PayloadAction<any>) {
			state.loading = false;
			state.error = action.payload;
			state.success = false;
		},
	},
});

export const {
	fetchAnalyticsLoading,
	fetchAnalyticsSuccess,
	fetchAnalyticsFailure,
	resetQueryData,
	setQueryData,
} = analyticsSlice.actions;

export default analyticsSlice.reducer;
