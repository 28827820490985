// import { Button } from "@/components/ui/button";
import { Button } from "@/components/ui/button";
import { useAppSelector } from "@/hooks/hooks";
import { cn } from "@/lib/utils";
import { useUpdateBookingStatusMutation } from "@/redux/features/api/partnerEndpoints";
import { formatDateString } from "@/services/utilities";
import { TeamMemberRoles } from "@/types/team-member";
import { ChevronRight } from "lucide-react";
import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router";

interface MobileMeetingCardProps {
	meetingdata: any;
	setShowDeleteMeeting: Dispatch<SetStateAction<boolean>>;
}
const MobileMeetingCard: React.FC<MobileMeetingCardProps> = ({
	meetingdata,
	setShowDeleteMeeting,
}) => {
	const navigate = useNavigate();
	const [_, { isLoading }] = useUpdateBookingStatusMutation();
	const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");

	return (
		<div className="flex flex-col items-center justify-between rounded-[12px] bg-white p-2 drop-shadow-[0_1px_5px_rgba(16,24,40,0.15)] md:flex-row ">
			<div className="flex  w-full gap-3.5 rounded-xl">
				{meetingdata?.room?.photos[0]?.image_url ? (
					<img
						src={meetingdata?.room?.photos[0]?.image_url}
						alt={meetingdata?.room?.photos[0]?.file_name}
						className="h-[72px] w-[150px] rounded-[12px] md:h-[123px] md:w-[179px]"
					/>
				) : (
					<div className="flex min-h-[72px] w-[150px] flex-col justify-center self-stretch rounded-[12px] bg-[#DCDCDC] px-4 ">
						<div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-[#C7C7C7] ">
							<p className="select-none text-lg font-semibold text-white">
								{meetingdata.room?.name
									.split(" ")[0][0]
									.toUpperCase()}
							</p>
						</div>
					</div>
				)}

				<div className="flex flex-col justify-between gap-y-2">
					<div className="space-y-1">
						<h2 className="text-sm font-semibold">
							{meetingdata.title}
						</h2>
					</div>
				</div>
				<div
					className={cn(
						"ml-auto flex flex-[0.1] flex-col items-end justify-center gap-1.5 py-1",
						{
							hidden: meetingdata.status === "completed",
						}
					)}
				>
					<button
						className="text-[#858C95]"
						onClick={() =>
							navigate(`/partner/meeting/${meetingdata.id}`, {
								state: { meeting: meetingdata.status },
							})
						}
					>
						<ChevronRight />
					</button>
				</div>
			</div>
			<div className="grid w-full grid-cols-3 place-content-between gap-[0px] pt-3.5">
				<span className="flex items-center gap-2 text-[#6B7280]">
					<i className="mgc_box_line text-[12px] before:!text-[#BFBFBF]" />
					<p className="text-[10px] font-semibold">
						{meetingdata?.room?.name}
					</p>
				</span>
				<span className="flex items-center gap-2 text-[#6B7280]">
					<i className="mgc_calendar_line text-[12px] before:!text-[#BFBFBF]" />

					<p className="text-[10px]">
						{meetingdata?.start_at
							? formatDateString(
									meetingdata.start_at.split(" ")[0]
								)
							: "N/A"}
					</p>
				</span>
				<span className="flex items-center gap-2 text-[#6B7280]">
					<i className="mgc_contacts_2_line text-[12px] before:!text-[#BFBFBF]" />
					<p className="text-[10px]">
						{meetingdata?.host?.name.split("")[0][0].toUpperCase() +
							meetingdata?.host?.name.split("")[1].toUpperCase()}
					</p>
				</span>
				<span className="flex items-center gap-2 text-[#6B7280]">
					<i className="mgc_location_3_line text-[12px] before:!text-[#BFBFBF]" />
					<p className="text-[10px]">{meetingdata?.room?.address}</p>
				</span>
				<span className="flex items-center gap-2 text-[#6B7280]">
					<i className="mgc_time_line text-[12px] before:!text-[#BFBFBF]" />
					<p className="text-[10px]">
						{meetingdata?.start_at
							? meetingdata.start_time.slice(0, -3)
							: "N/A"}{" "}
						-{" "}
						{meetingdata?.end_time
							? meetingdata.end_time.slice(0, -3)
							: "N/A"}
					</p>
				</span>
				<span className="flex items-center gap-2 text-[10px] text-[#6B7280]">
					<span className="flex h-[10px] w-[10px] items-center justify-center rounded-full border border-[#BFBFBF] bg-[#fff] p-1 text-[6px] text-[#BFBFBF]">
						{meetingdata?.host?.name.split("")[0][0]}
					</span>
					{meetingdata?.host?.name}
				</span>
			</div>

			<div className="mt-2.5 flex h-max w-full flex-grow flex-col justify-between gap-y-7 border-t-[0.5px] pt-2.5">
				<div
					className="flex w-full flex-1 justify-end gap-x-2.5"
					onClick={(e) => e.stopPropagation()}
				>
					{userRole !== TeamMemberRoles.PartnerAdmin && (
						<>
							{meetingdata?.status === "approved" ||
								(meetingdata?.status === "pending" && (
									<Button
										variant="outline"
										className="h-[36px] w-full"
										onClick={() =>
											setShowDeleteMeeting(true)
										}
										disabled={isLoading}
									>
										Cancel
									</Button>
								))}
							{meetingdata?.status === "approved" ||
								(meetingdata?.status === "pending" && (
									<Button
										className="h-[36px] w-full"
										variant="outlinecustom"
										onClick={() =>
											navigate(
												`/partner/meeting/${meetingdata.id}`,
												{
													state: {
														meeting:
															meetingdata.status,
													},
												}
											)
										}
									>
										Edit
									</Button>
								))}
							{meetingdata?.status === "invited" && (
								<Button
									className="h-[36px] w-full"
									variant="outline"
									onClick={() =>
										navigate(
											`/partner/meeting/${meetingdata.id}`,
											{
												state: {
													meeting:
														meetingdata?.status,
												},
											}
										)
									}
								>
									Decline
								</Button>
							)}
							{meetingdata?.status === "invited" && (
								<Button
									className="h-[36px] w-full"
									onClick={() =>
										navigate(
											`/partner/meeting/${meetingdata.id}`,
											{
												state: {
													meeting:
														meetingdata?.status,
												},
											}
										)
									}
								>
									Accept
								</Button>
							)}
						</>
					)}

					{(meetingdata?.status === "pending" ||
						meetingdata?.status === "approved" ||
						meetingdata?.status === "declined" ||
						meetingdata?.status === "cancelled" ||
						meetingdata?.status === "done") && (
						<span
							className={cn(
								"flex w-fit items-center justify-self-end rounded-lg bg-[#D2E8E5] px-5 py-1 text-[14px] font-medium capitalize text-[#216D63]",
								{
									"bg-[#F6E2E1] text-[#B7241D]":
										meetingdata?.status === "declined" ||
										meetingdata?.status === "cancelled" ||
										meetingdata?.status === "pending",
									"bg-[#F6F6F6] text-[#636363]":
										meetingdata?.status === "done",
									"bg-[#E7F3F1] text-[#138576]":
										meetingdata?.status === "accepted",
								}
							)}
						>
							{meetingdata?.status}
						</span>
					)}
				</div>
			</div>
		</div>
	);
};

export default MobileMeetingCard;
