import { useAppSelector } from "@/hooks/hooks";
import React, { useEffect, useState } from "react";

interface IRecurringData {
	is_recurring: number;
	frequency: string;
	start_date: string;
	end_date: string;
	day_date_format?: "by_date" | "by_day";
	days?: string[];
	dates?: number[];
	week_occurrences?: number[];
	yearly_month?: string;
}

const RecurrenceSummary: React.FC = () => {
	const [recurringData, setRecurringData] = useState<IRecurringData | null>(
		null
	);
	const { date } = useAppSelector((state) => state.bookings.booking);

	const updateRecurrenceData = () => {
        const savedData = localStorage.getItem("recurringData");
        if (savedData) {
            const parsedData: IRecurringData = JSON.parse(savedData);
            setRecurringData(parsedData);
        } else {
            setRecurringData(null);
        }
    };

	useEffect(() => {
        updateRecurrenceData();
        const handleCustomStorageEvent = () => {
            updateRecurrenceData();
        };
        window.addEventListener("recurringDataChange", handleCustomStorageEvent);

        return () => {
            window.removeEventListener("recurringDataChange", handleCustomStorageEvent);
        };
    }, []);

	if (!recurringData) {
		return null;
	}

	const {
		frequency,
		start_date,
		end_date,
		days,
		dates,
		week_occurrences,
		yearly_month,
	} = recurringData;

	return (
		<p className="text-sm px-4">
			{frequency.toLowerCase() === "daily" && (
				<span>
					Occurs every day starting {start_date} until {end_date}
				</span>
			)}

			{frequency.toLowerCase() === "weekly" && (
				<span>
					Occurs every week on{" "}
					{days?.map((item, idx) => <span key={idx}>{item}, </span>)}{" "}
					starting {date ?? start_date} until {end_date}
				</span>
			)}

			{frequency.toLowerCase() === "monthly" && (
				<span>
					Occurs every month on{" "}
					{dates && dates?.length > 0 ? (
						<span>{dates[0]}</span>
					) : (
						<span>
							the {week_occurrences?.[0]} {week_occurrences?.[1]}
						</span>
					)}{" "}
					starting {date ?? start_date} until {end_date}
				</span>
			)}

			{frequency.toLowerCase() === "yearly" && (
				<span>
					Occurs every year on{" "}
					{dates && dates?.length > 0 ? (
						<span>
							{yearly_month} {dates[0]}
						</span>
					) : (
						<span>
							the {week_occurrences?.[0]} {week_occurrences?.[1]}{" "}
							of {yearly_month}
						</span>
					)}{" "}
					starting {date ?? start_date} until {end_date}
				</span>
			)}
		</p>
	);
};

export default RecurrenceSummary;
