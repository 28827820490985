import RoomCard from "@/components/partner/room/RoomCard";
import RoomFilter from "../../components/partner/room/RoomFilter";
import { DatePicker } from "@/components/ui/datepicker";
import { FilterIcon } from "lucide-react";
import Search from "@/components/input/Search";
import { useEffect, useState } from "react";
import { useGetRoomsQuery } from "@/redux/features/apiSlice";
import { Button } from "@/components/ui/button";
import { useAppDispatch, useAppSelector } from "@/hooks/hooks";
import {
	resetFilters,
	setDateFilter,
	setLocationFilter,
	setNameFilter,
} from "@/redux/slices/filterSlice";
import RoomLoadingCard from "@/components/partner/room/RoomLoadingCard";
import CustomShadSelect, { Option } from "@/components/common/CustomShadSelect";
import { Room } from "@/types/admin/booking";
import { useGetFilterDataQuery } from "@/redux/features/api/partnerEndpoints";
import DefaultTopbar from "@/components/topbar/DefaultTopbar";
import { useDebouncedCallback } from "use-debounce";
import { DateTime } from "luxon";
import { ScrollArea } from "@/components/ui/scroll-area";

const Rooms = () => {
	const business = useAppSelector((state) => state.user.user);
	const [roomsdata, setRoomsData] = useState<Room[]>([]);
	const filterDataQuery = useGetFilterDataQuery({
		isAdmin: !business?.company,
		business_or_company_id: business?.company_id ?? business?.business_id,
	});
	const dispatch = useAppDispatch();
	const relevantFilters = [
		"date",
		"duration",
		"start_time",
		"capacity",
		"end_time",
		"location_id",
	];
	const filters = useAppSelector((state) => state.filters);

	// Filter the filters based on the relevant filters
	const filteredFilters = Object.entries(filters)
		.filter(([key]) => relevantFilters.includes(key))
		.reduce((obj, [key, value]) => {
			if (
				value !== null &&
				value !== "" &&
				value !== undefined &&
				value !== "all"
			) {
				obj[key] = value.toString();
			}
			return obj;
		}, {});
	const { amenities, name, date } = useAppSelector((state) => state.filters);

	const { isFetching, isSuccess, isLoading, data } =
		useGetRoomsQuery(filteredFilters);
	const [openFilter, setOpenFilter] = useState(false);
	// const [date, setDate] = useState<Date | undefined>(undefined);
	const [locationList, setLocationList] = useState<Option[]>([]);

	const handleReset = () => {
		dispatch(resetFilters());
	};

	const handleDateChange = (date: Date) => {
		const luxonDate = DateTime.fromJSDate(date).toISODate();
		dispatch(setDateFilter(luxonDate!));
	};

	useEffect(() => {
		if (!isFetching && isSuccess) {
			let filteredRooms = data.data;
			if (name) {
				filteredRooms = filteredRooms.filter((room) =>
					room.name.toLowerCase().includes(name.toLowerCase())
				);
			}
			if (amenities && amenities.length > 0) {
				filteredRooms = filteredRooms.filter((room) =>
					amenities.every((amenity) =>
						room.amenities.some(
							(roomAmenity) => roomAmenity.name === amenity
						)
					)
				);
			}

			setRoomsData(filteredRooms);
		}
	}, [isSuccess, isFetching, data, name, amenities]);

	useEffect(() => {
		if (filterDataQuery.isSuccess && filterDataQuery.data)
			setLocationList(
				() =>
					filterDataQuery.data.data.locations.map((location) => ({
						label: location.name,
						value: location.id.toString(),
					})) ?? []
			);
	}, [filterDataQuery.isSuccess, filterDataQuery.data]);

	const debouncedSetNameFilter = useDebouncedCallback((value) => {
		dispatch(setNameFilter(value));
	}, 500);

	return (
		<div className="flex h-full gap-4 overflow-hidden bg-[#FAFAFA]">
			<div className="flex w-full scroll-m-0 flex-col overflow-hidden">
				<div className="mb-4 flex w-full flex-col justify-between gap-4 px-5 py-4 md:flex-row md:items-center md:px-8">
					<DefaultTopbar title="Reserve Spaces" />

					<div className="flex w-fit flex-col gap-2 mmd:w-full">
						<CustomShadSelect
							placeholder="Location Name"
							className="w-full md:w-fit"
							options={locationList}
							onChange={(value) => {
								dispatch(setLocationFilter(value));
							}}
						/>
						<div className="flex gap-2 md:hidden mmd:w-full">
							<div className="xl:w-full mmd:w-full">
								<Search
									onChange={(e) => {
										debouncedSetNameFilter(e.target.value);
									}}
									placeholder="Search"
									className="w-full border-[#E5E5E7] md:w-full"
								/>
							</div>
							<DatePicker
								date={
									date
										? DateTime?.fromISO(date).toJSDate()
										: undefined
								}
								setDate={handleDateChange}
								title="Select Dates"
								className="w-fit font-medium !text-[#858C95] md:w-[183px]"
							/>
							<button
								className="block rounded-md border border-[#E5E5E7] bg-white p-2 shadow-sm md:hidden"
								onClick={() => setOpenFilter(!openFilter)}
							>
								<FilterIcon color="#6B7280" size="20px" />
							</button>
						</div>
					</div>
				</div>
				{!isFetching && !roomsdata?.length && (
					<div className="h-[80svh] px-8">
						<div className="flex h-full  w-full items-center justify-center rounded-lg border border-[#BFBFBF]">
							<div className="flex max-w-lg flex-col items-center  gap-y-2 text-center">
								<h1 className="text-2xl font-semibold text-basecolor ">
									Expand your search to see more
								</h1>
								<p className="text-lg font-normal text-basecolor">
									There are no spaces available for your
									search preferences
								</p>
								<Button
									variant="outlinecustom"
									className="w-fit border-basecolor px-12 py-2 text-xs font-semibold text-basecolor"
									onClick={handleReset}
								>
									Reset Filters
								</Button>
							</div>
						</div>
					</div>
				)}
				{isFetching && (
					<div className="3xl:grid-cols-5 min-[2000px]:grid-cols-5 grid w-full gap-4 overflow-y-auto px-5 py-2 md:px-8 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
						{[11, 22, 33, 44, 55].map((room) => (
							<RoomLoadingCard key={room} />
						))}
					</div>
				)}
				<ScrollArea className="flex-1">
					{!isFetching && roomsdata?.length ? (
						<div className="3xl:grid-cols-5 min-[2000px]:grid-cols-5 grid w-full gap-4 overflow-y-auto px-5 py-2 md:px-8 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
							{roomsdata.map((room, index) => (
								<RoomCard
									key={index}
									room={room}
									isLoading={isLoading}
								/>
							))}
						</div>
					) : null}
				</ScrollArea>
			</div>

			<RoomFilter openFilter={openFilter} setOpenFilter={setOpenFilter} />
		</div>
	);
};

export default Rooms;

