import React from "react";
import {
	LineChart,
	ResponsiveContainer,
	XAxis,
	YAxis,
	Line,
	Tooltip,
} from "recharts";

const StatisticsLineChart: React.FC<{
	formattedData: { title: string; value: number }[];
	dayFormat?: boolean;
}> = ({ formattedData, dayFormat }) => {
	return (
		<ResponsiveContainer width="100%" className="flex-1">
			<LineChart
				data={formattedData}
				margin={{
					top: 5,
					right: 12,
					left: 12,
					bottom: 0,
				}}
			>
				<XAxis
					dataKey="title"
					tickLine={false}
					axisLine={{
						stroke: "#E5E5E7",
						strokeWidth: 1,
					}}
					className="leading-[20] tracking-[0%]"
					interval={0}
					tick={<CustomXAxisTick dayFormat={dayFormat} />}
				/>
				<YAxis axisLine={false} hide={true} />
				<Tooltip
					content={<CustomTooltip />}
					cursor={{ stroke: "none" }}
				/>
				<Line
					type="monotone"
					dataKey="value"
					stroke="#5D57C8"
					strokeWidth={1}
					dot={false}
				/>
			</LineChart>
		</ResponsiveContainer>
	);
};

export default StatisticsLineChart;

const CustomXAxisTick: React.FC<any> = ({ x, y, payload, dayFormat }) => {
	return (
		<text
			x={x}
			y={y}
			dy={10}
			fill="#858C95"
			fontSize="11px"
			textAnchor="middle"
		>
			{dayFormat ? payload.value.slice(0, 3) : payload.value}
		</text>
	);
};

const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
	if (active && payload && payload.length) {
		return (
			<div className="rounded-lg border border-solid border-gray-200 bg-white p-2 drop-shadow-md">
				<p className="label" style={{ margin: 0 }}>{`${label}`}</p>
				<p className="intro" style={{ margin: 0 }}>
					Total: <span className="font-semibold">{payload[0].value}</span>
				</p>
			</div>
		);
	}

	return null;
};
