import Avatar from "@/components/common/Avatar";
import GuestHolder from "@/components/common/GuestHolder";
import Modal from "@/components/modals/Modal";
import EditBooking from "@/components/modals/partner/EditBooking";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { useUpdateBookingStatusMutation } from "@/redux/features/api/partnerEndpoints";
import { MeetingStatus } from "@/services/mockData";
import { formatDateString, notifyWithIcon } from "@/services/utilities";
import { LoadingOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { BsBox2 } from "react-icons/bs";
import { FaLink, FaRegClock } from "react-icons/fa6";
import { IoLocationOutline } from "react-icons/io5";
import { LuCalendarCheck } from "react-icons/lu";
import { PiBuildings } from "react-icons/pi";
import { useNavigate } from "react-router";
// import { DateTime } from "luxon";

interface MeetingDetailsCard {
	meetingdata?: any; //Finish this and update
	status: MeetingStatus;
}

const BookingHistoryDetailsCard = ({
	meetingdata,
	status,
}: MeetingDetailsCard) => {
	// console.log(meetingdata)
	const navigate = useNavigate();
	const [copied, setCopied] = useState<boolean | undefined>(false);
	const [isTooltipOpen, setTooltipOpen] = useState<boolean>(false);
	const [showAllGuests, setShowAllGuests] = useState(false);
	const [showEditBooking, setShowEditBooking] = useState(false);
	const [updateBooking, { data, isLoading, error, isError, isSuccess }] =
		useUpdateBookingStatusMutation();

	const handleCancel = () => {
		const data = {
			booking_id: meetingdata?.id,
			data: {
				host_id: meetingdata?.host_user_id,
				status: "cancelled",
			},
		};
		updateBooking(data);
	};

	const handleCopyUrlLink = async () => {
		setCopied(true);
		await navigator.clipboard
			.writeText(
				window.location.origin +
					"/meeting-invite/" +
					meetingdata.link_code
			)
			.then(() => {
				setCopied(true);
			})
			.catch(() => setCopied(undefined));

		setTimeout(() => {
			setCopied(false);
		}, 1500);
	};

	useEffect(() => {
		if (isSuccess && data) {
			notifyWithIcon("", "Reservation Cancelled Successfully");
			setTimeout(() => {
				navigate(-1);
			}, 2000);
		} else if (isError && error) {
			notifyWithIcon("", "Error Cancelling Booking");
		}
	}, [isSuccess, isLoading, isError]);

	return (
		<>
			<section className="relative space-y-2 rounded-lg bg-white p-6 drop-shadow-[0_0px_10px_rgba(0,0,0,0.15)]">
				<h1 className="max-w-full divide-y-0 text-[20px] font-semibold text-[#323539] md:max-w-[70%]">
					{meetingdata?.title ?? "No Title Available"}
				</h1>
				{(meetingdata?.status === "accepted" ||
					meetingdata?.status === "declined" ||
					meetingdata?.status === "pending") && (
					<span
						className={cn(
							" right-4 top-4 flex w-fit items-center rounded-lg bg-[#F6E2E1] px-5 py-1 text-[14px] font-medium  capitalize md:absolute",
							{
								"bg-[#E7F3F1] text-[#138576]":
									meetingdata?.status === "accepted",
								"bg-[#F6E2E1] text-[#B7241D]":
									meetingdata?.status === "declined" ||
									meetingdata?.status === "pending",
							}
						)}
					>
						{meetingdata?.status}
					</span>
				)}

				<p className="py-2">
					{meetingdata?.description ?? "No description available"}
				</p>

				<section className="hidden flex-col space-y-3 md:flex">
					<p className="flex items-center gap-2">
						<span className="text-primary">
							<BsBox2 />
						</span>
						{meetingdata?.room?.name ?? "N/A"}
					</p>
					<p className="flex items-center gap-2 border-t border-t-primary pt-3">
						<span className="text-primary">
							<IoLocationOutline />
						</span>
						{meetingdata?.room?.address ?? "N/A"}
					</p>
					<p className="flex items-center gap-2 border-t border-t-primary pt-3">
						<span className="text-primary">
							<LuCalendarCheck />
						</span>
						{meetingdata?.start_at
							? formatDateString(
									meetingdata.start_at.split(" ")[0]
								)
							: "N/A"}
					</p>
					<p className="flex items-center gap-2 border-t border-t-primary pt-3">
						<span className="text-primary">
							<FaRegClock />
						</span>
						{meetingdata?.start_at
							? meetingdata.start_time.slice(0, -3)
							: "N/A"}{" "}
						-{" "}
						{meetingdata?.end_time
							? meetingdata.end_time.slice(0, -3)
							: "N/A"}
					</p>
				</section>
				{/* Mobile view of above section */}
				<section className="flex flex-col gap-y-1 text-[14px] md:hidden">
					<p className="flex items-center gap-2">
						<span className="text-primary">
							<LuCalendarCheck />
						</span>
						{meetingdata?.start_at
							? formatDateString(
									meetingdata.start_at.split(" ")[0]
								)
							: "N/A"}
					</p>
					<p className="flex items-center gap-2">
						<span className="text-primary">
							<FaRegClock />
						</span>
						{meetingdata?.start_at
							? meetingdata.start_time.slice(0, -3)
							: "N/A"}{" "}
						-{" "}
						{meetingdata?.end_time
							? meetingdata.end_time.slice(0, -3)
							: "N/A"}
					</p>
					<p className="flex items-center gap-2">
						<span className="text-primary">
							<PiBuildings />
						</span>
						{meetingdata?.room?.name ?? "N/A"}
					</p>
					<p className="flex items-center gap-2">
						<Avatar
							name={meetingdata?.host?.name ?? "N/A"}
							variant="host"
						/>
					</p>
					<p className="flex items-center gap-2">
						<span className="text-primary">
							<PiBuildings />
						</span>
						{meetingdata?.company?.name}
					</p>
				</section>

				<section className="flex justify-between pt-4 md:border-t md:border-t-primary">
					<div className="hidden w-full space-y-2 md:block">
						<h1>Organizer</h1>
						<Avatar
							variant="host"
							name={meetingdata?.host?.name ?? "N/A"}
						/>
					</div>
					<div className="w-[200px] space-y-2 lg:min-w-[200px]">
						<h1 className="text-[12px] font-semibold text-[#323539] md:text-base">
							Guests
						</h1>
						{!showAllGuests && (
							<>
								{meetingdata?.meeting_members?.length === 0 && (
									<p>No guests</p>
								)}
								{meetingdata?.meeting_members
									?.slice(0, 3)
									?.map((guest: any, index: number) => (
										<div className="" key={index}>
											<GuestHolder
												data={guest}
												status={status}
												name={
													guest?.member_profile
														?.name ??
													guest?.member_profile.email
												}
												invitationStatus={guest?.status}
											/>
										</div>
									))}

								{meetingdata?.meeting_members?.length > 3 && (
									<button
										onClick={() => setShowAllGuests(true)}
										className="text-[12px] font-medium text-primary"
									>
										{" "}
										+ 2 other guests
									</button>
								)}
								{meetingdata?.meeting_members &&
									meetingdata?.meeting_members.length === 0 &&
									"No guest"}
								{/* <button
								onClick={() => setShowAllGuests(true)}
								className="text-[12px] font-medium text-primary"
							>
								{" "}
								+ 2 other guests
							</button> */}
							</>
						)}
						{showAllGuests && (
							<ScrollArea className="h-[120px]">
								{meetingdata?.meeting_members?.map(
									(guest: any, index: number) => (
										<div className="py-2" key={index}>
											<GuestHolder
												data={guest}
												status={status}
												name={
													guest?.member_profile
														?.name ??
													guest?.member_profile.email
												}
												invitationStatus={guest?.status}
											/>
										</div>
									)
								)}
							</ScrollArea>
						)}
					</div>
				</section>
				{/* <section
					className={cn(
						"flex items-center justify-between gap-2 border-y-[0.5px] border-y-primary  py-2 md:py-3",
						{
							hidden: meetingdata.status === "declined",
						}
					)}
				>
					<h1 className="font-semibold text-[#323539]">
						Add to your calendar
					</h1>
					<div className="flex items-center gap-1 self-start md:gap-2">
						<button className=" margin-auto rounded-full bg-white p-1 drop-shadow-[2px_2px_2px_rgba(0,0,0,0.25)]">
							
							<img src={GoogleCalendarIcon} alt="" />
						</button>
						<button className=" margin-auto rounded-full bg-white p-2 drop-shadow-[2px_2px_2px_rgba(0,0,0,0.25)]">
							<img src={MsCalendarIcon} alt="" />
						</button>
						<button className=" margin-auto rounded-full bg-white p-2 drop-shadow-[2px_2px_2px_rgba(0,0,0,0.25)]">
							<img src={YahooCalendarIcon} alt="" />
						</button>
					</div>
				</section> */}
				<section
					className={cn("", {
						hidden: meetingdata?.status === "declined",
					})}
				>
					{meetingdata?.status === "approved" && (
						<TooltipProvider>
							<Tooltip delayDuration={0} open={isTooltipOpen}>
								<TooltipTrigger asChild>
									<p
										className="my-4 flex cursor-pointer items-center gap-2 font-bold text-primary"
										onClick={() => handleCopyUrlLink()}
										onMouseEnter={() =>
											setTooltipOpen(true)
										}
										onMouseLeave={() =>
											setTooltipOpen(false)
										}
									>
										<FaLink /> Copy Sharable Link
									</p>
								</TooltipTrigger>
								<TooltipContent side="top" sideOffset={0}>
									{copied === true
										? "Copied"
										: copied === false
											? "Copy"
											: "Falied to copy"}
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					)}

					<div className="flex  gap-2 pt-4">
						{meetingdata?.status === "accepted" ||
							(meetingdata?.status === "pending" && (
								<Button
									variant="outline"
									className="w-full"
									onClick={handleCancel}
								>
									{isLoading ? (
										<LoadingOutlined
											style={{
												fontSize: 24,
											}}
											spin
										/>
									) : (
										"Cancel"
									)}
								</Button>
							))}
						{(meetingdata?.status === "pending" ||
							meetingdata?.status === "approved") && (
							<Button
								className="w-full text-white"
								onClick={() => setShowEditBooking(true)}
							>
								Edit
							</Button>
						)}

						{(meetingdata?.status === "accepted" ||
							meetingdata?.status === "invited") && (
							<Button variant="outline" className="w-full">
								Decline
							</Button>
						)}
						{(meetingdata?.status === "declined" ||
							meetingdata?.status === "invited") && (
							<Button className="w-full text-white">
								Accept
							</Button>
						)}
					</div>
				</section>
			</section>
			<Modal
				dialogClassName=" p-0 max-w-2xl "
				onInteractOutside={(e) => {
					e.preventDefault();
				}}
				open={showEditBooking}
				setOpen={setShowEditBooking}
			>
				<div className="w-full">
					<EditBooking room={meetingdata} bookingdata={meetingdata} />
				</div>
			</Modal>
		</>
	);
};

export default BookingHistoryDetailsCard;
