import React, { useRef } from "react";
import useCustomToast, { CustomToastProps } from "../CustomToast";

interface ImageUploaderProps {
	photos: FileList | undefined;
	setPhotos: React.Dispatch<React.SetStateAction<FileList | undefined>>;
	allowedUploadFormats: string[];
	handleFileChange: (
		event: React.ChangeEvent<HTMLInputElement>,
		allowedFormats: string[],
		customToast: (
			message: string,
			{ id, type, duration, undoText, undoHandler }: CustomToastProps
		) => void
	) => FileList | void;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
	photos,
	setPhotos,
	allowedUploadFormats,
	handleFileChange,
}) => {
	const customToast = useCustomToast();
	const fileInputRef = useRef<HTMLInputElement | null>(null);

	// Remove image function
	const removeImage = (image: File) => {
		setPhotos((prevPhotos) => {
			const dt = new DataTransfer();
			if (prevPhotos)
				Array.from(prevPhotos)
					.filter((file) => file !== image)
					.forEach((file) => dt.items.add(file));
			return dt.files;
		});
	};

	// Handle file change
	const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = handleFileChange(
			event,
			allowedUploadFormats,
			customToast
		);

		if (files) {
			setPhotos(() => {
				const dt = new DataTransfer();
				// Array.from(prevPhotos).forEach((file) => dt.items.add(file));
				Array.from(files).forEach((file) => dt.items.add(file));
				return dt.files;
			});
		}
	};

	return (
		<>
			{photos &&
				Array.from(photos).map((file, index) => (
					<div
						key={file.lastModified}
						className="group relative flex h-[108px] max-h-[108px] w-[108px] max-w-[108px] cursor-pointer items-center justify-center overflow-hidden rounded-[20px] border-[5px] border-solid border-white bg-[#E5E5E7] shadow-[0px_0px_5px_0px_rgba(0,0,0,0.25)]"
						onClick={() => removeImage(file)}
					>
						<img
							src={URL.createObjectURL(file)}
							alt={`Selected ${index}`}
							className="h-full w-full rounded-[20px] object-scale-down"
						/>
						<span className="absolute left-1/2 top-1/2 z-10 flex h-full w-full -translate-x-1/2 -translate-y-1/2 items-center justify-center bg-white bg-opacity-0 opacity-0 transition-opacity duration-200 ease-in-out group-hover:bg-opacity-70 group-hover:opacity-100">
							<i className="mgc_delete_2_line before:!text-[#C8322B]" />
						</span>
					</div>
				))}

			<div
				className="group flex h-[108px] w-[108px] cursor-pointer items-center justify-center overflow-hidden rounded-[20px] border-[5px] border-solid border-white bg-[#E5E5E7] shadow-[0px_0px_5px_0px_rgba(0,0,0,0.25)]"
				onClick={() => fileInputRef.current?.click()}
			>
				<div className="flex h-full w-full flex-col items-center justify-center duration-300 group-hover:bg-white/70">
					<i className="mgc_upload_3_line before:!text-[#8C8F93]" />
					<p className="text-[12px] font-medium leading-[20px] text-[#323539]">
						Upload Image
					</p>
				</div>
				<input
					type="file"
					ref={fileInputRef}
					className="group"
					onChange={onFileChange}
					accept={allowedUploadFormats.join(",")}
					multiple
					hidden
				/>
			</div>
		</>
	);
};

export default ImageUploader;
