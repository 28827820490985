import useCustomToast from "@/components/CustomToast";
import FormInput from "@/components/input/FormInput";
import { LoaderButton } from "@/components/Loader/LoaderButton";
import DefaultTopbar from "@/components/topbar/DefaultTopbar";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/hooks/auth";
import { useAppSelector } from "@/hooks/hooks";
import { cn } from "@/lib/utils";
import {
	useChangePasswordMutation,
	useGetInformationMutation,
	useUpdateAdminProfileMutation,
} from "@/redux/features/api/adminEndpoints";
import {
	ChangePasswordParams,
	ChangePasswordSchema,
	PartnerUpdateProfileParams,
	PartnerUpdateProfileSchema,
} from "@/types/team-member";
import { zodResolver } from "@hookform/resolvers/zod";
import lodash from "lodash";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { IoIosLock } from "react-icons/io";

const Account: React.FC = () => {
	// password not working yet
	const [isEditing, setIsEditing] = useState<boolean>(false);
	// const [countryCode, setCountryCode] = useState("+1");
	const user = useAppSelector((s) => s.user.user);
	const { setAuthUser } = useAuth();
	const customToast = useCustomToast();

	const [initialFormState] = useState({
		name: user?.name || "",
		email: user?.email || "",
	} as PartnerUpdateProfileParams);

	const {
		register,
		handleSubmit,
		formState: { isSubmitting },
		reset,
		getValues,
	} = useForm<PartnerUpdateProfileParams>({
		resolver: zodResolver(PartnerUpdateProfileSchema),
		defaultValues: initialFormState,
	});

	const [updateAdminProfile] = useUpdateAdminProfileMutation();
	const [getBussinessInformation] = useGetInformationMutation();

	const onSubmit: SubmitHandler<PartnerUpdateProfileParams> = async (
		data
	) => {
		try {
			if (
				lodash.isEqual(data, { name: user?.name, email: user?.email })
			) {
				setIsEditing(false);
				return;
			}
			await updateAdminProfile({ name: data.name });

			await getBussinessInformation({})
				.unwrap()
				.then((res) => {
					setAuthUser(res);
				});
			setIsEditing(false);
			handleResetForm(data);

			customToast("Profile updated successfully", {
				id: "update-profile",
				type: "success",
			});
		} catch (error) {
			customToast("An error occured kindly try again later", {
				id: "update-profile",
				type: "error",
			});
			// setError("root", {
			// 	message:
			// 		"An error occured kindly try again later",
			// });
		}
	};

	const handleResetForm = (newFormState: PartnerUpdateProfileParams) => {
		reset(newFormState);
	};

	return (
		<div className="flex w-full flex-col p-8 text-left ">
			{/* Header  */}
			<DefaultTopbar title="Account Settings" />
			{/* Tab list  */}
			<div className="mt-6 md:max-w-[328px]">
				<form
					className="flex flex-col space-y-5 !text-[15px]"
					onSubmit={handleSubmit(onSubmit)}
				>
					<FormInput
						label="Full Name"
						inputType="text"
						readOnly={!isEditing}
						className={cn(
							"outline-none transition-all duration-200  ease-in-out",
							{
								"shadow-[0px_0px_5px_0px_rgba(0,0,0,0.1)]s":
									isEditing,
								"cursor-not-allowed opacity-60": !isEditing,
							}
						)}
						{...register("name")}
					/>
					{/* <div className="flex-1">
						<label
							htmlFor="phone-number"
							className="mb-1.5 block text-sm font-medium leading-[20px] tracking-[-0.1px] text-[#323539]"
						>
							Phone Number
						</label>
						<div>
							<div className="flex">
								<CustomShadSelect
									className={cn(
										"h-[46px] w-fit rounded-r-none border-r-transparent",
										{
											"pointer-events-none cursor-not-allowed opacity-60":
												!isEditing,
										}
									)}
									placeholder="+1"
									label="Country Codes"
									options={countryCodes}
									value={countryCode}
									onChange={(value) => {
										setCountryCode(value);
									}}
									labelClassName="px-2"
									itemClassName="px-8"
								/>
								<FormInput
									inputType="text"
									className={cn(
										"h-[46px] rounded-l-none py-0 outline-none transition-all duration-200  ease-in-out",
										{
											"shadow-[0px_0px_5px_0px_rgba(0,0,0,0.1)]s":
												isEditing,
											"cursor-not-allowed opacity-60":
												!isEditing,
										}
									)}
									maxLength={10}
									value={watch("phone_number")}
									error={
										errors?.phone_number?.message
											? ""
											: undefined
									}
									onChange={(e) => {
										setValue(
											"phone_number",
											e.target.value
										);
									}}
									readOnly={!isEditing}
								/>
							</div>
							{errors.phone_number && (
								<small className="mt-1 text-xs text-red-500">
									{errors.phone_number.message}
								</small>
							)}
						</div>
					</div> */}
					<div className="relative">
						<FormInput
							label="Email Address"
							inputType="text"
							placeholder={"Email Address"}
							className={cn(
								"cursor-not-allowed opacity-60 outline-none transition-all duration-200 ease-in-out",
								{
									"shadow-[0px_0px_5px_0px_rgba(0,0,0,0.1)]s":
										isEditing,
								}
							)}
							readOnly={true}
							value={getValues("email")}
						/>
						<span className="absolute right-4 top-[55%] text-lg font-semibold text-[#9CA3AF]">
							<IoIosLock />
						</span>
					</div>

					<section className="mt-1 flex items-center space-x-6 self-end">
						{isEditing && (
							<Button
								className={cn("self-end px-5 py-2", {
									"cursor-not-allowed": isSubmitting,
								})}
								variant="outline"
								onClick={() => {
									setIsEditing(false);
									handleResetForm(initialFormState);
								}}
								disabled={isSubmitting}
							>
								Cancel
							</Button>
						)}
						{isEditing ? (
							<LoaderButton
								className={cn("self-end px-5 py-2", {
									"cursor-not-allowed": isSubmitting,
								})}
								loading={isSubmitting}
								disabled={isSubmitting}
								loaderSize={16}
								type="submit"
							>
								Save
							</LoaderButton>
						) : (
							<Button
								className={cn("self-end px-5 py-2", {
									"cursor-not-allowed": isSubmitting,
								})}
								onClick={(e) => {
									if (!isEditing) setIsEditing(true);
									e.preventDefault();
								}}
								disabled={isSubmitting}
								type="button"
							>
								Edit
							</Button>
						)}
					</section>
				</form>
				<ChangePassword />
			</div>
		</div>
	);
};

export default Account;

const ChangePassword: React.FC = () => {
	const customToast = useCustomToast();

	const [changePasswordMutation] = useChangePasswordMutation();
	const [isEditing, setIsEditing] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
		reset,
	} = useForm<ChangePasswordParams>({
		resolver: zodResolver(ChangePasswordSchema),
	});

	const onSubmit: SubmitHandler<ChangePasswordParams> = async (data) => {
		try {
			await changePasswordMutation(data);
			handleResetForm();
			setIsEditing(false);

			customToast("Password was changed successfully! 💪🏽", {
				id: "update-password",
				type: "success",
			});
		} catch (error) {
			customToast("An error occured kindly try again later", {
				id: "update-password",
				type: "error",
			});
			// setError("root", {
			// 	message:
			// 		"An error occured kindly try again later",
			// });
		}
	};

	const handleResetForm = () => {
		reset();
	};

	return (
		<form
			className="flex flex-col space-y-5 !text-[15px]"
			onSubmit={handleSubmit(onSubmit)}
		>
			<FormInput
				label="New Password"
				labelClassName="mb-2"
				inputType="text"
				type="password"
				placeholder="New Password"
				className={cn(
					"outline-none transition-all duration-200  ease-in-out",
					{
						"shadow-[0px_0px_5px_0px_rgba(0,0,0,0.1)]s": isEditing,
						"cursor-not-allowed opacity-60": !isEditing,
					}
				)}
				readOnly={!isEditing}
				error={errors.password}
				{...register("password")}
			/>
			<FormInput
				label="Confirm New Password"
				labelClassName="mb-2"
				inputType="text"
				type="password"
				placeholder="Confirm New Password"
				className={cn(
					"outline-none transition-all duration-200  ease-in-out",
					{
						"shadow-[0px_0px_5px_0px_rgba(0,0,0,0.1)]s": isEditing,
						"cursor-not-allowed opacity-60": !isEditing,
					}
				)}
				readOnly={!isEditing}
				error={errors.password_confirmation}
				{...register("password_confirmation")}
			/>

			<section className="mt-1 flex items-center space-x-6 self-end">
				{isEditing && (
					<Button
						className={cn("self-end px-5 py-2", {
							"cursor-not-allowed": isSubmitting,
						})}
						variant="outline"
						onClick={() => {
							handleResetForm();
							setIsEditing(false);
						}}
						disabled={isSubmitting}
					>
						Cancel
					</Button>
				)}
				{isEditing ? (
					<LoaderButton
						className={cn("self-end px-5 py-2", {
							"cursor-not-allowed": isSubmitting,
						})}
						loading={isSubmitting}
						disabled={isSubmitting}
						loaderSize={16}
						type="submit"
					>
						Save
					</LoaderButton>
				) : (
					<Button
						className={cn("self-end px-5 py-2", {
							"cursor-not-allowed": isSubmitting,
						})}
						onClick={(e) => {
							if (!isEditing) setIsEditing(true);
							e.preventDefault();
						}}
						disabled={isSubmitting}
						type="button"
					>
						Edit
					</Button>
				)}
			</section>
		</form>
	);
};
