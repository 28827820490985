import userReducer from "./userSlice";
import locationReducer from "./locationSlice";
import bookingsReducer from "./bookingSlice";
import filterReducer from "./filterSlice";
import { apiSlice } from "../features/apiSlice";
import eventReducer from "./eventSlice";
import { combineReducers } from "@reduxjs/toolkit";
import meetingFilterSlice from "./meetingFilterSlice";
import analyticsSlice from "./analyticsSlice";
import sidebarSlice from "./sideBarSlice";
import displayFilterSlice from "./displayFilterSlice";

const reducers = combineReducers({
	user: userReducer,
	location: locationReducer,
	bookings: bookingsReducer,
	filters: filterReducer,
	events: eventReducer,
	meetingFilter: meetingFilterSlice,
	analytics: analyticsSlice,
	sidebar: sidebarSlice,
	displayFilter: displayFilterSlice,
	[apiSlice.reducerPath]: apiSlice.reducer,
});

export default reducers;
