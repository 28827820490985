import Loader from "@/components/Loader/Loader";
import Summary from "@/components/admin/analytics/Summary";
import Statistics from "@/components/admin/analytics/statistics/Statistics";
import Trends from "@/components/admin/analytics/trends/Trends";
import CustomShadSelect, { Option } from "@/components/common/CustomShadSelect";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { useAppDispatch, useAppSelector } from "@/hooks/hooks";
import { cn } from "@/lib/utils";
import { useGetAllAdminPartnersQuery } from "@/redux/features/api/adminEndpoints";
import { useGetLocationAnalyticsQuery } from "@/redux/features/api/locationEndpoints";
import {
	useGetAllAdminLocationRoomsQuery,
	useGetAllAdminLocationsQuery,
} from "@/redux/features/apiSlice";
import { resetQueryData, setQueryData } from "@/redux/slices/analyticsSlice";
import { AnalyticsQueryParams } from "@/types/location";
import dayjs from "dayjs";
import { CalendarIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import { DateRange } from "react-day-picker";
import { LuRotateCcw } from "react-icons/lu";

const Analytics: React.FC = () => {
	const dispatch = useAppDispatch();
	const businessId =
		useAppSelector((state) => state.user.user?.business_id) ?? 0;
	const getAdminLocationsQuery = useGetAllAdminLocationsQuery();
	const getAllAdminParternsQuery = useGetAllAdminPartnersQuery({
		businessId,
	});

	const queryData = useAppSelector((state) => state.analytics.query_data);
	const user = useAppSelector((s) => s.user.user);

	const [selectedLocation, setSelectedLocation] = useState("all");
	const [selectedRoom, setSelectedRoom] = useState("all");
	const [selectedPartner, setSelectedPartner] = useState("all");

	const getLocationAnalyticsQuery = useGetLocationAnalyticsQuery(
		{
			user_id: businessId,
			query_data: queryData,
		},
		{
			refetchOnFocus: true,
			refetchOnReconnect: true,
		}
	);

	const locationsList: Option[] = [
		{ label: "All Locations", value: "all" },
		...(getAdminLocationsQuery?.data?.data?.map((location) => ({
			label: location.name,
			value: location.id.toString(),
		})) || []),
	];

	const partnersList: Option[] = [
		{ label: "All Partners", value: "all" },
		...(getAllAdminParternsQuery?.data?.data?.map((partner) => ({
			label: partner.name,
			value: partner.id.toString(),
		})) || []),
	];

	const [roomList, setRoomList] = useState<Option[]>([]);

	const { data: roomsData, isFetching: isRoomsFetching } =
		useGetAllAdminLocationRoomsQuery(
			{
				id: +queryData["location_ids[]"],
			},
			{
				skip: !queryData["location_ids[]"]?.length,
			}
		);

	const createdAtString = useAppSelector(
		(state) => state.user.user?.created_at
	);
	const createdAtDate = createdAtString
		? new Date(createdAtString)
		: new Date();

	const [date, setDate] = useState<DateRange | undefined>({
		from: createdAtDate,
		to: new Date(),
	});

	useEffect(() => {
		if (roomsData) {
			const rooms: Option[] = [
				{ label: "All Rooms", value: "all" },
				...(roomsData.data.map((room) => ({
					label: room.name,
					value: room.id,
				})) || []),
			];
			setRoomList(rooms);
		}
	}, [roomsData]);

	useEffect(() => {
		dispatch(resetQueryData());
	}, []);

	useEffect(() => {
		getLocationAnalyticsQuery.refetch();
	}, [queryData]);

	return (
		<div className="relative flex flex-1 flex-col">
			<div className="sticky top-[-16px] z-10 flex flex-col space-y-6 bg-white pb-5">
				<div className={cn("flex items-start justify-between pr-4")}>
					<div>
						<p className="text-xs font-normal">
							{user?.business?.name}
						</p>
						<h1 className="text-[20px] font-semibold text-basecolor">
							Analytics
						</h1>
					</div>
				</div>
				<div className="flex items-center justify-between space-x-4 bg-white">
					{!getLocationAnalyticsQuery.isLoading &&
						locationsList &&
						locationsList.length && (
							<>
								<CustomShadSelect
									className="h-9"
									placeholder="Location"
									label="Location"
									options={locationsList}
									value={selectedLocation}
									onChange={(value) => {
										dispatch(
											setQueryData({
												["location_ids[]"]:
													value === "all"
														? undefined
														: value,
												["room_ids[]"]: undefined,
											} as AnalyticsQueryParams)
										);
										setSelectedLocation(value);
										setSelectedRoom("all");
									}}
									labelClassName="px-2"
									itemClassName="px-8"
								/>
								{queryData["location_ids[]"] &&
									queryData["location_ids[]"].length !==
										0 && (
										<div
											className={`${isRoomsFetching ? "pointer-events-none opacity-40" : ""} w-full transition-opacity duration-200`}
										>
											<CustomShadSelect
												className="h-9"
												placeholder="Rooms"
												label="Rooms"
												options={
													roomList.length === 0
														? [
																{
																	label: "No rooms",
																	value: "0",
																	disabled:
																		true,
																},
															]
														: roomList
												}
												value={selectedRoom}
												onChange={(value) => {
													dispatch(
														setQueryData({
															["room_ids[]"]:
																value === "all"
																	? undefined
																	: value,
														} as AnalyticsQueryParams)
													);
													setSelectedRoom(value);
												}}
												labelClassName="px-2"
												itemClassName="px-8"
											/>
										</div>
									)}

								<CustomShadSelect
									className="h-9"
									placeholder="Partners"
									label="Partners"
									options={partnersList}
									value={selectedPartner}
									onChange={(value) => {
										dispatch(
											setQueryData({
												["partner_company_ids[]"]:
													value === "all"
														? undefined
														: value,
											} as AnalyticsQueryParams)
										);
										setSelectedPartner(value);
									}}
									labelClassName="px-2"
									itemClassName="px-8"
								/>
								<AnalyticsDatePicker
									date={date}
									setDate={setDate}
								/>
								<Button
									variant="outline"
									size="icon-sm"
									className="h-9 min-w-[36px]"
									onClick={() =>
										getLocationAnalyticsQuery.refetch()
									}
								>
									<LuRotateCcw
										size={18}
										className={`${getLocationAnalyticsQuery.isFetching && "spinning"}`}
									/>
								</Button>
								{/* <Button className="h-9 max-w-[115px] space-x-1.5 rounded-md">
									<i className="mgc_download_2_line before-text-white text-[18px]" />
									<p className="text-white">Export Pdf</p>
								</Button> */}
							</>
						)}
				</div>
			</div>
			{getAdminLocationsQuery.isSuccess &&
				getAllAdminParternsQuery.isSuccess &&
				getAdminLocationsQuery.isSuccess && (
					<div
						className={cn(
							"relative mt-6 flex w-full flex-1 flex-col space-y-6"
						)}
					>
						<>
							<Summary />
							<Statistics />
							<Trends />
						</>
					</div>
				)}
			{(getLocationAnalyticsQuery.isLoading ||
				getAllAdminParternsQuery.isLoading ||
				getAdminLocationsQuery.isLoading) && (
				<div className="my-10 grid flex-1 place-content-center">
					<Loader size={24} />
				</div>
			)}
		</div>
	);
};

export default Analytics;

const AnalyticsDatePicker: React.FC<{
	date: any;
	setDate: any;
}> = ({ date, setDate }) => {
	const dispatch = useAppDispatch();
	const userCreatedAt = useAppSelector((s) => s.user.user?.created_at);

	useEffect(() => {
		if (date) {
			const from = new Date(date.from ?? "").toLocaleDateString("en-CA");
			const to = new Date(date.to ?? "").toLocaleDateString("en-CA");
			dispatch(
				setQueryData({
					start_date: from,
					end_date: to,
				} as AnalyticsQueryParams)
			);
		}
	}, [date]);

	return (
		<div className={cn("grid max-w-[320px] gap-2")}>
			<Popover>
				<PopoverTrigger asChild>
					<Button
						id="date"
						variant={"outline"}
						className={cn(
							"h-9 w-[300px] justify-start text-left font-normal",
							!date && "text-muted-foreground"
						)}
					>
						<CalendarIcon className="mr-2 h-4 w-4" />
						{date?.from ? (
							date.to ? (
								<>
									{dayjs(date.from).format("MMM DD, YYYY")} -
									{dayjs(date.to).format("MMM DD, YYYY")}
								</>
							) : (
								dayjs(date.from).format("MMM DD, YYYY")
							)
						) : (
							<span>Pick a date</span>
						)}
					</Button>
				</PopoverTrigger>
				<PopoverContent className="w-auto p-0" align="start">
					<Calendar
						initialFocus
						mode="range"
						defaultMonth={date?.from}
						selected={date}
						onSelect={setDate}
						numberOfMonths={2}
						fromDate={new Date(userCreatedAt ?? "")}
						toDate={new Date()}
					/>
				</PopoverContent>
			</Popover>
		</div>
	);
};
