// import CustomSelect from "@/components/common/CustomSelect"
// import Search from "@/components/input/Search"
import DeletePartners from "@/components/modals/admin/DeletePartners";
import Modal from "@/components/modals/Modal";
import { ScrollArea } from "@/components/ui/scroll-area";
import Wrapper from "@/container/Wrapper";
import { useGetAllAdminLocationsQuery } from "@/redux/features/apiSlice";
import { BookingLocation } from "@/types/location";
import { useState } from "react";
import LocationListCard from "./LocationListCard";

export type LocationItem = {
	id: number;
	name: string;
	address: string;
};
const LocationList: React.FC<{ locationData: BookingLocation[] }> = ({
	locationData,
}) => {
	const [showDelete, setShowDelete] = useState(false);
	const { isSuccess, isError, isLoading } = useGetAllAdminLocationsQuery();

	const [selectedItems, setSelectedItems] = useState<any[]>([]);
	const [, setSelectAll] = useState<boolean>(false);

	// const toggleSelectAll = () => {
	// 	if (selectAll) {
	// 		setSelectedItems([]);
	// 	} else {
	// 		setSelectedItems(locationData);
	// 	}
	// 	setSelectAll(!selectAll);
	// };

	const toggleSelectItem = (item: any) => {
		if (selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
			setSelectedItems(
				selectedItems.filter(
					(selectedItem) => selectedItem.id !== item.id
				)
			);
		} else {
			setSelectedItems([...selectedItems, item]);
		}
		setSelectAll(false); // Unselect select all if any individual item is selected
	};

	return (
		<>
			<Wrapper className="" fetching={isLoading} working={false}>
				{isSuccess && locationData.length ? (
					<ScrollArea className="h-[70vh]">
						<div className="flex flex-col gap-y-4 py-4">
							{/* <div className="flex justify-between px-6">
								<div className="flex items-center gap-2 text-[14px]">
									<input
										id="select-all"
										type="checkbox"
										className="accent-primary"
										onClick={toggleSelectAll}
									/>
									<label
										className={cn("", {
											"text-[#7D7E80]": !selectAll,
										})}
									>
										Select All
									</label>
								</div>
								<TooltipProvider>
									<div className="flex hidden items-center gap-1">
										<Tooltip delayDuration={0}>
											<TooltipTrigger asChild>
												<Button
													variant="outlinecustom"
													disabled={!selectAll}
													className="h-7 px-1.5 py-1.5"
												>
													<MdMailOutline />
												</Button>
											</TooltipTrigger>
											<TooltipContent
												side="top"
												sideOffset={10}
											>
												Send Mail
											</TooltipContent>
										</Tooltip>

										<Tooltip delayDuration={0}>
											<TooltipTrigger asChild>
												<Button
													variant="outlinecustom"
													disabled={!selectAll}
													className="h-7 px-1.5 py-1.5"
													onClick={() =>
														setShowDelete(true)
													}
												>
													<TbTrash />
												</Button>
											</TooltipTrigger>
											<TooltipContent
												side="top"
												sideOffset={10}
											>
												Delete
											</TooltipContent>
										</Tooltip>
									</div>
								</TooltipProvider>
							</div> */}
							<div className="flex flex-col gap-2 px-1">
								{locationData.map((item, index) => (
									<LocationListCard
										location={item}
										key={index}
										onSelect={() => toggleSelectItem(item)}
										checked={selectedItems.some(
											(selectedItem) =>
												selectedItem.id === item.id
										)}
									/>
								))}
							</div>
						</div>
					</ScrollArea>
				) : (
					""
				)}

				{isError && <p>Error Fetching Details</p>}
			</Wrapper>

			<Modal
				dialogClassName="!rounded-[12px] max-w-[360px] bg-white w-full"
				open={showDelete}
				setOpen={setShowDelete}
			>
				<DeletePartners
					onDeleteSuccess={() => {}}
					isLoading={false}
				/>
			</Modal>
		</>
	);
};

export default LocationList;
