import { configureStore } from "@reduxjs/toolkit";
import {
	PERSIST,
	REGISTER,
	PAUSE,
	PURGE,
	FLUSH,
	REHYDRATE,
	persistReducer,
	persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./slices";
import { apiSlice } from "./features/apiSlice";

const persistConfig = {
	key: "root",
	storage,
	blacklist: [apiSlice.reducerPath, "user", "bookings", "filters", "sidebar"],
	// whiteList: ["user"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [
					FLUSH,
					REHYDRATE,
					PAUSE,
					PERSIST,
					PURGE,
					REGISTER,
				],
			},
			// serializableCheck: { ignoredActions: [PERSIST, PURGE, REGISTER] },
		}).concat(apiSlice.middleware),
	devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
