import hamburgerMenu from "@/assets/icons/hamburger.svg";
import Footer from "@/components/Footer";
import PartnerSidebar from "@/components/sidebar/PartnerSidebar";
import { useAppDispatch, useAppSelector } from "@/hooks/hooks";
import useScreenDimension from "@/hooks/useScreenDimension";
import { cn } from "@/lib/utils";
import { setIsOpen } from "@/redux/slices/sideBarSlice";
import { Navigate, Outlet } from "react-router";

const PartnerLayout = () => {
	const dispatch = useAppDispatch();

	const { user } = useAppSelector((state) => state.user);
	const { isOpen } = useAppSelector((state) => state.sidebar);
	const { width } = useScreenDimension();

	if (!user || user.role == "BUSINESS_ADMIN") {
		return <Navigate to="/admin/bookings" replace />;
	}

	return (
		<main className="relative mx-auto flex h-[100svh] bg-[#FBFBFB]">
			<PartnerSidebar />
			<div className="flex min-h-[100svh] w-full flex-col">
				{width < 768 && (
					<nav className="sticky top-0 flex w-full justify-end bg-primary px-4 pb-[18px] pt-3.5">
						<img
							src={hamburgerMenu}
							alt="Hamburger"
							className={cn(
								"focus-visible:ring-offset cursor-pointer duration-200 ease-in-out focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring",
								{
									"rotate-90": isOpen,
								}
							)}
							onClick={() => {
								dispatch(setIsOpen(!isOpen));
								// if (!isOpen) dispatch(setIsOpen(true));
								// else dispatch(setIsOpen(false));
							}}
						/>
					</nav>
				)}

				<div
					className={cn(
						"flex w-full flex-1 flex-col justify-between overflow-auto md:pl-16"
					)}
					// ref={aRef}
				>
					<Outlet />
					<div className="sticky bottom-0 left-0 w-full bg-white py-2 text-center ">
						<Footer />
					</div>
				</div>
			</div>
		</main>
	);
};

export default PartnerLayout;
