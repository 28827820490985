import { useMemo } from "react";
import * as dates from "date-arithmetic";
import { Navigate, momentLocalizer } from "react-big-calendar";
import TimeGrid from "react-big-calendar/lib/TimeGrid";

// Define interface for props with default values
interface WeekViewProps {
	date: Date;
	localizer: momentLocalizer; // Use the type provided by your date library
	max?: Date;
	min?: Date;
	scrollToTime?: Date;
}

const CustomWeekView = ({
	date,
	localizer,
	max = localizer.endOf(new Date(), "day"),
	min = localizer.startOf(new Date(), "day"),
	scrollToTime = localizer.startOf(new Date(), "day"),
	...props
}: WeekViewProps) => {
	const currRange = useMemo(
		() => CustomWeekView.range(date, { localizer }),
		[date, localizer]
	);
	return (
		<TimeGrid
			date={date}
			defaultDate={date}
			eventOffset={15}
			localizer={localizer}
			max={max}
			resources={false}
			min={min}
			range={currRange}
			scrollToTime={scrollToTime}
			{...props}
		/>
	);
};

CustomWeekView.range = (date, { localizer }) => {
	const start = dates.startOf(date, "day");
	const end = dates.add(start, 4, "day");

	let current = start;
	const range: any = [];

	while (localizer.lte(current, end, "day")) {
		range.push(current);
		current = localizer.add(current, 1, "day");
	}
	return range;
};

CustomWeekView.navigate = (date: Date, action: Navigate, { localizer }) => {
	switch (action) {
		case Navigate.PREVIOUS:
			return localizer.add(date, -5, "day");

		case Navigate.NEXT:
			return localizer.add(date, 5, "day");

		default:
			return date;
	}
};

CustomWeekView.title = (date, { localizer }) => {
	const [start, ...rest] = CustomWeekView.range(date, { localizer });
	return localizer.format({ start, end: rest.pop() }, "dayRangeHeaderFormat");
};

// Set default prop types (optional)
// WeekView.propTypes = {
// 	date: prop-types.instanceOf(Date).isRequired,
// 	localizer: PropTypes.object,
// 	max: PropTypes.instanceOf(Date),
// 	min: PropTypes.instanceOf(Date),
// 	scrollToTime: PropTypes.instanceOf(Date),
// };

export default CustomWeekView;
