import BookingCalendar from "@/components/admin/booking/management/calendar/Calendar";
import ManagementEventList from "@/components/admin/booking/management/ManagementEventList";
import ManagementHeader from "@/components/admin/booking/management/ManagementHeader";
import DefaultTopbar from "@/components/topbar/DefaultTopbar";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { useGetAllAdminBookingsQuery } from "@/redux/features/api/adminEndpoints";
import { Booking } from "@/types/admin/booking";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { LuArrowBigUp, LuCalendarDays } from "react-icons/lu";

const Management = () => {
	const { data, isLoading, isSuccess } = useGetAllAdminBookingsQuery({
		status: 0,
	});

	const [events, setEvents] = useState<Booking[]>([]);
	const [selectedStations, setSelectedStations] = useState<
		{
			value: string;
			label: string;
		}[]
	>([]);
	const handleRemoveSchedule = (selectedStations, station) => {
		setSelectedStations(
			selectedStations.filter(
				(selectedStation) => selectedStation !== station
			)
		);
	};

	useEffect(() => {
		if (data && data.data) setEvents(data?.data);
	}, [isLoading, isSuccess, data]);

	return (
		<>
			<div className="flex items-center justify-between pb-4">
				<DefaultTopbar title="Manage Reservations" />
				<div className="flex items-center gap-x-3">
					<div className="flex items-center gap-x-2 text-[16px] font-medium">
						<LuCalendarDays size={18} />
						<p>{DateTime.now().toFormat("dd MMM, yyyy")}</p>
					</div>
					<div>
						<Button size="sm" variant="outline">
							<LuArrowBigUp size={18} />
							<span className="ml-2">Upgrade plan</span>
						</Button>
					</div>
				</div>
			</div>

			{/* //Main content   */}
			<div className="flex flex-col gap-y-4">
				<ManagementHeader bookingData={data?.data ?? []} />
				<div className="flex gap-6">
					<div className="flex-[0.2]">
						<ManagementEventList />
					</div>

					<div
						className={cn("h-[72vh] w-full flex-[0.8]", {
							"h-[65vh]": selectedStations.length > 1,
						})}
					>
						<BookingCalendar
							handleRemoveSchedule={handleRemoveSchedule}
							events={events}
							setEvents={setEvents}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default Management;
