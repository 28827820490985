import BookingList from "@/components/admin/partners/partner/booking/BookingList";
import BookingListCardDetails from "@/components/admin/partners/partner/booking/BookingListCardDetails";
import PartnerInformationOverview from "@/components/admin/partners/partner/PartnerInformationOverview";
import UserList from "@/components/admin/partners/partner/users/UserList";
import Wrapper from "@/container/Wrapper";
import { useAppSelector } from "@/hooks/hooks";
import useAdminBookingReview from "@/hooks/useAdminBookingReview";
import { cn } from "@/lib/utils";
import { useGetAllAdminPartnerDetailsQuery } from "@/redux/features/api/adminEndpoints";
import { useGetPartnerUsersQuery } from "@/redux/features/api/generalEndpoints";
import { ChevronLeft } from "lucide-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";

const Partner: React.FC = () => {
	const { user } = useAppSelector((state) => state.user);
	const navigate = useNavigate();
	const { partner_id } = useParams();
	const [activeTab, setActiveTab] = useState<"users" | "bookings">("users");
	const isShowBookingDetails = useAdminBookingReview();

	const { data, isFetching, isSuccess, isError } =
		useGetAllAdminPartnerDetailsQuery({
			partnerId: partner_id ?? "",
		});
	const {
		data: userData,
		isFetching: isUserDataFetching,
		isSuccess: isUserFetchSuccess,
		isError: isUserFetchError,
	} = useGetPartnerUsersQuery({
		companyId: partner_id ?? "",
	});

	// const userData = data?.data?.users;
	const bookingsData = data?.data?.bookings;
	const partnerData = data?.data;

	return (
		<>
			<div className="flex h-full flex-col gap-y-6 ">
				{/* Partner Header  */}
				<div className="flex items-start justify-between pr-8">
					<div className="flex items-center gap-4">
						<button onClick={() => navigate(-1)}>
							<ChevronLeft />
						</button>
						<div>
							<p className="text-xs font-normal">
								{user?.business?.name ?? "[Business Name]"}
							</p>
							<h1 className="text-[20px] font-semibold text-basecolor">
								Partners
							</h1>
						</div>
					</div>
					<div className="flex items-center gap-4">
						<div className="flex w-fit gap-1 rounded-[99px] p-1 pr-4 shadow-[0px_0px_5px_rgba(0,0,0,0.15)]">
							<div className="flex h-[48px] w-[48px] items-center justify-center rounded-[99px] bg-primary/20 p-2">
								<i className="mgc_user_security_line text-[18px] before:!text-primary" />
							</div>
							<div className=" ">
								<p className="pb-0 text-lg font-semibold text-primary">
									{partnerData?.statics?.total_admins ?? 0}
								</p>
								<p className="pt-0 text-[13px] font-[400] text-primary">
									Admins
								</p>
							</div>
						</div>
						<div className="flex w-fit gap-1 rounded-[99px] p-1 pr-4 shadow-[0px_0px_5px_rgba(0,0,0,0.15)]">
							<div className="flex h-[48px] w-[48px] items-center justify-center rounded-[99px] bg-primary/20 p-2">
								<i className="mgc_calendar_month_line text-[18px] before:!text-primary" />
							</div>
							<div>
								<p className="pb-0 text-lg font-semibold text-primary">
									{partnerData?.statics?.total_bookings ?? 0}
								</p>
								<p className="pt-0 text-[13px] font-[400] text-primary">
									Reservations
								</p>
							</div>
						</div>
					</div>
				</div>
				{/* //Partner Profile  */}
				<div className="px-8">
					<PartnerInformationOverview partner={partnerData} />
				</div>

				{isShowBookingDetails ? (
					<BookingListCardDetails />
				) : (
					<Wrapper className="" fetching={isFetching}>
						{isSuccess && data && (
							<div className="flex flex-col gap-y-6 px-8">
								{/* the search which would change based on the available tab  */}
								{/* tab list  */}
								<div className="flex h-full w-full flex-col px-6  pb-3">
									{/* Tab  */}
									<div className="mb-4 flex w-full cursor-pointer border-b border-b-[#9CA3AF] text-[15px] font-semibold text-[#858C95] ">
										<button
											onClick={() =>
												setActiveTab("users")
											}
											className={cn(
												"flex w-full justify-center py-2",
												{
													"border-b-2 border-b-primary text-primary":
														activeTab === "users",
												}
											)}
										>
											<div
												className={cn(
													"flex items-center gap-2 ",
													{
														"text-primary":
															activeTab ===
															"users",
													}
												)}
											>
												<p> Users </p>
												<span className=" flex h-[25px]  w-[25px] items-center justify-center rounded-full bg-primary/20  bg-opacity-10 text-center text-xs text-primary">
													{userData?.data?.length ??
														0}
												</span>
											</div>
										</button>
										<button
											onClick={() =>
												setActiveTab("bookings")
											}
											className={cn(
												"flex w-full justify-center py-2",
												{
													"border-b-2 border-b-primary text-primary":
														activeTab ===
														"bookings",
												}
											)}
										>
											<div
												className={cn(
													"flex items-center gap-2 ",
													{
														"text-primary":
															activeTab ===
															"bookings",
													}
												)}
											>
												<p>Reservations </p>

												<span className=" flex h-[25px]  w-[25px] items-center justify-center rounded-full bg-primary/20  bg-opacity-10 text-center text-xs text-primary">
													{bookingsData?.length ?? 0}
												</span>
											</div>
										</button>
									</div>
									{/* Tab list  */}
									<div className="">
										{activeTab === "users" && (
											<div>
												<UserList
													isUserDataFetching={
														isUserDataFetching
													}
													isUserFetchError={
														isUserFetchError
													}
													isUserFetchSuccess={
														isUserFetchSuccess
													}
													users={userData?.data}
													companyId={partner_id}
												/>
											</div>
										)}
										{activeTab === "bookings" && (
											<div className="w-full">
												<BookingList
													bookings={bookingsData}
												/>
											</div>
										)}
									</div>
								</div>
							</div>
						)}
						{isError && <p>Error Fetching partner data</p>}
					</Wrapper>
				)}
			</div>
		</>
	);
};

export default Partner;
