import { useGetGuestDetailQuery } from "@/redux/features/api/adminEndpoints";
import { MeetingStatus } from "@/services/mockData";
import { formatDateString } from "@/services/utilities";
import { LuCalendarCheck } from "react-icons/lu";
import { PiBuildings } from "react-icons/pi";
import { useParams } from "react-router";
import Avatar from "../common/Avatar";
// import { DateTime } from "luxon";

interface GuestMeetingDetailsCard {
	meetingdata?: any; //Finish this and update
	status: MeetingStatus;
}

const GuestMeetingDetailsCard = () => {
	// console.log(meetingdata)
	const { meeting_id } = useParams();
	const getGuestDetailQuery = useGetGuestDetailQuery({
		meeting_id: meeting_id ?? "",
	});

	return (
		<>
			<section className="relative space-y-2 rounded-lg bg-white p-6 drop-shadow-[0_0px_10px_rgba(0,0,0,0.15)]">
				<h1 className="max-w-full divide-y-0 text-[20px] font-semibold text-[#323539] md:max-w-[70%]">
					{getGuestDetailQuery?.data?.data?.room_booking.title ??
						"No Title Available"}
				</h1>
				<p className="py-2">
					{getGuestDetailQuery?.data?.data?.room_booking
						?.description ?? "No description available"}
				</p>

				<section className="hidden flex-col space-y-4 md:flex">
					<div className="flex items-center gap-x-[8px] gap-y-[5px]">
						<i className="mgc_box_line before:!text-primary" />
						<p className="text-[#323539]">
							{getGuestDetailQuery?.data?.data?.room_booking?.room
								?.name ?? "N/A"}
						</p>
					</div>
					<div className="flex items-center gap-x-[8px] gap-y-[5px] border-t border-t-primary/30 pt-3">
						<i className="mgc_location_line before:!text-primary" />
						<p className="text-[#323539]">
							{getGuestDetailQuery.data?.data?.room_booking
								? getGuestDetailQuery.data.data.room_booking
										?.room?.location?.address +
									" | " +
									getGuestDetailQuery?.data?.data
										?.room_booking?.room?.address
								: "N/A"}
						</p>
					</div>
					<div className="flex items-center gap-x-[8px] gap-y-[5px] border-t border-t-primary/30 pt-3">
						<i className="mgc_schedule_line before:!text-primary" />
						<p className="text-[#323539]">
							{getGuestDetailQuery?.data?.data?.room_booking
								?.start_at
								? formatDateString(
										getGuestDetailQuery?.data?.data?.room_booking.start_at.split(
											" "
										)[0]
									)
								: "N/A"}
						</p>
					</div>
					<div className="flex items-center gap-x-[8px] gap-y-[5px] border-t border-t-primary/30 pt-3">
						<i className="mgc_time_line before:!text-primary" />
						<p className="text-[#323539]">
							{getGuestDetailQuery?.data?.data?.room_booking
								?.start_at
								? getGuestDetailQuery?.data?.data?.room_booking.start_time.slice(
										0,
										-3
									)
								: "N/A"}{" "}
							-{" "}
							{getGuestDetailQuery?.data?.data?.room_booking
								?.end_time
								? getGuestDetailQuery?.data?.data?.room_booking.end_time.slice(
										0,
										-3
									)
								: "N/A"}
						</p>
					</div>
				</section>
				{/* Mobile view of above section */}
				<section className="flex flex-col space-y-1.5 text-[14px] md:hidden">
					<p className="flex items-center gap-x-[8px] gap-y-[5px]">
						<span className="text-primary">
							<LuCalendarCheck />
						</span>
						{getGuestDetailQuery?.data?.data?.room_booking?.start_at
							? formatDateString(
									getGuestDetailQuery?.data?.data?.room_booking.start_at.split(
										" "
									)[0]
								)
							: "N/A"}
					</p>
					<p className="flex items-center gap-x-[8px] gap-y-[5px]">
						<i className="mgc_time_line before:!text-primary" />
						{getGuestDetailQuery?.data?.data?.room_booking?.start_at
							? getGuestDetailQuery?.data?.data?.room_booking.start_time.slice(
									0,
									-3
								)
							: "N/A"}{" "}
						-{" "}
						{getGuestDetailQuery?.data?.data?.room_booking?.end_time
							? getGuestDetailQuery?.data?.data?.room_booking.end_time.slice(
									0,
									-3
								)
							: "N/A"}
					</p>
					<p className="flex items-center gap-x-[8px] gap-y-[5px]">
						<span className="text-primary">
							<PiBuildings />
						</span>
						{getGuestDetailQuery?.data?.data?.room_booking?.room
							?.name ?? "N/A"}
					</p>
					<p className="flex items-center gap-x-[8px] gap-y-[5px]">
						<Avatar
							name={
								getGuestDetailQuery?.data?.data?.room_booking
									?.host?.name ?? "N/A"
							}
							variant="host"
						/>
					</p>
					<p className="flex items-center gap-x-[8px] gap-y-[5px]">
						<span className="text-primary">
							<PiBuildings />
						</span>
						{
							getGuestDetailQuery?.data?.data?.room_booking
								?.company?.name
						}
					</p>
				</section>

				<section className="flex justify-between pt-4 md:border-t md:border-t-primary/30">
					<div className="hidden w-full space-y-2 md:block">
						<h1>Organizer</h1>
						<Avatar
							variant="host"
							name={
								getGuestDetailQuery?.data?.data?.room_booking
									?.host?.name ?? "N/A"
							}
							spanClassName="size-[34px] md:text-[15px] font-medium"
						/>
					</div>
					{/* <div className="w-[200px] space-y-2 lg:min-w-[200px]">
						<h1 className="text-[12px] font-semibold text-[#323539] md:text-base">
							Guests
						</h1>
						{!showAllGuests && (
							<>
								{meetingdata?.meeting_members
									.slice(0, 3)
									?.map((guest: any, index: number) => (
										<div className="" key={index}>
											<GuestHolder
												data={guest}
												status={status}
												name={
													guest?.member_profile
														?.name ??
													guest?.member_profile.email
												}
												invitationStatus={guest?.status}
											/>
										</div>
									))}

								{meetingdata?.meeting_members?.length > 3 && (
									<button
										onClick={() => setShowAllGuests(true)}
										className="text-[12px] font-medium text-primary"
									>
										{" "}
										+ 2 other guests
									</button>
								)}
								{meetingdata?.meeting_members &&
									meetingdata?.meeting_members.length === 0 &&
									"No guest"}
							</>
						)}
						{showAllGuests && (
							<ScrollArea className="h-[120px]">
								{meetingdata?.meeting_members?.map(
									(guest: any, index: number) => (
										<div className="py-2" key={index}>
											<GuestHolder
												data={guest}
												status={status}
												name={
													guest?.member_profile
														?.name ??
													guest?.member_profile.email
												}
												invitationStatus={guest?.status}
											/>
										</div>
									)
								)}
							</ScrollArea>
						)}
					</div> */}
				</section>
				{/* <section
					className={cn(
						"flex items-center justify-between gap-2 border-y-[0.5px] border-y-primary  py-2 md:py-3",
						{
							hidden: meetingdata.status === "declined",
						}
					)}
				>
					<h1 className="font-semibold text-[#323539]">
						Add to your calendar
					</h1>
					<div className="flex items-center gap-1 self-start md:gap-2">
						<button className=" margin-auto rounded-full bg-white p-1 drop-shadow-[2px_2px_2px_rgba(0,0,0,0.25)]">
							
							<img src={GoogleCalendarIcon} alt="" />
						</button>
						<button className=" margin-auto rounded-full bg-white p-2 drop-shadow-[2px_2px_2px_rgba(0,0,0,0.25)]">
							<img src={MsCalendarIcon} alt="" />
						</button>
						<button className=" margin-auto rounded-full bg-white p-2 drop-shadow-[2px_2px_2px_rgba(0,0,0,0.25)]">
							<img src={YahooCalendarIcon} alt="" />
						</button>
					</div>
				</section> */}
			</section>
		</>
	);
};

export default GuestMeetingDetailsCard;
