import useDimension from "@/hooks/useScreenDimension";
import { useUpdateBookingStatusMutation } from "@/redux/features/api/partnerEndpoints";
import { ChevronRight } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router";
import { cn } from "../../lib/utils";
import CancelMeeting from "../modals/partner/CancelMeeting";
import { Button } from "../ui/button";
import { Dialog, DialogContent } from "../ui/dialog";
import MobileMeetingCard from "./mobile/MobileMeetingCard";
import { useAppSelector } from "@/hooks/hooks";
import { TeamMemberRoles } from "@/types/team-member";
import CapacityHolder from "../common/CapacityHolder";
// import { MEETING_DATA } from "@/services/mockData";

interface MeetingCardProps {
	// meetingdata: MEETING_DATA;
	meetingdata: any;
}

const MeetingCard = ({ meetingdata }: MeetingCardProps) => {
	const { width } = useDimension();
	const navigate = useNavigate();
	const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");

	const [showDeleteMeeting, setShowDeleteMeeting] = useState(false);
	// ... your existing state and variables

	const [_, { isLoading }] = useUpdateBookingStatusMutation();

	return (
		<>
			{/* //View above 768px  */}
			{width > 768 ? (
				<div
					className="flex min-h-[131px] cursor-pointer flex-col items-center rounded-[12px] bg-white p-2 drop-shadow-[0_1px_5px_rgba(16,24,40,0.15)] md:flex-row"
					onClick={() =>
						navigate(`/partner/meeting/${meetingdata.id}`, {
							state: {
								meeting: meetingdata?.status,
							},
						})
					}
				>
					<div className="flex flex-grow gap-3.5 self-stretch rounded-xl xl:flex-[0.35]">
						{meetingdata?.room?.photos[0]?.image_url ? (
							<img
								src={meetingdata?.room?.photos[0]?.image_url}
								alt=""
								className="w-[150px] self-stretch rounded-[12px] md:w-[179px]"
							/>
						) : (
							<div className="flex min-h-[72px] w-[150px] flex-col justify-center self-stretch rounded-[12px] bg-[#DCDCDC] px-4 md:w-[179px]">
								<div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-[#C7C7C7] ">
									<p className="select-none text-lg font-semibold text-white">
										{meetingdata.room?.name
											.split(" ")[0][0]
											.toUpperCase()}
									</p>
								</div>
							</div>
						)}

						<div className="flex flex-col justify-between gap-y-2 py-2">
							<div className="space-y-1">
								<h2 className=" font-bold">
									{meetingdata?.room?.name}
								</h2>
								<div className="flex items-center space-x-2 text-[10px]">
									<i className="mgc_location_3_line text-[12px] before:!text-[#323539]" />
									<p>
										{meetingdata?.room?.location?.name ??
											"N/A"}
									</p>
								</div>
							</div>

							<CapacityHolder
								capacity={meetingdata?.room?.capacity}
							/>
						</div>
					</div>
					<div className="flex flex-grow flex-col justify-between space-y-[9px] border-l-2 pl-2 xl:flex-[0.2] ">
						<span className="flex items-center gap-2 ">
							<i className="mgc_calendar_line text-base before:!text-[#BFBFBF]" />
							<p className="text-[10px]">
								{meetingdata?.start_at.split(" ")[0] ?? "N/A"}
							</p>
						</span>
						<span className="flex items-center gap-2 ">
							<i className="mgc_time_line text-base before:!text-[#BFBFBF]" />
							<p className="text-[10px]">
								{meetingdata?.start_time?.slice(0, -3) ?? "N/A"}{" "}
								{meetingdata?.duration == null
									? "(All day)"
									: `- ${meetingdata?.end_time?.slice(0, -3) ?? "N/A"}`}
							</p>
						</span>
						<span className="flex items-center gap-2 ">
							<i className="mgc_contacts_2_line text-base before:!text-[#BFBFBF]" />
							<p className="text-[10px]">
								{meetingdata?.host?.name ?? "N/A"}
								{meetingdata?.is_host ? " (You)" : ""}
							</p>
						</span>
						<span className="flex items-center gap-2 text-[10px]">
							<span className="ml-0.5 flex h-[13px] w-[13px] items-center justify-center rounded-full border border-[#BFBFBF] bg-[#fff] p-1 text-[#BFBFBF]">
								{meetingdata?.host?.name?.split("")[0] ?? "N/A"}
							</span>
							{meetingdata?.host?.name ?? "N/A"}
						</span>
					</div>

					<div className="flex  flex-grow flex-col justify-between gap-y-7 self-stretch border-l-2 pl-2 xl:flex-[0.35]">
						<p className="line-clamp-1 text-[20px] font-semibold text-[#323539]">
							{meetingdata?.title ?? "N/A"}
						</p>
						{userRole !== TeamMemberRoles.PartnerAdmin && (
							<div
								className="flex w-[80%] justify-between gap-x-2.5"
								onClick={(e) => e.stopPropagation()}
							>
								{meetingdata?.status === "approved" ||
									(meetingdata?.status === "pending" && (
										<Button
											variant="outline"
											className="h-[36px] w-full"
											onClick={() =>
												setShowDeleteMeeting(true)
											}
											disabled={isLoading}
										>
											Cancel
										</Button>
									))}
								{meetingdata?.status === "approved" ||
									(meetingdata?.status === "pending" && (
										<Button
											className="h-[36px] w-full"
											variant="outlinecustom"
											onClick={() =>
												navigate(
													`/partner/meeting/${meetingdata.id}`,
													{
														state: {
															meeting:
																meetingdata.status,
														},
													}
												)
											}
										>
											Edit
										</Button>
									))}
								{meetingdata?.status === "invited" && (
									<Button
										className="h-[36px] w-full"
										variant="outline"
										onClick={() =>
											navigate(
												`/partner/meeting/${meetingdata.id}`,
												{
													state: {
														meeting:
															meetingdata?.status,
													},
												}
											)
										}
									>
										Decline
									</Button>
								)}
								{meetingdata?.status === "invited" && (
									<Button
										className="h-[36px] w-full"
										onClick={() =>
											navigate(
												`/partner/meeting/${meetingdata.id}`,
												{
													state: {
														meeting:
															meetingdata?.status,
													},
												}
											)
										}
									>
										Accept
									</Button>
								)}
							</div>
						)}

						{(meetingdata?.status === "pending" ||
							meetingdata?.status === "approved" ||
							meetingdata?.status === "declined" ||
							meetingdata?.status === "cancelled" ||
							meetingdata?.status === "done") && (
							<span
								className={cn(
									"flex w-fit items-center rounded-lg bg-[#D2E8E5] px-5 py-1 text-[14px] font-medium capitalize text-[#216D63]",
									{
										"bg-[#F6E2E1] text-[#B7241D]":
											meetingdata?.status ===
												"declined" ||
											meetingdata?.status ===
												"cancelled" ||
											meetingdata?.status === "pending",
										"bg-[#F6F6F6] text-[#636363]":
											meetingdata?.status === "done",
										"bg-[#E7F3F1] text-[#138576]":
											meetingdata?.status === "accepted",
									}
								)}
							>
								{meetingdata?.status}
							</span>
						)}
					</div>

					<div
						className={cn(
							"ml-auto flex flex-[0.1] flex-col items-end justify-center gap-1.5 py-1",
							{
								hidden:
									meetingdata?.status === "done" ||
									meetingdata?.status === "cancelled",
							}
						)}
					>
						<button className="text-[#858C95]">
							<ChevronRight />
						</button>
					</div>
				</div>
			) : (
				<MobileMeetingCard
					meetingdata={meetingdata}
					setShowDeleteMeeting={setShowDeleteMeeting}
				/>
			)}
			{userRole !== TeamMemberRoles.PartnerAdmin && (
				<Dialog
					modal
					open={showDeleteMeeting}
					onOpenChange={setShowDeleteMeeting}
				>
					<DialogContent className="flex max-w-[95%] rounded-lg border border-[#E5E5E7] bg-white p-4 shadow-md xs:max-w-[360px]">
						<CancelMeeting
							handleClose={() => setShowDeleteMeeting(false)}
							name={meetingdata?.room?.name}
							meetingdata={meetingdata}
						/>
					</DialogContent>
				</Dialog>
			)}
		</>
	);
};

export default MeetingCard;
