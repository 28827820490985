import { Button } from "@/components/ui/button";
import { FaRegCreditCard } from "react-icons/fa6";
import { TbTrash } from "react-icons/tb";

interface PaymentMethodCardProps {
	onSelect: () => void;
	checked: boolean;
	item: {
		id: number;
		bank: string;
		username: string;
		bank_card_details: string;
	};
}
const PaymentMethodCard = ({
	item,
	onSelect,
	checked,
}: PaymentMethodCardProps) => {
	return (
		<div className="flex items-center justify-between rounded-[12px] bg-white px-4  py-3 shadow-[0px_0px_5px_rgba(0,0,0,0.25)]">
			<div className="flex gap-4 lg:flex-[0.3]">
				<input type="checkbox" checked={checked} onChange={onSelect} />
				<div className="relative flex items-center gap-3">
					<FaRegCreditCard />
					<p>{item.bank}</p>
				</div>
			</div>
			<div className="items-center gap-x-4 border-l-2  pl-6 lg:flex lg:flex-[0.3]">
				<div className="relative flex items-center gap-1">
					<p className="capitalize">{item.username}</p>
				</div>
			</div>
			<div className="hidden items-center justify-between gap-x-4 border-l-2 pl-6 lg:flex lg:flex-[0.4]">
				<div className="relative flex items-center gap-1">
					<p>
						{item.bank_card_details.replace(
							/^\d{12}/,
							"************"
						)}
					</p>
				</div>
				<Button
					variant="outlinecustom"
					className="h-7 border-none px-1.5 py-1.5 text-lg text-[#858C95]"
				>
					<TbTrash />
				</Button>
			</div>
		</div>
	);
};

export default PaymentMethodCard;
