import CustomSelect from "@/components/common/CustomSelect";
import TimeSlotPicker from "@/components/common/TimeSlotPicker";
import FormInput from "@/components/input/FormInput";
import { LoaderButton } from "@/components/Loader/LoaderButton";
import { DatePicker } from "@/components/ui/datepicker";
import { DialogClose } from "@/components/ui/dialog";
import { Switch } from "@/components/ui/switch";
import { useAppDispatch, useAppSelector } from "@/hooks/hooks";
import { useBookRoomMutation } from "@/redux/features/apiSlice";
import {
	clearBooking,
	updateBooking,
	updateIsAllDay,
} from "@/redux/slices/bookingSlice";
import { RepeatTimeOptions } from "@/services/constants";
import { DateTime } from "luxon";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { FaRegClock } from "react-icons/fa6";
import AttendeeSelector from "./AttendeeSelector";
import useCustomToast from "@/components/CustomToast";
import useScreenDimension from "@/hooks/useScreenDimension";
import Modal from "@/components/modals/Modal";
import RecurrenceForm from "@/components/admin/booking/book/room/RecurrenceForm";
import { cn } from "@/lib/utils";
import CustomShadSelect from "@/components/common/CustomShadSelect";
import {
	formatTime,
	generateBookingDurationTime,
	generateBookingEndTimeOptions,
	generateDurationString,
} from "@/services/utilities";
import RecurrenceSummary from "@/components/admin/booking/book/room/RecurrenceSummary";

interface BookingDetailsProps {
	room: any;
	timeslots: string[];
	isLoading: boolean;
	defaultDate: string;
	setDefaultDate: Dispatch<SetStateAction<string>>;
	defaultDateError: string;
	getTimeSlotsRefetch: any;
	setOverviewData: any;
	setActiveTab: Dispatch<SetStateAction<"reservation" | "room" | "overview">>;
}

const BookingDetails = ({
	room,
	timeslots,
	isLoading,
	defaultDate,
	setDefaultDate,
	// getTimeSlotsRefetch,
	setOverviewData,
	setActiveTab,
}: BookingDetailsProps) => {
	const [selectedAttendees, setSelectedAttendees] = useState([]);
	const [showReccurringModal, setShowReccurringModal] = useState(false);
	const [recurrenceData, setReccurenceData] = useState({});
	const dispatch = useAppDispatch();
	const user = useAppSelector((state) => state.user);
	const { width } = useScreenDimension();
	const { duration, description, title, isAllDay, startTime, date, endTime } =
		useAppSelector((state) => state.bookings.booking);
	const customToast = useCustomToast();
	const [repeatSelection, setRepeatSelection] = useState<{
		label: string;
		value: string;
	}>({ label: "Does not repeat", value: "does not repeat" });
	const handleSaveRecurrenceData = (data: any) => {
		setReccurenceData(data);
		setShowReccurringModal(false);
	};
	const startTimeOptions = useMemo(() => {
		return timeslots?.map((timeslot) => ({
			label: formatTime(timeslot),
			value: timeslot,
		}));
	}, [timeslots]);

	const [
		bookRoom,
		{
			data: bookingData,
			isSuccess: isBookingRoomSuccess,
			isError: isBookingRoomError,
			isLoading: isBookingRoomLoading,
			error: bookingRoomError,
		},
	] = useBookRoomMutation();

	const handleSubmit = async (formData) => {
		if (!formData.startTime || formData.startTime === "06:00") {
			return customToast("Please select a valid start time", {
				id: "book-room",
				type: "error",
			});
		}
		const data = {
			room_id: formData.room_id,
			title: formData.title,
			date: formData.date,
			...(formData.description && { description: formData.description }),
			is_allday: formData.isAllDay,
			start_time: formData.startTime,
			...(formData.duration !== 0 && { duration: formData.duration }),
			...(formData.member_emails.length >= 1 && {
				member_emails: formData.member_emails,
			}),
			is_recurring: repeatSelection.value == "does not repeat" ? 0 : 1,
			...recurrenceData,
			// is_recurring: 0, //to update when working on recurring meetings
			host_id: formData.host_id,
		};
		await bookRoom({
			business_or_company_id: user.user?.company.id ?? 0,
			data,
		});
	};

	const {
		register,
		handleSubmit: handleFormSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: {
			title: title,
			description: description,
		},
	});

	const onSubmit = () => {
		const submittedData = {
			title: title,
			room_id: room.id,
			host_id: user.user?.id ?? undefined,
			description,
			date: defaultDate,
			duration,
			isAllDay: isAllDay ? 1 : 0,
			startTime,
			member_emails: selectedAttendees,
		};
		handleSubmit(submittedData);
	};

	useEffect(() => {
		if (isBookingRoomSuccess) {
			customToast("Space reserved successfully 🎉", {
				id: "book-room",
				type: "success",
			});
			dispatch(clearBooking());
			setTimeout(() => {
				dispatch(clearBooking());
				setOverviewData(bookingData);
				setActiveTab("overview");
			}, 2000);
		} else if (isBookingRoomError) {
			if (bookingRoomError) {
				const errorMessage =
					(
						bookingRoomError as {
							data?: { message?: string };
						}
					)?.data?.message || "An error occurred";
				return customToast(errorMessage, {
					id: "book-room",
					type: "error",
				});
			}
		}
	}, [isBookingRoomSuccess, isBookingRoomError, bookingRoomError]);

	useEffect(() => {
		if (repeatSelection.value == "does not repeat") {
			setShowReccurringModal(false);
		}
	}, [repeatSelection]);

	useEffect(() => {
		if (timeslots?.length > 0 && startTimeOptions.length > 0) {
			const newStartTime = startTimeOptions[0]?.value;
			if (!startTime && newStartTime) {
				dispatch(
					updateBooking({
						startTime: newStartTime,
					})
				);
			}
		}
	}, [timeslots, startTimeOptions]);

	useEffect(() => {
		if (startTime && endTime) {
			const newDuration = generateBookingDurationTime(startTime, endTime);
			dispatch(
				updateBooking({
					duration: newDuration,
				})
			);
		}
	}, [startTime, endTime]);
	return (
		<div
			className={cn("relative", {
				" opacity-90": repeatSelection.value == "custom",
			})}
		>
			<div className="flex w-full flex-col gap-y-3">
				<div>
					<FormInput
						inputType="text"
						value={room.name}
						disabled={true}
					/>
				</div>
				<form
					className="flex flex-col gap-y-2"
					onSubmit={handleFormSubmit(onSubmit)}
				>
					<div>
						<FormInput
							inputType="text"
							{...register("title", {
								required: "Reservation name is required",
							})}
							placeholder="Title"
							error={errors.title}
							onChange={(e) => {
								dispatch(
									updateBooking({
										title: e.target.value,
									})
								);
							}}
						/>
					</div>
					<div className="flex flex-col gap-y-2">
						<div className="flex flex-col gap-2">
							<div className="flex gap-x-2">
								{/* Date picker  */}
								<DatePicker
									title="Select Date"
									className="max-w-[170px]"
									date={
										date
											? DateTime.fromISO(date).toJSDate()
											: DateTime.fromISO(
													defaultDate
												).toJSDate()
									}
									setDate={(date) => {
										if (date) {
											const luxonDate =
												DateTime.fromJSDate(date);
											dispatch(
												updateBooking({
													date:
														luxonDate.toISODate() ??
														"",
												})
											);
											setDefaultDate(
												luxonDate.toISODate() ?? ""
											);
										}
									}}
								/>
								<CustomShadSelect
									options={startTimeOptions}
									value={startTime}
									className="w-full max-w-[227px] text-[14px]"
									placeholder={
										<div className="flex items-center gap-3 text-[14px]">
											<FaRegClock /> Start Time
										</div>
									}
									onChange={(newValue: any) => {
										dispatch(
											updateBooking({
												startTime: newValue,
											})
										);
									}}
								/>
								<CustomShadSelect
									options={generateBookingEndTimeOptions(
										timeslots,
										startTime
									)}
									value={endTime}
									className="w-full max-w-[227px] text-[14px]"
									placeholder={
										<div className="flex items-center gap-3 text-[14px]">
											<FaRegClock /> End Time
										</div>
									}
									onChange={(newValue) => {
										dispatch(
											updateBooking({
												endTime: newValue,
											})
										);
									}}
								/>
							</div>

							{/* second layer  */}
							<div className="flex w-full gap-x-2">
								<CustomSelect
									placeholder="Does not repeat"
									className="w-full max-w-[255px] text-[14px]"
									options={RepeatTimeOptions}
									value={repeatSelection}
									onChange={(selectedOption: any) => {
										if (
											selectedOption.value ==
											repeatSelection.value
										) {
											setShowReccurringModal(true);
										}
										setRepeatSelection(selectedOption);
									}}
								/>
								<p className="my-auto px-2 text-sm text-basecolor">
									{generateDurationString(duration)}
								</p>
								{/* <CustomSelect
									value={{
										label: generateDurationString(duration),
										value: generateDurationString(duration),
									}}
									isDisabled={true}
									className="w-36 max-w-[227px] text-[14px]"
									placeholder={
										<div className="flex items-center gap-3 text-[14px]">
											<FaRegClock /> Duration
										</div>
									}
								/> */}

								<div className="flex items-center gap-x-2">
									<Switch
										id="all-day"
										checked={isAllDay}
										onCheckedChange={() =>
											dispatch(updateIsAllDay(!isAllDay))
										}
									/>
									<p>All day</p>
								</div>
							</div>
							<RecurrenceSummary />
						</div>
						<div className="px-2">
							<p className="pb-1  pt-3 text-xs font-medium text-basecolor">
								Available Time Slots
							</p>

							<TimeSlotPicker
								selectedTimeSlot={startTime}
								selectTimeSlot={(time) =>
									dispatch(
										updateBooking({
											startTime: time,
										})
									)
								}
								timeslots={timeslots}
								slotAmount={
									width > 640 ? 6 : width > 425 ? 4 : 3
								}
								isLoading={isLoading}
							/>
						</div>
					</div>

					<div className="flex w-full flex-col gap-4 md:flex-row">
						<div className="flex-1 md:flex-[0.6]">
							<FormInput
								rows={10}
								textarea={true}
								className="hidden w-full rounded-md text-[14px] md:block"
								error={errors.description}
								placeholder="Add Description"
								onChange={(e) => {
									dispatch(
										updateBooking({
											description: e.target.value,
										})
									);
								}}
							/>
						</div>

						{/* <div className="md:hidden">
						<FormInput
							rows={5}
							{...register("description", {
								required:
									"Meeting description is required",
							})}
							error={errors.description}
							value={description}
							textarea={true}
							className="rounded-md text-[14px]"
							placeholder="Add Description"
							onChange={(e) => {
								dispatch(
									updateBooking({
										description:
											e.target.value,
									})
								);
							}}
						/>
					</div> */}

						{/* Attendees list */}
						<div className="flex-1 md:flex-[0.4]">
							<AttendeeSelector
								selectedAttendees={selectedAttendees}
								setSelectedAttendees={setSelectedAttendees}
								user={user}
							/>
						</div>
					</div>

					<div className="mt-4 px-1">
						<div className="flex w-full gap-2">
							<DialogClose className="w-full rounded-md border border-primary bg-transparent px-4 py-2 text-primary">
								Cancel
							</DialogClose>

							<LoaderButton
								className="w-full"
								variant="default"
								type="submit"
								disabled={isBookingRoomLoading}
								loading={isBookingRoomLoading}
								loaderSize={20}
							>
								Confirm Reservation
							</LoaderButton>
						</div>
					</div>
				</form>
			</div>
			{/* Reccurence Form  */}
			<Modal
				dialogClassName=" p-0 max-w-xl "
				onInteractOutside={(e) => {
					e.preventDefault();
				}}
				showDeleteIcon={false}
				open={showReccurringModal}
				setOpen={setShowReccurringModal}
			>
				<RecurrenceForm
					defaultType={repeatSelection}
					handleClose={() => {
						setReccurenceData({});
						setRepeatSelection({
							label: "Does not repeat",
							value: "does not repeat",
						});
						setShowReccurringModal(false);
					}}
					handleSaveRecurrenceData={handleSaveRecurrenceData}
				/>
			</Modal>
		</div>
	);
};

export default BookingDetails;
