import { Route, Routes } from "react-router";
import Partners from "./List";
import Partner from "./Profile";

const Home = () => {
	return (
		<Routes>
			<Route index element={<Partners />} />
			<Route path=":partner_id/*" element={<Partner />} />
		</Routes>
	);
};

export default Home;
