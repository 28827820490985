import BookingHistoryDetailsCard from "@/components/admin/booking/history/BookingHistoryDetailsCard";
import AmenityHolder from "@/components/common/AmenityHolder";
import CapacityHolder from "@/components/common/CapacityHolder";
import ImageCarousel from "@/components/common/ImageCarousel";
import { cn } from "@/lib/utils";
import { useState } from "react";
import { FaChevronLeft } from "react-icons/fa6";
import { IoChevronDown } from "react-icons/io5";
import { PiBuildings } from "react-icons/pi";
import { useLocation, useNavigate } from "react-router";

const HistoryDetails = () => {
	const location = useLocation();

	const historyData = location.state.history;
	// const id = location.pathname.split("/").pop();
	// const {status}  gotten from the meeting details
	// const status: MeetingStatus = location.state.meeting;
	// console.log(id);
	const navigate = useNavigate();
	const [showAmenities, setShowAmenities] = useState(false);
	const [showDescription, setShowDescription] = useState(false);
	// const status = data?.status;

	return (
		<div className="flex h-full flex-col gap-y-8 overflow-y-auto">
			{/* top  */}
			<div className="flex items-center gap-4">
				<FaChevronLeft
					onClick={() => navigate(-1)}
					className=" cursor-pointer"
				/>
				<p className="text-[22px] font-semibold text-[#323539]">
					{historyData?.data?.status !== "invited"
						? "All Reservations"
						: "Your Meeting"}
				</p>
			</div>
			<div>
				<>
					{/* {(status === "accepted" ||
						status === "invited" ||
						status === "declined") && (
						<div>
							<h1 className="text-[22px] font-semibold text-[#323539]">
								Invitation from:{" "}
								<span className=" font-normal ">
									Kimberly Rueda (EY)
								</span>
							</h1>
						</div>
					)} */}

					<div className="flex flex-col gap-x-20 md:flex-row md:px-4 ">
						<div className="w-full ">
							{(historyData.status === "accepted" ||
								historyData?.status === "pending" ||
								historyData?.status === "invited" ||
								historyData?.status === "declined") &&
								historyData?.company && (
									<section>
										<div className="mb-4 border-b-[0.5px] border-b-[#858C95] pb-3">
											<h1 className="text-lg font-semibold text-[#323539]">
												{historyData?.company?.name}
											</h1>
											<p className="flex items-center gap-1 text-[14px] text-[#6D748D]">
												<PiBuildings />
												{historyData?.company
													?.address ?? "N/A"}
											</p>
										</div>
									</section>
								)}
							<section className="flex items-center justify-between">
								<div>
									<h1 className="text-lg font-bold text-[#323539]">
										{historyData?.room?.name ?? "N/A"}
									</h1>
									<p className="text-xs font-normal">
										{/* Building <b>A</b> | Floor <b>15</b> */}
										{historyData?.room?.address ?? "N/A"}
									</p>
								</div>
								<div>
									<CapacityHolder
										capacity={
											historyData.room?.capacity ?? "N/A"
										}
									/>
								</div>
							</section>
							<section className="pb-4">
								<p className="text-[12px]">
									{historyData?.room?.description ??
										""}
								</p>
							</section>
							<section>
								<div className="w-full">
									{/* <ImageCarousel
											images={historyData?.room?.photos}
										/> */}
									{historyData?.room.photos &&
									historyData?.room.photos.length ? (
										<ImageCarousel
											images={historyData?.room?.photos}
										/>
									) : (
										<div className="flex h-[100px] w-full flex-col justify-center rounded-xl bg-[#DCDCDC] px-4 md:h-[192px]">
											<div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-[#C7C7C7] ">
												<p className="select-none text-lg text-white">
													{historyData?.room?.name
														.split(" ")[0][0]
														.toUpperCase()}
												</p>
											</div>
										</div>
									)}
								</div>
								{/* <img
										src={picture}
										alt=""
										className="h-[120px] w-full rounded-md  md:h-[192px] md:max-w-[616px]"
									/> */}
							</section>
							<section className="flex flex-col pt-4 md:hidden md:gap-y-2">
								<div className="flex items-center justify-between">
									<h1 className=" font-medium">
										Description
									</h1>
									<span
										className={cn("rotate-0 md:hidden", {
											"rotate-180": showDescription,
										})}
										onClick={() =>
											setShowDescription(!showDescription)
										}
									>
										<IoChevronDown />
									</span>
								</div>
								<p
									className={cn("text-[12px] md:text-base", {
										hidden: !showDescription,
									})}
								>
									{historyData.room?.description ??
										"No description available"}
								</p>
							</section>
							<section className="flex flex-col py-4 md:gap-y-2">
								<div className="flex items-center justify-between">
									<h1 className=" font-medium">Amenities</h1>
									<span
										className={cn("rotate-0 md:hidden", {
											"rotate-180": showAmenities,
										})}
										onClick={() =>
											setShowAmenities(!showAmenities)
										}
									>
										<IoChevronDown />
									</span>
								</div>
								<ul
									className={cn(
										"flex flex-wrap gap-4 text-[12px]",
										{
											"hidden md:flex": !showAmenities,
										}
									)}
								>
									{historyData?.room?.amenities?.map(
										(amenity) => (
											<div
												className="w-fit"
												key={amenity.name}
											>
												<AmenityHolder
													amenity={amenity?.name}
													variant="overview"
												/>
											</div>
										)
									)}
								</ul>
							</section>
						</div>
						<div className="w-full ">
							<div className="max-w-lg">
								<BookingHistoryDetailsCard
									meetingdata={historyData}
									status={historyData?.status}
								/>
							</div>
						</div>
					</div>
				</>
			</div>
		</div>
	);
};

export default HistoryDetails;
