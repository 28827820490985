import { useEffect } from "react";
import { UseFormSetError } from "react-hook-form";

export interface ApiError {
	status: number;
	message: string;
	data: {
		errors: Record<string, string[]>;
	};
}

interface Props {
	error: any;
	isError: boolean;
	setError: UseFormSetError<any>;
	keys: string[];
}

const useSetErrors = ({ error, isError, setError, keys }: Props) => {
	useEffect(() => {
		const errorData = (error as ApiError)?.data;
		if (isError && errorData && errorData.errors) {
			keys.forEach((key) => {
				if (errorData.errors.hasOwnProperty(key)) {
					setError(key, {
						message: errorData.errors[key][0],
					});
				}
			});
		}
	}, [isError, error, setError, keys]);

	return true;
};

export default useSetErrors;
