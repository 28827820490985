import useCustomToast from "@/components/CustomToast";
import FormInput from "@/components/input/FormInput";
import { LoaderButton } from "@/components/Loader/LoaderButton";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useAuth } from "@/hooks/auth";
import { useAppSelector } from "@/hooks/hooks";
import { cn } from "@/lib/utils";
import {
	useGetInformationMutation,
	useUpdateAdminProfileMutation,
} from "@/redux/features/api/adminEndpoints";
import { UpdateProfileParams, UpdateProfileSchema } from "@/types/team-member";
import { zodResolver } from "@hookform/resolvers/zod";
import lodash from "lodash";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

const Details: React.FC = () => {
	const customToast = useCustomToast();
	const { setAuthUser } = useAuth();
	const [updateAdminProfile] = useUpdateAdminProfileMutation();
	const [getBussinessInformation] = useGetInformationMutation();
	const user = useAppSelector((s) => s.user.user);

	const [isEditing, setIsEditing] = useState(false);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [initialFormState] = useState<UpdateProfileParams>({
		name: user?.name ?? "",
		email: user?.email ?? "",
	});

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors, isSubmitting },
		reset,
	} = useForm<UpdateProfileParams>({
		resolver: zodResolver(UpdateProfileSchema),
		defaultValues: initialFormState,
	});

	const onSubmit: SubmitHandler<UpdateProfileParams> = async (data) => {
		try {
			if (
				lodash.isEqual(data, { name: user?.name, email: user?.email })
			) {
				setIsEditing(false);
				return;
			}
			// console.log(data);
			await updateAdminProfile(data);
			await getBussinessInformation({})
				.unwrap()
				.then((res) => setAuthUser(res));

			handleResetForm(data);
			customToast("Profile updated successfully", {
				id: "update-profile",
				type: "success",
			});
		} catch (error) {
			customToast("An error occured kindly try again later", {
				id: "update-profile",
				type: "error",
			});
			// setError("root", {
			// 	message:
			// 		"An error occured kindly try again later",
			// });
		}
	};

	const handleResetForm = (newFormState: UpdateProfileParams) => {
		reset(newFormState);
	};

	useEffect(() => {
		if (user && user.name) {
			setFirstName(user.name.split(" ")[1]);
			setLastName(user.name.split(" ")[0]);
		}
	}, [user]);

	useEffect(() => {
		setValue("name", (lastName + " " + firstName).trim());
	}, [firstName, lastName]);

	return (
		<form
			className={cn("flex h-[80vh] flex-1 flex-col justify-between px-7")}
			onSubmit={handleSubmit(onSubmit)}
		>
			<h1 className="mb-4 text-2xl font-semibold text-basecolor">
				User Details
			</h1>
			<ScrollArea className="flex flex-1 flex-col gap-y-4 pb-2">
				<div className="grid grid-cols-2 gap-5">
					<FormInput
						label="Last Name"
						labelClassName="mb-2"
						inputType="text"
						placeholder={isEditing ? "Last Name" : ""}
						className={cn(
							"outline-none transition-all duration-200  ease-in-out",
							{
								"shadow-[0px_0px_5px_0px_rgba(0,0,0,0.1)]s":
									isEditing,
								"cursor-not-allowed opacity-60": !isEditing,
							}
						)}
						readOnly={!isEditing}
						value={lastName}
						defaultValue={lastName}
						error={errors.name}
						onChange={(e) => setLastName(e.target.value)}
					/>
					<FormInput
						label="First Name"
						labelClassName="mb-2"
						inputType="text"
						placeholder={isEditing ? "First Name" : ""}
						className={cn(
							"outline-none transition-all duration-200  ease-in-out",
							{
								"shadow-[0px_0px_5px_0px_rgba(0,0,0,0.1)]s":
									isEditing,
								"cursor-not-allowed opacity-60": !isEditing,
							}
						)}
						readOnly={!isEditing}
						value={firstName}
						defaultValue={firstName}
						onChange={(e) => setFirstName(e.target.value)}
					/>
					<FormInput
						label="Email Address"
						labelClassName="mb-2"
						inputType="text"
						placeholder={
							isEditing ? "info@spaces.migranium.com" : ""
						}
						className={cn(
							"outline-none transition-all duration-200  ease-in-out",
							{
								"shadow-[0px_0px_5px_0px_rgba(0,0,0,0.1)]s":
									isEditing,
								"cursor-not-allowed opacity-60": !isEditing,
							}
						)}
						readOnly={!isEditing}
						error={errors.email}
						{...register("email")}
					/>
				</div>
			</ScrollArea>
			<section className="mt-1 flex items-center space-x-6 self-end">
				{isEditing && (
					<Button
						className={cn("self-end px-5 py-2", {
							"cursor-not-allowed": isSubmitting,
						})}
						variant="outline"
						onClick={() => {
							setIsEditing(false);
							handleResetForm(initialFormState);
						}}
						disabled={isSubmitting}
					>
						Cancel
					</Button>
				)}
				{isEditing ? (
					<LoaderButton
						className={cn("self-end px-5 py-2", {
							"cursor-not-allowed": isSubmitting,
						})}
						loading={isSubmitting}
						disabled={isSubmitting}
						loaderSize={16}
						type="submit"
					>
						Save
					</LoaderButton>
				) : (
					<Button
						className={cn("self-end px-5 py-2", {
							"cursor-not-allowed": isSubmitting,
						})}
						onClick={(e) => {
							if (!isEditing) setIsEditing(true);
							e.preventDefault();
						}}
						disabled={isSubmitting}
						type="button"
					>
						Edit
					</Button>
				)}
			</section>
		</form>
	);
};

export default Details;
