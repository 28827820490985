import { cn } from "@/lib/utils";
import { useState } from "react";
import BookingInfo from "./BookingInfo";
import RoomInfo from "./RoomInfo";
import Wrapper from "@/container/Wrapper";
import { useGetAdminBookingDetailsQuery } from "@/redux/features/api/adminEndpoints";

interface BookingOverview {
	status: "approved" | "pending";
	activetab: "booking" | "room";
	handleClose: () => void;
	id: string;
}

const BookingOverview = ({ status, activetab, id, handleClose }) => {
	const { data, isLoading } = useGetAdminBookingDetailsQuery({
		booking_id: parseInt(id),
	});
	const [activeTab, setActiveTab] = useState<"booking" | "room">(
		activetab ?? "room"
	);

	return (
		<div className="flex h-full w-full flex-col pb-4 ">
			<div className="mb-4 flex w-full cursor-pointer border-b border-b-[#9CA3AF] text-[15px] font-semibold text-[#858C95] ">
				<button
					onClick={() => setActiveTab("room")}
					className={cn("flex w-full justify-center py-2", {
						"border-b-2 border-b-primary text-primary":
							activeTab === "room",
					})}
				>
					<span>Space</span>
				</button>
				<button
					onClick={() => setActiveTab("booking")}
					className={cn("flex w-full justify-center py-2", {
						"border-b-2 border-b-primary text-primary":
							activeTab === "booking",
					})}
				>
					<span>Reservations</span>
				</button>
			</div>
			<div className="px-4">
				<Wrapper
					fetching={isLoading}
					spinClassName=" h-[40vh]"
					className=""
				>
					<>
						{activeTab === "room" && (
							<div>
								<RoomInfo room={data?.data.room} />
							</div>
						)}
						{activeTab === "booking" && (
							<div className="w-full">
								<BookingInfo
									data={data?.data}
									status={status}
									handleClose={handleClose}
								/>
							</div>
						)}
					</>
				</Wrapper>
			</div>
		</div>
	);
};

export default BookingOverview;
