import AmenityHolder from "@/components/common/AmenityHolder";
import CapacityHolder from "@/components/common/CapacityHolder";
import RoomSummary from "@/components/modals/admin/rooms/RoomSummary";
import Modal from "@/components/modals/Modal";
import { Button } from "@/components/ui/button";
import { useAppSelector } from "@/hooks/hooks";
import { cn } from "@/lib/utils";
import { Room } from "@/types/admin/booking";
import { TeamMemberRoles } from "@/types/team-member";
import { useState } from "react";

interface RoomListCardProps {
	onSelect: () => void;
	checked: boolean;
	room: Room;
}
const RoomListCard: React.FC<RoomListCardProps> = ({
	room,
	onSelect,
	checked,
}) => {
	const [showSummary, setShowSummary] = useState(false);
	const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");
	return (
		<>
			<div
				className={cn(
					"flex flex-col gap-y-2 rounded-xl bg-[#FBFBFB] p-1.5 drop-shadow-[0_0_6px_rgba(0,0,0,0.15)]",
					{
						"border border-primary": checked,
					}
				)}
				onClick={() => {
					if (
						userRole !== TeamMemberRoles.TeamMember &&
						userRole !== TeamMemberRoles.PartnerManager
					) {
						onSelect();
					}
				}}
			>
				<div className="flex flex-row gap-2 md:flex-col">
					{!room?.photos[0]?.image_url ? (
						<div className="flex h-[72px]  w-full flex-col justify-center rounded-tl-md rounded-tr-md bg-[#DCDCDC]  px-4 md:h-[119px]">
							<div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-[#C7C7C7] ">
								<p className="select-none text-lg font-semibold text-white">
									{room?.name.split(" ")[0][0].toUpperCase()}
								</p>
							</div>
						</div>
					) : (
						<img
							src={room?.photos[0]?.image_url ?? ".."}
							alt={room.photos[0]?.file_name}
							className=" h-[72px] w-[150px] rounded-tl-xl rounded-tr-xl bg-white object-cover md:h-[119px] md:w-full"
						/>
					)}

					<div className="flex flex-col items-center justify-between md:flex-row">
						<div className="">
							<span className="flex items-center gap-2 text-[10px]">
								<span className="text-[14px] font-semibold">
									{room?.name ?? "N/A"}
								</span>
							</span>
							{/* <span className="flex text-[10px] items-center gap-2"><IoLocation /><span>Building <b>A </b>| Floor <b>4</b></span></span> */}

							{room.address && (
								<span className="flex items-center gap-2 text-[10px] text-basecolor">
									<i className="mgc_location_3_line text-[12px] before:!text-[#323539]" />
									<span>{room.address ?? "N/A"}</span>
								</span>
							)}
						</div>
						<CapacityHolder capacity={room?.capacity ?? "N/A"} />
					</div>
				</div>

				<div className="flex flex-col gap-y-2 pl-2">
					<div className="space-y-1 pb-1">
						<h1 className="text-[8px] font-normal uppercase tracking-[2px] text-[#6B7280]">
							Amenites
						</h1>
						<div className="flex flex-wrap items-center gap-2 text-[10px]">
							{room?.amenities.length
								? room?.amenities?.map(
										(amenity: any, index: number) => (
											<AmenityHolder
												key={index}
												amenity={amenity.name}
												variant="list"
											/>
										)
									)
								: <p className="text-sm font-light">No amenities available</p> }
							{/* <AmenityHolder amenity="Tv/ Projector" variant="list" />
							<AmenityHolder amenity="Video Calls" variant="list" />
							<AmenityHolder amenity="Sound System" variant="list" /> */}
						</div>
					</div>
				</div>
				<div className="flex justify-end rounded-none">
					<Button
						className="ml-auto h-[32px] rounded-md px-12 py-1"
						variant="outlinecustom"
						onClick={() => setShowSummary(true)}
					>
						View Details
					</Button>
				</div>
			</div>
			<Modal
				dialogClassName="!rounded-[30px] max-w-xl  p-0 bg-white w-full"
				open={showSummary}
				setOpen={setShowSummary}
			>
				<RoomSummary
					handleClose={() => setShowSummary(false)}
					room={room}
				/>
			</Modal>
		</>
	);
};

export default RoomListCard;
