import CustomSelect from "@/components/common/CustomSelect";
import CustomShadSelect from "@/components/common/CustomShadSelect";
import TimeSlotPicker from "@/components/common/TimeSlotPicker";
import useCustomToast from "@/components/CustomToast";
import FormInput from "@/components/input/FormInput";
import { LoaderButton } from "@/components/Loader/LoaderButton";
import Modal from "@/components/modals/Modal";
import { DatePicker } from "@/components/ui/datepicker";
import { DialogClose } from "@/components/ui/dialog";
import { Switch } from "@/components/ui/switch";
import { useAppDispatch, useAppSelector } from "@/hooks/hooks";
import { cn } from "@/lib/utils";
import { useGetAdminBookingPartnerListQuery } from "@/redux/features/api/adminBookingEndpoints";
import { useBookRoomMutation } from "@/redux/features/apiSlice";
import { clearBooking, updateBooking } from "@/redux/slices/bookingSlice";
import { RepeatTimeOptions } from "@/services/constants";
import {
	formatTime,
	generateBookingDurationTime,
	generateBookingEndTimeOptions,
	generateDurationString,
} from "@/services/utilities";
import { Room } from "@/types/admin/booking";
import { DateTime } from "luxon";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { FaRegClock } from "react-icons/fa6";
import AttendeeSelector from "./AttendeeSelector";
import RecurrenceForm from "./RecurrenceForm";
import RecurrenceSummary from "./RecurrenceSummary";

interface BookingDetailsProps {
	room: Room;
	timeslots: string[];
	isLoading: boolean;
	getAdminRoomTimeSlotsIsLoading: boolean;
	defaultDate: string;
	setDefaultDate: Dispatch<SetStateAction<string>>;
	setOverviewData: any;
	bookingData: any;
	setActiveTab: Dispatch<SetStateAction<"reservation" | "room" | "overview">>;
}

const BookingDetails = ({
	room,
	timeslots,
	getAdminRoomTimeSlotsIsLoading,
	defaultDate,
	setDefaultDate,
	// getTimeSlotsRefetch,
	setOverviewData,
	setActiveTab,
}: BookingDetailsProps) => {
	const [selectedAttendees, setSelectedAttendees] = useState([]);
	const customToast = useCustomToast();
	const [recurrenceData, setReccurenceData] = useState({});
	const [selectedRole, setSelectedRole] = useState<{
		label: string;
		value: string;
	}>({ label: "", value: "" });
	const [allAttendees, setAllAttendees] = useState<any>([]);
	const [hosts, setHosts] = useState<any>();
	const [selectedHost, setSelectedHost] = useState<any>();
	const [partners, setPartners] = useState([]);
	const [internal, setInternal] = useState<any>([]);
	const [repeatSelection, setRepeatSelection] = useState<{
		label: string;
		value: string;
	}>({ label: "Does not repeat", value: "does not repeat" });
	const [showReccurringModal, setShowReccurringModal] = useState(false);

	const dispatch = useAppDispatch();
	const user = useAppSelector((state) => state.user);
	const toast = useCustomToast();

	const { duration, description, title, isAllDay, startTime, date, endTime } =
		useAppSelector((state) => state.bookings.booking);

	const {
		data: partnerData,
		isLoading: isPartnerListLoading,
		isSuccess: isPartnerListSuccess,
	} = useGetAdminBookingPartnerListQuery();

	const [
		bookRoom,
		{
			data: bookingData,
			isLoading: isBookingLoading,
			isSuccess: isBookingRoomSuccess,
			isError: isBookingRoomError,
			error: bookingRoomError,
		},
	] = useBookRoomMutation();

	const startTimeOptions = useMemo(() => {
		return timeslots?.map((timeslot) => ({
			label: formatTime(timeslot),
			value: timeslot,
		}));
	}, [timeslots]);

	const handleSaveRecurrenceData = (data: any) => {
		setReccurenceData(data);
		setShowReccurringModal(false);
	};
	const handleSubmit = async (formData) => {
		const data = {
			room_id: formData.room_id,
			title: formData.title,
			date: formData.date,
			...(formData.description && { description: formData.description }),
			is_allday: formData.isAllDay,
			start_time: formData.startTime,
			duration: formData.duration,
			...(formData.member_emails.length && {
				member_emails: formData.member_emails,
			}),
			host_id: formData.host_id,
			is_recurring: repeatSelection.value == "does not repeat" ? 0 : 1,
			...recurrenceData,
		};
		await bookRoom({
			business_or_company_id: formData.partner_company_id,
			data,
		});
	};

	const {
		register,
		handleSubmit: handleFormSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: {
			title: title,
			description: description,
		},
	});
	const onSubmit = () => {
		if (!startTime || !endTime) {
			return toast("Please select a valid start and end time", {
				id: "time-validation",
				type: "error",
				duration: 1500,
			});
		} else if (!selectedRole.value) {
			return toast("Please select a business or partner name", {
				id: "company-validation",
				type: "error",
				duration: 1500,
			});
		}

		const submittedData = {
			title: title,
			room_id: room.id,
			partner_company_id: selectedRole?.value,
			date: defaultDate,
			description,
			host_id: selectedHost?.value,
			duration,
			isAllDay: isAllDay ? 1 : 0,
			startTime,
			member_emails: selectedAttendees,
		};

		handleSubmit(submittedData);
	};

	useEffect(() => {
		// Reset hosts and selected attendees when selectedRole changes
		setHosts([]);
		setSelectedAttendees([]);
		setSelectedHost("");

		// Proceed only if partnerData is available and valid
		if (isPartnerListSuccess && partnerData) {
			if (selectedRole?.value !== internal?.value) {
				const filteredPartner = partnerData.data.partners.find(
					(partner) => partner.id === parseInt(selectedRole.value)
				);
				const filteredHosts = filteredPartner?.admins ?? [];
				setHosts(
					filteredHosts.map((host) => ({
						label: host.name ?? host.email,
						value: host.id,
					}))
				);
				setAllAttendees(filteredPartner?.member_emails ?? []);
			} else if (selectedRole?.value === internal?.value) {
				const internalAdmins = partnerData.data.internal?.admins ?? [];
				const businessMembers =
					partnerData.data.internal?.member_emails ?? [];
				setHosts(
					internalAdmins.map((host) => ({
						label: host.name ?? host.email,
						value: host.id,
					}))
				);
				setAllAttendees(businessMembers);
			}
		}
	}, [selectedRole, isPartnerListSuccess, partnerData, internal]);

	useEffect(() => {
		if (isPartnerListSuccess && partnerData) {
			const list = partnerData.data.partners.map((data) => ({
				label: data.name,
				value: data.id.toString(),
			}));
			const internalList = {
				label: partnerData.data.internal?.name,
				value: partnerData.data.internal?.id?.toString(),
			};
			setInternal(internalList);
			setPartners(list);
		}
	}, [, isPartnerListSuccess, isPartnerListLoading, partnerData]);

	useEffect(() => {
		if (repeatSelection.value !== "does not repeat") {
			setShowReccurringModal(true);
		}
	}, [repeatSelection]);

	useEffect(() => {
		if (isBookingRoomSuccess) {
			customToast("Space reserved successfully 🎉", {
				id: "book-room",
				type: "success",
			});
			setTimeout(() => {
				dispatch(clearBooking());
				localStorage.removeItem("recurringData");
				setOverviewData(bookingData);
				setActiveTab("overview");
			}, 2000);
		} else if (isBookingRoomError) {
			if (bookingRoomError) {
				const errorMessage =
					(
						bookingRoomError as {
							data?: { message?: string };
						}
					)?.data?.message || "An error occurred";
				return toast(errorMessage || "unknown error", {
					id: "book-room",
					type: "error",
				});
			}
		}
	}, [isBookingRoomSuccess, isBookingRoomError, bookingRoomError]);

	
	useEffect(() => {
		if (timeslots?.length > 0 && startTimeOptions.length > 0) {
			const newStartTime = startTimeOptions[0]?.value;
			const newEndTime = startTimeOptions[1]?.value;
			if (!startTime && newStartTime) {
				dispatch(
					updateBooking({
						startTime: newStartTime,
					})
				);
			}
			if (!endTime && newEndTime) {
				dispatch(
					updateBooking({
						endTime: newEndTime,
					})
				);
			}
		}
	}, [timeslots, startTimeOptions]);

	useEffect(() => {
		if (startTime && endTime) {
			const newDuration = generateBookingDurationTime(startTime, endTime);
			dispatch(
				updateBooking({
					duration: newDuration,
				})
			);
		}
	}, [startTime, endTime]);

	return (
		<div
			className={cn("relative", {
				" opacity-90": repeatSelection.value == "custom",
			})}
		>
			<div className="flex w-full flex-col gap-y-3 ">
				<div>
					<FormInput
						inputType="text"
						value={room.name}
						disabled={true}
					/>
				</div>

				<form
					className="flex flex-col gap-y-2"
					onSubmit={handleFormSubmit(onSubmit)}
				>
					<div>
						<FormInput
							inputType="text"
							{...register("title", {
								required: "Meeting name is required",
							})}
							placeholder="Meeting Name"
							error={errors.title}
							onChange={(e) => {
								dispatch(
									updateBooking({
										title: e.target.value,
									})
								);
							}}
						/>
					</div>

					<div className="flex flex-col gap-y-2">
						<div className="flex flex-col gap-2">
							<div className="flex flex-wrap gap-2">
								<DatePicker
									title="Select Date"
									className="max-w-[170px] flex-1"
									date={
										date
											? DateTime.fromISO(date).toJSDate()
											: DateTime.fromISO(
													defaultDate
												).toJSDate()
									}
									setDate={(date) => {
										if (date) {
											const luxonDate =
												DateTime.fromJSDate(date);
											dispatch(
												updateBooking({
													date:
														luxonDate.toISODate() ??
														"",
												})
											);
											setDefaultDate(
												luxonDate.toISODate() ?? ""
											);
										}
									}}
								/>

								<CustomShadSelect
									options={startTimeOptions}
									value={startTime}
									className="w-full max-w-[185px] text-[14px]"
									placeholder={
										<div className="flex items-center gap-3 text-[14px]">
											<FaRegClock /> Start Time
										</div>
									}
									onChange={(newValue: any) => {
										dispatch(
											updateBooking({
												startTime: newValue,
											})
										);
									}}
								/>
								<div className="flex items-center gap-x-2">
									<Switch
										id="all-day"
										checked={isAllDay}
										onCheckedChange={() =>
											dispatch(
												updateBooking({
													isAllDay: !isAllDay,
												})
											)
										}
									/>
									<p>All day</p>
								</div>
							</div>
							<div className="flex flex-wrap gap-2">
								<CustomSelect
									placeholder="Does not repeat"
									className="w-full max-w-[170px] text-[14px]"
									options={RepeatTimeOptions}
									value={repeatSelection}
									onChange={(selectedOption: any) => {
										if (
											selectedOption.value ==
											repeatSelection.value
										) {
											setShowReccurringModal(true);
										}
										setRepeatSelection(selectedOption);
									}}
								/>
								<CustomShadSelect
									options={generateBookingEndTimeOptions(
										timeslots,
										startTime
									)}
									value={endTime}
									className="w-full max-w-[185px] text-[14px]"
									placeholder={
										<div className="flex items-center gap-3 text-[14px]">
											<FaRegClock /> End Time
										</div>
									}
									onChange={(newValue) => {
										dispatch(
											updateBooking({
												endTime: newValue,
											})
										);
									}}
								/>
								<p className="px-2 my-auto text-sm text-basecolor">{generateDurationString(duration)}</p>
								{/* <CustomSelect
									value={{
										label: generateDurationString(duration),
										value: generateDurationString(duration),
									}}
									isDisabled={true}
									className="w-36 max-w-[185px] text-[14px]"
									placeholder={
										<div className="flex items-center gap-3 text-[14px]">
											<FaRegClock /> Duration
										</div>
									}
								/> */}
							</div>
						</div>

						{repeatSelection.value != "does not repeat" && (
							<RecurrenceSummary />
						)}
						<TimeSlotPicker
							selectedTimeSlot={startTime}
							selectTimeSlot={(time) =>
								dispatch(
									updateBooking({
										startTime: time,
									})
								)
							}
							timeslots={timeslots}
							slotAmount={6}
							isLoading={getAdminRoomTimeSlotsIsLoading}
						/>
					</div>
					{/* Partner Section  */}
					<div className="flex w-full flex-col gap-4 md:flex-row">
						<div className="flex flex-[0.6] flex-col gap-y-2">
							<h1>Host</h1>
							<CustomSelect
								options={[internal, ...(partners ?? [])]}
								className="text-[14px]"
								placeholder="Business Name / Partner Name"
								onChange={(newValue: any) => {
									setSelectedRole(newValue);
								}}
								height="40px"
							/>
							{selectedRole?.value !== "partner" && (
								<CustomSelect
									value={selectedHost}
									options={hosts ?? []}
									className=" text-[14px]"
									placeholder="Host Name"
									onChange={(newValue: any) => {
										setSelectedHost(newValue);
									}}
									height="40px"
								/>
							)}

							<div className="flex-1 md:flex-[0.6]">
								<FormInput
									rows={6}
									textarea={true}
									className="hidden w-full rounded-md text-[14px] md:block"
									error={errors.description}
									placeholder="Add Description"
									onChange={(e) => {
										dispatch(
											updateBooking({
												description: e.target.value,
											})
										);
									}}
								/>
							</div>
						</div>

						{/* Attendees list */}
						<div className="flex-1 md:flex-[0.4]">
							<AttendeeSelector
								selectedAttendees={selectedAttendees}
								setSelectedAttendees={setSelectedAttendees}
								allAttendees={allAttendees}
								user={user}
								isLoading={false}
								isError={false}
								showHost={false}
							/>
						</div>
					</div>

					<div className="mt-4 px-1">
						<div className="flex w-full gap-2">
							<DialogClose className="w-full rounded-md border border-primary bg-transparent px-4 py-2 text-primary">
								Cancel
							</DialogClose>

							<LoaderButton
								className="w-full"
								variant="default"
								type="submit"
								disabled={isBookingLoading}
								loading={isBookingLoading}
								loaderSize={20}
							>
								Confirm Reservation
							</LoaderButton>
						</div>
					</div>
				</form>
			</div>
			<Modal
				dialogClassName=" p-0 max-w-xl "
				onInteractOutside={(e) => {
					e.preventDefault();
				}}
				showDeleteIcon={false}
				open={showReccurringModal}
				setOpen={setShowReccurringModal}
			>
				<RecurrenceForm
					defaultType={repeatSelection}
					handleClose={() => {
						setReccurenceData({});
						setRepeatSelection({
							label: "Does not repeat",
							value: "does not repeat",
						});
						setShowReccurringModal(false);
					}}
					handleSaveRecurrenceData={handleSaveRecurrenceData}
				/>
			</Modal>
		</div>
	);
};

export default BookingDetails;
