import { SummaryPlaceholder } from "@/components/modals/admin/LocationSummary";
import NameLetter from "@/components/NameLetter";
import { Button } from "@/components/ui/button";
import { DialogClose } from "@/components/ui/dialog";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { useAppSelector } from "@/hooks/hooks";
import { TeamMemberRoles } from "@/types/team-member";

const CompanySummary = ({
	data,
	setShowEditPartner,
}: {
	data?: any;
	setShowEditPartner: any;
}) => {
	const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");
	return (
		<TooltipProvider>
			<div className="flex w-full flex-col items-start gap-2">
				<div className="flex  w-full flex-wrap items-center justify-between gap-2 rounded-tl-[30px] rounded-tr-[30px] bg-white px-7 py-4">
					<div className="flex items-center gap-2">
						{/* <div className="flex h-[72px] w-full flex-col justify-center bg-[#DCDCDC] px-4 md:h-[119px] md:rounded-t-md mmd:max-w-[150px] mmd:rounded-md">
							
							<div className="flex h-[41px] w-[41px] items-center justify-center rounded-full bg-[#C7C7C7] md:h-[50px] md:w-[50px]">
								<p className="select-none text-lg font-semibold text-white">
									{data?.name.split(" ")[0][0].toUpperCase()}
								</p>
							</div>
						</div> */}
						<NameLetter
							name={data?.name.split(" ")[0][0].toUpperCase()}
						/>

						<div className="flex flex-col space-y-2">
							{/* <div className="flex flex-col"> */}
							<div>
								<h1 className="font-bold text-basecolor">
									{data?.name ?? "N/A"}
								</h1>
								{data?.address && (
									<div className="flex items-center gap-2 text-[10px] font-light text-basecolor">
										<i className="mgc_location_3_line text-[12px] before:!text-[#323539]" />
										<p>{data?.address ?? "N/A"}</p>
									</div>
								)}
							</div>
							<div className="flex items-center space-x-1 sm:hidden">
								<Tooltip delayDuration={0}>
									<TooltipTrigger asChild>
										<a
											href={`${data?.super_admin?.email ? "mailto:" + data?.super_admin?.email : null}`}
											className="flex max-h-[20px] max-w-[20px] items-center justify-center rounded-full bg-[#F9F9F9] px-[3.75px] py-[5px] text-sm text-primary"
										>
											<i className="mgc_mail_line text-[12px] before:!text-primary xs:text-[14px]" />
										</a>
									</TooltipTrigger>
									<TooltipContent side="top" sideOffset={4}>
										{data?.super_admin?.email}
									</TooltipContent>
								</Tooltip>
								{data?.super_admin?.phone_number && (
									<Tooltip delayDuration={0}>
										<TooltipTrigger asChild>
											<a
												href={`${data?.email ? "tel" + data?.super_admin?.phone_number : null}`}
												className="flex max-h-[20px] max-w-[20px] items-center justify-center rounded-full bg-[#F9F9F9] p-2 px-[3.75px] py-[5px] text-sm text-primary"
											>
												<i className="mgc_phone_line text-[12px] before:!text-primary xs:text-[14px]" />
											</a>
										</TooltipTrigger>
										<TooltipContent
											side="top"
											sideOffset={4}
										>
											{data?.super_admin?.phone_number}
										</TooltipContent>
									</Tooltip>
								)}
							</div>
						</div>
					</div>
					<div className="flex space-x-2 pr-4">
						<div className="flex flex-col space-y-2">
							<div className="flex items-center gap-1 text-xs">
								<i className="mgc_phone_line text-[12px] before:!text-[#323539]" />
								<div className="flex items-center space-x-1">
									<p className="text-[#6B7280]">Phone:</p>
									<span>
										{data?.super_admin.phone_number ??
											"N/A"}
									</span>
								</div>
							</div>
							<div className="flex items-center gap-1 text-xs">
								<i className="mgc_user_security_line text-[12px] before:!text-[#323539]" />
								<div className="flex items-center gap-1 text-xs">
									<div className="flex items-center space-x-1">
										<p className="text-[#6B7280]">Admin:</p>
										<span>
											{data?.super_admin.name ?? "N/A"}
										</span>
									</div>
								</div>
							</div>
						</div>
						<div className="flex items-center space-x-2 msm:hidden">
							<Tooltip delayDuration={0}>
								<TooltipTrigger asChild>
									<a
										href={`${data?.super_admin.email ? "mailto:" + data?.super_admin.email : null}`}
										className="flex h-fit w-fit items-center justify-center rounded-full bg-[#F9F9F9] p-2 text-sm text-primary"
									>
										<i className="mgc_mail_line text-[12px] before:!text-primary xs:text-[14px]" />
									</a>
								</TooltipTrigger>
								<TooltipContent side="top" sideOffset={4}>
									{data?.super_admin.email}
								</TooltipContent>
							</Tooltip>
							{data?.super_admin.phone_number && (
								<Tooltip delayDuration={0}>
									<TooltipTrigger asChild>
										<a
											href={`${data?.super_admin.phone_number ? "tel" + data?.super_admin.phone_number : null}`}
											className="flex h-fit w-fit items-center justify-center rounded-full bg-[#F9F9F9] p-2 text-sm text-primary"
										>
											<i className="mgc_phone_line text-[12px] before:!text-primary" />
										</a>
									</TooltipTrigger>
									<TooltipContent side="top" sideOffset={4}>
										{data?.super_admin.phone_number}
									</TooltipContent>
								</Tooltip>
							)}
						</div>
					</div>
				</div>
				<div className="flex w-full flex-col gap-y-2 rounded-bl-[30px] rounded-br-[30px] bg-[#F5F5F5] px-7 pb-6 pt-4">
					<div className="flex flex-col gap-y-4 py-3">
						<div className="flex flex-col gap-y-2.5">
							<h1 className="text-xs font-semibold">General</h1>
							<div className="flex gap-3 ">
								<SummaryPlaceholder
									title="Admin"
									icon="mgc_user_security_line"
									value={data?.total_admins ?? "N/A"}
								/>
								<SummaryPlaceholder
									title="Guests"
									icon="mgc_group_2_line"
									value={data?.total_guests ?? "N/A"}
								/>
							</div>
						</div>
						<div className="flex flex-col gap-y-2.5">
							<h1 className="text-xs font-semibold">
								Reservations
							</h1>
							<div className="flex flex-wrap gap-1">
								<SummaryPlaceholder
									title="Reservation"
									value={data?.total_bookings ?? "0"}
								/>
								<SummaryPlaceholder
									title="Cancelled"
									value={
										data?.total_cancelled_bookings ?? "0"
									}
								/>
								<SummaryPlaceholder
									title="Space Used"
									value={data?.total_rooms_used ?? "0"}
								/>
								<SummaryPlaceholder
									title="Rescheduled"
									value={
										data?.total_rescheduled_bookings ?? "0"
									}
								/>
							</div>
						</div>
						{data.address && (
							<div className="flex flex-col gap-y-2.5">
								<h1 className="text-xs font-semibold">
									Location Access
								</h1>
								<div className="flex gap-3 ">
									<p className="text-xs">{data.address}</p>
								</div>
							</div>
						)}
					</div>
					<div className="flex w-full items-center justify-end gap-x-1.5">
						<DialogClose className="h-9 w-fit rounded-md border border-primary bg-transparent px-[30px] text-primary">
							Close
						</DialogClose>
						{userRole !== TeamMemberRoles.PartnerAdmin && (
							<Button
								className="h-9 rounded-md border border-primary bg-primary px-6 text-white"
								onClick={() => setShowEditPartner(true)}
							>
								Edit
							</Button>
						)}
					</div>
				</div>
			</div>
		</TooltipProvider>
	);
};

export default CompanySummary;