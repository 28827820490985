import { createSlice } from "@reduxjs/toolkit";

interface EventsState {
	searchQuery: string;
	selectedLocation: string;
	selectedPartner: string;
	selectedRooms: { label: string; value: string }[];
	date: Date | string;
}

const initialState: EventsState = {
	searchQuery: "",
	selectedLocation: "all",
	selectedPartner: "all",
	selectedRooms: [],
	date: new Date().toISOString(),
};

const eventsSlice = createSlice({
	name: "events",
	initialState,
	reducers: {
		setSearchQuery: (state, action) => {
			state.searchQuery = action.payload;
		},
		setSelectedLocation: (state, action) => {
			state.selectedLocation = action.payload;
		},
		setSelectedPartner: (state, action) => {
			state.selectedPartner = action.payload;
		},
		setSelectedRooms: (state, action) => {
			state.selectedRooms = action.payload;
		},
		setDate: (state, action) => {
			state.date = action.payload;
		},
		resetBookingFilters: () => initialState,
	},
});

export const {
	setSearchQuery,
	setSelectedLocation,
	setSelectedPartner,
	setSelectedRooms,
	setDate,
	resetBookingFilters,
} = eventsSlice.actions;
export default eventsSlice.reducer;
