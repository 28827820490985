import Loader from "@/components/Loader/Loader";
import { cn } from "@/lib/utils";
// import { SyncOutlined } from "@ant-design/icons";
// import Spin from "antd/es/spin";

interface WrapperProps {
	className: string;
	spinClassName?: string;
	chidrenClassName?: string;
	fetching: boolean;
	working?: boolean;
	children: React.ReactNode;
}

// const antIcon = <SyncOutlined style={{ fontSize: 24 }} spin />;

const Wrapper = ({
	className,
	spinClassName,
	fetching,
	chidrenClassName,
	children,
}: WrapperProps) => {
	return (
		<div className={className}>
			{fetching ? (
				<div className={cn("mt-4", spinClassName)}>
					<Loader size={24} />
				</div>
			) : (
				<div className={cn("", chidrenClassName)}>{children}</div>
			)}
		</div>
	);
};

export default Wrapper;
