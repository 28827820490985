import { useAppSelector } from "@/hooks/hooks";
import { useGetLocationAnalyticsQuery } from "@/redux/features/api/locationEndpoints";
import BookedByPartnersBarChart from "./BookedByPartnersBarChart";

type PartnerData = {
	partner_name: string;
	booked: number;
	cancelled: number;
};

const BookedByPartners: React.FC = () => {
	const businessId =
		useAppSelector((state) => state.user.user?.business_id) ?? 0;
	const queryData = useAppSelector((state) => state.analytics.query_data);

	const getAdminLocationsQuery = useGetLocationAnalyticsQuery({
		user_id: businessId,
		query_data: queryData,
	});

	const createDoubleBarData = (
		partner_name: string,
		booked: number,
		cancelled: number
	) => ({
		partner_name,
		booked,
		cancelled,
	});

	const bookedDataByPartnersData = getAdminLocationsQuery?.data
		? getAdminLocationsQuery?.data?.data.statics.booked_by_partners.map(
				(item) => {
					const partnerData: PartnerData = {
						partner_name: item.partner_name,
						booked: +item.booked,
						cancelled: +item.cancelled,
					};

					return createDoubleBarData(
						partnerData.partner_name,
						partnerData.booked,
						partnerData.cancelled
					);
				}
			)
		: [];

	return (
		<div className="flex w-full flex-col space-y-5 rounded-lg bg-white p-8 pb-[22px]">
			<div className="pb-4">
				<h3 className="font-manrope text-lg font-bold leading-[16px] tracking-[0.5px] text-[#454D56]">
					Reservations by Partners
				</h3>
				<p className="text-xs leading-[120%] tracking-[0.41px] text-[#596574]">
					Total:{" "}
					<span className="text-base font-semibold">
						{bookedDataByPartnersData.reduce(
							(acc, item) => acc + item.booked + item.cancelled,
							0
						)}
					</span>
				</p>
			</div>
			<div className="flex min-h-[275px] w-full flex-1 flex-col">
				<BookedByPartnersBarChart data={bookedDataByPartnersData} />
			</div>
		</div>
	);
};

export default BookedByPartners;
