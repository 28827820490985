import CustomMultiSelect from "@/components/common/CustomMultiSelect";
import CustomShadSelect from "@/components/common/CustomShadSelect";
import Search from "@/components/input/Search";
import { Button } from "@/components/ui/button";
import { useAppDispatch, useAppSelector } from "@/hooks/hooks";
import {
	setSearchQuery,
	setSelectedLocation,
	setSelectedPartner,
	setSelectedRooms,
} from "@/redux/slices/eventSlice";
import { extractUniqueObjectValues } from "@/services/utilities";
import { Booking } from "@/types/admin/booking";
import { LuRotateCcw, LuTrash2 } from "react-icons/lu";
import AutoApprove from "./AutoApprove";

interface ManagementHeaderProps {
	bookingData: Booking[];
}

const ManagementHeader: React.FC<ManagementHeaderProps> = ({ bookingData }) => {
	const dispatch = useAppDispatch();
	const { searchQuery, selectedLocation, selectedPartner, selectedRooms } =
		useAppSelector((state) => state.events);

	const locations = extractUniqueObjectValues(bookingData, {
		all: "All Locations",
		keyName: "location",
		valueKeyName: "id",
		labelKeyName: "name",
	});

	const rooms = extractUniqueObjectValues(
		bookingData,
		{
			all: "All Rooms",
			keyName: "room",
			valueKeyName: "id",
			labelKeyName: "name",
		},
		false
	);

	const partners = extractUniqueObjectValues(bookingData, {
		all: "All Partners",
		keyName: "company",
		valueKeyName: "id",
		labelKeyName: "name",
	});

	const handleSearchQueryChange = (searchQuery) => {
		dispatch(setSearchQuery(searchQuery.value));
	};

	const handleLocationChange = (selectedLocation: string) => {
		dispatch(setSelectedLocation(selectedLocation));
	};

	const handlePartnerChange = (selectedPartner: string) => {
		dispatch(setSelectedPartner(selectedPartner));
	};

	const handleRoomsChange = (selectedRooms: unknown) => {
		dispatch(setSelectedRooms(selectedRooms));
	};

	const isOptionDisabled = (option) => {
		return selectedRooms.length >= 4 && !selectedRooms.includes(option);
	};

	return (
		<div className="flex items-center justify-between gap-x-4 text-sm">
			<div className="flex items-center gap-3">
				<AutoApprove />
			</div>
			<div className="flex items-center gap-3">
				<div className="relative">
					<Search
						placeholder="Search by name"
						value={searchQuery}
						onChange={(e) =>
							handleSearchQueryChange(e.target.value)
						}
					/>
				</div>
				<div className="hidden flex-col  items-center gap-2.5  md:flex md:flex-row">
					<CustomShadSelect
						placeholder="Location"
						className="xl:!w-[150px]"
						value={selectedLocation}
						options={locations}
						onChange={handleLocationChange}
					/>
					<CustomMultiSelect
						isSearchable
						isMulti
						closeMenuOnSelect={false}
						value={selectedRooms}
						placeholder="Spaces"
						className="xl:w-[200px]"
						options={rooms}
						isOptionDisabled={isOptionDisabled}
						onChange={handleRoomsChange}
					/>
					<CustomShadSelect
						placeholder="Partners"
						className="xl:!w-[150px]"
						value={selectedPartner}
						options={partners}
						onChange={handlePartnerChange}
					/>
				</div>

				<Button variant="outline" onClick={() => location.reload()}>
					<LuRotateCcw size={18} />
				</Button>
				<Button variant="outline">
					<LuTrash2 size={18} className=" text-red-500" />
				</Button>
			</div>
		</div>
	);
};

export default ManagementHeader;
