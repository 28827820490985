import DefaultOperatingHoursModal from "@/components/admin/location/DefaultOperatingHoursModal";
import useCustomToast from "@/components/CustomToast";
import { LoaderButton } from "@/components/Loader/LoaderButton";
import DayTimeslots from "@/components/modals/admin/locations/settings/DayTimeslots";
import { useAppSelector } from "@/hooks/hooks";
import { useAddOperatingHoursMutation } from "@/redux/features/api/locationEndpoints";
import { OperatingHour } from "@/redux/slices/locationSlice";
import { defaultTimeSlots } from "@/services/constants";
import { convertSchedule, isTimeOverlapping } from "@/services/utilities";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";

const LocationOperationalHours = () => {
	const { location_id } = useParams();
	const user = useAppSelector((state) => state.user);
	const customToast = useCustomToast();
	const navigate = useNavigate();
	const operatingHours = useAppSelector(
		(state) => state.location.operatingHours
	);

	const [slots, setSlots] = useState<OperatingHour[]>(
		location_id ? operatingHours : defaultTimeSlots
	);

	const [addOperatingHours, { data: responseData, isSuccess, isLoading }] =
		useAddOperatingHoursMutation();

	const { handleSubmit } = useForm<OperatingHour[]>();

	const onSubmit: SubmitHandler<OperatingHour[]> = async () => {
		try {
			if (isTimeOverlapping(slots)) return;
			await addOperatingHours({
				id: user.user?.business_id ?? 0,
				location_id: parseInt(location_id ?? ""),
				data: {
					update_all_rooms: 1,
					rooms_to_update: [],
					data: convertSchedule(slots),
				},
			}).unwrap();
		} catch (error) {
			customToast("An error occured kindly try again later", {
				id: "operating-hours",
				type: "error",
			});
			// setError("root", {
			// 	message:
			// 		"An error occured kindly try again later",
			// });
		}
	};

	const onSuccess = () => {
		customToast("Operating hours added successfully", {
			id: "operating-hours",
			type: "success",
		});
		setTimeout(
			() => navigate("/admin/location-management/" + location_id),
			1000
		);

		// console.log(responseData);
	};

	useEffect(() => {
		if (isSuccess) onSuccess();
	}, [isSuccess, responseData]);

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<h1 className="py-3 text-xl font-semibold text-basecolor">
					Operating hours
				</h1>
				<div className="flex flex-col gap-y-3 overflow-scroll">
					{slots.map((slot, i) => (
						<DayTimeslots
							{...slot}
							key={i}
							index={i}
							slots={slots}
							shouldShowDay
							shouldShowPlus
							setSlots={setSlots}
						/>
					))}
				</div>
				<div className="mt-8 flex w-full justify-end">
					<div className="flex gap-2">
						<LoaderButton
							variant="default"
							loading={isLoading}
							disabled={isLoading}
							loaderSize={16}
							type="submit"
						>
							Add
						</LoaderButton>
					</div>
				</div>
			</form>
			<DefaultOperatingHoursModal setDefaultSlots={setSlots} />
		</>
	);
};

export default LocationOperationalHours;
