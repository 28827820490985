import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { API_URI } from "./constants";
import { BaseQueryFn } from "@reduxjs/toolkit/query";

// export const client: AxiosInstance = axios.create({
// 	baseURL: `${API_URI}`,
// 	withCredentials: true,
// });

export const axiosBaseQuery =
	(
		{ baseUrl }: { baseUrl: string } = { baseUrl: `${API_URI}` }
	): BaseQueryFn<
		{
			url: string;
			method?: AxiosRequestConfig["method"];
			data?: AxiosRequestConfig["data"];
			params?: AxiosRequestConfig["params"];
			headers?: AxiosRequestConfig["headers"];
		},
		unknown,
		unknown
	> =>
	async ({ url, method, data, params, headers }) => {
		try {
			const result = await axios({
				url: baseUrl + url,
				method,
				data,
				params,
				headers,
			});
			return { data: result.data };
		} catch (axiosError) {
			const err = axiosError as AxiosError;
			return {
				error: {
					status: err.response?.status,
					data: err.response?.data || err.message,
				},
			};
		}
	};
