import { cn } from "@/lib/utils";
import { LuChrome, LuSofa } from "react-icons/lu";
import { MdOutlineVideocam } from "react-icons/md";
import { PiMonitorBold, PiSpeakerHifiBold } from "react-icons/pi";

interface AmenityHolderProps {
	amenity: string;
	variant?: "overview" | "list";
}
const AmenityHolder = ({ amenity, variant }: AmenityHolderProps) => {
	return (
		<div>
			<span
				className={cn("flex items-center gap-1 text-[#959595] ", {
					"rounded-full bg-white px-2 py-1 shadow-sm": variant === "overview",
				})}
			>
				{amenity === "Tv/ Projector" && <PiMonitorBold />}
				{amenity.toLowerCase() === "tv" && <PiMonitorBold />}
				{amenity === "Sofa" && <LuSofa />}
				{amenity === "Sound System" && <PiSpeakerHifiBold />}
				{amenity.toLowerCase() === "speaker" && <PiSpeakerHifiBold />}
				{amenity === "Google Chromecast" && <LuChrome />}
				{amenity === "Video Calls" && <MdOutlineVideocam />}
				<p
					className={cn("text-[14px] font-normal text-[#6B7280]", {
						"text-[10px]": variant === "list",
					})}
				>
					{amenity}
				</p>
				{/* text-[#959595] */}
			</span>
		</div>
	);
};

export default AmenityHolder;
