import Avatar from "@/components/common/Avatar";
import CustomShadSelect from "@/components/common/CustomShadSelect";
import useCustomToast from "@/components/CustomToast";
import { LoaderButton } from "@/components/Loader/LoaderButton";
import { DialogClose } from "@/components/ui/dialog";
import { cn } from "@/lib/utils";
import { countryCodes } from "@/services/constants";
import { toTitleCase } from "@/services/utilities";
import {
	PartnerAddMemberParams,
	PartnerAddMemberSchema,
} from "@/types/team-member";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { IoIosSave } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { LuPhone } from "react-icons/lu";
import { MdOutlineMail, MdOutlineModeEdit } from "react-icons/md";
import { TbTrash } from "react-icons/tb";
import { useParams } from "react-router";
import FormInput from "../../../input/FormInput";
import { Button } from "../../../ui/button";

const AddUser = ({ addNewMember, handleClose }) => {
	const customToast = useCustomToast();
	const [countryCode, setCountryCode] = useState("+1");
	// const

	const {
		register,
		handleSubmit,
		setValue,

		watch,
		formState: { errors },
	} = useForm<PartnerAddMemberParams>({
		resolver: zodResolver(PartnerAddMemberSchema),
	});

	const { partner_id: partner_company_id } = useParams();

	const [addNewMemberMutate, { isError, isSuccess, isLoading, error }] =
		addNewMember;

	const onSubmit: SubmitHandler<PartnerAddMemberParams> = async (data) => {
		customToast("Adding " + toTitleCase(data.role), {
			id: "member-added",
			type: "loading",
		});
		const submittedFormData = {
			name: data.name ?? "".trim(),
			email: data.email,
			phone_number: data.phone_number
				? countryCode + data.phone_number.slice(-10)
				: undefined,
			role: data.role,
		};
		try {
			await addNewMemberMutate({
				partner_company_id,
				data: submittedFormData,
			});
			customToast("Member added successfully", {
				id: "member-added",
				type: "success",
			});
			handleClose();
		} catch (error) {
			customToast("An error occured kindly try again later", {
				id: "member-added",
				type: "error",
			});
		}
	};

	useEffect(() => {
		if (!isSuccess) return;

		if (isError && error) {
			const errorData = error as { data?: ErrorResponse };

			if (errorData.data && errorData.data.errors) {
				const errorMessages = Object.values(errorData.data.errors)
					.flat()
					.filter(
						(message): message is string =>
							typeof message === "string"
					);

				errorMessages.forEach((message) => {
					customToast(message, {
						id: "member-added",
						type: "error",
					});
				});
			} else {
				customToast("An error occured kindly try again later", {
					id: "member-added",
					type: "error",
				});
			}
		}
	}, [isSuccess, isError, error, handleClose]);

	// The cancel button has not been activated

	return (
		<form
			className="mt-4 flex w-full flex-col gap-y-3"
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className="flex gap-2">
				<div className="flex-1">
					<FormInput
						inputType="text"
						placeholder="Email Address"
						register={{ ...register("email") }}
						className="h-[46px]"
						autoFocus={false}
						autoComplete={false}
						error={errors.email}
					/>
				</div>
				<div className="min-w-[160px] flex-1">
					<CustomShadSelect
						className="h-[46px] max-w-full flex-1 text-[14px]"
						placeholder="Select Role"
						options={[
							{ label: "Admin", value: "admin" },
							{ label: "Guest", value: "guest" },
						]}
						onChange={(value) => {
							const role = value as "admin" | "guest";
							setValue("role", role);
						}}
					/>
					{errors.role && (
						<small className="mt-1 text-xs text-red-500">
							{errors.role.message}
						</small>
					)}
				</div>
			</div>
			<div className="flex gap-2">
				<FormInput
					inputType="text"
					className="h-[46px]"
					placeholder="Full Name"
					autoFocus={false}
					autoComplete={false}
					register={{ ...register("name") }}
				/>
				<div className="flex-1">
					<div className="flex items-center">
						<CustomShadSelect
							className="h-[46px] w-fit rounded-r-none border-r-transparent"
							placeholder="+1"
							label="Country Codes"
							value={countryCode}
							options={countryCodes}
							onChange={(value) => {
								setCountryCode(value);
							}}
							labelClassName="px-2"
							itemClassName="px-8"
						/>
						<FormInput
							inputType="text"
							className="h-[46px] min-w-[160px] rounded-l-none py-0"
							placeholder="Phone number"
							maxLength={10}
							value={watch("phone_number")}
							error={
								errors?.phone_number?.message ? "" : undefined
							}
							onChange={(e) => {
								setValue("phone_number", e.target.value);
							}}
						/>
					</div>
					{errors.phone_number && (
						<small className="mt-1 text-xs text-red-500">
							{errors.phone_number.message}
						</small>
					)}
				</div>
			</div>
			{/* <div className="ml-auto">
				<Button
					className="flex w-fit items-center gap-1"
					onClick={addToList}
				>
					<BsPlus className="text-xl" />
					Add
				</Button>
			</div>
			<hr className="bg-primary " />
			<ScrollArea className="h-[20vh]">
				//to add the list of 
				<AddUserListCard list={list} />
			</ScrollArea> */}
			<div className="flex w-full gap-2 pb-4">
				<DialogClose className="w-full">
					<Button className="w-full" variant="outlinecustom">
						Cancel
					</Button>
				</DialogClose>

				<LoaderButton
					className="w-full"
					variant="default"
					disabled={isLoading}
					loading={isLoading}
					loaderSize={20}
				>
					Send Invite
				</LoaderButton>
			</div>
		</form>
	);
};

export default AddUser;

export const AddUserListCard = ({
	list,
}: {
	list: {
		firstName: string;
		lastName: string;
		email: string;
		phone: string;
	}[];
}) => {
	const [editDetails, setEditDetails] = useState(false);
	const handleEditMember = () => {
		setEditDetails(!editDetails);
	};
	return (
		<div className="flex flex-col gap-1">
			{list.map((detail, index) => (
				<div
					key={index}
					className="flex items-center justify-between rounded-[12px] bg-white px-4  py-1.5 shadow-lg"
				>
					<div className="flex gap-4">
						<div>
							<Avatar
								name={detail.firstName + " " + detail.lastName}
								variant="guest"
								className="!text-xs"
							/>
						</div>
					</div>

					<div className="flex items-center justify-end">
						<div className="relative flex items-center gap-1">
							<MdOutlineMail className="absolute left-2" />
							<FormInput
								inputType="text"
								value={detail.email}
								className={cn(" h-7 w-fit pl-8 text-xs", {
									"border-none focus:border-none":
										!editDetails,
								})}
								disabled={!editDetails}
							/>
						</div>
						<div className="relative flex items-center gap-1">
							<LuPhone className="absolute left-2" />
							<FormInput
								inputType="text"
								value={detail.phone}
								className={cn(" h-7 pl-8 text-xs", {
									"border-none focus:border-none":
										!editDetails,
								})}
								disabled={!editDetails}
							/>
						</div>
					</div>
					<div className="hidden justify-end gap-2 lg:flex lg:flex-[0.2]">
						{/* Cancel  */}
						{editDetails ? (
							<Button
								className="h-7 bg-[#E0E0E0] px-1.5 py-1.5"
								onClick={() => setEditDetails(false)}
							>
								<IoClose />
							</Button>
						) : null}

						<Button
							variant="outlinecustom"
							className="h-7 px-1.5 py-1.5"
							onClick={handleEditMember}
						>
							{editDetails ? (
								<IoIosSave />
							) : (
								<MdOutlineModeEdit />
							)}
						</Button>

						<Button
							variant="outlinecustom"
							className="h-7 px-1.5 py-1.5"
						>
							<TbTrash />
						</Button>
					</div>
				</div>
			))}
		</div>
	);
};
