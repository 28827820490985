import useCustomToast from "@/components/CustomToast";
import { LoaderButton } from "@/components/Loader/LoaderButton";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { useAppSelector } from "@/hooks/hooks";
import { useDeleteTeamMemberMutation } from "@/redux/features/api/teamMemberEndpoints";
import { toTitleCase } from "@/services/utilities";
import { TeamMember, TeamMemberRoles } from "@/types/team-member";
import React, { useEffect, useState } from "react";
import { TbTrash } from "react-icons/tb";

const DeleteTeamMember: React.FC<{ teamMember: TeamMember }> = ({
	teamMember,
}) => {
	const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");
	const businessId = useAppSelector((state) => state.user.user?.business_id);

	const [deleteTeamMember, deleteTeamMemberMutation] =
		useDeleteTeamMemberMutation();

	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const customToast = useCustomToast();

	const handleDeleteTeamMember = async () => {
		if (
			userRole === TeamMemberRoles.TeamMember ||
			userRole === TeamMemberRoles.PartnerManager
		) {
			return customToast("You are not allowed to perform this action", {
				id: "delete-guest",
				type: "error",
			});
		}
		await deleteTeamMember({
			business_id: businessId ?? 0,
			team_member_id: teamMember.id,
		});
	};

	useEffect(() => {
		if (deleteTeamMemberMutation.isSuccess) {
			customToast("Team Member Deleted Successfully 🎉", {
				id: "delete-team-member",
				type: "success",
			});
			setShowDeleteModal(false);
		}
	}, [deleteTeamMemberMutation.isSuccess]);

	useEffect(() => {
		if (deleteTeamMemberMutation.isError) {
			customToast("Team member could not be deleted 🤕", {
				id: "delete-team-member",
				type: "error",
			});
		}
	}, [deleteTeamMemberMutation.isError]);

	return (
		<>
			<Tooltip delayDuration={0}>
				<TooltipTrigger asChild>
					<Button
						variant="outlinecustom"
						className="h-7 px-1.5 py-1.5"
						onClick={() => setShowDeleteModal(true)}
					>
						<TbTrash />
					</Button>
				</TooltipTrigger>
				<TooltipContent side="top" sideOffset={10}>
					Delete
				</TooltipContent>
			</Tooltip>
			<Dialog
				modal
				open={showDeleteModal}
				onOpenChange={setShowDeleteModal}
			>
				<DialogContent
					className="flex max-w-[380px] gap-0 space-x-2 p-4 sm:rounded-xl"
					hasDeleteIcon={false}
				>
					<i className="mgc_delete_2_line before-text-red-2 my-[5px] text-[20px]" />
					<div>
						<DialogHeader>
							<DialogTitle className="text-[22px] font-semibold capitalize text-[#323539]">
								Remove As{" "}
								{toTitleCase(teamMember.role.replace("_", " "))}
							</DialogTitle>
						</DialogHeader>
						<div className="mb-4 mt-1 flex flex-col space-y-3 text-[#6D748D]">
							<p>
								Are you sure you want to remove{" "}
								<span className="font-semibold capitalize">
									{teamMember.name} ?
								</span>
							</p>
							<p>
								They will no longer be able to book rooms or
								manage meetings.
							</p>
						</div>
						<DialogFooter>
							<Button
								type="submit"
								variant="outline"
								className="flex-1 border-[#6B7280] text-[#6B7280] hover:bg-[#6B7280]/30 hover:text-inherit"
								onClick={() => setShowDeleteModal(false)}
								disabled={deleteTeamMemberMutation.isLoading}
							>
								Cancel
							</Button>
							<LoaderButton
								variant="destructive"
								type="submit"
								className=" flex-1"
								loading={deleteTeamMemberMutation.isLoading}
								disabled={deleteTeamMemberMutation.isLoading}
								onClick={handleDeleteTeamMember}
								loaderSize={24}
							>
								Delete
							</LoaderButton>
						</DialogFooter>
					</div>
					<i
						className="mgc_close_line before-text-gray-5 my-[5px] cursor-pointer text-[16px]"
						onClick={() => {
							if (!deleteTeamMemberMutation.isLoading)
								setShowDeleteModal(false);
						}}
					/>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default DeleteTeamMember;
