import { useState, useEffect } from "react";
import { DateTime } from "luxon";
import {
	defaultMonth,
	defaultWeekDay,
	defaultWeekOfMonth,
	weekInMonthOptions,
} from "@/services/constants";
import { useAppSelector } from "@/hooks/hooks";
import { findInSelectOption } from "@/services/utilities";

const useRecurrence = (defaultType) => {
	// Todo: Take out occurence data from hook
	const { date } = useAppSelector((state) => state.bookings.booking);
	const [startDate, setStartDate] = useState<any>(
		date ?? DateTime.now().toISODate()
	);

	const [endDate, setEndDate] = useState<any>();
	const [repeatEvery, setRepeatEvery] = useState<{
		label: string;
		value: string;
	}>(() => {
		switch (defaultType.value) {
			case "daily":
				return { label: "Day", value: "daily" };
			case "weekly":
				return { label: "Week", value: "weekly" };
			case "monthly":
				return { label: "Month", value: "monthly" };
			case "yearly":
				return { label: "Year", value: "yearly" };
			default:
				return { label: "Day", value: "daily" };
		}
	});
	const [optionSelected, setOptionSelected] =
		useState<string>("yearlyOption1");
	const [optionMonthlySelected, setOptionMonthlySelected] =
		useState<string>("monthlyOption1");
	const [occurrence, setOccurrence] = useState("");
	const [day, setDay] = useState("");
	const [selectedDays, setSelectedDays] = useState<string[]>([
		defaultWeekDay.label,
	]);

	const [weekMonth, setWeekMonth] = useState<{
		label: string;
		value: string;
	}>({
		label: defaultWeekOfMonth,
		value: defaultWeekOfMonth,
	});
	const [weekDay, setWeekDay] = useState<{ label: string; value: string }>({
		label: defaultWeekDay.label,
		value: defaultWeekDay.value,
	});
	const [month, setMonth] = useState<{ label: string; value: string }>({
		label: defaultMonth.label,
		value: defaultMonth.value,
	});

	useEffect(() => {
		const savedData = localStorage.getItem("recurringData");
		if (savedData) {
			const recurringData = JSON.parse(savedData);
			setRepeatEvery({
				label: recurringData.frequency,
				value: recurringData.frequency,
			});
			setStartDate(date);
			setEndDate(recurringData.end_date);

			if (recurringData.frequency === "weekly") {
				setSelectedDays(
					recurringData?.days?.map(
						(day) => day?.charAt(0).toUpperCase() + day?.slice(1)
					)
				);
			} else if (recurringData.frequency === "monthly") {
				// setDay(recurringData?.dates[0]);
				if (recurringData.day_date_format === "by_day") {
					setOptionMonthlySelected("monthlyOption2");
					setWeekMonth({
						label: recurringData?.week_occurrences[0],
						value: recurringData?.week_occurrences[0],
					});
					setWeekDay({
						label: recurringData?.days[0],
						value: recurringData?.days[0],
					});
				}
			} else if (recurringData.frequency === "yearly") {
				setMonth({
					label: recurringData?.yearly_month,
					value: recurringData?.yearly_month,
				});
				if (recurringData?.day_date_format === "by_day") {
					setOptionSelected("yearlyOption2");
					setWeekMonth({
						label:
							findInSelectOption(
								weekInMonthOptions,
								recurringData?.week_occurrences[0]
							)?.label || " ",
						value: recurringData?.week_occurrences[0],
					});
					setWeekDay({
						label: recurringData?.days[0],
						value: recurringData?.days[0],
					});
				}
			}
		}
	}, []);

	useEffect(() => {
		if (repeatEvery.value === "yearly") {
			setOccurrence("1");
		}
	}, [repeatEvery]);

	useEffect(() => {
		const startDay = DateTime.fromISO(startDate).weekdayLong;
		if (startDay && !selectedDays.length) {
			setSelectedDays([startDay]);
		}
	}, [startDate]);

	// useEffect(() => {
	// 	let text = "";
	// 	if (repeatEvery.label === "Day") {
	// 		text = `Occurs every ${parseInt(occurrence) > 1 ? occurrence : ""} ${parseInt(occurrence) > 1 ? "days" : "day"} starting ${startDate} until ${endDate}`;
	// 	} else if (repeatEvery.label === "Week") {
	// 		text = `Occurs every ${parseInt(occurrence) > 1 ? occurrence : ""} ${parseInt(occurrence) > 1 ? "weeks" : "week"} on ${selectedDays.join(", ")} starting ${startDate} until ${endDate}`;
	// 	}
	// 	setOccurenceText(text);
	// }, [
	// 	repeatEvery,
	// 	occurrence,
	// 	startDate,
	// 	endDate,
	// 	selectedDays,
	// 	defaultType,
	// ]);

	const saveRecurrenceData = (recurringData) => {
		localStorage.setItem("recurringData", JSON.stringify(recurringData));
		const event = new Event("recurringDataChange");
		window.dispatchEvent(event);
	};

	const validateForm = () => {
		if (!startDate || !repeatEvery || !endDate) {
			return false;
		}
		return true;
	};

	const toggleDaySelection = (day) => {
		if (selectedDays.includes(day)) {
			if (selectedDays.length > 1) {
				setSelectedDays(selectedDays.filter((d) => d !== day));
			}
		} else {
			setSelectedDays([...selectedDays, day]);
		}
	};

	return {
		startDate,
		setStartDate,
		endDate,
		setEndDate,
		repeatEvery,
		setRepeatEvery,
		optionSelected,
		setOptionSelected,
		optionMonthlySelected,
		setOptionMonthlySelected,
		occurrence,
		setOccurrence,
		day,
		setDay,
		selectedDays,
		toggleDaySelection,
		weekMonth,
		setWeekMonth,
		weekDay,
		setWeekDay,
		month,
		setMonth,
		saveRecurrenceData,
		validateForm,
	};
};

export default useRecurrence;
