import useCustomToast from "@/components/CustomToast";
import NameLetter from "@/components/NameLetter";
import Modal from "@/components/modals/Modal";
import DeletePartner from "@/components/modals/admin/DeletePartner";
import PartnerSummary from "@/components/modals/admin/partners/PartnerSummary";
import { Button } from "@/components/ui/button";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { useAppSelector } from "@/hooks/hooks";
import { useDeleteAdminPartnerMutation } from "@/redux/features/api/adminEndpoints";
import { PartnerCompany } from "@/types/partners/member";
import { TeamMemberRoles } from "@/types/team-member";
import { IUserPartner } from "@/types/user";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

const PartnerInformationOverview: React.FC<{ partner?: PartnerCompany }> = ({
	partner,
}) => {
	const user = useAppSelector((state) => state.user.user) as IUserPartner;
	const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");
	const isPartnerOfCompany =
		user.team_member_info &&
		!!user.team_member_info.partner_companies.find(
			(item) => item.id === partner?.id
		);
	const navigate = useNavigate();
	const [showSummary, setShowSummary] = useState<boolean>(false);
	const [showDelete, setShowDelete] = useState(false);
	const toast = useCustomToast();
	const [deleteAdminPartner, { isSuccess, isLoading, isError }] =
		useDeleteAdminPartnerMutation();

	const handleDelete = () => {
		if (
			(userRole === TeamMemberRoles.TeamMember ||
				userRole === TeamMemberRoles.PartnerManager) &&
			isPartnerOfCompany
		)
			return toast("You are not allowed to perform this action", {
				type: "error",
				id: "delete-error",
			});
		const data: { company_ids: number[] | any } = {
			company_ids: [parseInt(partner?.id.toString()!) ?? undefined],
		};

		if (partner?.id == undefined) {
			toast("Partner ID is undefined", {
				type: "error",
				id: "partner-error",
			});
		} else {
			deleteAdminPartner(data);
		}
	};

	useEffect(() => {
		if (isSuccess) {
			toast("Partner deleted successfully", {
				type: "success",
				id: "deletion-success",
			});
			setShowDelete(false);
			setTimeout(() => {
				navigate(-1);
			}, 2000);
		}
		if (isError) {
			toast("Error deleting partner", {
				type: "error",
				id: "deletion-error",
			});
			setShowDelete(false);
		}
	}, [isSuccess, isError]);

	return (
		<TooltipProvider>
			<div className="flex items-center justify-between rounded-md bg-white p-4 drop-shadow-[0px_1px_5px_rgba(16,24,40,0.15)]">
				<div className="flex flex-[0.3] items-center gap-2">
					<NameLetter name={partner?.name.split(" ")[0][0] ?? ""} />
					<div>
						<h1>{partner?.name ?? "N/A"}</h1>
						{partner?.address && (
							<p className="flex items-center gap-2 text-[10px] font-light">
								<i className="mgc_location_line before:!text-primary" />{" "}
								{partner?.address ?? "N/A"}
							</p>
						)}
					</div>
				</div>
				<div className="flex flex-[0.7] justify-between">
					<div className="flex items-center gap-4">
						<span className="flex items-center gap-2 rounded-full bg-primary bg-opacity-5 px-3 py-2 text-xs">
							<i className="mgc_calendar_line text-[18px] before:!text-[#858C95]" />
							<span className="text-[#506F8B]">Booking</span>
							<p className="text-light text-[14px] font-semibold text-basecolor">
								{partner?.statics?.total_bookings ?? 0}
							</p>
						</span>
						<span className="flex items-center gap-2 rounded-full bg-primary bg-opacity-5 px-3 py-2 text-xs">
							<i className="mgc_user_security_line text-[18px] before:!text-[#858C95]" />
							<span className="text-[#506F8B]">Admins</span>
							<p className="text-light text-[14px] font-semibold text-basecolor">
								{partner?.statics?.total_admins ?? 0}
							</p>
						</span>
						<span className="flex items-center gap-2 rounded-full bg-primary bg-opacity-5 px-3 py-2 text-xs">
							<i className="mgc_group_2_line text-[18px] before:!text-[#858C95]" />
							<span className="text-[#506F8B]">Guests</span>
							<p className="text-light text-[14px] font-semibold text-basecolor">
								{partner?.statics?.total_guests ?? 0}
							</p>
						</span>
					</div>

					<div className="flex items-center gap-2 border-l pl-2">
						<span className="flex items-center gap-2 text-sm font-light text-primary">
							<i className="mgc_user_security_line text-[14px] before:!text-primary" />
							<p className="text-[10px] text-[#6B7280]">Admin</p>
							<p className="text-sm font-light text-primary">
								{partner?.super_admin?.name ?? "N/A"}
							</p>
						</span>
						<div className="flex items-center gap-1 text-xs">
							<Tooltip delayDuration={0}>
								<TooltipTrigger asChild>
									<a
										href={`mailto:${partner?.email ?? null}`}
										className="flex items-center justify-center rounded-full bg-[#F9F9F9] p-1.5"
									>
										<i className="mgc_mail_line text-[12px] before:!text-primary xs:text-[13px]" />
									</a>
								</TooltipTrigger>
								<TooltipContent side="top" sideOffset={4}>
									{partner?.email}
								</TooltipContent>
							</Tooltip>

							<Tooltip delayDuration={0}>
								<TooltipTrigger asChild>
									<a
										href={`tel:${partner?.phone_number ?? null}`}
										className="flex items-center justify-center rounded-full bg-[#F9F9F9] p-1.5"
									>
										<i className="mgc_phone_line text-[12px] before:!text-primary xs:text-[13px]" />
									</a>
								</TooltipTrigger>
								<TooltipContent side="top" sideOffset={4}>
									{partner?.phone_number}
								</TooltipContent>
							</Tooltip>
						</div>
					</div>

					<div className="hidden justify-end gap-2 lg:flex lg:flex-[0.2]">
						<Tooltip delayDuration={0}>
							<TooltipTrigger asChild>
								<Button
									variant="outlinecustom"
									className="h-7 px-1.5 py-1.5"
									onClick={() => setShowSummary(true)}
								>
									<i className="mgc_information_line before:!text-primary" />
								</Button>
							</TooltipTrigger>
							<TooltipContent
								className="text-xs"
								side="top"
								sideOffset={10}
							>
								Information
							</TooltipContent>
						</Tooltip>
						{((userRole !== TeamMemberRoles.TeamMember &&
							userRole !== TeamMemberRoles.PartnerManager) ||
							isPartnerOfCompany) && (
							<>
								<Tooltip delayDuration={0}>
									<TooltipTrigger asChild>
										<Button
											variant="outlinecustom"
											className="h-7 px-1.5 py-1.5"
										>
											<i className="mgc_edit_2_line before:!text-primary" />
										</Button>
									</TooltipTrigger>
									<TooltipContent side="top" sideOffset={10}>
										Edit
									</TooltipContent>
								</Tooltip>
								<Tooltip delayDuration={0}>
									<TooltipTrigger asChild>
										<Button
											variant="outlinecustom"
											className="h-7 px-1.5 py-1.5"
											onClick={() => setShowDelete(true)}
										>
											<i className="mgc_delete_2_line before:!text-primary" />
										</Button>
									</TooltipTrigger>
									<TooltipContent
										className="text-xs"
										side="top"
										sideOffset={10}
									>
										Delete
									</TooltipContent>
								</Tooltip>
							</>
						)}
					</div>
				</div>
			</div>
			<Modal
				dialogClassName="!rounded-[12px] max-w-2xl p-0 bg-white w-full"
				open={showSummary}
				setOpen={setShowSummary}
			>
				<PartnerSummary data={partner} />
			</Modal>
			{((userRole !== TeamMemberRoles.TeamMember &&
				userRole !== TeamMemberRoles.PartnerManager) ||
				isPartnerOfCompany) && (
				<Modal
					dialogClassName="!rounded-[12px] max-w-[360px] bg-white w-full"
					open={showDelete}
					setOpen={setShowDelete}
				>
					<DeletePartner
						isLoading={isLoading}
						handleDelete={handleDelete}
						name={partner?.name ?? "N/A"}
					/>
				</Modal>
			)}
		</TooltipProvider>
	);
};

export default PartnerInformationOverview;
