import { ADMIN_URI } from "@/services/constants";
import LocalStorage from "@/services/storage";
import {
	BookingLocation,
	Location,
	SubmittingOperatingHours,
} from "@/types/location";
import { apiSlice } from "../apiSlice";
import { AnalyticsQueryParams } from "@/types/location";
import { AnalyticsResponse } from "@/types/analytics";

const storage = new LocalStorage();

export const locationEndpoints = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getLocation: builder.query<
			{ data: BookingLocation },
			{
				user_id: number;
				location_id: number;
			}
		>({
			query: ({ user_id, location_id }) => ({
				url: `/${ADMIN_URI}/${user_id}/locations/${location_id}`,
				method: "post",
				headers: {
					Authorization: "Bearer " + storage.getItem("TOKEN_COOKIE"),
				},
			}),
			providesTags: (_, __, { user_id, location_id }) => [
				{
					type: "Location",
					user_id,
					location_id,
				},
			],
		}),
		deleteLocation: builder.mutation<
			unknown,
			{
				business_id: number;
				location_id: number;
			}
		>({
			query: ({ business_id, location_id }) => ({
				url: `/${ADMIN_URI}/${business_id}/locations/${location_id}`,
				method: "delete",
				headers: {
					Authorization: "Bearer " + storage.getItem("TOKEN_COOKIE"),
				},
			}),
			invalidatesTags: ["Location"],
		}),
		
		updateLocation: builder.mutation<
			{
				status: string;
				message: string;
				data: BookingLocation;
			},
			{
				id: number;
				location_id: number;
				data: Location;
			}
		>({
			query: ({ id, location_id, data }) => ({
				url: `/${ADMIN_URI}/${id}/locations/${location_id}`,
				method: "post",
				data,
				headers: {
					Authorization: "Bearer " + storage.getItem("TOKEN_COOKIE"),
				},
			}),
			invalidatesTags: (_, __, { id, location_id }) => [
				{
					type: "Location",
					user_id: id,
					location_id,
				},
			],
		}),
		addOperatingHours: builder.mutation<
			{
				status: string;
				message: string;
				data: BookingLocation;
			},
			{
				id: number;
				location_id: number;
				data: SubmittingOperatingHours;
			}
		>({
			query: ({ id, location_id, data }) => ({
				url: `/${ADMIN_URI}/${id}/locations/${location_id}/working-hours`,
				method: "post",
				data,
				headers: {
					Authorization: "Bearer " + storage.getItem("TOKEN_COOKIE"),
				},
			}),
		}),
		updateLocationOperatingHours: builder.mutation<
			{
				status: string;
				message: string;
				data: BookingLocation;
			},
			{
				id: number;
				location_id: number;
				data: SubmittingOperatingHours;
			}
		>({
			query: ({ id, location_id, data }) => ({
				url: `/${ADMIN_URI}/${id}/locations/${location_id}/working-hours`,
				method: "post",
				data,
				headers: {
					Authorization: "Bearer " + storage.getItem("TOKEN_COOKIE"),
				},
			}),
			invalidatesTags: (_, __, { id, location_id }) => [
				{
					type: "Location",
					user_id: id,
					location_id,
				},
			],
		}),
		getLocationAnalytics: builder.query<
			AnalyticsResponse,
			{
				user_id: number;
				query_data: AnalyticsQueryParams | {};
			}
		>({
			query: ({ user_id, query_data }) => {
				const queryData = Object.keys(query_data).reduce(
					(acc, keyName) => {
						if (Array.isArray(query_data[keyName])) {
							const arr = query_data[keyName]
								.map((value) => value)
								.toString();
							acc[keyName] = "[" + arr + "]";
						} else {
							acc[keyName] = query_data[keyName];
						}
						return acc;
					},
					{}
				);

				return {
					url: `/${ADMIN_URI}/${user_id}/analytics`,
					params: queryData,
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			providesTags: (_, __, { user_id, query_data }) => [
				{
					type: "Analytics",
					user_id,
					query_data,
				},
			],
		}),
	}),
});

export const {
	useGetLocationQuery,
	useGetLocationAnalyticsQuery,
	useAddOperatingHoursMutation,
	useUpdateLocationMutation,
	useUpdateLocationOperatingHoursMutation,
	useDeleteLocationMutation,
} = locationEndpoints;
