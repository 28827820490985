import useCustomToast from "@/components/CustomToast";
import FormInput from "@/components/input/FormInput";
import { LoaderButton } from "@/components/Loader/LoaderButton";
import { useAppSelector } from "@/hooks/hooks";
import { useUpdateThemeMutation } from "@/redux/features/api/adminEndpoints";
// import LocalStorage from "@/services/storage";
import { changeTheme } from "@/services/utilities";
import { useState } from "react";

const Theme: React.FC = () => {
	const [updateTheme, updateThemeMutation] = useUpdateThemeMutation();
	const customToast = useCustomToast();
	const user = useAppSelector((s) => s.user.user);

	const [theme, setTheme] = useState(
		user?.business.theme === "default"
			? "#005893"
			: user?.business.theme ?? "#005893"
	);
	const selectedThemeBackground = `bg-[${theme}]`;
	const handleUpdateTheme = async () => {
		try {
			await updateTheme({ id: user?.business_id ?? 0, data: { theme } });
			setTheme(changeTheme(theme));
			customToast("Theme updated successfully 🎉", {
				id: "update-theme",
				type: "success",
			});
		} catch (error) {
			customToast("Theme could not be updated", {
				id: "update-theme",
				type: "error",
			});
		}
	};

	return (
		<div>
			<h1 className="text-2xl font-semibold text-basecolor">Theme</h1>
			<div className="flex gap-8 pt-4">
				<div className="flex w-full flex-wrap items-center gap-4">
					{AvailableThemes.map((theme, index) => (
						<button
							key={index}
							onClick={() => setTheme(theme)}
							className="h-[73px] w-[73px] rounded-full border-4  border-white shadow-md"
							style={{ background: theme }}
						/>
					))}
					{/* <div>
						<h1 className="text-[22px] font-semibold text-basecolor">
							Custom
						</h1>
						<button
							className="h-[73px] w-[73px] rounded-full border-4  border-white shadow-md"
							style={{ background: "#E3DFDE" }}
						></button>
					</div> */}
				</div>
				<div className="w-full">
					<div
						className={`mx-auto h-[319px] w-[319px] rounded-[40px] border-8 border-white drop-shadow-lg duration-200 ease-in-out ${selectedThemeBackground} shadow-[0px_0px_10px_2px_rgba(0,0,0,0.25)]`}
						style={{ background: theme }}
					/>
					<div className="mx-auto w-[60%] pt-6">
						<label
							htmlFor="phone-number"
							className="mb-1.5 block text-sm font-medium leading-[20px] tracking-[-0.1px] text-[#323539]"
						>
							Color Code{" "}
							<small className="text-xxs">
								(# for hex values)
							</small>
						</label>
						<FormInput
							className="flex h-10 font-medium leading-[22px]"
							inputType="text"
							value={theme}
							onChange={(e) => setTheme(e.target.value)}
						/>
					</div>
				</div>
			</div>

			<div className="mt-8 flex w-full justify-end pr-20">
				<LoaderButton
					variant="default"
					disabled={
						theme === user?.business.theme ||
						updateThemeMutation.isLoading
					}
					onClick={handleUpdateTheme}
					loading={updateThemeMutation.isLoading}
					loaderSize={20}
				>
					Save
				</LoaderButton>
			</div>
		</div>
	);
};

export default Theme;

const AvailableThemes = [
	"#50A111",
	"#B86111",
	"#89581D",
	"#C51C1C",
	"#1A7343",
	"#119384",
	"#0071A1",
	"#0C57A8",
	"#0D3C70",
	"#494949",
	"#4C18BD",
	"#69216F",
	"#840F55",
	"#840F55",
	"#000000",
];
