import useCustomToast from "@/components/CustomToast";
import FormInput from "@/components/input/FormInput";
import { LoaderButton } from "@/components/Loader/LoaderButton";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { cn } from "@/lib/utils";
import { useChangePasswordMutation } from "@/redux/features/api/adminEndpoints";
import {
	ChangePasswordParams,
	ChangePasswordSchema,
} from "@/types/team-member";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

const ChangePassword: React.FC = () => {
	const customToast = useCustomToast();

	const [changePasswordMutation] = useChangePasswordMutation();
	const [isEditing, setIsEditing] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
		reset,
	} = useForm<ChangePasswordParams>({
		resolver: zodResolver(ChangePasswordSchema),
	});

	const onSubmit: SubmitHandler<ChangePasswordParams> = async (data) => {
		try {
			await changePasswordMutation(data);
			handleResetForm();
			setIsEditing(false);

			customToast("Password updated successfully", {
				id: "update-password",
				type: "success",
			});
		} catch (error) {
			customToast("An error occured kindly try again later", {
				id: "update-password",
				type: "error",
			});
			// setError("root", {
			// 	message:
			// 		"An error occured kindly try again later",
			// });
		}
	};

	const handleResetForm = () => {
		reset();
	};

	return (
		<form
			className={cn("flex h-[80vh] flex-1 flex-col justify-between px-7")}
			onSubmit={handleSubmit(onSubmit)}
		>
			<h1 className="mb-4 text-2xl font-semibold text-basecolor">
				Change Password
			</h1>
			<ScrollArea className="flex flex-1 flex-col gap-y-4 pb-2">
				<div className="grid grid-cols-2 gap-5">
					<FormInput
						label="New Password"
						labelClassName="mb-2"
						inputType="text"
						type="password"
						placeholder="New Password"
						className={cn(
							"outline-none transition-all duration-200  ease-in-out",
							{
								"shadow-[0px_0px_5px_0px_rgba(0,0,0,0.1)]s":
									isEditing,
								"cursor-not-allowed opacity-60": !isEditing,
							}
						)}
						readOnly={!isEditing}
						error={errors.password}
						{...register("password")}
					/>
					<FormInput
						label="Confirm New Password"
						labelClassName="mb-2"
						inputType="text"
						type="password"
						placeholder="Confirm New Password"
						className={cn(
							"outline-none transition-all duration-200  ease-in-out",
							{
								"shadow-[0px_0px_5px_0px_rgba(0,0,0,0.1)]s":
									isEditing,
								"cursor-not-allowed opacity-60": !isEditing,
							}
						)}
						readOnly={!isEditing}
						error={errors.password_confirmation}
						{...register("password_confirmation")}
					/>
				</div>
			</ScrollArea>
			<section className="mt-1 flex items-center space-x-6 self-end">
				{isEditing && (
					<Button
						className={cn("self-end px-5 py-2", {
							"cursor-not-allowed": isSubmitting,
						})}
						variant="outline"
						onClick={() => {
							handleResetForm();
							setIsEditing(false);
						}}
						disabled={isSubmitting}
					>
						Cancel
					</Button>
				)}
				{isEditing ? (
					<LoaderButton
						className={cn("self-end px-5 py-2", {
							"cursor-not-allowed": isSubmitting,
						})}
						loading={isSubmitting}
						disabled={isSubmitting}
						loaderSize={16}
						type="submit"
					>
						Save
					</LoaderButton>
				) : (
					<Button
						className={cn("self-end px-5 py-2", {
							"cursor-not-allowed": isSubmitting,
						})}
						onClick={(e) => {
							if (!isEditing) setIsEditing(true);
							e.preventDefault();
						}}
						disabled={isSubmitting}
						type="button"
					>
						Edit
					</Button>
				)}
			</section>
		</form>
	);
};

export default ChangePassword;
