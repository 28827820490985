/* eslint-disable react-hooks/exhaustive-deps */
import { CalendarIcon, ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import moment from "moment";
import { Views, momentLocalizer } from "react-big-calendar";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useElementWidth } from "@/hooks/useElementWidth";
import useScreenDimension from "@/hooks/useScreenDimension";
import DateSelector from "../../DateSelector";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { LuUsers2 } from "react-icons/lu";
import { useAppDispatch, useAppSelector } from "@/hooks/hooks";
import { generateColorWithOpacity } from "@/services/utilities";
import { setSelectedRooms } from "@/redux/slices/eventSlice";
import { IoClose } from "react-icons/io5";
import useCustomToast from "@/components/CustomToast";

interface CalendarHeaderProps {
	events: any;
	setEvents: any;
	filteredEvents: any;
	setFilteredEvents: any;
	view: Views;
	setDate: (date: Date) => void;
	date: Date | undefined;
}
const CalendarHeader = ({
	view,
	events,
	setFilteredEvents,
	setDate,
	date,
}: CalendarHeaderProps) => {
	const { selectedRooms } = useAppSelector((state) => state.events);
	const dispatch = useAppDispatch();
	const [selected, setSelected] = useState("");
	const toast = useCustomToast();
	const rooms: any = [...new Set(events?.map((event) => event.room))];
	const uniqueRooms = rooms.filter(
		(item, index, self) => index === self.findIndex((t) => t.id === item.id)
	);

	const filteredRooms = uniqueRooms.filter((room) =>
		selectedRooms.some((selectedRoom) => selectedRoom.value === room.id)
	);

	const isMultiView = filteredRooms?.length >= 1;
	const localizer = momentLocalizer(moment);
	const { elementRef, elementWidth } = useElementWidth();
	const { width } = useScreenDimension();

	const roomColors = [
		"rgba(90, 15, 87, 1)",
		"rgba(4, 59, 109, 1)",
		"rgba(30, 138, 124, 1)",
		"rgba(192, 41, 96, 1)",
	];

	const handleRemoveRoom = (
		selectedRooms: { value: string; label: string }[],
		room: { value: string; label: string }
	) => {
		const updatedSelectedRooms = selectedRooms.filter(
			(selectedRoom) => selectedRoom.value !== room.value
		);
		dispatch(setSelectedRooms(updatedSelectedRooms));
		setFilteredEvents(events);
	};

	const updateFilterEvents = (room) => {
		setTimeout(() => {
			const filteredEvents = events.filter((event) => {
				return (
					event.room &&
					event.room.name.toLowerCase() === room.toLowerCase()
				);
			});

			if (filteredEvents.length < 1) {
				return toast("No appointment found", {
					id: "no-events",
					type: "success"
				});
			}

			setSelected(room);
			setFilteredEvents(filteredEvents);
		}, 3000);
	};
	const handleRoomSelection = (room: { value: string; label: string }) => {
		updateFilterEvents(room.value);
	};

	const handleRoomSelectionRemoval = (
		e,
		selectedRooms,
		room: { value: string; label: string }
	) => {
		e.stopPropagation();
		handleRemoveRoom(selectedRooms, room);
	};

	const onPrevClick = useCallback(() => {
		if (view === Views.DAY) {
			setDate(moment(date).subtract(1, "d").toDate());
		} else if (view === Views.WEEK) {
			setDate(localizer.add(date as Date, -5, "day"));
		} else {
			setDate(moment(date).subtract(1, "M").toDate());
		}
	}, [view, date]);
	// console.log(date)
	const onNextClick = useCallback(() => {
		if (view === Views.DAY) {
			setDate(moment(date).add(1, "d").toDate());
		} else if (view === Views.WEEK) {
			setDate(localizer.add(date as Date, 5, "day"));
		} else {
			setDate(moment(date).add(1, "M").toDate());
		}
	}, [view, date]);

	const dateText = useMemo(() => {
		if (view === Views.DAY) return moment(date).format("dddd, MMMM DD");
		if (view === Views.WEEK) {
			const from = moment(date)?.startOf("week");
			const to = moment(date)?.endOf("week");
			return `${from.format("MMMM DD")} - ${to.format("MMMM DD")}`;
		}
		if (view == Views.MONTH) {
			return moment(date).format("MMMM YYYY");
		}
	}, [view, date]);

	useEffect(() => {
		if (filteredRooms.length === 0) {
		  setFilteredEvents(events);
		}
	  }, [filteredRooms, events]);

	// Week View Header Container
	if (view.WEEK == Views.WEEK) {
		return (
			<>
				<div
					className={cn(
						" rounded-tl-md rounded-tr-md border border-[#E5E7EB]",
						{ hidden: !isMultiView }
					)}
				>
					<div
						className={` flex w-full justify-center gap-4 overflow-x-auto border-b border-b-[#E5E5E7] px-4 `}
						style={{ maxWidth: elementWidth }}
					>
						{filteredRooms.length >= 1 &&
							filteredRooms?.map((room, index) => (
								<button
									key={room.id}
									className={cn(
										" flex w-fit items-center gap-x-4 rounded-none px-4 text-[14px] font-[600] ",
										{
											"border-b-2 border-b-[#043B6D] text-[#09244B]":
												selected == room.name,
										}
									)}
									style={{
										backgroundColor:
											generateColorWithOpacity(
												roomColors[index],
												0.1
											),
									}}
									onClick={() =>
										handleRoomSelection({
											value: room.name,
											label: room.name,
										})
									}
								>
									<p className="flex gap-3">
										<span>{room.name}</span>

										<span
											className={`flex items-center justify-center gap-1 rounded-full bg-opacity-50 px-2 py-1.5 text-[9px] text-basecolor`}
											style={{
												backgroundColor:
													generateColorWithOpacity(
														roomColors[index],
														0.2
													),
											}}
										>
											<LuUsers2 className="text-xs" />
											<span className="text-[10px] font-bold">
												{room.capacity}
											</span>
										</span>
									</p>
									<IoClose
										className="cursor-pointer text-lg"
										onClick={(e) =>
											handleRoomSelectionRemoval(
												e,
												selectedRooms,
												{
													value: room.id,
													label: room.name,
												}
											)
										}
									/>
								</button>
							))}
					</div>
				</div>

				<div
					className={cn(
						"absolute top-[11.5%] flex w-full items-center justify-between",
						{
							"top-[18%]": isMultiView,
						}
					)}
				>
					<Button
						className="bg-transparent text-[24px] text-[#09244B] hover:bg-transparent"
						onClick={onPrevClick}
					>
						<ChevronLeftIcon />
					</Button>
					<Button
						className="bg-transparent text-[24px] text-[#09244B] hover:bg-transparent"
						onClick={onNextClick}
					>
						<ChevronRightIcon />
					</Button>
				</div>
			</>
		);
	}

	// Day and Month view container
	return (
		<div
			className={cn(
				"flex h-[56px] w-full items-center rounded-tl-md rounded-tr-md border border-[#E5E7EB] px-4 pt-3 ",
				{
					"h-[110px] flex-col": isMultiView,
				}
			)}
		>
			<div
				className={cn(
					"flex h-full  w-full scroll-py-3  overflow-x-auto scroll-smooth border-b  border-b-[#E5E5E7] px-4",
					{ hidden: !isMultiView }
				)}
				style={{ maxWidth: elementWidth, scrollbarWidth: "thin" }}
			>
				{filteredRooms.length >= 1 &&
					filteredRooms?.map((room, index) => (
						<button
							key={room.id}
							className={cn(
								" flex w-fit items-center gap-x-4  whitespace-nowrap rounded-none px-4 pb-2 text-[15px] font-semibold text-[#858C95] ",
								{
									"border-b-2 border-primary":
										selected === room.name,
								}
							)}
							style={{
								color: generateColorWithOpacity(
									roomColors[index],
									1
								),
							}}
							onClick={() =>
								handleRoomSelection({
									value: room.name,
									label: room.name,
								})
							}
						>
							<p className="flex items-center gap-3">
								<span>{room.name}</span>

								<span
									className={`flex items-center justify-center gap-1 rounded-full bg-opacity-50 px-2 py-1.5 text-[9px] text-basecolor`}
									style={{
										backgroundColor:
											generateColorWithOpacity(
												roomColors[index],
												0.2
											),
									}}
								>
									<LuUsers2 className="text-xs" />
									<span className="text-[10px] font-bold">
										{room.capacity}
									</span>
								</span>
							</p>
							<IoClose
								className="text-lg"
								onClick={(e) =>
									handleRoomSelectionRemoval(
										e,
										selectedRooms,
										{
											value: room.id,
											label: room.name,
										}
									)
								}
							/>
							{/* {room.name}
							<span
								className={`flex items-center justify-center gap-1 rounded-full bg-opacity-50 px-2 py-1.5 text-[9px] text-basecolor`}
								style={{
									backgroundColor: generateColorWithOpacity(
										roomColors[index],
										0.2
									),
								}}
							>
								<LuUsers2 className="text-xs" />
								<span className="text-[10px] font-bold">
									{room.capacity}
								</span>
							</span> */}
						</button>
					))}
			</div>
			<div
				ref={elementRef}
				className="flex h-full w-full items-center justify-between"
			>
				<Button
					className="bg-transparent px-0 text-[#09244B] hover:bg-transparent"
					onClick={onPrevClick}
				>
					<ChevronLeftIcon />
				</Button>
				<p className="flex items-center gap-1 font-inter font-[600] text-[#111827]">
					{dateText}
					<span>
						{width < 1025 ? (
							<>
								<Popover>
									<PopoverTrigger>
										<span
											className="px-0"
										>
											<CalendarIcon color="#858C95" />
										</span>
									</PopoverTrigger>
									<PopoverContent className="w-full">
										<DateSelector
											date={date}
											setDate={(date: Date) =>
												setDate(date)
											}
										/>
									</PopoverContent>
								</Popover>
							</>
						) : null}
					</span>
				</p>
				<Button
					className="bg-transparent px-0 text-[#09244B]  hover:bg-transparent"
					onClick={onNextClick}
				>
					<ChevronRightIcon />
				</Button>
			</div>
		</div>
	);
};

export default CalendarHeader;
