import { cn } from "@/lib/utils";
import {
	useDeleteMemberMutation,
	useUpdateMemberMutation,
} from "@/redux/features/api/partnerEndpoints";
import { formatDateString } from "@/services/utilities";
import { PartnerCompanyMember } from "@/types/partners/member";
import { Dispatch, SetStateAction, useState } from "react";
import { IoClose } from "react-icons/io5";
import useCustomToast from "../CustomToast";
import FormInput from "../input/FormInput";
import { Button } from "../ui/button";

import { isEqual } from "lodash";
import { MdMoreVert } from "react-icons/md";
import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog";
import { useAppSelector } from "@/hooks/hooks";
import { useParams } from "react-router";

// This is mobile view
// Not done yet

const EditMember: React.FC<{
	item: PartnerCompanyMember | any;
	showMemberDetails: boolean;
	setShowMemberDetails: Dispatch<SetStateAction<boolean>>;
}> = ({ item, showMemberDetails, setShowMemberDetails }) => {
	const user = useAppSelector((s) => s.user.user);
	const customToast = useCustomToast();
	const { partner_id } = useParams();
	const [editDetails, setEditDetails] = useState(false);
	const [formData, setFormData] = useState({
		name: item.name,
		email: item.email,
		phone: item.phone_number,
	});

	const [updateMember, { isLoading }] = useUpdateMemberMutation();
	const [deleteTeamMember, deleteMemberMutation] = useDeleteMemberMutation();

	const handleDelete = async () => {
		try {
			customToast("Deleting " + item.role, {
				id: "delete-team-member",
				type: "loading",
			});
			await deleteTeamMember({
				member_id: item.id,
			})
				.unwrap()
				.then(() => {
					setShowMemberDetails(false);
					customToast("Deleted " + item.name, {
						id: "delete-team-member",
						type: "success",
					});
				});
		} catch (error) {
			customToast("Unable to delete " + item.name, {
				id: "delete-team-member",
				type: "error",
			});
		}
	};

	const handleUpdateMember = async () => {
		if (!editDetails) {
			setEditDetails(true);
			return; // Enter edit mode
		}

		const comparedInitialData = {
			name: item.name,
			email: item.email,
			phone_number: item.phone_number,
			role: item.role,
		};
		if (
			!isEqual(comparedInitialData, {
				name: formData.name,
				email: formData.email,
				phone_number: formData.phone,
				role: item.role,
			})
		) {
			customToast("Updating " + item.role, {
				id: "update-team-member",
				type: "loading",
			});
			try {
				await updateMember({
					member_id: item.id,
					partner_company_id:
						user?.company_id ?? parseInt(partner_id ?? ""),
					data: {
						name: formData.name ?? undefined,
						email: formData.email,
						phone_number: formData.phone ?? undefined,
						role: item.role,
					},
				})
					.unwrap()
					.then(() => {
						setEditDetails(false);
						customToast("Member updated successfully", {
							id: "update-team-member",
							type: "success",
						});
					});
			} catch (error) {
				// This should be handled by the useEffect above
				customToast("Error updating member", {
					id: "update-team-member",
					type: "error",
				});
			}
		} else {
			setEditDetails(false);
		}
	};

	const handleEditToggle = () => {
		if (editDetails) {
			// If in edit mode (Save button)
			handleUpdateMember(); // Trigger the update process
		} else {
			setEditDetails(true); // Enter edit mode
		}
	};

	return (
		<Dialog
			modal
			open={showMemberDetails}
			onOpenChange={(value) => {
				if (!deleteMemberMutation.isLoading)
					setShowMemberDetails(value);
			}}
		>
			<DialogTrigger asChild>
				<Button variant="ghost">
					<MdMoreVert className="h-5 w-5 text-[#D9D9D9]" />
				</Button>
			</DialogTrigger>
			<DialogContent className="flex w-[80%] max-w-[400px] rounded-lg border border-[#E5E5E7] bg-white p-4 shadow-md">
				<div className="flex flex-col w-full">
					<h1 className="font-semibold">User Details</h1>
					<div className="flex w-full flex-col gap-y-2 mt-3">
						<div className="flex gap-4 ">
							<div className="flex items-center gap-x-2 w-full">
								<span
									className={cn(
										"relative flex h-[16px] w-[16px] items-center justify-center rounded-full border border-[#B7B7B7] bg-[#F8F8F8] p-2.5 text-[8px] text-[#525252] md:text-[12px]"
									)}
								>
									{formData?.name?.charAt(0).toUpperCase() ??
										""}
								</span>
								<FormInput
									inputType="text"
									value={formData?.name ?? ""}
									className={cn(" h-7 w-full  text-[14px]", {
										"border-none focus:border-none":
											!editDetails,
									})}
									onChange={(e) =>
										setFormData({
											...formData,
											name: e.target.value,
										})
									}
									disabled={!editDetails}
								/>
							</div>
						</div>

						<div className="mb-2 flex w-full flex-col items-center justify-center gap-y-2">
							<div className="relative flex w-full items-center gap-2">
								{/* <MdOutlineMail className="absolute left-2" /> */}
								<i className="mgc_mail_line text-[18px] before:!text-[#858C95]" />

								<FormInput
									inputType="text"
									value={formData.email ?? ""}
									className={cn("h-7 px-3 text-[14px]", {
										"border-none px-0 focus:border-none":
											!editDetails,
									})}
									onChange={(e) =>
										setFormData({
											...formData,
											email: e.target.value,
										})
									}
									disabled={!editDetails}
								/>
							</div>
							<div className="relative flex w-full items-center gap-2">
								<i className="mgc_phone_line text-[18px] before:!text-[#858C95]" />
								<FormInput
									inputType="text"
									value={formData.phone ?? ""}
									className={cn("h-7 px-3 text-[14px]", {
										"border-none px-0 focus:border-none":
											!editDetails,
									})}
									onChange={(e) =>
										setFormData({
											...formData,
											phone: e.target.value,
										})
									}
									disabled={!editDetails}
								/>
							</div>
							<div className="relative flex w-full items-center gap-2">
								<i className="mgc_calendar_line text-[18px] before:!text-[#858C95]" />
								<FormInput
									inputType="text"
									value={
										item?.created_at
											? formatDateString(item.created_at)
											: ""
									}
									className="h-7 border-none px-0 text-[14px] focus:border-none"
									disabled={true}
								/>
							</div>
						</div>
						<div className="grid grid-cols-1 items-center gap-2 sm:grid-cols-2">
							{/* {
					type === "guest" && <Tooltip delayDuration={0}>
						<TooltipTrigger asChild>
							<Button variant="outlinecustom" className="px-1.5 h-7 py-1.5"><FaUserShield /></Button>
						</TooltipTrigger>
						<TooltipContent side="top" sideOffset={10}>
							Make Admin
						</TooltipContent>
					</Tooltip>
				} */}
							{!isLoading && (
								<Button
									variant="outlinecustom"
									className="flex h-7 w-full items-center gap-2 px-1.5 py-1.5"
									onClick={handleEditToggle}
									disabled={
										isLoading ||
										deleteMemberMutation.isLoading
									}
								>
									{editDetails ? "Save" : "Edit"}
									{editDetails ? (
										<i className="mgc_save_2_line before:!text-primary" />
									) : (
										<i className="mgc_edit_2_line before:!text-primary" />
									)}
								</Button>
							)}

							{editDetails && (
								<Button
									variant="outlinecustom"
									className="flex h-7 w-full items-center gap-2 px-1.5 py-1.5"
									onClick={() => setEditDetails(false)}
									disabled={
										isLoading ||
										deleteMemberMutation.isLoading
									}
								>
									Cancel <IoClose />
								</Button>
							)}
							{/* remove admin would get user role  */}
							{/* {item.role === "guest" && (
					<Button
						variant="outlinecustom"
						className="flex h-7 w-full items-center gap-2 px-1.5 py-1.5"
					>
						Make Admin
						<FaUserShield />
					</Button>
				)}
				{item.role === "admin" && (
					<Button
						variant="outlinecustom"
						className="flex h-7 w-full items-center gap-2 px-1.5 py-1.5"
					>
						Remove Admin
						<FaUserTimes />
					</Button>
				)} */}

							<Button
								variant="outlinecustom"
								className="flex h-7 w-full items-center gap-2 px-1.5 py-1.5"
								onClick={handleDelete}
								disabled={
									deleteMemberMutation.isLoading || isLoading
								}
							>
								<p>Delete</p>
								<i className="mgc_delete_2_line before:!text-primary" />
							</Button>
						</div>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default EditMember;
