import CustomShadSelect from "@/components/common/CustomShadSelect";
import useCustomToast from "@/components/CustomToast";
import AddressComponent, {
	findCountryByLabel,
	findProvinceByLabel,
} from "@/components/input/AddressComponent";
import FormInput from "@/components/input/FormInput";
import { LoaderButton } from "@/components/Loader/LoaderButton";
import { useAppSelector } from "@/hooks/hooks";
import {
	useGetLocationQuery,
	useUpdateLocationMutation,
} from "@/redux/features/api/locationEndpoints";
import { updateLocation } from "@/redux/slices/locationSlice";
import {
	countryCodes,
	countryOptions,
	findCountry,
	findState,
} from "@/services/constants";
import { changeCountry, updateCountryAndState } from "@/services/utilities";
import { Location, LocationSchema } from "@/types/location.ts";
import { TeamMemberRoles } from "@/types/team-member";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";

const EditLocationInfo: React.FC = () => {
	const [countryCode, setCountryCode] = useState("+1");

	const [updateLocationInfo, { data: responseData, isSuccess, isLoading }] =
		useUpdateLocationMutation();
	const { location_id } = useParams();
	const navigate = useNavigate();
	const customToast = useCustomToast();

	const user = useAppSelector((state) => state.user);

	const [provinceOptions, setProvinceOptions] = useState<
		{
			label: string;
			value: string;
		}[]
	>([]);

	const { data } = useGetLocationQuery({
		user_id: user?.user?.business_id ?? 0,
		location_id: location_id ? +location_id : 0,
	});

	const dispatch = useDispatch();

	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors },
		watch,
	} = useForm<Location>({
		resolver: zodResolver(LocationSchema),
	});

	const onSubmit: SubmitHandler<Location> = async (data) => {
		if (
			user?.user &&
			(user?.user.role === TeamMemberRoles.TeamMember ||
				user?.user.role === TeamMemberRoles.PartnerManager)
		)
			return customToast("You are not allowed to perform this action", {
				id: "edit-location",
				type: "error",
			});
		try {
			const updatedCountry = findCountry(data.country || "");

			const updatedState = findState(
				data.state || "",
				data.country || ""
			);

			await updateLocationInfo({
				id: user.user?.business_id ?? 0,
				location_id: location_id ? +location_id : 0,
				data: {
					...data,
					country: updatedCountry,
					state: updatedState,
					phone_number: data.phone_number
						? countryCode + data.phone_number.slice(-10)
						: undefined,
				},
			}).unwrap();

			dispatch(updateLocation(getValues()));
		} catch (error) {
			customToast("An error occured kindly try again later", {
				id: "edit-location",
				type: "error",
			});
		}
	};

	const onSuccess = () => {
		customToast("Location info updated successfully", {
			id: "edit-location",
			type: "success",
		});
		navigate("/admin/location-management/" + location_id);
	};

	useEffect(() => {
		setValue("time_zone", Intl.DateTimeFormat().resolvedOptions().timeZone);
	}, []);

	useEffect(() => {
		if (isSuccess) onSuccess();
	}, [isSuccess, responseData]);

	useEffect(() => {
		if (data) {
			setCountryCode(
				data.data.phone_number
					? data.data.phone_number.slice(0, -10)
					: ""
			);

			// Needed
			// setValue(
			// 	"phone_number",
			// 	data.data.phone_number !== null
			// 		? data.data.phone_number.slice(-10)
			// 		: undefined
			// );

			const countryValue = findCountryByLabel(data.data.country ?? "");
			const newProvinceOptions = changeCountry(countryValue);

			const newProvince = findProvinceByLabel(
				newProvinceOptions,
				data.data.state ?? ""
			);

			updateCountryAndState(
				setValue,
				setProvinceOptions,
				true,
				newProvince,
				countryValue
			);

			setValue("name", data.data.name ?? "");
			setValue("email", data.data.email ?? "");
			setValue(
				"phone_number",
				data.data.phone_number ? data.data.phone_number.slice(-10) : ""
			);
			setValue("address", data.data.address ?? "");
			setValue("address_2", data.data.address_2 ?? "");
			setValue("city", data.data.city ?? "");
			setValue("zip_code", data.data.zip_code ?? "");
			setValue("time_zone", data.data.time_zone ?? "");
		}
	}, [data]);

	return (
		<form
			className="flex flex-col space-y-[18px]"
			onSubmit={handleSubmit(onSubmit)}
		>
			<h1 className="font-inter text-[22px] font-semibold tracking-[-1%] text-[#323539]">
				Location Information
			</h1>
			{/* <div className="flex items-center space-x-4">
				<div className="h-[74px] w-[106px] rounded-md bg-slate-300"></div>
				<div>
					<Button
						className="space-x-1.5 rounded-md border border-[#043B6D] bg-white text-[#043B6D] hover:bg-white"
						onClick={() => {
							logoRef.current.click();
						}}
					>
						<input
							type="file"
							ref={logoRef}
							onChange={handleFileChange}
							hidden
						/>
						<i className="mgc_upload_3_line before-text-dark-blue-3 text-[16px]" />
						<p className="font-semibold">
							Upload Photo
						</p>
					</Button>
					<div className="text-[12px] font-medium leading-[20px] text-[#6B7280]">
						<p>File Must be a JPG or PNG</p>
						<p>Max size of 500kb</p>
					</div>
				</div>
			</div> */}
			<div className="my-4 border-b border-b-[#E5E5E7] pb-4">
				<h1 className="text-[15px] leading-[22px] tracking-normal text-[#323539]">
					Location Details
				</h1>
				<p className="text-[14px] leading-[20px] tracking-[0.1px] text-[#858C95]">
					This information contains your location details
				</p>
			</div>
			<div className="flex flex-col gap-y-4">
				<FormInput
					label="Location Name"
					register={{ ...register("name") }}
					inputType="text"
					placeholder="Location Name"
					className="h-[46px] shadow-[0px_0px_5px_0px_rgba(0,0,0,0.1)]"
					error={errors.name}
				/>
				<div className="flex space-x-3">
					<div className="flex-1">
						<label
							htmlFor="phone-number"
							className="mb-1.5 block text-sm font-medium leading-[20px] tracking-[-0.1px] text-[#323539]"
						>
							Location Phone
						</label>
						<div>
							<div className="flex items-center">
								<CustomShadSelect
									className="h-9 w-fit rounded-r-none border-r-transparent"
									placeholder="+1"
									label="Country Codes"
									value={countryCode}
									options={countryCodes}
									onChange={(value) => {
										setCountryCode(value);
									}}
									labelClassName="px-2"
									itemClassName="px-8"
								/>
								<FormInput
									inputType="text"
									className="h-9 rounded-l-none py-0"
									maxLength={10}
									value={watch("phone_number")}
									error={
										errors?.phone_number?.message
											? ""
											: undefined
									}
									onChange={(e) => {
										setValue(
											"phone_number",
											e.target.value
										);
									}}
								/>
							</div>
							{errors.phone_number && (
								<small className="mt-1 text-xs text-red-500">
									{errors.phone_number.message}
								</small>
							)}
						</div>
					</div>
					<FormInput
						label="Location Email"
						inputType="text"
						placeholder="Email"
						className="h-9"
						register={{
							...register("email"),
						}}
						error={errors.email}
						required
					/>
				</div>
				<div className="flex flex-col space-y-2">
					<AddressComponent
						placeholder="Address Line 1"
						label="Address"
						className="h-[42px] max-w-full border-[#E5E5E7] shadow-[0px_0px_5px_0px_rgba(0,0,0,0.1)]"
						placeholderClassName="font-normal"
						oldValue={watch("address")}
						onGetAddress={(
							address,
							city,
							province,
							country,
							postalCode
						) => {
							setValue("address", address);
							setValue("city", city);
							setValue("state", province);
							setValue("country", country);
							setValue("zip_code", postalCode);

							const countryValue = findCountryByLabel(country);

							const newProvinceOptions =
								changeCountry(countryValue);
							setProvinceOptions(newProvinceOptions);

							const newProvince = findProvinceByLabel(
								newProvinceOptions,
								province
							);
							updateCountryAndState(
								setValue,
								setProvinceOptions,
								true,
								newProvince,
								countryValue
							);
						}}
						errorMessage={errors?.address?.message}
					/>
					<FormInput
						inputType="text"
						placeholder="Address Line 2"
						className="shadow-[0px_0px_5px_0px_rgba(0,0,0,0.1)]"
						register={{
							...register("address_2"),
						}}
						error={errors.address_2}
					/>
					<div className="flex space-x-2">
						<FormInput
							inputType="text"
							placeholder="City"
							className="shadow-[0px_0px_5px_0px_rgba(0,0,0,0.1)]"
							register={{
								...register("city"),
							}}
							error={errors.city}
						/>
						<FormInput
							inputType="text"
							placeholder="Zip Code"
							className="shadow-[0px_0px_5px_0px_rgba(0,0,0,0.1)]"
							register={{
								...register("zip_code"),
							}}
							error={errors.zip_code}
						/>
					</div>
					<div className="flex space-x-2">
						<div className="flex flex-1 flex-col">
							<CustomShadSelect
								placeholder="Country"
								label="Country"
								className="h-[42px] shadow-[0px_0px_5px_0px_rgba(0,0,0,0.1)] data-[placeholder]:font-normal data-[placeholder]:text-[#323539]/50"
								options={countryOptions}
								value={watch("country")}
								onChange={(value) => {
									updateCountryAndState(
										setValue,
										setProvinceOptions,
										false,
										undefined,
										value
									);
								}}
							/>
							{errors.country && (
								<small className="mt-1 text-xs text-red-500">
									{errors.country.message}
								</small>
							)}
						</div>
						<div className="flex flex-1 flex-col">
							<CustomShadSelect
								placeholder="Province"
								label="Province"
								options={provinceOptions}
								className="h-[42px] shadow-[0px_0px_5px_0px_rgba(0,0,0,0.1)] data-[placeholder]:font-normal data-[placeholder]:text-[#323539]/50"
								value={watch("state")}
								onChange={(value) => {
									updateCountryAndState(
										setValue,
										setProvinceOptions,
										false,
										value,
										getValues("country")
									);
								}}
							/>
							{errors.state && (
								<small className="mt-1 text-xs text-red-500">
									{errors.state.message}
								</small>
							)}
						</div>
					</div>
				</div>
			</div>

			<div className="mt-8 flex w-full justify-end">
				<div className="flex gap-2">
					<LoaderButton
						variant="default"
						disabled={isLoading}
						loading={isLoading}
						type="submit"
						loaderSize={16}
						className="select-none"
					>
						Update
					</LoaderButton>
				</div>
			</div>
		</form>
	);
};

export default EditLocationInfo;
