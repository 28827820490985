import useCustomToast from "@/components/CustomToast";
import { LoaderButton } from "@/components/Loader/LoaderButton";
import RequestIsLoading from "@/components/Loader/RequestIsLoading";
import CustomShadSelect from "@/components/common/CustomShadSelect";
import FormInput from "@/components/input/FormInput";
import { Button } from "@/components/ui/button";
import useSetErrors, { ApiError } from "@/hooks/useSetErrors";
import { useUpdateGuestMutation } from "@/redux/features/api/teamMemberEndpoints";
import { useGetGuestQuery } from "@/redux/features/api/teamMemberEndpoints";
import { countryCodes } from "@/services/constants";
import { GuestParams, GuestSchema, TeamMember } from "@/types/team-member";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { useLocation, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";

const EditGuest: React.FC = () => {
	const {
		register,
		handleSubmit,
		setValue,
		setError,
		formState: { errors },
		watch,
	} = useForm<GuestParams>({
		resolver: zodResolver(GuestSchema),
	});

	const navigate = useNavigate();

	const { business_id, guest_id } = useParams();
	const location = useLocation();

	const getTeamMemberQuery = useGetGuestQuery(
		{
			business_id: parseInt(business_id ?? ""),
			guest_id: parseInt(guest_id ?? ""),
		},
		{
			skip: !!location.state.team_member,
		}
	);
	const customToast = useCustomToast();
	// console.log(location.state.team_member);

	const [
		updateGuest,
		{ data: responseData, isSuccess, isLoading, isError, error },
	] = useUpdateGuestMutation();

	const [countryCode, setCountryCode] = useState("+1");
	const [firstName, setFirstName] = useState("");

	const handleEditGuest: SubmitHandler<GuestParams> = async (data) => {
		await updateGuest({
			business_id: parseInt(business_id ?? ""),
			guest_id: parseInt(guest_id ?? ""),
			data: {
				...data,
				phone_number: countryCode + data.phone_number,
				name: data.name + " " + firstName,
			},
		}).unwrap();
	};

	useEffect(() => {
		const setFormData = (teamMemberData: TeamMember) => {
			setValue(
				"name",
				teamMemberData.name ? teamMemberData.name.split(" ")[0] : ""
			);
			setFirstName(
				teamMemberData.name
					? teamMemberData.name.split(" ").slice(1).join(" ")
					: ""
			);
			setValue("email", teamMemberData.email);
			setValue(
				"phone_number",
				teamMemberData.phone_number
					? teamMemberData.phone_number.slice(-10)
					: ""
			);
			setCountryCode(
				teamMemberData.phone_number
					? teamMemberData.phone_number.slice(0, -10)
					: "+1"
			);
			// setValue("role", teamMemberData.role);
		};

		if (location.state.team_member) {
			setFormData(location.state.team_member);
		} else if (getTeamMemberQuery.isSuccess && getTeamMemberQuery.data) {
			setFormData(getTeamMemberQuery.data.data);
		}
	}, [
		location.state.team_member,
		getTeamMemberQuery.isSuccess,
		getTeamMemberQuery.data,
	]);

	useEffect(() => {
		if (isSuccess && responseData) {
			customToast("Guest updated successfully 🎉", {
				id: "add-team-member",
				type: "success",
				duration: 3000,
			});
			setTimeout(() => {
				navigate("/admin/team-members");
			}, 1500);
		}
	}, [isSuccess]);

	useSetErrors({
		error,
		isError,
		setError,
		keys: ["email"],
	});

	useEffect(() => {
		const errorObject = error as ApiError;
		if (errorObject && errorObject.status !== 401) {
			customToast("Could not update guest 🤕. Please try again later", {
				id: "update-guest",
				type: "error",
			});
		}
	}, [isError]);

	return (
		<form
			className="relative flex h-full w-[60vw] flex-col space-y-4"
			onSubmit={handleSubmit(handleEditGuest)}
		>
			{/* The header  */}
			<div className="flex gap-4">
				<Link to="/admin/team-members/">
					<FaChevronLeft className="text-xl font-semibold text-[#858C95]" />
				</Link>

				<div>
					<p className="text-xs font-normal">
						Team Members &gt; Edit &gt;{" "}
						{location.state.team_member
							? location.state.team_member.name ?? ""
							: getTeamMemberQuery?.data?.data.name ?? ""}
					</p>
					<h1
						className="text-[20px] font-semibold text-basecolor"
						onClick={() =>
							navigate(
								"/admin/team-members/edit-team-member/" +
									guest_id,
								{
									replace: true,
									state: {},
								}
							)
						}
					>
						Edit Team Member
					</h1>
				</div>
			</div>
			{/* body content  */}
			<div className="flex flex-1 flex-col space-y-6 px-8">
				<div className="flex flex-col gap-y-4">
					<div className="flex flex-col gap-y-4">
						<p className="font-manrope font-semibold text-basecolor">
							Team Member Information
						</p>
						<div className="grid grid-cols-2 gap-2">
							<FormInput
								inputType="w-full"
								placeholder="Last Name"
								className="h-10"
								error={errors?.name}
								register={{
									...register("name"),
								}}
							/>
							<FormInput
								inputType="w-full"
								placeholder="First Name"
								className="h-10"
								value={firstName}
								onChange={(e) =>
									setFirstName(e.target.value.trim())
								}
							/>

							<FormInput
								inputType="w-full"
								placeholder="Email"
								className="h-10"
								register={{
									...register("email"),
								}}
								error={errors.email}
							/>
							<div>
								<div className="flex items-center">
									<CustomShadSelect
										className="h-10 w-fit rounded-r-none border-r-transparent"
										placeholder="+1"
										label="Country Codes"
										options={countryCodes}
										value={countryCode}
										onChange={(value) => {
											setCountryCode(value);
										}}
										labelClassName="px-2"
										itemClassName="px-8"
									/>
									<FormInput
										inputType="text"
										className="h-10 rounded-l-none py-0"
										placeholder="Phone Number"
										maxLength={10}
										value={watch("phone_number")}
										error={
											errors?.phone_number?.message
												? ""
												: undefined
										}
										onChange={(e) => {
											setValue(
												"phone_number",
												e.target.value
											);
										}}
									/>
								</div>
								{errors.phone_number && (
									<small className="mt-1.5 text-xs text-red-500">
										{errors.phone_number.message}
									</small>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="mt-6 flex justify-end gap-2 px-8">
				<Button
					className="max-w-[190px] flex-1"
					variant="outlinecustom"
					onClick={() => {
						navigate("/admin/team-members");
					}}
					disabled={isLoading}
				>
					Cancel
				</Button>
				<LoaderButton
					className="max-w-[190px] flex-1"
					variant="default"
					disabled={getTeamMemberQuery.isLoading || isLoading}
					loading={isLoading}
					loaderSize={20}
				>
					Save
				</LoaderButton>
			</div>

			<RequestIsLoading
				isLoading={getTeamMemberQuery.isLoading}
				size={20}
				isWhite
			/>
		</form>
	);
};

export default EditGuest;
