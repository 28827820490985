/* eslint-disable react/prop-types */
import React, { useRef, useState, useEffect } from "react";
import Select, { components, Props as SelectProps } from "react-select";
import { IoClose } from "react-icons/io5";

type CustomSelectProps = SelectProps<any>;

const CustomMultiSelect: React.FC<CustomSelectProps> = (props) => {
	const wrapperRef = useRef<HTMLDivElement>(null); // Change to a div ref
	const [menuOpen, setMenuOpen] = useState(false);

	// Handle click outside to close menu
	useEffect(() => {
		function handleClickOutside(event) {
			if (
				wrapperRef.current &&
				!wrapperRef.current.contains(event.target)
			) {
				setMenuOpen(false);
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () =>
			document.removeEventListener("mousedown", handleClickOutside);
	}, [wrapperRef]);

	return (
		<div ref={wrapperRef}>
			<Select
				{...props}
				styles={{
					clearIndicator: (provided) => ({
						...provided,
						display: "none",
					}),
					placeholder: (provided) => ({
						...provided,
						// color: "#979a9b",
						fontSize: "15px",
						fontWeight: 400,
						color: "#858C95",
					}),
					control: (defaultStyles: any) => ({
						...defaultStyles,
						"&:active": { borderColor: "inherit" },
						"&:focus": { borderColor: "#9CBDF9" },
						borderColor: "#E5E5E7",
						borderRadius: "8px",
						borderWidth: "0.5px",
					}),
					menu: (provided: any) => ({
						...provided,
						backgroundColor: "#fff",
						zIndex: 9999,
						borderRadius: "8px",
						color: "#323539",
					}),
					input: (provided: any) => ({
						...provided,
						caretColor: "transparent",
						border: "none",
						fontSize: "15px",
						borderWidth: "0.5px",
						// "&:focus": {
						// 	outline: "transparent",
						// },
					}),
				}}
				menuIsOpen={menuOpen}
				onMenuOpen={() => setMenuOpen(true)}
				onMenuClose={() => setMenuOpen(false)}
				closeMenuOnScroll={true}
				hideSelectedOptions={false}
				components={{
					ValueContainer: customValueContainer,
					MultiValue: customMultiValue,
					Option,
				}}
			/>
		</div>
	);
};

export default CustomMultiSelect;

const customValueContainer = (props) => {
	const { children, getValue, clearValue, selectProps } = props;
	const selectedOptions = getValue();
	const handleClear = () => {
		clearValue();
	};

	const displayText =
		selectedOptions.length > 0
			? `${selectedOptions.length} selected`
			: selectProps.placeholder;

	return (
		<components.ValueContainer {...props}>
			<div className="flex items-center gap-2">
				{displayText !== selectProps.placeholder ? (
					<div className="bg-primary/2 ml-2 flex items-center rounded-lg border  border-primary px-3 py-1 text-sm font-normal text-primary">
						{displayText}
						<button
							className="ml-2 cursor-pointer text-primary"
							onClick={handleClear}
						>
							<IoClose />
						</button>
					</div>
				) : null}
				{children}
			</div>
		</components.ValueContainer>
	);
};

const customMultiValue = () => {
	// <div className="border-[#043B6D] bg-gray-900 p-2">{data.label}</div>
	return null;
};

const Option = (selectProps) => (
	<components.Option {...selectProps} className="flex items-center gap-4">
		<div className="flex items-center gap-x-2">
			<input
				type="checkbox"
				className="accent-primary"
				checked={selectProps.isSelected}
				onChange={() => null}
			/>
			<label>{selectProps.label}</label>
		</div>
	</components.Option>
);
