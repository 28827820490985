import { Button } from "@/components/ui/button";
import { DialogClose } from "@/components/ui/dialog";
import { useUpdateMemberMutation } from "@/redux/features/api/partnerEndpoints";
import { LoadingOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { notifyWithIcon } from "@/services/utilities";

const MakeAdmin = ({ name, id, handleClose }) => {
	//Change the flow to a button loading
	const [updateMember, { isLoading, isSuccess, error, isError }] =
		useUpdateMemberMutation();

	const handleMakeAdmin = async () => {
		updateMember({
			member_id: id ? parseInt(id.toString()) : 0,
			data: {
				role: "admin",
			},
		}).unwrap();
	};

	useEffect(() => {
		if (isSuccess) {
			notifyWithIcon("Error", "Admin added successfully");
			handleClose();
		} else if (isError && error) {
			const errorData = error as {
				data?: { message: string; errors?: Record<string, string[]> };
			};
			if (errorData.data && errorData.data.errors) {
				for (const messages of Object.values(errorData.data.errors)) {
					(messages as string[]).forEach((message) => {
						notifyWithIcon("Error", message);
					});
				}
				handleClose();
			} else {
				notifyWithIcon("Error", "Error Adding Admin");
				handleClose();
			}
		}
	}, [isSuccess, isError, error]);

	return (
		<>
			<div className="flex flex-grow flex-col gap-y-2">
				<h1 className="text-2xl font-semibold text-[#323539]">
					Add as Admin
				</h1>
				<p>
					Are you sure you want to make{" "}
					<span className="font-bold text-[#6D748D]">
						{name || "anna"}{" "}
					</span>
					an admin?
				</p>
				<p>
					This will give them the permission to create and manage
					reservations
				</p>
				<div className="flex w-full items-center gap-x-1.5 pt-2">
					<DialogClose className="w-full rounded-md border border-primary bg-transparent bg-white px-4 py-2 text-primary">
						Cancel
					</DialogClose>
					<Button
						className=" w-full rounded-md px-4 py-2 text-white"
						variant="default"
						onClick={handleMakeAdmin}
						disabled={isLoading}
					>
						{isLoading ? (
							<LoadingOutlined style={{ fontSize: 24 }} spin />
						) : (
							"Yes"
						)}
					</Button>
				</div>
			</div>
		</>
	);
};

export default MakeAdmin;
