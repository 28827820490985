import { ScrollArea } from "@/components/ui/scroll-area";
import { useState } from "react";
import PaymentMethodCard from "./PaymentMethodCard";

export type Item = {
	id: number;
	bank: string;
	username: string;
	bank_card_details: string;
};

const PaymentMethod = () => {
	const initialItems: Item[] = [
		{
			id: 1,
			bank: "Anex",
			username: "Bankole Oshoks",
			bank_card_details: "1345623410098745",
		},
		{
			id: 2,
			bank: "Barclays",
			username: "Layi Wasabi",
			bank_card_details: "134562341009822",
		},
		{
			id: 3,
			bank: "Unity Bank",
			username: "Adamas Oshomole",
			bank_card_details: "1345213410098748",
		},
	];
	const [items] = useState<Item[]>(initialItems);
	const [selectedItems, setSelectedItems] = useState<Item[]>([]);
	const [, setSelectAll] = useState<boolean>(false);

	// const toggleSelectAll = () => {
	// 	if (selectAll) {
	// 		setSelectedItems([]);
	// 	} else {
	// 		setSelectedItems(items);
	// 	}
	// 	setSelectAll(!selectAll);
	// };

	const toggleSelectItem = (item: Item) => {
		if (selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
			setSelectedItems(
				selectedItems.filter(
					(selectedItem) => selectedItem.id !== item.id
				)
			);
		} else {
			setSelectedItems([...selectedItems, item]);
		}
		setSelectAll(false);
	};
	return (
		<>
			{/* <div className="flex justify-between px-4 py-2">
				<div className="flex items-center gap-2 text-[14px]">
					<input
						id="select-all"
						type="checkbox"
						onClick={toggleSelectAll}
					/>
					<label className={cn("", { "text-[#7D7E80]": !selectAll })}>
						Select All
					</label>
				</div>
				<TooltipProvider>
					<div className="flex items-center gap-1">
						<Tooltip delayDuration={0}>
							<TooltipTrigger asChild>
								<Button
									variant="outlinecustom"
									disabled={!selectAll}
									className="h-7 px-1.5 py-1.5"
								>
									<TbTrash />
								</Button>
							</TooltipTrigger>
							<TooltipContent side="top" sideOffset={10}>
								Delete
							</TooltipContent>
						</Tooltip>
					</div>
				</TooltipProvider>
			</div> */}
			<ScrollArea className="h-[20vh] w-full">
				<div className="flex flex-col gap-y-4 py-1">
					<div className="flex flex-col gap-2 px-1">
						{items.map((item, index) => (
							<PaymentMethodCard
								item={item}
								key={index}
								onSelect={() => toggleSelectItem(item)}
								checked={selectedItems.some(
									(selectedItem) =>
										selectedItem.id === item.id
								)}
							/>
						))}
					</div>
				</div>
			</ScrollArea>
		</>
	);
};

export default PaymentMethod;
