import CustomShadSelect from "@/components/common/CustomShadSelect";
import useCustomToast from "@/components/CustomToast";
import FormInput from "@/components/input/FormInput";
import { LoaderButton } from "@/components/Loader/LoaderButton";
import { DialogClose } from "@/components/ui/dialog";
import { useAppSelector } from "@/hooks/hooks";
import { countryCodes } from "@/services/constants";
import { notifyWithIcon, toTitleCase } from "@/services/utilities";
import {
	PartnerAddMemberParams,
	PartnerAddMemberSchema,
} from "@/types/team-member";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

const AddMember = ({ addNewMember, handleClose }) => {
	const customToast = useCustomToast();
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [countryCode, setCountryCode] = useState("+1");
	// const

	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		watch,
		formState: { errors },
	} = useForm<PartnerAddMemberParams>({
		resolver: zodResolver(PartnerAddMemberSchema),
	});
	const user = useAppSelector((s) => s.user.user);

	const [addNewMemberMutate, { isError, isSuccess, isLoading, error }] =
		addNewMember;

	const onSubmit: SubmitHandler<PartnerAddMemberParams> = async (data) => {
		customToast("Adding " + toTitleCase(data.role), {
			id: "member-added",
			type: "loading",
		});
		const submittedFormData = {
			name: data.name ?? "".trim(),
			email: data.email,
			phone_number: data.phone_number
				? countryCode + data.phone_number.slice(-10)
				: undefined,
			role: data.role,
		};
		try {
			await addNewMemberMutate({
				partner_company_id: user?.company_id,
				data: submittedFormData,
			});
			customToast("Member added successfully", {
				id: "member-added",
				type: "success",
			});
			handleClose();
		} catch (error) {
			customToast("An error occured kindly try again later", {
				id: "member-added",
				type: "error",
			});
		}
	};

	useEffect(() => {
		if (!isSuccess) return;

		if (isError && error) {
			const errorData = error as { data?: ErrorResponse };

			if (errorData.data && errorData.data.errors) {
				const errorMessages = Object.values(errorData.data.errors)
					.flat()
					.filter(
						(message): message is string =>
							typeof message === "string"
					);

				errorMessages.forEach((message) => {
					notifyWithIcon("Error", message);
				});
			} else {
				notifyWithIcon("Error", "An error occurred");
			}
		}
	}, [isSuccess, isError, error, handleClose]);

	useEffect(() => {
		if (lastName.length && firstName.length) {
			const combinedName = `${lastName} ${firstName}`.trim();
			if (getValues("name") !== combinedName)
				setValue("name", combinedName);
		}
	}, [firstName, lastName]);

	return (
		<div className="mt-4 flex w-full flex-col gap-y-3">
			<form
				onSubmit={handleSubmit(onSubmit)}
				className="flex flex-col gap-y-3"
			>
				<FormInput
					inputType="text"
					placeholder="Email Address"
					{...register("email")}
					error={errors.email}
				/>
				<div className="flex flex-wrap gap-2">
					<FormInput
						className="h-10 min-w-[160px]"
						inputType="text"
						placeholder="Last Name"
						value={lastName}
						defaultValue={lastName}
						onChange={(e) => setLastName(e.target.value)}
						error={errors.name}
					/>
					<FormInput
						inputType="text"
						className="h-10 min-w-[160px]"
						placeholder="First Name"
						value={firstName}
						defaultValue={firstName}
						onChange={(e) => setFirstName(e.target.value)}
					/>
				</div>
				<div className="mb-8 flex flex-wrap gap-2">
					<div className="flex-1">
						<div className="flex items-center">
							<CustomShadSelect
								className="h-10 w-fit rounded-r-none border-r-transparent"
								placeholder="+1"
								label="Country Codes"
								value={countryCode}
								options={countryCodes}
								onChange={(value) => {
									setCountryCode(value);
								}}
								labelClassName="px-2"
								itemClassName="px-8"
							/>
							<FormInput
								inputType="text"
								className="h-10 min-w-[160px] rounded-l-none py-0"
								placeholder="Phone number"
								maxLength={10}
								value={watch("phone_number")}
								error={
									errors?.phone_number?.message
										? ""
										: undefined
								}
								onChange={(e) => {
									setValue("phone_number", e.target.value);
								}}
							/>
						</div>
						{errors.phone_number && (
							<small className="mt-1 text-xs text-red-500">
								{errors.phone_number.message}
							</small>
						)}
					</div>

					<div className="min-w-[160px] flex-1">
						<CustomShadSelect
							className="w-full max-w-full text-[14px]"
							placeholder="Select Role"
							options={[
								{ label: "Admin", value: "admin" },
								{ label: "Guest", value: "guest" },
							]}
							onChange={(value) => {
								const role = value as "admin" | "guest";
								setValue("role", role);
							}}
						/>
						{errors.role && (
							<small className="mt-1 text-xs text-red-500">
								{errors.role.message}
							</small>
						)}
					</div>
				</div>
				<div className="flex w-full gap-2 pb-4">
					<DialogClose className="w-full rounded-md border border-primary bg-transparent px-4 py-2 text-primary">
						Cancel
					</DialogClose>
					<LoaderButton
						disabled={isLoading}
						className="w-full"
						variant="default"
						type="submit"
						loading={isLoading}
						loaderSize={24}
					>
						Send Invite
					</LoaderButton>
				</div>
			</form>
		</div>
	);
};

export default AddMember;
