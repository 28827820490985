import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MeetingFilterState {
	isOpen: boolean;
}

const initialState: MeetingFilterState = {
	isOpen: true,
};

const SideBarSlice = createSlice({
	name: "filters",
	initialState,
	reducers: {
		setIsOpen: (state, action: PayloadAction<boolean>) => {
			state.isOpen = action.payload;
		},
		reset: () => initialState,
	},
});

export const { setIsOpen, reset } = SideBarSlice.actions;
export default SideBarSlice.reducer;
