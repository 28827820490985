import useCustomToast from "@/components/CustomToast";
import { LoaderButton } from "@/components/Loader/LoaderButton";
import DayTimeslots from "@/components/modals/admin/locations/settings/DayTimeslots";
import { useAppSelector } from "@/hooks/hooks";
import {
	useGetLocationQuery,
	useUpdateLocationOperatingHoursMutation,
} from "@/redux/features/api/locationEndpoints";

import { OperatingHour } from "@/redux/slices/locationSlice";
import { defaultTimeSlots } from "@/services/constants";
import {
	convertSchedule,
	convertToOperatingHours,
	isTimeOverlapping,
} from "@/services/utilities";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router";

const EditLocationOperatingHours: React.FC = () => {
	const { location_id } = useParams();
	const user = useAppSelector((state) => state.user);
	const customToast = useCustomToast();

	const { data } = useGetLocationQuery({
		user_id: user?.user?.business_id ?? 0,
		location_id: location_id ? +location_id : 0,
	});

	const [slots, setSlots] = useState<OperatingHour[]>(
		data && data.data.working_hours.length
			? convertToOperatingHours(data.data.working_hours)
			: defaultTimeSlots
	);

	const [updateOperatingHours, { data: responseData, isSuccess, isLoading }] =
		useUpdateLocationOperatingHoursMutation();

	const { handleSubmit } = useForm<OperatingHour[]>();

	const onSubmit: SubmitHandler<OperatingHour[]> = async () => {
		try {
			if (isTimeOverlapping(slots)) return;
			await updateOperatingHours({
				id: user.user?.business_id ?? 0,
				location_id: parseInt(location_id ?? ""),
				data: {
					update_all_rooms: 1,
					rooms_to_update: [],
					data: convertSchedule(slots),
				},
			}).unwrap();
		} catch (error) {
			customToast("An error occured kindly try again later", {
				id: "operating-hours",
				type: "error",
			});
		}
	};

	const onSuccess = () => {
		customToast("Operating hours updated successfully", {
			id: "operating-hours",
			type: "success",
		});
	};

	useEffect(() => {
		if (isSuccess) onSuccess();
	}, [isSuccess, responseData]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<h1 className="py-3 text-xl font-semibold text-basecolor">
				Operating hours
			</h1>
			<div className="flex flex-col gap-y-3 overflow-scroll">
				{slots.map((slot, i) => (
					<DayTimeslots
						{...slot}
						key={i}
						index={i}
						slots={slots}
						shouldShowDay
						shouldShowPlus
						setSlots={setSlots}
					/>
				))}
			</div>
			<div className="mt-8 flex w-full justify-end">
				<div className="flex gap-2">
					{/* <Button
						variant="outlinecustom"
						disabled={isLoading}
						onClick={() =>
							navigate(
								"/admin/location-management/add-location/" +
									location_id
							)
						}
					>
						Back
					</Button> */}
					<LoaderButton
						variant="default"
						loading={isLoading}
						disabled={isLoading}
						loaderSize={16}
						type="submit"
					>
						Update
					</LoaderButton>
				</div>
			</div>
		</form>
	);
};

export default EditLocationOperatingHours;
