interface CapacityHolderProps {
	capacity: number;
}
const CapacityHolder: React.FC<CapacityHolderProps> = ({ capacity }) => {
	return (
		<div className="flex h-fit w-fit items-center gap-2 rounded-full bg-[#E3F2FF] px-2 py-1">
			<div className="flex items-center gap-1">
				<i className="mgc_group_2_line text-[12px] before:!text-primary" />
				<p className="text-[10px]">Capacity</p>
			</div>
			<p className="text-[12px] font-bold">{capacity}</p>
		</div>
	);
};

export default CapacityHolder;
