import BillingHistory from "@/components/admin/settings/BillingHistory";
import PaymentMethod from "@/components/admin/settings/PaymentMethod";
import Modal from "@/components/modals/Modal";
import AddCard from "@/components/modals/admin/settings/AddCard";
import { Button } from "@/components/ui/button";
import { useState } from "react";

const Billing = () => {
	const [showAddCard, setShowAddCard] = useState(false);

	return (
		<>
			<h1 className="text-2xl font-semibold text-basecolor">Payment</h1>
			<div className="pt-4 ">
				<p className="font-sm text-basecolor ">Saved Payment Methods</p>
				<PaymentMethod />
				<div className="flex justify-end pt-6">
					<Button
						className="ml-auto w-fit "
						variant="default"
						onClick={() => setShowAddCard(true)}
					>
						Add New Payment Method
					</Button>
				</div>
			</div>
			<div>
				<h1 className="text-2xl text-basecolor">Billings History</h1>
				<BillingHistory />
			</div>
			<Modal
				open={showAddCard}
				setOpen={setShowAddCard}
				dialogClassName="max-w-xl !rounded-[20px]"
			>
				<AddCard />
			</Modal>
		</>
	);
};

export default Billing;
