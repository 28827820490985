import CustomShadSelect from "@/components/common/CustomShadSelect";
import useCustomToast from "@/components/CustomToast";
import FormInput from "@/components/input/FormInput";
import { LoaderButton } from "@/components/Loader/LoaderButton";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useAuth } from "@/hooks/auth";
import { useAppSelector } from "@/hooks/hooks";
import { cn } from "@/lib/utils";
import {
	useGetInformationMutation,
	useUpdateAdminBusinessLogoMutation,
	useUpdateAdminBusinessMutation,
} from "@/redux/features/api/adminEndpoints";
import { allowedUploadFormats, countryCodes } from "@/services/constants";
import { handleFileChange, objectToFormData } from "@/services/utilities";
import {
	TeamMemberRoles,
	UpdateBusinessParams,
	UpdateBusinessSchema,
} from "@/types/team-member";
import { zodResolver } from "@hookform/resolvers/zod";
import lodash from "lodash";
import { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

const BusinessDetails: React.FC = () => {
	const [isEditing, setIsEditing] = useState(false);
	const [countryCode, setCountryCode] = useState("+1");
	const [logoUrl, setLogoUrl] = useState<string | null>(null);

	const [updateAdminBusiness] = useUpdateAdminBusinessMutation();
	const [updateAdminBusinessLogo, updateAdminBusinessLogoMutation] =
		useUpdateAdminBusinessLogoMutation();
	const [getBussinessInformation] = useGetInformationMutation();

	const user = useAppSelector((s) => s.user.user);
	const customToast = useCustomToast();

	const { setAuthUser } = useAuth();

	const [initialFormState] = useState<UpdateBusinessParams>({
		name: user?.business.name || "",
		email: user?.email || "",
		phone_number: user?.business?.phone_number ? user?.business?.phone_number?.slice(-10) : "" , // for some reason this phone_number doesn't come with the user details
		address: user?.business.address || "",
		city: user?.business.city || "",
		country: user?.business.country || "",
		state: user?.business.state || "",
		"zip-code": user?.business.zip_code || "",
	});

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors, isSubmitting },
		watch,
	} = useForm<UpdateBusinessParams>({
		resolver: zodResolver(UpdateBusinessSchema),
		defaultValues: initialFormState,
	});

	const logoRef: any | null = useRef(null);

	const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = handleFileChange(event, allowedUploadFormats, customToast);
		if (
			user &&
			(user.role === TeamMemberRoles.TeamMember ||
				user.role === TeamMemberRoles.PartnerManager)
		)
			return customToast("You are not allowed to perform this action", {
				id: "update-business",
				type: "error",
			});
		if (file) {
			try {
				setLogoUrl(URL.createObjectURL(file[0]));
				await updateAdminBusinessLogo(
					objectToFormData({
						logo: file[0],
					})
				).unwrap();

				await getBussinessInformation({})
					.unwrap()
					.then((res) => setAuthUser(res));

				setIsEditing(false);
				customToast("Logo updated successfully 🎉", {
					id: "update-business",
					type: "success",
				});
			} catch (error) {
				customToast("An error occured kindly try again later", {
					id: "update-business",
					type: "error",
				});
				// setError("root", {
				// 	message:
				// 		"An error occured kindly try again later",
				// });
			}
		}
	};

	const onSubmit: SubmitHandler<UpdateBusinessParams> = async (data) => {
		if (
			user &&
			(user.role === TeamMemberRoles.TeamMember ||
				user.role === TeamMemberRoles.PartnerManager)
		)
			return customToast("You are not allowed to perform this action", {
				id: "update-business",
				type: "error",
			});
		try {
			if (lodash.isEqual(data, initialFormState)) {
				setIsEditing(false);
				return;
			}

			const { logo, ...formDataWithoutLogo } = data;

			await updateAdminBusiness(
				objectToFormData({
					...formDataWithoutLogo,
					phone_number: countryCode + data.phone_number,
				})
			).unwrap();
			handleResetForm(data);
			await getBussinessInformation({})
				.unwrap()
				.then((res) => setAuthUser(res));
			setIsEditing(false);
			customToast("Profile updated successfully", {
				id: "update-business",
				type: "success",
			});
		} catch (error) {
			customToast("An error occured kindly try again later", {
				id: "update-business",
				type: "error",
			});
			// setError("root", {
			// 	message:
			// 		"An error occured kindly try again later",
			// });
		}
	};

	const handleResetForm = (newFormState: UpdateBusinessParams) => {
		reset(newFormState);
	};

	useEffect(() => {
		if (user?.business.phone_number)
			setCountryCode(user?.business.phone_number.slice(0, -10));
	}, [user]);

	return (
		<form
			className={
				"flex h-[80vh] w-full flex-1 flex-col justify-between px-7"
			}
			onSubmit={handleSubmit(onSubmit)}
		>
			<h1 className="mb-4 text-2xl font-semibold text-basecolor">
				Business Details
			</h1>
			<ScrollArea className="flex-1">
				<section className="flex items-center gap-x-4 py-8">
					<div className="h-[108px] w-[108px] rounded-[20px] border-[5px] border-solid border-white bg-[#E5E5E7] shadow-[0px_0px_5px_0px_rgba(0,0,0,0.25)]">
						{(logoUrl || user?.business.logo_url) && (
							<img
								src={(logoUrl || user?.business.logo_url) ?? ""}
								alt="Logo"
								className="h-full w-full rounded-[20px] object-scale-down"
							/>
						)}
					</div>
					<div className="flex flex-col gap-y-2">
						<LoaderButton
							className={cn(
								"flex max-w-fit space-x-1.5 px-3 py-2 text-[12px] transition-all duration-200 ease-in-out",
								{
									"cursor-not-allowed": !isEditing,
								}
							)}
							variant="outlinecustom"
							onClick={(e) => {
								logoRef.current.click();
								e.preventDefault();
							}}
							disabled={
								!isEditing ||
								updateAdminBusinessLogoMutation.isLoading
							}
							loading={updateAdminBusinessLogoMutation.isLoading}
							loaderSize={16}
						>
							<i className="mgc_upload_3_line text-[18px] before:!text-primary" />
							<small className="text-xs font-semibold leading-[20px]">
								Upload Logo
							</small>
						</LoaderButton>
						<input
							type="file"
							ref={logoRef}
							onChange={onFileChange}
							accept={allowedUploadFormats.join(",")}
							hidden
						/>
						<div className="flex flex-col gap-y-1.5 text-[#6B7280]">
							<p className="text-xs">
								File must be JPEG, JPG, PNG, SVG or GIF{" "}
							</p>
							<p className="text-xs">Max size of 2MB</p>
						</div>
						<p className="text-sm">
							{watch("logo") && watch("logo")?.name}
						</p>
					</div>
				</section>

				<section className="flex flex-col gap-y-4 pb-2">
					<FormInput
						label="Business Name"
						inputType="text"
						placeholder={isEditing ? "Business Name" : ""}
						className={cn(
							"w-1/2 outline-none transition-all duration-200  ease-in-out",
							{
								"shadow-[0px_0px_5px_0px_rgba(0,0,0,0.1)]s":
									isEditing,
								"cursor-not-allowed opacity-60": !isEditing,
							}
						)}
						readOnly={!isEditing}
						register={{ ...register("name") }}
						error={errors.name}
					/>

					<div className="flex-1">
						<label
							htmlFor="phone-number"
							className="mb-1.5 block text-sm font-medium leading-[20px] tracking-[-0.1px] text-[#323539]"
						>
							Phone Number
						</label>
						<div>
							<div className="flex">
								<CustomShadSelect
									className={cn(
										"h-[42px] w-fit rounded-r-none border-r-transparent",
										{
											"pointer-events-none cursor-not-allowed opacity-60":
												!isEditing,
										}
									)}
									placeholder="+1"
									label="Country Codes"
									options={countryCodes}
									value={countryCode}
									onChange={(value) => {
										setCountryCode(value);
									}}
									labelClassName="px-2"
									itemClassName="px-8"
								/>
								<FormInput
									inputType="text"
									className={cn(
										"h-[42px] rounded-l-none py-0 outline-none transition-all duration-200  ease-in-out",
										{
											"shadow-[0px_0px_5px_0px_rgba(0,0,0,0.1)]s":
												isEditing,
											"cursor-not-allowed opacity-60":
												!isEditing,
										}
									)}
									maxLength={10}
									value={watch("phone_number")}
									error={
										errors?.phone_number?.message
											? ""
											: undefined
									}
									onChange={(e) => {
										setValue(
											"phone_number",
											e.target.value
										);
									}}
									readOnly={!isEditing}
								/>
							</div>
							{errors.phone_number && (
								<small className="mt-1 text-xs text-red-500">
									{errors.phone_number.message}
								</small>
							)}
						</div>
					</div>
					<FormInput
						label="Email Address"
						inputType="text"
						placeholder={isEditing ? "Email Address" : ""}
						className={cn(
							"outline-none transition-all duration-200  ease-in-out",
							{
								"shadow-[0px_0px_5px_0px_rgba(0,0,0,0.1)]s":
									isEditing,
								"cursor-not-allowed opacity-60": !isEditing,
							}
						)}
						readOnly={!isEditing}
						register={{ ...register("email") }}
						error={errors.email}
					/>
					<div className="flex flex-col gap-4">
						<FormInput
							inputType="text"
							label="Address"
							placeholder={isEditing ? "Address Line 1" : ""}
							className={cn(
								"outline-none transition-all duration-200  ease-in-out",
								{
									"shadow-[0px_0px_5px_0px_rgba(0,0,0,0.1)]s":
										isEditing,
									"cursor-not-allowed opacity-60": !isEditing,
								}
							)}
							readOnly={!isEditing}
							register={{ ...register("address") }}
							error={errors.address}
						/>
						<FormInput
							inputType="text"
							placeholder={isEditing ? "Address Line 2" : ""}
							className={cn(
								"outline-none transition-all duration-200  ease-in-out",
								{
									"shadow-[0px_0px_5px_0px_rgba(0,0,0,0.1)]s":
										isEditing,
									"cursor-not-allowed opacity-60": !isEditing,
								}
							)}
							readOnly={!isEditing}
						/>
						<div className="grid grid-cols-2 gap-2">
							<FormInput
								inputType="text"
								placeholder={isEditing ? "City" : ""}
								className={cn(
									"outline-none transition-all duration-200  ease-in-out",
									{
										"shadow-[0px_0px_5px_0px_rgba(0,0,0,0.1)]s":
											isEditing,
										"cursor-not-allowed opacity-60":
											!isEditing,
									}
								)}
								readOnly={!isEditing}
								register={{ ...register("city") }}
								error={errors.city}
							/>
							<FormInput
								inputType="text"
								placeholder={isEditing ? "Zip Code" : ""}
								className={cn(
									"outline-none transition-all duration-200  ease-in-out",
									{
										"shadow-[0px_0px_5px_0px_rgba(0,0,0,0.1)]s":
											isEditing,
										"cursor-not-allowed opacity-60":
											!isEditing,
									}
								)}
								readOnly={!isEditing}
								register={{ ...register("zip-code") }}
								error={errors["zip-code"]}
								maxLength={11}
							/>
							<FormInput
								inputType="text"
								placeholder={isEditing ? "State" : ""}
								className={cn(
									"outline-none transition-all duration-200  ease-in-out",
									{
										"shadow-[0px_0px_5px_0px_rgba(0,0,0,0.1)]s":
											isEditing,
										"cursor-not-allowed opacity-60":
											!isEditing,
									}
								)}
								readOnly={!isEditing}
								register={{ ...register("state") }}
								error={errors.state}
							/>
							<FormInput
								inputType="text"
								placeholder={isEditing ? "Country" : ""}
								className={cn(
									"outline-none transition-all duration-200  ease-in-out",
									{
										"shadow-[0px_0px_5px_0px_rgba(0,0,0,0.1)]s":
											isEditing,
										"cursor-not-allowed opacity-60":
											!isEditing,
									}
								)}
								readOnly={!isEditing}
								register={{ ...register("country") }}
								error={errors.country}
							/>
						</div>
					</div>
				</section>
			</ScrollArea>

			{user && user.role !== TeamMemberRoles.TeamMember && (
				<section className="mt-1 flex items-center space-x-6 self-end">
					{isEditing && (
						<Button
							className={cn("self-end px-5 py-2", {
								"cursor-not-allowed": isSubmitting,
							})}
							variant="outline"
							onClick={() => {
								setIsEditing(false);
								handleResetForm(initialFormState);
							}}
							disabled={isSubmitting}
						>
							Cancel
						</Button>
					)}
					{isEditing ? (
						<LoaderButton
							className={cn("self-end px-5 py-2", {
								"cursor-not-allowed": isSubmitting,
							})}
							loading={isSubmitting}
							disabled={isSubmitting}
							loaderSize={16}
							type="submit"
						>
							Save
						</LoaderButton>
					) : (
						<Button
							className={cn("self-end px-5 py-2", {
								"cursor-not-allowed": isSubmitting,
							})}
							onClick={(e) => {
								if (!isEditing) setIsEditing(true);
								e.preventDefault();
							}}
							disabled={isSubmitting}
							type="button"
						>
							Edit
						</Button>
					)}
				</section>
			)}
		</form>
	);
};

export default BusinessDetails;
