import AmenityHolder from "@/components/common/AmenityHolder";
import CapacityHolder from "@/components/common/CapacityHolder";
import TimeSlotPicker from "@/components/common/TimeSlotPicker";
import { Button } from "@/components/ui/button";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import React, { Dispatch, SetStateAction } from "react";
import { RoomCardProps } from "./booking/book/room/RoomCard";
import { cn } from "@/lib/utils";

const RoomCardContent: React.FC<
	RoomCardProps & {
		openBooking: boolean;
		setOpenBooking: Dispatch<SetStateAction<boolean>>;
	}
> = ({ room, isLoading, setOpenBooking }) => {
	const isBookable = room?.time_slots?.length > 0;
	return (
		<>
			<div className="flex flex-row gap-2 md:flex-col">
				{!room?.photos[0]?.image_url ? (
					<div className="flex h-[72px] w-full flex-col justify-center bg-[#DCDCDC] px-4 md:h-[119px] md:rounded-t-md mmd:max-w-[150px] mmd:rounded-md">
						<div className="flex h-[41px] w-[41px] items-center justify-center rounded-full bg-[#C7C7C7] md:h-[50px] md:w-[50px]">
							<p className="select-none text-lg font-semibold text-white">
								{room?.name.split(" ")[0][0].toUpperCase()}
							</p>
						</div>
					</div>
				) : (
					<img
						src={room?.photos[0]?.image_url ?? ".."}
						alt={room?.photos[0]?.file_name}
						className="h-[72px] w-full object-cover md:h-[119px] md:w-full md:rounded-t-md mmd:max-w-[150px] mmd:rounded-md"
					/>
				)}

				<div className="items-left flex w-full flex-col justify-between gap-2 md:flex-row md:items-center">
					<div className="">
						<div className="flex justify-between space-x-1">
							<Tooltip delayDuration={0}>
								<TooltipTrigger asChild>
									<div className="flex items-center">
										<Button
											variant="default"
											className="h-[18px] w-[22px] bg-[#F5F5F5] p-0 hover:bg-[#F5F5F5] mmd:hidden"
											onClick={() => setOpenBooking(true)}
										>
											<i className="mgc_information_line before:!text-[#323539]" />
										</Button>
										<span className="line-clamp-1 text-[14px] font-semibold">
											{room.name}
										</span>
									</div>
								</TooltipTrigger>
								<TooltipContent side="top" sideOffset={4}>
									{room.name}
								</TooltipContent>
							</Tooltip>
							<Button
								variant="default"
								className="h-[18px] w-[22px] bg-[#F5F5F5] p-0 hover:bg-[#F5F5F5] md:hidden"
								onClick={() => setOpenBooking(true)}
							>
								<i className="mgc_information_line before:!text-[#323539]" />
							</Button>
						</div>
						{room.address && (
							<span className="flex items-center gap-2 text-[10px]">
								<i className="mgc_location_3_line text-[12px] before:!text-[#323539] pl-1" />
								<span>{room.address}</span>
							</span>
						)}
					</div>
					<CapacityHolder capacity={room.capacity} />
				</div>
			</div>

			<div className="flex w-full flex-col gap-y-2 pl-2">
				<div className="space-y-1 pb-1">
					<h1 className="text-[8px] font-normal uppercase tracking-[3px] text-[#6B7280]">
						Amenites
					</h1>
					<div className={cn("flex flex-wrap items-center gap-2 text-[10px]", { "text-[#959595]": !room?.amenities.length})}>
						{room?.amenities.length
							? room?.amenities?.map(
									(amenity: any, index: number) => (
										<AmenityHolder
											key={index}
											amenity={amenity.name}
											variant="list"
										/>
									)
								)
							: "No amenities available"}
					</div>
				</div>
				<div className="mx-auto w-full max-w-xs">
					<TimeSlotPicker
						timeslots={
							room?.time_slots.map((time) => time.slice(0, -3)) ??
							[]
						}
						isLoading={isLoading}
					/>
				</div>
			</div>

			<div className="flex justify-end rounded-none">
				<Button
					className="ml-auto h-[32px] rounded-md px-12 py-1"
					disabled={!isBookable}
					variant="outlinecustom"
					onClick={() => setOpenBooking(true)}
				>
					View Details
				</Button>
			</div>
		</>
	);
};

export default RoomCardContent;
