import Modal from "@/components/modals/Modal";
import BookingOverview from "@/components/modals/admin/bookings/BookingOverview";
import { cn } from "@/lib/utils";
import { useEffect, useState } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import { LuBellRing } from "react-icons/lu";
import { PiCheckCircle } from "react-icons/pi";
import { TbCalendarStats } from "react-icons/tb";
// import RescheduleBooking from "./modals/RescheduleBooking";
import RescheduleConfirmationCard from "@/components/modals/admin/partners/RescheduleCard";
import { useUpdateAdminBookingStatusMutation } from "@/redux/features/apiSlice";
import { message } from "antd";

interface EventActionPaneProps {
	id: any;
	internal: boolean;
	eventStatus:
		| "pending"
		| "approved"
		| "done"
		| "cancelled"
		| "declined"
		| "rescheduled"
		| "onprogress";
	onClose: () => void;
}
const EventActionPane = ({
	id,
	eventStatus,
	internal,
	onClose,
}: EventActionPaneProps) => {
	const [showBookingInfo, setShowBookingInfo] = useState(false);
	const [showRescheduleBooking, setShowResheduleBooking] = useState(false);
	const [submittedStatus, setSubmittedStatus] = useState<string | null>(null);

	const [messageApi, contextHolder] = message.useMessage();
	const key = "updateBookingStatus";
	const [updateStatus, { isSuccess, isError, isLoading }] =
		useUpdateAdminBookingStatusMutation();

	const handleApproveBooking = async () => {
		messageApi.open({
			key,
			type: "loading",
			content: "Approving reservation...",
		});
		setSubmittedStatus("approved");

		await updateStatus({
			booking_id: id,
			data: { status: "approved" },
		});
		onClose();
	};
	const handleDeclineBooking = async () => {
		messageApi.open({
			key,
			type: "loading",
			content: "Declining reservation...",
		});
		setSubmittedStatus("declined");

		await updateStatus({
			booking_id: id,
			data: { status: "declined" },
		});
		onClose();
	};
	const handleRescheduleBooking = async (message) => {
		messageApi.open({
			key,
			type: "loading",
			content: "Requesting reschedule...",
			duration: 0,
		});
		setSubmittedStatus("reschedule");
		await updateStatus({
			booking_id: id,
			data: {
				status: "provider_requested_reschedule",
				message_to_host: message,
			},
		});
		onClose();
	};
	const handleNotifyHost = async () => {
		messageApi.open({
			key,
			type: "loading",
			content: "Notifying Host...",
		});
		setSubmittedStatus("notified");
		await updateStatus({
			booking_id: id,
			data: { status: "notify" },
		});
		onClose();
	};
	useEffect(() => {
		if (isSuccess) {
			messageApi.open({
				key,
				type: "success",
				content:
					submittedStatus === "approved"
						? "Reservation status updated successfully!"
						: submittedStatus === "notified"
							? "Host was notified"
							: submittedStatus === "reschedule"
								? "Reservation rescheduled successfully"
								: "Reservation declined.",
				duration: 2,
			});
		}

		if (isError) {
			messageApi.open({
				key,
				type: "error",
				content:
					submittedStatus === "approved"
						? "Error approving reservation."
						: submittedStatus === "notified"
							? "Error in notifying host "
							: submittedStatus === "reschedule"
								? "Error in rescheduling"
								: "Error declining reservation.",
				duration: 2,
			});
		}
	}, [isSuccess, isError, submittedStatus, messageApi]);
	return (
		<>
			<div
				className={cn(
					"flex flex-col items-center justify-start gap-4 text-[14px] font-[400] disabled:opacity-50"
				)}
				aria-disabled={isLoading}
			>
				{contextHolder}
				<button
					className="flex items-center gap-2 self-start px-0 text-[14px]"
					onClick={() => {
						setShowBookingInfo(true);
					}}
				>
					<BsInfoCircle className="text-[14px]" />
					Details
				</button>
				{eventStatus === "approved" && !internal && (
					<button
						className="flex items-center gap-2 self-start text-[14px] text-[#2AA63C]"
						onClick={handleNotifyHost}
					>
						<LuBellRing className="text-sm" />
						Notify
					</button>
				)}
				{eventStatus == "pending" && (
					<button
						className="flex items-center gap-2 self-start text-[14px] text-[#2AA63C]"
						onClick={handleApproveBooking}
					>
						<PiCheckCircle />
						Approve
					</button>
				)}

				{eventStatus === "approved" && !internal && (
					<button
						className="flex items-center gap-2 self-start text-[14px]"
						onClick={() => {
							onClose();
							setShowResheduleBooking(true);
						}}
					>
						<TbCalendarStats />
						Reschedule
					</button>
				)}

				{eventStatus !== "done" &&
					eventStatus !== "cancelled" &&
					eventStatus !== "declined" &&
					eventStatus !== "onprogress" && (
						<button
							className=" flex items-center gap-2 self-start text-[14px] text-[#C8322B]"
							onClick={handleDeclineBooking}
						>
							<IoMdRemoveCircleOutline />
							Decline
						</button>
					)}
			</div>

			<Modal
				dialogClassName="max-w-2xl p-0 pt-2"
				open={showBookingInfo}
				setOpen={setShowBookingInfo}
			>
				<BookingOverview
					activetab={"booking"}
					status={eventStatus}
					handleClose={() => setShowBookingInfo(false)}
					id={id}
				/>
			</Modal>

			<Modal
				open={showRescheduleBooking}
				setOpen={setShowResheduleBooking}
				dialogClassName="max-w-[400px] p-6 !rounded-lg bg-white"
			>
				<RescheduleConfirmationCard
					handleReschedule={handleRescheduleBooking}
					isLoading={false}
				/>
			</Modal>
			{/* <Modal
				dialogClassName="max-w-xl p-0 pt-2"
				open={showRescheduleBooking}
				setOpen={setShowResheduleBooking}
			>
				<div className="w-full p-4">
					<RescheduleConfirmationCard/>
				</div>
			</Modal> */}
		</>
	);
};

export default EventActionPane;
