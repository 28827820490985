import AdminSidebar from "@/components/sidebar/AdminSidebar";
import { useAppSelector } from "@/hooks/hooks";
import { cn } from "@/lib/utils";
import { Navigate, Outlet } from "react-router";

const AdminLayout: React.FC = () => {
	const user = useAppSelector((state) => state.user.user);

	if (
		(user && user?.role === "PARTENER_SUPER_ADMIN") ||
		user?.role === "PARTNER_ADMIN"
	) {
		return <Navigate to="/partner/rooms" replace />;
	}
	return (
		<main className="relative flex h-[100svh] flex-col">
			{/* <Sidebar /> */}
			<AdminSidebar />
			<div className="flex min-h-[100svh] w-full flex-col justify-between p-2">
				{/* //Add topbar here too  */}
				<div
					className={cn(
						"flex w-full flex-1 flex-col justify-between overflow-auto p-4 pl-[88px]"
					)}
					// ref={aRef}
				>
					<Outlet />
					{/* <div className="sticky bottom-0 left-0 w-full bg-white py-2 text-center ">
						<Footer />
					</div> */}
				</div>
				{/* <div className="flex-1 overflow-y-auto p-2 py-2 md:p-4 lg:ml-16">
					<Outlet />
				</div>
				<div className="sticky bottom-0 left-0 w-full bg-transparent py-2 text-center ">
					<Footer />
				</div> */}
			</div>
		</main>
	);
};

export default AdminLayout;
