import RoomCardContent from "@/components/admin/RoomCardContent";
import AdminRoomOverview from "@/components/modals/AdminRoomOverview";
import Modal from "@/components/modals/Modal";
import { TooltipProvider } from "@/components/ui/tooltip";
import useScreenDimension from "@/hooks/useScreenDimension";
import { Room } from "@/types/admin/booking";
import { useState } from "react";

export interface RoomCardProps {
	room: Room;
	isLoading: boolean;
}

const RoomCard: React.FC<RoomCardProps> = ({ room, isLoading }) => {
	const { width } = useScreenDimension();
	const isMobile = width < 800;
	const [openBooking, setOpenBooking] = useState(false);

	return (
		<TooltipProvider>
			<div className="flex flex-col gap-y-2 rounded-xl bg-[#FBFBFB] p-1.5 drop-shadow-[0_0_6px_rgba(0,0,0,0.15)]">
				<RoomCardContent
					room={room}
					isLoading={isLoading}
					openBooking={openBooking}
					setOpenBooking={setOpenBooking}
				/>
				<Modal
					dialogClassName=" p-0 max-w-2xl "
					onInteractOutside={(e) => {
						e.preventDefault();
					}}
					open={!isMobile && openBooking}
					setOpen={setOpenBooking}
				>
					<div className="w-full pt-4">
						<AdminRoomOverview room={room} />
					</div>
				</Modal>
			</div>
		</TooltipProvider>
	);
};

export default RoomCard;
