import { useAuth } from "@/hooks/auth";
import { useAppDispatch, useAppSelector } from "@/hooks/hooks";
import { cn } from "@/lib/utils";
import { setIsOpen } from "@/redux/slices/sideBarSlice";
import { motion } from "framer-motion";
import { useLayoutEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { getInitials, RoutePath } from "../../services/utilities";
import { Button } from "../ui/button";
import { ScrollArea } from "../ui/scroll-area";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "../ui/tooltip";

type NavItem = {
	icon: React.ReactNode;
	label: string;
	path: string; //Update once the paths are complete
};

const navItems: NavItem[] = [
	{
		icon: "mgc_list_check_3_line",
		label: "Spaces",
		path: RoutePath.PARTNERS_ROOMS,
	},
	{
		icon: "mgc_calendar_time_add_line",
		label: "Meetings",
		path: RoutePath.PARTNERS_MEETINGS,
	},
	{
		icon: "mgc_group_line",
		label: "Users",
		path: RoutePath.PARTNER_TEAM_MEMBERS,
	},
];

const PartnerSidebar = () => {
	const location = useLocation();
	const dispatch = useAppDispatch();
	const user = useAppSelector((s) => s.user.user);
	const isOpen = useAppSelector((state) => state.sidebar.isOpen);

	// const { innerWidth } = useAppSelector((state) => state.sidebar);
	const [isIconView, setIsIconView] = useState(true);
	// const [innerWidth, setInnerWidth] = useState(0);
	const { logout } = useAuth();
	const [variant, setVariant] = useState<"icon" | "default">("icon");

	const handleMouseEnter = () => {
		setVariant("default");
		setIsIconView(false);
	};

	const handleMouseLeave = () => {
		setVariant("icon");
		setIsIconView(true);
	};

	useLayoutEffect(() => {
		const handleResize = () => {
			if (window.innerWidth < 768 && !isOpen) dispatch(setIsOpen(true));
			else if (window.innerWidth > 768) dispatch(setIsOpen(true));
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	// useEffect(() => {
	// 	const handleClickOutside = (event: MouseEvent) => {
	// 		if (
	// 			aRef.current &&
	// 			!aRef.current.contains(event.target as Node)
	// 		) {
	// 			if (isOpen) dispatch(setIsOpen(false));
	// 			// else dispatch(setIsOpen(true));
	// 		}
	// 	};

	// 	window.addEventListener("mousedown", handleClickOutside);
	// 	return () => {
	// 		window.removeEventListener("mousedown", handleClickOutside);
	// 	};
	// }, []);

	return (
		<TooltipProvider>
			<motion.aside
				initial={{ x: "-100%" }}
				animate={{ x: isOpen ? 0 : "-100%" }}
				transition={{
					type: "spring",
					stiffness: 300,
					damping: 30,
					duration: 0.15,
				}}
				className={cn(
					"absolute left-0 top-0 z-10 h-[100svh] bg-primary duration-100",
					{
						"w-[160px] min-w-[160px]": variant === "default",
						"w-[60px] min-w-[60px]": variant === "icon",
						// sticky: innerWidth > 768,
					}
				)}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				<div className="relative flex h-full w-full flex-col py-2">
					<div className="my-3 flex items-center gap-2 px-2">
						<NavLink
							to={RoutePath.PARTNERS_SETTINGS}
							className={cn(
								"flex w-fit items-center justify-center gap-2 rounded-full px-2 text-white"
								// {
								// 	"bg-white":
								// 		location.pathname.includes(
								// 			RoutePath.PARTNERS_SETTINGS
								// 		),
								// }
							)}
						>
							<p
								className={cn(
									"w-fit rounded-full bg-primary p-1.5 font-semibold text-white",
									{
										"bg-white text-primary":
											location.pathname.includes(
												RoutePath.PARTNERS_SETTINGS
											),
									}
								)}
							>
								{getInitials(user?.name ?? "")}
							</p>
							{isIconView ? null : (
								<p
									className={cn(
										" whitespace-nowrap text-white",
										{
											"text-white":
												location.pathname.includes(
													RoutePath.PARTNERS_SETTINGS
												),
										}
									)}
								>
									My Account
								</p>
							)}
						</NavLink>
					</div>
					<ScrollArea className=" max-h-[100svh]">
						{navItems.map((item) => {
							const isActive = location.pathname.includes(
								item.path
							);
							return isIconView ? (
								<Tooltip key={item.label} delayDuration={0}>
									<TooltipTrigger asChild>
										<NavLink
											to={item.path}
											className={cn(
												"mb-2 flex items-center justify-center gap-2 px-2 py-2.5 text-sm text-white transition-all",
												{
													"bg-white text-primary":
														isActive,
												}
											)}
										>
											<i
												className={cn(
													`${item.icon} text-[20px] before:!text-white`,
													{
														"before:!text-primary":
															isActive,
													}
												)}
											/>
										</NavLink>
									</TooltipTrigger>
									<TooltipContent
										side="right"
										sideOffset={10}
									>
										{item.label}
									</TooltipContent>
								</Tooltip>
							) : (
								// <NavLink key={item.label} to={item.path}>
								// 	<div
								// 		className={cn(
								// 			"mb-2 flex items-center gap-2 px-2 py-2.5 text-sm text-white transition-all",
								// 			{
								// 				"bg-white text-primary":
								// 					isActive,
								// 			}
								// 		)}
								// 	>
								// 		<div>{item.icon}</div>
								// 		<div>{item.label}</div>
								// 	</div>
								// </NavLink>
								<NavLink
									className={cn("flex", {
										"bg-white": isActive,
									})}
									key={item.label}
									to={item.path}
								>
									<div
										className={cn(
											"mb-2 flex items-center gap-2 px-6 py-2.5 text-base font-medium text-white transition-all",
											{
												"text-primary": isActive,
											}
										)}
									>
										<i
											className={cn(
												`${item.icon} text-[20px] before:!text-white`,
												{
													"before:!text-primary":
														isActive,
												}
											)}
										/>
										<div>{item.label}</div>
									</div>
								</NavLink>
							);
						})}
					</ScrollArea>
					<div className=" mt-auto pb-4 text-sm">
						{isIconView ? (
							<>
								<Tooltip delayDuration={0}>
									<TooltipTrigger asChild>
										<Button
											onClick={logout}
											className="w-full text-white hover:bg-primary hover:text-white"
											variant="ghost"
											size="icon"
										>
											<i className="mgc_entrance_line text-[18px] before:!text-white" />
										</Button>
									</TooltipTrigger>
									<TooltipContent
										side="right"
										sideOffset={10}
									>
										Sign out
									</TooltipContent>
								</Tooltip>
							</>
						) : (
							<>
								<Button
									className="w-full rounded-none text-white hover:bg-primary hover:text-white"
									variant="ghost"
									onClick={logout}
								>
									<i className="mgc_entrance_line text-[18px] before:!text-white hover:before:!text-primary" />
									<span className="ml-2">Sign out</span>
								</Button>
							</>
						)}
					</div>
				</div>
			</motion.aside>
		</TooltipProvider>
	);
};

export default PartnerSidebar;
