import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import LocalStorage from "../../services/storage";
import { Location, TimeSlot } from "../../types/location.ts";
import { defaultTimeSlots } from "@/services/constants.ts";

const storage = new LocalStorage();

function saveStorage(constant, data) {
	storage["setLocalStorage"](constant, data);
}

export interface OperatingHour {
	day: string;
	day_value: number;
	id?: number;
	is_active: 0 | 1;
	location?: any;
	location_id?: number;
	service_time_slots?: any[];
	product_working_hour_id?: number;
	time_slots: TimeSlot[];
	created_at?: string;
	updated_at?: string;
}

interface Member {
	firstName: string;
	lastName: string;
	email: string;
	phone_number: string;
}

const initialLocationState: Location = {
	name: "",
	phone_number: "",
	email: "",
	address: "",
	address_2: "",
	country: "",
	state: "",
	city: "",
	zip_code: "",
	url_code: "",
	time_zone: "",
};

const initialOperatingHoursState: OperatingHour[] = defaultTimeSlots;

const initialMemberState: Member = {
	firstName: "",
	lastName: "",
	email: "",
	phone_number: "",
};

const initialState = {
	location: initialLocationState,
	operatingHours: initialOperatingHoursState,
	member: initialMemberState,
};

export const locationSlice = createSlice({
	name: "location",
	initialState,
	reducers: {
		updateLocation(state, action: PayloadAction<Partial<Location>>) {
			saveStorage("location", {
				location: action.payload,
			});
			state.location = {
				...state.location,
				...action.payload,
			};
		},
		updateOperatingHours(state, action: PayloadAction<OperatingHour[]>) {
			saveStorage("operatingHours", {
				operatingHours: action.payload,
			});
			state.operatingHours = action.payload;
		},
		updateMember(state, action: PayloadAction<Partial<Member>>) {
			saveStorage("member", {
				member: action.payload,
			});
			state.member = {
				...state.member,
				...action.payload,
			};
		},
	},
});

export const { updateLocation, updateOperatingHours, updateMember } =
	locationSlice.actions;

export default locationSlice.reducer;
