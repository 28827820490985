import { useCallback, useEffect } from "react";
import { useAppSelector } from "./hooks";

declare global {
	interface Window {
		helpcrunchSettings: {
			organization: string;
			appId: string;
			user: {
				user_id: string;
				email: string;
				name: string;
				phone: string;
				company: string;
			};
		};
	}
}

const useHelpCrunchIdentify: () => void = () => {
	const user = useAppSelector((state) => state.user.user);

	const handleHelpCrunchIdentify = useCallback(() => {
		if (user)
			window.helpcrunchSettings = {
				organization: "migranium",
				appId: import.meta.env.VITE_HELP_CRUNCH_APP_ID,
				user: {
					user_id: user.id.toString(),
					email: user.email,
					name: user.name,
					phone: user.business.phone_number,
					company: user.business.name,
				},
			};

		(function (w: any, d: any) {
			const hS = w.helpcrunchSettings;
			if (!hS || !hS.organization) {
				return;
			}
			const widgetSrc = "https://embed.helpcrunch.com/sdk.js";
			w.HelpCrunch = function () {
				// eslint-disable-next-line prefer-rest-params
				w.HelpCrunch.q.push(arguments);
			};
			w.HelpCrunch.q = [];
			function r() {
				if (d.querySelector('script[src="' + widgetSrc + '"')) {
					return;
				}
				const s = d.createElement("script");
				s.async = 1;
				s.type = "text/javascript";
				s.src = widgetSrc;
				(d.body || d.head).appendChild(s);
			}

			if (d.readyState === "complete" || hS.loadImmediately) {
				r();
			} else if (w.attachEvent) {
				w.attachEvent("onload", r);
			} else {
				w.addEventListener("load", r, false);
			}
		})(window, document);
	}, [user]);

	useEffect(() => {
		handleHelpCrunchIdentify();
	}, [handleHelpCrunchIdentify]);
};

export default useHelpCrunchIdentify;
