import { useState } from "react";

import ApprovedBooking from "@/components/admin/booking/management/modals/ApprovedBooking";
import PendingBooking from "@/components/admin/booking/management/modals/PendingBooking";
import EditBooking from "./EditBooking";

interface BookingInfoProps {
	status: "approved" | "pending" | "done" | "cancelled";
	handleClose: () => void;
	data: any;
}
const BookingInfo = ({ status, handleClose, data }: BookingInfoProps) => {
	const [editBooking, setEditBooking] = useState(false);
	const [type, setType] = useState<"normal" | "occurence" | "series">("normal");
	const handleRescheduleTrigger = (type: "normal" | "occurence" | "series") => {
		setEditBooking(true);
		setType(type);
	};
	return (
		<div>
			<div>
				{status !== "pending" && (
					<>
						{editBooking ? (
							<EditBooking
								type={type}
								room={data?.room}
								bookingdata={data}
								onUpdateSuccess={()=>setEditBooking(false)}
								isAdmin
							/>
						) : (
							<ApprovedBooking
								data={data}
								handleReschedule={handleRescheduleTrigger}
								handleClose={handleClose}
							/>
						)}
					</>
				)}
				{status === "pending" && <PendingBooking data={data} />}
			</div>
		</div>
	);
};

export default BookingInfo;
