/**
 *
 * DON'T TOUCH, TO BE REFACTORED
 */

import CustomSelect from "@/components/common/CustomSelect";
import TimeSlotPicker from "@/components/common/TimeSlotPicker";
import FormInput from "@/components/input/FormInput";
import AttendeeSelector from "@/components/partner/room/AttendeeSelector";
import { Button } from "@/components/ui/button";
import { DatePicker } from "@/components/ui/datepicker";
import { DialogClose } from "@/components/ui/dialog";
import { Switch } from "@/components/ui/switch";
import { useAppSelector } from "@/hooks/hooks";
import useScreenDimension from "@/hooks/useScreenDimension";
import { cn } from "@/lib/utils";
import {
	useGetRoomTimeslotsQuery,
	useUpdateBookingMutation,
} from "@/redux/features/api/partnerEndpoints";
import { RepeatTimeOptions } from "@/services/constants";
import {
	formatTime,
	generateBookingEndTimeOptions,
	generateDurationString,
	insertAndSortTimes,
	notifyWithIcon,
	selectRepeatOption,
} from "@/services/utilities";
import { Booking } from "@/types/admin/booking";
import { LoadingOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaRegClock } from "react-icons/fa6";

const EditBooking = ({
	room,
	bookingdata,
	// type,
	isAdmin,
	onUpdateSuccess,
}: {
	room: any;
	// type: "normal" | "occurence" | "series";
	bookingdata: Booking;
	onUpdateSuccess?: () => void;
	isAdmin?: boolean;
}) => {
	const { width } = useScreenDimension();
	const user = useAppSelector((state) => state.user);
	const isRecurring = bookingdata?.is_recurring;
	const [timeslots, setTimeSlots] = useState<string[]>(room.time_slots ?? []);
	const companyId = isAdmin ? bookingdata.company.id : 0;
	const [rooms, setRooms] = useState<{ label: string; value: string }[]>();
	const [selectedRoom, setSelectedRoom] = useState<{
		label: string;
		value: string;
	}>();

	const [repeatSelection, setRepeatSelection] = useState<
		| {
				label: string;
				value: string;
		  }
		| undefined
	>(!isRecurring ? selectRepeatOption("") : selectRepeatOption("weekly"));

	const [selectedAttendees, setSelectedAttendees] = useState(
		bookingdata?.meeting_members
			? bookingdata.meeting_members.map(
					(member: any) => member?.member_profile?.email
				)
			: []
	);

	// console.log(bookingdata);
	const [formData, setFormData] = useState({
		date: bookingdata?.start_at.split(" ")[0] ?? DateTime.now(),
		room_id: bookingdata?.room?.id,
		duration: bookingdata?.duration,
		endTime: bookingdata?.end_time,
		description: bookingdata?.description,
		title: bookingdata?.title,
		isAllDay: !bookingdata?.end_time,
		startTime: bookingdata?.start_time,
	});

	const [startTimeError, setStartTimeError] = useState(false);

	const {
		register,
		handleSubmit: handleFormSubmit,
		formState: { errors },
	} = useForm();
	const isBookable =
		insertAndSortTimes(
			[...timeslots],
			bookingdata.start_time,
			formData.date === bookingdata?.start_at.split(" ")[0]
		).length > 0;

	const {
		isSuccess,
		data,
		isFetching: isGetRoomTimeSlotsLoading,
	} = useGetRoomTimeslotsQuery({
		date: formData.date,
		room_id: selectedRoom
			? selectedRoom.value
			: bookingdata.room_id.toString(),
		// duration: formData.duration,
		duration: 60,
		is_allday: formData.isAllDay ? 1 : 0,
		company_id: isAdmin ? companyId : undefined,
	});

	const [
		updateBooking,
		{
			data: isUpdateData,
			isLoading: isUpdateLoading,
			error: updateError,
			isError: isUpdateError,
			isSuccess: isUpdateSuccess,
		},
	] = useUpdateBookingMutation();

	//Add update recurring meeting here too

	const onSubmit = () => {
		if (!formData.startTime.length) return setStartTimeError(true);
		const submittedData: {
			booking_id: number;
			data: {
				room_id: number | string;
				title: string;
				members: string[];
				date: Date | string;
				description: string;
				is_allday: number | boolean;
				duration: number | string;
				host_id: number | string;
				start_time?: string;
			};
			companyId?: number;
		} = {
			booking_id: bookingdata?.id,
			data: {
				room_id: bookingdata.room?.id,
				title: formData.title,
				members: selectedAttendees?.map((attendee) => attendee.email),
				date: formData.date,
				description: formData.description as string,
				is_allday: formData.isAllDay,
				start_time: formData.startTime.split(":").slice(0, 2).join(":"),
				duration: formData.duration,
				host_id: bookingdata?.host_user_id,
			},
			companyId: isAdmin ? companyId : undefined,
		};
		// const recurringData = {
		// 	data
		// }
		updateBooking(submittedData);
	};

	useEffect(() => {
		if (isSuccess && data.data) {
			setTimeSlots(data.data);
		}
	}, [isSuccess, data]);

	useEffect(() => {
		if (isUpdateSuccess) {
			notifyWithIcon("", "Meeting Details Updated successfully!");
			if (onUpdateSuccess) onUpdateSuccess();
		} else if (isUpdateError) {
			if (updateError) {
				const errorMessage =
					(
						updateError as {
							data?: { message?: string };
						}
					)?.data?.message || "An error occurred";
				return notifyWithIcon("", errorMessage || "Unknown error");
			}
		}
	}, [isUpdateSuccess, isUpdateError, isUpdateData]);

	useEffect(() => {
		if (isSuccess && data.data && data.other_room_options) {
			const roomsdata = Object.keys(data?.other_room_options).map(
				(key) => ({
					label: data.other_room_options[key].name,
					value: data.other_room_options[key].id,
				})
			);
			setRooms(roomsdata);
			setTimeSlots(data.data.map((timeslot) => timeslot.slice(0, -3)));
		}
	}, [isSuccess, data]);

	return (
		<div className={"flex h-full w-full max-w-2xl flex-col pb-4"}>
			{!isAdmin && (
				<div className="flex w-full cursor-pointer justify-center rounded-tl-[30px] rounded-tr-[30px]  border-b border-b-primary bg-white pb-2 pt-4 text-center text-[15px] font-semibold text-primary ">
					<h1 className="">Edit Reservation</h1>
				</div>
			)}

			<div className={cn("w-full", { "px-4 pt-4": !isAdmin })}>
				<div className="flex w-full flex-col gap-y-3">
					<div>
						<CustomSelect
							placeholder={room.name ?? ""}
							className="text-[15px]"
							value={selectedRoom}
							options={rooms ?? []}
							isDisabled={
								isGetRoomTimeSlotsLoading || !rooms?.length
							}
							onChange={(newValue: any) => {
								setSelectedRoom(newValue);
								setFormData({
									...formData,
									room_id: newValue.value,
								});
							}}
						/>
						{/* <FormInput
							inputType="text"
							value={isAdmin ? room.name : room.room.name}
							className="disabled: border-none font-bold disabled:bg-transparent"
							disabled={true}
						/> */}
					</div>
					<form
						className="flex flex-col gap-y-2"
						onSubmit={handleFormSubmit(onSubmit)}
					>
						<div>
							<FormInput
								inputType="text"
								{...register("title", {
									required: "Reservation name is required",
								})}
								placeholder="Title"
								error={errors.title}
								value={formData.title}
								onChange={(e) => {
									setFormData({
										...formData,
										title: e.target.value,
									});
								}}
							/>
						</div>
						<div className="flex flex-col gap-y-2">
							<div className="flex flex-wrap gap-2">
								<DatePicker
									title="Select Date"
									className="w-fit"
									date={
										formData.date
											? DateTime.fromISO(
													formData.date
												).toJSDate()
											: undefined
									}
									setDate={(date) => {
										date
											? setFormData({
													...formData,
													startTime: "",
													date: DateTime.fromJSDate(
														date
													)?.toISODate() as string,
												})
											: undefined;
									}}
								/>
								<CustomSelect
									options={insertAndSortTimes(
										[...timeslots],
										bookingdata.start_time,
										formData.date ===
											bookingdata?.start_at.split(" ")[0]
									).map((time) => ({
										value: time,
										label: formatTime(time),
									}))}
									value={{
										label: formatTime(formData.startTime),
										value: formData.startTime,
									}}
									className="w-full max-w-[185px] text-[14px]"
									placeholder={
										<div className="flex items-center gap-3 text-[14px]">
											<FaRegClock /> Start Time
										</div>
									}
									onChange={(newValue: any) => {
										setFormData({
											...formData,
											startTime: newValue,
										});
									}}
								/>

								{/* <CustomSelect
									placeholder="Does not repeat"
									className="max-w-[185px] text-[14px]"
								/> */}
								<div className="flex items-center gap-x-2">
									<Switch
										id="all-day"
										checked={formData.isAllDay}
										onCheckedChange={() =>
											setFormData({
												...formData,
												isAllDay: !formData.isAllDay,
											})
										}
									/>
									<p>All day</p>
								</div>
							</div>
							<div className="flex flex-wrap gap-2">
								<CustomSelect
									placeholder="Does not repeat"
									className="w-full max-w-[170px] text-[14px]"
									options={RepeatTimeOptions}
									value={repeatSelection}
									onChange={(selectedOption: any) => {
										setRepeatSelection(selectedOption);
									}}
								/>
								<CustomSelect
									options={generateBookingEndTimeOptions(
										timeslots,
										formData.startTime
									)}
									value={{
										label: formatTime(formData.endTime),
										value: formData.endTime,
									}}
									className="w-full max-w-[185px] text-[14px]"
									placeholder={
										<div className="flex items-center gap-3 text-[14px]">
											<FaRegClock /> End Time
										</div>
									}
									onChange={(newValue: any) => {
										setFormData({
											...formData,
											endTime: newValue,
										});
										// dispatch(
										// 	updateBooking({
										// 		endTime: newValue,
										// 	})
										// );
									}}
								/>
								<p className="my-auto px-2 text-sm text-basecolor">
									{generateDurationString(formData.duration)}
								</p>
								{/* <CustomSelect
									value={{
										label: generateDurationString(
											formData.duration
										),
										value: generateDurationString(
											formData.duration
										),
									}}
									isDisabled={true}
									className="w-36 max-w-[185px] text-[14px]"
									placeholder={
										<div className="flex items-center gap-3 text-[14px]">
											<FaRegClock /> Duration
										</div>
									}
								/> */}
							</div>
							<div className="flex flex-col space-y-1.5">
								<TimeSlotPicker
									selectedTimeSlot={formData.startTime}
									selectTimeSlot={(time) =>
										setFormData({
											...formData,
											startTime: time,
										})
									}
									timeslots={insertAndSortTimes(
										[...timeslots],
										bookingdata.start_time,
										formData.date ===
											bookingdata?.start_at.split(" ")[0]
									)}
									slotAmount={
										width > 640 ? 6 : width > 425 ? 4 : 3
									}
									isLoading={isGetRoomTimeSlotsLoading}
								/>
								{startTimeError && (
									<small className="text-xs text-red-500">
										Kindly select a reservation time
									</small>
								)}
							</div>
						</div>
						<div className="flex w-full flex-col gap-4 md:flex-row">
							<div className="flex-1 md:flex-[0.6]">
								<FormInput
									rows={10}
									error={errors.description}
									value={formData.description as string}
									textarea={true}
									className="hidden w-full rounded-md text-[14px] md:block"
									placeholder="Add Description"
									onChange={(e) => {
										setFormData({
											...formData,
											description: e.target.value,
										});
									}}
								/>
							</div>

							{/* Attendees list */}
							<div className="flex-1 md:flex-[0.4]">
								<AttendeeSelector
									selectedAttendees={selectedAttendees}
									setSelectedAttendees={setSelectedAttendees}
									user={user}
									companyId={
										isAdmin
											? bookingdata.company.id.toString()
											: undefined
									}
								/>
							</div>
						</div>

						<div className="mt-4 px-1">
							<div className="flex w-full gap-2">
								<DialogClose className="w-full rounded-md border border-primary bg-transparent px-4 py-2 text-primary">
									Cancel
								</DialogClose>

								<Button
									className="w-full"
									variant="default"
									type="submit"
									disabled={isUpdateLoading || !isBookable}
								>
									{isUpdateLoading ? (
										<LoadingOutlined
											style={{
												fontSize: 24,
											}}
											spin
										/>
									) : (
										" Update Reservation"
									)}
								</Button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default EditBooking;
