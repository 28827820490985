import FormInput from "@/components/input/FormInput";
import { Button } from "@/components/ui/button";
import { FaRegCreditCard } from "react-icons/fa6";

const AddCard = () => {
	return (
		<div className="flex max-h-[70svh] w-full flex-col  gap-y-3">
			{/* <div className="flex w-full  justify-center text-primary pt-4 pb-2 text-center text-[15px] font-semibold cursor-pointer "> */}
			<h1 className="text-[22px] font-semibold">Add Card</h1>

			{/* </div> */}
			{/* image carousel  */}
			<FormInput
				label="Name on Card"
				labelStyles="text-sm font-normal"
				inputType="text"
				placeholder="Card Full Name"
			/>
			<div className="">
				<p className="pb-1 text-sm font-normal">Credit Card</p>
				<div className="flex items-center rounded-md border-[#E5E5E7] bg-white">
					<div className="h-full flex-[0.1] border-r px-4">
						<FaRegCreditCard />
					</div>
					<FormInput
						inputType="text"
						maxLength={12}
						className=" flex-[0.5]  border-none text-sm text-basecolor"
						placeholder="Card Number"
					/>
					<div className="flex flex-[0.4] items-center justify-end pr-4">
						<input
							type="text"
							maxLength={2}
							className="w-full max-w-[20px] border-none px-0 text-sm text-basecolor placeholder:text-sm focus-visible:outline-none "
							placeholder="02"
						/>
						<p className="text-basecolor">/</p>
						<input
							type="text"
							maxLength={2}
							className="ml-1  w-full max-w-[20px] border-none px-0 text-sm text-basecolor placeholder:text-sm focus-visible:outline-none"
							placeholder="24"
						/>
						<input
							type="text"
							maxLength={3}
							className="ml-2 w-full  max-w-[35px]  border-none px-0 text-sm text-basecolor placeholder:text-sm focus-visible:outline-none"
							placeholder="CVV"
						/>
					</div>
				</div>
			</div>
			<FormInput
				label="Zip code"
				labelStyles="text-sm font font-normal"
				inputType="text"
				placeholder="PDFE 1232"
			/>
			<Button
				variant="default"
				className="ml-auto w-fit px-6"
				disabled={true}
			>
				Save
			</Button>
		</div>
	);
};

export default AddCard;
