import { Route, Routes } from "react-router";
import History from "./History";
import Management from "./Management";
import Bookings from "./Book";
import HistoryDetails from "./HistoryDetails";

const Home = () => {
	return (
		<Routes>
			<Route index element={<Management />} />
			<Route path="/book" element={<Bookings />} />
			<Route path="/history" element={<History />} />
			<Route path="/history/*" element={<HistoryDetails />} />
			<Route path="/management" element={<Management />} />
		</Routes>
	);
};

export default Home;
