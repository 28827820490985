import * as React from "react";
import { format } from "date-fns";
// import { Calendar as CalendarIcon } from "lucide-react"
import { LuCalendar as CalendarIcon } from "react-icons/lu";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { SelectSingleEventHandler } from "react-day-picker";
import { DateTime } from "luxon";

interface DatePickerProps {
	date?: Date | undefined;
	setDate?: (date: any) => void;
	className?: string;
	title: string;
	disablePastDates?: boolean;
	disabledDate?: Date;
	allowDateClearOnCurrentDay?: boolean;
}

export function DatePicker({
	className,
	title,
	date,
	setDate,
	disablePastDates = true,
	disabledDate,
	allowDateClearOnCurrentDay = false,
}: DatePickerProps) {
	const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
	const handleOnSelect: SelectSingleEventHandler = React.useCallback(
		(day) => {
			if (day || allowDateClearOnCurrentDay) {
				if (setDate) setDate(day);
				setIsPopoverOpen(false);
			}
		},
		[setDate, allowDateClearOnCurrentDay]
	);

	// const disabledDates: any[] = React.useMemo(() => {
	// 	const today = DateTime.now();
	// 	const disabledDates: any[] = [];

	// 	// Disable all dates before today
	// 	let currentDate = today.minus({ days: 1 });
	// 	while (currentDate.toMillis() > 0) {
	// 	  disabledDates.push(currentDate.toJSDate());
	// 	  currentDate = currentDate.minus({ days: 1 });
	// 	}

	// 	return disabledDates;
	//   }, []);
	const disabledDates: any[] = React.useMemo(() => {
		const disabledDates: any[] = [];

		if (disablePastDates) {
			const today = DateTime.now();
			let currentDate = today.minus({ days: 1 });
			while (currentDate.toMillis() > 0) {
				disabledDates.push(currentDate.toJSDate());
				currentDate = currentDate.minus({ days: 1 });
			}
		}

		if (disabledDate) {
			const targetDate = DateTime.fromJSDate(disabledDate);
			let currentDate = targetDate;
			while (currentDate.toMillis() > 0) {
				disabledDates.push(currentDate.toJSDate());
				currentDate = currentDate.minus({ days: 1 });
			}
		}

		return disabledDates;
	}, [disablePastDates, disabledDate]);

	return (
		<Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
			<PopoverTrigger asChild>
				<Button
					variant={"outline"}
					className={cn(
						"w-[280px] justify-start text-left font-normal",
						className,
						!date && "text-muted-foreground"
					)}
				>
					<CalendarIcon className="mr-2 h-4 w-4" />
					{date ? (
						format(date, "PPP")
					) : (
						<span>{title || "Pick a date"}</span>
					)}
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-auto p-0">
				<Calendar
					mode="single"
					selected={date}
					onSelect={handleOnSelect}
					initialFocus
					disabled={disablePastDates ? disabledDates : undefined}
				/>
			</PopoverContent>
		</Popover>
	);
}
