import NameLetter from "@/components/NameLetter";
import { CiLocationOn } from "react-icons/ci";
import { DialogClose } from "../../../ui/dialog";
import { SummaryPlaceholder } from "../LocationSummary";
import { PartnerCompany } from "@/types/partners/member";

const PartnerSummary: React.FC<{ data?: PartnerCompany }> = ({ data }) => {
	const partnerStatistics = data?.statics;

	return (
		<div className="flex w-full flex-col items-start gap-2">
			<div className="flex  w-full items-center justify-between rounded-tl-[30px] rounded-tr-[30px] bg-white px-7 py-4">
				<div className="flex items-center gap-2">
					<NameLetter name={data?.name.split(" ")[0][0] ?? ""} />
					<div>
						<h1 className="font-bold text-basecolor">
							{data?.name ?? "N/A"}
						</h1>
						<p className="flex items-center gap-2 text-[10px] font-light text-basecolor">
							<CiLocationOn />
							{data?.address ?? "N/A"}
						</p>
					</div>
				</div>
				<div className="flex flex-col gap-2 pr-4">
					<div className="flex items-center gap-1 text-xs">
						<i className="mgc_phone_line text-[12px] before:!text-[#323539] xs:text-[13px]" />
						<p className="text-[#6B7280]">
							Phone:{" "}
							<span className="text-[#323539]">
								{data?.phone_number ?? "N/A"}
							</span>
						</p>
					</div>
					<div className="flex items-center gap-1 text-xs">
						<i className="mgc_user_security_line text-[12px] before:!text-[#323539] xs:text-[13px]" />
						<div className="flex items-center gap-1 text-xs">
							<p className="text-[#6B7280]">
								Admin:{" "}
								<span className="text-[#323539]">
									{data?.super_admin.name ?? "N/A"}
								</span>{" "}
							</p>
							<span className="flex w-fit items-center justify-center rounded-full bg-[#F9F9F9] p-1 text-xs text-primary">
								<a
									href={`${data?.super_admin?.email ? "mailto:info@migranium.com" : null}`}
								>
									<i className="mgc_mail_line before:!text-primary" />
								</a>
							</span>
							<a
								href={"tel:" + data?.phone_number}
								className="flex size-[24px] items-center justify-center rounded-full bg-[#F9F9F9]"
							>
								<i className="mgc_phone_line before:!text-primary" />
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className="flex w-full flex-col gap-y-2 rounded-bl-[30px] rounded-br-[30px] bg-[#F5F5F5] px-7 pb-6 pt-4">
				<div className="flex flex-col gap-y-4 py-3">
					<div className="flex flex-col gap-y-3">
						<h1 className="text-xs font-semibold">General</h1>
						<div className="flex gap-3 ">
							<SummaryPlaceholder
								title="Admin"
								icon="mgc_group_2_line"
								value={partnerStatistics?.total_admins ?? "N/A"}
							/>
							<SummaryPlaceholder
								title="Guests"
								icon="mgc_group_2_line"
								value={partnerStatistics?.total_guests ?? "N/A"}
							/>
						</div>
					</div>
					<div className="flex flex-col gap-y-3">
						<h1 className="text-xs font-semibold">Reservations</h1>
						<div className="flex gap-3 ">
							<SummaryPlaceholder
								title="Booked"
								value={
									partnerStatistics?.total_bookings ?? "N/A"
								}
							/>
							<SummaryPlaceholder
								title="Cancelled"
								value={
									partnerStatistics?.total_cancelled_bookings ??
									"N/A"
								}
							/>
							<SummaryPlaceholder
								title="Space Used"
								value={
									partnerStatistics?.total_rooms_used ?? "N/A"
								}
							/>
							<SummaryPlaceholder
								title="Rescheduled"
								value={
									partnerStatistics?.total_rescheduled_bookings ??
									"N/A"
								}
							/>
						</div>
					</div>
					{/* <div className="flex flex-col gap-y-3">
						<h1 className="text-xs font-semibold">
							Location Access
						</h1>
						{partnerStatistics?.location_access
							? partnerStatistics?.location_access?.map((location, index) => (
									// <div key={index-location} className="flex gap-3 ">
									// 	<p className="text-xs"></p>
									// </div>
									<SummaryPlaceholder
									key={index-location}
									title=""
									value={
										location ??
										"N/A"
									}
								/>
								))
							: "N/A"}
					</div> */}
				</div>
				<div className="flex w-full items-center justify-end gap-x-1.5 pt-2 ">
					<div className="flex gap-2">
						<DialogClose className="h-9 w-fit rounded-md border border-primary bg-transparent px-4 text-primary">
							Close
						</DialogClose>

						{/* <DialogClose className="w-fit rounded-md border border-primary bg-primary px-6 py-2 text-white">
							Edit
						</DialogClose> */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PartnerSummary;
