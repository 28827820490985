import { cn } from "@/lib/utils";
import React, { useLayoutEffect, useState } from "react";
import { BsCheck } from "react-icons/bs";
import { FaChevronLeft } from "react-icons/fa6";
import {
	Navigate,
	Outlet,
	useLocation,
	useNavigate,
	useParams,
} from "react-router";

const AddLocation: React.FC = () => {
	const navigate = useNavigate();

	const { location_id } = useParams();
	const location = useLocation();

	if (location_id && isNaN(parseInt(location_id))) {
		return (
			<Navigate
				to="/admin/location-management"
				state={{ from: location }}
				replace
			/>
		);
	}

	const updateSteps = () => [
		{
			label: "Location Info",
			completed: /\d+\/operating-hours/.test(
				location.pathname.split(
					"/admin/location-management/add-location/"
				)[1]
			),
			active: !/add-location\/\d+/.test(
				location.pathname.split("/admin/location-management/")[1]
			),
		},
		{
			label: "Operating Hours",
			completed: false,
			active: /\d+\/operating-hours/.test(
				location.pathname.split(
					"/admin/location-management/add-location/"
				)[1]
			),
		},
	];

	const [steps, setSteps] = useState(updateSteps());

	useLayoutEffect(() => {
		setSteps(updateSteps());
	}, [location]);

	return (
		<div>
			<div className="flex gap-4">
				<button className="" onClick={() => navigate(-1)}>
					<FaChevronLeft className="text-xl font-semibold text-[#858C95]" />
				</button>
				<div>
					<div className="flex ">
						<p className="pr-1 text-[#6D748D]">Location</p>
						<p className="text-primary"> &gt; Add Location</p>
					</div>
					<h1 className="text-[20px] font-semibold text-basecolor">
						Add Location
					</h1>
				</div>
			</div>
			<div className="flex justify-center">
				<div className="flex w-full max-w-[804px] flex-col items-center">
					<StepsComponent steps={steps} />
					<div className="mt-4 w-full">
						<Outlet />
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddLocation;

interface Step {
	label: string;
	completed: boolean;
	active: boolean;
}

interface StepsComponentProps {
	steps: Step[];
}

const StepsComponent: React.FC<StepsComponentProps> = ({ steps }) => {
	return (
		<div className="my-4 flex w-full items-center justify-between">
			{steps.map((step, index) => (
				<React.Fragment key={index}>
					<div
						id={`step-${index}`}
						className={cn(
							`step flex items-center gap-2 font-semibold text-gray-500`,
							{
								"text-primary": step.completed || step.active,
							}
						)}
					>
						<span
							className={cn(
								"flex h-8 w-8 items-center justify-center rounded-full bg-primary text-white",
								{
									"border border-[#E5E5E7] bg-transparent text-[#858C95]":
										!step.completed,
									"bg-primary text-white": step.active,
								}
							)}
						>
							{step.completed ? (
								<BsCheck className="text-2xl" />
							) : (
								index + 1
							)}
						</span>
						{step.label}
					</div>
					{index < steps.length - 1 && (
						<div
							className={cn(
								"mx-2 w-40 flex-1 border border-dashed border-gray-300",
								{
									"border-primary": step.completed,
								}
							)}
						/>
					)}
				</React.Fragment>
			))}
		</div>
	);
};
