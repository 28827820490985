// import UploadImage from "@/components/common/UploadImage";
import FormInput from "@/components/input/FormInput";
import { LoaderButton } from "@/components/Loader/LoaderButton";
import { Button } from "@/components/ui/button";
import { useUpdateBusinessMutation } from "@/redux/features/api/partnerEndpoints";
import { notifyWithIcon } from "@/services/utilities";
import { useEffect, useState } from "react";

const EditBusiness = ({ partnerData, close }) => {
	const [updateBusiness, { isLoading, isSuccess, isError, data, error }] =
		useUpdateBusinessMutation();
	const [formData, setFormData] = useState({
		name: partnerData?.name ?? "",
		email: partnerData?.email ?? "",
		phone_number: partnerData?.phone_number ?? "",
		address: partnerData?.address ?? "",
		city: partnerData?.city ?? "",
		country: partnerData?.country ?? "",
		state: partnerData?.state ?? "",
	});
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};
	const handleSubmit = async () => {
		const data = formData;
		// console.log(data);
		updateBusiness({ data });
		//empty form

		close();
	};

	useEffect(() => {
		if (isSuccess && data) {
			notifyWithIcon("", data.message);
			setFormData({
				name: "",
				email: "",
				phone_number: "",
				address: "",
				city: "",
				country: "",
				state: "",
			});
		}
		if (isError && error) {
			if (error) {
				const errorMessage =
					(
						error as {
							data?: { message?: string };
						}
					)?.data?.message || "An error occurred";
				return notifyWithIcon("", errorMessage);
			}
			console.error(error);
		}
	}, [isSuccess, data, isError]);

	return (
		<div className="flex w-full flex-col gap-y-3 rounded-[30px] bg-[#F5F5F5] ">
			<div className="flex w-full cursor-pointer justify-center rounded-tl-[30px] rounded-tr-[30px]  border-b border-b-primary bg-white pb-2 pt-4 text-center text-[15px] font-semibold text-primary ">
				<h1 className="">Edit Your Business</h1>
			</div>
			<div className="flex flex-col gap-4 px-4">
				{/* <div className="flex items-center gap-4">
					<div className="aspect-square w-[108px] rounded-[20px] border-[5px] border-white shadow-[0px_0px_5px_rgba(0,0,0,0.25)]"></div>
					<div>
						<UploadImage />
						<p className="pt-2 text-[12px] text-[#6B7280]">
							File must be a JPG or PNG
						</p>
						<p className="text-[12px] text-[#6B7280]">
							Max Size of 5MB
						</p>
					</div>
				</div> */}
				<div className="flex flex-col gap-y-3">
					<FormInput
						inputType="text"
						placeholder="Business Name"
						label="Business Name"
						name="name"
						value={formData.name}
						onChange={handleChange}
					/>
					<div className="flex flex-wrap gap-4">
						<FormInput
							inputType="text"
							placeholder="info@businessemail.com"
							label="Business Email Address"
							name="email"
							className="min-w-[120px]"
							value={formData.email}
							onChange={handleChange}
						/>
						<FormInput
							inputType="text"
							placeholder="+1 (000)000 - 000"
							label="Business Phone Number"
							name="phone_number"
							className="min-w-[120px]"
							value={formData.phone_number}
							onChange={handleChange}
						/>
					</div>

					<FormInput
						inputType="text"
						placeholder="Address"
						label="Business Address"
						name="address"
						value={formData.address}
						onChange={handleChange}
					/>
					<div className="flex flex-wrap gap-2">
						<FormInput
							inputType="text"
							placeholder="Country"
							name="country"
							label="Country"
							className="min-w-[120px]"
							value={formData.country}
							onChange={handleChange}
						/>
						<FormInput
							inputType="text"
							placeholder="Province"
							name="state"
							label="State"
							className="min-w-[120px]"
							value={formData.state}
							onChange={handleChange}
						/>
						<FormInput
							inputType="text"
							placeholder="City"
							name="city"
							label="City"
							className="min-w-[120px]"
							value={formData.city}
							onChange={handleChange}
						/>
					</div>
				</div>
			</div>

			<div className=" flex w-full gap-2 px-4 py-6">
				<Button
					className="w-full"
					variant="outlinecustom"
					onClick={close}
				>
					Cancel
				</Button>
				<LoaderButton
					className="w-full"
					variant="default"
					onClick={handleSubmit}
					disabled={isLoading}
					loading={isLoading}
					loaderSize={20}
				>
					Save
				</LoaderButton>
			</div>
		</div>
	);
};

export default EditBusiness;
