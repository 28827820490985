import CustomSelect from "@/components/common/CustomSelect";
import TimeSlotPicker from "@/components/common/TimeSlotPicker";
import FormInput from "@/components/input/FormInput";
import AttendeeSelector from "@/components/partner/room/AttendeeSelector";
import { Button } from "@/components/ui/button";
import { DatePicker } from "@/components/ui/datepicker";
import { DialogClose } from "@/components/ui/dialog";
import { Switch } from "@/components/ui/switch";
import { useAppDispatch, useAppSelector } from "@/hooks/hooks";
import useScreenDimension from "@/hooks/useScreenDimension";
import { cn } from "@/lib/utils";
import {
	useGetRoomTimeslotsQuery,
	useUpdateBookingMutation,
} from "@/redux/features/api/partnerEndpoints";
import { RepeatTimeOptions } from "@/services/constants";
import {
	formatTime,
	generateBookingDurationTime,
	generateBookingEndTimeOptions,
	generateDurationString,
	insertAndSortTimes,
	selectRepeatOption,
} from "@/services/utilities";
import { Booking } from "@/types/admin/booking";
import { LoadingOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaRegClock } from "react-icons/fa6";
import Modal from "../../Modal";
import RecurrenceEditForm, {
	IRecurringData,
} from "@/components/admin/booking/book/room/RecurrenceEditForm";
import {
	useGetRecurringReservationDetailQuery,
	useUpdateRecurringBookingMutation,
} from "@/redux/features/api/adminBookingEndpoints";
import { updateBooking } from "@/redux/slices/bookingSlice";
import RecurrenceSummary from "@/components/admin/booking/book/room/RecurrenceSummary";
import useCustomToast from "@/components/CustomToast";

const EditBooking = ({
	room,
	bookingdata,
	type,
	isAdmin,
	onUpdateSuccess,
}: {
	room: any;
	type: "normal" | "occurence" | "series";
	bookingdata: Booking;
	onUpdateSuccess?: () => void;
	isAdmin?: boolean;
}) => {
	const { width } = useScreenDimension();
	const user = useAppSelector((state) => state.user);
	const { date } = useAppSelector((state) => state.bookings.booking);
	const dispatch = useAppDispatch();
	const toast = useCustomToast();
	const isRecurring = bookingdata?.is_recurring;
	const [timeslots, setTimeSlots] = useState<string[]>(
		room?.time_slots ?? []
	);
	const [recurrenceData, setRecurrenceData] = useState<IRecurringData | any>(
		{}
	);
	const [showReccurringModal, setShowReccurringModal] = useState(false);
	const companyId = isAdmin ? bookingdata?.company.id : 0;
	const [rooms, setRooms] = useState<{ label: string; value: string }[]>();
	const [selectedRoom, setSelectedRoom] = useState<{
		label: string;
		value: string;
	}>();
	const [repeatSelection, setRepeatSelection] = useState<
		| {
				label: string;
				value: string;
		  }
		| any
	>(selectRepeatOption(""));
	const [selectedAttendees, setSelectedAttendees] = useState(
		bookingdata?.meeting_members
			? bookingdata.meeting_members.map(
					(member: any) => member?.member_profile?.email
				)
			: []
	);
	const [formData, setFormData] = useState({
		date: bookingdata?.start_at.split(" ")[0] ?? date,
		room_id: bookingdata?.room?.id,
		duration: generateBookingDurationTime(
			bookingdata?.start_time?.slice(0, -3),
			bookingdata?.end_time.slice(0, -3)
		),
		endTime: bookingdata?.end_time.slice(0, -3),
		description: bookingdata?.description,
		title: bookingdata?.title,
		isAllDay: !bookingdata?.end_time,
		startTime: bookingdata?.start_time.slice(0, -3),
	});
	const [startTimeError, setStartTimeError] = useState(false);

	const {
		register,
		handleSubmit: handleFormSubmit,
		formState: { errors },
	} = useForm();
	const isBookable =
		insertAndSortTimes(
			[...timeslots],
			bookingdata.start_time,
			formData.date === bookingdata?.start_at?.split(" ")[0]
		).length > 0;

	const {
		isSuccess,
		data,
		isFetching: isGetRoomTimeSlotsLoading,
	} = useGetRoomTimeslotsQuery(
		{
			room_id: selectedRoom
				? selectedRoom.value
				: bookingdata.room_id.toString(),
			date: formData.date,
			duration: 60,
			is_allday: formData.isAllDay ? 1 : 0,
			company_id: isAdmin ? companyId : undefined,
		},
		{
			// skip: formData.date == bookingdata?.start_at.split(" ")[0],
		}
	);
	const {
		isSuccess: isGetReccuringDataSuccess,
		data: isGetReccuringData,
		isFetching: isGetReccuringDataLoading,
	} = useGetRecurringReservationDetailQuery(
		{
			booking_id: bookingdata?.recurring_id!,
			companyId: companyId,
		},
		{
			skip: type !== "series",
		}
	);

	const [
		updateBookingDetails,
		{
			data: isUpdateData,
			isLoading: isUpdateLoading,
			error: updateError,
			isError: isUpdateError,
			isSuccess: isUpdateSuccess,
		},
	] = useUpdateBookingMutation();

	const [
		updateRecurringBookingDetails,
		{
			data: isUpdateRecurringData,
			isLoading: isUpdateRecurringLoading,
			error: updateRecurringError,
			isError: isUpdateRecurringError,
			isSuccess: isUpdateRecurringSuccess,
		},
	] = useUpdateRecurringBookingMutation();

	//Add update recurring meeting here too
	const handleSaveRecurrenceData = (data: any) => {
		const recurringData = {
			...data,
			start_date: formData.date,
		};
		localStorage.setItem("recurringData", JSON.stringify(recurringData));
		const event = new Event("recurringDataChange");
		window.dispatchEvent(event);
		setRecurrenceData(recurringData);
		setShowReccurringModal(false);
	};

	const onSubmit = () => {
		setStartTimeError(false);
		if (!formData.isAllDay && (!formData.startTime || !formData.endTime)) {
			return setStartTimeError(true);
		}
		const submittedData: {
			booking_id: number;
			data: {
				room_id: number | string;
				title: string;
				member_emails: string[];
				date: Date | string;
				description: string;
				is_allday: number | boolean;
				duration: number | string;
				host_id: number | string;
				start_time?: string;
				recurring_data?: any;
			};
			companyId?: number;
		} = {
			booking_id:
				type == "series" ? bookingdata?.recurring_id! : bookingdata?.id,
			data: {
				room_id: formData.room_id,
				title: formData.title,
				member_emails: selectedAttendees,
				date: formData.date,
				description: formData.description as string,
				is_allday: formData.isAllDay,
				start_time: formData?.startTime
					?.split(":")
					.slice(0, 2)
					.join(":"),
				duration: formData.duration,
				host_id: bookingdata?.host_user_id,
				...(type == "series" &&
					recurrenceData && {
						...recurrenceData,
					}),
			},
			companyId: isAdmin ? companyId : undefined,
		};
		if (type == "series" && isRecurring) {
			return updateRecurringBookingDetails(submittedData);
		} else {
			return updateBookingDetails(submittedData);
		}
	};

	useEffect(() => {
		if (isSuccess && data.data) {
			if (data.other_room_options) {
				const roomsdata = data.other_room_options.map((room) => ({
					label: room?.name,
					value: room?.id,
				}));
				setRooms(roomsdata);
			}
			setTimeSlots(data.data.map((timeslot) => timeslot.slice(0, -3)));
		}
	}, [isSuccess, data]);
	useEffect(() => {
		if (isUpdateSuccess || isUpdateRecurringSuccess) {
			toast("Meeting Details Updated successfully!", {
				id: "update-success",
				type: "success",
			});
			if (onUpdateSuccess) onUpdateSuccess();
		} else if (isUpdateError || isUpdateRecurringError) {
			if (updateError) {
				const errorMessage =
					(
						updateError as {
							data?: { message?: string };
						}
					)?.data?.message || "An error occurred";
				return toast(` ${errorMessage || "Unknown error"} `, {
					id: "update-error",
					type: "error",
				});
			} else if (updateRecurringError) {
				const errorMessage =
					(
						updateRecurringError as {
							data?: { message?: string };
						}
					)?.data?.message || "An error occurred";
				return toast(` ${errorMessage || "Unknown error"} `, {
					id: "update-error",
					type: "error",
				});
			}
		}
	}, [
		isUpdateSuccess,
		isUpdateError,
		isUpdateData,
		isUpdateRecurringData,
		isUpdateRecurringError,
		isUpdateRecurringSuccess,
	]);

	useEffect(() => {
		setRecurrenceData({
			is_recurring: 1,
			frequency: isGetReccuringData?.data?.frequency,
			end_date: isGetReccuringData?.data?.end_at?.split(" ")[0],
			day_date_format: isGetReccuringData?.data?.day_date_format,
			days: isGetReccuringData?.data?.days,
			dates: isGetReccuringData?.data?.dates,
			week_occurrences: isGetReccuringData?.data?.week_occurrences,
			yearly_month: isGetReccuringData?.data?.yearly_month,
			start_at: isGetReccuringData?.data?.start_at,
			end_at: isGetReccuringData?.data?.end_at,
		});
		setRepeatSelection(
			selectRepeatOption(isGetReccuringData?.data?.frequency)
		);
	}, [isGetReccuringData, isGetReccuringDataSuccess]);
	return (
		<div className={"flex h-full w-full max-w-2xl flex-col pb-4"}>
			{!isAdmin && (
				<div className="flex w-full cursor-pointer justify-center rounded-tl-[30px] rounded-tr-[30px]  border-b border-b-primary bg-white pb-2 pt-4 text-center text-[15px] font-semibold text-primary ">
					<h1 className="">Edit Reservation</h1>
				</div>
			)}

			<div className={cn("w-full", { "px-4 pt-4": !isAdmin })}>
				{type !== "normal" && (
					<p className=" pb-4 text-sm text-[#959595]">
						{type == "series" &&
							"You're editing a reservation series"}
						{type == "occurence" &&
							"You're editing an occurence of a series"}
					</p>
				)}

				<div className="flex w-full flex-col gap-y-3">
					<div>
						<CustomSelect
							placeholder={room?.name ?? " "}
							className="text-[15px]"
							value={selectedRoom}
							options={rooms ?? []}
							isDisabled={
								isGetRoomTimeSlotsLoading || !rooms?.length
							}
							onChange={(newValue: any) => {
								setSelectedRoom(newValue);
								setFormData({
									...formData,
									room_id: newValue.value,
								});
							}}
						/>
					</div>
					<form
						className="flex flex-col gap-y-2"
						onSubmit={handleFormSubmit(onSubmit)}
					>
						<div>
							<FormInput
								inputType="text"
								{...register("title", {
									required: "Reservation name is required",
								})}
								className="h-10"
								placeholder="Title"
								error={errors.title}
								value={formData.title}
								onChange={(e) => {
									setFormData({
										...formData,
										title: e.target.value,
									});
								}}
							/>
						</div>
						<div className="flex flex-col gap-y-2">
							<div className="flex flex-wrap gap-2">
								<DatePicker
									title="Select Date"
									className="w-fit"
									date={
										formData.date
											? DateTime.fromISO(
													formData.date
												).toJSDate()
											: undefined
									}
									setDate={(date) => {
										if (date) {
											const luxonDate =
												DateTime.fromJSDate(date);
											const isoDate =
												luxonDate.toISODate() as string;
											setFormData({
												...formData,
												startTime: "",
												endTime: "",
												duration: 60,
												date: isoDate ?? undefined,
											});
											dispatch(
												updateBooking({
													date: isoDate ?? undefined,
												})
											);
										}
									}}
								/>
								<CustomSelect
									options={insertAndSortTimes(
										[...timeslots],
										bookingdata.start_time,
										formData.date ===
											bookingdata?.start_at.split(" ")[0]
									).map((time) => ({
										value: time,
										label: formatTime(time),
									}))}
									value={{
										label: formData.startTime
											? formatTime(formData.startTime)
											: "Start Time",
										value: formData.startTime,
									}}
									className="w-full max-w-[185px] text-[14px]"
									placeholder={
										<div className="flex items-center gap-3 text-[14px]">
											<FaRegClock /> Start Time
										</div>
									}
									onChange={(newValue: any) => {
										setFormData({
											...formData,
											startTime: newValue.value,
											endTime: "",
											duration:
												generateBookingDurationTime(
													newValue?.value,
													formData?.endTime
												),
										});
									}}
								/>
								<div className="flex items-center gap-x-2">
									<Switch
										id="all-day"
										checked={formData.isAllDay}
										onCheckedChange={() =>
											setFormData({
												...formData,
												isAllDay: !formData.isAllDay,
											})
										}
									/>
									<p>All day</p>
								</div>
							</div>
							<div className="flex flex-wrap gap-2">
								<CustomSelect
									placeholder="Does not repeat"
									className="w-full max-w-[170px] text-[14px]"
									isDisabled={
										type === "normal" ||
										type === "occurence" ||
										isGetReccuringDataLoading
									}
									options={RepeatTimeOptions}
									value={repeatSelection}
									onChange={(selectedOption: any) => {
										if (
											selectedOption.value ==
											repeatSelection.value
										) {
											setShowReccurringModal(true);
										}
										setRepeatSelection(selectedOption);
									}}
								/>

								<CustomSelect
									options={generateBookingEndTimeOptions(
										timeslots,
										formData.startTime
									)}
									value={{
										label: formData.endTime
											? formatTime(formData.endTime)
											: "End Time",
										value: formData.endTime,
									}}
									className="w-full max-w-[185px] text-[14px]"
									placeholder={
										<div className="flex items-center gap-3 text-[14px]">
											<FaRegClock /> End Time
										</div>
									}
									onChange={(newValue: any) => {
										setFormData({
											...formData,
											endTime: newValue.value,
											duration:
												generateBookingDurationTime(
													formData.startTime,
													newValue.value
												),
										});
									}}
								/>
								<p className="my-auto px-2 text-sm text-basecolor">
									{generateDurationString(formData.duration)}
								</p>
								{/* <CustomSelect
									value={{
										label: generateDurationString(
											formData.duration
										),
										value: generateDurationString(
											formData.duration
										),
									}}
									isDisabled={true}
									className="w-36 max-w-[185px] text-[14px]"
									placeholder={
										<div className="flex items-center gap-3 text-[14px]">
											<FaRegClock /> Duration
										</div>
									}
								/> */}
							</div>
							<RecurrenceSummary />
							{/* {repeatSelection?.value != "does not repeat" && (
								<p className="px-4 text-xs">{occurenceText}</p>
							)} */}
							<div className="flex flex-col space-y-1.5">
								<TimeSlotPicker
									selectedTimeSlot={formData.startTime}
									selectTimeSlot={(time) =>
										setFormData({
											...formData,
											startTime: time,
											endTime: "",
										})
									}
									timeslots={insertAndSortTimes(
										[...timeslots],
										bookingdata.start_time,
										formData.date ===
											bookingdata?.start_at.split(" ")[0]
									)}
									slotAmount={
										width > 640 ? 6 : width > 425 ? 4 : 3
									}
									isLoading={isGetRoomTimeSlotsLoading}
								/>
								{startTimeError && (
									<small className="text-xs text-red-500">
										Kindly select a valid reservation{" "}
										<b> start time & end time</b>
									</small>
								)}
							</div>
						</div>
						<div className="flex w-full flex-col gap-4 md:flex-row">
							<div className="flex-1 md:flex-[0.6]">
								<FormInput
									rows={10}
									error={errors.description}
									value={formData.description as string}
									textarea={true}
									className="hidden w-full rounded-md text-[14px] md:block"
									placeholder="Add Description"
									onChange={(e) => {
										setFormData({
											...formData,
											description: e.target.value,
										});
									}}
								/>
							</div>

							{/* Attendees list */}
							<div className="flex-1 md:flex-[0.4]">
								<AttendeeSelector
									selectedAttendees={selectedAttendees}
									setSelectedAttendees={setSelectedAttendees}
									user={user}
									companyId={
										isAdmin
											? bookingdata.company.id.toString()
											: undefined
									}
								/>
							</div>
						</div>

						<div className="mt-4 px-1">
							<div className="flex w-full gap-2">
								<DialogClose className="w-full rounded-md border border-primary bg-transparent px-4 py-2 text-primary">
									Cancel
								</DialogClose>

								<Button
									className="w-full"
									variant="default"
									type="submit"
									disabled={isUpdateLoading || !isBookable}
								>
									{isUpdateLoading ||
									isUpdateRecurringLoading ? (
										<LoadingOutlined
											style={{
												fontSize: 24,
											}}
											spin
										/>
									) : (
										" Update Reservation"
									)}
								</Button>
							</div>
						</div>
					</form>
				</div>
			</div>
			<Modal
				dialogClassName=" p-0 max-w-xl "
				onInteractOutside={(e) => {
					e.preventDefault();
				}}
				showDeleteIcon={false}
				open={showReccurringModal}
				setOpen={setShowReccurringModal}
			>
				<RecurrenceEditForm
					defaultType={repeatSelection}
					handleClose={() => {
						setShowReccurringModal(false);
					}}
					setPreviousData={setFormData} // did this temporarily -- to update
					previousRecurringData={recurrenceData}
					updateRepeatSelection={setRepeatSelection}
					handleSaveRecurrenceData={handleSaveRecurrenceData}
					previousData={formData}
				/>
			</Modal>
		</div>
	);
};

export default EditBooking;
