import { Button } from "@/components/ui/button";
import { DatePicker } from "@/components/ui/datepicker";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Dispatch, SetStateAction } from "react";
import { Views } from "react-big-calendar";
import { BsPlusCircle } from "react-icons/bs";
import { useNavigate } from "react-router";
// import useScreenDimension from "../../..//hooks/useScreenDimension";

interface ManagementToolbarProps {
	view: string;
	setDate: (date: Date) => void;
	date: Date | undefined;
	setView: Dispatch<SetStateAction<string>>;
	// setShowAppointmentModal: Dispatch<SetStateAction<boolean>>;
}
const ManagementToolbar = ({
	view,
	setView,
	date,
	setDate,
}: ManagementToolbarProps) => {
	const navigate = useNavigate();
	return (
		<div className="relative flex items-center justify-end gap-4">
			<Tabs defaultValue={view}>
				<TabsList>
					<TabsTrigger
						className="data-[state=active]:border data-[state=active]:border-primary data-[state=active]:text-primary"
						onClick={() => setView(Views.DAY)}
						value="day"
					>
						Day View
					</TabsTrigger>
					<TabsTrigger
						className="data-[state=active]:border data-[state=active]:border-primary data-[state=active]:text-primary"
						onClick={() => setView(Views.WEEK)}
						value="week"
					>
						Week View
					</TabsTrigger>
					<TabsTrigger
						className="data-[state=active]:border data-[state=active]:border-primary data-[state=active]:text-primary"
						onClick={() => setView(Views.MONTH)}
						value="month"
					>
						Month View
					</TabsTrigger>
				</TabsList>
			</Tabs>
			<div className="flex gap-4">
				<DatePicker
					disablePastDates={false}
					title="select date"
					date={date}
					className="w-[195px]"
					setDate={setDate}
				/>
				<Button
					className="flex gap-2 px-3 py-2 text-white"
					onClick={() => navigate("/admin/bookings/book")}
				>
					<BsPlusCircle />
					Add Reservation
				</Button>
				{/* <DatePickerWithRange
					className="w-fit"
					title="Select Start - End Date"
				/> */}
			</div>
		</div>
	);
};

export default ManagementToolbar;
