import CapacityHolder from "@/components/common/CapacityHolder";
import { Room } from "@/types/admin/booking";

interface DisplayCardProps {
	roomdata: Room;
}

const DisplayCard: React.FC<DisplayCardProps> = ({ roomdata }) => {

	return (
		<div className="flex items-center justify-between rounded-[12px] bg-white p-2 drop-shadow-[0_1px_5px_rgba(16,24,40,0.15)] ">
			<div className="flex flex-[0.4] gap-2 rounded-xl">
				{/* <img
					src={roomdata?.image}
					alt=""
					className="h-[87px] w-[155px] rounded-[12px]"
				/> */}
				{/* <div className="flex h-[65px] w-[65px] items-center justify-center rounded-md border border-[#E8E8E8]">
					<div className="flex size-9 items-center justify-center rounded-full bg-[#C7C7C7]/60">
						<p className="select-none text-lg font-semibold text-white">
							{roomdata.room?.name.split(" ")[0][0].toUpperCase()}
						</p>
					</div>
				</div> */}
				<div className="flex h-[87px] w-[155px] flex-col justify-center rounded-[12px] bg-[#DCDCDC] px-4">
					<div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-[#C7C7C7] ">
						<p className="select-none text-lg font-semibold text-white">
							{roomdata?.name.split(" ")[0][0].toUpperCase()}
						</p>
					</div>
				</div>
				<div className="flex flex-col justify-center gap-y-2">
					<div className="space-y-1">
						<h2 className="text-[22px] font-bold">
							{roomdata.name}
						</h2>

						<div className="flex items-center space-x-1">
							<i className="mgc_location_3_line text-[12px] before:!text-[#323539]" />
							<p className="text-[10px]">
								{roomdata?.location.address &&
									roomdata?.location.address + " | "}
								{roomdata?.address}
							</p>
						</div>
					</div>

					<CapacityHolder capacity={roomdata?.capacity} />
				</div>
			</div>
			<div className="flex flex-[0.3] flex-col justify-center gap-1.5 border-l-2 py-1 pl-2">
				{roomdata.amenities.length ? (
					roomdata?.amenities?.map((amenity, index) => (
						<span
							key={index}
							className="flex items-center gap-1 text-[#959595]"
						>
							{/* {amenity === "Tv/ Projector" && <PiMonitorBold />}
						{amenity === "Sound System" && <PiSpeakerHifiBold />}
						{amenity === "Google Chromecast" && <LuChrome />} */}
							<p className="text-[10px] text-[#959595]">
								{amenity.name}
							</p>
						</span>
					))
				) : (
					<p className="text-[10px] text-[#959595]">No Amenities</p>
				)}
			</div>
			<div className="flex flex-[0.25] flex-col justify-center gap-1.5 border-l-2 py-1 pl-2">
				<span className="flex items-center gap-2 text-[#959595] ">
					<i className="mgc_contacts_2_line text-[12px] before:!text-[#959595]" />
					{/* <LuContact /> */}
					<p className="text-[10px] font-bold">
						{/* {roomdata.admin.name} */} AA
					</p>
				</span>
				<span className="flex items-center gap-2">
					<span className="flex h-[10px] w-[10px] items-center justify-center rounded-full border border-[#959595] bg-white p-1.5 text-[10px] text-[#959595]">
						A
					</span>
					{/* {roomdata.contact?.name} */}
					<p className="text-[10px] text-[#6B7280]">
						Adetunji Adeyinka
					</p>
				</span>
				<span className="flex items-center gap-2 text-[#959595]">
					{/* <IoMdTime /> */}
					{/* <p className="text-[10px]">{roomdata.contact.time}</p> */}
				</span>
			</div>
			{/* <div className="mt-auto flex-[0.1] pb-2 pr-4">
				<span
					className={cn(
						"flex w-fit items-center rounded-3xl px-4 py-[0.5px] text-[10px] capitalize",
						{
							"bg-[#F6E2E1] text-[#B7241D]":
								roomdata?.status === "pending",
							"bg-[#F7EDD4] text-[#896200]":
								roomdata?.status === "upcoming",
							"bg-[#F5F1F6] text-[#69216F]":
								roomdata?.status === "in progress",
							"bg-[#E7F3F1] text-[#138576]":
								roomdata?.status === "available",
						}
					)}
				>
					{roomdata.status}
				</span>
			</div> */}
		</div>
	);
};

export default DisplayCard;
