import { HiOutlineTrash } from "react-icons/hi2";
import { Button } from "@/components/ui/button";
import { LoadingOutlined } from "@ant-design/icons";
import { DialogClose } from "@/components/ui/dialog";
import { Booking } from "@/types/admin/booking";
import { cn } from "@/lib/utils";

interface DeleteBookingProps {
	name: string;
	data: Booking;
	handleDelete: () => void;
	handleDeleteRecurring: () => void;
	isLoading: boolean;
	isRecurringLoading: boolean;
}
const DeleteBooking = ({
	name,
	data,
	handleDelete,
	handleDeleteRecurring,
	isLoading,
	isRecurringLoading,
}: DeleteBookingProps) => {
	const isRecurring = data.is_recurring;
	return (
		<div className="flex items-start gap-2 pr-4">
			<div className="flex flex-[0.1] pt-1">
				<HiOutlineTrash className="text-lg text-[#C8322B]" />
			</div>
			<div className="flex flex-grow flex-col gap-y-2">
				<h1 className="text-[22px] font-semibold text-[#323539]">
					{isRecurring
						? "Delete Recurring Reservation"
						: "Delete Reservation"}
				</h1>
				<p>
					Are you sure you want to delete{" "}
					<span className="font-bold text-primary">
						{name ?? "N/A"}{" "}
					</span>
					reservation
				</p>
				<p>They will no longer show up in your reservation history.</p>
				<div className="flex w-full items-center gap-x-1.5 pt-2">
					{!isRecurring ? (
						<DialogClose className="w-full rounded-md border border-primary bg-transparent px-4 py-2 text-primary">
							Cancel
						</DialogClose>
					) : null}

					<Button
						className={cn(
							" w-full rounded-md px-4 py-2 text-white",
							{
								"border bg-transparent text-destructive  hover:text-white":
									isRecurring,
							}
						)}
						variant="destructive"
						onClick={handleDelete}
					>
						{isLoading ? (
							<LoadingOutlined style={{ fontSize: 24 }} spin />
						) : isRecurring ? (
							"Delete Occurence "
						) : (
							"Delete"
						)}
					</Button>

					{isRecurring ? (
						<Button
							className={cn(
								" w-full rounded-md px-4 py-2 text-white hover:text-white",
								{
									"border bg-transparent text-destructive  hover:text-white":
										isRecurring,
								}
							)}
							variant="destructive"
							onClick={handleDeleteRecurring}
						>
							{isRecurringLoading ? (
								<LoadingOutlined
									style={{ fontSize: 24 }}
									spin
								/>
							) : (
								"Delete Series"
							)}
						</Button>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default DeleteBooking;
