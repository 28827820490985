import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion";
import dayjs from "dayjs";
import React from "react";
import BookedByPartners from "./BookedByPartners";
import MostBookedRooms from "./MostBookedRooms";
import { useAppSelector } from "@/hooks/hooks";

const Statistics: React.FC = () => {
	const queryData = useAppSelector((state) => state.analytics.query_data);

	const dateRange =
		dayjs(queryData.start_date).format("D MMMM, YYYY") +
		" - " +
		dayjs(queryData.end_date).format("D MMMM, YYYY");

	return (
		<Accordion
			type="single"
			collapsible
			className="flex flex-col space-y-6"
			defaultValue="item-1"
		>
			<AccordionItem
				value="item-1"
				className="w-full rounded-[20px] border-none bg-[#F8F9FB] p-3"
			>
				<AccordionTrigger className="flex py-0 hover:no-underline">
					<div className="flex flex-col items-start space-y-1">
						<h3 className="text-lg font-semibold text-[#111827]">
							Statistics
						</h3>
						<p className="text-sm font-normal text-[#374151]">
							{dateRange}
						</p>
					</div>
				</AccordionTrigger>
				<AccordionContent className="p-0 pt-4">
					<div className="grid h-fit grid-cols-[0.4fr_0.6fr] gap-4">
						<MostBookedRooms />
						<BookedByPartners />
					</div>
				</AccordionContent>
			</AccordionItem>
		</Accordion>
	);
};

export default Statistics;
