import { Skeleton } from "@/components/ui/skeleton";

const RoomLoadingCard = () => {
	return (
		<div className="flex flex-col gap-y-2 rounded-xl bg-[#FBFBFB] p-1.5 drop-shadow-[0_0_6px_rgba(0,0,0,0.15)]">
			<Skeleton className="h-[149px] rounded-tl-xl rounded-tr-xl bg-[#DCDCDC]" />
			<div className="space-y-2">
				<Skeleton className="h-4 w-[250px] bg-[#DCDCDC]" />
				<Skeleton className="h-4 w-[200px] bg-[#DCDCDC]" />
			</div>
			<div className="flex justify-end pt-4">
				<Skeleton className="h-[25px] w-[100px] rounded-md bg-[#DCDCDC]" />
			</div>
		</div>
	);
};

export default RoomLoadingCard;
