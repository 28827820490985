import { cn } from "@/lib/utils";
import { Dispatch, SetStateAction, useState } from "react";
import FormInput from "@/components/input/FormInput";
import { Button } from "@/components/ui/button";

import { isEqual } from "lodash";
import { MdMoreVert } from "react-icons/md";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import useCustomToast from "@/components/CustomToast";
import {
	useDeletePartnerMemberMutation,
	useDeletePartnerUserMutation,
	useUpdatePartnerMemberMutation,
	useUpdatePartnerUserMutation,
} from "@/redux/features/api/generalEndpoints";

const EditPartnerMember: React.FC<{
	member: any;
	showMemberDetails: boolean;
	setShowMemberDetails: Dispatch<SetStateAction<boolean>>;
}> = ({ member, showMemberDetails, setShowMemberDetails }) => {
	const customToast = useCustomToast();
	const [editDetails, setEditDetails] = useState(false);
	const [formData, setFormData] = useState({
		name: member.name,
		email: member.email,
		phone: member.phone_number,
	});

	const [updatePartnerMember, updatePartnerMemberMutation] =
		useUpdatePartnerMemberMutation();
	const [updatePartnerUser, updatePartnerUserMutation] =
		useUpdatePartnerUserMutation();
	const [deletePartnerMember, deletePartnerMemberMutation] =
		useDeletePartnerMemberMutation();
	const [deletePartnerUser, deletePartnerUserMutation] =
		useDeletePartnerUserMutation();

	const handleDelete = async () => {
		try {
			customToast("Deleting " + member.name, {
				id: "delete-team-member",
				type: "loading",
			});
			let deleteFunction;
			let deleteParams;

			if (member?.user_id == null) {
				deleteFunction = deletePartnerMember;
				deleteParams = {
					company_id: member.company_id,
					member_id: member.member_id,
				};
			} else {
				deleteFunction = deletePartnerUser;
				deleteParams = {
					company_id: member.company_id,
					user_id: member.user_id,
				};
			}
			await deleteFunction(deleteParams).unwrap();

			setShowMemberDetails(false);
			customToast(`Deleted ${member.name}`, {
				id: "delete-team-member",
				type: "success",
			});
		} catch (error) {
			customToast("Unable to delete " + member.name, {
				id: "delete-team-member",
				type: "error",
			});
		}
	};

	const handleUpdateMember = async () => {
		const updatedData = {
			name: member.name,
			email: member.email,
			phone_number: member.phone_number,
		};

		if (
			isEqual(updatedData, {
				name: formData.name,
				email: formData.email,
				phone_number: formData.phone,
			})
		) {
			setEditDetails(false);
		}
		try {
			customToast(`Updating ${member.name}`, {
				id: "update-team-member",
				type: "loading",
			});
			let updateFunction;
			let updateParams;

			if (member?.user_id == null) {
				updateFunction = updatePartnerMember;
				updateParams = {
					company_id: member.company_id,
					member_id: member.member_id,
					data: {
						name: formData.name ?? undefined,
						email: formData.email,
						phone_number: formData.phone ?? undefined,
						role: member.role,
					},
				};
			} else {
				updateFunction = updatePartnerUser;
				updateParams = {
					company_id: member.company_id,
					user_id: member.user_id,
					data: {
						name: formData.name ?? undefined,
						email: formData.email,
						phone_number: formData.phone ?? undefined,
						role: member.role,
					},
				};
			}
			await updateFunction(updateParams).unwrap();
			setShowMemberDetails(false);
			setEditDetails(false);
			customToast("Member updated successfully", {
				id: "update-team-member",
				type: "success",
			});
		} catch (error) {
			customToast("Error updating member", {
				id: "update-team-member",
				type: "error",
			});
		}
	};

	return (
		<Dialog
			modal
			open={showMemberDetails}
			onOpenChange={(value) => {
				if (
					!deletePartnerMemberMutation.isLoading ||
					!deletePartnerUserMutation.isLoading
				)
					setShowMemberDetails(value);
			}}
		>
			<DialogTrigger asChild>
				<Button variant="ghost">
					<MdMoreVert className="h-5 w-5 text-[#D9D9D9]" />
				</Button>
			</DialogTrigger>
			<DialogContent className="flex w-[80%] max-w-[400px] rounded-lg border border-[#E5E5E7] bg-white p-4 shadow-md">
				<div className="flex w-full flex-col">
					<h1 className="font-semibold">User Details</h1>
					<div className="mt-3 flex w-full flex-col gap-y-2">
						<div className="flex gap-4 ">
							<div className="flex w-full items-center gap-x-2">
								<span
									className={cn(
										"relative flex h-[16px] w-[16px] items-center justify-center rounded-full border border-[#B7B7B7] bg-[#F8F8F8] p-2.5 text-[8px] text-[#525252] md:text-[12px]"
									)}
								>
									{formData?.name?.charAt(0).toUpperCase() ??
										""}
								</span>
								<FormInput
									inputType="text"
									value={formData?.name ?? ""}
									className={cn(" h-7 w-full  text-[14px]", {
										"border-none px-0 focus:border-none":
											!editDetails,
									})}
									onChange={(e) =>
										setFormData({
											...formData,
											name: e.target.value,
										})
									}
									disabled={!editDetails}
								/>
							</div>
						</div>

						<div className="mb-2 flex w-full flex-col items-center justify-center gap-y-2">
							<div className="relative flex w-full items-center gap-2">
								{/* <MdOutlineMail className="absolute left-2" /> */}
								<i className="mgc_mail_line text-[18px] before:!text-[#858C95]" />

								<FormInput
									inputType="text"
									value={formData.email ?? ""}
									className={cn("h-7 px-3 text-[14px]", {
										"border-none px-0 focus:border-none":
											!editDetails,
									})}
									onChange={(e) =>
										setFormData({
											...formData,
											email: e.target.value,
										})
									}
									disabled={!editDetails}
								/>
							</div>
							<div className="relative flex w-full items-center gap-2">
								<i className="mgc_phone_line text-[18px] before:!text-[#858C95]" />
								<FormInput
									inputType="text"
									value={formData.phone ?? ""}
									className={cn("h-7 px-3 text-[14px]", {
										"border-none px-0 focus:border-none":
											!editDetails,
									})}
									onChange={(e) =>
										setFormData({
											...formData,
											phone: e.target.value,
										})
									}
									disabled={!editDetails}
								/>
							</div>
						</div>
						<div className="flex items-center gap-2 msm:flex-col">
							{member?.role !== "PARTENER_SUPER_ADMIN" &&
								(!editDetails ? (
									<Button
										variant="outlinecustom"
										className="flex h-7 w-full items-center gap-2 px-1.5 py-1.5"
										onClick={handleDelete}
										disabled={
											deletePartnerMemberMutation.isLoading ||
											deletePartnerUserMutation.isLoading ||
											updatePartnerMemberMutation.isLoading ||
											updatePartnerUserMutation.isLoading
										}
									>
										<p>Delete</p>
										<i className="mgc_delete_2_line before:!text-primary" />
									</Button>
								) : null)}
							{(!updatePartnerMemberMutation.isLoading ||
								!updatePartnerUserMutation.isLoading) && (
								<Button
									variant="outlinecustom"
									className="flex h-7 w-full items-center gap-2 px-1.5 py-1.5"
									onClick={
										editDetails
											? handleUpdateMember
											: () => setEditDetails(true)
									}
									disabled={
										deletePartnerMemberMutation.isLoading ||
										deletePartnerUserMutation.isLoading ||
										updatePartnerMemberMutation.isLoading ||
										updatePartnerUserMutation.isLoading
									}
								>
									{editDetails ? "Save" : "Edit"}
									{editDetails ? (
										<i className="mgc_save_2_line before:!text-primary" />
									) : (
										<i className="mgc_edit_2_line before:!text-primary" />
									)}
								</Button>
							)}

							{editDetails && (
								<Button
									variant="outlinecustom"
									className="flex h-7 w-full items-center gap-2 px-1.5 py-1.5"
									onClick={() => setEditDetails(false)}
									disabled={
										deletePartnerMemberMutation.isLoading ||
										deletePartnerUserMutation.isLoading ||
										updatePartnerMemberMutation.isLoading ||
										updatePartnerUserMutation.isLoading
									}
								>
									Cancel
								</Button>
							)}
						</div>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default EditPartnerMember;
