import { useQueryParams } from "@/services/utilities";
import RegisterPartner from "../guest/partner/RegisterPartner";
import RegisterTeamMember from "../guest/partner/RegisterTeamMember";
import RegisterAdminTeamMember from "../guest/admin/RegisterAdminTeamMember";

const Register = () => {
	const query = useQueryParams();
	const role = query.get("role");
	const token = query.get("token");

	// Determine which component to render based on the role and token
	const renderContent = () => {
		if (role === "partner" && token) {
			return <RegisterPartner />;
		} else if (role === "partner_team_member" && token) {
			return <RegisterTeamMember />;
		} else if (role === "business_team_member") {
			return <RegisterAdminTeamMember />;
		} else {
			return (
				<div className="mx-auto mt-auto flex h-full w-full flex-col items-center justify-center gap-x-8 px-8 lg:flex-row xl:px-24">
					<p>Invalid role or token</p>;
				</div>
			);
		}
	};

	return <>{renderContent()}</>;
};

export default Register;
