import CustomMultiSelect from "@/components/common/CustomMultiSelect";
import ImageUploader from "@/components/common/ImageUploader";
import useCustomToast from "@/components/CustomToast";
import FormInput from "@/components/input/FormInput";
import { LoaderButton } from "@/components/Loader/LoaderButton";
import { DialogClose } from "@/components/ui/dialog";
import { useAppSelector } from "@/hooks/hooks";
import { cn } from "@/lib/utils";
import {
	useEditRoomMutation,
	useGetAllAdminLocationAmenitiesQuery,
} from "@/redux/features/apiSlice";
import { allowedUploadFormats } from "@/services/constants";
import { createFormData, handleFileChange } from "@/services/utilities";
import { TeamMemberRoles } from "@/types/team-member";
import { Image } from "antd";
import { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";

export interface RoomPhotoResponse {
	id: number;
	file_name: string;
	room_id: number;
	created_at: string;
	updated_at: string;
	image_url: string;
}

const EditRoom = ({ roomData, handleClose }) => {
	const id = location.pathname.split("/").pop();
	const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");
	const room_id = roomData.id;
	const { data: amenitiesList } = useGetAllAdminLocationAmenitiesQuery({
		id: parseInt(id!),
	});
	const customToast = useCustomToast();
	const [EditRoom, { isLoading: isEditLoading }] = useEditRoomMutation();
	const [formData, setFormData] = useState<{
		name: string;
		address: string;
		capacity: string;
		description: string;
		amenities: any;
		roomPhotos: RoomPhotoResponse[];
		amenitiesToAdd: number[];
		amenitiesToRemove: number[];
	}>({
		name: "",
		address: "",
		capacity: "",
		description: "",
		amenities: [],
		roomPhotos: [],
		amenitiesToAdd: [],
		amenitiesToRemove: [],
	});

	const [previewOpen, setPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	// const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [photosToAdd, setPhotosToAdd] = useState<FileList>();
	const [photosToRemove, setPhotosToRemove] = useState<number[]>([]);
	const [selectedAmenities, setSelectedAmenities] = useState<
		{ label: string; value: string }[]
	>([]);
	// const roomImagesRef: any | null = useRef(null);
	useEffect(() => {
		setFormData({
			...formData,
			name: roomData.name,
			address: roomData.address,
			capacity: roomData.capacity,
			description: roomData.description,
			amenities: roomData.amenities.filter((amenity) =>
				roomData.amenities.includes(amenity.id)
			),
			roomPhotos: roomData.photos,
		});

		// setFileList(
		// 	roomData.photos.map((photo) => ({
		// 		uid: photo.id,
		// 		name: photo.file_name,
		// 		status: "done",
		// 		url: photo.image_url,
		// 	}))
		// );

		setSelectedAmenities(
			roomData?.amenities.map((amenity) => {
				return {
					label: amenity.name,
					value: amenity.id,
				};
			})
		);
	}, [roomData]);

	const handleRemoveSelectedAmenity = (amenity: string) => {
		const updatedAmenities = selectedAmenities.filter(
			(item) => item.label !== amenity
		);
		setSelectedAmenities(updatedAmenities);
		setFormData({
			...formData,
			amenities: updatedAmenities,
		});
	};

	// const handleChange: UploadProps["onChange"] = ({
	// 	fileList: newFileList,
	// 	file: currentFile,
	// }) => {
	// 	if (!beforeUpload(currentFile as RcFile)) {
	// 		const updatedFileList = newFileList.filter(
	// 			(file) => file.uid !== currentFile.uid
	// 		);
	// 		setFileList(updatedFileList);
	// 		return;
	// 	}
	// 	setFileList(newFileList);

	// 	const newFiles = newFileList
	// 		.filter(
	// 			(file) =>
	// 				!roomData.photos.some((photo) => photo.id === file.uid)
	// 		)
	// 		.map((file) => file.originFileObj as File);

	// 	// setFormData((prevFormData) => ({
	// 	// 	...prevFormData,
	// 	// 	roomPhotos: newFiles,
	// 	// }));
	// };

	// const beforeUpload = (file: RcFile) => {
	// 	const isJpgOrPng =
	// 		file.type === "image/jpeg" || file.type === "image/png";

	// 	if (!isJpgOrPng) {
	// 		message.error("You can only upload JPG/PNG file!");
	// 	}
	// 	const isLt2M = file.size / 1024 / 1024 < 2;
	// 	if (!isLt2M) {
	// 		message.error("Image must be smaller than 2MB!");
	// 	}
	// 	return isJpgOrPng && isLt2M;
	// };

	// const handlePreview = async (file: UploadFile) => {
	// 	if (!file.url && !file.preview) {
	// 		file.preview = await getBase64(file.originFileObj as FileType);
	// 	}
	// 	setPreviewImage(file.url || (file.preview as string));
	// 	setPreviewOpen(true);
	// };

	const handleSubmit = async () => {
		if (
			userRole === TeamMemberRoles.TeamMember ||
			userRole === TeamMemberRoles.PartnerManager
		)
			return customToast("You are not allowed to perform this action", {
				id: "add-room",
				type: "error",
			});
		// const photos_to_add: any = fileList
		// 	.filter(
		// 		(file) =>
		// 			!roomData.photos.some((photo) => photo.uid === file.uid) &&
		// 			(file.originFileObj as File)
		// 	)
		// 	.map((file) => file.originFileObj);
		// const photos_to_remove: any = roomData.photos
		// 	.filter((photo) => !fileList.some((file) => file.uid === photo.id))
		// 	.map((photo) => photo.id);

		const formDataToSend = new FormData();
		formDataToSend.append("name", formData.name);
		formDataToSend.append("capacity", formData.capacity);
		if (formData.address.length)
			formDataToSend.append("address", formData.address);
		if (formData.description.length)
			formDataToSend.append("description", formData.description);
		if (photosToAdd)
			Array.from(photosToAdd).forEach((file, index) => {
				formDataToSend.append(`photos_to_add[${index}]`, file);
			});

		if (photosToRemove)
			photosToRemove.forEach((file, index) => {
				formDataToSend.append(
					`photos_to_remove[${index}]`,
					file.toString()
				);
			});

		createFormData(
			formDataToSend,
			"amenities_to_remove",
			roomData.amenities
				.filter(
					(amenity) =>
						!selectedAmenities.some((a) => amenity.id == a.value)
				)
				.map((amenity) => amenity.id)
		);

		createFormData(
			formDataToSend,
			"amenities_to_add",
			selectedAmenities
				.filter(
					(amenity) =>
						!roomData.amenities.some((a) => amenity.value == a.id)
				)
				.map((amenity) => amenity.value)
		);
		try {
			const submissionData = {
				id: id!,
				room_id: room_id!,
				data: formDataToSend,
			};
			const response = await EditRoom(submissionData).unwrap();
			customToast(response.message, {
				id: "add-room",
				type: "error",
			});
			handleClose();
		} catch (error) {
			customToast("An error occured", {
				id: "add-room",
				type: "error",
			});
			// console.error(error);
		}
	};

	// const uploadImage = async (options) => {
	// 	if (
	// 		userRole === TeamMemberRoles.TeamMember ||
	// 		userRole === TeamMemberRoles.PartnerManager
	// 	)
	// 		return notifyWithIcon(
	// 			"error",
	// 			"You are not allowed to perform this action"
	// 		);
	// 	const { file } = options;

	// 	const imageFormData = new FormData();

	// 	const submissionData = {
	// 		id: id!,
	// 		room_id: room_id!,
	// 		data: imageFormData,
	// 	};

	// 	imageFormData.append("photos_to_add[]", file);

	// 	try {
	// 		await EditRoom(submissionData);
	// 		notifyWithIcon("success", "Image uploaded succesfully");
	// 	} catch (err) {
	// 		notifyWithIcon("error", "Image could not be uploaded");
	// 	}
	// };

	const removeImageFromExisting = (image: RoomPhotoResponse) => {
		setPhotosToRemove((prevFormData) => {
			const photosToRemoveId = formData.roomPhotos.find(
				(file) => file !== image
			);

			return [...prevFormData, photosToRemoveId?.id ?? 0];
		});

		setFormData((prevFormData) => {
			const newPhotos = prevFormData.roomPhotos.filter(
				(file) => file !== image
			);
			return {
				...prevFormData,
				roomPhotos: newPhotos,
			};
		});
	};

	return (
		<div>
			<div className="flex max-h-[70svh] w-full flex-col gap-y-3 rounded-[30px]  bg-[#F5F5F5]">
				<div className="overflow-y-auto px-8 py-2">
					{/* <Upload
						multiple={true}
						listType="picture-card"
						fileList={fileList}
						beforeUpload={beforeUpload}
						onPreview={handlePreview}
						onChange={handleChange}
						accept="image/*"
						customRequest={uploadImage}
					>
						{fileList.length >= 8 ? null : uploadButton}
					</Upload> */}
					<div className="add-room-grid">
						{formData.roomPhotos.map((file, index) => (
							<div
								key={index}
								className="group relative flex h-[108px] max-h-[108px] w-[108px] max-w-[108px] cursor-pointer items-center justify-center overflow-hidden rounded-[20px] border-[5px] border-solid border-white bg-[#E5E5E7] shadow-[0px_0px_5px_0px_rgba(0,0,0,0.25)]"
								onClick={() => removeImageFromExisting(file)}
							>
								<img
									src={file.image_url}
									alt={`Selected ${index}`}
									className="h-full w-full rounded-[20px] object-scale-down"
								/>
								<span className="absolute left-1/2 top-1/2 z-10 flex h-full w-full -translate-x-1/2 -translate-y-1/2 items-center justify-center bg-white bg-opacity-0 opacity-0 transition-opacity duration-200 ease-in-out group-hover:bg-opacity-70 group-hover:opacity-100">
									<i className="mgc_delete_2_line before:!text-[#C8322B]" />
								</span>
							</div>
						))}

						<ImageUploader
							photos={photosToAdd}
							setPhotos={setPhotosToAdd}
							allowedUploadFormats={allowedUploadFormats}
							handleFileChange={handleFileChange}
						/>
					</div>
					{previewImage && (
						<Image
							wrapperStyle={{
								display: "none",
							}}
							preview={{
								visible: previewOpen,
								onVisibleChange: (visible) =>
									setPreviewOpen(visible),
								afterOpenChange: (visible) =>
									!visible && setPreviewImage(""),
							}}
							src={previewImage}
						/>
					)}

					{/* Description  */}
					<div className="flex flex-col gap-y-4 pt-4">
						<FormInput
							name="name"
							value={formData.name}
							label="Space Name"
							inputType="text"
							placeholder="Add Name"
							onChange={(e) =>
								setFormData({
									...formData,
									name: e.target.value,
								})
							}
						/>
						<div className="flex gap-4">
							<FormInput
								name="address"
								value={formData.address}
								label="Space Location"
								inputType="text"
								placeholder="Add Address"
								onChange={(e) =>
									setFormData({
										...formData,
										address: e.target.value,
									})
								}
							/>
							<FormInput
								name="capacity"
								value={formData.capacity}
								min={0}
								label="Capacity"
								inputType="number"
								placeholder="Add Capacity"
								onChange={(e) =>
									setFormData({
										...formData,
										capacity: e.target.value,
									})
								}
							/>
						</div>
						<FormInput
							label="Description"
							value={formData.description}
							textarea={true}
							className="text-[15px]"
							rows={2}
							placeholder="Add Description"
							onChange={(e) =>
								setFormData({
									...formData,
									description: e.target.value,
								})
							}
						/>

						<div className="flex flex-col gap-y-1">
							<h1 className="text-sm font-medium text-basecolor">
								Amenities
							</h1>
							<CustomMultiSelect
								placeholder="Add Amenity"
								isSearchable
								isMulti
								closeMenuOnSelect={false}
								value={selectedAmenities}
								onChange={(selectedOption) => {
									setSelectedAmenities(selectedOption);
									setFormData({
										...formData,
										amenities: selectedOption,
									});
								}}
								options={
									amenitiesList?.data.length
										? amenitiesList?.data?.map(
												(amenity) => ({
													label: amenity.name,
													value: amenity.id,
												})
											)
										: []
								}
							/>
						</div>

						{/* Amenities  */}
						<div className="mb-4 space-y-2">
							<div className="flex items-center gap-2">
								{selectedAmenities.map((item, id) => (
									<AmenityHolder
										removeSelected={
											handleRemoveSelectedAmenity
										}
										key={id}
										amenity={item.label}
										variant="overview"
									/>
								))}
							</div>
						</div>
					</div>
				</div>
				<div className="flex w-full items-center gap-x-1.5 rounded-bl-[30px] rounded-br-[30px] px-4 py-4">
					<DialogClose
						className="w-full rounded-md border border-primary bg-transparent px-4 py-2 text-primary"
						disabled={isEditLoading}
					>
						Cancel
					</DialogClose>
					<LoaderButton
						className=" w-full rounded-md px-4 py-2 text-white "
						onClick={handleSubmit}
						loaderSize={24}
						loading={isEditLoading}
					>
						Update Space
					</LoaderButton>
				</div>
			</div>
		</div>
	);
};

export default EditRoom;

interface AmenityHolderProps {
	amenity: string;
	variant?: "overview" | "list";
	removeSelected: (amenity: string) => void;
}
const AmenityHolder = ({
	amenity,
	variant,
	removeSelected,
}: AmenityHolderProps) => {
	return (
		<div>
			<span
				className={cn("flex items-center gap-1 text-[#959595] ", {
					"rounded-full bg-primary/5 px-2 py-1":
						variant === "overview",
				})}
			>
				<p
					className={cn("text-[14px] font-normal text-[#6B7280]", {
						"text-[10px]": variant === "list",
					})}
				>
					{amenity}
				</p>
				<button onClick={() => removeSelected(amenity)}>
					<IoClose />
				</button>
			</span>
		</div>
	);
};
