import CalendarImage from "@/assets/icons/full_calendar.svg";
import useCustomToast from "@/components/CustomToast";
import { Button } from "@/components/ui/button";
import { useUpdateAdminBookingStatusMutation } from "@/redux/features/apiSlice";
import { formatDateString, formatTime } from "@/services/utilities";
import { Booking } from "@/types/admin/booking";
import { Spin } from "antd";
import { useEffect, useState } from "react";
import { FaLocationDot, FaRegClock } from "react-icons/fa6";
import { LuCalendarCheck, LuContact, LuUsers } from "react-icons/lu";
import { PiBuildings } from "react-icons/pi";

interface ApprovedBooking {
	handleReschedule: (type) => void;
	handleClose: () => void;
	data: Booking;
}
const ApprovedBooking = ({ handleReschedule, handleClose, data }) => {
	const [showEditRecurringPane, setShowEditRecurringPane] = useState(false);
	const isRecurring = data.is_recurring;
	const [
		updateStatus,
		{
			isSuccess: isDeleteSuccess,
			isLoading: isDeleteLoading,
			isError: isDeleteError,
		},
	] = useUpdateAdminBookingStatusMutation();
	const toast = useCustomToast();
	const handleDeleteBooking = () => {
		updateStatus({
			booking_id: data?.id.toString(),
			data: { status: "delete" },
		});
	};
	const handleShowEdit = () => {
		if (isRecurring) {
			setShowEditRecurringPane(!showEditRecurringPane);
		} else {
			handleReschedule("normal");
		}
	};

	useEffect(() => {
		if (isDeleteSuccess) {
			toast("Reservation deleted successfully!", {
				id: "delete",
				type: "success",
			});
			handleClose();
		} else if (isDeleteError) {
			toast("Error deleting Reservation", {
				id: "delete",
				type: "error",
			});
		}
	}, [isDeleteSuccess, isDeleteError]);

	return (
		<div className="flex flex-col gap-y-6">
			<div className="flex justify-between border-b-2 pb-2">
				<div>
					<h1 className=" pb-2 text-3xl font-semibold text-basecolor">
						Reservation Details
					</h1>
					<p className="flex items-center gap-2 font-medium text-basecolor">
						<span className="text-[#BFBFBF]">
							<PiBuildings />
						</span>
						{data?.room?.name ?? "N/A"}
					</p>
					<p className="flex items-center gap-2 pt-2 font-medium text-basecolor">
						<span className="text-[#BFBFBF]">
							<FaLocationDot />
						</span>
						{data?.room?.address ?? "N/A"}
					</p>
				</div>
				<div>
					<img src={CalendarImage} />
				</div>
			</div>

			{/* Info  */}

			<div className="flex gap-x-6">
				<div className="w-full">
					<h1 className="max-w-full divide-y-0 border-b-2 border-b-[#BFBFBF] pb-2 font-semibold  text-[#323539]">
						{data?.title ?? "N/A"}
					</h1>
					<section className="hidden flex-col gap-y-2 pt-2 md:flex">
						<p className="flex items-center gap-2">
							<span className="text-[#BFBFBF]">
								<LuCalendarCheck />
							</span>
							{data?.start_at
								? formatDateString(data?.start_at.split(" ")[0])
								: "N/A"}
						</p>
						<p className="flex items-center gap-2 ">
							<span className="text-[#BFBFBF]">
								<FaRegClock />
							</span>
							{data?.start_at
								? formatTime(data.start_time)
								: "N/A"}{" "}
							{"-"}{" "}
							{data?.end_time ? formatTime(data.end_time) : "N/A"}
						</p>

						<p className="flex items-center gap-2 ">
							<LuContact className="text-xl text-[#BFBFBF]" />
							<span className=" ">
								{data?.company?.name ?? "N/A"}
							</span>
						</p>
						<p className="flex items-center gap-2 ">
							<span className="flex h-[13px] w-[13px] items-center justify-center rounded-full border border-[#BFBFBF] bg-[#fff] p-2 text-xs text-[#BFBFBF]">
								{data?.host?.name.split("")[0][0]}
							</span>
							{data?.host?.name ?? "N/A"}
						</p>
						<p className="flex items-center gap-2">
							<LuUsers className="text-[#BFBFBF]" />

							<span className=" text-[#6B7280]">
								{" "}
								{data?.meeting_members.length} Attendees
							</span>
						</p>
					</section>
				</div>
				<div className="w-full">
					{data?.room?.photos?.length ? (
						<img
							src={data.room.photos[0]?.image_url}
							className="h-[171px] w-[352px] rounded-md"
						/>
					) : (
						<div className="flex h-[171px] w-[352px] flex-col justify-center rounded-md bg-[#DCDCDC] px-4">
							<div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-[#C7C7C7] ">
								<p className="select-none text-lg font-semibold text-white">
									{data.room?.name
										.split(" ")[0][0]
										.toUpperCase()}
								</p>
							</div>
						</div>
					)}
				</div>
			</div>

			{data?.status !== "done" && (
				<div className="relative flex w-full items-center gap-x-1.5 pb-2 pt-4">
					{showEditRecurringPane && (
						<EditRecurringPane
							handleOccurence={() =>
								handleReschedule("occurence")
							}
							handleSeries={() => handleReschedule("series")}
						/>
					)}

					<Button
						variant="outlinecustom"
						className="w-full bg-transparent"
						onClick={handleDeleteBooking}
					>
						{isDeleteLoading ? (
							<Spin style={{ color: "#fff" }} />
						) : (
							"Delete Reservation"
						)}
					</Button>
					<Button
						className=" w-full rounded-md px-4 py-2 text-white "
						onClick={handleShowEdit}
					>
						Edit Reservation
					</Button>
				</div>
			)}
		</div>
	);
};

export default ApprovedBooking;

const EditRecurringPane = ({
	handleSeries,
	handleOccurence,
}: {
	handleSeries: () => void;
	handleOccurence: () => void;
}) => {
	return (
		<div className="absolute bottom-16 right-2 flex w-[280px] flex-col gap-y-2 rounded-md bg-white p-6 shadow-md">
			<Button variant="outlinecustom" className="" onClick={handleSeries}>
				Edit Series
			</Button>
			<Button variant="default" onClick={handleOccurence}>
				Edit Occurence
			</Button>
		</div>
	);
};
