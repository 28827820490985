import { Option } from "@/components/common/CustomShadSelect";
import { cn } from "@/lib/utils";
import { countryOptions } from "@/services/constants";
import { Check, ChevronDown } from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import { Button } from "../ui/button";
import { Command, CommandInput, CommandItem, CommandList } from "../ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";

// React.FC<{
// 	// setFormValue: UseFormSetValue<any>;
// 	// setProvinceOptions: Dispatch<
// 	// 	SetStateAction<
// 	// 		{
// 	// 			label: string;
// 	// 			value: string;
// 	// 		}[]
// 	// 	>
// 	// >;
// 	// formTrigger: UseFormTrigger<any>;
// 	// errorMessage?: string;
// }>

const AddressComponent: React.FC<{
	label?: string;
	placeholder?: string;
	className?: string;
	placeholderClassName?: string;
	onGetAddress: (
		address: string,
		city: string,
		province: string,
		country: string,
		state: string
	) => void;
	errorMessage?: string;
	oldValue?: string;
}> = ({
	label,
	className,
	placeholder,
	placeholderClassName,
	errorMessage,
	onGetAddress,
	oldValue,
}) => {
	const {
		ready,
		value,
		suggestions: { data },
		setValue,
		clearSuggestions,
	} = usePlacesAutocomplete({
		debounce: 300,
	});
	const [open, setOpen] = useState(false);
	const [selected, setSelected] = useState<string>(oldValue ?? "");
	// const user = useUserStore((s) => s.user);

	const handleSetActive = useCallback((suggestion) => {
		setSelected(suggestion.description);
		setValue(suggestion.description);

		setOpen(false);

		clearSuggestions();

		getGeocode({ address: suggestion.description }).then((results) => {
			// Extract address components to get city, province, country, and postal code
			const addressComponents = results[0].address_components;
			let city = "";
			let province = "";
			let country = "";
			let postalCode = "";
			for (const component of addressComponents) {
				const types = component.types;
				if (types.includes("locality")) {
					city = component.long_name;
				} else if (types.includes("administrative_area_level_1")) {
					province = component.long_name;
				} else if (types.includes("country")) {
					country = component.long_name;
				} else if (types.includes("postal_code")) {
					postalCode = component.long_name;
				}
			}
			// console.log(city, province, country, postalCode);
			onGetAddress(
				suggestion.description,
				city,
				province,
				country,
				postalCode
			);
			// const countryValue = findCountryByLabel(country);
			// setFormValue("city", city);
			// setFormValue("postal_code", postalCode);
			// setFormValue("address", suggestion.description);
			// const newProvinceOptions = changeCountry(countryValue);
			// setProvinceOptions(newProvinceOptions);
			// const newProvince = findProvinceByLabel(
			// 	newProvinceOptions,
			// 	province
			// );
			// updateCountryAndState(
			// 	setFormValue,
			// 	setProvinceOptions,
			// 	true,
			// 	newProvince,
			// 	countryValue
			// );
			// formTrigger();
			// setFormValue("postal_code", postalCode);
		});
	}, []);

	useEffect(() => {
		if (oldValue) setSelected(oldValue);
	}, [oldValue]);

	// console.log(oldValue, selected)
	const displayName = selected ? selected : placeholder;

	return (
		<div className="flex-1">
			{label && (
				<label className="bt-1.5 mb-1.5 block text-sm font-medium tracking-[-0.1px] text-[#323539]">
					{label} <span className="text-red-600">*</span>
				</label>
			)}
			<Popover open={open} onOpenChange={setOpen}>
				<PopoverTrigger asChild className="flex w-full px-3">
					<Button
						variant="outline"
						role="combobox"
						className={cn(
							"max-w-[385px] justify-between overflow-hidden",
							className,
							{ "border-red-500": errorMessage }
						)}
					>
						<p
							className={cn(
								"truncate-address font-medium text-[#323539]",
								{
									"text-[#323539]/50":
										displayName === placeholder,
								},
								placeholderClassName
							)}
						>
							{displayName}
						</p>
						<ChevronDown className="ml-auto h-4 w-4 shrink-0 opacity-50" />
					</Button>
				</PopoverTrigger>

				<PopoverContent
					side="bottom"
					className={cn("flex-1 p-0", "min-w-[350px]")}
				>
					<Command
						shouldFilter={false}
						className="h-auto rounded-lg border border-b-0 shadow-md"
					>
						<CommandInput
							value={value}
							onValueChange={setValue}
							disabled={!ready}
							placeholder="Search for your address"
						/>

						<CommandList>
							{data.map((suggestion) => {
								const { place_id } = suggestion;
								return (
									<CommandItem
										key={place_id}
										onSelect={() =>
											handleSetActive(suggestion)
										}
										value={suggestion}
									>
										<Check
											className={cn(
												"mr-2 h-4 w-4",
												selected === suggestion
													? "opacity-100"
													: "opacity-0"
											)}
										/>
										{suggestion.description}
									</CommandItem>
								);
							})}
						</CommandList>
					</Command>
				</PopoverContent>
			</Popover>
			{errorMessage && (
				<small className="mt-1.5 text-sm text-red-500">
					{errorMessage}
				</small>
			)}
		</div>
	);
};

export default AddressComponent;

export const findCountryByLabel = (searchingCountry: string) => {
	return (
		countryOptions.find(
			(country) =>
				country.label.toLowerCase() === searchingCountry.toLowerCase()
		)?.value ?? ""
	);
};

export const findProvinceByLabel = (
	newProvinceOptions: Option[],
	searchingProvince: string
) => {
	return (
		newProvinceOptions.find(
			(province) =>
				province.label.toLowerCase() === searchingProvince.toLowerCase()
		)?.value ?? ""
	);
};
