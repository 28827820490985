import { HiOutlineTrash } from "react-icons/hi2";
import { DialogClose } from "../../ui/dialog";
import { Button } from "../../ui/button";
import { useUpdateMemberMutation } from "@/redux/features/api/partnerEndpoints";
import { LoadingOutlined } from "@ant-design/icons";
import { notifyWithIcon } from "@/services/utilities";
import { useEffect } from "react";

interface RemoveAdminProps {
	name: string;
	id: number;
	handleClose: () => void;
}
const RemoveAdmin = ({ name, id, handleClose }: RemoveAdminProps) => {
	const [updateMember, { isLoading, isSuccess, error, isError }] =
		useUpdateMemberMutation();

	const handleRemoveAdmin = async () => {
		updateMember({
			member_id: id ? parseInt(id.toString()) : 0,
			data: {
				role: "guest",
			},
		}).unwrap();
	};

	useEffect(() => {
		if (isSuccess) {
			notifyWithIcon("Error", "Admin removed successfully");
			handleClose();
		} else if (isError && error) {
			const errorData = error as {
				data?: { message: string; errors?: Record<string, string[]> };
			};
			if (errorData.data && errorData.data.errors) {
				for (const messages of Object.values(errorData.data.errors)) {
					(messages as string[]).forEach((message) => {
						notifyWithIcon("Error", message);
					});
				}
				handleClose();
			} else {
				notifyWithIcon("Error", "Error Removing Admin");
				handleClose();
			}
		}
	}, [isSuccess, isError, error]);

	return (
		<div className="flex items-start gap-2">
			<div className="flex flex-[0.1] pt-1">
				<HiOutlineTrash className="text-lg text-red-400" />
			</div>
			<div className="flex flex-grow flex-col gap-y-2">
				<h1 className="text-2xl font-semibold text-[#323539]">
					Remove as Admin
				</h1>
				<p>
					Are you sure you want to remove{" "}
					<span className="font-bold text-[#6D748D]">
						{name || "anna"}{" "}
					</span>
					from the admin list?
				</p>
				<p>
					They will no longer be able to reserve spaces or manage
					meetings.
				</p>
				<div className="flex w-full items-center gap-x-1.5 pt-2">
					<DialogClose className="w-full rounded-md border border-primary bg-transparent bg-white px-4 py-2 text-primary">
						Cancel
					</DialogClose>
					<Button
						className=" w-full rounded-md px-4 py-2 text-white"
						variant="destructive"
						onClick={handleRemoveAdmin}
					>
						{isLoading ? (
							<LoadingOutlined style={{ fontSize: 24 }} spin />
						) : (
							"Remove"
						)}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default RemoveAdmin;
