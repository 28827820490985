import { PiMonitorBold, PiSpeakerHifiBold } from "react-icons/pi";
import { LuChrome } from "react-icons/lu";
import { MdOutlineVideocam } from "react-icons/md";
import { IoAdd, IoClose, IoPodium } from "react-icons/io5";
import { CiViewBoard } from "react-icons/ci";
import { Mic } from "lucide-react";
import { cn } from "@/lib/utils";
import React from "react";

interface AmenityProps {
	amenity: string;
	selected: boolean;
	setSelected: (amenity: string) => void;
	removeSelected: (amenity: string) => void;
}
const Amenity = React.memo(
	({ amenity, selected, setSelected, removeSelected }: AmenityProps) => {
		return (
			<div>
				<span
					className={cn(
						"flex items-center gap-1 rounded-full bg-white px-2 py-1 text-[#959595] ",
						{
							"!bg-[#6B7280] text-white": selected,
						}
					)}
				>
					{amenity === "tv" && <PiMonitorBold />}
					{amenity === "whiteboard" && <CiViewBoard />}
					{amenity === "microphone" && <Mic className="w-4" />}
					{amenity === "video call" && <MdOutlineVideocam />}
					{amenity === "sound system" && <PiSpeakerHifiBold />}
					{amenity === "podium" && <IoPodium />}
					{amenity === "blackout curtains" && <IoPodium />}
					{amenity === "google chromecast" && <LuChrome />}
					<p
						className={cn(
							"text-sm font-normal capitalize text-[#6B7280]",
							{
								" text-white": selected,
							}
						)}
					>
						{amenity}
					</p>
					{!selected ? (
						<button
							className=""
							onClick={() => setSelected(amenity)}
						>
							<IoAdd />
						</button>
					) : (
						<button onClick={() => removeSelected(amenity)}>
							<IoClose />
						</button>
					)}
				</span>
			</div>
		);
	}
);

export default Amenity;
