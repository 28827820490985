import { Option } from "@/components/common/CustomShadSelect";
import { Badge } from "@/components/ui/badge";
import { Command, CommandGroup, CommandItem } from "@/components/ui/command";
import { CommandList, Command as CommandPrimitive } from "cmdk";
import { X } from "lucide-react";
import React, {
	Dispatch,
	KeyboardEvent,
	SetStateAction,
	useCallback,
	useRef,
	useState,
} from "react";
import { Merge, FieldError, FieldErrorsImpl } from "react-hook-form";

interface MultiSelectProps {
	options: Option[];
	placeholder: string;
	selected: Option[];
	setSelected: Dispatch<SetStateAction<Option[]>>;
	error?:
		| FieldError
		| Merge<FieldError, FieldErrorsImpl<any>>
		| undefined
		| "";
}

const MultiSelect: React.FC<MultiSelectProps> = ({
	options,
	placeholder,
	selected,
	setSelected,
	error,
}) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const [open, setOpen] = useState(false);
	// const [selected, setSelected] = useState<
	// 	Option[]
	// >([]);
	const [inputValue, setInputValue] = useState("");

	const handleUnselect = useCallback((option: Option) => {
		setSelected((prev) => prev.filter((s) => s.value !== option.value));
	}, []);

	const handleKeyDown = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
		const input = inputRef.current;
		if (input) {
			if (e.key === "Delete" || e.key === "Backspace") {
				if (input.value === "") {
					setSelected((prev) => {
						const newSelected = [...prev];
						newSelected.pop();
						return newSelected;
					});
				}
			}
			if (e.key === "Escape") {
				input.blur();
			}
		}
	}, []);

	const selectables = options.filter((option) => !selected.includes(option));

	return (
		<div>
			<Command
				onKeyDown={handleKeyDown}
				className="relative overflow-visible bg-transparent"
			>
				<div className="group rounded-md border border-input px-3 py-2 text-sm ring-offset-background focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2">
					<div className="flex flex-wrap gap-1">
						{selected.map((option) => (
							<Badge key={option.value} variant="secondary">
								{option.label}
								<button
									className="ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2"
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											handleUnselect(option);
										}
									}}
									onMouseDown={(e) => {
										e.preventDefault();
										e.stopPropagation();
									}}
									onClick={() => handleUnselect(option)}
								>
									<X className="h-3 w-3 text-muted-foreground hover:text-foreground" />
								</button>
							</Badge>
						))}
						<CommandPrimitive.Input
							ref={inputRef}
							value={inputValue}
							onValueChange={setInputValue}
							onBlur={() => setOpen(false)}
							onFocus={() => setOpen(true)}
							placeholder={placeholder}
							className="ml-2 flex-1 bg-transparent outline-none placeholder:text-muted-foreground"
						/>
					</div>
				</div>
				<div className="relative mt-2">
					{open && selectables.length > 0 ? (
						<div className="absolute top-0 z-10 w-full rounded-md border bg-popover text-popover-foreground shadow-md outline-none animate-in">
							<CommandList>
								<CommandGroup className="h-full overflow-auto">
									{selectables.map((option) => (
										<CommandItem
											key={option.value}
											onMouseDown={(e) => {
												e.preventDefault();
												e.stopPropagation();
											}}
											disabled={option.disabled}
											onSelect={() => {
												setInputValue("");
												setSelected((prev) => [
													...prev,
													option,
												]);
											}}
											className="cursor-pointer"
										>
											{option.label}
										</CommandItem>
									))}
								</CommandGroup>
							</CommandList>
						</div>
					) : null}
				</div>
			</Command>
			{error && (
				<small className="mt-1 text-xs text-red-500">
					{(error.message || error.type)?.toString()}
				</small>
			)}
		</div>
	);
};

export default MultiSelect;
