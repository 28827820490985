import React from "react";
import { motion } from "framer-motion";

const VisitsByLocationsAndRatingsLine: React.FC<{
	title: string;
	completeTotal: number;
	rowTotal: number;
	halfTotal: number;
	backgroundColor: string;
	hasHalfTotal?: boolean;
}> = ({
	title,
	completeTotal,
	rowTotal,
	halfTotal,
	backgroundColor,
	hasHalfTotal,
}) => {
	const walkInWidth = (halfTotal / (halfTotal + rowTotal)) * 100;

	return (
		<div>
			<h4 className="text-xs font-normal text-[#858C95]">{title}</h4>
			<div className="flex w-full items-center justify-between space-x-3">
				<motion.div
					className="flex h-3 flex-1 rounded-[2px]"
					animate={{
						maxWidth: `${halfTotal ? ((rowTotal + halfTotal) / completeTotal) * 100 : rowTotal !== 0 ? (rowTotal / completeTotal) * 100 : 0}%`,
					}}
					initial={{
						backgroundColor,
					}}
					transition={{
						duration: 3,
						ease: "easeOut",
					}}
				>
					{hasHalfTotal && (
						<motion.div
							className="flex-1 rounded-[2px] bg-[#005893]"
							animate={{
								maxWidth: `${walkInWidth}%`,
							}}
							transition={{
								duration: 3,
								ease: "easeOut",
							}}
						/>
					)}
				</motion.div>

				<p className="rounded-[28px] bg-[#F7F7F8] px-2 py-1 text-xs tracking-[0] text-[#043B6D]">
					{rowTotal + halfTotal}
				</p>
			</div>
		</div>
	);
};

export default VisitsByLocationsAndRatingsLine;
