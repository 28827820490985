import LocationList from "@/components/admin/location/LocationList";
import CustomShadSelect from "@/components/common/CustomShadSelect";
import Search from "@/components/input/Search";
import { Button } from "@/components/ui/button";
import { useAppSelector } from "@/hooks/hooks";
import { useGetAllAdminLocationsQuery } from "@/redux/features/apiSlice";
import { sortByName } from "@/services/utilities";
import { BookingLocation } from "@/types/location";
import { TeamMemberRoles } from "@/types/team-member";
import { useEffect, useState } from "react";
import { PiBuildings } from "react-icons/pi";
import { useNavigate } from "react-router";

const Locations: React.FC = () => {
	const { user } = useAppSelector((state) => state.user);
	const getAllAdminLocationQuery = useGetAllAdminLocationsQuery();
	const [items, setItems] = useState<BookingLocation[]>(
		getAllAdminLocationQuery?.data?.data ?? []
	);
	const [selectedSortBy, setSelectedSortBy] = useState("asc");
	const [searchQuery, setSearchQuery] = useState("");
	const navigate = useNavigate();

	const totalLocations = getAllAdminLocationQuery?.data
		? getAllAdminLocationQuery?.data.data.length
		: 0;

	const roomNumber = getAllAdminLocationQuery?.data
		? getAllAdminLocationQuery.data.data.reduce((total, current) => {
				return total + (current.total_rooms || 0);
			}, 0)
		: 0;

	const handleSortChange = (sortOrder: "asc" | "dsc") => {
		setSelectedSortBy(sortOrder);
		const sortedData = sortByName([...items], sortOrder);
		setItems(sortedData);
	};

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const query = e.target.value;
		setSearchQuery(query);
		setItems((prevData) =>
			e.target.value.length === 0
				? getAllAdminLocationQuery?.data?.data ?? []
				: prevData.filter(
						(item) =>
							(item.name &&
								item.name
									.toLocaleLowerCase()
									.includes(e.target.value.toLowerCase())) ||
							(item.address &&
								item.address
									.toLocaleLowerCase()
									.includes(e.target.value.toLowerCase()))
					)
		);
	};

	useEffect(() => {
		if (getAllAdminLocationQuery.data)
			setItems(getAllAdminLocationQuery?.data?.data);
	}, [getAllAdminLocationQuery.isSuccess]);

	return (
		<div className="flex flex-col gap-y-4">
			{/* The header  */}
			<div className="flex items-start justify-between pr-4">
				<div>
					<p className="text-xs font-normal">
						{user?.business.name ?? "[Location Name]"}
					</p>
					<h1 className="text-[20px] font-semibold text-basecolor">
						Locations
					</h1>
				</div>
				<div className="flex items-center gap-4">
					<div className="flex w-[124px] gap-1 rounded-[99px] p-1 shadow-[0px_0px_5px_rgba(0,0,0,0.15)]">
						<div className="flex h-[48px] w-[48px] items-center justify-center rounded-[99px] bg-primary/10 p-2">
							<PiBuildings className="text-xl text-primary" />
						</div>
						{/* //This should change based on the view  */}
						<div className=" ">
							<p className="pb-0 text-lg font-semibold text-primary/80">
								{totalLocations}
							</p>
							<p className="pt-0 text-[13px] font-[400] text-primary/80">
								Locations
							</p>
						</div>
					</div>
					<div className="flex w-[135px]  gap-1 rounded-[99px] p-1 shadow-[0px_0px_5px_rgba(0,0,0,0.15)]">
						<div className="flex h-[48px] w-[48px] items-center justify-center rounded-[99px] bg-primary/10 p-2">
							<i className="mgc_box_line text-xl before:!text-primary" />
						</div>
						{/* //This should change based on the view  */}
						<div className="">
							<p className="pb-0 text-lg font-semibold text-primary/80">
								{roomNumber}
							</p>
							<p className="pt-0 text-[13px] font-[400] text-primary/80">
								Rooms
							</p>
						</div>
					</div>
				</div>
			</div>
			{/* the search  */}
			<div className="flex h-9 w-full gap-2 px-4">
				<Search
					value={searchQuery}
					onChange={handleSearch}
					placeholder="Search"
					className="h-9 w-[50vw] !bg-white"
				/>
				<CustomShadSelect
					placeholder="Sort by"
					className="h-10 w-fit flex-1"
					value={selectedSortBy}
					options={[
						{ value: "asc", label: "Sort (A-Z)" },
						{ value: "dsc", label: "Sort (Z-A)" },
					]}
					onChange={(value) => {
						handleSortChange(value as "asc" | "dsc");
					}}
				/>
				{user &&
					user.role !== TeamMemberRoles.TeamMember &&
					user.role !== TeamMemberRoles.PartnerManager && (
						<Button
							variant="default"
							className="h-10 px-8"
							onClick={() => navigate("add-location")}
						>
							Add
						</Button>
					)}
			</div>

			{/* Location list  */}
			<div className="px-4">
				<LocationList locationData={items} />
			</div>
		</div>
	);
};

export default Locations;
