import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useGetAllAdminBookingsQuery } from "@/redux/features/api/adminEndpoints";
import { useEffect, useState } from "react";
import EventColumn from "./calendar/EventColumn";
import { useAppSelector } from "@/hooks/hooks";

// interface ManagementEventListProps {
// 	view: any;
// }

const ManagementEventList = () => {
	const { data, isLoading } = useGetAllAdminBookingsQuery({ status: 1 });
	const { searchQuery, selectedLocation, selectedPartner, selectedRooms } =
		useAppSelector((s) => s.events);
	const [eventData, setEventData] = useState(data?.data ?? []);

	useEffect(() => {
		if (data && data.data) {
			let filteredData = data.data;

			// Filter by search query
			if (searchQuery) {
				filteredData = filteredData.filter((item) =>
					item.title.toLowerCase().includes(searchQuery.toLowerCase())
				);
			}

			// Filter by selected location
			if (selectedLocation && selectedLocation !== "all") {
				filteredData = filteredData.filter(
					(item) => item.location.id === +selectedLocation
				);
			}

			// Filter by selected partner
			if (selectedPartner && selectedPartner !== "all") {
				filteredData = filteredData.filter(
					(item) => item.company.id === +selectedPartner
				);
			}

			// Additional filter by selected rooms if needed

			if (selectedRooms && selectedRooms.length) {
				filteredData = filteredData.filter(
					(item) =>
						selectedRooms?.filter(
							(room) => +room.value === item.room_id
						).length
				);
			}

			setEventData(filteredData);
		}
	}, [data, searchQuery, selectedLocation, selectedPartner, selectedRooms]);

	return (
		<Tabs defaultValue="pending" className="w-full space-y-4">
			<TabsList className="relative">
				<TabsTrigger
					value="pending"
					className="flex gap-2 rounded-md data-[state=active]:border data-[state=active]:border-[#BC2E48] data-[state=active]:text-[#BC2E48]"
				>
					<p className="flex items-center gap-1 text-[14px] text-[#BC2E48]">
						Pending{" "}
						<span className="flex h-[12px] w-[16px] items-center justify-center rounded-full bg-[#E33B32] p-2 text-xs text-white">
							{eventData?.filter(
								(item) => item.status === "pending"
							)?.length ?? "0"}
						</span>
					</p>
				</TabsTrigger>
				<TabsTrigger
					value="approved"
					className="rounded-md text-[14px] data-[state=active]:border data-[state=active]:border-[#138576] data-[state=active]:text-[#138576]"
				>
					Approved
				</TabsTrigger>
			</TabsList>
			<TabsContent value="pending">
				<EventColumn
					data={eventData?.filter(
						(item) => item.status === "pending"
					)}
					loading={isLoading}
					statuses={["pending"]}
				/>
			</TabsContent>
			<TabsContent value="approved">
				<EventColumn
					statuses={["onprogress", "approved"]}
					data={eventData?.filter(
						(item) =>
							item.status == "onprogress" ||
							item.status == "approved"
					)}
					loading={isLoading}
				/>
			</TabsContent>
		</Tabs>
	);
};

export default ManagementEventList;
