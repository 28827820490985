import useCustomToast from "@/components/CustomToast";
import { Button } from "@/components/ui/button";
import { DialogClose } from "@/components/ui/dialog";
import { useUpdateBookingStatusMutation } from "@/redux/features/api/partnerEndpoints";
import { LoadingOutlined } from "@ant-design/icons";
import { useEffect } from "react";

interface DeleteAdminProps {
	name: string;
	meetingdata: any;
	handleClose: () => void;
}
const CancelMeeting = ({
	name,
	meetingdata,
	handleClose,
}: DeleteAdminProps) => {
	const [updateBooking, { data, isLoading, error, isError, isSuccess }] =
		useUpdateBookingStatusMutation();

	const customToast = useCustomToast();

	const handleCancel = () => {
		const data = {
			booking_id: meetingdata?.id,
			data: {
				host_id: meetingdata?.host_user_id,
				status: "cancelled",
			},
		};

		customToast("Cancelling meeting...", {
			id: "cancel-meeting",
			type: "loading",
		});
		updateBooking(data);
	};

	useEffect(() => {
		if (isSuccess && data) {
			customToast("Meeting cancelled successfully!", {
				id: "cancel-meeting",
				type: "success",
			});
			handleClose();
		} else if (isError && error) {
			customToast("Error cancelling meeting!", {
				id: "cancel-meeting",
				type: "error",
			});
		}
	}, [isSuccess, isError]);
	return (
		<>
			<div className="flex items-start gap-2">
				<div className="flex flex-[0.1] pt-1">
					<i className="mgc_delete_2_line text-[20px] before:!text-[#C8322B]" />
				</div>
				<div className="flex flex-grow flex-col gap-y-2">
					<h1 className="text-2xl font-semibold text-[#323539]">
						Canel Meeting
					</h1>
					<p>
						Are you sure you want to cancel your reservation at{" "}
						<span className="font-bold text-[#6D748D]">{name}</span>
						?
					</p>
					<div className="flex w-full items-center gap-x-1.5 pt-2">
						<DialogClose className="w-full rounded-md border border-primary bg-transparent bg-white px-4 py-2 text-primary">
							Cancel
						</DialogClose>
						<Button
							className=" w-full rounded-md px-4 py-2 text-white"
							variant="destructive"
							onClick={handleCancel}
						>
							{isLoading ? (
								<LoadingOutlined
									style={{ fontSize: 24 }}
									spin
								/>
							) : (
								"Delete"
							)}
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default CancelMeeting;
