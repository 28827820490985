import PartnerList from "@/components/admin/partners/PartnerList";
import CustomSelect from "@/components/common/CustomSelect";
import Search from "@/components/input/Search";
import Modal from "@/components/modals/Modal";
import AddPartner from "@/components/modals/admin/partners/AddPartner";
import { Button } from "@/components/ui/button";
import { useAppSelector } from "@/hooks/hooks";
import { useGetAllAdminPartnersQuery } from "@/redux/features/api/adminEndpoints";
import { PartnerCompany } from "@/types/partners/member";
import { TeamMemberRoles } from "@/types/team-member";
import { useEffect, useMemo, useState } from "react";
// import { BsDownload, BsFiletypeCsv } from "react-icons/bs";

const Partners = () => {
	const { user } = useAppSelector((state) => state.user);

	let business;
	if ("business" in user!) {
		business = user.business;
	}
	const [showAddMember, setShowAddMember] = useState(false);
	const [partnerData, setPartnerData] = useState<any>([]);
	const { isLoading, data, isSuccess, isError } = useGetAllAdminPartnersQuery(
		{ businessId: business?.id ?? 0 }
	);
	const [searchQuery, setSearchQuery] = useState("");
	const [selectedLocationFilter, setSelectedLocationFilter] =
		useState<any>(null);
	const [selectedBookingCountFilter, setSelectedBookingCountFilter] =
		useState<any>(null);
	const [sortBy, setSortBy] = useState<{
		value: string;
		label: string;
	} | null>({ value: "", label: "None" });

	const handleLocationFilterChange = (selectedOption: any) => {
		setSelectedLocationFilter(selectedOption);
	};

	const handleBookingFilterChange = (selectedOption: any) => {
		setSelectedBookingCountFilter(selectedOption);
	};

	const totalReservations =
		data && data.data
			? data?.data.reduce(
					(total, inital) => total + +inital.statics.total_bookings,
					0
				)
			: 0;

	const filteredPartners: PartnerCompany[] | undefined = useMemo(() => {
		if (!partnerData) return [];
		return partnerData
			.filter((partner: PartnerCompany) => {
				const matchesSearchQuery = partner.name
					.toLowerCase()
					.includes(searchQuery.toLowerCase());
				const matchesLocationFilter =
					selectedLocationFilter === null ||
					selectedLocationFilter.value === "" ||
					partner.address === selectedLocationFilter.value;
				const matchesBookingCountFilter =
					selectedBookingCountFilter === null ||
					selectedBookingCountFilter.value === "" ||
					parseInt(partner.statics.total_bookings, 10) ===
						parseInt(selectedBookingCountFilter.value, 10);
				return (
					matchesSearchQuery &&
					matchesLocationFilter &&
					matchesBookingCountFilter
				);
			})
			.sort((a: PartnerCompany, b: PartnerCompany) => {
				if (sortBy?.value === "name") {
					return a.name.localeCompare(b.name);
				} else if (sortBy?.value === "bookings") {
					return (
						parseInt(b.statics.total_bookings, 10) -
						parseInt(a.statics.total_bookings, 10)
					);
				}
				return 0;
			});
	}, [
		partnerData,
		searchQuery,
		selectedLocationFilter,
		selectedBookingCountFilter,
		sortBy,
	]);

	const locationOptions = useMemo(() => {
		const uniqueLocations = new Set();
		uniqueLocations.add({
			value: "",
			label: "All Locations",
		});

		if (data?.data) {
			data?.data.forEach((partner) => {
				if (partner.address) {
					uniqueLocations.add({
						value: partner.address,
						label: partner.address,
					});
				}
			});
		}

		return Array.from(uniqueLocations);
	}, [data?.data]);

	const bookingCountOptions = useMemo(() => {
		const uniqueBookings = new Set();
		uniqueBookings.add({
			value: "",
			label: "All Reservations",
		});

		if (data?.data) {
			data?.data.forEach((partner) => {
				if (partner.statics.total_bookings) {
					uniqueBookings.add({
						value: partner.statics.total_bookings,
						label: partner.statics.total_bookings,
					});
				}
			});
		}

		return Array.from(uniqueBookings);
	}, [data?.data]);

	useEffect(() => {
		if (isSuccess) {
			setPartnerData(data.data);
		}
	}, [isSuccess, isError, isLoading, data]);
	return (
		<div className="flex flex-col gap-y-4">
			{/* The header  */}
			<div className="flex items-start justify-between pr-4">
				<div>
					<p className="text-xs font-normal">
						{business?.name ?? "[Business Name]"}
					</p>
					<h1 className="text-[20px] font-semibold text-basecolor">
						Partners
					</h1>
				</div>
				<div className="flex items-center gap-4">
					<div className="flex w-fit gap-1 rounded-[99px] p-1 pr-4 shadow-[0px_0px_5px_rgba(0,0,0,0.15)]">
						<div className="flex h-[48px] w-[48px] items-center justify-center rounded-[99px] bg-primary/10 p-2">
							<i className="mgc_user_security_line text-[20px] before:!text-primary" />
						</div>
						<div className=" ">
							<p className="pb-0 text-lg font-semibold text-primary">
								{data?.data.length ?? 0}
							</p>
							<p className="pt-0 text-[13px] font-[400] text-primary">
								Partners
							</p>
						</div>
					</div>
					<div className="flex w-fit gap-1 rounded-[99px] p-1 pr-4 shadow-[0px_0px_5px_rgba(0,0,0,0.15)]">
						<div className="flex h-[48px] w-[48px] items-center justify-center rounded-[99px] bg-primary/10 p-2">
							<i className="mgc_calendar_month_line text-[20px] before:!text-primary" />
						</div>
						<div className=" ">
							<p className="pb-0 text-lg font-semibold text-primary">
								{totalReservations ?? 0}
							</p>
							<p className="pt-0 text-[13px] font-[400] text-primary">
								Reservations
							</p>
						</div>
					</div>
				</div>
			</div>
			{/* the search  */}
			<div className="flex  h-9 w-full gap-2 px-4">
				<div className="flex flex-[0.6]">
					<Search
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
						placeholder="Search"
						className="h-9 w-full !bg-white"
					/>
				</div>
				<div className="flex w-full flex-[0.4] gap-2">
					<div className="flex w-full flex-[0.4] gap-2">
						{/* Location Filter */}
						<CustomSelect
							placeholder="Location"
							className="w-full min-w-[157px]"
							options={locationOptions}
							value={selectedLocationFilter}
							onChange={handleLocationFilterChange}
						/>
						{/* Bookings Number Filter */}
						<CustomSelect
							placeholder="Bookings"
							className="w-full min-w-[157px]"
							options={bookingCountOptions}
							value={selectedBookingCountFilter}
							onChange={handleBookingFilterChange}
						/>

						{/* Sort By Dropdown */}
						<CustomSelect
							placeholder="Sort by"
							className="w-full min-w-[157px]"
							options={[
								{ value: "", label: "None" },
								{ value: "name", label: "Name" },
								{
									value: "reservations",
									label: "Reservations",
								}, // Option to sort by number of bookings
							]}
							value={sortBy}
							onChange={(selectedOption: any) =>
								setSortBy(selectedOption)
							}
						/>
					</div>
					{user?.role !== TeamMemberRoles.TeamMember &&
						user?.role !== TeamMemberRoles.PartnerManager && (
							<Button
								variant="default"
								className=" h-full px-8"
								onClick={() => setShowAddMember(true)}
							>
								Add
							</Button>
						)}
				</div>
			</div>

			{/* Partners list  */}
			<div className="px-4">
				<PartnerList
					isError={isError}
					isSuccess={isSuccess}
					isLoading={isLoading}
					data={filteredPartners}
				/>
			</div>

			{user?.role !== TeamMemberRoles.TeamMember &&
				user?.role !== TeamMemberRoles.PartnerManager && (
					<Modal
						open={showAddMember}
						setOpen={setShowAddMember}
						dialogClassName="max-w-xl p-0"
					>
						<AddPartner
							handleClose={() => setShowAddMember(false)}
						/>
					</Modal>
				)}
		</div>
	);
};

export default Partners;
