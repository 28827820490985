import { cn } from "@/lib/utils";
import { Input } from "../ui/input";
import { PiMagnifyingGlass } from "react-icons/pi";
import React from "react";

interface SearchProps {
	className?: string;
	inputClassName?: string;
	placeholder?: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	value?: string;
	defaultValue?: string;
}
const Search: React.FC<SearchProps> = ({
	className,
	inputClassName,
	placeholder,
	onChange,
	value,
	defaultValue,
}) => {
	return (
		<div className={cn("relative w-full", className)}>
			<Input
				className={cn("pl-8 focus:outline-none", inputClassName)}
				placeholder={placeholder || "Search"}
				{...(value && { value })}
				{...(defaultValue && { defaultValue })}
				onChange={onChange}
			/>
			<span className=" absolute left-2 top-[50%] translate-y-[-40%] text-lg">
				<PiMagnifyingGlass width={"14px"} height={"14px"} />
			</span>
		</div>
	);
};

export default Search;
