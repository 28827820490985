import AmenityHolder from "@/components/common/AmenityHolder";
import CapacityHolder from "@/components/common/CapacityHolder";
import ImageCarousel from "@/components/common/ImageCarousel";
import { DialogClose } from "@radix-ui/react-dialog";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { IoLocation } from "react-icons/io5";

const RoomInfo = ({ room }) => {
	return (
		<div className="flex flex-col gap-y-4 rounded-bl-[30px] rounded-br-[30px] p-4">
			{/* image carousel  */}
			<div>
				<ImageCarousel images={room?.photos ?? "N/A"} />
			</div>
			{/* Description  */}
			<div>
				<div className="flex items-center justify-between">
					<div className="">
						<span className="flex items-center gap-2 text-[10px]">
							<IoIosInformationCircleOutline />
							<span className="text-[14px] font-semibold">
								{room?.name ?? "N/A"}
							</span>
						</span>
						<span className="flex items-center gap-2 text-[10px]">
							<IoLocation />
							<span>{room?.address ?? "N/A"}</span>
						</span>
					</div>
					<CapacityHolder capacity={room?.capacity ?? "N/A"} />
				</div>
				<p className="pt-2 text-[14px] font-light">
					{room?.description ?? "No description Available"}
				</p>
			</div>
			{/* Amenities  */}
			<div className="mb-4 space-y-2">
				<h1 className="text-[14px] font-medium">Amenities</h1>
				<div className="flex items-center gap-2">
					{room?.amenities.length ? (
						room?.amenities?.map((amenity: any, index: number) => (
							<AmenityHolder
								key={index}
								amenity={amenity.name}
								variant="overview"
							/>
						))
					) : (
						<p className=" text-sm font-light text-gray-400">
							No amenities available
						</p>
					)}
				</div>
			</div>

			<div className="flex w-full items-center gap-x-1.5 pt-2">
				<DialogClose className="w-full rounded-md border border-primary bg-transparent px-4 py-2 text-primary">
					Close
				</DialogClose>
			</div>
		</div>
	);
};

export default RoomInfo;
