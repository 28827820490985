import * as z from "zod";

export interface Admin {
	id: number;
	name: string;
	email: string;
	role: string;
	job_title: string | null;
	stripe_customer_id: string | null;
	created_at: string;
	updated_at: string;
	stripe_id: string;
	pm_type: string | null;
	pm_last_four: string | null;
	trial_ends_at: string | null;
	business_id: number;
	is_email_verified: number;
	is_active: number;
	company_id: string | null;
	phone_number: string | null;
}

export interface Workable {
	id: number;
	day_of_week: string;
	workable_id: number;
	workable_type: string;
	is_active: 0 | 1;
	created_at: string;
	updated_at: string;
	time_slots: TimeSlot[];
}

export interface BookingLocation {
	name: string;
	email: string;
	phone_number: string;
	address: string;
	address_2: string;
	country: string;
	state: string;
	city: string;
	zip_code: string;
	time_zone: string;
	business_id: number;
	admin_id: number;
	url_code: string;
	product_id: number;
	updated_at: string;
	created_at: string;
	id: number;
	total_admins: number;
	total_rooms: number;
	total_rooms_used: number;
	total_bookings: number;
	total_cancelled_bookings: number;
	total_rescheduled_bookings: number;
	track_url: string;
	working_hours: Workable[];
	admin: Admin;
	amenities: any[];
	manager?: string;
}

export const LocationSchema = z.object({
	name: z.string().min(1, "Name is required"),
	email: z.string().optional(),
	phone_number: z.string().optional(),
	// .optional(),
	address: z.string().min(10, "Address is required"),
	address_2: z.string().optional(),
	country: z.string(),
	state: z.string(),
	city: z.string(),
	zip_code: z
		.string()
		.min(1, "Zip code is required")
		.max(11, "Max length of zip code is 11"),
	url_code: z.string().optional(),
	time_zone: z.string().min(1, "Time zone is required"),
});

export type Location = z.infer<typeof LocationSchema>;

export interface TimeSlot {
	start_time: string;
	end_time: string;
	is_active?: boolean;
}

export interface DaySchedule {
	is_active: boolean;
	time_slots: TimeSlot[];
}

export interface ScheduleData {
	[key: string]: DaySchedule;
}

export interface SubmittingOperatingHours {
	update_all_rooms: number;
	rooms_to_update: number[];
	data: ScheduleData;
}

{
	/* Analytics */
}

export interface AnalyticsQueryParams {
	start_date: string;
	end_date: string;
	["partner_company_ids[]"]: string;
	["room_ids[]"]: string | undefined;
	["location_ids[]"]: string;
	time_filter_for_trending_by_days: string;
	day_filter_for_trending_by_times: string;
}
