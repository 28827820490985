import { cn } from "@/lib/utils";
import EventCard from "../EventCard";
import Wrapper from "@/container/Wrapper";
import { useState, useEffect } from "react";
import { MdOutlineRuleFolder } from "react-icons/md";

interface EventColumnProps {
	loading: boolean;
	statuses: ("pending" | "approved" | "onprogress")[];
	data: any;
}

const EventColumn: React.FC<EventColumnProps> = ({
	statuses,
	loading,
	data,
}) => {
	const [items, setItems] = useState<any[]>([]);
	// const [selectedItems, setSelectedItems] = useState<any[]>([]);
	// const [selectAll, setSelectAll] = useState<boolean>(false);
	// useEffect(() => {
	// 	if (!loading && data) {
	// 		const filteredItems = data.filter((item) => item.status === status);
	// 		setItems(filteredItems);
	// 	}
	// }, [loading, data, status]);

	useEffect(() => {
		if (!loading && data) {
			const filteredItems = data.filter((item) =>
				statuses.includes(item.status)
			);
			setItems(filteredItems);
		}
	}, [loading, data, statuses]);

	// const toggleSelectAll = () => {
	// 	if (selectAll) {
	// 		setSelectedItems([]);
	// 	} else {
	// 		setSelectedItems(items);
	// 	}
	// 	setSelectAll(!selectAll);
	// };

	return (
		<div className="flex h-[80vh] w-[318px] max-w-full flex-col rounded-lg border-[0.5px] border-[#D9D9D9] bg-[#F7F7F8]">
			<div
				className={cn("rounded-tl-lg rounded-tr-lg bg-[#138576]", {
					"bg-[#C8322B]": statuses.some(
						(status) => status === "pending"
					),
					"bg-[#138576]": statuses.some(
						(status) => status === "approved"
					),
				})}
			>
				<p className="p-3 text-[20px] font-[600] text-white">
					{statuses.some((status) => status === "pending")
						? "Pending Approval"
						: "Approved Reservations"}
				</p>
			</div>
			<div className="h-full overflow-y-scroll p-2">
				<div className="flex items-center gap-2  text-[14px]">
					{/* <input
						id="select-all"
						type="checkbox"
						checked={selectAll}
						onChange={toggleSelectAll}
						className="accent-primary"
					/> */}
					{/* <label
						htmlFor="select-all"
						className={cn("", {
							"text-[#7D7E80]": !selectAll,
						})}
					>
						Select All
					</label> */}
				</div>
				<Wrapper
					fetching={loading}
					className=""
					chidrenClassName="flex h-full flex-col gap-y-2"
					spinClassName="mt-8"
				>
					{!loading &&
						items.map((item) => (
							<EventCard
								key={`${item.id}-${status}`}
								status={item.status}
								data={item}
							/>
						))}
					{!loading && items.length === 0 && (
						<div className="mt-4 flex items-center justify-center gap-2 text-center">
							<MdOutlineRuleFolder />
							<p>No events</p>
						</div>
					)}
				</Wrapper>
			</div>
		</div>
	);
};

export default EventColumn;
