import { cn } from "@/lib/utils";
import { useEffect, useState } from "react";
import BookingDetails from "../partner/room/BookingDetails";
import BookingOverview from "../partner/room/BookingOverview";
import { useAppSelector } from "@/hooks/hooks";
import { useGetRoomTimeslotsQuery } from "@/redux/features/api/partnerEndpoints";
import { Room } from "@/types/admin/booking";
import RoomDetails from "../admin/RoomDetails";
import { TeamMemberRoles } from "@/types/team-member";

const RoomOverview: React.FC<{ room: Room }> = ({ room }) => {
	const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");
	const [overviewData, setOverviewData] = useState<any>();
	const [timeslots, setTimeSlots] = useState<string[]>(room.time_slots ?? []);
	const [activeTab, setActiveTab] = useState<
		"reservation" | "room" | "overview"
	>("room");
	const handleTab = (tab) => {
		setActiveTab(tab);
	};
	// Changed from the initial fetch with duration 
	const { isAllDay } = useAppSelector(
		(state) => state.bookings.booking
	);

	const { date: filterDate } = useAppSelector((state) => state.filters);

	const [defaultDate, setDefaultDate] = useState(filterDate);
	const [defaultDateError, setDefaultDateError] = useState("");

	const isBookable = timeslots?.length > 0;
	const {
		isSuccess,
		data,
		isFetching: getRoomTimeSlotsIsFetching,
		isError: getRoomTimeSlotsIsError,
		refetch: getTimeSlotsRefetch,
	} = useGetRoomTimeslotsQuery({
		room_id: room.id.toString(),
		date: defaultDate,
		duration: 60,
		is_allday: isAllDay ? 1 : 0,
	});

	useEffect(() => {
		if (isSuccess && data.data) {
			setTimeSlots(data.data);
		}
	}, [isSuccess, data]);

	useEffect(() => {
		if (getRoomTimeSlotsIsError) {
			setDefaultDateError(
				new Date(defaultDate).toLocaleDateString("en-CA") +
					" does not have any openings"
			);
			setDefaultDate(filterDate);
		}
	}, [getRoomTimeSlotsIsError]);

	return (
		<div className="flex h-full w-full flex-col pb-4 md:max-w-2xl">
			<div className="mb-4 flex w-full cursor-pointer border-b border-b-[#9CA3AF] text-[15px] font-semibold text-[#858C95] ">
				<button
					onClick={() => setActiveTab("room")}
					className={cn("flex w-full justify-center py-2", {
						"border-b-2 border-b-primary text-primary":
							activeTab === "room",
					})}
				>
					Space
				</button>
				{userRole !== TeamMemberRoles.PartnerAdmin && (
					<button
						disabled={!isBookable}
						onClick={() => setActiveTab("reservation")}
						className={cn("flex w-full justify-center py-2", {
							"border-b-2 border-b-primary text-primary":
								activeTab === "reservation",
						})}
					>
						Reservation
					</button>
				)}
			</div>
			<div className="px-4">
				{activeTab === "room" && (
					<div className="w-full">
						<RoomDetails
							timeslots={
								timeslots.map((time) => time.slice(0, -3)) ?? []
							}
							room={room}
							handleActiveTab={handleTab}
						/>
					</div>
				)}
				{activeTab === "reservation" && (
					<div className="w-full">
						<BookingDetails
							isLoading={getRoomTimeSlotsIsFetching}
							timeslots={
								timeslots.map((time) => time.slice(0, -3)) ?? []
							}
							room={room}
							defaultDate={defaultDate}
							setDefaultDate={setDefaultDate}
							defaultDateError={defaultDateError}
							getTimeSlotsRefetch={getTimeSlotsRefetch}
							setOverviewData={setOverviewData}
							setActiveTab={setActiveTab}
						/>
					</div>
				)}
				{activeTab === "overview" && (
					<BookingOverview data={overviewData?.data} />
				)}
			</div>
		</div>
	);
};

export default RoomOverview;
