import { HiOutlineTrash } from "react-icons/hi2";
import Avatar from "./Avatar";
import { GuestStatus } from "@/types/common/IGuest";
import { Dialog, DialogTrigger, DialogContent } from "../ui/dialog";
import DeleteGuest from "../modals/DeleteGuest";
import { MeetingStatus, Status } from "@/services/mockData";
import { cn } from "@/lib/utils";

interface GuestHolderProps {
	name: string;
	data?: any;
	invitationStatus?: GuestStatus;
	status: MeetingStatus | Status;
}
const GuestHolder = ({ name, status, invitationStatus }: GuestHolderProps) => {
	return (
		<div
			className={cn(
				"flex items-center justify-between rounded-xl  pr-2",
				{
					"group/item cursor-pointer  hover:bg-[#EFEFEF]":
						status === "booked",
				}
			)}
		>
			<Avatar name={name} status={invitationStatus} variant="guest" />
			{/* Refactor later to a modal ???  */}
			<Dialog modal>
				<DialogTrigger asChild>
					<button>
						<HiOutlineTrash
							className={cn(
								"invisible text-sm text-[#8C8C8C] hover:text-black group-hover/item:visible"
							)}
						/>
					</button>
				</DialogTrigger>
				<DialogContent className="flex max-w-[360px] rounded-xl border border-[#E5E5E7] bg-white p-4 shadow-md">
					<DeleteGuest name={name} />
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default GuestHolder;
