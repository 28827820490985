import { BsCloudUpload } from "react-icons/bs";
import { Button } from "../ui/button";

const UploadImage = () => {
	return (
		<div>
			<Button
				variant="outlinecustom"
				className="h-full gap-1 px-2 py-1 text-[12px] text-primary"
			>
				<BsCloudUpload />
				Replace Logo
			</Button>
		</div>
	);
};

export default UploadImage;
