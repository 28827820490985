import GuestMemberList from "@/components/admin/team-members/GuestMemberList";
import TeamMemberList from "@/components/admin/team-members/TeamMemberList";
import { useAppSelector } from "@/hooks/hooks";
import { cn } from "@/lib/utils";
import {
	useGetAllGuestsQuery,
	useGetAllTeamMembersQuery,
} from "@/redux/features/api/teamMemberEndpoints";
import React, { useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // useNavigate from 'react-router-dom'

const TeamMembers: React.FC = () => {
	const user = useAppSelector((state) => state.user.user);
	const location = useLocation();
	const navigate = useNavigate();

	const [step, setStep] = useState<"team-members" | "guest">("team-members");

	const userId = useAppSelector((state) => state!.user!.user!.business_id);
	useGetAllTeamMembersQuery(userId);
	useGetAllGuestsQuery(userId);

	const handleStepChange = (currentStep: "team-members" | "guest") => {
		setStep(currentStep);
		if (currentStep === "guest")
			navigate(`/admin/team-members/${currentStep}`, { replace: true });
		else navigate("/admin/team-members/");
	};

	useLayoutEffect(() => {
		if (location.pathname.includes("/admin/team-members/guest"))
			setStep("guest");
	}, []);

	return (
		<div className="flex h-full flex-1 flex-col gap-y-4 ">
			{/* The header  */}
			<div className="flex items-start justify-between pr-4">
				<div>
					<p className="text-xs font-normal">{user!.name}</p>
					<h1 className="text-[20px] font-semibold text-basecolor">
						Team Members
					</h1>
				</div>
			</div>
			<div className="my-4 flex w-full items-center justify-evenly border-b border-b-[#E5E5E7]">
				{teamMembersStep.map((item, index) => (
					<React.Fragment key={index}>
						<div
							id={`step-${index}`}
							className={cn(
								`step my-[-1.5px] flex flex-1 cursor-pointer items-center justify-center gap-2 border-b-2 border-b-transparent py-[11px] font-semibold text-gray-500 transition-all duration-300`,
								{
									"border-primary text-primary":
										item.currentStep === step,
								}
							)}
							onClick={() => handleStepChange(item.currentStep)}
						>
							{item.label}
						</div>
					</React.Fragment>
				))}
			</div>
			{step === "team-members" && <TeamMemberList />}
			{step === "guest" && <GuestMemberList />}
		</div>
	);
};

export default TeamMembers;

const teamMembersStep: {
	label: string;
	currentStep: "team-members" | "guest";
}[] = [
	{
		label: "Team Members",
		currentStep: "team-members",
	},
	{
		label: "Guest",
		currentStep: "guest",
	},
];
