import { useAppSelector } from "@/hooks/hooks";
import { Navigate, Outlet, useLocation } from "react-router-dom";

interface UserProtectedRouteProps {
	allowedRoles: string[];
	redirect: string;
}

const UserProtectedRoute: React.FC<UserProtectedRouteProps> = ({
	allowedRoles,
	redirect,
}) => {
	const location = useLocation();
	const role = useAppSelector((state) => state?.user?.user?.role ?? "");
	if (allowedRoles.includes(role)) return <Outlet />;

	return <Navigate to={redirect} state={{ from: location }} replace />;
};

export default UserProtectedRoute;
