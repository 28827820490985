import CustomSelect from "@/components/common/CustomSelect";
import Search from "@/components/input/Search";
import { Button } from "@/components/ui/button";
// import { DatePicker } from '@/components/ui/datepicker'
import useCustomToast from "@/components/CustomToast";
import AddRoom from "@/components/modals/admin/rooms/AddRoom";
import Modal from "@/components/modals/Modal";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useAppSelector } from "@/hooks/hooks";
import { useDeleteRoomMutation } from "@/redux/features/apiSlice";
import { TeamMemberRoles } from "@/types/team-member";
import { useEffect, useState } from "react";
import { TbTrash } from "react-icons/tb";
import RoomListCard from "./RoomListCard";
import { Room } from "@/types/admin/booking";

interface RoomListProps {
	rooms: Room[];
}

const RoomList = ({ rooms: allRooms }: RoomListProps) => {
	const location_id = location.pathname.split("/").pop();
	const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");
	const [showAddRoom, setShowAddRoom] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [capacityFilter, setCapacityFilter] = useState<number | null>(null);
	const [sortBy, setSortBy] = useState("");
	const [selectedItems, setSelectedItems] = useState<any>([]);
	const [, setSelectAll] = useState<boolean>(false);
	const [deleteRoom, { isSuccess, isError }] = useDeleteRoomMutation();
	const customToast = useCustomToast();

	const filteredRooms = allRooms.filter((room) => {
		const nameMatch = room.name
			.toLowerCase()
			.includes(searchQuery.toLowerCase());
		const capacityMatch =
			capacityFilter === null ||
			room.capacity >= parseInt(capacityFilter.toString());

		return nameMatch && capacityMatch;
	});

	// console.log(filteredRooms);
	// const toggleSelectAll = () => {
	// 	if (selectAll) {
	// 		setSelectedItems([]);
	// 	} else {
	// 		setSelectedItems(filteredRooms);
	// 	}
	// 	setSelectAll(!selectAll);
	// };

	const toggleSelectItem = (item: any) => {
		if (selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
			setSelectedItems(
				selectedItems.filter(
					(selectedItem) => selectedItem.id !== item.id
				)
			);
		} else {
			// setSelectedItems([...selectedItems, item]); TODO: uncomment this for deleting multiple
			setSelectedItems([item]);
		}
		setSelectAll(false); // Unselect select all if any individual item is selected
	};

	const handleRoomDelete = () => {
		if (
			userRole === TeamMemberRoles.TeamMember ||
			userRole === TeamMemberRoles.PartnerManager
		)
			return customToast("You are not allowed to perform this action", {
				id: "delete-room",
				type: "error",
			});
		if (selectedItems.length === 0) return;
		if (selectedItems.length === 1) {
			deleteRoom({
				id: location_id!,
				room_id: selectedItems[0].id,
			});
		}
	};

	useEffect(() => {
		if (isSuccess) {
			customToast("Space Deleted Successfully 🎉", {
				id: "delete-room",
				type: "error",
			});
		} else if (isError) {
			customToast("Error deleting space 🤕", {
				id: "delete-room",
				type: "error",
			});
		}
	}, [isSuccess, isError]);

	return (
		<div className="flex w-full scroll-m-0 flex-col overflow-y-auto ">
			<div className="flex w-full gap-2 py-2 ">
				<Search
					placeholder="Search"
					className="h-8.5 flex-[0.5]"
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
				/>
				<CustomSelect
					placeholder="Capacity"
					className="w-full flex-[0.5]"
					options={[
						{ value: null, label: "Any" },
						{ value: "20", label: "20" },
						{ value: "40", label: "40" },
					]}
					onChange={(selectedOption: any) =>
						setCapacityFilter(selectedOption.value as number | null)
					}
				/>
				<CustomSelect
					placeholder="Sort by"
					className="w-full flex-[0.25]"
					value={sortBy}
					options={[
						{ value: null, label: "None" },
						{ value: "name", label: "Name" },
						{
							value: "capacity",
							label: "Capacity",
						},
					]}
					onChange={(selectedOption: any) =>
						setSortBy(selectedOption.value as string)
					}
				/>
				{userRole !== TeamMemberRoles.TeamMember &&
					userRole !== TeamMemberRoles.PartnerManager && (
						<Button
							variant="default"
							className=" h-full px-10"
							onClick={() => setShowAddRoom(true)}
						>
							Add
						</Button>
					)}

				{/* <Button
					variant="outlinecustom"
					className="h-full gap-1 px-2 py-1 text-xs font-semibold text-primary"
				>
					<BsFiletypeCsv />
					Import CSV
				</Button> */}
			</div>

			<div className="flex flex-col gap-y-4">
				{userRole !== TeamMemberRoles.TeamMember &&
					userRole !== TeamMemberRoles.PartnerManager && (
						<div className="flex justify-end px-6 py-2">
							{/* <div className="flex items-center gap-2 text-[14px]">
						<input
							id="select-all"
							type="checkbox"
							className="accent-primary"
							onClick={toggleSelectAll}
						/>
						<label
							className={cn("", {
								"text-[#7D7E80]": !selectAll,
							})}
						>
							Select All
						</label>
					</div> */}
							<Button
								variant="outlinecustom"
								disabled={!selectedItems.length}
								className="size-8"
								onClick={handleRoomDelete}
							>
								<TbTrash />
								<i className="mgc_delete_2_line before:!text-primary" />
							</Button>
						</div>
					)}
				<ScrollArea className="h-[50vh]">
					<div className="3xl:grid-cols-5 min-[2000px]:grid-cols-5 grid gap-2 p-3 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
						{filteredRooms?.map((item, index) => (
							<RoomListCard
								room={item}
								key={index}
								onSelect={() => toggleSelectItem(item)}
								checked={selectedItems.some(
									(selectedItem) =>
										selectedItem.id === item.id
								)}
							/>
						))}
					</div>
					{!filteredRooms.length && (
						<p className="w-full text-center">
							No Spaces Available
						</p>
					)}
				</ScrollArea>
			</div>

			{userRole !== TeamMemberRoles.TeamMember &&
				userRole !== TeamMemberRoles.PartnerManager && (
					<Modal
						dialogClassName="!rounded-[30px] max-w-2xl  p-0 bg-white w-full"
						open={showAddRoom}
						setOpen={setShowAddRoom}
					>
						<AddRoom handleClose={() => setShowAddRoom(false)} />
					</Modal>
				)}
		</div>
	);
};

export default RoomList;
