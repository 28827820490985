import { Calendar } from "@/components/ui/calendar";
import { DateFormatter } from "react-day-picker";

interface DateSelectorProps {
	date: Date | undefined;
	setDate: (date) => void;
}
const DateSelector = ({ date, setDate }: DateSelectorProps) => {
	const formatWeekdayName: DateFormatter = (day) => {
		return new Intl.DateTimeFormat("en-US", { weekday: "narrow" }).format(
			day
		);
	};
	return (
		<Calendar
			mode="single"
			selected={date}
			month={date}
			onMonthChange={setDate}
			onSelect={setDate}
			className="rounded-md "
			formatters={{ formatWeekdayName }}
			classNames={{
				nav_button:
					"h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100",
				cell: "border-[0.5px] border-gray-300",
				row: "flex w-full",
				head_cell:
					"text-muted-foreground rounded-md font-normal text-[0.8rem] w-full",
			}}
		/>
	);
};

export default DateSelector;
