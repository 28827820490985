import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import Billing from "./fragments/Billing";
import BusinessDetails from "./fragments/BusinessDetails";
import Subscription from "./fragments/Subscription";
import Theme from "./fragments/Theme";
import UserDetails from "./fragments/UserDetails";
import ChangePassword from "./fragments/ChangePassword";

const Settings: React.FC = () => {
	return (
		<div className="flex flex-col gap-y-8">
			<h1 className="text-[22px] font-semibold text-basecolor">
				Account Settings
			</h1>
			{/*tabs */}
			<Tabs defaultValue="business-details" className="flex h-full">
				<TabsList className="flex h-full flex-col items-start justify-normal gap-y-2 bg-transparent text-left text-basecolor">
					{tabsData.map((tab) => (
						<CustomTabsTrigger
							key={tab.value}
							value={tab.value}
							label={tab.label}
						/>
					))}
				</TabsList>
				<div className="h-[80vh] border-r-2 px-2"></div>
				<TabsContent value="business-details" className="w-full">
					<BusinessDetails />
				</TabsContent>
				<TabsContent value="user-details" className="w-full">
					<UserDetails />
				</TabsContent>
				<TabsContent value="change-password" className="w-full">
					<ChangePassword />
				</TabsContent>
				<TabsContent value="subscriptions" className="w-full">
					<div className="px-7 ">
						<Subscription />
					</div>
				</TabsContent>
				<TabsContent value="billings" className="w-full">
					<div className="px-7 ">
						<Billing />
					</div>
				</TabsContent>
				<TabsContent value="theme" className="w-full">
					<div className="px-7 ">
						<Theme />
					</div>
				</TabsContent>
			</Tabs>
		</div>
	);
};

export default Settings;

const CustomTabsTrigger: React.FC<{ value: string; label: string }> = ({
	value,
	label,
}) => {
	return (
		<TabsTrigger
			className="group flex w-full items-center justify-between transition-all duration-200 ease-in-out data-[state=active]:bg-primary/10 data-[state=active]:text-primary"
			value={value}
		>
			{label}
			<i className="mgc_right_line text-[20px] opacity-0 transition-opacity duration-200 ease-in-out before:!text-primary group-data-[state=active]:opacity-100" />
		</TabsTrigger>
	);
};

const tabsData = [
	{ value: "business-details", label: "Business Details" },
	{ value: "user-details", label: "User Details" },
	{ value: "change-password", label: "Change Password" },
	// { value: "subscriptions", label: "Subscriptions" },
	// { value: "billings", label: "Billings and Payments" },
	{ value: "theme", label: "Theme" },
];
