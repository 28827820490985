import { Button } from "@/components/ui/button";
import React, { Dispatch, ReactNode, SetStateAction } from "react";
import { LoaderButton } from "../Loader/LoaderButton";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "../ui/dialog";

interface DialogContentProps {
	showDeleteModal: boolean;
	setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
	title: string;
	content: ReactNode;
	isLoading: boolean;
	isDisabled: boolean;
	handleDelete: () => void;
}

const DeleteModal: React.FC<DialogContentProps> = ({
	title,
	content,
	isLoading,
	isDisabled,
	handleDelete,
	showDeleteModal,
	setShowDeleteModal,
}) => {
	return (
		<Dialog modal open={showDeleteModal} onOpenChange={setShowDeleteModal}>
			<DialogContent
				className="flex max-w-[380px] gap-0 space-x-2 p-4 sm:rounded-xl"
				hasDeleteIcon={false}
			>
				<i className="mgc_delete_2_line before-text-red-2 my-[5px] text-[20px]" />
				<div>
					<DialogHeader>
						<DialogTitle className="text-[22px] font-semibold capitalize text-[#323539]">
							{title}
						</DialogTitle>
					</DialogHeader>
					{content}
					<DialogFooter className="msm:space-y-2">
						<Button
							type="submit"
							variant="outline"
							className="flex-1 border-[#6B7280] text-[#6B7280] hover:bg-[#6B7280]/30 hover:text-inherit"
							onClick={() => setShowDeleteModal(false)}
							disabled={isLoading}
						>
							Cancel
						</Button>
						<LoaderButton
							variant="destructive"
							type="submit"
							className=" flex-1"
							loading={isLoading}
							disabled={isDisabled}
							onClick={handleDelete}
							loaderSize={24}
						>
							Delete
						</LoaderButton>
					</DialogFooter>
				</div>
				<i
					className="mgc_close_line before-text-gray-5 my-[5px] cursor-pointer text-[16px]"
					onClick={() => {
						if (!isLoading) setShowDeleteModal(false);
					}}
				/>
			</DialogContent>
		</Dialog>
	);
};

export default DeleteModal;
