import Modal from "@/components/modals/Modal";
// import PartnerSummary from "@/components/modals/admin/partners/PartnerSummary";
import EditBusiness from "@/components/modals/partner/EditBusiness";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import Wrapper from "@/container/Wrapper";
import { useGetPartnerAccountDetailsQuery } from "@/redux/features/api/partnerEndpoints";
import LocalStorage from "@/services/storage";
import { useState } from "react";
import CompanySummary from "./CompanySummary";
import { useAppSelector } from "@/hooks/hooks";
import { TeamMemberRoles } from "@/types/team-member";

const CompanyInfoOverview = () => {
	const storage = new LocalStorage();
	const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");
	const partner_id = storage.getItem("MG:USERID") as number;
	const [showSummary, setShowSummary] = useState<boolean>(false);

	const { data: partnerData, isFetching } = useGetPartnerAccountDetailsQuery({
		id: partner_id,
	});
	const [showEditPartner, setShowEditPartner] = useState(false);
	const PartnerData = partnerData?.data;

	return (
		<>
			<TooltipProvider>
				<Wrapper fetching={isFetching} className="">
					{/*Desktop */}
					<div className="flex items-center justify-between rounded-md bg-white p-4 drop-shadow-[0px_1px_5px_rgba(16,24,40,0.15)] mmd:hidden">
						<div className="flex flex-[0.3] items-center gap-2">
							<div className="flex h-[65px] w-[65px] items-center justify-center rounded-md border border-[#E8E8E8]">
								<div className="flex size-9 items-center justify-center rounded-full bg-[#C7C7C7]/60">
									<p className="select-none text-lg font-semibold text-white">
										{PartnerData?.name
											.split(" ")[0][0]
											.toUpperCase()}
									</p>
								</div>
							</div>
							<div>
								<h1>{PartnerData?.name ?? "N/A"}</h1>
								{PartnerData?.address && (
									<p className="flex items-center gap-2 text-[10px] font-light">
										<i className="mgc_location_line before:!text-primary" />{" "}
										{PartnerData?.address ?? "N/A"}
									</p>
								)}
							</div>
						</div>
						<div className="flex flex-[0.7] justify-between">
							<div className="flex items-center gap-4">
								<span className="flex items-center gap-2 rounded-full bg-primary bg-opacity-5 px-3 py-2 text-xs">
									<i className="mgc_calendar_line text-[18px] before:!text-[#858C95]" />
									<span className="text-[#506F8B]">
										Reservation
									</span>
									<p className="text-light text-[14px] font-semibold text-basecolor">
										{PartnerData?.total_bookings ?? "N/A"}
									</p>
								</span>
								<span className="flex items-center gap-2 rounded-full bg-primary bg-opacity-5 px-3 py-2 text-xs">
									<i className="mgc_user_security_line text-[18px] before:!text-[#858C95]" />
									<span className="text-[#506F8B]">
										Admins
									</span>
									<p className="text-light text-[14px] font-semibold text-basecolor">
										{PartnerData?.total_admins ?? "N/A"}
									</p>
								</span>
								<span className="flex items-center gap-2 rounded-full bg-primary bg-opacity-5 px-3 py-2 text-xs">
									<i className="mgc_group_2_line text-[18px] before:!text-[#858C95]" />
									<span className="text-[#506F8B]">
										Guests
									</span>
									<p className="text-light text-[14px] font-semibold text-basecolor">
										{PartnerData?.total_guests ?? "N/A"}
									</p>
								</span>
							</div>

							<div className="flex items-center gap-2 border-l pl-2">
								<span className="flex items-center gap-2 text-sm font-light text-primary">
									<i className="mgc_user_security_line text-[14px] before:!text-primary" />
									<p className="text-[10px] text-[#6B7280]">
										Admin
									</p>
								</span>
								<p className="text-sm font-light text-primary">
									{PartnerData?.super_admin?.name ?? "N/A"}
								</p>
								{PartnerData?.super_admin.email && (
									<Tooltip delayDuration={0}>
										<TooltipTrigger asChild>
											<a
												href={
													PartnerData?.super_admin
														.email
														? `mailto:${PartnerData?.super_admin.email}`
														: ""
												}
												aria-disabled={
													!PartnerData?.super_admin
														.email
												}
												className="flex items-center justify-center rounded-full bg-[#F9F9F9] p-1.5"
											>
												<i className="mgc_mail_line text-[12px] before:!text-primary xs:text-[13px]" />
											</a>
										</TooltipTrigger>
										<TooltipContent
											side="top"
											sideOffset={10}
										>
											<p>
												{PartnerData?.super_admin.email}
											</p>
										</TooltipContent>
									</Tooltip>
								)}
								{PartnerData?.super_admin.phone_number && (
									<Tooltip delayDuration={0}>
										<TooltipTrigger asChild>
											<a
												href={
													PartnerData?.super_admin
														.phone_number
														? `tel:${PartnerData?.super_admin.phone_number}`
														: " "
												}
												className="flex items-center justify-center rounded-full bg-[#F9F9F9] p-1.5"
											>
												<i className="mgc_phone_line text-[12px] before:!text-primary xs:text-[13px]" />
											</a>
										</TooltipTrigger>
										<TooltipContent
											side="top"
											sideOffset={10}
										>
											<p>
												{
													PartnerData?.super_admin
														.phone_number
												}
											</p>
										</TooltipContent>
									</Tooltip>
								)}
							</div>

							<div className="hidden justify-end gap-2 lg:flex lg:flex-[0.2]">
								<Tooltip delayDuration={0}>
									<TooltipTrigger asChild>
										<Button
											variant="outlinecustom"
											className="h-7 px-1.5 py-1.5"
											onClick={() => setShowSummary(true)}
										>
											<i className="mgc_information_line before:!text-primary" />
										</Button>
									</TooltipTrigger>
									<TooltipContent side="top" sideOffset={10}>
										Information
									</TooltipContent>
								</Tooltip>
								{userRole !== TeamMemberRoles.PartnerAdmin && (
									<Tooltip delayDuration={0}>
										<TooltipTrigger asChild>
											<Button
												variant="outlinecustom"
												className="h-7 px-1.5 py-1.5"
												onClick={() =>
													setShowEditPartner(true)
												}
											>
												<i className="mgc_edit_2_line before:!text-primary" />
											</Button>
										</TooltipTrigger>
										<TooltipContent
											side="top"
											sideOffset={10}
										>
											Edit
										</TooltipContent>
									</Tooltip>
								)}
							</div>
						</div>
					</div>
					{/*Desktop */}

					{/*Mobile */}
					<div className="flex flex-1 flex-col space-y-2 rounded-[12px] bg-white px-2.5 py-2.5 drop-shadow-[0px_1px_5px_rgba(16,24,40,0.15)] md:hidden">
						<div className="flex items-center space-x-1.5 px-1.5">
							<div className="size-[65px] rounded-[10px] border border-solid border-[#E8E8E8] " />
							<div className="flex flex-col space-y-1">
								<p className="font-bold leading-[19.22px]">
									{PartnerData?.name ?? "N/A"}
								</p>
								<div className="flex items-center space-x-2">
									<i className="mgc_location_3_line text-[12px]" />
									<small className="text-xxs leading-[10px]">
										{PartnerData?.address ?? "N/A"}
									</small>
								</div>
							</div>
						</div>
						<div className="flex justify-between ">
							<Badge className="flex select-none items-center space-x-[5px] rounded-full bg-primary/5 py-1 shadow-none hover:bg-primary/5">
								<i className="mgc_calendar_line text-[12px] before:!text-[#858C95]" />
								<div className="flex items-center space-x-1">
									<p className="text-[10px] font-normal leading-[12px] text-[#506F8B]">
										Reservations
									</p>
									<p className="text-[10px] font-semibold leading-[12px] text-[#323539]">
										{PartnerData?.total_bookings ?? "N/A"}
									</p>
								</div>
							</Badge>
							<div className="flex justify-between space-x-[5px]">
								<Badge className="flex select-none items-center space-x-[5px] rounded-full bg-primary/5 py-1 shadow-none hover:bg-primary/5">
									<i className="mgc_user_security_line text-[12px] before:!text-[#858C95]" />
									<div className="flex items-center space-x-1">
										<p className="text-[10px] font-normal leading-[12px] text-[#506F8B]">
											Admins
										</p>
										<p className="text-[10px] font-semibold leading-[12px] text-[#323539]">
											{PartnerData?.total_admins ?? "N/A"}
										</p>
									</div>
								</Badge>
								<Badge className="flex select-none items-center space-x-[5px] rounded-full bg-primary/5 py-1 shadow-none hover:bg-primary/5">
									<i className="mgc_group_2_line text-[12px] before:!text-[#858C95]" />
									<div className="flex items-center space-x-1">
										<p className="text-[10px] font-normal leading-[12px] text-[#506F8B]">
											Reservations
										</p>
										<p className="text-[10px] font-semibold leading-[12px] text-[#323539]">
											{PartnerData?.total_guests ?? "N/A"}
										</p>
									</div>
								</Badge>
							</div>
						</div>
						<div className="flex justify-between">
							<div className="flex items-center space-x-2">
								<div className="min-h-[20px] w-0.5 bg-[#D3DAE0]" />
								<div className="flex items-center space-x-[5px]">
									<i className="mgc_user_security_line text-[12px] before:!text-primary" />
									<p className="text-[10px] leading-[10px] text-[#6B7280]">
										Admin
									</p>
									<p className="text-[12px] leading-[14.4px] text-primary">
										{PartnerData?.super_admin?.name ??
											"N/A"}
									</p>

									<a href={`mailto:${PartnerData?.email}`}>
										<Button className="size-[20px] rounded-full bg-[#F9F9F9] px-1 py-[5px] shadow-[0px_0px_2px_0_rgba(0,0,0,0.15)] hover:bg-[#F9F9F9]">
											<i className="mgc_mail_line text-[12px] before:!text-primary" />
										</Button>
									</a>
									<a
										href={`tel:${PartnerData?.phone_number}`}
									>
										<Button className="size-[20px] rounded-full bg-[#F9F9F9] px-1 py-[5px] shadow-[0px_0px_2px_0_rgba(0,0,0,0.15)] hover:bg-[#F9F9F9]">
											<i className="mgc_phone_line text-[12px] before:!text-primary" />
										</Button>
									</a>
								</div>
							</div>

							<Button
								variant="outlinecustom"
								className="h-7 self-end px-1.5 py-1.5"
								onClick={() => setShowSummary(true)}
							>
								<i className="mgc_information_line text-[12px] before:!text-primary" />
							</Button>
						</div>
					</div>
					{/*Mobile */}
				</Wrapper>
			</TooltipProvider>
			<Modal
				dialogClassName="!rounded-[12px] max-w-2xl p-0 bg-white w-full mmd:max-w-[95%]"
				open={showSummary}
				setOpen={setShowSummary}
			>
				<CompanySummary
					data={PartnerData}
					setShowEditPartner={setShowEditPartner}
				/>
			</Modal>
			<Modal
				dialogClassName="max-w-[95%] sm:max-w-xl p-0 w-full"
				open={showEditPartner}
				setOpen={setShowEditPartner}
			>
				<EditBusiness
					close={() => setShowEditPartner(false)}
					partnerData={partnerData?.data}
				/>
			</Modal>
		</>
	);
};

export default CompanyInfoOverview;
