import { CiCircleInfo } from "react-icons/ci";
import GoogleMail from "@/assets/icons/google_mail.svg";
import YahooMail from "@/assets/icons/yahoo_mail.svg";
import MicrosoftMail from "@/assets/icons/outlook_mail.svg";
import CloudMail from "@/assets/icons/icloud.svg";
import { Button } from "@/components/ui/button";

const VerifyEmail = () => {
	return (
		<div className="flex h-screen flex-col items-center justify-center bg-[#F5F5F5]">
			<div className="flex w-fit flex-col gap-y-5 rounded-lg border border-[#E5E5E7] bg-white p-6">
				<CiCircleInfo className="text-3xl text-[#C8322B]" />
				<div className="max-w-[487px]">
					<h1 className="text-[20px] font-medium text-[#323539]">
						Verify your email first
					</h1>
					<p className="text-[#858C95]">
						Click the verification link sent to your provided email
						within the next 24 hours for uninterrupted access.
						Complete the email verification process to access your
						dashboard.{" "}
					</p>
				</div>
				<div className="flex items-center gap-x-4 bg-[#FAFBFC] px-1">
					<p className="text-[#858C95]">Verify with</p>
					<div className="flex gap-2">
						<Button
							className="flex items-center gap-2"
							variant="outline"
						>
							<img src={GoogleMail} />
							Google
						</Button>
						<Button
							className="flex items-center gap-2"
							variant="outline"
						>
							<img src={YahooMail} />
							Yahoo
						</Button>
						<Button
							className="flex items-center gap-2"
							variant="outline"
						>
							<img src={MicrosoftMail} />
							Outlook
						</Button>
						<Button
							className="flex items-center gap-2"
							variant="outline"
						>
							<img src={CloudMail} />
							Icloud
						</Button>
					</div>
				</div>
				<div>
					<p className="text-[15px] font-semibold text-basecolor">
						Verify Later
					</p>
				</div>
			</div>
		</div>
	);
};

export default VerifyEmail;
