import { ADMIN_URI } from "@/services/constants";
import LocalStorage from "@/services/storage";
import { GuestParams, TeamMember, TeamMemberParams } from "@/types/team-member";
import { apiSlice } from "../apiSlice";

const storage = new LocalStorage();

export const teamMemberEndpoints = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAllTeamMembers: builder.query<{ data: TeamMember[] }, number>({
			query: (id) => ({
				url: `/${ADMIN_URI}/${id}/team-members`,
				method: "get",
				headers: {
					Authorization: "Bearer " + storage.getItem("TOKEN_COOKIE"),
				},
			}),
			providesTags: ["TeamMembers"],
		}),
		getTeamMember: builder.query<
			{ data: TeamMember },
			{ id: number; team_member_id: number }
		>({
			query: ({ id, team_member_id }) => ({
				url: `/${ADMIN_URI}/${id}/team-members/${team_member_id}`,
				method: "get",
				headers: {
					Authorization: "Bearer " + storage.getItem("TOKEN_COOKIE"),
				},
			}),

			providesTags: (_, __, { id, team_member_id }) => [
				{
					type: "TeamMembers",
					user_id: id,
					team_member_id,
				},
			],
		}),
		adminAddTeamMember: builder.mutation<
			any,
			{ id: string | number; data: TeamMemberParams | GuestParams }
		>({
			query: ({ id, data }) => ({
				url: `/${ADMIN_URI}/${id}/team-members`,
				method: "post",
				data,
				headers: {
					Authorization: "Bearer " + storage.getItem("TOKEN_COOKIE"),
				},
			}),
			invalidatesTags: ["TeamMembers"],
		}),
		adminUpdateTeamMember: builder.mutation<
			{ data: TeamMember },
			{
				id: string | number;
				data: TeamMemberParams | GuestParams;
				team_member_id: number;
			}
		>({
			query: ({ id, data, team_member_id }) => ({
				url: `/${ADMIN_URI}/${id}/team-members/${team_member_id}`,
				method: "post",
				data,
				headers: {
					Authorization: "Bearer " + storage.getItem("TOKEN_COOKIE"),
				},
			}),
			invalidatesTags: ["TeamMembers"],
		}),
		deleteTeamMember: builder.mutation<
			unknown,
			{ business_id: string | number; team_member_id: number }
		>({
			query: ({ business_id, team_member_id }) => ({
				url: `/${ADMIN_URI}/${business_id}/team-members/${team_member_id}`,
				method: "delete",
				headers: {
					Authorization: "Bearer " + storage.getItem("TOKEN_COOKIE"),
				},
			}),
			invalidatesTags: ["TeamMembers"],
		}),
		getGuest: builder.query<
			{ data: TeamMember },
			{ business_id: number; guest_id: number }
		>({
			query: ({ business_id, guest_id }) => ({
				url: `/${ADMIN_URI}/${business_id}/guests/detail/${guest_id}`,
				method: "get",
				headers: {
					Authorization: "Bearer " + storage.getItem("TOKEN_COOKIE"),
				},
			}),
			providesTags: (_, __, { business_id, guest_id }) => [
				{
					type: "Guests",
					company_id: business_id,
					id: guest_id,
				},
			],
		}),
		getAllGuests: builder.query<{ data: TeamMember[] }, number>({
			query: (id) => ({
				url: `/${ADMIN_URI}/${id}/guests`,
				method: "get",
				headers: {
					Authorization: "Bearer " + storage.getItem("TOKEN_COOKIE"),
				},
			}),
			providesTags: ["Guests"],
		}),
		addGuest: builder.mutation<
			{ data: TeamMember[] },
			{ business_id: number; data: GuestParams }
		>({
			query: ({ business_id, data }) => ({
				url: `/${ADMIN_URI}/${business_id}/guests`,
				method: "post",
				data,
				headers: {
					Authorization: "Bearer " + storage.getItem("TOKEN_COOKIE"),
				},
			}),
			invalidatesTags: ["Guests"],
		}),
		updateGuest: builder.mutation<
			{ data: TeamMember },
			{ business_id: number; guest_id: number; data: GuestParams }
		>({
			query: ({ business_id, guest_id, data }) => ({
				url: `/${ADMIN_URI}/${business_id}/guests/${guest_id}`,
				method: "post",
				data,
				headers: {
					Authorization: "Bearer " + storage.getItem("TOKEN_COOKIE"),
				},
			}),
			invalidatesTags: ["Guests"],
		}),
		deleteGuest: builder.mutation<
			{ data: TeamMember[] },
			{ business_id: number; guest_id: number }
		>({
			query: ({ business_id, guest_id }) => ({
				url: `/${ADMIN_URI}/${business_id}/guests/${guest_id}`,
				method: "delete",
				headers: {
					Authorization: "Bearer " + storage.getItem("TOKEN_COOKIE"),
				},
			}),
			invalidatesTags: ["Guests"],
		}),
	}),
});

export const {
	useGetAllTeamMembersQuery,
	useGetAllGuestsQuery,
	useGetTeamMemberQuery,
	useGetGuestQuery,
	useAddGuestMutation,
	useUpdateGuestMutation,
	useDeleteGuestMutation,
	useAdminUpdateTeamMemberMutation,
	useAdminAddTeamMemberMutation,
	useDeleteTeamMemberMutation,
} = teamMemberEndpoints;
