import DeletePartners from "@/components/modals/admin/DeletePartners";
import Modal from "@/components/modals/Modal";
import { ScrollArea } from "@/components/ui/scroll-area";
import Wrapper from "@/container/Wrapper";
import { useAppSelector } from "@/hooks/hooks";
import {
	useDeleteAdminPartnerMutation,
	useGetAllAdminPartnersQuery,
} from "@/redux/features/api/adminEndpoints";
import { notifyWithIcon } from "@/services/utilities";
import { PartnerCompany } from "@/types/partners/member";
import { TeamMemberRoles } from "@/types/team-member";
import { useEffect, useState } from "react";
import PartnerListCard from "./PartnerListCard";

interface PartnerListInterface {
	isLoading: boolean;
	isError: boolean;
	isSuccess: boolean;
	data: PartnerCompany[] | undefined;
}
const PartnerList: React.FC<PartnerListInterface> = ({
	isLoading,
	isSuccess,
	isError,
	data,
}) => {
	const [showDelete, setShowDelete] = useState(false);
	const [selectedItems, setSelectedItems] = useState<any[]>([]);
	const [, setSelectAll] = useState<boolean>(false);
	const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");
	const businessId =
		useAppSelector((state) => state.user.user?.business_id) ?? 0;
	const getAllAdminPartsQuery = useGetAllAdminPartnersQuery({ businessId });
	const [
		deleteAdminPartner,
		{
			isSuccess: isDeleteSuccess,
			isLoading: isDeleteLoading,
			isError: isDeleteError,
			error,
		},
	] = useDeleteAdminPartnerMutation();
	// const toggleSelectAll = () => {
	// 	if (selectAll) {
	// 		setSelectedItems([]);
	// 	} else {
	// 		setSelectedItems(data);
	// 	}
	// 	setSelectAll(!selectAll);
	// };

	const toggleSelectItem = (item: any) => {
		if (selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
			setSelectedItems(
				selectedItems.filter(
					(selectedItem) => selectedItem.id !== item.id
				)
			);
		} else {
			setSelectedItems([...selectedItems, item]);
		}
		setSelectAll(false);
	};

	const handleDelete = async () => {
		const data = {
			company_ids: selectedItems.map((item) => item.id),
		};
		deleteAdminPartner(data);
	};

	useEffect(() => {
		if (isDeleteSuccess) {
			notifyWithIcon("success", "Partners deleted successfully");
			setShowDelete(false);
			getAllAdminPartsQuery.refetch();
			setSelectedItems([]);
		} else if (isDeleteError && error) {
			notifyWithIcon("error", "Error deleting partners");
			setShowDelete(false);
		}
	}, [isDeleteSuccess, getAllAdminPartsQuery.refetch, isDeleteError, error]); // Include isDeleteSuccess in the dependency array

	return (
		<>
			<Wrapper className="" fetching={isLoading} working={false}>
				{isSuccess && data && (
					<ScrollArea className="h-[70vh]">
						<div className="flex flex-col gap-y-4">
							{/* <div className="flex justify-between px-6">
								<div className="flex items-center gap-2 text-[14px]">
									<input
										id="select-all"
										type="checkbox"
										onClick={toggleSelectAll}
									/>
									<label
										className={cn("", {
											"text-[#7D7E80]": !selectAll,
										})}
									>
										Select All
									</label>
								</div>
								<TooltipProvider>
									<div className="flex items-center gap-1">
										<Tooltip delayDuration={0}>
											<TooltipTrigger asChild>
												<Button
													variant="outlinecustom"
													disabled={!selectAll}
													className="h-7 px-1.5 py-1.5"
												>
													<MdMailOutline />
												</Button>
											</TooltipTrigger>
											<TooltipContent
												side="top"
												sideOffset={10}
											>
												Send Mail
											</TooltipContent>
										</Tooltip>

										<Tooltip delayDuration={0}>
											<TooltipTrigger asChild>
												<Button
													variant="outlinecustom"
													disabled={!selectAll}
													className="h-7 px-1.5 py-1.5"
													onClick={() =>
														setShowDelete(true)
													}
												>
													<TbTrash />
												</Button>
											</TooltipTrigger>
											<TooltipContent
												side="top"
												sideOffset={10}
											>
												Delete
											</TooltipContent>
										</Tooltip>
									</div>
								</TooltipProvider>
							</div> */}
							<div className="flex flex-col gap-2 p-1">
								{data.map((item, index) => (
									<PartnerListCard
										partner={item}
										key={index}
										onSelect={() => toggleSelectItem(item)}
										checked={selectedItems.some(
											(selectedItem) =>
												selectedItem.id === item.id
										)}
									/>
								))}
							</div>
						</div>
					</ScrollArea>
				)}

				{isError && <p>Error Fetching Details</p>}
			</Wrapper>
			{userRole !== TeamMemberRoles.TeamMember && (
				<Modal
					dialogClassName="!rounded-[12px] max-w-[360px] bg-white w-full"
					open={showDelete}
					setOpen={setShowDelete}
				>
					{/* Pass the selectedItems to DeletePartners */}
					<DeletePartners
						onDeleteSuccess={handleDelete}
						isLoading={isDeleteLoading}
					/>
				</Modal>
			)}
		</>
	);
};

export default PartnerList;
