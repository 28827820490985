import BookingHistoryCard from "@/components/admin/booking/history/BookingHistoryCard";
import CustomShadSelect from "@/components/common/CustomShadSelect";
import Search from "@/components/input/Search";
import DefaultTopbar from "@/components/topbar/DefaultTopbar";
import { Button } from "@/components/ui/button";
import { DatePicker } from "@/components/ui/datepicker";
import { ScrollArea } from "@/components/ui/scroll-area";
import Wrapper from "@/container/Wrapper";
import { useGetAllAdminBookingsQuery } from "@/redux/features/api/adminEndpoints";
import { extractUniqueObjectValues } from "@/services/utilities";
import { Booking } from "@/types/admin/booking";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { LuArrowBigUp, LuCalendarDays } from "react-icons/lu";

const History = () => {
	const getAllAdminBookingsQuery = useGetAllAdminBookingsQuery({
		status: -1,
	});
	const [meetingdata, setMeetingData] = useState<Booking[]>([]);
	const [searchQuery, setSearchQuery] = useState("");
	const [roomList, setRoomList] = useState<any>([
		{ value: "all", label: "All Rooms" },
	]);
	const [partnerList, setPartnerList] = useState<any>([
		{ value: "all", label: "All Partners" },
	]);
	const [locationList, setLocationList] = useState<any>([
		{ value: "all", label: "All Locations" },
	]);

	const [selectedLocation, setSelectedLocation] = useState("all");
	const [selectedRoom, setSelectedRoom] = useState("all");
	const [selectedPartner, setSelectedPartner] = useState("all");
	const [selectedDate, setSelectedDate] = useState<Date | undefined>();

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const query = e.target.value;
		setSearchQuery(query);
		setMeetingData((prevData) =>
			e.target.value.length === 0
				? getAllAdminBookingsQuery?.data?.data ?? []
				: prevData.filter(
						(item) =>
							(item.title &&
								item.title
									.toLocaleLowerCase()
									.includes(e.target.value.toLowerCase())) ||
							(item.location.address &&
								item.location.address
									.toLocaleLowerCase()
									.includes(e.target.value.toLowerCase())) ||
							(item.room.name &&
								item.room.name
									.toLocaleLowerCase()
									.includes(e.target.value.toLowerCase()))
					)
		);
	};

	useEffect(() => {
		if (
			getAllAdminBookingsQuery.isSuccess &&
			getAllAdminBookingsQuery.data &&
			getAllAdminBookingsQuery.data.data
		) {
			setLocationList(
				extractUniqueObjectValues(getAllAdminBookingsQuery.data.data, {
					all: "All Locations",
					keyName: "location",
					valueKeyName: "id",
					labelKeyName: "name",
				})
			);
			setRoomList(
				extractUniqueObjectValues(getAllAdminBookingsQuery.data.data, {
					all: "All Rooms",
					keyName: "room",
					valueKeyName: "id",
					labelKeyName: "name",
				})
			);
			setPartnerList(
				extractUniqueObjectValues(getAllAdminBookingsQuery.data.data, {
					all: "All Partners",
					keyName: "company",
					valueKeyName: "id",
					labelKeyName: "name",
				})
			);

			let filteredData = getAllAdminBookingsQuery?.data.data;

			// Filter by search query
			if (searchQuery) {
				filteredData = filteredData.filter((item) =>
					item.title.toLowerCase().includes(searchQuery.toLowerCase())
				);
				// console.log(searchQuery.toLowerCase());
			}

			// Filter by selected location
			if (selectedLocation && selectedLocation !== "all") {
				filteredData = filteredData.filter(
					(item) => item.location.id === +selectedLocation
				);
			}

			// Filter by selected partner
			if (selectedPartner && selectedPartner !== "all") {
				filteredData = filteredData.filter(
					(item) => item.company.id === +selectedPartner
				);
			}

			// Filter by selected room

			if (selectedRoom && selectedRoom !== "all") {
				filteredData = filteredData.filter(
					(item) => item.room.id === +selectedRoom
				);
			}

			if (selectedDate) {
				filteredData = filteredData.filter(
					(item) =>
						new Date(item.start_at).toLocaleDateString("en-CA") ===
						new Date(selectedDate).toLocaleDateString("en-CA")
				);
			}

			setMeetingData(filteredData);
		}
	}, [
		getAllAdminBookingsQuery.isSuccess,
		getAllAdminBookingsQuery.data,
		selectedDate,
		searchQuery,
		selectedLocation,
		selectedPartner,
		selectedRoom,
	]);

	return (
		<div className="flex flex-1 flex-col">
			<div className="flex items-center justify-between pb-4">
				<DefaultTopbar title="Manage Reservations" />
				<div className="flex items-center gap-x-3">
					<div className="flex items-center gap-x-2 text-[16px] font-medium">
						<LuCalendarDays size={18} />
						<p>{DateTime.now().toFormat("dd MMM, yyyy")}</p>
					</div>
					<div>
						<Button size="sm" variant="outline">
							<LuArrowBigUp size={18} />
							<span className="ml-2">Upgrade plan</span>
						</Button>
					</div>
				</div>
			</div>
			<div className="flex w-full items-center gap-x-2 md:gap-x-4">
				{/* //Temporarily hide below out until integration  */}

				<div className="xl:w-full">
					<Search
						value={searchQuery}
						onChange={handleSearch}
						placeholder="Search"
						className="w-full md:w-full"
					/>
				</div>
				<div className="hidden flex-1 flex-col items-center gap-2.5  md:flex md:flex-row">
					<CustomShadSelect
						placeholder="Location"
						className="flex-1 xl:w-[183px]"
						options={locationList}
						onChange={setSelectedLocation}
						value={selectedLocation}
					/>
					<CustomShadSelect
						placeholder="Spaces"
						className="flex-1 xl:w-[183px]"
						options={roomList}
						onChange={setSelectedRoom}
						value={selectedRoom}
					/>
					<CustomShadSelect
						placeholder="Partners"
						className="flex-1 xl:w-[183px]"
						options={partnerList}
						onChange={setSelectedPartner}
						value={selectedPartner}
					/>
				</div>
				<DatePicker
					title="Select Date"
					className="w-fit font-medium !text-[#858C95] md:w-[183px]"
					date={selectedDate}
					setDate={(date) => {
						if (date !== selectedDate) setSelectedDate(date);
						else setSelectedDate(undefined);
					}}
					disablePastDates={false}
					allowDateClearOnCurrentDay
				/>
				{/* <div className="rounded-md p-2 bg-white shadow-sm border md:hidden block">
					<FilterIcon color="#6B7280" size="20px" />
				</div> */}
			</div>

			{/* Meeting History  */}
			<Wrapper fetching={getAllAdminBookingsQuery.isLoading} className="">
				{meetingdata && meetingdata?.length ? (
					<ScrollArea className="mt-4 h-[80svh] bg-transparent md:h-[70vh] md:bg-white ">
						{meetingdata.map((meeting, index) => (
							<div
								className="px-1 py-2 md:px-4"
								key={`${meeting?.title} - ${index}`}
							>
								<BookingHistoryCard
									meetingdata={meeting}
									key={index}
								/>
							</div>
						))}
					</ScrollArea>
				) : (
					<p className="mt-6">No Reservation made</p>
				)}
			</Wrapper>
		</div>
	);
};

export default History;
