import Loader from "@/components/Loader/Loader";
import MeetingCard from "@/components/partner/MeetingCard";
import MeetingHeader from "@/components/partner/MeetingHeader";
import DefaultTopbar from "@/components/topbar/DefaultTopbar";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useAppSelector } from "@/hooks/hooks";
import { cn } from "@/lib/utils";
import { useGetAllBookingsQuery } from "@/redux/features/api/partnerEndpoints";
import { Booking } from "@/types/admin/booking";
import { useEffect, useMemo, useState } from "react";
import { BsFolder } from "react-icons/bs";

const Meetings: React.FC = () => {
	const [activeTab, setActiveTab] = useState<"past" | "upcoming">("upcoming");
	const [meetingData, setMeetingData] = useState<Booking[]>([]);
	const { location, room, status, bookedBy, searchQuery } = useAppSelector(
		(state) => state.meetingFilter
	);
	const { data, isFetching, isSuccess, isError } = useGetAllBookingsQuery();
	useEffect(() => {
		setMeetingData(data?.data ?? []);
	}, [isSuccess, isFetching, data]);

	const [newMeetings, setNewMeetings] = useState<Booking[]>([]);

	const filteredMeetings = useMemo(() => {
		if (!data) return [];

		const newData = data?.data?.filter((meeting) => {
			const isLocationMatch =
				location === "all" || meeting.room.location.name === location;
			const isRoomMatch = room === "all" || meeting.room.name === room;
			const isStatusMatch = status === "all" || meeting.status === status;
			const isBookedByMatch =
				bookedBy === "all" || meeting.host.name === bookedBy;
			const isSearchQueryMatch = searchQuery
				.toLowerCase()
				.trim()
				.split(" ")
				.some(
					(term) =>
						meeting.title.toLowerCase().trim().includes(term) ||
						meeting.room.name.toLowerCase().trim().includes(term)
				);

			if (activeTab === "past") {
				return (
					(searchQuery.length ? isSearchQueryMatch : true) &&
					isLocationMatch &&
					isRoomMatch &&
					isStatusMatch &&
					isBookedByMatch &&
					(meeting.status === "done" ||
						meeting.status === "cancelled")
				);
			} else {
				return (
					(searchQuery.length ? isSearchQueryMatch : true) &&
					isLocationMatch &&
					isRoomMatch &&
					isStatusMatch &&
					isBookedByMatch &&
					meeting.status !== "done" &&
					meeting.status !== "cancelled"
				);
			}
		});
		setNewMeetings(newData);
		return newData;
	}, [data, location, room, status, bookedBy, searchQuery, activeTab]);

	const renderMeetings = () => {
		if (isError) return <p>Error Fetching Details</p>;

		if (newMeetings?.length === 0) {
			return (
				<div className="flex items-center justify-center gap-4 pt-6">
					<BsFolder />
					<p>No Reservation Data Available</p>
				</div>
			);
		}

		return newMeetings?.map((meeting, index) => (
			<div
				className="px-1 py-2 md:px-4"
				key={`${meeting.title} - ${index}`}
			>
				<MeetingCard meetingdata={meeting} />
			</div>
		));
	};

	return (
		<div className="flex h-full flex-1 flex-col overflow-hidden p-4 md:p-8">
			<DefaultTopbar title="All Reservations" />
			<div className="flex h-full flex-1 flex-col overflow-hidden md:gap-y-4">
				<MeetingHeader
					initialData={meetingData}
					filteredMeetings={filteredMeetings}
					setData={setNewMeetings}
				/>
				<>
					<div className="mb-4 mt-2 flex w-full cursor-pointer border-b border-b-[#9CA3AF] text-[15px] font-semibold text-[#858C95] md:mt-0">
						<div
							onClick={() => setActiveTab("past")}
							className={cn("flex w-full justify-center py-2", {
								"border-b-2 border-b-primary text-primary":
									activeTab === "past",
							})}
						>
							<p>Past</p>
						</div>
						<div
							onClick={() => setActiveTab("upcoming")}
							className={cn("flex w-full justify-center py-2", {
								"border-b-2 border-b-primary text-primary":
									activeTab === "upcoming",
							})}
						>
							<p>Upcoming</p>
						</div>
					</div>
					{isFetching ? (
						<Loader size={24} />
					) : (
						<ScrollArea className="flex-1">
							{renderMeetings()}
						</ScrollArea>
					)}
				</>
			</div>
		</div>
	);
};

export default Meetings;
