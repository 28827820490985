import CalendarIcon from "@/assets/icons/full_calendar.svg";
import { formatDateString, formatTime } from "@/services/utilities";
import { Booking } from "@/types/admin/booking";
import React from "react";
import { LuCalendarCheck } from "react-icons/lu";
import { PiBuildings } from "react-icons/pi";

const BookingOverviewContent: React.FC<{ data?: Booking }> = ({ data }) => {
	return (
		<>
			<section className="flex items-start justify-between">
				<div>
					<h1 className="text-xl font-semibold text-[#323539] md:text-3xl">
						Reservation Successful!
					</h1>
					<p className="font-normal">
						Check your <span className="text-primary">email</span>{" "}
						for confirmation.
					</p>
				</div>
				<div>
					<img src={CalendarIcon} alt="Calendar" />
				</div>
			</section>
			{/* //details  */}
			<section className="flex flex-wrap-reverse gap-5">
				<div className="flex-[0.4]">
					<div className="flex flex-col gap-2 border-t border-t-[#BFBFBF] pt-2">
						<p className="flex items-center gap-2">
							<span className="text-primary">
								<PiBuildings />
							</span>
							{data?.room?.name || "Space Name"}
						</p>

						<p className="flex items-center gap-2">
							<i className="mgc_location_line text-[16px] before:!text-primary" />
							{data?.room?.address || "Space Address"}
						</p>
						<p className="flex items-center gap-2">
							<span className="text-primary">
								<LuCalendarCheck />
							</span>
							{formatDateString(data?.start_at ?? "") || "Date"}
						</p>
						<p className="flex items-center gap-2">
							<i className="mgc_time_line text-[16px] before:!text-primary" />
							{data?.start_time
								? formatTime(data?.start_time)
								: "Date"}{" "}
							-{" "}
							{formatTime(data?.end_time?.slice(0, -3) ?? "") ||
								"Date"}
						</p>
					</div>
				</div>
				<div className="flex flex-[0.6]">
					{data?.room?.photos && data?.room?.photos.length ? (
						<img
							src={data?.room?.photos[0].image_url}
							className="h-full max-h-[171px] w-full max-w-[352px] rounded-2xl"
							alt="Space picture"
						/>
					) : (
						<div className="flex max-h-[171px] w-full max-w-[352px] flex-col justify-center self-stretch rounded-2xl bg-[#DCDCDC] px-4 md:h-[192px]">
							<div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-[#C7C7C7] ">
								<p className="select-none text-lg font-semibold text-white">
									{data?.room?.name
										.split(" ")[0][0]
										.toUpperCase()}
								</p>
							</div>
						</div>
					)}
				</div>
			</section>
		</>
	);
};

export default BookingOverviewContent;
