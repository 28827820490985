import FormInput from "@/components/input/FormInput";
import { Button } from "@/components/ui/button";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { IoClose } from "react-icons/io5";
import { LuPhone } from "react-icons/lu";
import { MdOutlineMail, MdOutlineModeEdit } from "react-icons/md";
import { TbTrash } from "react-icons/tb";
// import Avatar from "@/components/common/Avatar";
// import { Dialog, DialogContent } from "@/components/ui/dialog";
// import {
// 	Select,
// 	SelectContent,
// 	SelectGroup,
// 	SelectItem,
// 	SelectTrigger,
// 	SelectValue,
// } from "@/components/ui/select";
// import { useAppSelector } from "@/hooks/hooks";
import { cn } from "@/lib/utils";
import { getInitials } from "@/services/utilities";
// import { TeamMemberRoles } from "@/types/team-member";
import { IUser } from "@/types/user";
import { useState } from "react";
// import { FaRegUser } from "react-icons/fa6";
import { IoIosSave } from "react-icons/io";
import { RiUserStarFill } from "react-icons/ri";
import EditPartnerMember from "@/components/modals/admin/EditPartnerMember";
interface UserListCardProps {
	type: "super_admin" | "admin";
	onSelect: () => void;
	checked: boolean;
	userDetails: IUser;
}

const RoleSelection: Record<string, string> = {
	PARTNER_ADMIN: "Admin",
	admin: "Admin",
	PARTENER_SUPER_ADMIN: "Super Admin",
	guest: "Guest",
};
const UserListCard: React.FC<UserListCardProps> = ({
	type,
	userDetails,
	onSelect,
	checked,
}) => {
	// const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");
	// const user = useAppSelector((state) => state.user.user) as IUserPartner;
	// const isPartnerOfCompany =
	// 	user.team_member_info &&
	// 	user.team_member_info.partner_companies.find(
	// 		(item) => item.id === userDetails.company_id
	// 	);
	// const [selectedRole, setSelectedRole] = useState(
	// 	userDetails.role === "PARTENER_SUPER_ADMIN" ? "Super Admin" : "admin"
	// );
	const [editDetails, setEditDetails] = useState(false);
	const [showMemberDetails, setShowMemberDetails] = useState(false);
	const handleEditMember = () => {
		setEditDetails(!editDetails);
	};

	{
		type;
	}
	return (
		<TooltipProvider>
			<div className="flex items-center justify-between rounded-[12px] bg-white px-4  py-3 shadow-[0px_0px_5px_rgba(0,0,0,0.25)]">
				<div className="flex gap-4 lg:flex-[0.15]">
					<input
						type="checkbox"
						checked={checked}
						onChange={onSelect}
					/>
					<div className="flex items-center gap-2">
						<span
							className={cn(
								"relative flex h-[16px] w-[16px] items-center justify-center rounded-full border border-[#B7B7B7] bg-[#F8F8F8] p-2.5 text-[#525252] md:text-[12px]"
							)}
						>
							{getInitials(userDetails?.name ?? "N/A")}
						</span>

						<FormInput
							inputType="text"
							value={userDetails?.name ?? "N/A"}
							className={cn(" h-7 w-fit text-[14px]", {
								"border-none pl-0 text-base  focus:border-none":
									!editDetails,
							})}
							disabled={!editDetails}
						/>
					</div>
				</div>

				<div className="hidden items-center justify-evenly gap-x-4 lg:flex lg:flex-[0.65]">
					<div className="relative flex  w-full items-center gap-1">
						<MdOutlineMail className="absolute left-2" />
						<FormInput
							inputType="text"
							value={userDetails?.email ?? "N/A"}
							className={cn(" h-7 w-fit pl-8 text-[14px]", {
								"border-none focus:border-none": !editDetails,
							})}
							disabled={!editDetails}
						/>
					</div>
					<div className="relative flex w-full items-center  gap-1">
						<LuPhone className="absolute left-2" />
						<FormInput
							inputType="text"
							value={userDetails?.phone_number ?? "N/A"}
							className={cn(" h-7 pl-8 text-[14px]", {
								"border-none focus:border-none": !editDetails,
							})}
							disabled={!editDetails}
						/>
					</div>
					<div className="relative flex  w-full items-center  gap-1">
						<FormInput
							inputType="text"
							value={RoleSelection[userDetails?.role] ?? "N/A"}
							className={cn(" h-7 pl-8 text-[14px]", {
								"border-none focus:border-none": !editDetails,
							})}
							disabled={!editDetails}
						/>
						{/* <Select disabled={!editDetails}>
							<SelectTrigger className=" w-[120px]  text-sm focus:ring-0 disabled:border-none">
								<SelectValue placeholder={selectedRole}>
									{selectedRole}
								</SelectValue>
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									<SelectItem
										value="admin"
										onClick={() => setSelectedRole("admin")}
									>
										<div className="flex items-center gap-2">
											<FaRegUser />
											Admin
										</div>
									</SelectItem>
									<SelectItem
										value="super_admin"
										onClick={() =>
											setSelectedRole("super_admin")
										}
									>
										<div className="flex items-center gap-2">
											<FaRegUser />
											Super Admin
										</div>
									</SelectItem>
								</SelectGroup>
							</SelectContent>
						</Select> */}
					</div>
				</div>
				<div className="hidden justify-end gap-2 lg:flex lg:flex-[0.2]">
					{editDetails ? (
						<Tooltip delayDuration={0}>
							<TooltipTrigger asChild>
								<Button
									className="h-7 bg-[#E0E0E0] px-1.5 py-1.5"
									onClick={() => setEditDetails(false)}
								>
									<IoClose />
								</Button>
							</TooltipTrigger>
							<TooltipContent side="top" sideOffset={10}>
								Cancel
							</TooltipContent>
						</Tooltip>
					) : null}

					<Tooltip delayDuration={0}>
						<TooltipTrigger asChild>
							<Button
								variant="outlinecustom"
								className="hidden h-7 px-1.5 py-1.5"
								onClick={handleEditMember}
								disabled={true}
							>
								{editDetails ? (
									<IoIosSave />
								) : (
									<MdOutlineModeEdit />
								)}
							</Button>
						</TooltipTrigger>
						<TooltipContent side="top" sideOffset={10}>
							{editDetails ? "Save" : "Edit"}
						</TooltipContent>
					</Tooltip>

					{type === "admin" && (
						<Tooltip delayDuration={0}>
							<TooltipTrigger asChild>
								<Button
									variant="outlinecustom"
									className="hidden h-7 px-1.5 py-1.5"
									disabled={true}
								>
									<RiUserStarFill />
								</Button>
							</TooltipTrigger>
							<TooltipContent side="top" sideOffset={10}>
								Make Super Admin
							</TooltipContent>
						</Tooltip>
					)}

					<Tooltip delayDuration={0}>
						<TooltipTrigger asChild>
							<Button
								variant="outlinecustom"
								className="hidden h-7 px-1.5 py-1.5"
								disabled={true}
							>
								<TbTrash />
							</Button>
						</TooltipTrigger>
						<TooltipContent side="top" sideOffset={10}>
							Delete
						</TooltipContent>
					</Tooltip>
				</div>
				<EditPartnerMember
					member={userDetails}
					showMemberDetails={showMemberDetails}
					setShowMemberDetails={setShowMemberDetails}
				/>
			</div>
		</TooltipProvider>
	);
};

export default UserListCard;
