import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Views } from "react-big-calendar";
import { Calendar } from "./BigCalendar";
import { useAppDispatch, useAppSelector } from "@/hooks/hooks";
import useScreenDimension from "@/hooks/useScreenDimension";
import { setDate as eventSliceSetDate } from "@/redux/slices/eventSlice";
import { Booking } from "@/types/admin/booking";
import DateSelector from "../../DateSelector";
import ManagementToolbar from "../ManagementToolbar";
import CalendarHeader from "./CalendarHeader";
import WeekView from "./CustomWeekView";
import EventBlock from "./EventBlock";

type Keys = keyof typeof Views;

interface BookingCalendarProps {
	events: Booking[];
	setEvents: any;
	handleRemoveSchedule: (selectedStations, station) => void;
}
const BookingCalendar = ({ events, setEvents }: BookingCalendarProps) => {
	const dispatch = useAppDispatch();
	const [startTimeShown, setStartTimeShown] = useState(
		moment().set({ hour: 11, minute: 0, second: 0, millisecond: 0 }).toDate()
	);

	const setDate = (newDate: Date | string) => {
		dispatch(eventSliceSetDate(new Date(newDate).toISOString()));
		setStartTimeShown(
			moment(new Date(newDate).toISOString())
				.set({ hour: 11, minute: 0, second: 0, millisecond: 0 })
				.toDate()
		);
	};
	
	const { date } = useAppSelector((s) => s.events);
	const [view, setView] = useState<(typeof Views)[Keys]>(Views.DAY);
	const onNavigate = useCallback((newDate) => setDate(newDate), [setDate]);
	const onView = useCallback(
		(newView) => {
			setView(newView);
		},
		[setView]
	);
	const { width } = useScreenDimension();
	const formats = useMemo(
		() => ({
			timeGutterFormat: (date, culture, localizer) =>
				localizer.format(date, "hh A", culture),
		}),
		[]
	);
	const [filteredEvents, setFilteredEvents] = useState<any>([]);

	
	const mainevents = useMemo(() => {
		if (events?.length === 0) {
			return [];
		}
		return filteredEvents?.map((event) => ({
			...event,
			start: moment(event.start_at, "YYYY-MM-DD HH:mm:ss").toDate(),
			resourceId: event.room_id,
			end: moment(event.end_at, "YYYY-MM-DD HH:mm:ss").toDate(),
		}));
	}, [events, filteredEvents]);

	useEffect(() => {
		if (events?.length > 0) {
			setFilteredEvents(events);
		}
	}, [events]);
	
	useEffect(() => {
		setStartTimeShown(
			moment(date)
				.set({ hour: 11, minute: 0, second: 0, millisecond: 0 })
				.toDate()
		);
	}, [date]);

	// const onView = useCallback(
	// 	(newView) => {
	// 		onViewChange?.(newView); // Call the onViewChange prop with the new view
	// 	},
	// 	[onViewChange]
	// );
	// console.log(mainevents);
	// const resourceMap = useMemo(() => {
	// 	const uniqueResources = new Map<
	// 		string,
	// 		{
	// 			resourceId: number;
	// 			resourceTitle: string;
	// 			capacity: number;
	// 			status: string;
	// 		}
	// 	>();
	// 	events?.forEach((event) => {
	// 		if (!uniqueResources.has(event.room_id as any)) {
	// 			uniqueResources.set(event.room_id as any, {
	// 				resourceId: event.room_id,
	// 				resourceTitle: event.room.name,
	// 				capacity: event.room.capacity,
	// 				status: event.status,
	// 			});
	// 		}
	// 	});
	// 	return Array.from(uniqueResources.values());
	// }, [events]);

	const { components } = useMemo(
		() => ({
			components: {
				day: {
					event: (event) => (
						<EventBlock
							key={event}
							booking={event.event}
							viewType="Day"
						/>
					),
				},
				week: {
					event: (event) => (
						<EventBlock
							key={event}
							booking={event.event}
							viewType="Week"
						/>
					),
				},
				month: {
					event: (event) => (
						<EventBlock
							key={event}
							booking={event.event}
							viewType="Month"
						/>
					),
				},
			},
		}),
		[]
	);

	const views = useMemo(() => {
		if (view === Views.DAY) {
			return {
				month: true,
				week: WeekView,
				day: true,
				resource: true, // Only include resource view on day view
			};
		} else {
			return {
				month: true,
				week: WeekView,
				day: true,
				resource: false,
			};
		}
	}, [view]);

	return (
		<div className="relative h-full w-full space-y-4 pr-4">
			<ManagementToolbar
				view={view}
				date={new Date(date)}
				setDate={setDate}
				setView={setView}
			/>
			<div className="h-full">
				<CalendarHeader
					view={view}
					events={events}
					filteredEvents={filteredEvents}
					setFilteredEvents={setFilteredEvents}
					setEvents={setEvents}
					setDate={setDate}
					date={new Date(date)}
				/>
				<div className="flex h-full w-full ">
					<Calendar
						className="w-full"
						components={components}
						date={date}
						view={view}
						views={views}
						onView={onView}
						onNavigate={onNavigate}
						formats={formats}
						events={mainevents}
						scrollToTime={startTimeShown}
						startAccessor="start"
						endAccessor="end"
						showAllEvents={true}
						defaultDate={date}
						// dayLayoutAlgorithm="no-overlap"
						toolbar={false}
					/>
					{width > 1025
						? view === "day" && (
								<div className="border border-l-0 p-4">
									<DateSelector
										date={new Date(date)}
										setDate={(date: Date) => setDate(date)}
									/>
								</div>
							)
						: null}
				</div>
			</div>
		</div>
	);
};

export default BookingCalendar;
