import { cn } from "@/lib/utils";
// import { BOOKING_DATA } from "@/services/mockData";
import { formatDateString } from "@/services/utilities";
import { ChevronRight } from "lucide-react";
import { IoMdTime } from "react-icons/io";
import { IoPeopleOutline } from "react-icons/io5";
import { LuCalendar, LuContact, LuUsers2 } from "react-icons/lu";
import { useNavigate } from "react-router";

interface BookingListCardProps {
	bookingdata: any;
	onSelect: () => void;
	checked: boolean;
}

const BookingListCard: React.FC<BookingListCardProps> = ({
	bookingdata,
	onSelect,
	checked,
}) => {
	// const { width } = useDimension();
	const navigate = useNavigate();
	// console.log(bookingdata);
	return (
		<>
			<div
				className="flex max-h-[131px] cursor-pointer flex-col items-center justify-between rounded-[12px] bg-white p-2 drop-shadow-[0_1px_5px_rgba(16,24,40,0.15)] md:flex-row"
				onClick={() =>
					navigate(`booking/${bookingdata?.id}`, {
						state: { meeting: bookingdata?.status },
					})
				}
			>
				<div className="flex flex-grow gap-3.5 rounded-xl xl:flex-[0.4]">
					<div className="relative flex-[0.4]">
						<input
							className="absolute left-2 top-2"
							type="checkbox"
							checked={checked}
							onChange={onSelect}
						/>
						{bookingdata?.room?.photos[0]?.image_url ? (
							<img
								src={bookingdata?.room?.photos[0]?.image_url}
								alt=""
								className=" h-[72px] w-[150px] rounded-[12px] object-cover md:h-[123px] md:w-[179px]"
							/>
						) : (
							<div className="flex h-[72px] w-full flex-col justify-center rounded-md bg-[#DCDCDC]  px-4 md:h-[119px]">
								<div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-[#C7C7C7] ">
									<p className="select-none text-lg text-white">
										{
											bookingdata.room?.name.split(
												" "
											)[0][0]
										}
									</p>
								</div>
							</div>
						)}
					</div>
					<div className="flex flex-[0.6] flex-col justify-between gap-y-2">
						<div className="space-y-1">
							<h2 className="  truncate font-bold">
								{bookingdata?.room?.name ?? "N/A"}
							</h2>
							<div className="flex items-center space-x-1.5 text-[10px]">
								<i className="mgc_location_3_line text-[12px] before:!text-[#323539]" />
								<span>
									{bookingdata?.room?.address ?? "N/A"}
									{/* Building {bookingdata.building} | Floor{" "}
									{bookingdata.floor}{" "} */}
								</span>
							</div>
						</div>

						<div className="hidden w-fit items-center gap-2 rounded-full  bg-[#E3F2FF] px-2 py-1 md:flex">
							<div className="flex items-center gap-1 ">
								<IoPeopleOutline
									width={"9px"}
									height={"9px"}
									className=""
								/>
								<p className="text-[10px] font-light">
									Capacity
								</p>
							</div>
							<p className="text-[10px] font-bold">
								{bookingdata?.room?.capacity ?? "N/A"}
							</p>
						</div>
					</div>
				</div>
				<div className="flex flex-grow flex-col justify-between gap-1.5 border-l-2 py-1 pl-2 xl:flex-[0.2] ">
					<span className="flex items-center gap-2 ">
						<LuCalendar className="text-[#BFBFBF]" />
						<p className="text-[10px]">
							{bookingdata?.start_at
								? formatDateString(
										bookingdata?.start_at.split(" ")[0]
									)
								: "N/A"}
							{/* {bookingdata.appointment?.date} */}
						</p>
					</span>
					<span className="flex items-center gap-2 ">
						<IoMdTime className="text-[#BFBFBF]" />
						<p className="text-[10px]">
							{bookingdata?.start_time?.slice(0, -3) ?? "N/A"} -{" "}
							{bookingdata?.end_time?.slice(0, -3) ?? "N/A"}{" "}
							{/* {bookingdata.appointment?.time} */}
						</p>
					</span>
					<span className="flex items-center gap-2 ">
						<LuContact className="text-[#BFBFBF]" />
						<p className="text-[10px]">
							{bookingdata?.host?.name ?? "N/A"}
						</p>
					</span>
					<span className="flex items-center gap-2 ">
						<LuUsers2 className="text-[#BFBFBF]" />
						<p className="text-[10px] text-[#6B7280]">
							{bookingdata?.no_of_guests ?? "N/A"} Guests
						</p>
					</span>
				</div>

				<div className="flex h-max flex-grow flex-col  justify-between  xl:flex-[0.3] ">
					<p className="line-clamp-1 border-l-2 pl-2  font-semibold text-basecolor">
						{bookingdata?.title}
					</p>
					<div className="flex w-full flex-col items-end gap-y-4">
						<button
							className="text-xl text-[#858C95]"
							onClick={() =>
								navigate(`booking/${bookingdata?.id}`, {
									state: { meeting: bookingdata?.status },
								})
							}
						>
							<ChevronRight />
						</button>
						<div className="flex gap-x-2.5">
							{bookingdata?.status && (
								<span
									className={cn(
										"flex w-fit items-center rounded-lg bg-[#F6E2E1] px-5 py-1 text-[14px]  font-medium capitalize",
										{
											"bg-[#F7EDD4] text-[#896200]":
												bookingdata?.status ===
												"upcoming",
											"bg-[#F6E2E1] text-[#B7241D]":
												bookingdata?.status ===
												"cancelled",
											"bg-[#F5F1F6] text-[#69216F]":
												bookingdata.status ===
												"onprogress",
											"bg-[#138576]/10 text-[#138576]":
												bookingdata.status ===
												"approved",
											"bg-[#636363]/10 text-[#636363]":
												bookingdata.status === "done",
										}
									)}
								>
									{bookingdata?.status === "onprogress"
										? "In Progress"
										: bookingdata?.status}
								</span>
							)}
						</div>
					</div>
				</div>

				{/* <div
					className={cn(
						"flex flex-[0.1] flex-col justify-center items-end py-1 gap-1.5 ml-auto",
						{
							hidden: meetingdata.status === "completed",
						},
					)}
				>
					
				</div> */}
			</div>
		</>
	);
};

export default BookingListCard;
