import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

export interface Filters {
	name: string;
	date: string;
	duration: string;
	isAllDay: boolean;
	capacity: number | null;
	amenities: any[] | null;
	start_time: string;
	end_time: string;
	location_id: string | number;
	// Add any other filter properties you need
}

const initialState: Filters = {
	name: "",
	date: DateTime.now().toISODate(),
	capacity: null,
	amenities: [],
	duration: "60",
	isAllDay: false,
	start_time: "",
	end_time: "",
	location_id: "all",
};

const filtersSlice = createSlice({
	name: "filters",
	initialState,
	reducers: {
		resetFilters: () => {
			return initialState; // Reset to initial state
		},
		setNameFilter(state, action: PayloadAction<string>) {
			state.name = action.payload;
		},
		setDateFilter(state, action: PayloadAction<string>) {
			state.date = action.payload;
		},
		setLocationFilter(state, action: PayloadAction<string | number>) {
			state.location_id = action.payload;
		},
		setCapacityFilter(state, action: PayloadAction<number | null>) {
			state.capacity = action.payload;
		},
		incrementCapacityFilter(state) {
			if (state.capacity !== null) {
				state.capacity = Math.max(0, state.capacity + 1);
			}
		},
		decrementCapacityFilter(state) {
			if (state.capacity !== null && state.capacity > 0) {
				state.capacity = Math.max(0, state.capacity - 1);
			}
		},
		setAmenitiesFilter(state, action: PayloadAction<string[] | null>) {
			state.amenities = action.payload;
		},
		clearAmenitiesFilter(state) {
			state.amenities = null;
		},
		setDurationFilter(state, action: PayloadAction<string>) {
			state.duration = action.payload;
		},
		setAllDayFilter(state, action: PayloadAction<boolean>) {
			state.isAllDay = action.payload;
			if (action.payload === true) {
				state.duration = "60";
				state.start_time = "";
				state.end_time = "";
			}
		},
		setStartTimeFilter(state, action: PayloadAction<string>) {
			state.start_time = action.payload;
		},
		setEndTimeFilter(state, action: PayloadAction<string>) {
			state.end_time = action.payload;
		},
		updateFilters(state, action: PayloadAction<Filters>) {
			return {
				...state,
				...action.payload,
			};
		},
		// Other reducer cases for updating filters
	},
});

export const {
	setDateFilter,
	setCapacityFilter,
	setAmenitiesFilter,
	clearAmenitiesFilter,
	setDurationFilter,
	setStartTimeFilter,
	setAllDayFilter,
	setEndTimeFilter,
	setLocationFilter,
	incrementCapacityFilter,
	decrementCapacityFilter,
	setNameFilter,
	updateFilters,
	resetFilters,
} = filtersSlice.actions;
export default filtersSlice.reducer;
