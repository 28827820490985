// import CustomSelect from "@/components/common/CustomSelect"
// import Search from "@/components/input/Search"
import { useEffect, useState } from "react";
import MemberListCard from "./MemberListCard";
// import { MdMailOutline } from "react-icons/md";
import CustomSelect, { Option } from "@/components/common/CustomShadSelect";
import Loader from "@/components/Loader/Loader";
import { Button } from "@/components/ui/button";
import { useAppSelector } from "@/hooks/hooks";
import { useGetAllTeamMembersQuery } from "@/redux/features/api/teamMemberEndpoints";
// import { useGetAllAdminLocationsQuery } from "@/redux/features/apiSlice";
import Search from "@/components/input/Search";
import { sortByName, toTitleCase } from "@/services/utilities";
import { TeamMember, TeamMemberRoles } from "@/types/team-member";
import { useNavigate } from "react-router";
// import DeletePartners from "@/components/modals/admin/DeletePartners";
// import Modal from "@/components/modals/Modal";

const TeamMemberList: React.FC = () => {
	// const [showDelete, setShowDelete] = useState(false);
	// const initialItems: Item[] = [
	// 	{ id: 1, name: "Scott Eyawo" },
	// 	{ id: 2, name: "Daniella Mikiyas" },
	// 	{ id: 3, name: "Yaski Lana" },
	// 	// More items from mikiyas
	// ];

	const userId = useAppSelector((state) => state!.user!.user!.business_id);
	const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");
	const getAllTeamMembersQuery = useGetAllTeamMembersQuery(userId);
	// const queryData = useAppSelector((state) => state.analytics.query_data);

	const navigate = useNavigate();

	const [items, setItems] = useState<TeamMember[]>(
		getAllTeamMembersQuery?.data?.data ?? []
	);
	const [selectedItems, setSelectedItems] = useState<TeamMember[]>([]);
	const [, setSelectAll] = useState<boolean>(false);
	// const [displayData, setDisplayData] = useState();
	const [selectedRole, setSelectedRole] = useState("all");
	const [selectedSortBy, setSelectedSortBy] = useState("asc");
	const [searchQuery, setSearchQuery] = useState("");

	const rolesList: Option[] = [
		{ value: "all", label: "All Roles" },
		...getUniqueRoles(getAllTeamMembersQuery?.data?.data ?? []),
	];
	// const toggleSelectAll = () => {
	// 	if (selectAll) {
	// 		setSelectedItems([]);
	// 	} else {
	// 		setSelectedItems(items);
	// 	}
	// 	setSelectAll(!selectAll);
	// };

	const handleSortChange = (sortOrder: "asc" | "dsc") => {
		setSelectedSortBy(sortOrder);
		const sortedData = sortByName([...items], sortOrder);
		setItems(sortedData);
	};

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const query = e.target.value;
		setSearchQuery(query);
		setItems((prevData) =>
			e.target.value.length === 0
				? getAllTeamMembersQuery?.data?.data ?? []
				: prevData.filter(
						(item) =>
							(item.name &&
								item.name
									.toLocaleLowerCase()
									.includes(e.target.value.toLowerCase())) ||
							(item.email &&
								item.email
									.toLocaleLowerCase()
									.includes(e.target.value.toLowerCase()))
					)
		);
	};

	const toggleSelectItem = (item: TeamMember) => {
		if (selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
			setSelectedItems(
				selectedItems.filter(
					(selectedItem) => selectedItem.id !== item.id
				)
			);
		} else {
			setSelectedItems([...selectedItems, item]);
		}
		setSelectAll(false);
	};

	useEffect(() => {
		if (getAllTeamMembersQuery.data)
			setItems(getAllTeamMembersQuery?.data?.data);
	}, [getAllTeamMembersQuery.isSuccess, getAllTeamMembersQuery.data]);

	return (
		<>
			<div className="relative flex h-full flex-1 flex-col gap-y-3">
				<div className="flex w-full gap-2">
					<Search
						value={searchQuery}
						onChange={handleSearch}
						placeholder="Search"
						className="h-9 flex-1 !bg-white"
						inputClassName="h-9"
					/>
					{/* <CustomSelect
						placeholder="Location"
						className="w-fit flex-1"
						options={locationsList}
						value={selectedLocation}
						onChange={(value) => {
							setSelectedLocation(value);
							console.log(
								(value === "all"
									? items
									: items?.filter(
											(item) => item.id !== +value
										)) ?? []
							);
							setItems(
								(value === "all"
									? items
									: items?.filter(
											(item) => item.id !== +value
										)) ?? []
							);
						}}
					/> */}
					<CustomSelect
						placeholder="Roles"
						className="h-9 w-fit flex-1"
						options={rolesList}
						value={selectedRole}
						onChange={(value) => {
							setSelectedRole(value);
							setItems(
								(value === "all"
									? getAllTeamMembersQuery?.data?.data
									: getAllTeamMembersQuery?.data?.data?.filter(
											(item) => item.role === value
										)) ?? []
							);
						}}
					/>
					<CustomSelect
						placeholder="Sort by"
						className="h-9 w-fit flex-1"
						value={selectedSortBy}
						options={[
							{ value: "asc", label: "Sort (A-Z)" },
							{ value: "dsc", label: "Sort (Z-A)" },
						]}
						onChange={(value) => {
							handleSortChange(value as "asc" | "dsc");
						}}
					/>
					{userRole !== TeamMemberRoles.TeamMember &&
						userRole !== TeamMemberRoles.PartnerManager && (
							<Button
								variant="default"
								className=" h-full px-8"
								onClick={() => navigate("add-team-member")}
							>
								Add
							</Button>
						)}

					{/* <Button
					variant="outlinecustom"
					className="text-[12px] h-full py-1 px-2 text-primary gap-1"
				>
					<BsFiletypeCsv />
					Import CSV
				</Button> */}
				</div>
				<div className="flex h-full flex-1 flex-col gap-y-4">
					{/* <div className="ml-9 flex justify-between">
						<div className="flex items-center gap-2 text-[14px]">
							<input
								id="select-all"
								type="checkbox"
								onClick={toggleSelectAll}
							/>

							<label
								className={cn("", {
									"text-[#7D7E80]": !selectAll,
								})}
							>
								Select All
							</label>
						</div>
						<TooltipProvider>
							<div className="flex items-center gap-1">
								<Tooltip delayDuration={0}>
									<TooltipTrigger asChild>
										<Button
											variant="outlinecustom"
											disabled={!selectAll}
											className="h-7 px-1.5 py-1.5"
										>
											<TbTrash />
										</Button>
									</TooltipTrigger>
									<TooltipContent side="top" sideOffset={10}>
										Delete All
									</TooltipContent>
								</Tooltip>
							</div>
						</TooltipProvider>
					</div> */}

					<div className="flex flex-col space-y-2 px-1">
						{getAllTeamMembersQuery.data &&
							items &&
							items.map((item, index) => (
								<MemberListCard
									key={index}
									item={item}
									onSelect={() => toggleSelectItem(item)}
									checked={selectedItems.some(
										(selectedItem) =>
											selectedItem.id === item.id
									)}
								/>
							))}

						{getAllTeamMembersQuery.data &&
							items &&
							items.length === 0 && (
								<p className="py-10 text-center text-[18px] leading-[20px] tracking-[-0.1px] text-[#323539]">
									No Team Member Found 🤕
								</p>
							)}

						{getAllTeamMembersQuery.isLoading && (
							<Loader size={28} />
						)}
					</div>
				</div>
			</div>
			{/* <Modal dialogClassName="!rounded-[12px] max-w-[360px] bg-white w-full" open={showDelete} setOpen={setShowDelete}>
				<DeletePartners />
			</Modal> */}
		</>
	);
};

export default TeamMemberList;

const getUniqueRoles = (teamMembers: TeamMember[]): Option[] => {
	const uniqueRoles = Array.from(
		new Set(teamMembers.map((member) => member.role))
	);
	return uniqueRoles.map((role) => ({
		value: role,
		label: toTitleCase(role.split("_").join(" ")),
	}));
};
