// import { useState } from "react";
import FormInput from "./FormInput";
import { IoAdd, IoRemove } from "react-icons/io5";
import { useAppDispatch, useAppSelector } from "@/hooks/hooks";
import {
	decrementCapacityFilter,
	incrementCapacityFilter,
	setCapacityFilter,
} from "@/redux/slices/filterSlice";
import { useDebouncedCallback } from "use-debounce";

const CapacitySelector = () => {
	// const [value, setValue] = useState<number>(1);
	const capacity = useAppSelector((state) => state.filters.capacity);
	const dispatch = useAppDispatch();

	const increaseValue = () => {
		dispatch(incrementCapacityFilter());
	};
	const decreaseValue = () => {
		dispatch(decrementCapacityFilter());
	};

	const debouncedCapacityFilter = useDebouncedCallback((value) => {
		dispatch(setCapacityFilter(value));
	}, 500);

	return (
		<div className="relative w-full">
			<button
				className="absolute left-3 top-[30%] text-xl"
				onClick={decreaseValue}
			>
				<IoRemove />
			</button>
			<FormInput
				placeholder="Enter Here"
				className="!h-10 px-10 text-center text-[14px]"
				inputType="number"
				min={0}
				value={capacity?.toString()}
				onChange={(e) => debouncedCapacityFilter(e.target.value)}
				label=""
			/>
			<button
				className="absolute bottom-[30%] right-3 text-xl"
				onClick={increaseValue}
			>
				<IoAdd />
			</button>
		</div>
	);
};

export default CapacitySelector;
