import AmenityHolder from "@/components/common/AmenityHolder";
import CapacityHolder from "@/components/common/CapacityHolder";
import ImageCarousel from "@/components/common/ImageCarousel";
import MeetingDetailsCard from "@/components/partner/MeetingDetailsCard";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion";
import { Badge } from "@/components/ui/badge";
import Wrapper from "@/container/Wrapper";
import { cn } from "@/lib/utils";
import { useGetPartnerMeetingDetailsQuery } from "@/redux/features/api/partnerEndpoints";
import { FaChevronLeft } from "react-icons/fa6";
import { PiBuildings } from "react-icons/pi";
import { useNavigate, useParams } from "react-router";

const MeetingDetails = () => {
	const { id } = useParams();
	const { data, isFetching, isSuccess, isError } =
		useGetPartnerMeetingDetailsQuery({
			id: parseInt(id!),
		});
	const navigate = useNavigate();
	const status = data?.status;

	return (
		<div className="flex h-full flex-col gap-y-8 overflow-y-auto p-4">
			{/* top  */}
			<div className="flex items-center gap-4">
				<FaChevronLeft
					onClick={() => navigate(-1)}
					className=" cursor-pointer"
				/>
				<p className="text-[22px] font-semibold text-[#323539]">
					{data?.data?.status !== "invited"
						? "All Reservations"
						: "Your Meeting"}
				</p>
			</div>
			<Wrapper fetching={isFetching} className="">
				{isSuccess && data && (
					<>
						<div className="flex flex-col gap-x-20 md:flex-row md:px-4 ">
							<div className="w-full ">
								{(data?.data?.status === "accepted" ||
									data?.data?.status === "pending" ||
									data?.data?.status === "invited" ||
									data?.data?.status === "declined") &&
									data?.data?.company && (
										<section>
											<div className="mb-4 border-b-[0.5px] border-b-[#858C95] pb-3">
												<h1 className="text-lg font-semibold text-[#323539]">
													{data?.data?.company?.name}
												</h1>
												<p className="flex items-center gap-1 text-[14px] text-[#6D748D]">
													<PiBuildings />
													{data?.data?.company
														?.address ?? "N/A"}
												</p>
											</div>
										</section>
									)}
								<section className="flex items-center justify-between">
									<div>
										<h1 className="text-lg font-bold text-[#323539]">
											{data?.data?.room?.name ?? "N/A"}
										</h1>
										<p className="text-xs font-normal">
											{/* Building <b>A</b> | Floor <b>15</b> */}
											{data?.data?.room?.address ?? "N/A"}
										</p>
									</div>
									<div>
										<CapacityHolder
											capacity={
												data?.data?.room?.capacity ??
												"N/A"
											}
										/>
									</div>
								</section>
								<section className="py-4">
									<p className="text-[12px]">
										{data?.data?.room?.description ?? ""}
									</p>
								</section>
								<section>
									{data?.data?.room?.photos.length ? (
										<ImageCarousel
											images={data?.data?.room?.photos}
										/>
									) : (
										<div className="flex h-[120px] w-full flex-col justify-center rounded-md bg-[#DCDCDC] md:h-[192px] md:max-w-[616px]">
											<div className="mx-4 flex h-[50px] w-[50px] items-center justify-center rounded-full bg-[#C7C7C7]">
												<p className="select-none text-lg font-semibold text-white">
													{data?.data?.room.name
														.split(" ")[0][0]
														.toUpperCase()}
												</p>
											</div>
										</div>
									)}

									{/* <img
										src={picture}
										alt=""
										className="h-[120px] w-full rounded-md  md:h-[192px] md:max-w-[616px]"
									/> */}
								</section>
								{data?.data?.room?.description ? (
									<section className="flex flex-col pt-4 md:gap-y-2 mmd:hidden">
										<h1 className=" font-medium">
											Description
										</h1>
										<p
											className={cn(
												"text-[12px] md:text-base"
											)}
										>
											{data?.data?.room?.description}
										</p>
									</section>
								) : null}

								<section className="flex flex-col py-4 md:gap-y-2 mmd:hidden">
									<div className="flex items-center justify-between">
										<h1 className=" font-medium">
											Amenities
										</h1>
									</div>
									<ul className="flex flex-wrap gap-4 text-[12px]">
										{data?.data?.room?.amenities.length ? (
											data?.data?.room?.amenities?.map(
												(amenity) => (
													<div
														className="w-fit"
														key={amenity.name}
													>
														<AmenityHolder
															amenity={
																amenity?.name
															}
															variant="overview"
														/>
													</div>
												)
											)
										) : (
											<p className="text-sm font-light"> No amenities available</p>
										)}
									</ul>
								</section>
								<Accordion
									type="single"
									collapsible
									className="w-full md:hidden"
								>
									<AccordionItem
										value="item-1"
										className="border-none"
									>
										<AccordionTrigger className="py-2 hover:no-underline">
											Description
										</AccordionTrigger>
										<AccordionContent>
											{data?.data?.description ??
												"No description"}
										</AccordionContent>
									</AccordionItem>
									<AccordionItem
										value="item-2"
										className="border-none"
									>
										<AccordionTrigger className="py-2 hover:no-underline">
											Amenities
										</AccordionTrigger>
										<AccordionContent>
											<div className="p-1">
												{data?.data?.room?.amenities
													.length ? (
													<div className="flex flex-wrap items-center gap-[5px]">
														{data?.data.room.amenities.map(
															(item) => (
																<Badge
																	variant="white"
																	className="rounded-full border-transparent px-2.5 py-2 shadow-[0px_0px_4px_0px_rgba(0,0,0,0.15)]"
																>
																	{item.name}
																</Badge>
															)
														)}
													</div>
												) : (
													<p className="text-[12px] font-light">
														No amenities available
													</p>
												)}
											</div>
										</AccordionContent>
									</AccordionItem>
								</Accordion>
							</div>
							<div className="w-full ">
								<div className="max-w-lg">
									<MeetingDetailsCard
										meetingdata={data.data}
										status={status}
									/>
								</div>
							</div>
						</div>
					</>
				)}
				{isError && <p>Error Fetching data</p>}
			</Wrapper>
		</div>
	);
};

export default MeetingDetails;
