import Calendar from "@/assets/icons/calendar.svg";
import Office from "@/assets/icons/office.svg";
import Footer from "@/components/Footer";
import { useGetDisplayDataQuery } from "@/redux/features/api/adminBookingEndpoints";
import Autoplay from "embla-carousel-autoplay";
import React from "react";
import { Carousel, CarouselContent, CarouselItem } from "../../ui/carousel";
import DisplayCard from "./DisplayCard";
// import { Calendar } from '@/components/ui/calendar';

const DisplayFullScreenView = () => {
	const plugin = React.useRef(
		Autoplay({ delay: 6000, stopOnInteraction: true })
	);

	const { data } = useGetDisplayDataQuery({});

	return (
		<div className=" h-screen rounded-lg border-2 border-primary bg-[#F3F3F3] px-4">
			<div className="flex justify-between border-b border-b-primary py-4">
				<div>
					<p className="font-medium text-[#6D748D]">
						Ontario Health Hub
					</p>
					<h2 className="font-hoves text-[28px] font-semibold capitalize">
						Today's Activities
					</h2>
				</div>
				<div className="flex justify-end gap-12">
					{/* meeting room  */}
					<div className="flex h-fit  w-[180px] items-center gap-1 rounded-[99px] bg-white p-1">
						<div className="flex h-[48px] w-[48px] items-center justify-center rounded-[99px] bg-[#E0CDE2] p-2">
							<img src={Office} alt="Office Icon" />
						</div>
						<div className=" ">
							<p className="pb-0 text-lg font-semibold text-primary">
								26 Available
							</p>
							<p className="pt-0 text-[13px] font-[400] text-primary">
								Meeting Spaces
							</p>
						</div>
					</div>
					{/* time  */}
					//update time to use momentjs or date time
					<div className="flex h-fit w-fit items-center gap-1 rounded-[99px] bg-white p-1 ">
						<div className="flex h-[48px] w-[48px] items-center justify-center rounded-[99px] bg-[#E0CDE2] p-2">
							<img src={Calendar} alt="Calendar Icon" />
						</div>
						<div className=" pr-4">
							<p className="pb-0 text-lg font-semibold text-primary">
								March 12, 2024
							</p>
							<p className="pt-0 text-[13px] font-[400] text-primary">
								Monday
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="my-2"></div>

			<Carousel
				orientation="vertical"
				plugins={[plugin.current]}
				opts={{
					align: "start",
					// containScroll: "trimSnaps",
					dragFree: true,
					slidesToScroll: 1,
					loop: true,
				}}
				className="h-[80vh]"
			>
				<CarouselContent className=" h-[80vh]">
					{data.data &&
						data.data.map((room, index) => (
							<CarouselItem
								key={"s" + index}
								className=" basis-[1/6]"
							>
								{/* <div className="w-full"> */}
								<DisplayCard roomdata={room} key={index} />
								{/* </div> */}
							</CarouselItem>
						))}
				</CarouselContent>
			</Carousel>
			{/* <div className="mt-auto"> */}
			<Footer />
			{/* </div> */}
		</div>
	);
};

export default DisplayFullScreenView;
