import React from "react";

const NameLetter: React.FC<{ name: string }> = ({ name }) => {
	return (
		<div className="flex h-[65px] w-[65px] items-center justify-center rounded-md border border-[#E8E8E8]">
			<div className="flex size-9 items-center justify-center rounded-full bg-[#C7C7C7]/60">
				<p className="select-none text-lg font-semibold text-white">
					{name}
				</p>
			</div>
		</div>
	);
};

export default NameLetter;
