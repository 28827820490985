import { Route, Routes } from "react-router";
import TeamMembers from "./List";
import AddTeamMember from "./AddTeamMember";
import EditTeamMember from "./EditTeamMember";
import AddGuest from "./AddGuest";
import EditGuest from "./EditGuest";
import UserProtectedRoute from "@/provider/UserProtectedRoute";
import { TeamMemberRoles } from "@/types/team-member";

const Home = () => {
	return (
		<Routes>
			<Route index element={<TeamMembers />} />
			<Route path="/guest" element={<TeamMembers />} />
			<Route
				element={
					<UserProtectedRoute
						redirect="/admin/team-members/"
						allowedRoles={[
							TeamMemberRoles.SuperAdmin,
							TeamMemberRoles.BusinessAdmin,
						]}
					/>
				}
			>
				<Route
					path="/edit-team-member/:team_member_id"
					element={<EditTeamMember />}
				/>

				<Route
					path="/edit-guest/:business_id/:guest_id"
					element={<EditGuest />}
				/>
				

				<Route path="/add-team-member" element={<AddTeamMember />} />

				<Route path="/add-guest" element={<AddGuest />} />
			</Route>
		</Routes>
	);
};

export default Home;
