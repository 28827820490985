import { z } from "zod";

export const TeamMemberRoles = {
	SuperAdmin: "SUPER_ADMIN",
	LocationManager: "LOCATION_MANAGER",
	RoomManager: "ROOM_MANAGER",
	PartnerManager: "PARTNER_MANAGER",
	TeamMember: "TEAM_MEMBER",
	BusinessAdmin: "BUSINESS_ADMIN",
	PartnerSuperAdmin: "PARTENER_SUPER_ADMIN",
	PartnerAdmin: "PARTNER_ADMIN",
} as const;

export type TeamMemberRolesType =
	(typeof TeamMemberRoles)[keyof typeof TeamMemberRoles];

export const RoleEnum = z.enum([
	TeamMemberRoles.PartnerManager,
	TeamMemberRoles.LocationManager,
	TeamMemberRoles.RoomManager,
	TeamMemberRoles.TeamMember,
	TeamMemberRoles.BusinessAdmin,
	TeamMemberRoles.SuperAdmin,
]);

export const TeamMemberSchema = z.object({
	name: z.string().min(1, "Name is required"),
	email: z.string().email("Invalid email address"),
	phone_number: z.string().min(10, "Invalid phone number").max(10),
	role: RoleEnum,
	location_ids: z.array(z.number()).optional(),
	room_ids: z.array(z.number()).optional(),
	partner_company_ids: z.array(z.number()).optional(),
});

export type TeamMemberParams = z.infer<typeof TeamMemberSchema>;

export const GuestSchema = z.object({
	name: z.string().optional(),
	email: z.string().email("Invalid email address"),
	phone_number: z.string().min(10, "Invalid phone number").max(10).optional(),
});

export const PartnerAddMemberSchema = z.object({
	name: z.string().optional(),
	email: z.string().email("Invalid email address"),
	phone_number: z.string().min(10, "Invalid phone number").max(10).optional(),
	role: z.enum(["guest", "admin"], {
		required_error: "Role is required",
	}),
});

export const AdminAddPartnerSchema = z.object({
	company_name: z.string(),
	superadmin_name: z.string().optional(),
	superadmin_email: z.string().email(),
	superadmin_phone_number: z.string().optional(),
});

export type GuestParams = z.infer<typeof GuestSchema>;

export type PartnerAddMemberParams = z.infer<typeof PartnerAddMemberSchema>;

export type AdminAddPartnerParams = z.infer<typeof AdminAddPartnerSchema>;

export interface Pivot {
	team_member_id: number;
	owningable_id: number;
}

export interface PartnerCompany {
	id: number;
	name: string;
	address: string;
	status: string;
	super_admin_id: number;
	business_product_id: number;
	business_id: number;
	partner_company_request_id: number;
	paused_at: string | null;
	paused_user_id: number | null;
	cancelled_at: string | null;
	cancelled_user_id: number | null;
	created_at: string;
	updated_at: string;
	email: string | null;
	phone_number: string | null;
	country: string;
	state: string;
	city: string;
	pivot: Pivot;
}

export interface TeamMember {
	id: number;
	company_id?: number;
	name: string;
	email: string;
	phone_number: string;
	business_id: number;
	user_id: number | null;
	token: string;
	status: string;
	role: TeamMemberRolesType | "guest";
	accepted_at: string | null;
	declined_at: string | null;
	cancelled_at: string | null;
	created_at: string;
	updated_at: string;
	registration_url: string;
	user: any | null;
	partner_companies: PartnerCompany[];
	invitation_link?: string;
	invitation_token?: string;
}

export const UpdateBusinessSchema = z.object({
	name: z.string().min(1, "Name is required"),
	email: z.string().email("Invalid email address"),
	phone_number: z.string().min(10, "Invalid phone number").max(10),
	address: z.string().min(10, "Address is required"),
	address_2: z.string().optional(),
	country: z.string().min(3, "Country is required"),
	state: z.string().min(3, "State is required"),
	city: z.string().min(3, "City is required"),
	password: z
		.string()
		.min(8, "Password needs to be at least 8 characters")
		.optional(),
	["zip-code"]: z.string().max(11).optional(),
	logo: z.instanceof(File).optional(),
});

export type UpdateBusinessParams = z.infer<typeof UpdateBusinessSchema>;

export const UpdateProfileSchema = z.object({
	name: z.string().min(1, "Last name is required"),
	email: z.string().email("Invalid email address"),
});

export type UpdateProfileParams = z.infer<typeof UpdateProfileSchema>;

export const ChangePasswordSchema = z
	.object({
		password: z
			.string()
			.min(8, { message: "New Password must be at least 8 characters" }),
		password_confirmation: z.string(),
	})
	.refine(
		(values) => {
			return values.password === values.password_confirmation;
		},
		{
			message: "Passwords must match!",
			path: ["password_confirmation"],
		}
	);

export type ChangePasswordParams = z.infer<typeof ChangePasswordSchema>;

export const PartnerUpdateProfileSchema = z.object({
	name: z.string().min(1, "Last name is required"),
	phone_number: z.string().optional(),
	email: z.string().email().optional(),
});

export type PartnerUpdateProfileParams = z.infer<
	typeof PartnerUpdateProfileSchema
>;
