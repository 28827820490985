import { ReactNode } from "react";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "../ui/select";

export type Option = {
	label: string;
	value: string;
	disabled?: boolean;
	metadata?: any;
};

type Props = {
	options: Option[];
	placeholder?: string | ReactNode;
	label?: any;
	className?: string;
	itemClassName?: string;
	labelClassName?: string;
	onChange: (value: string) => void;
	value?: string;
	defaultValue?: string;
	hasChevron?: boolean;
};

const CustomShadSelect = ({
	options,
	placeholder,
	label,
	className,
	itemClassName,
	labelClassName,
	value,
	defaultValue,
	hasChevron = true,
	onChange,
}: Props) => {
	return (
		<Select
			// {...(defaultValue && { defaultValue })}
			defaultValue={defaultValue}
			value={value}
			onValueChange={onChange}
		>
			<SelectTrigger className={className} hasChevron={hasChevron}>
				<SelectValue
					placeholder={placeholder ? placeholder : "Select an item"}
					className="text-[#858C95]"
				/>
			</SelectTrigger>
			<SelectContent className="!z-[9999]">
				<SelectGroup>
					{label ? (
						<SelectLabel className={labelClassName}>
							{label}
						</SelectLabel>
					) : null}
					{options.map((option, idx) => {
						return (
							<SelectItem
								key={`${option.value} - ${idx}`}
								value={option.value}
								className={itemClassName}
								disabled={option.disabled}
							>
								{option.label}
							</SelectItem>
						);
					})}
				</SelectGroup>
			</SelectContent>
		</Select>
	);
};

export default CustomShadSelect;
