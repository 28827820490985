import { FaRegCreditCard } from "react-icons/fa6";

interface BillingHistoryCardProps {
	onSelect: () => void;
	checked: boolean;
	item: {
		id: number;
		date: string;
		plan: string;
		price: string;
	};
}
const BillingHistoryCard = ({
	item,
	onSelect,
	checked,
}: BillingHistoryCardProps) => {
	return (
		<div className="flex items-center justify-between rounded-[12px] bg-white px-4  py-3 shadow-[0px_0px_5px_rgba(0,0,0,0.25)]">
			<div className="flex gap-8 lg:flex-[0.3]">
				<input type="checkbox" checked={checked} onChange={onSelect} />
				<div className="flex items-center gap-2">
					<p className=" capitalize">{item.date}</p>
				</div>
			</div>
			<div className="hidden items-center justify-between gap-x-4 border-l-2 pl-6 lg:flex lg:flex-[0.3]">
				<div className="relative flex items-center gap-1">
					<p className="capitalize">{item.plan}</p>
				</div>
			</div>
			<div className="hidden items-center justify-between gap-x-4 border-l-2 pl-6 lg:flex lg:flex-[0.4]">
				<div className="relative flex items-center gap-1">
					<FaRegCreditCard />
					<p>$ {item.price}</p>
				</div>
				{/* <div className="flex justify-end gap-2 lg:flex-[0.1]">
					<Button
						variant="outlinecustom"
						className="h-7 px-1.5 py-1.5"
					>
						<Download />
					</Button>
				</div> */}
			</div>
		</div>
	);
};

export default BillingHistoryCard;
