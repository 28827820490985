import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from "../ui/carousel";

interface ImageCarouselProps {
	images: any[];
}
const ImageCarousel = ({ images }: ImageCarouselProps) => {
	return (
		<Carousel>
			<CarouselContent className="cursor-pointer">
				<>
					{images.length &&
						images?.map((image, index) => (
							<CarouselItem key={index} className="">
								<div className="flex h-[192px] justify-center bg-contain">
									<img
										src={image.image_url}
										alt="1"
										className="w-full rounded-xl bg-center object-cover"
									/>
								</div>
							</CarouselItem>
						))}
				</>
			</CarouselContent>
			<CarouselPrevious className="left-1 border-none bg-white bg-opacity-30 text-white" />
			<CarouselNext className="right-3 border-none bg-white bg-opacity-30 text-white" />
		</Carousel>
	);
};

export default ImageCarousel;
